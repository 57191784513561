import React, { Component } from 'react';
import { URLImgFront } from '../../../config/URLDB';
import { MDBModal, MDBContainer, MDBModalHeader, MDBIcon } from "mdbreact";
import Swal from 'sweetalert2';
import ReCAPTCHA from "react-google-recaptcha";
import { ConsultarCampaniasActiva, ConsultarDatosClienteCampania } from '../../../services/WebAPI_Utrahuilca';
import Loading from '../../Loading';

const validate = values => {
    const errors = {}
    if (values.TipoIdentificacion === 'DEFAULT') {
        errors.TipoIdentificacion = 'campo obligatorio'
    }
    if (!values.Identificacion) {
        errors.Identificacion = 'campo obligatorio'
    }
    if (!values.FechaExpedicion) {
        errors.FechaExpedicion = 'campo obligatorio'
    }
    return errors;
};

class Seccion02 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            modal: false,
            TipoIdentificacion: 'DEFAULT',
            NumeroAsignados: 'DEFAULT',
            Identificacion: '',
            FechaExpedicion: '',
            stateLoadign: false,
            FechaSorteo: '',
            InfoNumerosAsig: [],
            NombreAsociado: '',
            valor: 'none',
            valorCheckCamp: 'none',
            estadoSolicitud: true,
        };
        this.recaptchaRef = React.createRef();

        const data = {
            tran: 8,
            id_camp: 4
        }
        ConsultarCampaniasActiva(data).then(response => {
            try {
                if (response.data.length > 0) {
                    if (response.data.length >= 1) {
                        if (response.data[0].codresp) {
                            this.setState({
                                valorCheckCamp: 'none'
                            });
                        }
                        else {
                            this.setState({
                                valorCheckCamp: 'block',
                            })
                        }
                    }
                }
            }
            catch (error) {
                this.setState({
                    valorCheckCamp: 'none'
                });
            }
        });
    }

    textInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    toggleModal = () => {
        this.setState({
            modal: !this.state.modal,
            errors: {},
            TipoIdentificacion: 'DEFAULT',
            NumeroAsignados: 'DEFAULT',
            Identificacion: '',
            FechaExpedicion: '',
            FechaSorteo: '',
            InfoNumerosAsig: [],
            NombreAsociado: '',
            valor: 'none',
        });
    }

    ModalPopupNumeroSorteo = () => {
        this.setState({
            modal: true
        });
    }

    ConsultarDatos = () => {
        const { errors, ...sinErrors } = this.state;
        const result = validate(sinErrors);
        this.setState({ errors: result });

        if (!Object.keys(result).length) {
            this.setState({
                stateLoadign: true,
                TipoIdentificacion: 'DEFAULT',
                Identificacion: '',
                FechaExpedicion: ''
            });
            const data = {
                tipoiden: this.state.TipoIdentificacion,
                nit: this.state.Identificacion,
                fec_exp: this.state.FechaExpedicion,
                id_camp: 4
            }
            ConsultarDatosClienteCampania(data).then(response => {
                try {
                    if (response.data.length > 0) {
                        if (response.data.length >= 1) {
                            if (response.data[0].codresp) {
                                this.setState({
                                    NumeroAsignados: 'DEFAULT',
                                    FechaSorteo: '',
                                    InfoNumerosAsig: [],
                                    NombreAsociado: '',
                                    valor: 'none',
                                });
                                Swal.fire({
                                    icon: 'error',
                                    title: response.data[0].desresp
                                })
                            }
                            else {
                                var fecha = new Date(response.data[0].fecsorteo);
                                this.setState({
                                    valor: 'block',
                                    NombreAsociado: response.data[0].nomcom,
                                    FechaSorteo: fecha.getDate() + "/" + (fecha.getMonth() + 1) + "/" + fecha.getFullYear(),
                                    InfoNumerosAsig: response.data,
                                    estadoSolicitud: true,
                                })
                            }
                        }
                    }
                }
                catch (error) {
                    this.setState({
                        TipoIdentificacion: 'DEFAULT',
                        Identificacion: '',
                        FechaExpedicion: '',
                        NumeroAsignados: 'DEFAULT',
                        FechaSorteo: '',
                        InfoNumerosAsig: [],
                        NombreAsociado: '',
                        valor: 'none',
                        estadoSolicitud: true,
                    });
                    Swal.fire({
                        icon: 'error',
                        title: response.data[0].descMessage
                    })
                }
                this.setState({
                    stateLoadign: false
                });
            });
        }
    }

    validRecat = () => {
        if (this.recaptchaRef.current.getValue()) {
            this.setState({
                estadoSolicitud: false
            })
        } else {
            this.setState({
                estadoSolicitud: true
            })
        }
    }

    render() {
        const { modal, TipoIdentificacion, Identificacion, FechaExpedicion, InfoNumerosAsig, NumeroAsignados, FechaSorteo, NombreAsociado, errors, valor, valorCheckCamp } = this.state;
        return (
            <React.Fragment>
                <div className="bg-white">
                    <div className="pb-1 pb-sm-2 pb-md-2 pb-lg-2" style={{ width: '53vmin', height: '9vmin', display: valorCheckCamp, margin: 'auto' }}>
                        <div onClick={this.ModalPopupNumeroSorteo} className="button-img" style={{ backgroundImage: `url("${URLImgFront}/assets/EstrategiasComerciales/TPTG_BOTON_CONSULTA_NUMEROS.png")` }}></div>
                    </div>
                    <div className="container py-2">
                        <div className="mb-3" style={{ width: '100%' }}>
                            <div className="d-flex justify-content-center">
                                <img style={{ width: '100%', marginBottom: '0px', objectFit: 'contain' }} className="img-fluid" src={`${URLImgFront}/assets/EstrategiasComerciales/TPTG_PREMIOS.png`} alt="Estrategia" />
                            </div>
                        </div>
                    </div>
                </div>
                <MDBModal isOpen={modal} toggle={this.toggleModal} size="fluid" centered className="modal_redAgencias">
                    <MDBContainer>
                        <MDBModalHeader toggle={this.toggleModal} style={{ zIndex: '2222', background: 'white', borderBottom: '1px solid #dee2e6' }} className="pb-0" titleClass="w-100">
                            <p className="text-center" style={{ color: '#0B6A85', fontWeight: 'bold', fontSize: '3.8vmin' }}>CONSULTE SUS NÚMEROS ASIGNADOS PARA LOS SORTEOS</p>
                        </MDBModalHeader>
                        <div className="row bg-white pb-2 mx-0 mx-0 px-3">
                            <React.Fragment>
                                <div className="col-md-12 px-0 text-center mt-3">
                                    <picture>
                                        {/*Img Big*/}
                                        <source media="(min-width: 750px)" srcSet={`${URLImgFront}/assets/EstrategiasComerciales/TPTG_CAMPANIA-APORTES-SOCIALES-CONSULTA.jpg`} style={{ backgroundPosition: 'center', backgroundSize: 'cover', height: '520px', width: '100%' }} /> 
                                        {/*Img small*/}
                                        <img src={`${URLImgFront}/assets/EstrategiasComerciales/TPTG_CONSULTA-DE-NUMEROS-SORTEO-UTRAHUILCA.jpg`} alt="Ejemplo_dos" style={{ backgroundPosition: 'center', backgroundSize: 'cover', height: '520px', width:'100%' }} />
                                        <div style={{ position: 'absolute', top: '0', width: '100%' }}>
                                            <div className="row">
                                                <div className="col-md-7 pl-0 bg-transparent"></div>
                                                <div className="col-md-5 pl-0 bg-transparent pt-4 pt-md-4">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="px-4 text-left">
                                                                <h5 className="font-weight-bold" style={{ color: '#fff' }}>Tipo de documento</h5>
                                                                <select name="TipoIdentificacion" className="form-control" value={TipoIdentificacion} onChange={this.textInput}>
                                                                    <option value="DEFAULT" disabled>Seleccione..</option>
                                                                    <option value="C">CÉDULA CIUDADANÍA</option>
                                                                    <option value="E">CÉDULA EXTRANJERÍA</option>
                                                                    <option value="M">PERMISO ESPECIAL DE PERMANENCIA</option>
                                                                    <option value="N">NIT</option>
                                                                    <option value="P">PASAPORTE</option>
                                                                </select>
                                                                {errors.TipoIdentificacion && <strong className="text-danger" style={{ fontSize: '13px' }}>{errors.TipoIdentificacion}</strong>}
                                                            </div>
                                                            <div className="px-4 text-left pt-3">
                                                                <h5 className="font-weight-bold" style={{ color: '#fff' }}>Número de documento</h5>
                                                                <input name="Identificacion" type="text" className="form-control" value={Identificacion} maxLength="20" onChange={this.textInput} />
                                                                {errors.Identificacion && <strong className="text-danger" style={{ fontSize: '13px' }}>{errors.Identificacion}</strong>}
                                                            </div>
                                                            <div className="px-4 text-left pt-3">
                                                                <h5 className="font-weight-bold" style={{ color: '#fff' }}>Fecha expedición</h5>
                                                                <input name="FechaExpedicion" type="date" className="form-control" value={FechaExpedicion} onChange={this.textInput} />
                                                                {errors.FechaExpedicion && <strong className="text-danger" style={{ fontSize: '13px' }}>{errors.FechaExpedicion}</strong>}
                                                            </div>
                                                            <div className="pt-3 ml-3 justify-content-center align-items-center d-flex">
                                                                <form onSubmit={this.onSubmit} >
                                                                    <ReCAPTCHA
                                                                        ref={this.recaptchaRef}
                                                                        sitekey="6LfTTPgbAAAAAOKQp-b36jm-sJGpLIKw8cnZTzu-"
                                                                        onChange={this.validRecat}
                                                                    />
                                                                </form>
                                                            </div>
                                                            <div className="d-flex justify-content-center pt-2" disabled={this.state.estadoSolicitud}>
                                                                <button className="btn btn-campanias" onClick={this.ConsultarDatos}  style={{ background: '#f07f00', color: '#fff', cursor: 'pointer', height: '55px', fontSize: '17px', width: '170px' }}>Consultar</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </picture>
                                </div>
                                
                                <div style={{ display: valor }} className="col-md-12 bg-white pb-5">
                                    <hr />
                                    <div className="row">
                                        <div className="px-4 col-md-12 text-center px-0">
                                            <span style={{ color: '#0B6A85', fontWeight: "bold", fontSize: '3.3vmin' }}> {NombreAsociado} </span>
                                        </div>
                                    </div>
                                    <div className="row pt-2">
                                        <div className="col-md-6 text-left px-0 d-flex">
                                        <h4 className="font-weight-bold" style={{ color: '#0B6A85', fontSize:'3.3vmin' }}>NÚMEROS ASIGNADOS: &nbsp;</h4>
                                        <div className="text-center">
                                            <select name="NumeroAsignados" className="form-control" defaultValue={NumeroAsignados}>
                                                    <option value="DEFAULT" disabled>...</option>
                                                    {InfoNumerosAsig.map((e, i) => {
                                                        return [
                                                            <option key={i} value={e.numero}>{e.numero}</option>
                                                        ]
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    <div className="col-md-6 text-center d-flex px-0">
                                        <h4 className="font-weight-bold pr-2" style={{ color: '#0B6A85', fontSize: '3.3vmin' }}>FECHA DE SORTEO: </h4> <span className="text-right" style={{ color: '#808080', fontSize: '3.3vmin', marginTop:'-1px' }}>{FechaSorteo}</span>
                                        </div>
                                    </div>
                                </div>
                                {this.state.stateLoadign ? (
                                    <Loading />
                                ) : (<React.Fragment></React.Fragment>)}
                            </React.Fragment>
                        </div>
                    </MDBContainer>
                </MDBModal>
            </React.Fragment>
        );
    }
}

export default Seccion02;