import React, { Component } from 'react';
import Header from '../components/Principal/Header';
import Footer from '../components/Principal/Footer';
import { MDBTabPane, MDBTabContent, MDBNav, MDBNavItem, MDBNavLink, MDBModal, MDBContainer, MDBIcon, MDBModalHeader } from "mdbreact";
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import { URLMaps } from '../config/URLMaps';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './style.css';
import { AgenciaDetalles_, Municipios_, Agencias_, DptoAgencias_ } from '../services/WebAPI_Utrahuilca';
import { URLImgFront } from '../config/URLDB';

class Red_Agencias extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeItem: '1',
            locationLatSelect: 2.55972,
            locationLngSelect: -74.4792104,
            zoomMap: 8,
            modal: false,
            numberAgency: null,
            stateTolima: true,
            statePutumayo: true,
            stateCauca: true,
            stateCaqueta: true,
            stateHuila: true,
            infoAgencias: [],
            stateDpto: false,
            stateIcon: true,
            DptoAgenciasA: [],
            Detalles: [],
            stateImageModal: false,
            stateSResult: false,
            AgenciasA: [],
            expanded: '',
            dataArray: [],
            loadpage: false,
            stateInfoA: false
        };
    }

    componentDidMount = () => {
        setTimeout(() => {
            this.setState({
                loadpage: true
            })
        }, 0.1)
        this.DptoAgencias();
        this.Agencias();
    }

    Agencias = () => {
        const data = {
            tran: 4,
            idredagencia: 0,
            idtipoagencia: 0,
            idmunicipio: 0,
            nombreagenc: 'NA',
            descagencia: 'NA',
            rutaiconomapa: 'NA',
            latitud: 'NA',
            longitud: 'NA',
            zoom: 0,
            estado: 0,
            usucrea: 'NA'
        }
        Agencias_(data).then(response => {
            try {
                if (response.data.length > 0) {
                    if (response.data.length >= 1) {
                        if (response.data[0].message === "004404") {
                            this.setState({
                                stateSResult: false
                            })
                        } else {
                            this.setState({
                                infoAgencias: response.data,
                                stateDpto: true,
                                stateSResult: true
                            });
                        }
                    } else {
                        this.setState({
                            stateSResult: false
                        })
                    }
                } else { }
            } catch (error) {
                console.log(error);
            }
        });
    }

    DptoAgencias = () => {
        const data = {
            tran: 7,
            iddepartamento: 0,
            estado: 1,
        }
        DptoAgencias_(data).then(response => {
            try {
                if (response.data.length > 0) {
                    if (response.data.length >= 1) {
                        if (response.data[0].message === "004404") {
                            this.setState({
                                stateSResult: false
                            })
                        } else {
                            this.setState({
                                DptoAgenciasA: response.data,
                                stateSResult: true
                            });
                        }
                    } else {
                        this.setState({
                            stateSResult: false
                        })
                    }
                } else { }
            } catch (error) {
                console.log(error);
            }
        });
    }

    DptoAgenciasID = (id) => {
        const data = {
            tran: 9,
            iddepartamento: id,
            estado: 1,
        }
        Municipios_(data).then(response => {
            try {
                if (response.data.length > 0) {
                    if (response.data.length >= 1) {
                        if (response.data[0].message === "004404") {
                            this.setState({
                                stateSResult: false
                            })
                        } else {
                            this.setState({
                                AgenciasA: response.data,
                                stateSResult: true
                            });
                            this.cargarAgencias(id);
                        }
                    } else {
                        this.setState({
                            stateSResult: false
                        })
                    }
                } else { }
            } catch (error) {
                console.log(error);
            }
        });
    }

    cargarAgencias = (id) => {
        const data = {
            tran: 9,
            idredagencia: 0,
            idtipoagencia: 0,
            idmunicipio: id,
            nombreagenc: 'NA',
            descagencia: 'NA',
            rutaiconomapa: 'NA',
            latitud: 'NA',
            longitud: 'NA',
            zoom: 0,
            estado: 0,
            usucrea: 'NA'
        }
        Agencias_(data).then(response => {
            try {
                if (response.data.length > 0) {
                    if (response.data.length >= 1) {
                        if (response.data[0].message === "004404") {
                            this.setState({
                                stateSResult: false
                            })
                        } else {
                            this.setState({
                                infoAgencias: response.data,
                                stateDpto: true,
                                stateSResult: true
                            });
                        }
                    } else {
                        this.setState({
                            stateSResult: false
                        })
                    }
                } else { }
            } catch (error) {
                console.log(error);
            }
        });
    }

    handleChange = (panel) => (event, isExpanded) => {
        this.setState({
            expanded: isExpanded ? panel : false
        })
    }

    toggle = tab => e => {
        if (this.state.activeItem !== tab) {
            this.setState({
                activeItem: tab
            });
        }
    };

    onMarkerClick = (id) => {
        this.setState({
            numberAgency: id,
            modal: !this.state.modal
        });
    };

    toggleModal = () => {
        this.setState({
            modal: !this.state.modal
        });
    }

    loadAgenciasDetalles = (id) => {
        const data = {
            tran: 6,
            idredagendetalle: 0,
            idredagencia: id,
            idmunicipio: 0,
            rutafotoagenc: 'NA',
            direccion: 'NA',
            infoadicihorario: 'NA',
            estado: 1,
            usucrea: 'NA'
        }
        AgenciaDetalles_(data).then(response => {
            if (response.data[0].message === "004404") {
                this.setState({
                    modal: false,
                    stateImageModal: false
                })
            } else {
                this.setState({
                    modal: true,
                    Detalles: response.data,
                    stateImageModal: true
                })
                this.loadTable();
            }
        });
    }

    loadTable = () => {
        if (this.state.Detalles.length >= 1) {
            this.setState({
                dataArray: []
            })
            const dataJson = this.state.Detalles;
            var jsonnuevo = [];
            for (var i = 0; i < dataJson.length; i++) {
                var horarios = [];
                var telefonos = [];
                for (var j = i; j < dataJson.length; j++) {
                    horarios.push({ "diaslaboran": dataJson[j].diaslaboran, "horariolaboran": dataJson[j].horariolaboran });
                    telefonos.push({ "indicativo": dataJson[j].indicativo, "nrocontacto": dataJson[j].nrocontacto, "extension": dataJson[j].extension, "manejawhatsapp": dataJson[j].manejawhatsapp });
                    if (j === (dataJson.length - 1)) {
                        j = dataJson.length;
                        i = j - 1;
                        break;
                    }
                    else {
                        if (dataJson[j].idredagendetalle !== dataJson[j + 1].idredagendetalle) {
                            i = j;
                            j = dataJson.length;
                            break;
                        }
                    }
                }
                telefonos = new Set(telefonos.map(JSON.stringify));
                let newtelefonos = Array.from(telefonos).map(JSON.parse);
                horarios = new Set(horarios.map(JSON.stringify));
                let newhorarios = Array.from(horarios).map(JSON.parse);

                jsonnuevo.push(
                    {
                        "idredagendetalle": dataJson[i].idredagendetalle,
                        "idredagencia": dataJson[i].idredagencia,
                        "nombreagenc": dataJson[i].nombreagenc,
                        "rutafotoagenc": dataJson[i].rutafotoagenc,
                        "infoadicihorario": dataJson[i].infoadicihorario,
                        "direccion": dataJson[i].direccion,
                        newhorarios,
                        newtelefonos,
                        "usucrea": dataJson[i].usucrea,
                        "feccrea": dataJson[i].feccrea,
                    }
                );
                horarios = null;
                telefonos = null;
            }
            if (jsonnuevo[0].infoadicihorario === null) {
                this.setState({
                    stateInfoA: false
                })
            } else {
                this.setState({
                    stateInfoA: true
                })
            }
            this.setState({
                dataArray: jsonnuevo,
                stateImageModal: true
            })
        } else {
            this.setState({
                stateImageModal: false
            })
        }
    }

    render() {
        const { activeItem, zoomMap, modal, locationLatSelect, locationLngSelect, stateDpto, stateIcon } = this.state;
        const MyMapComponent = withScriptjs(withGoogleMap((props) =>
            <GoogleMap
                defaultZoom={zoomMap}
                defaultCenter={{ lat: locationLatSelect, lng: locationLngSelect }}
            >
                {this.state.stateSResult ? (
                    <React.Fragment>
                        {this.state.infoAgencias.map((e, i) => {
                            return [
                                <React.Fragment key={i}>
                                    <Marker
                                        onClick={() => { this.loadAgenciasDetalles(e.idredagencia) }}
                                        icon={new window.google.maps.MarkerImage(`${URLImgFront}/assets${e.rutaiconomapa}`, null, null, null, new window.google.maps.Size(41, 51))}
                                        position={{ lat: parseFloat(e.latitud), lng: parseFloat(e.longitud) }}
                                    />
                                </React.Fragment>
                            ]
                        })}
                    </React.Fragment>
                ) : (
                    <React.Fragment></React.Fragment>
                )}
            </GoogleMap>
        ))
        return (
            <React.Fragment>
                <Header id={2} />
                {this.state.loadpage ? (
                    <React.Fragment>
                        <div style={{ height: '100vh', width: '100%' }}>
                            <MyMapComponent
                                isMarkerShown
                                googleMapURL={URLMaps}
                                loadingElement={<div style={{ height: `100%` }} />}
                                containerElement={<div style={{ height: `100vh` }} />}
                                mapElement={<div style={{ height: `100%` }} />}
                            />
                            <label htmlFor="check" className="abrir_menu_agencias" style={stateIcon ? { left: '340px' } : { left: '0px' }}><MDBIcon icon={stateIcon ? "angle-left" : "angle-right"} /></label>
                            <input type="radio" id="check" style={{ display: 'none' }} onClick={() => { this.setState({ stateIcon: !this.state.stateIcon }) }} />
                            <div className="contenedor_agencias" style={stateIcon ? { position: 'absolute', zIndex: '9', width: '350px', top: '100px', left: '0px', backgroundColor: '#fff', overflowY: 'scroll' } : { position: 'absolute', zIndex: '9', width: '0px', top: '100px', left: '0px', backgroundColor: '#fff', overflowY: 'scroll' }}>
                                <hr className="bg-white" />
                                <MDBNav className="nav-tabs mt-1 mx-0 px-0">
                                    <MDBNavItem>
                                        <MDBNavLink className="text-dark font-weight-bold" link to="#" active={activeItem === "1"} onClick={this.toggle("1")} role="tab" >
                                            Agencias
                                        </MDBNavLink>
                                    </MDBNavItem>
                                </MDBNav>
                                <MDBTabContent activeItem={activeItem} className="bg-white mt-0" >
                                    <MDBTabPane tabId="1" role="tabpanel" className="px-2 py-4">
                                        <div className="container content-depart pb-5">
                                            <p className="font-weight-bold mostrar-todos" onClick={() => { this.Agencias() }} style={stateIcon ? { borderBottom: '2px solid #FA7500', position: 'absolute', top: '100px', right: '25px', cursor: 'pointer' } : { display: 'none' }}>Mostrar Todas</p>
                                            <div className="text-center mt-5">
                                                {stateDpto ? (
                                                    <React.Fragment>
                                                        {this.state.DptoAgenciasA.map((e, i) => {
                                                            i = i + 1;
                                                            return [
                                                                <React.Fragment key={i}>
                                                                    <Accordion
                                                                        square
                                                                        expanded={this.state.expanded === 'panel' + i + ''}
                                                                        onChange={this.handleChange('panel' + i + '')}
                                                                    >
                                                                        <AccordionSummary
                                                                            expandIcon={<ExpandMoreIcon />}
                                                                            aria-controls={`panel${i}d-content`}
                                                                            id={`panel${i}d-header`}
                                                                            onClick={() => { this.DptoAgenciasID(e.iddepartamento) }}
                                                                        >
                                                                            <Typography style={{ fontSize: '18px' }}>{e.nombredpto}</Typography>
                                                                        </AccordionSummary>
                                                                        <AccordionDetails>
                                                                            <ul className="py-0 text-left my-0 list-group w-100" style={{ listStyle: 'none' }}>
                                                                                {this.state.AgenciasA.map((e, i) => {
                                                                                    return [
                                                                                        <React.Fragment key={i}>
                                                                                            <li className="list-group-item" onClick={() => { this.loadAgenciasDetalles(e.idredagencia) }}><p className="my-1 cursor"><MDBIcon icon="map-marker-alt" /> {e.nombreagenc}</p></li>
                                                                                        </React.Fragment>
                                                                                    ]
                                                                                })}
                                                                            </ul>
                                                                        </AccordionDetails>
                                                                    </Accordion>
                                                                </React.Fragment>
                                                            ]
                                                        })}
                                                    </React.Fragment>
                                                ) : (
                                                    <React.Fragment></React.Fragment>
                                                )}
                                            </div>
                                        </div>
                                    </MDBTabPane>
                                </MDBTabContent>
                            </div>
                        </div>

                        <MDBModal isOpen={modal} toggle={this.toggleModal} size="lg" centered className="modal_redAgencias">
                            <MDBModalHeader toggle={this.toggleModal} style={{ position: 'absolute', right: '10px', top: '10px', zIndex: '2222' }}></MDBModalHeader>
                            <MDBContainer>
                                <div className="row pb-3">
                                    {this.state.dataArray.map((e, i) => {
                                        return (
                                            <React.Fragment key={i}>
                                                <div className="col-md-12 text-center pt-3">
                                                    <div className="row">
                                                        <div className="col-md-6 pr-0 pl-0">
                                                            {this.state.stateImageModal ? (
                                                                <img style={{ height: '300px', width: '400px', objectFit: 'cover' }} className="img-fluid" src={`${URLImgFront}/assets${e.rutafotoagenc}`} alt={e.rutafotoagenc} />
                                                            ) : (
                                                                <React.Fragment></React.Fragment>
                                                            )}
                                                        </div>
                                                        <div className="col-md-6 pl-0 bg-white ">
                                                            <div className="py-3 pt-4" style={{ height: '300px' }}>
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <div className="px-4 text-center pt-4">
                                                                            <h5 className="font-weight-bold">{e.nombreagenc}</h5>
                                                                            <span className="font-weight-bold">Dirección</span><span> {e.direccion}</span>
                                                                            <hr />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12 pr-0">
                                                                        <div className="px-4">
                                                                            <p className="title text-center font-weight-bold">Horarios de Atención</p>
                                                                            {e.newhorarios.map((y, i) => <tr key={i} className="text-left">{y.diaslaboran + ' ' + y.horariolaboran}</tr>)}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 bg-white pb-5">
                                                    <div className="px-4">
                                                        <p className="title text-left pt-3 font-weight-bold">Teléfonos</p>
                                                        <ul style={{ listStyle: 'none' }}>
                                                            {e.newtelefonos.map((w, i) => <li key={i} className="numeros" style={{ float: 'left', cursor: 'pointer' }}><a href={`tel:+57${w.nrocontacto}`} className="text-dark"> <MDBIcon icon="phone-alt" /><span>{w.indicativo === '0' ? <b></b> : w.indicativo}</span> <span className="text-dark">{w.nrocontacto}</span> <span>{w.extensionw === '0' ? <b></b> : w.extensionw}</span>&nbsp;&nbsp;&nbsp; </a></li>)}
                                                        </ul>
                                                    </div>
                                                </div>
                                                {this.state.stateInfoA ? (
                                                    <div className="col-md-12 pb-3 bg-white">
                                                        <hr />
                                                        <div className="px-4">
                                                            <p className="font-weight-bold">Información adicional</p>
                                                            <p className="text-justify">{e.infoadicihorario}</p>
                                                        </div>
                                                    </div>
                                                ) : (<React.Fragment></React.Fragment>)}
                                            </React.Fragment>
                                        );
                                    })}
                                </div>
                            </MDBContainer>
                        </MDBModal>
                    </React.Fragment>
                ) : (
                    <React.Fragment></React.Fragment>
                )}
                <Footer />
            </React.Fragment >
        );
    }
}

export default Red_Agencias;