import { MDBIcon } from 'mdbreact';
import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import Swal from 'sweetalert2';

class Inicio extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    logout = () => {
        Swal.fire({
            text: "Esta seguro de cerrar sesión?",
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#00c851',
            cancelButtonColor: '#d33',
            confirmButtonText: 'SI',
            cancelButtonText: 'NO'
        }).then((result) => {
            if (result.value) {
                Swal.fire(
                    '',
                    'Se cerro sesión exitosamente!',
                    'success'
                )
                sessionStorage.removeItem('idusuariofundacion');
                sessionStorage.removeItem('usuariofundacion');
                this.props.history.push('/fundacion/login');
            }
        });
    }

    render() {
        return (
            <React.Fragment>
                <p className="my-0 font-weight-bold" style={{ fontSize: '25px' }}>Bienvenido - {this.props.usuario}</p>
                <span>Cargo: {this.props.cargo}</span>
                <hr />
                <div className="btn_cerrar_sesion">
                    <button onClick={this.logout} className="btn px-4 py-2 text-dark" style={{ background: '#f47216' }}>Cerrar Sesión &nbsp; <MDBIcon icon="sign-out-alt" /></button>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(Inicio);