import React, { Component } from 'react';
import { URLImgFront } from '../../../config/URLDB';

class Seccion06 extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="bg-white" style={{ height: '100%', width: '100%' }}>
                    <div className="container">
                        <div className="row pb-2">
                            <div className="col-12">
                                <p className="text-center mb-2" style={{ fontFamily: 'Posterama 1927', color: '#0B6A85', fontSize: '5.5vmin', fontWeight: 'bold' }}>Fechas del Sorteo</p>
                            </div>
                        </div>
                        <div className="pb-4 pb-md-5" style={{ width: '100%' }}>
                            <img style={{ width: '100%', marginBottom: '0px', objectFit: 'contain' }} className="img-fluid" src={`${URLImgFront}/assets/EstrategiasComerciales/TPTG_FECHASDELSORTEO.png`} alt="Estrategia" />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Seccion06;