import React, { Component } from 'react';
import { MDBIcon } from 'mdbreact';

class BAhorro extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    render() {
        return (
            <React.Fragment>
                <p className="texto-titulos text-left text-justify py-4">Beneficios de ahorro</p>
                <p className="text-justify"><MDBIcon icon="chevron-right" style={{ color: '#3f8592' }} /> Productos de ahorro de acuerdo a sus necesidades: Ahorro a la Vista, Ahorro Programado y a Término Fijo.</p>
                <p className="text-justify"><MDBIcon icon="chevron-right" style={{ color: '#3f8592' }} /> Rentabilidad garantizada de sus ahorros.</p>
                <p className="text-justify"><MDBIcon icon="chevron-right" style={{ color: '#3f8592' }} /> Las cuentas de ahorros no generan cobro por administración y no tienen descuentos inesperados por ningún concepto.</p>
                <p className="text-justify"><MDBIcon icon="chevron-right" style={{ color: '#3f8592' }} /> Acceso a la información de consulta de saldos, solicitud de extractos, certificaciones  y acceso Portal transaccional y aplicación móvil sin costo.</p>
                <p className="text-justify"><MDBIcon icon="chevron-right" style={{ color: '#3f8592' }} /> Consignaciones y retiros en la red de agencias de UTRAHUILCA sin costo.</p>
                <p className="text-justify"><MDBIcon icon="chevron-right" style={{ color: '#3f8592' }} /> Seguro de los productos de ahorros  (a la vista, programados y a término) hasta por 50 millones de pesos con el seguro de depósito FOGACOOP.</p>
                <p className="text-justify"><MDBIcon icon="chevron-right" style={{ color: '#3f8592' }} /> El promedio de los ahorros más la antigüedad de vinculación aumentan la reciprocidad para solicitar crédito.</p>
                <p className="text-justify"><MDBIcon icon="chevron-right" style={{ color: '#3f8592' }} /> Inembargabilidad de los ahorros hasta por montos establecidos en la ley. </p>
                <p className="text-justify"><MDBIcon icon="chevron-right" style={{ color: '#3f8592' }} /> Facilidad y Seguridad en el manejo de su operaciones a través de registros biométricos (Huella, foto y firma), lo que le permite hacer transacciones sin necesidad de tener libreta o tarjeta débito.</p>
                <p className="text-justify"><MDBIcon icon="chevron-right" style={{ color: '#3f8592' }} /> Recibir y enviar transferencias de fondos de otras cooperativas afiliadas a la Red Coopcentral o de otras entidades bancarias.</p>
            </React.Fragment>
        );
    }
}

export default BAhorro;