import React, { Component } from 'react';
import { URLImgFront } from '../../../config/URLDB';
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.min.css';

class Seccion04 extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            slidesPerView: 1,
            stateSwiper: false
        };
    }

    componentDidMount() {
        if (window.screen.width >= 550) {
            this.setState({
                slidesPerView: 1,
                stateSwiper: true
            })
        } else if (window.screen.width >= 60 && window.screen.width <= 549) {
            this.setState({
                slidesPerView: 1,
                stateSwiper: true
            })
        } else {
            this.setState({
                slidesPerView: 3,
                stateSwiper: true
            })
        }
    }

    render() {
        const params = {
            slidesPerView: this.state.slidesPerView,
            spaceBetween: 30,
            freeMode: true,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            autoplay: {
                delay: 4000,
                disableOnInteraction: false,
            },
            shouldSwiperUpdate: true
        }
        return (
            <React.Fragment>
                <div className="bg-white" style={{ height: '100%', width: '100%' }}>
                    {this.state.stateSwiper ? (<React.Fragment>
                        <Swiper {...params}>
                            <div  style={{ cursor: 'pointer' }}>
                                <div className="d-flex justify-content-center">
                                    <img style={{ width: '100%', marginBottom: '0px', objectFit: 'contain' }} src={`${URLImgFront}/assets/EstrategiasComerciales/TPTG_MENSUAL.png`} alt="Estrategia" />
                                </div>
                            </div>
                            <div style={{ cursor: 'pointer' }}>
                                <div className="d-flex justify-content-center">
                                    <img style={{ width: '100%', marginBottom: '0px', objectFit: 'contain' }} src={`${URLImgFront}/assets/EstrategiasComerciales/TPTG_TRIMESTRAL.png`} alt="Estrategia" />
                                </div>
                            </div>
                            <div style={{ cursor: 'pointer' }}>
                                <div className="d-flex justify-content-center">
                                    <img style={{ width: '100%', marginBottom: '0px', objectFit: 'contain' }} src={`${URLImgFront}/assets/EstrategiasComerciales/TPTG_GRAN_SORTEO.png`} alt="Estrategia" />
                                </div>
                            </div>
                        </Swiper>
                    </React.Fragment>) : (<React.Fragment></React.Fragment>)}
                </div>
            </React.Fragment>
        );
    }
}

export default Seccion04;