import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { URLImgFront } from '../../config/URLDB';

class Infantil extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    render() {
        return (
            <React.Fragment>
                <div>
                    <div className="container contenido_2 text-center text-white" style={{ position: 'absolute', zIndex: '2', top: '95%', left: '0', right: '0' }}>
                        <p className="texto-contenido px-0 mx-0">Cuenta de ahorro que le permitirá tener disponibilidad inmediata de sus recursos sin ningún costo y obtener rentabilidad diariamente.</p>
                        <div className="d-flex align-items-center">
                            <img width="190" className="img-fluid" src={`${URLImgFront}/assets/img-variadas/ninos.png`} alt="img-niños" />
                            <p className="texto-contenido pl-4">Dirigido a: Persona natural menor de edad.</p>
                        </div>
                        <hr className="bg-white" />
                        <div className="d-flex align-items-center">
                            <img width="190" className="img-fluid" src={`${URLImgFront}/assets/img-variadas/MONTO 1.png`} alt="img-monto" />
                            <div>
                                <p className="texto-contenido pl-4 text-left">Monto de apertura: </p>
                                <p className="texto-contenido pl-4 text-left">Infantil: De 0 a 14 años 1% de un SMMLV.</p>
                                <p className="texto-contenido pl-4 text-left">Juvenil: De 14 a 18 años 1.5% de un SMMLV.</p>
                            </div>
                        </div>
                        <p className="texto-contenido font-weight-bold pl-4 mt-5" style={{ fontSize: '35px' }}>Beneficios</p>
                        <div className="row py-4">
                            <div className="col-md-6 my-3">
                                <div className="card contenedor-juvenil" style={{ height: '200px' }}>
                                    <div className="card-body text-dark">
                                        <p className="texto-contenido text-left">Exención del impuesto del 4x1.000 conforme a las condiciones establecidas por el Estado hasta 350 UVT. La cuenta debe estar inscrita.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 my-3">
                                <div className="card contenedor-juvenil" style={{ height: '200px' }}>
                                    <div className="card-body text-dark">
                                        <p className="texto-contenido text-left">Tarjeta débito y libreta de ahorro, sin cuota de manejo o administración.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 my-3">
                                <div className="card contenedor-juvenil" style={{ height: '200px' }}>
                                    <div className="card-body text-dark">
                                        <p className="texto-contenido text-left">Los tres (3) primeros retiros del mes en cajeros ATH y SERVIBANCA con la tarjeta débito son gratuitos.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 my-3">
                                <div className="card contenedor-juvenil" style={{ height: '200px' }}>
                                    <div className="card-body text-dark">
                                        <p className="texto-contenido text-left">Compras y pagos ilimitados en establecimientos comerciales totalmente gratis con su tarjeta débito.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 my-3">
                                <div className="card contenedor-juvenil" style={{ height: '200px' }}>
                                    <div className="card-body text-dark">
                                        <p className="texto-contenido text-left">Consignaciones y retiros por ventanilla ilimitados.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 my-3">
                                <div className="card contenedor-juvenil" style={{ height: '200px' }}>
                                    <div className="card-body text-dark">
                                        <p className="texto-contenido text-left">Consulta de saldos, solicitud de extractos y certificaciones de la cuenta sin costo.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 my-3">
                                <div className="card contenedor-juvenil" style={{ height: '200px' }}>
                                    <div className="card-body text-dark">
                                        <p className="texto-contenido text-left">Su dinero se rentabiliza por encima del mercado financiero tradicional.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 my-3">
                                <div className="card contenedor-juvenil" style={{ height: '200px' }}>
                                    <div className="card-body text-dark">
                                        <p className="texto-contenido text-left">Las cuentas de ahorros no tienen débitos inesperados por concepto de comisiones.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 my-3">
                                <div className="card contenedor-juvenil" style={{ height: '200px' }}>
                                    <div className="card-body text-dark">
                                        <p className="texto-contenido text-left">Consulta de saldos y movimientos por ventanilla sin costo.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 my-3">
                                <div className="card contenedor-juvenil" style={{ height: '200px' }}>
                                    <div className="card-body text-dark">
                                        <p className="texto-contenido text-left">Recibe y Realiza: Transferencias de dinero entre cuentas de ahorros de Utrahuilca, Transferencias a otra entidad de la Red Coopcentral y Transferencias interbancarias a otras cuentas de ahorros; a excepción de este último, las transacciones no tienen costo.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 my-3">
                                <div className="card contenedor-juvenil" style={{ height: '200px' }}>
                                    <div className="card-body text-dark">
                                        <p className="texto-contenido text-left">Transacciones por ventanilla con libreta de ahorros o tarjeta débito sin costo.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 my-3">
                                <div className="card contenedor-juvenil" style={{ height: '200px' }}>
                                    <div className="card-body text-dark">
                                        <p className="texto-contenido text-left">Entrega gratuita de la primera libreta de ahorros o tarjeta débito.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 my-3">
                                <div className="card contenedor-juvenil" style={{ height: '200px' }}>
                                    <div className="card-body text-dark">
                                        <p className="texto-contenido text-left">Póliza de seguro a bajo costo en caso de sustracción, amparando las pérdidas que se originen a consecuencia del hurto, clonación, adulteración de su tarjeta débito.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 my-3">
                                <div className="card contenedor-juvenil" style={{ height: '200px' }}>
                                    <div className="card-body text-dark">
                                        <p className="texto-contenido text-left">Se beneficia con seguro de depósito FOGACOOP hasta por 50 millones de pesos.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 my-3">
                                <div className="card contenedor-juvenil" style={{ height: '200px' }}>
                                    <div className="card-body text-dark">
                                        <p className="texto-contenido text-left">El promedio de los ahorros más la antigüedad de vinculación aumentan la reciprocidad para solicitar crédito.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 my-3">
                                <div className="card contenedor-juvenil" style={{ height: '200px' }}>
                                    <div className="card-body text-dark">
                                        <p className="texto-contenido text-left">Inembargabilidad de los ahorros hasta por montos establecidos en la ley.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 mt-5">
                                <Link to="/Asesoria" className="mx-2"><span className="my-5 px-5 py-2 font-weight-bold" style={{ background: '#FA7500', color: '#fff', borderRadius: '20px', cursor: 'pointer' }}>Asesoría de productos de ahorro</span></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment >
        );
    }
}

export default Infantil;