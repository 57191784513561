import React, { Component } from 'react';
import { MDBIcon, MDBInput } from 'mdbreact';
import { VerifAsociadoConvenio_, GenerarCodigoOTP_, PQRSF_, ValidarCodigoOTP_ } from '../../services/WebAPI_Utrahuilca';
import Swal from 'sweetalert2';
import Header from '../Principal/Header';
import MensajeF from '../Mensaje_Flotante/MensajeF';
import Footer from '../Principal/Footer';
import Loading from '../Loading';

const validate = values => {
    const errors = {}
    if (values.tipoiden === 'DEFAULT') {
        errors.tipoiden = 'campo obligatorio'
    }
    if (!values.nit) {
        errors.nit = 'campo obligatorio'
    }
    return errors;
};

class FormSolicMultiportal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            nit: '',
            estadoSolicitud: true,
            tipoiden: 'DEFAULT',
            nomcom: '',
            correo: '',
            telcel: '',
            loadpage: false,
            stateLoadign: false
        };
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                loadpage: true
            })
        }, 0.1)
    }

    textInput = (e) => {
        if (e.target.name === "nit") {
            let regex = new RegExp("^[a-zA-Z0-9]+$");
            if (regex.test(e.target.value) || e.target.value === "") {
                this.setState({
                    [e.target.name]: e.target.value
                }, () => {
                    this.consultarPersona();
                });
            }
        } else {
            this.setState({
                [e.target.name]: e.target.value,
            }, () => {
                this.consultarPersona();
            })
        }
    }

    activeButton = () => {
        this.setState({
            estadoSolicitud: !this.state.estadoSolicitud
        })
    }

    consultarPersona = () => {
        if (this.state.nit.length === 0) {
            this.setState({
                nombres: '',
                apellidos: '',
                correo: '',
                telcel: ''
            })
        } else { }

        if (this.state.tipoiden !== 'DEFAULT' && this.state.nit.length >= 5) {
            const data = {
                tipoiden: this.state.tipoiden,
                nit: this.state.nit,
            }

            VerifAsociadoConvenio_(data).then(response => {
                try {
                    if (response.data.length > 0) {
                        if (response.data[0].respuesta) {
                            Swal.fire({
                                icon: 'warning',
                                text: 'No se encontró información en la petición'
                            })
                            this.setState({
                                nombres: '',
                                apellidos: '',
                                correo: '',
                                telcel: ''
                            })
                        }
                        else {
                            if (response.data[0].correo === " " || response.data[0].telcel === " " || response.data[0].correo === "" || response.data[0].telcel === "") {
                                Swal.fire({
                                    icon: 'warning',
                                    text: 'Sus datos están desactualizados, acérquese a una de nuestras oficinas!'
                                })
                                this.setState({
                                    nombres: '',
                                    apellidos: '',
                                    correo: '',
                                    telcel: ''
                                })
                            } else {
                                var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
                                if (!pattern.test(response.data[0].correo)) {
                                    Swal.fire({
                                        icon: 'warning',
                                        text: 'Sus datos están desactualizados, acérquese a una de nuestras oficinas!'
                                    })
                                    this.setState({
                                        nombres: '',
                                        apellidos: '',
                                        correo: '',
                                        telcel: ''
                                    })
                                } else {
                                    this.setState({
                                        nombres: response.data[0].prinom + ' ' + response.data[0].segnom,
                                        apellidos: response.data[0].priape + ' ' + response.data[0].segape,
                                        nomcom: response.data[0].nomcom,
                                        correo: response.data[0].correo,
                                        countCorreo: response.data[0].correo.length,
                                        telcel: response.data[0].telcel,
                                        countTelcel: response.data[0].telcel.length,
                                        direccion: response.data[0].dirres
                                    })
                                }
                            }
                        }
                    } else {
                        this.setState({
                            nombres: '',
                            apellidos: '',
                            correo: '',
                            telcel: ''
                        })
                    }
                } catch (error) {
                    console.log(error);
                }
            });
        }
    }

    sendForm = () => {
        const { errors, ...sinErrors } = this.state;
        const result = validate(sinErrors);
        this.setState({ errors: result });
        if (!Object.keys(result).length) {
            // formulario validado
            if (this.state.nomcom === '' && this.state.correo === '' && this.state.telcel === '') {
                Swal.fire({
                    icon: 'warning',
                    text: 'Verifique que los datos ingresados sean correctos!'
                })
            } else {
                this.setState({
                    stateLoadign: true
                })
                const data = {
                    tipoiden: this.state.tipoiden,
                    nit: this.state.nit,
                    nomcom: this.state.nomcom,
                    correo: this.state.correo,
                    telcel: this.state.telcel,
                    usuario: this.state.tipoiden + ';' + this.state.nit
                }
                GenerarCodigoOTP_(data).then(response => {
                    try {
                        this.setState({
                            stateLoadign: false
                        })
                        if (response.data[0].codresp === '000000') {
                            this.setState({
                                id_cod_otp: response.data[0].codigo
                            })
                            Swal.fire({
                                icon: 'success',
                                text: response.data[0].desresp,
                            })
                            this.validarOTP();
                        } else {
                            Swal.fire({
                                icon: 'warning',
                                text: response.data[0].desresp
                            })
                        }
                    } catch (error) {
                        console.log(error);
                    }
                });
            }
        }
    }

    validarOTP = () => {
        Swal.fire({
            icon: 'info',
            title: 'Ingresar el código de verificación',
            text: '',
            html:
                `<span style="text-align: justify">A su celular y correo electrónico fue enviado un código OTP para validar la identidad del Asociado.</span>
                 <input type="text" id="codigo_otp" class="swal2-input" maxLength="4" placeholder="Ingrese el código" pattern="[0-9]" autocomplete="false" onKeypress="if (event.keyCode < 45 || event.keyCode > 57) event.returnValue = false;">
                `,
            showCancelButton: true,
            showCloseButton: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            confirmButtonText: 'Validar',
            cancelButtonText: 'Reenviar Código',
            cancelButtonColor: '#008694',
            showLoaderOnConfirm: true,
            inputAttributes: {
                autocapitalize: 'off'
            },
            preConfirm: () => {
                this.setState({
                    stateLoadign: true
                })
                const data = {
                    id_cod_otp: parseInt(this.state.id_cod_otp),
                    codigo_otp: parseInt(Swal.getPopup().querySelector('#codigo_otp').value)
                }
                ValidarCodigoOTP_(data).then(response => {
                    try {
                        if (response.data[0].codresp === '000000') {
                            this.enviarCorreo();
                        } else {
                            this.setState({
                                stateLoadign: false
                            })

                            Swal.fire({
                                icon: 'warning',
                                text: response.data[0].desresp,
                                showCancelButton: false,
                                confirmButtonText: `Intentar nuevamente`,
                                confirmButtonColor: '#f47216',
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    this.validarOTP();
                                }
                            });
                        }
                    } catch (error) {
                        console.log(error);
                    }
                });

            },
        })
            .then((result) => {
                if (result.dismiss !== "close") {
                    if (!result.isConfirmed) {
                        this.sendForm();
                    }
                } else {
                    var checkBox = document.querySelector("#checkboxa");
                    checkBox.checked = false;
                    this.setState({
                        tipoiden: 'DEFAULT',
                        nit: '',
                        nombres: '',
                        apellidos: '',
                        correo: '',
                        direccion: '',
                        telcel: '',
                        estadoSolicitud: true
                    })
                }
            });
    }

    enviarCorreo = () => {
        const data = {
            tipodoc: this.state.tipoiden,
            nrodocumento: this.state.nit,
            nombres: this.state.nombres,
            apellidos: this.state.apellidos,
            email: this.state.correo,
            asunto: 'Solicitud de Activacion del Servicio',
            direccion: this.state.direccion,
            nrocelular: this.state.telcel,
            tiposolic: 'N/A',
            msjpqrs: 'N/A',
            tiporesp: 'N/A',
            ip: 'N/A',
            configenvio: 1
        }
        PQRSF_(data).then(response => {
            try {
                if (response[0] === "000000") {
                    var checkBox = document.querySelector("#checkboxa");
                    checkBox.checked = false;
                    this.setState({
                        stateLoadign: false,
                        tipoiden: 'DEFAULT',
                        nit: '',
                        nombres: '',
                        apellidos: '',
                        correo: '',
                        direccion: '',
                        telcel: '',
                        estadoSolicitud: true
                    })
                    Swal.fire({
                        icon: 'success',
                        title: response[1],
                        text: 'Formulario enviado exitosamente. De 24 a 48 horas, UTRAHUILCA enviará un correo electrónico para continuar con el registro.'
                    })
                } else {
                    this.setState({
                        stateLoadign: false
                    })

                    Swal.fire({
                        icon: 'warning',
                        text: response[1]
                    })
                }
            } catch (error) {
                console.log(error);
            }
        });
    }

    render() {
        const { errors } = this.state;
        return (
            <React.Fragment >
                <Header id={2} />
                <MensajeF />
                {this.state.loadpage ? (
                    <React.Fragment>
                        <div className="bg-white solicitud_credito" style={{ width: '100%', paddingTop: '190px' }}>
                            <div className="container-fluid px-5">
                                <p className="texto1" style={{ fontSize: '60px', color: '#747373' }}>Solicitud Portal Red Coopcentral</p>
                                <p className="texto2 text-justify">Asociado/a, si sus datos no coinciden o están desactualizados, visita su agencia más cercana para actualizarlos. </p>
                                <p className="pb-4 texto2 text-justify">Solicite información y soporte de la Cooperativa Virtual llamando a la línea gratuita nacional 01-8000-930-105 opción 1. De lunes a viernes de 8:00 a.m. a 6:00 p.m. jornada continua y sábados de 8:00 a.m. a 12:00 m.</p>
                            </div>

                            <div className="px-5 formulario" style={{ background: '#f7f7f7', width: '100%' }}>
                                <div className="container-fluid px-5 py-5">
                                    <p className="mb-0 font-weight-bold" style={{ fontSize: '25px', color: '#3f8592' }}>Diligencie el siguiente formulario y envíe</p>
                                    <hr style={{ background: '#3f8592' }} />
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="font-weight-bold mb-0 pb-0">Tipo de Documento <b className="text-danger">*</b></p>
                                            <select name="tipoiden" className="form-control mt-2" value={this.state.tipoiden} onChange={this.textInput}>
                                                <option value={'DEFAULT'} disabled>Seleccione...</option>
                                                <option value="C">Cédula de Ciudadania</option>
                                                <option value="E">Cédula de Extranjería</option>
                                                <option value="N">NIT</option>
                                                <option value="P">Pasaporte</option>
                                            </select>
                                            {errors.tipoiden && <strong className="text-danger" style={{ fontSize: '13px' }}>{errors.tipoiden}</strong>}
                                        </div>
                                        <div className="col-md-6 mt-2">
                                            <p className="font-weight-bold mb-0 pb-0">Número de Documento de Identidad <b className="text-danger">*</b></p>
                                            <MDBInput name="nit" value={this.state.nit} label="Ingrese su número de documento" type="text" className="mt-0" maxLength="20" onChange={this.textInput} />
                                            {errors.nit && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '75px' }}>{errors.nit}</strong>}
                                        </div>
                                        <div className="col-md-6">
                                            <p className="font-weight-bold mb-0 pb-0">Nombres </p>
                                            <MDBInput name="nombres" value={this.state.nombres} disabled={true} type="text" className="mt-0" />
                                        </div>
                                        <div className="col-md-6">
                                            <p className="font-weight-bold mb-0 pb-0">Apellidos </p>
                                            <MDBInput name="apellidos" value={this.state.apellidos} disabled={true} type="text" className="mt-0" />
                                        </div>
                                        <div className="col-md-6 pt-3">
                                            <p className="font-weight-bold mb-0 pb-0">Correo electrónico registrado en la Cooperativa</p>
                                            <MDBInput name="correo" value={this.state.correo === '' ? '' : `*******${this.state.correo.substring(this.state.countCorreo, 7)}`} disabled={true} type="email" className="mt-0" onChange={this.textInput} />
                                        </div>
                                        <div className="col-md-6 pt-3">
                                            <p className="font-weight-bold mb-0 pb-0">Número registrado en la Cooperativa</p>
                                            <MDBInput name="telcel" value={this.state.telcel === '' ? '' : `*******${this.state.telcel.substring(this.state.countTelcel, 6)}`} disabled={true} type="text" className="mt-0" onChange={this.textInput} />
                                        </div>
                                    </div>
                                    <div className="terminos">
                                        <div className="pt-4">
                                            <p className="font-weight-bold">Términos y condiciones</p>
                                            <p className="text-justify">Declaro que entiendo que antes de registrarme en la Cooperativa Virtual - Pagos y Transferencias, debo haber actualizado mis datos personales en la cooperativa en el último año, además es mi obligación conocer todas las condiciones, requisitos, servicios y demás aspectos que este portal me suministra. Además, entiendo y acepto que la clave que asigne para el ingreso de la Cooperativa Virtual-Pagos y Transferencias es de carácter personal, confidencial, indelegable e intransferible. Su manejo es de mi absoluta responsabilidad y por tal razón exonero a la Cooperativa Utrahuilca por el mal uso que puede darle a ella.</p>
                                            <hr className="bg-dark" />
                                            <div className="pt-1 d-flex align-items-center">
                                                <input type="checkbox" id="checkboxa" onClick={() => { this.setState({ estadoSolicitud: !this.state.estadoSolicitud }) }} />&nbsp;<span className="font-weight-bold mt-0 pt-0 "> Acepto términos y condiciones </span>
                                            </div>
                                            <div className="col-md-12 my-4 d-flex text-center justify-content-center">
                                                <button className="btn px-5" onClick={this.sendForm} disabled={this.state.estadoSolicitud} style={{ background: '#f47216', color: 'white', borderRadius: '20px' }}>Enviar Solicitud</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.state.stateLoadign ? (
                            <Loading />
                        ) : (<React.Fragment></React.Fragment>)}
                        <span className="pt-4 font-weight-bold" style={{ fontSize: '20px', position: 'absolute', top: '130px', left: '10px' }}><MDBIcon icon="caret-right" className="ml-2 mr-1" /> Personas <MDBIcon icon="caret-right" className="ml-2 mr-1" /> Solicitud Portal Red Coopcentral</span>
                    </React.Fragment>
                ) : (<React.Fragment></React.Fragment>)}
                <Footer />
            </React.Fragment >
        );
    }
}

export default FormSolicMultiportal;