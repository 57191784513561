import React, { Component } from 'react';
import FileUploader from "./Credito/FileUploader";
import FileUploaderUpdate from "./Credito/FileUploaderUpdate";
import { MDBIcon, MDBInput } from 'mdbreact';
import Swal from 'sweetalert2';
import { CargueImagenes, ProductosSimula_, Simulador_, SimProductosGPostAdmin_ } from '../../../services/WebAPI_Utrahuilca';
import reactCSS from 'reactcss';
import { SketchPicker } from 'react-color';
import FileUploaderIcon from './Credito/FileUploaderIcon';

const validate = values => {
    const errors = {}
    if (values.posicion === 'DEFAULT') {
        errors.posicion = 'campo obligatorio'
    }
    if (values.producto === 'DEFAULT') {
        errors.producto = 'campo obligatorio'
    }
    if (!values.descsimulador) {
        errors.descsimulador = 'campo obligatorio'
    }
    return errors;
};

class S_Crédito extends Component {
    uploader = React.createRef();
    uploader1 = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            estadoNuevo: false,
            errors: {},
            producto: 'DEFAULT',
            descsimulador: '',
            color: '#FFFFFF',
            posicion: 'DEFAULT',
            estadoCambioImg1: false,
            estadoCambioImg: false,
            simuladorData: [],
            Productos: [],
            editposicion: 0,
            editrutaiconosimul: '',
            editrutaImg: '',
            editdescripcion: '',
            editcolor: '',
            editnombprodutra: '',
            estado: null,
            estadoImagen: false,
            estadoImagen1: false,
            idSeleccionado: null,
            displayColorPicker: false,
            idPintar: 0,
        }
    }

    async componentDidMount() {
        this.cargarInformacion();
        this.cargarSelect();
    }

    cargarInformacion = () => {
        const data = {
            tran: 9,
            idsimuladorprod: 0,
            idprodutra: 0,
            descsimulador: 'NA',
            rutaiconosimul: 'NA',
            rutafondosimul: 'NA',
            color: 'NA',
            posicion: 0,
            estado: 1,
            usucrea: 'NA'
        }
        Simulador_(data).then(response => {
            try {
                if (response.data.length > 0) {
                    if (response.data.length >= 1) {
                        if (response.data[0].message === "004404") {
                            this.setState({
                                estadoNuevo: true
                            })
                        } else {
                            this.setState({
                                idSeleccionado: response.data[0].idsimuladorprod,
                                simuladorData: response.data,
                                idprodutra: response.data[0].idprodutra,
                                editrutaiconosimul: response.data[0].rutaiconosimul,
                                editrutaImg: response.data[0].rutafondosimul,
                                editdescripcion: response.data[0].descsimulador,
                                editcolor: response.data[0].color,
                                editnombprodutra: response.data[0].nombprodutra,
                                editposicion: response.data[0].posicion,
                                estado: response.data[0].estado,
                                stateImg: false,
                                estadoNuevo: false,
                                estadoImagen: true,
                                estadoImagen1: true,
                                estadoCambioImg1: false,
                                idPintar: response.data[0].idsimuladorprod
                            })
                            document.getElementById('' + response.data[0].idsimuladorprod + '').style.background = "#f47216";
                            document.getElementById('' + response.data[0].idsimuladorprod + '').style.color = "#fff";
                            document.getElementById('' + response.data[0].idsimuladorprod + '').style.fontWeight = "bold";
                            this.cargarSelect();
                        }
                    } else {
                        this.setState({
                            estadoNuevo: true
                        })
                    }
                } else {
                }
            } catch (error) {
                console.log(error);
            }
        });
    }

    cargarSelect = () => {
        const data = {
            tran: 8,
            idprodutra: 0,
            nombprodutra: 'NA',
            descprodutra: 'NA',
            rutaimgprod: 'NA',
            rutavistaprod: 'NA',
            prolinea: 0,
            idtipoprod: 2,
            idtipopers: 0,
            estado: 1,
            usucrea: 'NA'
        }
        ProductosSimula_(data).then(response => {
            try {
                if (response.data.length > 0) {
                    if (response.data.length >= 1) {
                        this.setState({
                            Productos: response.data
                        })
                    }
                }
            } catch (error) {
                console.log(error);
            }
        });
    }

    cambioImagen1 = (name, file) => {
        this.setState({
            estadoCambioImg1: true
        });
    }

    cambioImagen = (name, file) => {
        this.setState({
            estadoCambioImg: true
        });
    }

    textInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    insertarDatos = () => {
        const { errors, ...sinErrors } = this.state;
        const result = validate(sinErrors);
        this.setState({ errors: result });
        if (!Object.keys(result).length) {
            // formulario validado
            const data = {
                idsimuladorprod: 0,
                idprodutra: parseInt(this.state.producto),
                descsimulador: this.state.descsimulador,
                rutafondosimul: '/img-simulador/' + this.uploader.current.getFileName(),
                rutaiconosimul: '/img-simulador/' + this.uploader1.current.getFileName(),
                color: this.state.editcolor,
                posicion: parseInt(this.state.posicion),
                estado: 1,
                usucrea: sessionStorage.getItem('idusuario')
            }
            SimProductosGPostAdmin_(data).then(response => {
                if (response.data.length > 0) {
                    if (response.data[0].message === "000000") {
                        this.cargarImagen();
                        Swal.fire({
                            icon: 'success',
                            title: 'Se inserto correctamente.'
                        })
                        this.setState({
                            estadoNuevo: false,
                            producto: 'DEFAULT',
                            descsimulador: '',
                            color: '#FFFFFF',
                            posicion: 'DEFAULT',
                        })
                        this.cargarInformacion();
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error al insertar.'
                        })
                    }
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Ocurrio un error!'
                    })
                }
            });
        }
    }

    cargarImagen = () => {
        var formData = new FormData();
        var ruta1 = '';
        var ruta2 = '';
        var capNombre = this.uploader.current.getFileName();
        var capNombre1 = this.uploader1.current.getFileName();
        
        if (capNombre === undefined || capNombre === null || capNombre === "" || capNombre === "undefined" || capNombre === "null") {
            ruta1 = this.state.editrutaImg;
        } else {
            ruta1 = '/img-simulador/' + this.uploader.current.getFileName();
            formData.append("file", this.uploader.current.getFileObject());
            formData.append("rutaImagen", ruta1);
        }
        if (capNombre1 === undefined || capNombre1 === null || capNombre1 === "" || capNombre1 === "undefined" || capNombre1 === "null") {
            ruta2 = this.state.editrutaiconosimul;
        } else {
            ruta2 = '/img-simulador/' + this.uploader1.current.getFileName();
            formData.append("file", this.uploader1.current.getFileObject());
            formData.append("rutaImagen", ruta2);
        }
        CargueImagenes(formData).then(response => {
            if (response) {
                this.cargarInformacion();
            }
        });
    }

    busquedaId = (dataSe) => {
        document.getElementById('' + this.state.idPintar + '').style.background = "#fff";
        document.getElementById('' + this.state.idPintar + '').style.color = "#000";
        document.getElementById('' + this.state.idPintar + '').style.fontWeight = "normal";
        document.getElementById('' + dataSe.idsimuladorprod + '').style.background = "#f47216";
        document.getElementById('' + dataSe.idsimuladorprod + '').style.color = "#fff";
        document.getElementById('' + dataSe.idsimuladorprod + '').style.fontWeight = "bold";
        this.setState({
            estadoCambioImg: false,
            est: !this.state.est,
            idSeleccionado: dataSe.idsimuladorprod,
            idPintar: dataSe.idsimuladorprod
        })
        const data = {
            tran: 6,
            idsimuladorprod: dataSe.idsimuladorprod,
            idprodutra: 0,
            descsimulador: 'NA',
            rutaiconosimul: 'NA',
            rutafondosimul: 'NA',
            color: 'NA',
            posicion: 0,
            estado: 1,
            usucrea: 'NA'
        }
        Simulador_(data).then(response => {
            try {
                if (response.data.length > 0) {
                    if (response.data.length >= 1) {
                        if (response.data[0].message === "004404") {
                            this.setState({
                                estadoNuevo: true
                            })
                        } else {
                            this.setState({
                                editrutaImg: response.data[0].rutafondosimul,
                                idprodutra: response.data[0].idprodutra,
                                editrutaiconosimul: response.data[0].rutaiconosimul,
                                editdescripcion: response.data[0].descsimulador,
                                editcolor: response.data[0].color,
                                editnombprodutra: response.data[0].nombprodutra,
                                editposicion: response.data[0].posicion,
                                estado: response.data[0].estado,
                                stateImg: false,
                                estadoNuevo: false,
                                estadoImagen: true,
                                estadoImagen1: true
                            })
                        }
                    } else {
                        this.setState({
                            estadoNuevo: true
                        })
                    }
                } else {
                }
            } catch (error) {
                console.log(error);
            }
        });
    }

    activar = (id) => {
        const data = {
            tran: 7,
            idsimuladorprod: id,
            idprodutra: 0,
            descsimulador: 'NA',
            rutaiconosimul: 'NA',
            rutafondosimul: 'NA',
            color: 'NA',
            posicion: 0,
            estado: 1,
            usucrea: sessionStorage.getItem('idusuario')
        }
        Simulador_(data).then(response => {
            if (response) {
                this.setState({
                    estadoS: false
                })
                Swal.fire({
                    icon: 'success',
                    title: 'Se activo satisfactoriamente'
                });
                document.getElementById('' + this.state.idPintar + '').style.background = "#fff";
                document.getElementById('' + this.state.idPintar + '').style.color = "#000";
                document.getElementById('' + this.state.idPintar + '').style.fontWeight = "normal";
                this.cargarInformacion();
            }
        });
    }

    inactivar = (id) => {
        const data = {
            tran: 7,
            idsimuladorprod: id,
            idprodutra: 0,
            descsimulador: 'NA',
            rutaiconosimul: 'NA',
            rutafondosimul: 'NA',
            color: 'NA',
            posicion: 0,
            estado: 0,
            usucrea: sessionStorage.getItem('idusuario')
        }
        Simulador_(data).then(response => {
            if (response) {
                this.setState({
                    estadoS: false
                })
                Swal.fire({
                    icon: 'success',
                    title: 'Se inactivo satisfactoriamente'
                });
                document.getElementById('' + this.state.idPintar + '').style.background = "#fff";
                document.getElementById('' + this.state.idPintar + '').style.color = "#000";
                document.getElementById('' + this.state.idPintar + '').style.fontWeight = "normal";
                this.cargarInformacion();
            }
        });
    }

    actualizarDatos = (id) => {
        var ruta = '';
        var rutaIco = '';
        var capNombre = this.uploader.current.getFileName();
        var capNombre1 = this.uploader1.current.getFileName();
        if (capNombre === undefined || capNombre === null) {
            ruta = this.state.editrutaImg;
        } else {
            ruta = '/img-simulador/' + this.uploader.current.getFileName();
        }
        if (capNombre1 === undefined || capNombre1 === null) {
            rutaIco = this.state.editrutaiconosimul;
        } else {
            rutaIco = '/img-simulador/' + this.uploader1.current.getFileName();
        }
        const data = {
            idsimuladorprod: id,
            idprodutra: this.state.idprodutra,
            nombprodutra: this.state.editnombprodutra,
            rutafondosimul: ruta,
            rutaiconosimul: rutaIco,
            descsimulador: this.state.editdescripcion,
            color: this.state.editcolor,
            posicion: parseInt(this.state.editposicion),
            estado: this.state.estado,
            usucrea: sessionStorage.getItem('idusuario')
        }
        SimProductosGPostAdmin_(data).then(response => {
            if (response.data.length > 0) {
                if (response.data[0].message === "000000") {
                    this.cargarImagen();
                    this.setState({
                        estadoCambioImg: false,
                        color: '',
                        editposicion: null
                    })
                    Swal.fire({
                        icon: 'success',
                        title: 'Se actualizo correctamente.'
                    })
                    document.getElementById('' + this.state.idPintar + '').style.background = "#fff";
                    document.getElementById('' + this.state.idPintar + '').style.color = "#000";
                    document.getElementById('' + this.state.idPintar + '').style.fontWeight = "normal";
                    this.cargarInformacion();
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error al actualizar.'
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Ocurrio un error!'
                })
            }
        });
    }

    capturaChecked = (e) => {
        this.setState({
            checked: String(e.target.value),
        })
    }

    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    };

    handleClose = () => {
        this.setState({ displayColorPicker: false })
    };

    handleChange = (color) => {
        this.setState({ editcolor: color.hex })
    };

    handleNew = () => {
        this.setState({
            estadoNuevo: !this.state.estadoNuevo,
            errors: {},
            editcolor: '#ffffff'
        })
        if (this.state.estadoNuevo) {
            this.setState({
                producto: 'DEFAULT',
                descripcion: '',
                errors: {},
                posicion: 'DEFAULT',
                color: '#ffffff'
            })
            this.cargarInformacion();
        }
    }

    render() {
        const { estadoNuevo, errors, estadoCambioImg1, estadoCambioImg, editnombprodutra, simuladorData, editdescripcion, editrutaImg, estadoImagen, estadoImagen1, editrutaiconosimul, editposicion } = this.state;
        const styles = reactCSS({
            'default': {
                color: {
                    width: '36px',
                    height: '14px',
                    borderRadius: '2px',
                    background: this.state.editcolor,
                },
                swatch: {
                    padding: '5px',
                    background: '#fff',
                    borderRadius: '1px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                    display: 'inline-block',
                    cursor: 'pointer',
                },
                popover: {
                    position: 'absolute',
                    zIndex: '2',
                },
                cover: {
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                },
            },
        });
        return (
            <React.Fragment>
                <p className="font-weight-bold">Simulador de Crédito</p>
                <hr />
                <div className="plus_add_agencia align-items-center d-flex" onClick={() => { this.handleNew() }}>
                    {estadoNuevo ? <p>Cancelar &nbsp;<MDBIcon icon="times" size="lg" /></p> : <p>Agregar Nuevo &nbsp;<MDBIcon icon="plus-circle" size="lg" /></p>}
                </div>
                {estadoNuevo ? (
                    <React.Fragment>
                        <div className="justify-content-center d-flex">
                            <div className="text-center">
                                <FileUploader ref={this.uploader} estadoImg={true} FCambiar={this.cambioImagen1} />
                            </div>
                        </div>
                        <div className="container mt-5 pt-5 pb-5">
                            <div className="row">
                                <div className="col-md-6">
                                    <FileUploaderIcon ref={this.uploader1} estadoImg={true} FCambiar={this.cambioImagen} />
                                </div>
                                <div className="col-md-6">
                                    <label>Producto</label>
                                    <select name="producto" className="form-control" value={this.state.producto} onChange={this.textInput}>
                                        <option value="DEFAULT" disabled>Seleccione..</option>
                                        {this.state.Productos.map((e, i) => {
                                            return [
                                                <React.Fragment key={i}>
                                                    <option value={e.idprodutra}>{e.nombprodutra}</option>
                                                </React.Fragment>
                                            ]
                                        })}
                                    </select>
                                    {errors.producto && <strong className="text-danger" style={{ fontSize: '13px' }}>{errors.producto}</strong>}
                                </div>
                                <div className="col-md-6 mt-3">
                                    <MDBInput label="Descripción" type="textarea" name="descsimulador" value={this.state.descsimulador} onChange={this.textInput} />
                                    {errors.descsimulador && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '57px' }}>{errors.descsimulador}</strong>}
                                </div>
                                <div className="col-md-6">
                                    <label>Posición Simulador</label>
                                    <select name="posicion" className="form-control" value={this.state.posicion} onChange={this.textInput}>
                                        <option value="DEFAULT" disabled>Seleccione..</option>
                                        <option value="0">Horizontal</option>
                                        <option value="1">Vertical</option>
                                    </select>
                                    {errors.posicion && <strong className="text-danger" style={{ fontSize: '13px' }}>{errors.posicion}</strong>}
                                </div>
                                <div className="col-md-6">
                                    <p>Color</p>
                                    <div style={styles.swatch} onClick={this.handleClick}>
                                        <div style={styles.color} />
                                    </div>
                                    {this.state.displayColorPicker ? <div style={styles.popover}>
                                        <div style={styles.cover} onClick={this.handleClose} />
                                        <SketchPicker color={this.state.color} onChange={this.handleChange} />
                                    </div> : null}
                                </div>
                            </div>
                        </div>
                        <div className="pt-2 mt-5 modal-footer w-100">
                            <button type="button" className="btn btn-primary px-5" onClick={this.insertarDatos}>Guardar nuevo &nbsp; <MDBIcon icon="save" /></button>
                        </div>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className="listado_banner pt-5">
                            <p>Seleccionar producto</p>
                            <ul>
                                {simuladorData.map((e, i) => {
                                    let view;
                                    if (e.estado === 1) {
                                        view = <MDBIcon onClick={() => { this.inactivar(e.idsimuladorprod) }} icon="eye" size="lg" style={{ color: 'black', position: 'absolute', right: '-38px', marginTop: '5px' }} />
                                    } else {
                                        view = <MDBIcon onClick={() => { this.activar(e.idsimuladorprod) }} icon="eye-slash" size="lg" style={{ color: 'black', position: 'absolute', right: '-38px', marginTop: '5px' }} />
                                    }
                                    return [
                                        <li key={i} id={e.idsimuladorprod} onClick={() => { this.busquedaId(e) }}>{e.nombprodutra} {view}</li>
                                    ]
                                })}
                            </ul>
                        </div>
                        <div className="justify-content-center d-flex">
                            <div className="text-center">
                                {estadoImagen ? (
                                    <FileUploaderUpdate ref={this.uploader} ruta={editrutaImg} estadoImg={estadoCambioImg1} FCambiar={this.cambioImagen1} />
                                ) : (
                                    <React.Fragment></React.Fragment>
                                )}
                            </div>
                        </div>
                        <div className="container mt-5 pt-5 pb-5">
                            <div className="row">
                                <div className="col-md-6">
                                    {estadoImagen1 ? (
                                        <FileUploaderIcon ref={this.uploader1} ruta={editrutaiconosimul} estadoImg={estadoCambioImg} FCambiar={this.cambioImagen} />
                                    ) : (
                                        <React.Fragment></React.Fragment>
                                    )}
                                </div>
                                <div className="col-md-6">
                                    <MDBInput label="Nombre Producto" disabled={true} name="editnombprodutra" type="text" value={editnombprodutra} onChange={this.textInput} />
                                </div>
                                <div className="col-md-6 mt-3">
                                    <MDBInput label="Descripción" type="textarea" name="editdescripcion" value={editdescripcion} onChange={this.textInput} />
                                </div>
                                <div className="col-md-6">
                                    <label>Posición Simulador</label>
                                    <select name="editposicion" className="form-control" value={editposicion} onChange={this.textInput}>
                                        <option value="DEFAULT" disabled>Seleccione..</option>
                                        <option value="0">Horizontal</option>
                                        <option value="1">Vertical</option>
                                    </select>
                                </div>
                                <div className="col-md-6">
                                    <p>Color</p>
                                    <div style={styles.swatch} onClick={this.handleClick}>
                                        <div style={styles.color} />
                                    </div>
                                    {this.state.displayColorPicker ? <div style={styles.popover}>
                                        <div style={styles.cover} onClick={this.handleClose} />
                                        <SketchPicker color={this.state.color} onChange={this.handleChange} />
                                    </div> : null}
                                </div>
                            </div>
                        </div>
                        <div className="pt-2 mt-5 modal-footer w-100">
                            <button type="buttom" className="btn btn-success px-5" onClick={() => { this.actualizarDatos(this.state.idSeleccionado) }}>Guardar cambios &nbsp; <MDBIcon icon="save" /></button>
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

export default S_Crédito;