// Se realiza copia y pega de noticias y se envia solo el codigo que indica que es de eventos
// queda pendiente por ajustar el nombre de variables y constantes
import React, { Component } from 'react';
import { MDBIcon, MDBInput, MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import Swal from 'sweetalert2';
import { URLImgFront } from '../../../../config/URLDB';
import { CargueImagenes, NoticiasEventosDetalles_ } from '../../../../services/WebAPI_Utrahuilca';
import FileUploaderB from './FileUploader/FileUploaderB';
import FileUploader from './FileUploader/FileUploader';

const validate = values => {
    const errors = {}
    if (values.prioridadnotidet === "DEFAULT") {
        errors.prioridadnotidet = 'campo obligatorio'
    }
    if (!values.titulonotidet) {
        errors.titulonotidet = 'campo obligatorio'
    }
    if (!values.descripnotidet) {
        errors.descripnotidet = 'campo obligatorio'
    }
    if (!values.fecinipublinotidet) {
        errors.fecinipublinotidet = 'campo obligatorio'
    }
    if (!values.fecfinpublinotidet) {
        errors.fecfinpublinotidet = 'campo obligatorio'
    }
    return errors;
};

class Eventos extends Component {
    uploader = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            estadoNuevo: false,
            errors: {},
            estadoCambioImg1: true,
            estadoSinInfo: false,
            infoNoticiasDetalles: [],
            idnotidet: null,
            prioridadnotidet: "DEFAULT",
            imgnotidet: '',
            titulonotidet: '',
            descripnotidet: '',
            fecinipublinotidet: '',
            fecfinpublinotidet: '',
            estadoCambio: false,
            estado: null,
            urlnotidet: '',
            idDetallesNoticias: 0
        };
    }

    async componentDidMount() {
        this.cargarInformacion();
    }

    cargarInformacion = () => {
        const data = {
            tran: 6,
            idnotidet: 0,
            codcoopsejos: 1,
            titulonotidet: 'NA',
            descripnotidet: 'NA',
            urlnotidet: 'NA',
            imgnotidet: 'NA',
            fecinipublinotidet: '1900/01/01',
            fecfinpublinotidet: '1900/01/01',
            idtiponoticia: 0,
            prioridadnotidet: 0,
            estado: 0,
            usucrea: 'NA'
        }
        NoticiasEventosDetalles_(data).then(response => {
            try {
                if (response.data.length > 0) {
                    if (!response.data[0].message) {
                        this.setState({
                            idSeleccionado: response.data[0].idnotidet
                        })
                        setTimeout(() => {
                            this.setState({
                                infoNoticiasDetalles: response.data,
                                imgnotidet: response.data[0].imgnotidet,
                                estado: response.data[0].estado,
                                estadoSinInfo: true,
                                estadoImagen: true,
                            })
                        }, 120);
                    } else {
                        this.setState({
                            estadoSinResultados: false,
                            estadoNuevo: true,
                            estadoCambioImg1: true,
                            estadoSinInfo: false
                        })
                    }
                } else { }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    text: 'Ocurrio un error al consumir el servicio, comuniquese con sistemas!'
                })
            }
        });
    }

    textInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    cambioImagen1 = (name, file) => {
        this.setState({
            estadoCambioImg1: true
        });
    }

    cargarImagen = () => {
        var formData = new FormData();
        if (undefined === this.uploader.current) return;
        formData.append("file", this.uploader.current.getFileObject());
        formData.append("rutaImagen", '/Coopsejos/Eventos Detalles/' + this.uploader.current.getFileName());
        CargueImagenes(formData).then(response => {
            if (response) {
                this.cargarInformacion();
            }
        });
    }

    insertarDatos = () => {
        const { errors, ...sinErrors } = this.state;
        const result = validate(sinErrors);
        this.setState({ errors: result });
        if (!Object.keys(result).length) {
            // formulario validado
            const data = {
                tran: 1,
                idnotidet: 0,
                codcoopsejos: 1,
                imgnotidet: '/Coopsejos/Eventos Detalles/' + this.uploader.current.getFileName(),
                titulonotidet: this.state.titulonotidet,
                descripnotidet: this.state.descripnotidet,
                fecinipublinotidet: new Date(this.state.fecinipublinotidet + 'T12:00:00Z'),
                fecfinpublinotidet: new Date(this.state.fecfinpublinotidet + 'T12:00:00Z'),
                idtiponoticia: 4,
                prioridadnotidet: parseInt(this.state.prioridadnotidet),
                urlnotidet: this.state.urlnotidet === '' ? 'N/A' : this.state.urlnotidet,
                estado: 1,
                usucrea: sessionStorage.getItem('idusuario')
            }
            NoticiasEventosDetalles_(data).then(response => {
                try {
                    if (response.data.length > 0) {
                        if (response.data[0].message === "000000") {
                            this.cargarImagen();
                            Swal.fire({
                                icon: 'success',
                                title: 'Se agrego correctamente.'
                            })
                            this.setState({
                                estadoNuevo: false,
                                idnotidet: null,
                                codcoopsejos: null,
                                imgnotidet: '',
                                titulonotidet: '',
                                descripnotidet: '',
                                fecinipublinotidet: '',
                                fecfinpublinotidet: '',
                                estado: null,
                                estadoImagen: false,
                                displayColorPicker: false,
                                rutafondoImg: '',
                                color: '',
                                urlnotidet: '',
                                tipoVista: "DEFAULT",
                                prioridadnotidet: "DEFAULT"
                            })
                            this.cargarInformacion();
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Error al insertar.'
                            })
                        }
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Ocurrio un error!'
                        })
                    }
                } catch (error) {
                    Swal.fire({
                        icon: 'warning',
                        text: 'Ocurrio un error, valide haber completado los campos!'
                    })
                }
            });
        }
    }

    editar = (id) => {
        var seleccionado = [];
        this.state.infoNoticiasDetalles.map((e, i) => {
            if (e.idnotidet === id) {
                seleccionado.push(e)
            }
            return [];
        })
        this.setState({
            idnotidet: seleccionado[0].idnotidet,
            codcoopsejos: seleccionado[0].codcoopsejos,
            prioridadnotidet: seleccionado[0].prioridadnotidet,
            urlnotidet: seleccionado[0].urlnotidet,
            titulonotidet: seleccionado[0].titulonotidet,
            descripnotidet: seleccionado[0].descripnotidet,
            fecinipublinotidet: seleccionado[0].fecinipublinotidet,
            fecfinpublinotidet: seleccionado[0].fecfinpublinotidet,
            imgnotidet: seleccionado[0].imgnotidet,
            estadoEditar: true
        })
    }

    detallesNoticia = (id) => {
        this.setState({
            tablaNoticiasDetalles: true,
            idDetallesNoticias: id
        })
    }

    cambio = (name, file) => {
        this.setState({
            estadoCambio: true
        });
    }

    actualizarDatos = (id) => {
        var ruta = '';
        var capNombre = this.uploader.current.getFileName();
        if (capNombre === undefined || capNombre === null) {
            ruta = this.state.imgnotidet;
        } else {
            ruta = '/Coopsejos/Eventos Detalles/' + this.uploader.current.getFileName();
        }
        const data = {
            tran: 1,
            idnotidet: id,
            codcoopsejos: 1,
            imgnotidet: ruta,
            titulonotidet: this.state.titulonotidet,
            descripnotidet: this.state.descripnotidet,
            fecinipublinotidet: this.state.fecinipublinotidet,
            fecfinpublinotidet: this.state.fecfinpublinotidet,
            idtiponoticia: 4,
            prioridadnotidet: parseInt(this.state.prioridadnotidet),
            urlnotidet: this.state.urlnotidet === '' ? 'N/A' : this.state.urlnotidet,
            estado: 1,
            usucrea: sessionStorage.getItem('idusuario')
        }
        NoticiasEventosDetalles_(data).then(response => {
            try {
                if (response.data.length > 0) {
                    if (response.data[0].message === "000000") {
                        this.cargarImagen();
                        this.setState({
                            estadoCambio: false,
                            estadoEditar: false,
                            imgnotidet: '',
                            titulonotidet: '',
                            descripnotidet: '',
                            fecinipublinotidet: '',
                            fecfinpublinotidet: '',
                            urlnotidet: '',
                            prioridadnotidet: "DEFAULT"
                        })
                        Swal.fire({
                            icon: 'success',
                            title: 'Se actualizo satisfactoriamente'
                        })
                        this.cargarInformacion();
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Ocurrio un error al actualizar'
                        })
                    }
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Ocurrio un error!'
                    })
                }
            } catch (error) {
                Swal.fire({
                    icon: 'warning',
                    text: 'Ocurrio un error, valide haber completado los campos!'
                })
            }
        });
    }

    activar = (id) => {
        const data = {
            tran: 8,
            idnotidet: id,
            codcoopsejos: 1,
            imgnotidet: 'NA',
            titulonotidet: 'NA',
            descripnotidet: 'NA',
            fecinipublinotidet: '1900/01/01',
            fecfinpublinotidet: '1900/01/01',
            idtiponoticia: 0,
            prioridadnotidet: 0,
            urlnotidet: 'NA',
            estado: 1,
            usucrea: sessionStorage.getItem('idusuario')
        }
        NoticiasEventosDetalles_(data).then(response => {
            if (response) {
                this.setState({
                    estadoS: false
                })
                Swal.fire({
                    icon: 'success',
                    title: 'Se activo satisfactoriamente'
                });
                this.cargarInformacion();
            }
        });
    }

    inactivar = (id) => {
        const data = {
            tran: 8,
            idnotidet: id,
            codcoopsejos: 1,
            imgnotidet: 'NA',
            titulonotidet: 'NA',
            descripnotidet: 'NA',
            fecinipublinotidet: '1900/01/01',
            fecfinpublinotidet: '1900/01/01',
            idtiponoticia: 0,
            prioridadnotidet: 0,
            urlnotidet: 'NA',
            estado: 0,
            usucrea: sessionStorage.getItem('idusuario')
        }
        NoticiasEventosDetalles_(data).then(response => {
            if (response) {
                this.setState({
                    estadoS: false
                })
                Swal.fire({
                    icon: 'success',
                    title: 'Se inactivo satisfactoriamente'
                });
                this.cargarInformacion();
            }
        });
    }

    handleNew = () => {
        this.setState({
            estadoNuevo: !this.state.estadoNuevo,
            errors: {},
            imgnotidet: '',
            titulonotidet: '',
            descripnotidet: '',
            fecinipublinotidet: '',
            fecfinpublinotidet: '',
            urlnotidet: '',
            tablaNoticiasDetalles: false
        })
        if (this.state.estadoNuevo) {
            this.setState({
                errors: {},
                imgnotidet: '',
                titulonotidet: '',
                descripnotidet: '',
                fecinipublinotidet: '',
                fecfinpublinotidet: '',
                urlnotidet: '',
                tablaNoticiasDetalles: false
            })
            this.cargarInformacion();
        }
    }

    render() {
        const { estadoNuevo, estadoCambioImg1, estadoCambio, estadoSinInfo, titulonotidet, errors, descripnotidet, urlnotidet, fecinipublinotidet, fecfinpublinotidet, estadoEditar, idnotidet, infoNoticiasDetalles } = this.state;
        return (
            <React.Fragment>
                <div className="pb-5 mb-5 pt-3">
                    <h5 className="font-weight-bold">Eventos</h5>
                    <hr />
                    {estadoSinInfo ? (
                        <React.Fragment>
                            <div className="plus_add_agencia_2 align-items-center d-flex" onClick={() => { this.handleNew() }}>
                                {estadoNuevo ? <p>Cancelar &nbsp;<MDBIcon icon="times" size="lg" /></p> : <p>Agregar Nuevo &nbsp;<MDBIcon icon="plus-circle" size="lg" /></p>}
                            </div>
                        </React.Fragment>
                    ) : (
                        <React.Fragment></React.Fragment>
                    )}
                    {this.state.tablaNoticiasDetalles ? (
                        <React.Fragment>
                            <div onClick={() => { this.setState({ tablaNoticiasDetalles: false }) }}>
                                <p className="cancelar">Cancelar &nbsp;<MDBIcon icon="times" size="lg" /></p>
                            </div>
                        </React.Fragment>
                    ) : (<React.Fragment>
                        {estadoNuevo ? (
                            <React.Fragment>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-12 my-2 text-center">
                                            <FileUploaderB ref={this.uploader} estadoImg={estadoCambioImg1} FCambiar={this.cambioImagen1} />
                                        </div>
                                        <div className="col-md-6 my-2">
                                            <label>Prioridad</label>
                                            <select name="prioridadnotidet" className="form-control" value={this.state.prioridadnotidet} onChange={this.textInput}>
                                                <option value="DEFAULT">Seleccione...</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                            </select>
                                            {errors.prioridadnotidet && <strong className="text-danger" style={{ fontSize: '13px' }}>{errors.prioridadnotidet}</strong>}
                                        </div>
                                        <div className="col-md-6 my-2">
                                            <MDBInput label="Titulo" name="titulonotidet" type="text" value={titulonotidet} onChange={this.textInput} />
                                            {errors.titulonotidet && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '48px' }}>{errors.titulonotidet}</strong>}
                                        </div>
                                        <div className="col-md-6 my-2">
                                            <MDBInput label="Descripción" name="descripnotidet" type="textarea" value={descripnotidet} onChange={this.textInput} />
                                            {errors.descripnotidet && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '55px' }}>{errors.descripnotidet}</strong>}
                                        </div>
                                        <div className="col-md-6 my-2">
                                            <MDBInput label="Fecha Inicial de Publicación" name="fecinipublinotidet" type="date" value={fecinipublinotidet} onChange={this.textInput} />
                                            {errors.fecinipublinotidet && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '50px' }}>{errors.fecinipublinotidet}</strong>}
                                        </div>
                                        <div className="col-md-6 my-2">
                                            <MDBInput label="Fecha Final de Publicación" name="fecfinpublinotidet" type="date" value={fecfinpublinotidet} onChange={this.textInput} />
                                            {errors.fecfinpublinotidet && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '50px' }}>{errors.fecfinpublinotidet}</strong>}
                                        </div>
                                        <div className="col-md-6 my-2">
                                            <label>Ruta direccionamiento</label>
                                            <MDBInput label="opcional" name="urlnotidet" type="text" value={urlnotidet} onChange={this.textInput} />
                                        </div>
                                    </div>
                                </div>
                                <div className="pt-2 mt-5 modal-footer w-100">
                                    <button type="button" className="btn btn-primary px-5" onClick={this.insertarDatos}>Crear Producto &nbsp; <MDBIcon icon="save" /></button>
                                </div>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <div className="container-fluid">
                                    <div className="table-responsive text-center w-100">
                                        <MDBTable>
                                            <MDBTableHead>
                                                <tr className="font-weight-bold">
                                                    <td style={{ width: '50px !important' }}>Imagen</td>
                                                    <td>Prioridad Evento</td>
                                                    <td>Titulo Evento</td>
                                                    <td>Descripción Evento</td>
                                                    <td>Fecha Inicial del Evento</td>
                                                    <td>Fecha Final del Evento</td>
                                                    <td>Ruta direccionamiento</td>
                                                    {estadoEditar ?
                                                        (
                                                            <React.Fragment>

                                                                <td>Guardar</td>
                                                                <td>Cancelar</td>
                                                            </React.Fragment>
                                                        ) : (
                                                            <React.Fragment>
                                                                <td>Editar</td>
                                                                <td>Inactivar</td>
                                                            </React.Fragment>
                                                        )}
                                                </tr>
                                            </MDBTableHead>
                                            <MDBTableBody>
                                                {estadoEditar ?
                                                    (
                                                        <React.Fragment>
                                                            <tr>
                                                                <td>
                                                                    <FileUploader ref={this.uploader} estadoImg={estadoCambio} ruta={this.state.imgnotidet} FCambiar={this.cambio} />
                                                                </td>
                                                                <td className="w-25">
                                                                    <select name="prioridadnotidet" className="form-control" value={this.state.prioridadnotidet} onChange={this.textInput}>
                                                                        <option value="DEFAULT">Seleccione...</option>
                                                                        <option value="1">1</option>
                                                                        <option value="2">2</option>
                                                                    </select>
                                                                </td>
                                                                <td className="w-25"><textarea className="form-control" type="textarea" rows="5" name="titulonotidet" value={this.state.titulonotidet} onChange={this.textInput} /></td>
                                                                <td className="w-25"><textarea className="form-control" type="textarea" rows="5" name="descripnotidet" value={this.state.descripnotidet} onChange={this.textInput} /></td>
                                                                <td className="w-25"><input className="form-control" type="date" name="fecinipublinotidet" value={this.state.fecinipublinotidet} onChange={this.textInput} /></td>
                                                                <td className="w-25"><input className="form-control" type="date" name="fecfinpublinotidet" value={this.state.fecfinpublinotidet} onChange={this.textInput} /></td>
                                                                <td className="w-25"><textarea className="form-control" type="textarea" rows="5" name="urlnotidet" value={this.state.urlnotidet} onChange={this.textInput} /></td>
                                                                <td className="text-center" onClick={() => { this.actualizarDatos(idnotidet) }}><MDBIcon icon="save" size="2x" style={{ color: 'blue', cursor: 'pointer' }} /></td>
                                                                <td className="text-center" onClick={() => { this.setState({ estadoEditar: !this.state.estadoEditar }) }}><MDBIcon icon="times" size="2x" style={{ color: 'red', cursor: 'pointer' }} /></td>
                                                            </tr>
                                                        </React.Fragment>
                                                    ) : (
                                                        <React.Fragment>
                                                            {infoNoticiasDetalles.map((e, i) => {
                                                                let view;
                                                                if (e.estado === 1) {
                                                                    view = <MDBIcon onClick={() => { this.inactivar(e.idnotidet) }} icon="eye" size="2x" style={{ cursor: 'pointer' }} />
                                                                } else {
                                                                    view = <MDBIcon onClick={() => { this.activar(e.idnotidet) }} icon="eye-slash" size="2x" style={{ cursor: 'pointer' }} />
                                                                }
                                                                return [
                                                                    <React.Fragment key={i}>
                                                                        <tr>
                                                                            <td><img width="180" src={`${URLImgFront}/assets${e.imgnotidet}`} alt={e.imgnotidet} /></td>
                                                                            <td>{e.prioridadnotidet}</td>
                                                                            <td>{e.titulonotidet}</td>
                                                                            <td className="w-25 text-justify">{e.descripnotidet}</td>
                                                                            <td>{e.fecinipublinotidet}</td>
                                                                            <td>{e.fecfinpublinotidet}</td>
                                                                            <td>{e.urlnotidet}</td>
                                                                            <td className="text-center" onClick={() => { this.editar(e.idnotidet) }}><MDBIcon icon="edit" size="2x" style={{ color: 'green', cursor: 'pointer' }} /></td>
                                                                            <td className="text-center">{view}</td>
                                                                        </tr>
                                                                    </React.Fragment>
                                                                ]
                                                            })}
                                                        </React.Fragment>
                                                    )}
                                            </MDBTableBody>
                                        </MDBTable>
                                    </div>
                                </div>
                            </React.Fragment>
                        )}
                    </React.Fragment>)}
                </div>
            </React.Fragment>
        );
    }
}

export default Eventos;