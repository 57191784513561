import React, { Component } from 'react';
import Header from '../components/Principal/Header';

class Error404 extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    render() {
        return (
            <React.Fragment>
                <Header id={2} />
                <div className="container">
                    <div className="d-flex justify-content-center align-items-center error404">
                        <div className="text-center">
                            <h1 className="titleError">Vaya, encontró nuestra página 404</h1>
                            <p className="subtitle">Esto no es una falla, sólo un accidente no intencional. Sin embargo, dudamos que esta sea la página que estás buscando y nos disculpamos por ello.</p>
                            <div className="d-flex justify-content-center mt-25 mt-30-sm mt-40-xxl">
                                <a className="hb hb--primary hb--255 hb--h60" data-offset="30" id="-header-cta-get_started" href="/">Ir a la página de inicio</a>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Error404;