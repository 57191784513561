import React, { Component } from 'react';
import './Style.css';
import './SimulatorResponsive.css';
import { MDBIcon, MDBInput } from 'mdbreact';
import { ProductosSimula_, Simulacion_ } from '../../services/WebAPI_Utrahuilca';
import { URLImgFront } from '../../config/URLDB';
import FormAsesoria from '../Formularios/FormAsesoria';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import Loading from '../Loading';

const recaptchaRef = React.createRef();

const formatterPeso = new Intl.NumberFormat('es-CO', {
    currency: 'COP',
    minimumFractionDigits: 0
})

const validate = values => {
    const errors = {}
    if (!values.monto) {
        errors.monto = 'campo obligatorio'
    }
    if (!values.plazo) {
        errors.plazo = 'campo obligatorio'
    }
    return errors;
};

class Simula extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            monto: '',
            plazo: '',
            montoSimulado: '',
            plazoSimulado: '',
            stateContent: true,
            stateDollar: false,
            simuladorData: [],
            rutaImg: '',
            descripcion: '',
            color: '',
            nombprodutra: '',
            stateBVertical: false,
            stateBHorizontal: false,
            stateCampos: false,
            stateLoadign: false
        };
    }

    componentDidMount() {
        this.active();
        this.cargarInformacion();
    }

    cargarInformacion = () => {
        const nameP = this.props.match.params.name;
        if (nameP === "Ahorros" || nameP === "Credito") {
            const data = {
                tran: 7,
                idprodutra: 0,
                nombprodutra: 'NA',
                descprodutra: 'NA',
                rutaimgprod: 'NA',
                rutavistaprod: 'NA',
                prolinea: 0,
                idtipoprod: nameP === 'Ahorros' ? 1 : 2,
                idtipopers: 1,
                estado: 1,
                usucrea: 'NA'
            }
            ProductosSimula_(data).then(response => {
                try {
                    if (response.data.length > 0) {
                        if (response.data.length >= 1) {
                            if (response.data[0].message === "004404") {
                                this.setState({
                                    stateSimu: false
                                })
                            } else {
                                response.data.map((e) => {
                                    if (e.posicion === 1) {
                                        this.setState({
                                            stateBVertical: true
                                        })
                                    }
                                    if (e.posicion === 0) {
                                        this.setState({
                                            stateBHorizontal: true
                                        })
                                    }
                                    return [];
                                })
                                this.setState({
                                    simuladorData: response.data,
                                    idprodutra: response.data[0].idprodutra,
                                    prolinea: response.data[0].prolinea,
                                    rutaImg: response.data[0].rutafondosimul,
                                    descripcion: response.data[0].descsimulador,
                                    color: response.data[0].color,
                                    nombprodutra: response.data[0].nombprodutra,
                                    stateImg: true,
                                    stateSimu: true,
                                    idPintar: response.data[0].idsimuladorprod
                                })
                                if (response.data[0].idprodutra === 12) {
                                    this.setState({
                                        stateCampos: true
                                    })
                                } else {
                                    this.setState({
                                        stateCampos: false
                                    })
                                }
                                document.getElementById('' + response.data[0].idsimuladorprod + '').style.filter = "drop-shadow(8px 8px 10px " + response.data[0].color + ")";
                                document.getElementById('' + response.data[0].idsimuladorprod + '').style.fontWeight = "bold";
                            }
                        } else {
                            this.setState({
                                stateSimu: false
                            })
                        }
                    } else {

                    }
                } catch (error) {
                    console.log(error);
                }
            });
        } else {
            this.props.history.push('/404')
        }
    }

    active = () => {
        var header = document.getElementById("list_productos");
        var btns = header.getElementsByClassName("li");
        for (var i = 0; i < btns.length; i++) {
            btns[i].addEventListener("click", function () {
                var current = document.getElementsByClassName("active");
                current[0].className = current[0].className.replace(" active", "");
                this.className += " active";
            });
        }
    }

    handleChange = async e => {
        e.persist();
        await this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        })
    }

    toggleImg(text) {
        this.setState({
            nameLinea: text,
            stateSolicitud: 'none'
        })
    }

    textInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onSubmit = () => {
        const recaptchaValue = recaptchaRef.current.getValue();
        this.props.onSubmit(recaptchaValue);
    }

    show_box = () => {
        let box = document.getElementById("box")
        box.className += " show";
        box.classList.remove("hide");
        this.setState({
            stateContent: !this.state.stateContent
        })
    }

    hide_box = () => {
        let box = document.getElementById("box")
        box.className += " hide";
        box.classList.remove("show");
        this.setState({
            stateContent: !this.state.stateContent
        })
        this.cargarInformacion();
    }

    formatearMiles = (e) => {
        var num = e.target.value.replace(/\./g, '');
        if (e.target.value === "") {
            this.setState({
                stateDollar: false
            })
        } else {
            this.setState({
                stateDollar: true
            })
        }
        if (!isNaN(num)) {
            num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.');
            num = num.split('').reverse().join('').replace(/^[.]/, '');
            this.setState({
                monto: num
            })
        }
        else {
            this.setState({
                monto: e.target.value.replace(/[^\d.]*/g, '')
            })
        }
    }

    loadData = (data) => {
        this.setState({
            stateCampos: false
        })
        if (data.idprodutra === 12) {
            this.setState({
                stateCampos: true,
                plazo: ''
            })
        } else {
            this.setState({
                stateCampos: false,
                plazo: ''
            })
        }
        document.getElementById('' + this.state.idPintar + '').style.filter = "none";
        document.getElementById('' + this.state.idPintar + '').style.fontWeight = "normal";
        document.getElementById('' + data.idsimuladorprod + '').style.filter = "drop-shadow(8px 8px 10px " + data.color + ")";
        document.getElementById('' + data.idsimuladorprod + '').style.fontWeight = "bold";
        this.setState({
            rutaImg: data.rutafondosimul,
            idprodutra: data.idprodutra,
            prolinea: data.prolinea,
            descripcion: data.descsimulador,
            color: data.color,
            nombprodutra: data.nombprodutra,
            idPintar: data.idsimuladorprod,
            monto: '',
            plazo: '',
            montoSimulado: '',
            plazoSimulado: '',
            cuota: '',
            tasa_e_anual: '',
            tasa_n_anual: '',
            interes_ahorro: '',
            gmf: '',
            valor_renta: '',
            stateDollar: false
        })
    }

    simularDatos = () => {
        this.setState({
            montoSimulado: '',
            plazoSimulado: '',
            cuota: '',
            tasa_e_anual: '',
            tasa_n_anual: '',
            interes_ahorro: '',
            gmf: '',
            valor_renta: ''
        })
        const { errors, ...sinErrors } = this.state;
        const result = validate(sinErrors);
        this.setState({ errors: result });
        if (!Object.keys(result).length) {
            this.setState({
                stateLoadign: true
            })
            // formulario validado
            var nameP = this.props.match.params.name;
            const data = {
                prolinea: this.state.prolinea,
                monto: parseFloat(this.state.monto.replace(/\./g, '')),
                plazo: parseInt(this.state.plazo),
                tipoprod: nameP === 'Ahorros' ? 1 : 2
            }
            Simulacion_(data).then(response => {
                try {
                    if (response.data[0].codresp) {
                        this.setState({
                            stateLoadign: false
                        })
                        Swal.fire({
                            icon: 'warning',
                            text: 'Petició denegada ' + response.data[0].codresp
                        })
                    }
                    else {
                        this.setState({
                            montoSimulado: formatterPeso.format(response.data[0].monto),
                            plazoSimulado: response.data[0].plazo,
                            cuota: formatterPeso.format(response.data[0].cuota),
                            tasa_n_anual: response.data[0].tasanoanual,
                            tasa_e_anual: response.data[0].tasaefanual,
                            interes_ahorro: formatterPeso.format(response.data[0].interesahorro),
                            gmf: formatterPeso.format(response.data[0].gmf),
                            valor_renta: formatterPeso.format(response.data[0].valorRfte),
                            stateLoadign: false
                        })
                    }
                } catch (error) {
                    this.setState({
                        stateLoadign: false
                    })
                    console.log(error);
                }
            });
        }
    }

    render() {
        const { errors } = this.state;
        return (
            <React.Fragment>
                <div className="simulador">
                    <div className="simulador_items">
                        {this.state.stateImg ? (
                            <img className="img-fluid img-fondo-credito" src={`${URLImgFront}/assets${this.state.rutaImg}`} alt={this.state.rutaImg} />
                        ) : (
                            <React.Fragment></React.Fragment>
                        )}
                        {this.state.stateContent ? (
                            <React.Fragment>
                                <div className="contenedor">
                                    <div className="lista" id="list_productos">
                                        <MDBIcon icon="angle-left" className="icon-left" style={{ display: 'none' }} />
                                        <Link to="/" className="diseño_U">
                                            <img className="img-fluid img-logo-u" src={`${URLImgFront}/assets/logo/logoU.png`} alt="logoU.png" />
                                        </Link>
                                        {this.state.stateBVertical ? (
                                            <React.Fragment>
                                                <ul className="barra_vertical">
                                                    {this.state.simuladorData.map((e, i) => {
                                                        if (e.posicion === 1) {
                                                            return [
                                                                <React.Fragment key={i}>
                                                                    <li onClick={() => { this.loadData(e) }} className="li mt-1" id={e.idsimuladorprod}>
                                                                        <div>
                                                                            <img style={{ width: '55px', height: '55px' }} className="img-fluid" src={`${URLImgFront}/assets${e.rutaiconosimul}`} alt={e.nombprodutra} />
                                                                            <p>{e.nombprodutra}</p>
                                                                        </div>

                                                                    </li>
                                                                </React.Fragment>
                                                            ]
                                                        } else {
                                                            return [
                                                                <React.Fragment key={i}></React.Fragment>
                                                            ]
                                                        }
                                                    })}
                                                </ul>
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment></React.Fragment>
                                        )}
                                        {this.state.stateBHorizontal ? (
                                            <React.Fragment>
                                                <ul className="barra_horizontal">
                                                    {this.state.simuladorData.map((e, i) => {
                                                        if (e.posicion === 0) {
                                                            return [
                                                                <React.Fragment key={i}>
                                                                    <li onClick={() => { this.loadData(e) }} className="li mt-1" id={e.idsimuladorprod}>
                                                                        <img style={{ width: '55px', height: '55px' }} className="img-fluid" id={e.idsimuladorprod} src={`${URLImgFront}/assets${e.rutaiconosimul}`} alt={e.nombprodutra} />
                                                                        <p>{e.nombprodutra}</p>
                                                                    </li>
                                                                </React.Fragment>
                                                            ]
                                                        } else {
                                                            return [
                                                                <React.Fragment key={i}></React.Fragment>
                                                            ]
                                                        }
                                                    })}
                                                </ul>
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment></React.Fragment>
                                        )}
                                        <MDBIcon icon="angle-right" className="icon-right" style={{ display: 'none' }} />
                                    </div>
                                    <div className="contenedor_formulario">
                                        <ul className="h">
                                            <li>
                                                <div className="form">
                                                    <div className="pb-2" >
                                                        <h6 style={{ color: this.state.color }}>Simulador de {this.props.match.params.name} {this.state.nombprodutra}</h6>
                                                        <button onClick={this.show_box} className="btn font-weight-bold btn_s_superior" style={{ display: 'none', background: '' + this.state.color + '', color: '#fff', fontSize: '15px' }}>Solicitar&nbsp;</button>
                                                    </div>
                                                    <div className="form_data text-center pt-4">
                                                            <MDBInput size="ms" label="Monto a solicitar" onKeyUp={this.formatearMiles} onChange={this.textInput} name="monto" value={this.state.monto} outline className="pt-3 pl-4 font-weight-bold" style={{ border: '1px solid #d9dada' }} />{this.state.stateDollar ? <MDBIcon icon="dollar-sign" style={{ position: 'absolute', left: '34px', marginTop: '-67px' }} /> : <div></div>}
                                                        {errors.monto && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', marginTop: '-42px', left: '25px' }}>{errors.monto}</strong>}
                                                        <MDBInput size="ms" label={this.state.stateCampos ? "Plazo en días" : "Plazo en meses"} outline onChange={this.textInput} value={this.state.plazo} name="plazo" className="pt-3 font-weight-bold" style={{ border: '1px solid #d9dada' }} />
                                                        {errors.plazo && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', marginTop: '-42px', left: '25px' }}>{errors.plazo}</strong>}
                                                        <button onClick={this.simularDatos} className="btn font-weight-bold px-5" style={{ border: '1px solid ' + this.state.color + '', color: '' + this.state.color + '', fontSize: '15px', borderRadius: '20px' }}>Simular</button>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="descr pb-0">
                                                <div className="card pb-0">
                                                    <div className="card-body pb-0">
                                                        <p>{this.state.descripcion}</p>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                        <ul className="i">
                                            <li>
                                                <div className="card bg-transparent">
                                                    <div style={{ zIndex: '1', background: '' + this.state.color + '', opacity: '.1', width: '100%', height: '100%', position: 'absolute' }}></div>
                                                    <div className="card-body" style={{ zIndex: '3' }}>
                                                        <div className="row">
                                                            <div className="col-md-5">
                                                                <p>Monto: <MDBIcon icon="dollar-sign" style={{ color: '' + this.state.color + '' }} /> <b>{this.state.montoSimulado}</b></p>
                                                                <p>Plazo: <b>{this.state.plazoSimulado}</b></p>
                                                                {this.state.stateCampos ? (<React.Fragment>
                                                                    <p>Interes de Ahorro: <MDBIcon icon="dollar-sign" style={{ color: '' + this.state.color + '' }} /><b> {this.state.interes_ahorro}</b></p>
                                                                </React.Fragment>) : (<React.Fragment>
                                                                    <p>Cuota: <MDBIcon icon="dollar-sign" style={{ color: '' + this.state.color + '' }} /> <b>{this.state.cuota}</b></p>
                                                                </React.Fragment>)}
                                                                <p>Tasa efectiva anual: <b>{this.state.tasa_e_anual}</b> <MDBIcon icon="percent" style={{ color: '' + this.state.color + '' }} /></p>
                                                                <p>Tasa nominal anual: <b>{this.state.tasa_n_anual}</b> <MDBIcon icon="percent" style={{ color: '' + this.state.color + '' }} /></p>
                                                            </div>
                                                            {this.state.stateCampos ? (
                                                                <React.Fragment>
                                                                    <div className="col-md-7">
                                                                        <p>GMF: <MDBIcon icon="dollar-sign" style={{ color: '' + this.state.color + '' }} /><b> {this.state.gmf}</b></p>
                                                                        <p>Valor Retefuente: <MDBIcon icon="dollar-sign" style={{ color: '' + this.state.color + '' }} /><b> {this.state.valor_renta}</b></p>
                                                                    </div>
                                                                </React.Fragment>
                                                            ) : (<React.Fragment></React.Fragment>)}
                                                        </div>
                                                        <button onClick={this.show_box} className="btn font-weight-bold btn_s_inferior" style={{ background: '' + this.state.color + '', color: '#fff', fontSize: '15px', position: 'absolute', right: '0', bottom: '90px' }}>Solicitar&nbsp; </button>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </React.Fragment>
                        ) : (
                            <React.Fragment></React.Fragment>
                        )}
                    </div>
                    <div className="solicitud" id="box">
                        <div onClick={this.hide_box} className="cerrar"><p style={{ color: '' + this.state.color + '', cursor: 'pointer' }}><MDBIcon icon="arrow-down" size="3x" className=" pt-5" /></p></div>
                        <div className="container d-flex align-items-center form py-5" style={{ height: '100vh', overflow: 'hidden', overflowY: 'scroll' }}>
                            <div className="row pl-3" style={{ paddingTop: '500px' }}>
                                <div className="col-sm-12 py-2" style={{ color: '' + this.state.color + '', fontWeight: 'bold' }}>
                                    <h5>Solicitud Asesoria de {this.props.match.params.name} <b>{this.state.nombprodutra}</b></h5>
                                    <hr style={{ background: '' + this.state.color + '' }} />
                                </div>
                                <FormAsesoria color={this.state.color} idprodserv={this.props.match.params.name} />
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.stateLoadign ? (
                    <Loading />
                ) : (<React.Fragment></React.Fragment>)}
            </React.Fragment>
        );
    }
}

export default Simula;