import React, { Component } from 'react';
import { Comunicados_ } from '../../services/WebAPI_Utrahuilca';
import Swal from 'sweetalert2';
import { URLImgFront } from '../../config/URLDB';

class Seccion1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stateImg: false,
        };
    }

    async componentDidMount() {
        const data = {
            tran: 7,
            idvtacomunicado: 0,
            nombvtacomunic: 'NA',
            rutaimgfondocomun: 'NA',
            txtcomunicado1: 'NA',
            txtcomunicado2: 'NA',
            colortexto: 'NA',
            estado: 1,
            usucrea: 'NA'
        }
        Comunicados_(data).then(response => {
            try {
                if (response.data.length > 0) {
                    this.setState({
                        idvtacomunicado: response.data[0].idvtacomunicado,
                        nombvtacomunic: response.data[0].nombvtacomunic,
                        rutaimgfondocomun: response.data[0].rutaimgfondocomun,
                        txtcomunicado1: response.data[0].txtcomunicado1,
                        txtcomunicado2: response.data[0].txtcomunicado2,
                        colortexto: response.data[0].colortexto,
                        stateImg: true
                    });
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Ocurrio un error!'
                    })
                }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    text: 'Ocurrio un error al consumir el servicio, comuniquese con sistemas!'
                })
            }
        });
    }

    render() {
        const { stateImg } = this.state;
        return (
            <React.Fragment>
                <div className="seccion_transaccional_diseño" style={{ width: '100%', paddingTop: '120px' }}>
                    {stateImg ?
                        (
                            <img style={{ width: '100%', height: '40vh', objectFit: 'cover'  }} className="img-fluid" src={`${URLImgFront}/assets${this.state.rutaimgfondocomun}`} alt={this.state.rutaimgfondocomun} />
                        ) : (
                            <React.Fragment></React.Fragment>
                        )}
                    <div className="contenido_text_tra text-center" style={{ position: 'absolute', top: '280px', left: '0', right: '0', zIndex: '2' }}>
                        <p className="texto1" style={{ fontSize: '20px', color: '' + this.state.colortexto + '' }}>{this.state.txtcomunicado1}</p>
                        <p className="texto2" style={{ fontSize: '40px', color: '' + this.state.colortexto + '', fontWeight: 'bold' }}>{this.state.txtcomunicado2}</p>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Seccion1;