import React, { Component } from 'react';
import '../responsive/Convenios.css';
import Seccion1 from '../components/Convenios/Seccion1';
import Seccion2 from '../components/Convenios/Seccion2';
import MensajeF from '../components/Mensaje_Flotante/MensajeF';
import Footer from '../components/Principal/Footer';
import Header from '../components/Principal/Header';
import {withRouter} from 'react-router-dom';

class Convenios extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <React.Fragment>
                <Header id={2} />
                <Seccion1 name={this.props.match.params.name} />
                <MensajeF />
                <Seccion2 name={this.props.match.params.name} />
                <Footer />
            </React.Fragment>
        );
    }
}

export default withRouter(Convenios);