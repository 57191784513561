import React, { Component } from 'react';
import './style.css';
import PersonaJuridica from './Seccion2/PersonaJuridica';
import PersonaNatural from './Seccion2/PersonaNatural';
//import { SeccionPNaturalJuridica_ } from '../../services/WebAPI_Utrahuilca';
import { SeccionPMaestroCoopVirtual_ } from '../../services/WebAPI_Utrahuilca';
import { URLImgFront } from '../../config/URLDB';
//import { MDBIcon } from 'mdbreact';
//import { Link } from 'react-router-dom';
import './Seccion2/Recurso/Accordion.css';

class Seccion2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            estado: true,
            PerNatJur: [],
            stateImg: false
        };
    }

    async componentDidMount() {
        this.ConsumeData(1);
    }

    ConsumeData = (id) => {
        //const data = {
        //    tran: 7,
        //    idcoopvirtdet: 0,
        //    idtipopers: id,
        //    rutaimgdeta: 'NA',
        //    titcoopvirtdet: 'NA',
        //    desccoopvirtdet: 'NA',
        //    piecoopvirtdet: 'NA',
        //    manejaenlace: 1,
        //    rutaenlace: 'NA',
        //    estado: 1,
        //    usucrea: 'NA',
        //    tipocargue: 0
        //}

        this.setState({ PerNatJur: [], estado: true });
        if (id === 0) { this.setState({ estado: false }) }

        const data = {
            tran: 7,
            idmaestrocoopv: 0,
            idtipopers: id,
            titulo: 'NA',
            rutaiconotit: 'NA',
            descripcion: 'NA',
            estado: 1,
            idusucrea: 0
        }
        //SeccionPNaturalJuridica_(data).then(response => {
        SeccionPMaestroCoopVirtual_(data).then(response => {
            try {
                if (response.data.length > 0) {
                    if (response.data.length >= 1) {
                        if (response.data[0].message === "004404") {
                            this.setState({
                                stateImg: false
                            })
                        } else {
                            this.setState({
                                PerNatJur: response.data,
                                stateImg: true
                            });
                        }
                    } else {
                        this.setState({
                            stateImg: false
                        })
                    }
                }
            } catch (error) {
                console.log(error);
            }
        });
    }
    
    render() {
        const { estado, stateImg } = this.state;
        return (
            <React.Fragment>
                <div className="contenido_seccion_tra" style={{ width: '100%' }}>
                    {/*<div style={{ background: '#fff' }}>*/}
                    {/*    <div className="container">*/}
                    {/*        <div className="row py-4">*/}
                    {/*            <div className="col-md-12 my-2">*/}
                    {/*                <div className="d-flex align-items-center">*/}
                    {/*                    <img style={{ width: '130px', height: '130px' }} className="img-fluid" src={`${URLImgFront}/assets/img-variadas/LOGO RED COOP.png`} alt="LOGO RED COOP" />*/}
                    {/*                    <div>*/}
                    {/*                        <p className="text-dark pl-4 mt-2 text-justify">Sí aún no está registrado en la cooperativa virtual, acérquese a la agencia de UTRAHUILCA más cercana, actualice sus datos y realice el registro con su asesor de confianza; si ya actualizó sus datos durante el último año y no cuenta con una agencia a su alcance, realice el siguiente paso: <br />*/}
                    {/*                            <Link to="/Solicitud_Multiportal" className="link_virtual" style={{ borderBottom: '1px solid #000', color: '#000' }}>Solicitar registro en MULTIPORTAL</Link>*/}
                    {/*                            <a href="https://youtu.be/SxUZplEQbxA" target="_blank" style={{ borderBottom: '1px solid', marginLeft: '10px' }}>Ver Video Instructivo</a>*/}
                    {/*                        </p>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}

                    {/*            <div className="col-md-12 my-2 mb-2">*/}
                    {/*                <div className="d-flex align-items-center">*/}
                    {/*                    <img style={{ width: '130px', height: '130px' }} className="img-fluid" src={`${URLImgFront}/assets/img-variadas/LOGO COOP VIRTUAL.png`} alt="LOGO COOP VIRTUAL" />*/}
                    {/*                    <div>*/}
                    {/*                        <p className="text-dark pl-4 mt-2 mb-0 text-justify">Debe tener correo electrónico actualizado y correcto, ya que este será el medio que se usará para la confirmación al momento del registro.</p>*/}
                    {/*                        <p className="text-dark pl-4 mt-0 text-justify">Si no ha actualizado su información, por favor diríjase a la agencia más cercana. <br />*/}
                    {/*                            <a rel="noopener noreferrer" className="link_virtual" style={{ borderBottom: '1px solid #000', color: '#000' }} href="https://utraportal.utrahuilca.coop:8484/Registro" target={'_blank'}>Solicitar registro en UTRAPORTAL</a>*/}
                    {/*                            <a href="https://youtu.be/XaaXtRkpQBY" target="_blank" style={{ borderBottom: '1px solid', marginLeft: '10px' }}>Ver Video Instructivo</a>*/}
                    {/*                        </p>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className="container-fluid pt-5 pb-3" style={{ background: '#f7f7f7' }}>
                        <div className="row px-3">
                            <div className="col-md-12 my-2 pb-4 d-flex justify-content-center">
                                <div className="px-5">
                                    <img className="imagen_personas img-fluid" src={estado ? `${URLImgFront}/assets/img-variadas/utra_personas_focus.png` : `${URLImgFront}/assets/img-variadas/utra_personas.png`} alt="PERSONAS NATURALES" onClick={() => { this.ConsumeData(1) }} />
                                    <p className="texto-contenido font-weight-bold" onClick={() => { this.ConsumeData(1) }} style={estado ? { borderBottom: '3px solid #FA7500', cursor: 'pointer', textAlign: 'center' } : { borderBottom: 'none', cursor: 'pointer', textAlign: 'center' }}>PERSONAS</p>
                                </div>
                                <div className="px-5">
                                    <img className="imagen_personas img-fluid" src={estado ? `${URLImgFront}/assets/img-variadas/utra_empresas.png` : `${URLImgFront}/assets/img-variadas/utra_empresas_focus.png`} alt="PERSONAS JURIDICAS" onClick={() => { this.ConsumeData(0) }} />
                                    <p className="texto-contenido font-weight-bold" onClick={() => { this.ConsumeData(0) }} style={estado ? { borderBottom: 'none', cursor: 'pointer', textAlign: 'center' } : { borderBottom: '3px solid #FA7500   ', cursor: 'pointer', textAlign: 'center' }}>EMPRESAS</p>
                                </div>
                            </div>
                            {estado ?
                                (
                                    <PersonaNatural data={this.state.PerNatJur} stateImg={stateImg} />
                                ) : (
                                    <PersonaJuridica data={this.state.PerNatJur} stateImg={stateImg} />
                                )}
                        </div>
                    </div>

                    {/*<div className="container-fluid px-0 contenido_imagen_inferior">*/}
                    {/*    <img style={{ height: '385px !important' }} className="img-fluid" style={{ width: '100%', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', }} src={`${URLImgFront}/assets/img_principal/Imagen_PSE.jpg`} alt="IMAGEN PSE" />*/}
                    {/*    <div className="d-flex justify-content-center text-right section-pse btn-pse_st" style={{ position: 'absolute', right: '10%', marginTop: '-10%', zIndex: '9999' }}>*/}
                    {/*        <Link to="/PSE">*/}
                    {/*            <span className="mt-5 font-weight-bold px-5 text-white" style={{ fontSize: '17px', borderRadius: '20px', background: '#FA7500', cursor: 'pointer', padding: '2px' }}>Pagar por PSE</span>*/}
                    {/*        </Link>*/}
                    {/*    </div>*/}
                    {/*    <div className="d-flex justify-content-center text-center section-pse btn-pse_st pt-3" style={{ position: 'absolute', right: '12%', marginTop: '-9%', zIndex: '9999' }}>*/}
                    {/*        <a href="https://youtu.be/UROFl10uicI" target="_blank" className="link_virtual" style={{ borderBottom: '1px solid #000', color: '#000' }}>Ver Instructivo</a>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </React.Fragment>
        );
    }
}

export default Seccion2;