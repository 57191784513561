import React, { Component } from 'react';
import { MDBIcon, MDBInput } from 'mdbreact';
import FileUploaderImg1 from './DetallesCampañas/FileUploaderImg1';
import FileUploaderImg2 from './DetallesCampañas/FileUploaderImg2';
import FileUploaderImg3 from './DetallesCampañas/FileUploaderImg3';
import FileUploaderImg4 from './DetallesCampañas/FileUploaderImg4';
import { CargueImagenes, DetallesCampañasPrincipal_, SliderPrincipal_ } from '../../../services/WebAPI_Utrahuilca';
import Swal from 'sweetalert2';

const validate = values => {
    const errors = {}
    if (values.idsliderprinc === 'DEFAULT') {
        errors.idsliderprinc = 'campo obligatorio'
    }
    if (!values.nombcampcomer) {
        errors.nombcampcomer = 'campo obligatorio'
    }
    if (!values.descimgicono1) {
        errors.descimgicono1 = 'campo obligatorio'
    }
    if (!values.descimgicono2) {
        errors.descimgicono2 = 'campo obligatorio'
    }
    if (!values.descimgicono3) {
        errors.descimgicono3 = 'campo obligatorio'
    }
    if (!values.descobjetcamp) {
        errors.descobjetcamp = 'campo obligatorio'
    }
    return errors;
};

class DetallesCampañas extends Component {
    uploader1 = React.createRef();
    uploader2 = React.createRef();
    uploader3 = React.createRef();
    uploader4 = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            estadoNuevo: false,
            errors: {},
            infoSliderPrincipal: [],
            infoCampañas: [],
            estadoCambioImg1: true,
            estadoCambioImg1c: false,
            estadoCambioImg2: true,
            estadoCambioImg2c: false,
            estadoCambioImg3: true,
            estadoCambioImg3c: false,
            estadoCambioImg4: true,
            estadoCambioImg4c: false,
            idsliderprinc: 'DEFAULT',
            idsliderprinc2: null,
            idvtacampcomer: null,
            estadoImagen: false,
            nombsliderprinc: '',
            nombcampcomer: '',
            rutaimgicon1: '',
            descimgicono1: '',
            rutaimgicon2: '',
            descimgicono2: '',
            rutaimgicon3: '',
            descimgicono3: '',
            descobjetcamp: '',
            rutaimgperfil: '',
            editnombcampcomer: '',
            editrutaimgicon1: '',
            editdescimgicono1: '',
            editrutaimgicon2: '',
            editdescimgicono2: '',
            editrutaimgicon3: '',
            editdescimgicono3: '',
            editdescobjetcamp: '',
            editrutaimgperfil: '',
        }
    }

    componentDidMount() {
        this.cargarSelect();
        this.cargarInformacion();
    }

    cargarSelect = () => {
        const data = {
            tran: 10,
            id: 0,
            nombreImagen: 'NA',
            rutaModal: 'NA',
            texto1: 'NA',
            texto2: 'NA',
            texto3: 'NA',
            estado: 1,
            idtipoenlace: 0,
            rutatipoenlace: 'NA',
            posiciontexto: 0,
            colortexto: "NA",
            usucrea: 'NA'
        }
        SliderPrincipal_(data).then(response => {
            this.setState({
                infoSliderPrincipal: response.data,
            })
        });
    }

    cargarInformacion = () => {
        const data = {
            tran: 7,
            idvtacampcomer: 0,
            idsliderprinc: 0,
            nombcampcomer: 'NA',
            rutaimgicon1: 'NA',
            descimgicono1: 'NA',
            rutaimgicon2: 'NA',
            descimgicono2: 'NA',
            rutaimgicon3: 'NA',
            descimgicono3: 'NA',
            descobjetcamp: 'NA',
            rutaimgperfil: 'NA',
            usucrea: 'NA'
        }
        DetallesCampañasPrincipal_(data).then(response => {
            try {
                if (response.data.length > 0) {
                    if (response.data[0].message === "004404") {
                        this.setState({
                            estadoNuevo: true
                        })
                    } else {
                        if (response.data.length >= 1) {
                            this.setState({
                                idSeleccionado: response.data[0].idvtacampcomer
                            })
                            setTimeout(() => {
                                this.setState({
                                    infoCampañas: response.data,
                                    nombsliderprinc: response.data[0].nombsliderprinc,
                                    editnombcampcomer: response.data[0].nombcampcomer,
                                    editrutaimgicon1: response.data[0].rutaimgicon1,
                                    editdescimgicono1: response.data[0].descimgicono1,
                                    editrutaimgicon2: response.data[0].rutaimgicon2,
                                    editdescimgicono2: response.data[0].descimgicono2,
                                    editrutaimgicon3: response.data[0].rutaimgicon3,
                                    editdescimgicono3: response.data[0].descimgicono3,
                                    editdescobjetcamp: response.data[0].descobjetcamp,
                                    editrutaimgperfil: response.data[0].rutaimgperfil,
                                    idsliderprinc: response.data[0].idsliderprinc,
                                    estadoImagen: true,
                                    estadoNuevo: false
                                })
                                document.getElementById('' + response.data[0].idvtacampcomer + '').style.background = "#f47216";
                                document.getElementById('' + response.data[0].idvtacampcomer + '').style.color = "#fff";
                                document.getElementById('' + response.data[0].idvtacampcomer + '').style.fontWeight = "bold";
                            }, 20);
                        } else {
                            this.setState({
                                estadoNuevo: true
                            })
                        }
                    }
                } else { }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    text: 'Ocurrio un error al consumir el servicio, comuniquese con sistemas!'
                })
            }
        });
    }

    cambioImagen1 = (name, file) => {
        this.setState({
            estadoCambioImg1c: true
        });
    }

    cambioImagen2 = (name, file) => {
        this.setState({
            estadoCambioImg2c: true
        });
    }

    cambioImagen3 = (name, file) => {
        this.setState({
            estadoCambioImg3c: true
        });
    }

    cambioImagen4 = (name, file) => {
        this.setState({
            estadoCambioImg4c: true
        });
    }

    textInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    cargarImagen = () => {
        var formData = new FormData();
        var ruta1 = '';
        var ruta2 = '';
        var ruta3 = '';
        var ruta4 = '';
        var capNombre1 = this.uploader1.current.getFileName();
        var capNombre2 = this.uploader2.current.getFileName();
        var capNombre3 = this.uploader3.current.getFileName();
        var capNombre4 = this.uploader4.current.getFileName();
        
        if (capNombre1 === undefined || capNombre1 === null || capNombre1 === "") {
            ruta1 = this.state.editrutaimgicon1;
        } else {
            ruta1 = '/Principal/Detalles Campañas/' + this.uploader1.current.getFileName();
            formData.append("file", this.uploader1.current.getFileObject());
            formData.append("rutaImagen", ruta1);
        }
        if (capNombre2 === undefined || capNombre2 === null || capNombre1 === "") {
            ruta2 = this.state.editrutaimgicon2;
        } else {
            ruta2 = '/Principal/Detalles Campañas/' + this.uploader2.current.getFileName();
            formData.append("file", this.uploader2.current.getFileObject());
            formData.append("rutaImagen", ruta2);
        }
        if (capNombre3 === undefined || capNombre3 === null || capNombre1 === "") {
            ruta3 = this.state.editrutaimgicon3;
        } else {
            ruta3 = '/Principal/Detalles Campañas/' + this.uploader3.current.getFileName();
            formData.append("file", this.uploader3.current.getFileObject());
            formData.append("rutaImagen", ruta3);
        }
        if (capNombre4 === undefined || capNombre4 === null || capNombre1 === "") {
            ruta4 = this.state.editrutaimgperfil;
        } else {
            ruta4 = '/Principal/Detalles Campañas/' + this.uploader4.current.getFileName();
            formData.append("file", this.uploader4.current.getFileObject());
            formData.append("rutaImagen", ruta4);
        }
        CargueImagenes(formData).then(response => {
            if (response) {
                this.cargarInformacion();
            }
        });
    }

    insertarDatos = () => {
        const { errors, ...sinErrors } = this.state;
        const result = validate(sinErrors);
        this.setState({ errors: result });
        if (!Object.keys(result).length) {
            // formulario validado
            const data = {
                tran: 1,
                idvtacampcomer: 0,
                idsliderprinc: this.state.idsliderprinc,
                nombcampcomer: this.state.nombcampcomer,
                rutaimgicon1: '/Principal/Detalles Campañas/' + this.uploader1.current.getFileName(),
                descimgicono1: this.state.descimgicono1,
                rutaimgicon2: '/Principal/Detalles Campañas/' + this.uploader2.current.getFileName(),
                descimgicono2: this.state.descimgicono2,
                rutaimgicon3: '/Principal/Detalles Campañas/' + this.uploader3.current.getFileName(),
                descimgicono3: this.state.descimgicono3,
                descobjetcamp: this.state.descobjetcamp,
                rutaimgperfil: '/Principal/Detalles Campañas/' + this.uploader4.current.getFileName(),
                usucrea: sessionStorage.getItem('idusuario')
            }
            DetallesCampañasPrincipal_(data).then(response => {
                if (response.data.length > 0) {
                    if (response.data[0].message === "000000") {
                        this.cargarImagen();
                        Swal.fire({
                            icon: 'success',
                            title: response.data[0].message
                        })
                        this.setState({
                            estadoNuevo: false,
                            editnombcampcomer: '',
                            editrutaimgicon1: '',
                            editdescimgicono1: '',
                            editrutaimgicon2: '',
                            editdescimgicono2: '',
                            editrutaimgicon3: '',
                            editdescimgicono3: '',
                            editdescobjetcamp: '',
                            editrutaimgperfil: '',
                        })
                        this.cargarInformacion();
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: response.data[0].message
                        })
                    }
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Ocurrio un error!'
                    })
                }
            });
        }
    }

    actualizarDatos = (id) => {
        var ruta1 = '';
        var ruta2 = '';
        var ruta3 = '';
        var ruta4 = '';
        var capNombre1 = this.uploader1.current.getFileName();
        var capNombre2 = this.uploader2.current.getFileName();
        var capNombre3 = this.uploader3.current.getFileName();
        var capNombre4 = this.uploader4.current.getFileName();
        if (capNombre1 === undefined || capNombre1 === null) {
            ruta1 = this.state.editrutaimgicon1;
        } else {
            ruta1 = '/Principal/Detalles Campañas/' + this.uploader1.current.getFileName();
        }
        if (capNombre2 === undefined || capNombre2 === null) {
            ruta2 = this.state.editrutaimgicon2;
        } else {
            ruta2 = '/Principal/Detalles Campañas/' + this.uploader2.current.getFileName();
        }
        if (capNombre3 === undefined || capNombre3 === null) {
            ruta3 = this.state.editrutaimgicon3;
        } else {
            ruta3 = '/Principal/Detalles Campañas/' + this.uploader3.current.getFileName();
        }
        if (capNombre4 === undefined || capNombre4 === null) {
            ruta4 = this.state.editrutaimgperfil;
        } else {
            ruta4 = '/Principal/Detalles Campañas/' + this.uploader4.current.getFileName();
        }
        var idSlider = 0;
        if (this.state.idsliderprinc2 === null) {
            idSlider = this.state.idsliderprinc;
        } else {
            idSlider = parseInt(this.state.idsliderprinc2);
        }
        const data = {
            tran: 1,
            idvtacampcomer: id,
            idsliderprinc: idSlider,
            nombcampcomer: this.state.editnombcampcomer,
            rutaimgicon1: ruta1,
            descimgicono1: this.state.editdescimgicono1,
            rutaimgicon2: ruta2,
            descimgicono2: this.state.editdescimgicono2,
            rutaimgicon3: ruta3,
            descimgicono3: this.state.editdescimgicono3,
            descobjetcamp: this.state.editdescobjetcamp,
            rutaimgperfil: ruta4,
            usucrea: sessionStorage.getItem('idusuario')
        }
        DetallesCampañasPrincipal_(data).then(response => {
            if (response.data.length > 0) {
                if (response.data[0].message === "000000") {
                    this.cargarImagen();
                    this.setState({
                        estadoCambioImg: false,
                    })
                    Swal.fire({
                        icon: 'success',
                        title: 'Se actualizo correctamente.'
                    })
                    document.getElementById('' + this.state.idSeleccionado + '').style.background = "#fff";
                    document.getElementById('' + this.state.idSeleccionado + '').style.color = "#000";
                    document.getElementById('' + this.state.idSeleccionado + '').style.fontWeight = "normal";
                    this.cargarInformacion();
                    this.cargarSelect();
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Ocurrio un error al actualizar.'
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Ocurrio un error!'
                })
            }
        });
    }

    busquedaId = (id) => {
        document.getElementById('' + this.state.idSeleccionado + '').style.background = "#fff";
        document.getElementById('' + this.state.idSeleccionado + '').style.color = "#000";
        document.getElementById('' + this.state.idSeleccionado + '').style.fontWeight = "normal";
        document.getElementById('' + id + '').style.background = "#f47216";
        document.getElementById('' + id + '').style.color = "#fff";
        document.getElementById('' + id + '').style.fontWeight = "bold";
        this.setState({
            estadoCambioImg1c: false,
            estadoCambioImg2c: false,
            estadoCambioImg3c: false,
            estadoCambioImg4c: false,
            est: !this.state.est,
            idvtacampcomer: id
        })
        const data = {
            tran: 6,
            idvtacampcomer: id,
            idsliderprinc: 0,
            nombcampcomer: 'NA',
            rutaimgicon1: 'NA',
            descimgicono1: 'NA',
            rutaimgicon2: 'NA',
            descimgicono2: 'NA',
            rutaimgicon3: 'NA',
            descimgicono3: 'NA',
            descobjetcamp: 'NA',
            rutaimgperfil: 'NA',
            usucrea: 'NA'
        }
        DetallesCampañasPrincipal_(data).then(response => {
            if (response.data.length > 0) {
                this.setState({
                    idSeleccionado: response.data[0].idvtacampcomer,
                    editnombcampcomer: response.data[0].nombcampcomer,
                    editrutaimgicon1: response.data[0].rutaimgicon1,
                    editdescimgicono1: response.data[0].descimgicono1,
                    editrutaimgicon2: response.data[0].rutaimgicon2,
                    editdescimgicono2: response.data[0].descimgicono2,
                    editrutaimgicon3: response.data[0].rutaimgicon3,
                    editdescimgicono3: response.data[0].descimgicono3,
                    editdescobjetcamp: response.data[0].descobjetcamp,
                    editrutaimgperfil: response.data[0].rutaimgperfil,
                    idsliderprinc: response.data[0].idsliderprinc,
                    nombsliderprinc: response.data[0].nombsliderprinc,
                })
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'No hay información'
                })
            }
        });
    }
    handleNew = () => {
        this.setState({
            estadoNuevo: !this.state.estadoNuevo,
            errors: {},
        })
        if (this.state.estadoNuevo) {
            this.setState({
                errors: {},
            })
            this.cargarInformacion();
        }
    }

    render() {
        const { estadoNuevo, infoSliderPrincipal, infoCampañas, estadoImagen, errors } = this.state;
        return (
            <React.Fragment>
                <p className="font-weight-bold">Detalles Campañas</p>
                <hr />
                <div className="plus_add_agencia align-items-center d-flex" onClick={() => { this.handleNew() }}>
                    {estadoNuevo ? <p>Cancelar &nbsp;<MDBIcon icon="times" size="lg" /></p> : <p>Crear Campaña &nbsp;<MDBIcon icon="plus-circle" size="lg" /></p>}
                </div>
                {estadoNuevo ? (
                    <React.Fragment>
                        <div className="container py-3">
                            <div className="row">
                                <div className="col-md-6">
                                    <MDBInput label="Nombre de la Campaña" name="nombcampcomer" type="text" value={this.state.nombcampcomer} onChange={this.textInput} />
                                    {errors.nombcampcomer && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '50px' }}>{errors.nombcampcomer}</strong>}
                                </div>
                                <div className="col-md-6">
                                    <label>Seleccione Slider</label>
                                    <select name="idsliderprinc" className="form-control" value={this.state.idsliderprinc} onChange={this.textInput}>
                                        <option value="DEFAULT" disabled>Seleccione..</option>
                                        {infoSliderPrincipal.map((e, i) => {
                                            return [
                                                <option value={e.idsliderprinc} key={i}>{e.nombsliderprinc}</option>
                                            ]
                                        })}
                                    </select>
                                    {errors.idsliderprinc && <strong className="text-danger" style={{ fontSize: '13px' }}>{errors.idsliderprinc}</strong>}
                                </div>
                                <div className="col-md-4">
                                    <div className="text-center">
                                        <FileUploaderImg1 ref={this.uploader1} estadoImg={this.state.estadoCambioImg1} FCambiar={this.cambioImagen1} />
                                        <MDBInput label="Texto 1" type="text" name="descimgicono1" value={this.state.descimgicono1} onChange={this.textInput} />
                                        {errors.descimgicono1 && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', marginTop: '-30px', left: '15px' }}>{errors.descimgicono1}</strong>}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="text-center">
                                        <FileUploaderImg2 ref={this.uploader2} estadoImg={this.state.estadoCambioImg2} FCambiar={this.cambioImagen2} />
                                        <MDBInput label="Texto 2" type="text" name="descimgicono2" value={this.state.descimgicono2} onChange={this.textInput} />
                                        {errors.descimgicono2 && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', marginTop: '-30px', left: '15px' }}>{errors.descimgicono2}</strong>}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="text-center">
                                        <FileUploaderImg3 ref={this.uploader3} estadoImg={this.state.estadoCambioImg3} FCambiar={this.cambioImagen3} />
                                        <MDBInput label="Texto 3" type="text" name="descimgicono3" value={this.state.descimgicono3} onChange={this.textInput} />
                                        {errors.descimgicono3 && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', marginTop: '-30px', left: '15px' }}>{errors.descimgicono3}</strong>}
                                    </div>
                                </div>
                                <div className="col-md-12 mt-5">
                                    <div className="row">
                                        <div className="col-md-6 text-center">
                                            <FileUploaderImg4 ref={this.uploader4} estadoImg={this.state.estadoCambioImg4} FCambiar={this.cambioImagen4} />
                                        </div>
                                        <div className="col-md-6 pt-5">
                                            <MDBInput label="Descripcion campaña" rows="10" type="textarea" name="descobjetcamp" value={this.state.descobjetcamp} onChange={this.textInput} />
                                            {errors.descobjetcamp && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', marginTop: '-30px' }}>{errors.descobjetcamp}</strong>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="pt-2 mt-5 modal-footer w-100">
                            <button type="button" className="btn btn-primary px-5" onClick={this.insertarDatos}>Crear Campaña &nbsp; <MDBIcon icon="save" /></button>
                        </div>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className="listado_banner">
                            <p>Campañas</p>
                            <ul>
                                {infoCampañas.map((e, i) => {
                                    return [
                                        <li key={i} id={e.idvtacampcomer} onClick={() => { this.busquedaId(e.idvtacampcomer) }}>{e.nombcampcomer}</li>
                                    ]
                                })}
                            </ul>
                        </div>
                        <div className="container">
                            <div className="row">
                                {estadoImagen ? (
                                    <React.Fragment>
                                        <div className="col-md-6">
                                            <MDBInput label="Nombre de la Campaña" type="text" name="editnombcampcomer" onChange={this.textInput} value={this.state.editnombcampcomer} />
                                        </div>
                                        <div className="col-md-6">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label>Slider Seleccionado</label>
                                                    <input type="text" name="nombsliderprinc" disabled className="bg-light form-control" value={this.state.nombsliderprinc} />
                                                </div>
                                                <div className="col-md-6">
                                                    <label>Seleccione Slider</label>
                                                    <select name="idsliderprinc2" className="form-control" defaultValue={'DEFAULT'} onChange={this.textInput}>
                                                        <option value="DEFAULT" disabled>Seleccione..</option>
                                                        {infoSliderPrincipal.map((e, i) => {
                                                            return [
                                                                <option value={e.idsliderprinc} key={i}>{e.nombsliderprinc}</option>
                                                            ]
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="text-center">
                                                <FileUploaderImg1 ref={this.uploader1} ruta={this.state.editrutaimgicon1} estadoImg={this.state.estadoCambioImg1c} FCambiar={this.cambioImagen1} />
                                                <MDBInput label="Texto 1" type="text" name="editdescimgicono1" onChange={this.textInput} value={this.state.editdescimgicono1} />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="text-center">
                                                <FileUploaderImg2 ref={this.uploader2} ruta={this.state.editrutaimgicon2} estadoImg={this.state.estadoCambioImg2c} FCambiar={this.cambioImagen2} />
                                                <MDBInput label="Texto 2" type="text" name="editdescimgicono2" onChange={this.textInput} value={this.state.editdescimgicono2} />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="text-center">
                                                <FileUploaderImg3 ref={this.uploader3} ruta={this.state.editrutaimgicon3} estadoImg={this.state.estadoCambioImg3c} FCambiar={this.cambioImagen3} />
                                                <MDBInput label="Texto 3" type="text" name="editdescimgicono3" onChange={this.textInput} value={this.state.editdescimgicono3} />
                                            </div>
                                        </div>
                                        <div className="col-md-12 mt-5">
                                            <div className="row">
                                                <div className="col-md-6 text-center">
                                                    <FileUploaderImg4 ref={this.uploader4} ruta={this.state.editrutaimgperfil} estadoImg={this.state.estadoCambioImg4c} FCambiar={this.cambioImagen4} />
                                                </div>
                                                <div className="col-md-6 pt-5">
                                                    <MDBInput label="Descripcion campaña" type="textarea" rows="10" name="editdescobjetcamp" onChange={this.textInput} value={this.state.editdescobjetcamp} />
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment></React.Fragment>
                                )}
                            </div>
                        </div>
                        <div className="pt-2 mt-5 modal-footer w-100">
                            <button type="button" className="btn btn-success px-5" onClick={() => { this.actualizarDatos(this.state.idSeleccionado) }}>Guardar cambios &nbsp; <MDBIcon icon="save" /></button>
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

export default DetallesCampañas;
