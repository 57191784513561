import React, { Component } from 'react';
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.min.css';
import Iframes from '../Iframes/Iframes';
import { CargarPrincNuestrosAsociados_ } from '../../services/WebAPI_Utrahuilca';

class N_Asociados extends Component {
    constructor(props) {
        super(props);
        this.state = {
            infoPrincNuestAsociados: [],
            infocargdatos: false
        };
    }

    async componentDidMount() {
        this.cargarInfNuestAsociados();
    }

    cargarInfNuestAsociados = () => {
        CargarPrincNuestrosAsociados_().then(response => {
            try {
                if (response.data.length > 0) {
                    if (response.data[0].message === "004404") {
                        this.setState({ infoPrincNuestAsociados: [], infocargdatos: false });
                    } else {
                        this.setState({ infoPrincNuestAsociados: response.data, infocargdatos: true });
                    }
                }
            } catch (error) {
                console.log('Sin registro de videos');
            }
        });
    }

    render() {
        const params = {
            spaceBetween: 30,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            }
        }
        return (
            <React.Fragment>
                <div className="mb-5 pb-5" style={{ width: '100%' }}>
                    <div className="container">
                        <div className="row pb-2">
                            <div className="col-md-12 text-center d-flex justify-content-center">
                                <p className="mt-3 font-weight-bold py-2 w-25 text-dark" style={{ fontSize: '20px' }}>Nuestros Asociados</p>
                            </div>
                        </div>
                       
                        {this.state.infocargdatos ? (<React.Fragment>
                            <Swiper {...params} className="rounded pb-5">
                                {this.state.infoPrincNuestAsociados.map((e, i) => {
                                    return [
                                        <div className="d-flex justify-content-center">
                                            <Iframes ancho={'800px'} alto={'400px'} url={e.enlacevideo} />
                                        </div>
                                    ]
                                })}
                            </Swiper>
                        </React.Fragment>) : (<React.Fragment></React.Fragment>)}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default N_Asociados;