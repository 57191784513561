import React, { Component } from 'react';
import { MDBIcon } from 'mdbreact';

class BAsociado extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    render() {
        return (
            <React.Fragment>
                <p className="texto-titulos text-left text-justify py-4">Beneficios asociados</p>
                <p className="text-justify"><MDBIcon icon="chevron-right" style={{ color: '#3f8592' }} /> Ser propietario(a) de una de las Cooperativas de Ahorro y Crédito más grandes del país.</p>
                <p className="text-justify"><MDBIcon icon="chevron-right" style={{ color: '#3f8592' }} /> Ser gestor(a) cooperativo(a) a través de la participación en las actividades democráticas realizadas por la cooperativa.</p>
                <p className="text-justify"><MDBIcon icon="chevron-right" style={{ color: '#3f8592' }} /> Contribuir directamente al desarrollo económico y social de la región Surcolombiana.</p>
                <p className="text-justify"><MDBIcon icon="chevron-right" style={{ color: '#3f8592' }} /> Facilidad de acceso a los productos de ahorro y crédito que se ajustan a sus necesidades.</p>
                <p className="text-justify"><MDBIcon icon="chevron-right" style={{ color: '#3f8592' }} /> Para asociados (as) infantiles y juveniles, facilidad en el acceso a los diferentes productos de ahorro sección amiguitos y acceso a escuelas de formación cooperativa, artísticas, deportivas y de artes oficios a través de Fundautrahuilca.</p>
                <p className="text-justify"><MDBIcon icon="chevron-right" style={{ color: '#3f8592' }} /> Subsidios totales y parciales de costos financieros en los productos de ahorro y crédito.</p>
                <p className="text-justify"><MDBIcon icon="chevron-right" style={{ color: '#3f8592' }} /> Subsidio de apoyo solidario para el (los) beneficiario (s), en caso del fallecimiento del asociado (a) titular.</p>
                <p className="text-justify"><MDBIcon icon="chevron-right" style={{ color: '#3f8592' }} /> Tarifas especiales en adquisición de seguros con LA EQUIDAD SEGUROS.</p>
                <p className="text-justify"><MDBIcon icon="chevron-right" style={{ color: '#3f8592' }} /> Tarifas preferenciales y Subsidio hasta el 100% en su previsión exequial en convenio con EMCOFUN/LOS OLIVOS.</p>
                <p className="text-justify"><MDBIcon icon="chevron-right" style={{ color: '#3f8592' }} /> Acceder a las tarifas preferenciales en productos y servicios a través de los diferentes convenios empresariales de beneficios e interinstitucionales suscritos por UTRAHUILCA.</p>
                <p className="text-justify"><MDBIcon icon="chevron-right" style={{ color: '#3f8592' }} /> Ahorro en el costo administrativo en su empresa a través de la suscripción de convenios empresariales (Pago de Salarios, Descuento por libranza y Dispersión de pagos) sin ningún costo.</p>
                <p className="text-justify"><MDBIcon icon="chevron-right" style={{ color: '#3f8592' }} /> Ahorro en gastos financieros y disminución de riesgo por custodia de efectivo en su empresa gracias a la suscripción de convenios de SERVIRECAUDO sin ningún costo, para recaudo de sus cuotas de administración, Matriculas, Pensiones, Servicios públicos, entre otros.</p>
                <p className="text-justify"><MDBIcon icon="chevron-right" style={{ color: '#3f8592' }} /> Más puntos de atención en las Agencias Mini-Básicas y Extensiones de caja UTRAHUILCA, donde podrá acceder a información del Portafolio de Productos, Servicios y Beneficios, haciendo uso de ellos sin costo alguno y en horarios adicionales.</p>
                <p className="text-justify"><MDBIcon icon="chevron-right" style={{ color: '#3f8592' }} /> Participación en los planes de estímulos en ahorro y crédito diseñados para los asociados (as).</p>
                <p className="text-justify"><MDBIcon icon="chevron-right" style={{ color: '#3f8592' }} /> Participación en los programas sociales y culturales que organiza FUNDAUTRAHUILCA.</p>
                <p className="text-justify"><MDBIcon icon="chevron-right" style={{ color: '#3f8592' }} /> Acceso a educación cooperativa y empresarial gratuita.</p>
                <p className="text-justify"><MDBIcon icon="chevron-right" style={{ color: '#3f8592' }} /> Acceso a otros canales transaccionales para realizar consignaciones, retiros, pagos de cuotas de aportes sociales/ahorro/crédito, en puntos de Su Chance, Efecty, Banco Agrario, Banco de Bogotá y corresponsales bancarios del Grupo AVAL (Tarjeta débito afinidad) y las cajas de almacenes del Grupo Éxito.</p>
            </React.Fragment>
        );
    }
}

export default BAsociado;