import { MDBIcon, MDBInput, MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import React, { Component } from 'react';
import { BeneficiosPrincipal_, CargueImagenes } from '../../../services/WebAPI_Utrahuilca';
import FileUploaderB from './Beneficios/FileUploaderB';
import Swal from 'sweetalert2';
import FileUploader from "./Beneficios/FileUploader";
import { URLImgFront } from '../../../config/URLDB';

const validate = values => {
    const errors = {}
    if (!values.titulobenef1) {
        errors.titulobenef1 = 'campo obligatorio'
    }
    if (!values.descbenef1) {
        errors.descbenef1 = 'campo obligatorio'
    }
    return errors;
};

class Beneficios extends Component {
    uploader = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            estadoNuevo: false,
            infoBeneficiosPrincipal: [],
            idbenefutra: null,
            titulobenef: '',
            titulobenef1: '',
            rutaiconobenef: '',
            descbenef: '',
            descbenef1: '',
            estado: null,
            estadoCambioImg: true,
            estadoEditar: false,
            estadoCambio: false,
            estadoSinResultados: false,
        };
    }

    componentDidMount() {
        this.cargarInformacion();
    }

    cargarInformacion = () => {
        const data = {
            tran: 4,
            idbenefutra: 0,
            titulobenef: 'NA',
            rutaiconobenef: 'NA',
            descbenef: 'NA',
            estado: 0,
            usucrea: 'NA'
        }
        BeneficiosPrincipal_(data).then(response => {
            try {
                if (response.data.length > 0) {
                    if (response.data.length >= 1) {
                        if (response.data[0].message === "004404") {
                            this.setState({
                                estadoSinResultados: false
                            })
                        } else {
                            this.setState({
                                infoBeneficiosPrincipal: response.data,
                                titulobenef: response.data[0].titulobenef,
                                idbenefutra: response.data[0].idbenefutra,
                                rutaiconobenef: response.data[0].rutaiconobenef,
                                descbenef: response.data[0].descbenef,
                                estado: response.data[0].estado,
                                estadoSinResultados: true
                            })
                        }
                    } else {
                        this.setState({
                            estadoSinResultados: false
                        })
                    }
                } else {
                    this.setState({
                        estadoNuevo: true
                    })
                }
            } catch (error) {
                console.log(error);
            }
        });
    }

    cargarImagen = () => {
        var formData = new FormData();
        if (undefined === this.uploader.current) return;
        formData.append("file", this.uploader.current.getFileObject());
        formData.append("rutaImagen", '/Principal/Beneficios/' + this.uploader.current.getFileName());
        CargueImagenes(formData).then(response => {
            if (response) {
                this.cargarInformacion();
            }
        });
    }

    insertarDatos = () => {
        const { errors, ...sinErrors } = this.state;
        const result = validate(sinErrors);
        this.setState({ errors: result });
        if (!Object.keys(result).length) {
            // formulario validado
            const data = {
                tran: 1,
                idbenefutra: 0,
                titulobenef: this.state.titulobenef1,
                rutaiconobenef: '/Principal/Beneficios/' + this.uploader.current.getFileName(),
                descbenef: this.state.descbenef1,
                estado: 1,
                usucrea: sessionStorage.getItem('idusuario')
            }
            BeneficiosPrincipal_(data).then(response => {
                if (response.data.length > 0) {
                    if (response.data[0].message === "000000") {
                        this.cargarImagen();
                        Swal.fire({
                            icon: 'success',
                            title: 'Se agrego correctamente.'
                        })
                        this.setState({
                            estadoNuevo: false,
                            titulobenef1: '',
                            rutaiconobenef: '',
                            descbenef1: ''
                        })
                        this.cargarInformacion();
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error al insertar.'
                        })
                    }
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Ocurrio un error!'
                    })
                }
            });
        }
    }

    actualizarDatos = (id) => {
        var ruta = '';
        var capNombre = this.uploader.current.getFileName();
        if (capNombre === undefined || capNombre === null) {
            ruta = this.state.rutaiconobenef;
        } else {
            ruta = '/Principal/Beneficios/' + this.uploader.current.getFileName();
        }
        const data = {
            tran: 1,
            idbenefutra: this.state.idbenefutra,
            titulobenef: this.state.titulobenef,
            rutaiconobenef: ruta,
            descbenef: this.state.descbenef,
            estado: 1,
            usucrea: sessionStorage.getItem('idusuario')
        }
        BeneficiosPrincipal_(data).then(response => {
            if (response.data.length > 0) {
                if (response.data[0].message === "000000") {
                    this.cargarImagen();
                    this.setState({
                        estadoCambioImg: false,
                        estadoEditar: false
                    })
                    Swal.fire({
                        icon: 'success',
                        title: 'Se actualizo satisfactoriamente'
                    })
                    this.cargarInformacion();
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Ocurrio un error al actualizar'
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Ocurrio un error!'
                })
            }
        });
    }

    textInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    cambioImagen = (name, file) => {
        this.setState({
            estadoCambioImg: true
        });
    }

    editar = (id) => {
        var seleccionado = [];
        this.state.infoBeneficiosPrincipal.map((e, i) => {
            if (e.idbenefutra === id) {
                seleccionado.push(e)
            }
            return [];
        })
        this.setState({
            idbenefutra: seleccionado[0].idbenefutra,
            titulobenef: seleccionado[0].titulobenef,
            descbenef: seleccionado[0].descbenef,
            rutaiconobenef: seleccionado[0].rutaiconobenef,
            estadoEditar: true
        })
    }

    cambio = (name, file) => {
        this.setState({
            estadoCambio: true
        });
    }

    activar = (id) => {
        const data = {
            tran: 7,
            idbenefutra: id,
            titulobenef: 'NA',
            rutaiconobenef: 'NA',
            descbenef: 'NA',
            estado: 1,
            usucrea: sessionStorage.getItem('idusuario')
        }
        BeneficiosPrincipal_(data).then(response => {
            if (response) {
                this.setState({
                    estadoS: false
                })
                Swal.fire({
                    icon: 'success',
                    title: 'Se activo satisfactoriamente'
                });
                this.cargarInformacion();
            }
        });
    }

    inactivar = (id) => {
        const data = {
            tran: 7,
            idbenefutra: id,
            titulobenef: 'NA',
            rutaiconobenef: 'NA',
            descbenef: 'NA',
            estado: 0,
            usucrea: sessionStorage.getItem('idusuario')
        }
        BeneficiosPrincipal_(data).then(response => {
            if (response) {
                this.setState({
                    estadoS: false
                })
                Swal.fire({
                    icon: 'success',
                    title: 'Se inactivo satisfactoriamente'
                });
                this.cargarInformacion();
            }
        });
    }

    handleNew = () => {
        this.setState({
            estadoNuevo: !this.state.estadoNuevo,
            errors: {},
        })
        if (this.state.estadoNuevo) {
            this.setState({
                errors: {},
            })
            this.cargarInformacion();
        }
    }

    render() {
        const { estadoNuevo, estadoCambioImg, estadoCambio, titulobenef1, descbenef1, infoBeneficiosPrincipal, estadoEditar, idbenefutra, estadoSinResultados, errors } = this.state;
        return (
            <React.Fragment>
                <p className="font-weight-bold">Beneficios</p>
                <hr />
                <div className="plus_add_agencia align-items-center d-flex" onClick={() => { this.handleNew() }}>
                    {estadoNuevo ? <p>Cancelar &nbsp;<MDBIcon icon="times" size="lg" /></p> : <p>Crear Beneficio &nbsp;<MDBIcon icon="plus-circle" size="lg" /></p>}
                </div>
                {estadoNuevo ? (
                    <React.Fragment>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-4 text-center">
                                    <FileUploaderB ref={this.uploader} estadoImg={estadoCambioImg} FCambiar={this.cambioImagen} />
                                </div>
                                <div className="col-md-4">
                                    <MDBInput label="Titulo Beneficio" name="titulobenef1" type="text" value={titulobenef1} onChange={this.textInput} />
                                    {errors.titulobenef1 && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '50px' }}>{errors.titulobenef1}</strong>}
                                </div>
                                <div className="col-md-4">
                                    <MDBInput label="Descripción Beneficio" name="descbenef1" type="textarea" rows="15" value={descbenef1} onChange={this.textInput} />
                                    {errors.descbenef1 && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', marginTop: '-30px' }}>{errors.descbenef1}</strong>}
                                </div>
                            </div>
                        </div>
                        <div className="pt-2 mt-5 modal-footer w-100">
                            <button type="button" className="btn btn-primary px-5" onClick={this.insertarDatos}>Crear Beneficio &nbsp; <MDBIcon icon="save" /></button>
                        </div>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className="container">
                            <div className="table-responsive text-center w-100">
                                <MDBTable>
                                    <MDBTableHead>
                                        <tr className="font-weight-bold">
                                            <td>Icono</td>
                                            <td>Titulo</td>
                                            <td>Descripción</td>
                                            {estadoEditar ?
                                                (
                                                    <React.Fragment>
                                                        <td>Guardar</td>
                                                        <td>Cancelar</td>
                                                    </React.Fragment>
                                                ) : (
                                                    <React.Fragment>
                                                        <td>Editar</td>
                                                        <td>Inactivar</td>
                                                    </React.Fragment>
                                                )}
                                        </tr>
                                    </MDBTableHead>
                                    <MDBTableBody>
                                        {estadoSinResultados ? (
                                            <React.Fragment>
                                                {estadoEditar ?
                                                    (
                                                        <React.Fragment>
                                                            <tr>
                                                                <td>
                                                                    <FileUploader ref={this.uploader} estado={estadoCambio} ruta={this.state.rutaiconobenef} FCambiar={this.cambio} />
                                                                </td>
                                                                <td className="w-25"><input className="form-control" type="text" name="titulobenef" value={this.state.titulobenef} onChange={this.textInput} /></td>
                                                                <td className="w-100"><textarea className="form-control w-100" type="textarea" rows="10" name="descbenef" value={this.state.descbenef} onChange={this.textInput} /></td>
                                                                <td className="text-center" onClick={() => { this.actualizarDatos(idbenefutra) }}><MDBIcon icon="save" size="2x" style={{ color: 'blue', cursor: 'pointer' }} /></td>
                                                                <td className="text-center" onClick={() => { this.setState({ estadoEditar: !this.state.estadoEditar }) }}><MDBIcon icon="times" size="2x" style={{ color: 'red', cursor: 'pointer' }} /></td>
                                                            </tr>
                                                        </React.Fragment>
                                                    ) : (
                                                        <React.Fragment>
                                                            {infoBeneficiosPrincipal.map((e, i) => {
                                                                let view;
                                                                if (e.estado === 1) {
                                                                    view = <MDBIcon onClick={() => { this.inactivar(e.idbenefutra) }} icon="eye" size="2x" style={{ cursor: 'pointer' }} />
                                                                } else {
                                                                    view = <MDBIcon onClick={() => { this.activar(e.idbenefutra) }} icon="eye-slash" size="2x" style={{ cursor: 'pointer' }} />
                                                                }
                                                                return [
                                                                    <React.Fragment key={i}>
                                                                        <tr>
                                                                            <td><img width="75" src={`${URLImgFront}/assets${e.rutaiconobenef}`} alt={e.rutaiconobenef} /></td>
                                                                            <td>{e.titulobenef}</td>
                                                                            <td>{e.descbenef}</td>
                                                                            <td className="text-center" onClick={() => { this.editar(e.idbenefutra) }}><MDBIcon icon="edit" size="2x" style={{ color: 'green', cursor: 'pointer' }} /></td>
                                                                            <td className="text-center">{view}</td>
                                                                        </tr>
                                                                    </React.Fragment>
                                                                ]
                                                            })}
                                                        </React.Fragment>
                                                    )}
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment>
                                                <tr className="alert alert-info text-center w-100">
                                                    <td>Sin resultados</td>
                                                </tr>
                                            </React.Fragment>
                                        )}
                                    </MDBTableBody>
                                </MDBTable>
                            </div>
                        </div>
                    </React.Fragment>
                )}

            </React.Fragment>
        );
    }
}

export default Beneficios;