import { MDBIcon } from 'mdbreact';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { URLImgFront } from '../../config/URLDB';
import { Asociados_ } from '../../services/WebAPI_Utrahuilca';

class Seccion1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stateImg: false,
            stateOri: false
        };
    }

    async componentDidMount() {
        const data = {
            tran: 7,
            idvtaasociado: 0,
            nombvtaasociado: 'NA',
            rutaimgasoci: 'NA',
            txtvtaasociado1: 'NA',
            txtvtaasociado2: 'NA',
            txtvtaasociado3: 'NA',
            estado: 1,
            usucrea: 'NA'
        }
        Asociados_(data).then(response => {
            try {
                if (response.data.length > 0) {
                    if (response.data.length >= 1) {
                        if (response.data[0].message === "004404") {
                            this.setState({
                                stateImg: false
                            })
                        } else {
                            if (response.data[0].posiciontexto === 1) {
                                this.setState({
                                    left: "5%",
                                    stateOri: true
                                })
                            } else {
                                this.setState({
                                    right: "8%",
                                    stateOri: false
                                })
                            }
                            this.setState({
                                idvtaasociado: response.data[0].idvtaasociado,
                                nombvtaasociado: response.data[0].nombvtaasociado,
                                rutaimgasoci: response.data[0].rutaimgasoci,
                                txtvtaasociado1: response.data[0].txtvtaasociado1,
                                txtvtaasociado2: response.data[0].txtvtaasociado2,
                                txtvtaasociado3: response.data[0].txtvtaasociado3,
                                stateImg: true
                            });
                        }
                    } else {
                        this.setState({
                            stateImg: false
                        })
                    }
                } else {
                    this.setState({
                        estadoNuevo: true
                    })
                }
            } catch (error) {
                console.log(error);
            }
        });
    }

    render() {
        const { stateImg } = this.state;
        return (
            <React.Fragment>
                <div className="asociados_diseño bg-white" style={{ width: '100%', paddingTop: '120px' }}>
                    <div style={{ width: '100%', height: '70vh' }}>
                        {stateImg ?
                            (
                                <img style={{ position: 'absolute', right: '240px', top: '145px', objectFit: 'cover' }} className="img-fluid img-asociados" src={`${URLImgFront}/assets${this.state.rutaimgasoci}`} alt="asociados" />
                            ) : (
                                <React.Fragment></React.Fragment>
                            )}
                    </div>
                    <div className="contenido_text_a" style={{ position: 'absolute', top: '280px', left: '160px' }}>
                        <p className="w-75 font-weight-bold texto_1" style={{ fontSize: '25px', color: '#3f8592' }}>{this.state.txtvtaasociado1}</p>
                        <p className="texto_2" style={{ fontSize: '60px', color: '#747373', fontWeight: 'bold' }}>{this.state.txtvtaasociado2}</p>
                        <p className="texto_3 font-weight-bold pb-4">{this.state.txtvtaasociado3}</p>
                        <Link to="/Beneficios">
                            <span className="px-5 py-2 mt-3" style={{ background: '#3f8592', color: 'white', fontWeight: 'bold', borderRadius: '20px', cursor: 'pointer' }}>Ver Beneficios</span>
                        </Link>
                    </div>
                </div>
                <span className="pt-4 font-weight-bold" style={{ fontSize: '20px', position: 'absolute', top: '130px', left: '10px' }}><MDBIcon icon="caret-right" className="ml-2 mr-1" /> Asociados </span>
            </React.Fragment>
        );
    }
}

export default Seccion1;