import { Link } from 'react-router-dom';
import { MDBIcon } from 'mdbreact';
import React, { Component } from 'react';
import { URLImgFront } from '../../config/URLDB';
import Llamanos from '../Mensaje_Flotante/LLamanos/Llamanos';
// import Chat from '../Mensaje_Flotante/Chat/Chat';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stateLlamanos: false,
            stateChat: false
        };
    }

    activarChat = () => {
        this.setState({
            stateContenido: !this.state.stateContenido,
            stateChat: true
        })
    }

    activarLlamanos = () => {
        this.setState({
            stateContenido: !this.state.stateContenido,
            stateLlamanos: true
        })
    }

    cerrarChat = () => {
        this.setState({
            stateLlamanos: false,
            stateChat: false
        })
    }

    render() {
        return (
            <React.Fragment>
                <div className="mb-5 bg-white footer" style={{ width: '100%', height: '400px' }}>
                    <div style={{ background: '#f07f00', height: '60px' }}>
                        <div className="container w-75">
                            <div className="row pb-5">
                                <div className="col-md-6 d-flex align-items-center py-0">
                                    <img width="190" className="img-fluid py-0 mt-2 logo_footer" src={`${URLImgFront}/assets/logo/logo-blanco.png`} alt="logo" />
                                </div>
                                <div className="redes-sociales-footer">
                                    <ul style={{ listStyle: 'none' }}>
                                        <li>
                                            <a href="https://twitter.com/Utrahuilca" target={'_blank'} rel="noopener noreferrer">
                                                <MDBIcon fab icon="twitter" size="2x" className="text-white ico" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.youtube.com/channel/UCZa_TvUo3FlzVz-jnn_67jw" target={'_blank'} rel="noopener noreferrer">
                                                <MDBIcon fab icon="youtube" size="2x" className="text-white ico" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.facebook.com/utrahuilca" target={'_blank'} rel="noopener noreferrer">
                                                <MDBIcon fab icon="facebook-square" size="2x" className="text-white ico" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.instagram.com/utrahuilca/" target={'_blank'} rel="noopener noreferrer">
                                                <MDBIcon fab icon="instagram" size="2x" className="text-white ico" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container bg-white">
                        <div className="row mt-5">
                            <div className="col-md-4 my-3">
                                <img width="35" className="img-fluid pb-2" src={`${URLImgFront}/assets/iconos/casa-footer.svg`} alt="img-casa" />
                                <p className="font-weight-bold">Nuestros productos</p>
                                <p className="mb-0">Lo entregamos todo</p>
                                <Link to="/Servicios">
                                    <strong className="text-dark" style={{ borderBottom: '2px solid #3586c2' }}>Conócelos</strong>
                                </Link>
                            </div>
                            <div className="col-md-4 my-3">
                                <img width="35" className="img-fluid pb-2" src={`${URLImgFront}/assets/iconos/celular-footer.svg`} alt="img-telefono" />
                                <p className="font-weight-bold">UTRAHUILCA Más Cerca</p>
                                <p className="mb-0">Siempre será un gusto atenderle.</p>
                                <strong onClick={this.activarLlamanos} style={{ borderBottom: '2px solid #3586c2', cursor: 'pointer' }}>Llámenos</strong>
                            </div>
                            <div className="col-md-4 mb-3">
                                <img width="55" className="img-fluid" src={`${URLImgFront}/assets/img-variadas/ICONO FORMULARIO DE ASESORÍA negro.png`} alt="ICONO FORMULARIO DE ASESORÍA" />
                                <p className="font-weight-bold">Formulario de Asesoría</p>
                                <p className="mb-0">Encuentre la solución a su necesidad.</p>
                                <Link to="/Asesoria">
                                    <strong className="text-dark" style={{ borderBottom: '2px solid #3586c2', cursor: 'pointer' }}>Escríbanos</strong>
                                </Link>
                            </div>
                        </div>
                        <div className="row mt-5 ">
                            <div className="col-md-4 mt-5 footer-aspectos">
                                <Link to="/Conozcanos">
                                    <p className="pb-3 text-dark">Sobre Utrahuilca</p>
                                </Link>
                            </div>
                            <div className="col-md-4 footer-aspectos mt-5 pl-5" style={{ borderLeft: '2px solid #a8a9cc', marginLeft: '-40px' }}>
                                <Link to="/Red_Agencias">
                                    <p className="pb-3 text-dark">Red de Agencias</p>
                                </Link>
                            </div>
                            {/*<div className="col-md-4 footer-aspectos mt-5 pl-5" style={{ borderLeft: '2px solid #a8a9cc', marginLeft: '5px' }}>*/}
                            {/*    <a href={`${URLImgFront}/assets/Archivos/aviso de privacidad.pdf`} rel="noopener noreferrer" target={'_blank'} download={true}>*/}
                            {/*        <p className="pb-3 text-dark">Aviso de Privacidad</p>*/}
                            {/*    </a>*/}
                            {/*</div>*/}
                            { /* AJUSTE WS 2023/12/20 YC */}
                            <div className="col-md-4 footer-aspectos mt-5 pl-5" style={{ borderLeft: '2px solid #a8a9cc', marginLeft: '5px' }}>
                                <a href="/AvisoPrivacidad" rel="noopener noreferrer" target={'_blank'}>
                                    <p className="pb-3 text-dark">Aviso de Privacidad</p>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="py-3" style={{ background: '#f0f6f8' }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 convenios-footer text-center">
                                    <ul style={{ listStyle: 'none' }}>
                                        <li>
                                            <img width="150" className="img-fluid" src={`${URLImgFront}/assets/logos_convenios/super-ok.png`} alt="img1" />
                                        </li>
                                        <li>
                                            <img width="150" height="121.578" className="img-fluid" src={`${URLImgFront}/assets/logos_convenios/segurofogacoop_24.webp`} alt="img2" />
                                        </li>
                                        <li>
                                            <img width="150" className="img-fluid" src={`${URLImgFront}/assets/logos_convenios/sello-ok.png`} alt="img3" />
                                        </li>
                                        <li>
                                            <img width="150" className="img-fluid" src={`${URLImgFront}/assets/logos_convenios/red-ok.png`} alt="img4" />
                                        </li>
                                        <li>
                                            <a href="https://play.google.com/store/apps/details?id=com.visionamos.redcoopcentral.release&hl=es_CO" target={'_blank'} rel="noopener noreferrer">
                                                <img width="150" className="img-fluid pt-4 mt-2" src={`${URLImgFront}/assets/img-variadas/play_store.png`} alt="img5" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://apps.apple.com/co/app/visionamos-redcoopcentral/id1327527559" target={'_blank'} rel="noopener noreferrer">
                                                <img width="125" className="img-fluid pt-4 mt-2" src={`${URLImgFront}/assets/img-variadas/app_store.png`} alt="img6" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-12 pt-2">
                                    <p style={{ fontSize: '12px' }}>Copyright © Todos los derechos reservados Utrahuilca.coop</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.stateLlamanos ? (
                        <Llamanos cerrarM={this.cerrarChat} />
                    ) : (
                        <React.Fragment></React.Fragment>
                    )}
                </div>
            </React.Fragment>
        );
    }
}

export default Footer;