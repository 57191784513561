import React, { Component } from 'react';
import SideNav, { NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import { MDBIcon } from 'mdbreact';
import Swal from 'sweetalert2';
import { getProfile } from '../../services/WebAPI_Utrahuilca';
import { URLImgFront } from '../../config/URLDB';
import Inicio from '../ComponentsFundacion/Inicio';
import ConsultarAsociado from '../ComponentsFundacion/ConsultarAsociado/ConsultarAsociado'

class PrincipalFundacion extends Component {

    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
            active: '1',
            nombre: '',
            cargo: '',
        };
    }

    componentDidMount() {
        this.loadProfile();
    }

    loadProfile() {
        if (sessionStorage.length > 0) {
            const dataparametro = {
                idusuario: parseInt(sessionStorage.getItem("idusuariofundacion")),
                usuario: sessionStorage.getItem("usuariofundacion"),
            }
            getProfile(dataparametro).then(response => {
                try {
                    if (response.data.length > 0) {
                        this.setState({
                            nombre: response.data[0].nombrecomp,
                            cargo: response.data[0].cargo
                        })
                    } else {
                        sessionStorage.removeItem('idusuariofundacion');
                        sessionStorage.removeItem('usuariofundacion');
                        this.props.history.push('/fundacion/login');
                    }
                } catch (error) {
                    Swal.fire({
                        icon: 'error',
                        title: response.data[0].descMessage
                    })
                    sessionStorage.removeItem('idusuariofundacion');
                    sessionStorage.removeItem('usuariofundacion');
                    this.props.history.push('/fundacion/login');
                }
            });
        } else {
            sessionStorage.removeItem('idusuariofundacion');
            sessionStorage.removeItem('usuariofundacion');
            this.props.history.push('/fundacion/login');
        }
    }

    render() {
        const { expanded, active } = this.state;
        return (
            <React.Fragment>
                <SideNav
                    onSelect={(selected) => {
                        this.setState({
                            active: selected
                        });
                    }}
                    expanded={expanded}
                    onToggle={(expanded) => {
                        this.setState({ expanded });
                    }}
                    className="sidenav-design"
                >
                    <SideNav.Toggle
                        className="sideNavToggle"
                        onClick={() => this.setState({ expanded: !expanded })}
                    />
                    <SideNav.Nav className="sideNavNav">
                        <NavItem eventKey="1" className="items">
                            <NavIcon>
                                <MDBIcon icon="home" size="2x" />
                            </NavIcon>
                            <NavText>
                                Inicio
                            </NavText>
                        </NavItem>
                        <NavItem eventKey="0" className="items">
                            <NavIcon>
                                <MDBIcon icon="user-check" size="2x" />
                            </NavIcon>
                            <NavText>
                                Consultar Persona
                            </NavText>
                            <NavItem eventKey="01" className="item">
                                <NavText>
                                    Consultar
                                </NavText>
                            </NavItem>
                        </NavItem>
                    </SideNav.Nav>
                </SideNav>
                <main className="pl-5 ml-5 py-5 pr-5 container_fondo">
                    <img className="img-fluid img_logo" src={`${URLImgFront}/assets/logo/logofundautrahuilca.png`} alt="logo" width="500" />
                    <div className="contenedor">
                        {(() => {
                            switch (active) {
                                case '1': return <Inicio usuario={this.state.nombre} cargo={this.state.cargo} />;
                                case '01': return <ConsultarAsociado />;
                                default: return <Inicio />;
                            }
                        })()}
                    </div>
                </main>
            </React.Fragment>
        );
    }
}

export default PrincipalFundacion;