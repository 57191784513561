import React, { Component } from 'react';
import Seccion1 from '../components/Credito_Libranza/Seccion1';
import Seccion2 from '../components/Credito_Libranza/Seccion2';
import Seccion3 from '../components/Credito_Libranza/Seccion3';
import MensajeF from '../components/Mensaje_Flotante/MensajeF';
import Footer from '../components/Principal/Footer';
import Header from '../components/Principal/Header';

class CreditoLibranza extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    render() {
        return (
            <React.Fragment>
                <Header id={2} />
                <MensajeF />
                <Seccion1 />
                <Seccion2 />
                <Seccion3 />
                <Footer />
            </React.Fragment>
        );
    }
}

export default CreditoLibranza;