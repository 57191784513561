import { MDBIcon } from 'mdbreact';
import React, { Component } from 'react';
import Footer from '../../Principal/Footer';
import Header from '../../Principal/Header';
import SolicitudCredito from '../../Formularios/FormAsesoria';
import MensajeF from '../../Mensaje_Flotante/MensajeF';

class Solicitud extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadpage: false
        };
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                loadpage: true
            })
        }, 1)
    }

    render() {
        return (
            <React.Fragment>
                <Header id={2} />
                <MensajeF />
                {this.state.loadpage ? (
                    <React.Fragment>
                        <div className="bg-white solicitud_credito" style={{ width: '100%', paddingTop: '190px' }}>
                            <div className="container-fluid px-5">
                                <p className="texto1" style={{ fontSize: '60px', color: '#747373' }}>Formulario de Asesoría</p>
                                <p className="pb-4 w-75 texto2 text-justify">A través de este formulario, solicite asesoría de cualquiera de nuestros servicios de crédito, ahorro o vinculación. En el menor tiempo posible, uno de nuestros agentes telefónicos se pondrá en contacto con usted. Si desea más información, comuníquese a nuestra línea gratuita nacional 01-8000-930-105 opción 1. </p>
                            </div>
                            <div className="px-5 formulario" style={{ background: '#f7f7f7', width: '100%' }}>
                                <div className="container-fluid px-5 py-5">
                                    <p className="mb-0 font-weight-bold" style={{ fontSize: '25px', color: '#3f8592' }}>DATOS PERSONALES</p>
                                    <hr style={{ background: '#3f8592' }} />
                                    <SolicitudCredito color={'#f47216'} />
                                </div>
                            </div>
                        </div>
                        <span className="pt-4 font-weight-bold" style={{ fontSize: '20px', position: 'absolute', top: '130px', left: '10px', zIndex: '999' }}><MDBIcon icon="caret-right" className="ml-2 mr-1" /> Personas <MDBIcon icon="caret-right" className="ml-2 mr-1" /> Formulario de Asesoria</span>
                        <Footer />
                    </React.Fragment>
                ) : (
                    <React.Fragment></React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

export default Solicitud;