import React, { Component } from 'react';
import Footer from '../components/Principal/Footer';
import Header from '../components/Principal/Header';
import MensajeF from '../components/Mensaje_Flotante/MensajeF';
import Seccion1 from '../components/Coopsejos/Seccion1';
import Seccion2 from '../components/Coopsejos/Seccion2';

class Coopsejos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadpage: false
        };
    }
    componentDidMount() {
        setTimeout(() => {
            this.setState({
                loadpage: true
            })
        },0.1)
    }
    render() {
        return (
            <React.Fragment>
                <Header id={2} />
                <MensajeF />
                {this.state.loadpage ? (
                    <div>
                        <Seccion1 />
                        <Seccion2 />
                    </div>
                ) : (<React.Fragment></React.Fragment>)}
                <Footer />
            </React.Fragment>
        );
    }
}

export default Coopsejos