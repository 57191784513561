import { MDBIcon } from 'mdbreact';
import React, { Component } from 'react';
import { URLImgFront } from '../../config/URLDB';

class Personas extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    render() {
        return (
            <React.Fragment>
                <div className="diseño_responsive_view" style={{ width: '100%', paddingTop: '120px' }}>
                    <div className="vista_seguros" style={{ position: 'absolute', top: '450px', left: '80px' }}>
                        <p className="my-0" style={{ fontSize: '30px', color: '#fff' }}>Aqui puedes adquirir tu</p>
                        <p className="pb-5" style={{ fontSize: '56px', color: '#fff', fontWeight: 'bold' }}>CRÉDITO DE LIBRANZA</p>
                        <span className="position-absolute pt-2" style={{ fontSize: '30px', borderBottom: '1px solid white', color: '#fff', cursor: 'pointer' }}>Solicitar Crédito <MDBIcon icon="caret-right" size="lg" className="pl-4 pt-2" style={{ color: '#fff', position: 'absolute', right: '-30px' }} /></span>
                    </div>
                    <img style={{ width: '100%', height: '70vh', marginBottom: '15px', objectFit: 'cover' }} className="img-fluid" src={`${URLImgFront}/assets/img_principal/seguros.jpg`} alt="img1" />
                </div>
            </React.Fragment>
        );
    }
}

export default Personas;