import { MDBIcon, MDBInput } from 'mdbreact';
import React, { Component } from 'react';
import { Llamanos_ } from '../../../services/WebAPI_Utrahuilca';
import Swal from 'sweetalert2';
import { URLImgFront } from '../../../config/URLDB';
import {withRouter} from 'react-router-dom';

class Llamanos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stateMinimizar: true,
            numeros: []
        };
    }

    async componentDidMount() {
        const data = {
            tran: 7,
            idutrallamanos: 0,
            nrollamanos: 'NA',
            estado: 1,
            usucrea: 'NA'
        }
        Llamanos_(data).then(response => {
            try {
                if (response.data.length > 0) {
                    this.setState({
                        numeros: response.data
                    });
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Ocurrio un error!'
                    })
                }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    text: 'Ocurrio un error al consumir el servicio, comuniquese con sistemas!'
                })
            }
        });
    }

    minimizar = () => {
        this.setState({
            stateMinimizar: !this.state.stateMinimizar
        })
    }

    Btncerrar = () => {
        this.props.cerrarM();
    }

    render() {
        const { stateMinimizar } = this.state;
        return (
            <React.Fragment>
                {stateMinimizar ? (
                    <React.Fragment>
                        <div className="diseño-flotante" style={{ width: '470px' }}>
                            <div className="card">
                                <div className="card-header py-0 px-0">
                                    <img width="70" className="img-fluid" src={`${URLImgFront}/assets/logo/iconoU.png`} alt="iconoU" />
                                    <p className="mt-4" style={{ fontSize: '24px' }}>Llámenos</p>
                                    <div className="icons">
                                        <MDBIcon icon="minus" className="mx-2 icon" onClick={this.minimizar} />
                                        <MDBIcon icon="times" className="mx-2 icon" onClick={this.Btncerrar} />
                                    </div>
                                </div>
                                <div className="card-body py-0 px-0" style={{ background: '#f7f7f7' }}>
                                    <div className="container px-0">
                                        <div className="row">
                                            <div className="col-md-12 px-4 mx-3 py-3" style={{ background: '#fff' }}>
                                                <p>Llamar a nuestra línea de atención al cliente.</p>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="container">
                                                    <div className="row">
                                                        {this.state.numeros.map((e, i) => {
                                                            return [
                                                                <React.Fragment key={i}>
                                                                    <div className="col-md-12 w-50 px-3 my-0">
                                                                        <MDBInput label={e.nrollamanos} disabled icon="phone-alt" type="text" className="mt-0" />
                                                                        <a href={`tel:+57${e.nrollamanos}`} className="px-2 py-1 btn_llamar" style={{ background: '#3f8592', color: 'white', borderRadius: '20px', fontWeight: 'bold', fontSize: '15px', cursor: 'pointer' }}>Llámar ahora </a>
                                                                    </div>
                                                                </React.Fragment>
                                                            ]
                                                        })}
                                                        <div className="col-md-12 py-3" style={{ background: '#f38b21' }}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className="diseño-flotante" style={{ width: '470px' }}>
                            <div className="card">
                                <div className="card-header py-0 px-0">
                                    <img width="70" className="img-fluid" src={`${URLImgFront}/assets/logo/iconoU.png`} alt="iconoU" />
                                    <p className="mt-4" style={{ fontSize: '24px' }}>Llámanos</p>
                                    <div className="icons">
                                        <MDBIcon icon="expand" className="mx-2 icon" onClick={this.minimizar} />
                                        <MDBIcon icon="times" className="mx-2 icon" onClick={this.Btncerrar} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

export default withRouter(Llamanos);