import React, { Component } from 'react';
import { MDBIcon } from 'mdbreact';
import { Link } from 'react-router-dom';
//import { URLImgFront } from '../config/URLDB';
import Footer from '../components/Principal/Footer';
import Header from '../components/Principal/Header';
import MensajeF from '../components/Mensaje_Flotante/MensajeF';

class ActualizacionWebDian extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadpage: false
        };
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                loadpage: true
            })
        }, 0.1)
    }

    render() {
        return (
            <React.Fragment>
                <Header id={2} />
                <MensajeF />
                {this.state.loadpage ? (
                    <div>
                        <div className="py-5" style={{ background: '#20A150', height: '100%', width: '100%' }}>
                            <div className="container">
                                <div className="row pt-5">
                                    <div className="col-lg-12 pt-5">
                                        <p className="text-justify pt-3 pb-2" style={{ color: '#fff', fontSize: '22px', fontWeight: 'bold' }}>Conozca los documentos relacionados con la Asamblea General UTRAHUILCA 2024.</p>

                                        <p className="text-justify pb-2" style={{ color: '#fff', fontSize: '18px', fontWeight: 'bold' }}>El pasado 16 de marzo de 2024 se llevó a cabo la Sexagésima segunda (62) Asamblea General de Delegados de la Cooperativa UTRAHUILCA, donde asistieron 78 delegados principales y 12 suplentes comprometidos con la entidad solidaria y dispuestos a atender el orden del día.</p>
                                        <p className="text-justify" style={{ color: '#fff', fontSize: '18px', fontWeight: 'bold' }}>Consulte aquí la información enviada por UTRAHUILCA a la DIAN, para los Comentarios de la Sociedad Civil sobre la gestión realizada por la Cooperativa en el año gravable 2023, con motivo del proceso de actualización del Registro Web en dicha entidad de fiscalización.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="py-5 pt-4" style={{ background: '#fff', height: '100%', width: '100%' }}>
                            <div className="container">
                                <div className="row pt-2 pb-2">
                                    <div className="col-lg-12 pt-3 pb-3">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Documento</th>
                                                    <th scope="col">Descripción</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <a className="text-center d-flex justify-content-center" style={{ color: '#20A150' }} href={`/Documentos/ActualizacionDIAN/CertificacionRemuneraciones2023.pdf`} target={'_blank'} rel="noopener noreferrer">
                                                            <MDBIcon icon="fa fa-eye" size="1x" className="ico" style={{ paddingTop: '4px', paddingRight: '3px' }} /><span className="font-weight-bold">Ver Documento</span>
                                                        </a>
                                                    </td>
                                                    <td>Certificación Renúmeración Directivos y Gerenciales</td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <a className="text-center d-flex justify-content-center" style={{ color: '#20A150' }} href={`/Documentos/ActualizacionDIAN/MemoriaEconomica2023.pdf`} target={'_blank'} rel="noopener noreferrer">
                                                            <MDBIcon icon="fa fa-eye" size="1x" className="ico" style={{ paddingTop: '4px', paddingRight: '3px' }} /><span className="font-weight-bold">Ver Documento</span>
                                                        </a>
                                                    </td>
                                                    <td>Memoria Económica 2023</td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <Link className="text-center d-flex justify-content-center" style={{ color: '#20A150' }} to={`/Documentos/ActualizacionDIAN/InformeDeGestion2023.pdf`} target={'_blank'} rel="noopener noreferrer">
                                                            <MDBIcon icon="fa fa-eye" size="1x" className="ico" style={{ paddingTop: '4px', paddingRight: '3px' }} /><span className="font-weight-bold">Ver Documento</span>
                                                        </Link>
                                                    </td>
                                                    <td>Informe Gestión 2023</td>
                                                </tr>
                                                
                                                <tr>
                                                    <td>
                                                        <a className="text-center d-flex justify-content-center" style={{ color: '#20A150' }} href={`/Documentos/ActualizacionDIAN/EstadosFinanyNotasEF2023.pdf`} target={'_blank'} rel="noopener noreferrer">
                                                            <MDBIcon icon="fa fa-eye" size="1x" className="ico" style={{ paddingTop: '4px', paddingRight: '3px' }} /><span className="font-weight-bold">Ver Documento</span>
                                                        </a>
                                                    </td>
                                                    <td>Estados Financieras y Notas EF - Dictamen 2023</td>
                                                </tr>
                                                
                                                <tr>
                                                    <td>
                                                        <a className="text-center d-flex justify-content-center" style={{ color: '#20A150' }} href={`/Documentos/ActualizacionDIAN/CertificacionCumplimientoRTE2023.pdf`} target={'_blank'} rel="noopener noreferrer">
                                                            <MDBIcon icon="fa fa-eye" size="1x" className="ico" style={{ paddingTop: '4px', paddingRight: '3px' }} /><span className="font-weight-bold">Ver Documento</span>
                                                        </a>
                                                    </td>
                                                    <td>Certificación Cumplimiento Requisitos</td>
                                                </tr>
                                                
                                                <tr>
                                                    <td>
                                                        <a className="text-center d-flex justify-content-center" style={{ color: '#20A150' }} href={`/Documentos/ActualizacionDIAN/EstatutoUtrahuilca2024.pdf/0`} target={'_blank'} rel="noopener noreferrer">
                                                            <MDBIcon icon="fa fa-eye" size="1x" className="ico" style={{ paddingTop: '4px', paddingRight: '3px' }} /><span className="font-weight-bold">Ver Documento</span>
                                                        </a>
                                                    </td>
                                                    <td>Estatutos</td>
                                                </tr>
                                                
                                                <tr>
                                                    <td>
                                                        <a className="text-center d-flex justify-content-center" style={{ color: '#20A150' }} href={`/Documentos/ActualizacionDIAN/CertificadoAntecedentesJudiciales2023.pdf`} target={'_blank'} rel="noopener noreferrer">
                                                            <MDBIcon icon="fa fa-eye" size="1x" className="ico" style={{ paddingTop: '4px', paddingRight: '3px' }} /><span className="font-weight-bold">Ver Documento</span>
                                                        </a>
                                                    </td>
                                                    <td>Certificación Antecedentes Judiciales</td>
                                                </tr>
                                                
                                                <tr>
                                                    <td>
                                                        <a className="text-center d-flex justify-content-center" style={{ color: '#20A150' }} href={`/Documentos/ActualizacionDIAN/ActaAsamblea2024.pdf`} target={'_blank'} rel="noopener noreferrer">
                                                            <MDBIcon icon="fa fa-eye" size="1x" className="ico" style={{ paddingTop: '4px', paddingRight: '3px' }} /><span className="font-weight-bold">Ver Documento</span>
                                                        </a>
                                                    </td>
                                                    <td>Acta de Asamble 2024</td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <a className="text-center d-flex justify-content-center" style={{ color: '#20A150' }} href={`/Documentos/ActualizacionDIAN/CertificadoExistenciayRepresentacionLegal.pdf`} target={'_blank'} rel="noopener noreferrer">
                                                            <MDBIcon icon="fa fa-eye" size="1x" className="ico" style={{ paddingTop: '4px', paddingRight: '3px' }} /><span className="font-weight-bold">Ver Documento</span>
                                                        </a>
                                                    </td>
                                                    <td>Certificado de Existencia y Representación Legal</td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <a className="text-center d-flex justify-content-center" style={{ color: '#20A150' }} href={`/Documentos/ActualizacionDIAN/DeclarRentaUtrahuilca2023.pdf`} target={'_blank'} rel="noopener noreferrer">
                                                            <MDBIcon icon="fa fa-eye" size="1x" className="ico" style={{ paddingTop: '4px', paddingRight: '3px' }} /><span className="font-weight-bold">Ver Documento</span>
                                                        </a>
                                                    </td>
                                                    <td>Declaración de renta año gravable 2023</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="col-lg-12 pt-3 pb-3">
                                        <p className="text-justify pb-2" style={{ fontSize: '18px' }}>Esta información fue publicada el <strong>12 de Junio de 2024</strong> y estará vigente hasta el <strong>15 de Julio de 2024</strong> a las 06:00 PM.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    ) : (<React.Fragment></React.Fragment>)}
                <Footer />
            </React.Fragment>
        );
    }
}

export default ActualizacionWebDian;