import React, { Component } from 'react';
import FileUploader from "./ImagenTexto/FileUploader";
import FileUploaderUpdate from "./ImagenTexto/FileUploaderUpdate";
import { MDBIcon, MDBInput } from 'mdbreact';
import reactCSS from 'reactcss';
import { SketchPicker } from 'react-color';
import { CargueImagenes, SeccionTransaccional_ } from '../../../services/WebAPI_Utrahuilca';
import Swal from 'sweetalert2';

const validate = values => {
    const errors = {}
    if (!values.nombvtacoop) {
        errors.nombvtacoop = 'campo obligatorio'
    }
    if (!values.txtcoopvirtual1) {
        errors.txtcoopvirtual1 = 'campo obligatorio'
    }
    if (!values.txtcoopvirtual2) {
        errors.txtcoopvirtual2 = 'campo obligatorio'
    }
    return errors;
};

class ImagenTexto extends Component {
    uploader = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            estadoNuevo: false,
            errors: {},
            estadoCambioImg1: true,
            infoConvenios: [],
            nombvtacoop: '',
            txtcoopvirtual1: '',
            txtcoopvirtual2: '',
            editnombvtacoop: '',
            edittxtcoopvirtual1: '',
            edittxtcoopvirtual2: '',
            estadoImagen: false,
            displayColorPicker: false,
            rutaimgcoopvirt: '',
            color: '',
            estadoSinInfo: false,
        };
    }

    async componentDidMount() {
        this.cargarInformacion();
    }

    cargarInformacion = () => {
        const data = {
            tran: 4,
            idvtacoopvirt: 0,
            nombvtacoop: 'NA',
            rutaimgcoopvirt: 'NA',
            txtcoopvirtual1: 'NA',
            txtcoopvirtual2: 'NA',
            colortexto: 'NA',
            estado: 0,
            usucrea: 'NA'
        }
        SeccionTransaccional_(data).then(response => {
            try {
                if (response.data.length > 0) {
                    if (!response.data[0].message) {
                        this.setState({
                            idSeleccionado: response.data[0].idvtacoopvirt
                        })
                        setTimeout(() => {
                            this.setState({
                                infoConvenios: response.data,
                                editnombvtacoop: response.data[0].nombvtacoop,
                                rutaimgcoopvirt: response.data[0].rutaimgcoopvirt,
                                edittxtcoopvirtual1: response.data[0].txtcoopvirtual1,
                                edittxtcoopvirtual2: response.data[0].txtcoopvirtual2,
                                edittxtslider3: response.data[0].txtslider3,
                                color: response.data[0].colortexto,
                                estado: response.data[0].estado,
                                estadoSinInfo: true,
                                estadoImagen: true,
                            })
                            document.getElementById('' + response.data[0].idvtacoopvirt + '').style.background = "#f47216";
                            document.getElementById('' + response.data[0].idvtacoopvirt + '').style.color = "#fff";
                            document.getElementById('' + response.data[0].idvtacoopvirt + '').style.fontWeight = "bold";
                        }, 120);
                    } else {
                        this.setState({
                            estadoSinResultados: false,
                            estadoNuevo: true,
                            estadoCambioImg1: true,
                            estadoSinInfo: false
                        })
                    }
                } else { }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    text: 'Ocurrio un error al consumir el servicio, comuniquese con sistemas!'
                })
            }
        });
    }

    textInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    cambioImagen1 = (name, file) => {
        this.setState({
            estadoCambioImg1: true
        });
    }

    cambioImagen = (name, file) => {
        this.setState({
            estadoCambioImg: true
        });
    }

    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    };

    handleClose = () => {
        this.setState({ displayColorPicker: false })
    };

    handleChange = (color) => {
        this.setState({ color: color.hex })
    };

    cargarImagen = () => {
        var formData = new FormData();
        if (undefined === this.uploader.current) return;
        
        formData.append("file", this.uploader.current.getFileObject());
        formData.append("rutaImagen", '/Secc - Transaccional/ImagenTexto/' + this.uploader.current.getFileName());
        CargueImagenes(formData).then(response => {
            if (response) {
                this.cargarInformacion();
            }
        });
    }

    insertarDatos = () => {
        const { errors, ...sinErrors } = this.state;
        const result = validate(sinErrors);
        this.setState({ errors: result });
        if (!Object.keys(result).length) {
            // formulario validado
            const data = {
                tran: 1,
                idvtacoopvirt: 0,
                nombvtacoop: this.state.nombvtacoop,
                rutaimgcoopvirt: '/Secc - Transaccional/ImagenTexto/' + this.uploader.current.getFileName(),
                txtcoopvirtual1: this.state.txtcoopvirtual1,
                txtcoopvirtual2: this.state.txtcoopvirtual2,
                colortexto: this.state.color,
                estado: 1,
                usucrea: sessionStorage.getItem('idusuario')
            }
            SeccionTransaccional_(data).then(response => {
                if (response.data.length > 0) {
                    if (response.data[0].message === "000000") {
                        this.cargarImagen();
                        Swal.fire({
                            icon: 'success',
                            title: 'Se agrego correctamente.'
                        })
                        this.setState({
                            estadoNuevo: false,
                            nombvtacoop: '',
                            txtcoopvirtual1: '',
                            txtcoopvirtual2: '',
                            estadoImagen: false,
                            displayColorPicker: false,
                            rutafondoImg: '',
                            color: '',
                        })
                        this.cargarInformacion();
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error al insertar.'
                        })
                    }
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Ocurrio un error!'
                    })
                }
            });
        }
    }

    busquedaId = (id) => {
        document.getElementById('' + this.state.idSeleccionado + '').style.background = "#fff";
        document.getElementById('' + this.state.idSeleccionado + '').style.color = "#000";
        document.getElementById('' + this.state.idSeleccionado + '').style.fontWeight = "normal";
        document.getElementById('' + id + '').style.background = "#f47216";
        document.getElementById('' + id + '').style.color = "#fff";
        document.getElementById('' + id + '').style.fontWeight = "bold";
        this.setState({
            estadoCambioImg: false,
            est: !this.state.est,
            idSeleccionado: id
        })
        const data = {
            tran: 5,
            idvtacoopvirt: id,
            nombvtacoop: 'NA',
            rutaimgcoopvirt: 'NA',
            txtcoopvirtual1: 'NA',
            txtcoopvirtual2: 'NA',
            txtslider3: 'NA',
            colortexto: 'NA',
            estado: this.state.estado,
            usucrea: 'NA'
        }
        SeccionTransaccional_(data).then(response => {
            if (response.data.length > 0) {
                this.setState({
                    editnombvtacoop: response.data[0].nombvtacoop,
                    rutaimgcoopvirt: response.data[0].rutaimgcoopvirt,
                    edittxtcoopvirtual1: response.data[0].txtcoopvirtual1,
                    edittxtcoopvirtual2: response.data[0].txtcoopvirtual2,
                    edittxtslider3: response.data[0].txtslider3,
                    color: response.data[0].colortexto,
                    estado: response.data[0].estado,
                })
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'No hay información'
                })
            }
        });
    }

    activar = (id) => {
        const data = {
            tran: 6,
            idvtacoopvirt: id,
            nombvtacoop: 'NA',
            rutaimgcoopvirt: 'NA',
            txtcoopvirtual1: 'NA',
            txtcoopvirtual2: 'NA',
            txtslider3: 'NA',
            colortexto: 'NA',
            estado: 1,
            usucrea: sessionStorage.getItem('idusuario')
        }
        SeccionTransaccional_(data).then(response => {
            if (response) {
                this.setState({
                    estadoS: false
                })
                Swal.fire({
                    icon: 'success',
                    title: 'Se activo satisfactoriamente'
                });
                document.getElementById('' + this.state.idSeleccionado + '').style.background = "#fff";
                document.getElementById('' + this.state.idSeleccionado + '').style.color = "#000";
                document.getElementById('' + this.state.idSeleccionado + '').style.fontWeight = "normal";
                this.cargarInformacion();
            }
        });
    }

    actualizarDatos = (id) => {
        var ruta = '';
        var capNombre = this.uploader.current.getFileName();
        if (capNombre === undefined || capNombre === null) {
            ruta = this.state.rutaimgcoopvirt;
        } else {
            ruta = '/Secc - Transaccional/ImagenTexto/' + this.uploader.current.getFileName();
        }
        const data = {
            tran: 1,
            idvtacoopvirt: id,
            nombvtacoop: this.state.editnombvtacoop,
            rutaimgcoopvirt: ruta,
            txtcoopvirtual1: this.state.edittxtcoopvirtual1,
            txtcoopvirtual2: this.state.edittxtcoopvirtual2,
            colortexto: this.state.color,
            estado: 1,
            usucrea: sessionStorage.getItem('idusuario')
        }
        SeccionTransaccional_(data).then(response => {
            if (response.data.length > 0) {
                if (response.data[0].message === "000000") {
                    this.cargarImagen();
                    this.setState({
                        estadoCambio: false,
                        estadoEditar: false,
                    })
                    Swal.fire({
                        icon: 'success',
                        title: 'Se actualizo satisfactoriamente'
                    })
                    document.getElementById('' + this.state.idSeleccionado + '').style.background = "#fff";
                    document.getElementById('' + this.state.idSeleccionado + '').style.color = "#000";
                    document.getElementById('' + this.state.idSeleccionado + '').style.fontWeight = "normal";
                    this.cargarInformacion();
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Ocurrio un error al actualizar'
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Ocurrio un error!'
                })
            }
        });
    }

    handleNew = () => {
        this.setState({
            errors: {},
            estadoNuevo: !this.state.estadoNuevo,
        })
        if (this.state.estadoNuevo) {
            this.setState({
                errors: {}
            })
            this.cargarInformacion();
        }
    }

    render() {
        const { estadoNuevo, infoConvenios, nombvtacoop, estadoCambioImg1, errors, estadoCambioImg, estadoSinInfo, txtcoopvirtual1, txtcoopvirtual2, estadoImagen, rutaimgcoopvirt } = this.state;
        const styles = reactCSS({
            'default': {
                color: {
                    width: '36px',
                    height: '14px',
                    borderRadius: '2px',
                    background: this.state.color,
                },
                swatch: {
                    padding: '5px',
                    background: '#fff',
                    borderRadius: '1px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                    display: 'inline-block',
                    cursor: 'pointer',
                },
                popover: {
                    position: 'absolute',
                    zIndex: '2',
                },
                cover: {
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                },
            },
        });
        return (
            <React.Fragment>
                <p>Imagen - Texto Seccion Transaccional</p>
                <hr />
                {estadoSinInfo ? (
                    <React.Fragment>
                        <div className="plus_add_agencia align-items-center d-flex" onClick={() => { this.handleNew() }}>
                            {estadoNuevo ? <p>Cancelar &nbsp;<MDBIcon icon="times" size="lg" /></p> : <p>Agregar Nuevo &nbsp;<MDBIcon icon="plus-circle" size="lg" /></p>}
                        </div>
                    </React.Fragment>
                ) : (
                    <React.Fragment></React.Fragment>
                )}
                {estadoNuevo ? (
                    <React.Fragment>
                        <div className="justify-content-center d-flex">
                            <div className="text-center">
                                <FileUploader ref={this.uploader} estadoImg={estadoCambioImg1} FCambiar={this.cambioImagen1} />
                            </div>
                        </div>
                        <div className="container mt-5 pt-5 pb-5">
                            <div className="row">
                                <div className="col-md-6">
                                    <MDBInput label="Nombre" type="text" name="nombvtacoop" value={nombvtacoop} onChange={this.textInput} />
                                    {errors.nombvtacoop && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '50px' }}>{errors.nombvtacoop}</strong>}
                                </div>
                                <div className="col-md-6">
                                    <MDBInput label="Texto 1" type="text" name="txtcoopvirtual1" value={txtcoopvirtual1} onChange={this.textInput} />
                                    {errors.txtcoopvirtual1 && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '50px' }}>{errors.txtcoopvirtual1}</strong>}
                                </div>
                                <div className="col-md-6">
                                    <MDBInput label="Texto 2" type="text" name="txtcoopvirtual2" value={txtcoopvirtual2} onChange={this.textInput} />
                                    {errors.txtcoopvirtual2 && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '50px' }}>{errors.txtcoopvirtual2}</strong>}
                                </div>
                                <div className="col-md-6 mt-3">
                                    <p>Color Texto</p>
                                    <div style={styles.swatch} onClick={this.handleClick}>
                                        <div style={styles.color} />
                                    </div>
                                    {this.state.displayColorPicker ? <div style={styles.popover}>
                                        <div style={styles.cover} onClick={this.handleClose} />
                                        <SketchPicker color={this.state.color} onChange={this.handleChange} />
                                    </div> : null}
                                </div>
                            </div>
                        </div>
                        <div className="pt-2 mt-5 modal-footer w-100">
                            <button type="button" className="btn btn-primary px-5" onClick={this.insertarDatos}>Guardar nuevo &nbsp; <MDBIcon icon="save" /></button>
                        </div>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className="listado_banner pt-5">
                            <p>Seleccionar banner</p>
                            <ul>
                                {infoConvenios.map((e, i) => {
                                    let view;
                                    if (e.estado === 1) {
                                        view = <MDBIcon icon="eye" size="lg" style={{ color: 'black', position: 'absolute', right: '-38px', marginTop: '5px' }} />
                                    } else {
                                        view = <MDBIcon onClick={() => { this.activar(e.idvtacoopvirt) }} icon="eye-slash" size="lg" style={{ color: 'black', position: 'absolute', right: '-38px', marginTop: '5px' }} />
                                    }
                                    return [
                                        <li key={i} id={e.idvtacoopvirt} onClick={() => { this.busquedaId(e.idvtacoopvirt) }}>{e.nombvtacoop} {view}</li>
                                    ]
                                })}
                            </ul>
                        </div>
                        <div className="justify-content-center d-flex">
                            <div className="text-center">
                                {estadoImagen ? (
                                    <FileUploaderUpdate ref={this.uploader} ruta={rutaimgcoopvirt} estadoImg={estadoCambioImg} FCambiar={this.cambioImagen} />
                                ) : (
                                    <React.Fragment></React.Fragment>
                                )}
                            </div>
                        </div>
                        <div className="container mt-5 pt-5 pb-5">
                            <div className="row">
                                <div className="col-md-6">
                                    <MDBInput label="Nombre" type="text" name="editnombvtacoop" value={this.state.editnombvtacoop} onChange={this.textInput} />
                                </div>
                                <div className="col-md-6">
                                    <MDBInput label="Texto 1" type="text" name="edittxtcoopvirtual1" value={this.state.edittxtcoopvirtual1} onChange={this.textInput} />
                                </div>
                                <div className="col-md-6">
                                    <MDBInput label="Texto 2" type="text" name="edittxtcoopvirtual2" value={this.state.edittxtcoopvirtual2} onChange={this.textInput} />
                                </div>
                                <div className="col-md-6 mt-3">
                                    <p>Color Texto</p>
                                    <div style={styles.swatch} onClick={this.handleClick}>
                                        <div style={styles.color} />
                                    </div>
                                    {this.state.displayColorPicker ? <div style={styles.popover}>
                                        <div style={styles.cover} onClick={this.handleClose} />
                                        <SketchPicker color={this.state.color} onChange={this.handleChange} />
                                    </div> : null}
                                </div>
                            </div>
                        </div>
                        <div className="pt-2 mt-5 modal-footer w-100">
                            <button type="button" className="btn btn-success px-5" onClick={() => { this.actualizarDatos(this.state.idSeleccionado) }}>Guardar cambios &nbsp; <MDBIcon icon="save" /></button>
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

export default ImagenTexto;