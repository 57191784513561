import React, { Component } from 'react';
import { MDBIcon } from 'mdbreact';

class BCredito extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    render() {
        return (
            <React.Fragment>
                <p className="texto-titulos text-left text-justify pt-4">Beneficios crédito</p>
                <p className="font-weight-bold">Cooperar es contar con más servicios gratuitos:</p>
                <p className="text-justify"><MDBIcon icon="chevron-right" style={{ color: '#3f8592' }} /> Estudio de crédito totalmente gratuito.</p>
                <p className="text-justify"><MDBIcon icon="chevron-right" style={{ color: '#3f8592' }} /> Consulta en Centrales de Riesgo sin costo para el asociado (a), UTRAHUILCA asume el valor.</p>
                <p className="text-justify"><MDBIcon icon="chevron-right" style={{ color: '#3f8592' }} /> Expedición de certificados, extractos, paz y salvos sin costo para el asociado.</p>
                <p className="text-justify"><MDBIcon icon="chevron-right" style={{ color: '#3f8592' }} /> No se cobra comisión por abonos a capital, pago anticipado de créditos o por pagos por ventanilla.</p>
                <p className="font-weight-bold">Cooperar es pagar intereses de créditos en la medida justa y ahorrar la diferencia:</p>
                <p className="text-justify"><MDBIcon icon="chevron-right" style={{ color: '#3f8592' }} /> La política de costos de servicio del crédito en todas las líneas de crédito estarán por debajo de la tasa máxima autorizada.</p>
                <p className="text-justify"><MDBIcon icon="chevron-right" style={{ color: '#3f8592' }} /> La tasa de interés para la Línea de Microcrédito están en varios puntos por debajo de la tasa máxima autorizada, sin cobro adicionales que encarecen el crédito.</p>
                <p className="text-justify"><MDBIcon icon="chevron-right" style={{ color: '#3f8592' }} /> La tasa de interés diferencial para los asociados con ocupación empleados y pensionados que pertenecen a los convenios empresariales de libranza y/o pago de salarios.</p>
                <p className="text-justify"><MDBIcon icon="chevron-right" style={{ color: '#3f8592' }} /> Tasas de interés en las Líneas de Crédito sociales que realmente apoyan cuando más lo necesitamos: Especial, Vivienda, Salud, Educación, Calamidad y Apoyo Solidario.</p>
                <p className="text-justify"><MDBIcon icon="chevron-right" style={{ color: '#3f8592' }} /> Todos los asociados reciben puntos de descuentos en las tasas de interés de los créditos de acuerdo a la antigüedad de vinculación.</p>
                <p className="text-justify"><MDBIcon icon="chevron-right" style={{ color: '#3f8592' }} /> Todos los asociados reciben devolución del 4.5% de los intereses en su cuota mensual, por pagar puntualmente sus obligaciones crediticias y encontrarse al día en el pago de sus aportes sociales.</p>
                <p className="font-weight-bold">Más beneficios para nuestros asociados: </p>
                <p className="text-justify"><MDBIcon icon="chevron-right" style={{ color: '#3f8592' }} /> Atención personalizada en ahorro y crédito al campesino con los Gestores (as) Cooperativos (as) Rurales logrando inclusión financiera.</p>
                <p className="text-justify"><MDBIcon icon="chevron-right" style={{ color: '#3f8592' }} /> Periodicidad de pago de cuotas según sus ingresos (diaria, semanal, quincenal, mensual, semestral y anual) que se ajusta al flujo de caja del asociado.</p>
                <p className="text-justify"><MDBIcon icon="chevron-right" style={{ color: '#3f8592' }} /> Facilidad de acceso a créditos sin codeudor (a) a través del aval del Fondo Regional de Garantías del Tolima.</p>
            </React.Fragment>
        );
    }
}

export default BCredito;