import React, { Component } from 'react';
import { URLImgFront } from '../../config/URLDB';

class Seccion1 extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    render() {
        return (
            <React.Fragment>
                <div  className="pse_diseño" style={{ width: '100%', paddingTop: '120px' }}>
                    <img style={{ width: '100%',  marginBottom: '15px' }} className="img-fluid" src={`${URLImgFront}/assets/img_principal/PORTADA CANALES DE ATENCIÓN.png`} alt="PORTADA CANALES DE ATENCIÓN" />
                </div>
            </React.Fragment>
        );
    }
}

export default Seccion1;