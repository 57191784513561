import React, { Component } from 'react';
import '../stylesCoopsejos.css';
import { Link } from 'react-router-dom';
import { URLImgFront } from '../../../config/URLDB';
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.min.css';
import { NoticiasEventosDetalles_ } from '../../../services/WebAPI_Utrahuilca';

class Noticias extends Component {
    constructor(props) {
        super(props);
        this.state = {
            infoNoticiasDetalles: [],
            prioridad1: [],
            prioridad2: [],
            prioridad3: [],
            sinInfo: true
        };
    }

    async componentDidMount() {
        this.cargarInformacion();
    }

    cargarInformacion = () => {
        const data = {
            tran: 7,
            idnotidet: 0,
            codcoopsejos: 3,
            titulonotidet: 'NA',
            descripnotidet: 'NA',
            urlnotidet: 'NA',
            imgnotidet: 'NA',
            fecinipublinotidet: '1900/01/01',
            fecfinpublinotidet: '1900/01/01',
            idtiponoticia: 0,
            prioridadnotidet: 0,
            estado: 1,
            usucrea: 'NA'
        }
        NoticiasEventosDetalles_(data).then(response => {
            try {
                if (response.data.length > 0) {
                    if (response.data[0].message === "004404") {
                        this.setState({
                            sinInfo: true
                        })
                    } else {
                        var prioridad1 = [];
                        var prioridad2 = [];
                        var prioridad3 = [];
                        response.data.map((e) => {
                            if (e.prioridadnotidet === 1) {
                                prioridad1.push(e);
                            } else if (e.prioridadnotidet === 2) {
                                prioridad2.push(e);
                            } else {
                                prioridad3.push(e);
                            }
                            return [];
                        })
                        this.setState({
                            prioridad1: prioridad1,
                            prioridad2: prioridad2,
                            prioridad3: prioridad3,
                            sinInfo: false
                        })
                    }
                } else { }
            } catch (error) {
                console.log();
            }
        });
    }

    render() {
        const params = {
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            shouldSwiperUpdate: true,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false,
            }
        }
        return (
            <React.Fragment>
                {this.state.sinInfo ? (<React.Fragment>
                    <div className="container">
                        <div className="alert alert-warning">Actualmente no presentamos noticias.</div>
                    </div>
                </React.Fragment>) : (
                    <React.Fragment>
                        <div className="py-3 px-3" style={{ background: '#3f8592' }}>
                            <div className="container pt-2 pb-2" style={{ background: '#fff' }}>
                                <div className="row" style={{ background: '#fff' }}>
                                    <Swiper {...params} className="rounded">
                                        {this.state.prioridad1.map((e, i) => {
                                            if (e.urlnotidet !== 'N/A') {
                                                return [
                                                    <div key={i}>
                                                        <a href={e.urlnotidet} className="col-md-6 px-4 pl-4 noticia" target={'_blank'} rel="noopener noreferrer">
                                                            <div className="row px-5 py-5 text-dark" key={i} style={{ cursor: 'pointer' }}>
                                                                <div className="col-md-12">
                                                                    <div className="d-flex align-items-center">
                                                                        <p className="px-4 py-2 my-0 mr-4" style={{ background: '#FA7500', color: '#fff', borderRadius: '1px', fontSize: '20px' }}>{e.tiponoticia}</p>
                                                                        <span style={{ background: 'gray', height: '1px', width: '100%' }} ></span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-8 pt-2 px-0 py-0 text-center">
                                                                    <img style={{ width: '100%', height: '400px', objectFit: 'contain' }} className="img-fluid" src={`${URLImgFront}/assets${e.imgnotidet}`} alt={e.imgnotidet} />
                                                                </div>
                                                                <div className="col-md-4 pb-4 d-flex align-items-center">
                                                                    <div>
                                                                        <p className="text-left pt-2" style={{ fontSize: '15px', color: 'gray' }}>{e.fecinipublinotidet}</p>
                                                                        <p className="text-left font-weight-bold" style={{ fontSize: '25px' }}>{e.titulonotidet}</p>
                                                                        <p className="text-justify">{e.descripnotidet}</p>
                                                                        <p className="px-5 py-1 position-absolute" style={{ background: '#3f8592', color: 'white', fontWeight: 'bold', borderRadius: '20px', cursor: 'pointer', marginTop: '10px' }}>Conozca más </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                ]
                                            } else {
                                                return [
                                                    <div key={i}>
                                                        <Link to={`/Noticias/Detalles/${e.idnotidet}/${e.titulonotidet.replace(/\s+/g, '-')}`} className="col-md-6 px-4 pl-4 noticia">
                                                            <div className="row px-5 py-5 text-dark" key={i} style={{ cursor: 'pointer' }}>
                                                                <div className="col-md-12">
                                                                    <div className="d-flex align-items-center">
                                                                        <p className="px-4 py-2 my-0 mr-4" style={{ background: '#FA7500', color: '#fff', borderRadius: '1px', fontSize: '20px' }}>{e.tiponoticia}</p>
                                                                        <span style={{ background: 'gray', height: '1px', width: '100%' }} ></span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-8 pt-2 px-0 py-0 text-center">
                                                                    <img style={{ width: '100%', objectFit: 'contain' }} className="img-fluid" src={`${URLImgFront}/assets${e.imgnotidet}`} alt={e.imgnotidet} />
                                                                </div>
                                                                <div className="col-md-4 pb-4 d-flex align-items-center">
                                                                    <div>
                                                                        <p className="text-left pt-2" style={{ fontSize: '15px', color: 'gray' }}>{e.fecinipublinotidet}</p>
                                                                        <p className="text-left font-weight-bold" style={{ fontSize: '25px' }}>{e.titulonotidet}</p>
                                                                        <p className="text-justify">{e.descripnotidet}</p>
                                                                        <p className="px-5 py-1 position-absolute" style={{ background: '#3f8592', color: 'white', fontWeight: 'bold', borderRadius: '20px', cursor: 'pointer', marginTop: '10px' }}>Conozca más </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                ]
                                            }
                                        })}
                                    </Swiper>
                                </div>
                            </div>
                        </div>
                        <div style={{ background: '#fff' }}>
                            <div className="container">
                                <div className="row py-5 d-flex justify-content-center px-2">
                                    {this.state.prioridad2.map((e, i) => {
                                        if (e.urlnotidet !== 'N/A') {
                                            return [
                                                <a href={e.urlnotidet} key={i} className="col-md-6 px-4 pl-4 noticia" target={'_blank'} rel="noopener noreferrer">
                                                    <div className="row my-2 pr-0 text-dark noticia" style={{ cursor: 'pointer', background: '#f5f5f5' }}>
                                                        <div className="col-md-6 px-3 py-3">
                                                            <p className="text-left my-0 font-weight-bold" style={{ fontSize: '15px', color: '#FA7500' }}>{e.tiponoticia}</p>
                                                            <p className="text-left my-0" style={{ fontSize: '15px', color: 'gray' }}>{e.fecinipublinotidet}</p>
                                                            <p className="text-left my-0 font-weight-bold">{e.titulonotidet}</p>
                                                            <p className="text-justify my-0" style={{ fontSize: '13px' }}>{e.descripnotidet}</p>
                                                        </div>
                                                        <div className="col-md-6 px-0">
                                                            <img style={{ width: '100%', height: '130px', objectFit: 'cover' }} width="200" className="img-fluid" src={`${URLImgFront}/assets${e.imgnotidet}`} alt={e.imgnotidet} />
                                                        </div>
                                                    </div>
                                                </a>
                                            ]
                                        } else {
                                            return [
                                                <Link to={`/Noticias/Detalles/${e.idnotidet}/${e.titulonotidet.replace(/\s+/g, '-')}`} key={i} className="col-md-6 px-4 pl-4 noticia">
                                                    <div className="row my-2 pr-0 text-dark noticia" style={{ cursor: 'pointer', background: '#f5f5f5' }}>
                                                        <div className="col-md-6 px-3 py-3">
                                                            <p className="text-left my-0 font-weight-bold" style={{ fontSize: '15px', color: '#FA7500' }}>{e.tiponoticia}</p>
                                                            <p className="text-left my-0" style={{ fontSize: '15px', color: 'gray' }}>{e.fecinipublinotidet}</p>
                                                            <p className="text-left my-0 font-weight-bold">{e.titulonotidet}</p>
                                                            <p className="text-justify my-0" style={{ fontSize: '13px' }}>{e.descripnotidet}</p>
                                                        </div>
                                                        <div className="col-md-6 px-0">
                                                            <img style={{ width: '100%', height: '130px', objectFit: 'cover' }} width="200" className="img-fluid" src={`${URLImgFront}/assets${e.imgnotidet}`} alt={e.imgnotidet} />
                                                        </div>
                                                    </div>
                                                </Link>
                                            ]
                                        }
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="pb-5" style={{ background: '#f5f5f5' }}>
                            <div className="container">
                                <div className="row text-dark pl-0">
                                    <div className="col-md-12 pl-0">
                                        <div className="d-flex align-items-center">
                                            <p className="px-4 py-2 my-0 mr-4" style={{ background: '#428692', color: '#fff', borderRadius: '1px', fontSize: '20px' }}>Otras Noticias</p>
                                            <span style={{ background: 'gray', height: '1px', width: '80%' }} ></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="row pt-5 px-2">
                                    {this.state.prioridad3.map((e, i) => {
                                        if (e.urlnotidet !== 'N/A') {
                                            return [
                                                <a href={e.urlnotidet} key={i} className="col-md-6 px-4 pl-4 noticia" target={'_blank'} rel="noopener noreferrer">
                                                    <div className="row my-2 pr-0 text-dark noticia" style={{ cursor: 'pointer', background: '#fff' }}>
                                                        <div className="col-md-6 px-3 py-3">
                                                            <p className="text-left my-0 font-weight-bold" style={{ fontSize: '15px', color: '#FA7500' }}>{e.tiponoticia}</p>
                                                            <p className="text-left my-0" style={{ fontSize: '15px', color: 'gray' }}>{e.fecinipublinotidet}</p>
                                                            <p className="text-left my-0 font-weight-bold">{e.titulonotidet}</p>
                                                            <p className="text-justify my-0" style={{ fontSize: '13px' }}>{e.descripnotidet}</p>
                                                        </div>
                                                        <div className="col-md-6 px-0">
                                                            <img style={{ width: '100%', height: '130px', objectFit: 'cover' }} width="200" className="img-fluid" src={`${URLImgFront}/assets${e.imgnotidet}`} alt={e.imgnotidet} />
                                                        </div>
                                                    </div>
                                                </a>
                                            ]
                                        } else {
                                            return [
                                                <Link to={`/Noticias/Detalles/${e.idnotidet}/${e.titulonotidet.replace(/\s+/g, '-')}`} key={i} className="col-md-6 px-4 pl-4 noticia">
                                                    <div className="row my-2 pr-0 text-dark noticia" style={{ cursor: 'pointer', background: '#fff' }}>
                                                        <div className="col-md-6 px-3 py-3">
                                                            <p className="text-left my-0 font-weight-bold" style={{ fontSize: '15px', color: '#FA7500' }}>{e.tiponoticia}</p>
                                                            <p className="text-left my-0" style={{ fontSize: '15px', color: 'gray' }}>{e.fecinipublinotidet}</p>
                                                            <p className="text-left my-0 font-weight-bold">{e.titulonotidet}</p>
                                                            <p className="text-justify my-0" style={{ fontSize: '13px' }}>{e.descripnotidet}</p>
                                                        </div>
                                                        <div className="col-md-6 px-0">
                                                            <img style={{ width: '100%', height: '130px', objectFit: 'cover' }} width="200" className="img-fluid" src={`${URLImgFront}/assets${e.imgnotidet}`} alt={e.imgnotidet} />
                                                        </div>
                                                    </div>
                                                </Link>
                                            ]
                                        }
                                    })}
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

export default Noticias;