import React, { Component } from 'react';
import { URLImgFront } from '../../config/URLDB';
import { SeccionTransaccional_ } from '../../services/WebAPI_Utrahuilca';

class Seccion1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stateImg: false,
        };
    }

    async componentDidMount() {
        const data = {
            tran: 7,
            idvtacoopvirt: 0,
            nombvtacoop: 'NA',
            rutaimgcoopvirt: 'NA',
            txtcoopvirtual1: 'NA',
            txtcoopvirtual2: 'NA',
            colortexto: 'NA',
            estado: 1,
            usucrea: 'NA'
        }
        SeccionTransaccional_(data).then(response => {
            try {
                if (response.data.length > 0) {
                    if (response.data.length >= 1) {
                        if (response.data[0].message === "004404") {
                            this.setState({
                                stateImg: false
                            })
                        } else {
                            this.setState({
                                idvtacoopvirt: response.data[0].idvtacoopvirt,
                                nombvtacoop: response.data[0].nombvtacoop,
                                rutaimgcoopvirt: response.data[0].rutaimgcoopvirt,
                                txtcoopvirtual1: response.data[0].txtcoopvirtual1,
                                txtcoopvirtual2: response.data[0].txtcoopvirtual2,
                                colortexto: response.data[0].colortexto,
                                stateImg: true
                            });
                        }
                    } else {
                        this.setState({
                            stateImg: false
                        })
                    }
                } else {
                    this.setState({
                        estadoNuevo: true
                    })
                }
            } catch (error) {
                console.log(error);
            }
        });
    }

    render() {
        const { stateImg } = this.state;
        return (
            <React.Fragment>
                <div className="seccion_transaccional_diseño" style={{ width: '100%', paddingTop: '120px' }}>
                    {stateImg ?
                        (
                            <img style={{ width: '100%', height: '40vh', objectFit: 'cover' }} className="img-fluid" src={`${URLImgFront}/assets${this.state.rutaimgcoopvirt}`} alt={this.state.rutaimgcoopvirt} />
                        ) : (
                            <React.Fragment></React.Fragment>
                        )}
                    <div className="contenido_text_tra text-center" style={{ position: 'absolute', top: '300px', left: '0', right: '0', zIndex: '2' }}>
                        <p style={{ fontSize: '20px', color: '' + this.state.colortexto + '' }}>{this.state.txtcoopvirtual1}</p>
                        <p style={{ fontSize: '40px', color: '' + this.state.colortexto + '', fontWeight: 'bold' }}>{this.state.txtcoopvirtual2}</p>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Seccion1;