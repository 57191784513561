import { MDBIcon, MDBInput, MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import React, { Component } from 'react';
import { CargueImagenes, TrabajeconNosotros_, TrabajeconNosotrosPOST_, TrabajeconNosotrosAdmin_ } from '../../../services/WebAPI_Utrahuilca';
import FileUploaderB from './TrabajaConNosotros/FileUploaderB';
import FileUploader from './TrabajaConNosotros/FileUploader';
import Swal from 'sweetalert2';
import { URLImgFront } from '../../../config/URLDB';

const validate = values => {
    const errors = {}
    if (!values.nombcargo) {
        errors.nombcargo = 'campo obligatorio'
    }
    if (!values.descperfil) {
        errors.descperfil = 'campo obligatorio'
    }
    if (!values.desccargo) {
        errors.desccargo = 'campo obligatorio'
    }
    return errors;
};

class TrabajaConNosotros extends Component {
    uploader = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            estadoNuevo: false,
            errors: {},
            estadoCambioImg1: true,
            estadoSinInfo: false,
            infoConveniosDetalles: [],
            idvacante: null,
            rutaimgvacant: '',
            nombcargo: '',
            descperfil: '',
            desccargo: '',
            estadoCambio: false,
            estado: null,
        };
    }

    async componentDidMount() {
        this.cargarInformacion();
    }

    cargarInformacion = () => {
        const data = {
            tran: 4,
            idvacante: 0,
            rutaimgvacant: 'NA',
            nombcargo: 'NA',
            descperfil: 'NA',
            desccargo: 'NA',
            estado: 0,
            usucrea: 'NA'
        }
        TrabajeconNosotros_(data).then(response => {
            try {
                if (response.data.length > 0) {
                    if (!response.data[0].message) {
                        this.setState({
                            idSeleccionado: response.data[0].idvacante
                        })
                        setTimeout(() => {
                            this.setState({
                                infoConveniosDetalles: response.data,
                                rutaimgvacant: response.data[0].rutaimgvacant,
                                estado: response.data[0].estado,
                                estadoSinInfo: true,
                                estadoImagen: true,
                            })
                        }, 120);
                    } else {
                        this.setState({
                            estadoSinResultados: false,
                            estadoNuevo: true,
                            estadoCambioImg1: true,
                            estadoSinInfo: false
                        })
                    }
                } else { }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    text: 'Ocurrio un error al consumir el servicio, comuniquese con sistemas!'
                })
            }
        });
    }

    textInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    cambioImagen1 = (name, file) => {
        this.setState({
            estadoCambioImg1: true
        });
    }

    cargarImagen = () => {
        var formData = new FormData();
        if (undefined === this.uploader.current) return;
        formData.append("file", this.uploader.current.getFileObject());
        formData.append("rutaImagen", '/Conozcanos/Trabaja con Nosotros/' + this.uploader.current.getFileName());
        CargueImagenes(formData).then(response => {
            if (response) {
                this.cargarInformacion();
            }
        });
    }

    insertarDatos = () => {
        const { errors, ...sinErrors } = this.state;
        const result = validate(sinErrors);
        this.setState({ errors: result });
        if (!Object.keys(result).length) {
            // formulario validado
            const data = {
                idvacante: 0,
                rutaimgvacant: '/Conozcanos/Trabaja con Nosotros/' + this.uploader.current.getFileName(),
                nombcargo: this.state.nombcargo,
                descperfil: this.state.descperfil,
                desccargo: this.state.desccargo,
                estado: 1,
                usucrea: sessionStorage.getItem('idusuario')
            }
            TrabajeconNosotrosPOST_(data).then(response => {
                if (response.data.length > 0) {
                    if (response.data[0].message === "000000") {
                        this.cargarImagen();
                        Swal.fire({
                            icon: 'success',
                            title: 'Se agrego correctamente.'
                        })
                        this.setState({
                            estadoNuevo: false,
                            idvacante: null,
                            rutaimgvacant: '',
                            nombcargo: '',
                            descperfil: '',
                            desccargo: '',
                            estado: null,
                            estadoImagen: false,
                            displayColorPicker: false,
                            rutafondoImg: '',
                            color: '',
                        })
                        this.cargarInformacion();
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error al insertar.'
                        })
                    }
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Ocurrio un error!'
                    })
                }
            });
        }
    }

    editar = (id) => {
        var seleccionado = [];
        this.state.infoConveniosDetalles.map((e, i) => {
            if (e.idvacante === id) {
                seleccionado.push(e)
            }
            return [];
        })
        this.setState({
            idvacante: seleccionado[0].idvacante,
            nombcargo: seleccionado[0].nombcargo,
            descperfil: seleccionado[0].descperfil,
            desccargo: seleccionado[0].desccargo,
            rutaimgvacant: seleccionado[0].rutaimgvacant,
            estadoEditar: true
        })
    }

    cambio = (name, file) => {
        this.setState({
            estadoCambio: true
        });
    }

    actualizarDatos = (id) => {
        var ruta = '';
        var capNombre = this.uploader.current.getFileName();
        if (capNombre === undefined || capNombre === null) {
            ruta = this.state.rutaimgvacant;
        } else {
            ruta = '/Conozcanos/Trabaja con Nosotros/' + this.uploader.current.getFileName();
        }
        const data = {
            idvacante: id,
            rutaimgvacant: ruta,
            nombcargo: this.state.nombcargo,
            descperfil: this.state.descperfil,
            desccargo: this.state.desccargo,
            estado: 1,
            usucrea: sessionStorage.getItem('idusuario')
        }
        TrabajeconNosotrosPOST_(data).then(response => {
            if (response.data.length > 0) {
                if (response.data[0].message === "000000") {
                    this.cargarImagen();
                    this.setState({
                        estadoCambio: false,
                        estadoEditar: false,
                        rutaimgvacant: '',
                        nombcargo: '',
                        descperfil: '',
                        desccargo: '',
                    })
                    Swal.fire({
                        icon: 'success',
                        title: 'Se actualizo satisfactoriamente'
                    })
                    this.cargarInformacion();
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Ocurrio un error al actualizar'
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Ocurrio un error!'
                })
            }
        });
    }

    activar = (id) => {
        const data = {
            idvacante: id,
            estado: 1,
            usucrea: sessionStorage.getItem('idusuario')
        }
        TrabajeconNosotrosAdmin_(data).then(response => {
            if (response) {
                this.setState({
                    estadoS: false
                })
                Swal.fire({
                    icon: 'success',
                    title: 'Se activo satisfactoriamente'
                });
                this.cargarInformacion();
            }
        });
    }

    inactivar = (id) => {
        const data = {
            idvacante: id,
            estado: 0,
            usucrea: sessionStorage.getItem('idusuario')
        }
        TrabajeconNosotrosAdmin_(data).then(response => {
            if (response) {
                this.setState({
                    estadoS: false
                })
                Swal.fire({
                    icon: 'success',
                    title: 'Se inactivo satisfactoriamente'
                });
                this.cargarInformacion();
            }
        });
    }

    handleNew = () => {
        this.setState({
            estadoNuevo: !this.state.estadoNuevo,
            errors: {}
        })
        if (this.state.estadoNuevo) {
            this.setState({
                errors: {},
            })
            this.cargarInformacion();
        }
    }

    render() {
        const { estadoNuevo, estadoCambioImg1, estadoCambio, estadoSinInfo, nombcargo, errors, descperfil, desccargo, estadoEditar, idvacante, infoConveniosDetalles } = this.state;
        return (
            <React.Fragment>
                <p className="font-weight-bold">Trabaje con Nosotros</p>
                <hr />
                {estadoSinInfo ? (
                    <React.Fragment>
                        <div className="plus_add_agencia align-items-center d-flex" onClick={() => { this.handleNew() }}>
                            {estadoNuevo ? <p>Cancelar &nbsp;<MDBIcon icon="times" size="lg" /></p> : <p>Agregar Nuevo &nbsp;<MDBIcon icon="plus-circle" size="lg" /></p>}
                        </div>
                    </React.Fragment>
                ) : (
                    <React.Fragment></React.Fragment>
                )}
                {estadoNuevo ? (
                    <React.Fragment>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 my-2 text-center">
                                    <FileUploaderB ref={this.uploader} estadoImg={estadoCambioImg1} FCambiar={this.cambioImagen1} />
                                </div>
                                <div className="col-md-6 my-2">
                                    <MDBInput label="Nombre del Cargo" name="nombcargo" type="textarea" rows="7" value={nombcargo} onChange={this.textInput} />
                                    {errors.nombcargo && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '50px' }}>{errors.nombcargo}</strong>}
                                </div>
                                <div className="col-md-6 my-2">
                                    <MDBInput label="Perfil del Cargo" name="descperfil" type="textarea" rows="7" value={descperfil} onChange={this.textInput} />
                                    {errors.descperfil && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', marginTop: '-30px' }}>{errors.descperfil}</strong>}
                                </div>
                                <div className="col-md-12 my-2">
                                    <MDBInput label="Descripción del Cargo" name="desccargo" type="textarea" rows="10" value={desccargo} onChange={this.textInput} />
                                    {errors.desccargo && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', marginTop: '-30px' }}>{errors.desccargo}</strong>}
                                </div>
                            </div>
                        </div>
                        <div className="pt-2 mt-5 modal-footer w-100">
                            <button type="button" className="btn btn-primary px-5" onClick={this.insertarDatos}>Crear Oferta &nbsp; <MDBIcon icon="save" /></button>
                        </div>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className="container-fluid">
                            <div className="table-responsive text-center w-100">
                                <MDBTable>
                                    <MDBTableHead>
                                        <tr className="font-weight-bold">
                                            <td>Imagen</td>
                                            <td>Perfil del Cargo</td>
                                            <td>Nombre del Cargo</td>
                                            <td>Descripción del Cargo</td>
                                            {estadoEditar ?
                                                (
                                                    <React.Fragment>
                                                        <td>Guardar</td>
                                                        <td>Cancelar</td>
                                                    </React.Fragment>
                                                ) : (
                                                    <React.Fragment>
                                                        <td>Editar</td>
                                                        <td>Inactivar</td>
                                                    </React.Fragment>
                                                )}
                                        </tr>
                                    </MDBTableHead>
                                    <MDBTableBody>
                                        {estadoEditar ?
                                            (
                                                <React.Fragment>
                                                    <tr>
                                                        <td>
                                                            <FileUploader ref={this.uploader} estadoImg={estadoCambio} ruta={this.state.rutaimgvacant} FCambiar={this.cambio} />
                                                        </td>
                                                        <td className="w-25"><textarea className="form-control" type="textarea" rows="7" name="nombcargo" value={this.state.nombcargo} onChange={this.textInput} /></td>
                                                        <td className="w-25"><textarea className="form-control" type="textarea" rows="7" name="descperfil" value={this.state.descperfil} onChange={this.textInput} /></td>
                                                        <td className="w-50"><textarea className="form-control" type="textarea" rows="30" name="desccargo" value={this.state.desccargo} onChange={this.textInput} /></td>
                                                        <td className="text-center" onClick={() => { this.actualizarDatos(idvacante) }}><MDBIcon icon="save" size="2x" style={{ color: 'blue', cursor: 'pointer' }} /></td>
                                                        <td className="text-center" onClick={() => { this.setState({ estadoEditar: !this.state.estadoEditar }) }}><MDBIcon icon="times" size="2x" style={{ color: 'red', cursor: 'pointer' }} /></td>
                                                    </tr>
                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment>
                                                    {infoConveniosDetalles.map((e, i) => {
                                                        let view;
                                                        if (e.estado === 1) {
                                                            view = <MDBIcon onClick={() => { this.inactivar(e.idvacante) }} icon="eye" size="2x" style={{ cursor: 'pointer' }} />
                                                        } else {
                                                            view = <MDBIcon onClick={() => { this.activar(e.idvacante) }} icon="eye-slash" size="2x" style={{ cursor: 'pointer' }} />
                                                        }
                                                        return [
                                                            <React.Fragment key={i}>
                                                                <tr>
                                                                    <td><img width="180" src={`${URLImgFront}/assets${e.rutaimgvacant}`} alt={e.rutaimgvacant} /></td>
                                                                    <td>{e.nombcargo}</td>
                                                                    <td>{e.descperfil}</td>
                                                                    <td>{e.desccargo}</td>
                                                                    <td className="text-center" onClick={() => { this.editar(e.idvacante) }}><MDBIcon icon="edit" size="2x" style={{ color: 'green', cursor: 'pointer' }} /></td>
                                                                    <td className="text-center">{view}</td>
                                                                </tr>
                                                            </React.Fragment>
                                                        ]
                                                    })}
                                                </React.Fragment>
                                            )}
                                    </MDBTableBody>
                                </MDBTable>
                            </div>
                        </div>
                    </React.Fragment>
                )}

            </React.Fragment>
        );
    }
}

export default TrabajaConNosotros;