import React, { Component } from 'react';
import 'swiper/css/swiper.min.css';
import { URLImgFront } from '../../config/URLDB';
import { MDBTable, MDBTableBody, MDBTableHead, MDBInput } from 'mdbreact';
import { RevalorizacionAsociado_ } from '../../services/WebAPI_Utrahuilca';
import Loading from '../Loading';

const formatterPeso = new Intl.NumberFormat('es-CO', {
    currency: 'COP',
    minimumFractionDigits: 2
})

const validate = values => {
    const errors = {}
    if (!values.nit) {
        errors.nit = 'campo obligatorio'
    }
    if (!values.fecini) {
        errors.fecini = 'campo obligatorio'
    }
    return errors;
};

class Seccion2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            stateTable: false,
            nit: '',
            fecini: '',
            stateLoadign: false
        };
    }

    textInput = (e) => {
        if (e.target.name === "nit") {
            let regex = new RegExp("^[a-zA-Z0-9]+$");
            if (regex.test(e.target.value) || e.target.value === "") {
                this.setState({
                    [e.target.name]: e.target.value
                });
            }
        } else {
            this.setState({
                [e.target.name]: e.target.value
            })
        }
    }

    sendForm = () => {
        const { errors, ...sinErrors } = this.state;
        const result = validate(sinErrors);
        this.setState({ errors: result });
        if (!Object.keys(result).length) {
            // formulario validado
            this.setState({
                stateLoadign: true
            })
            
            const data = {
                tipoiden: 'NA',
                nit: this.state.nit,
                fecini: this.state.fecini
            }
            
            RevalorizacionAsociado_(data).then(response => {
                try {
                    this.setState({
                        stateLoadign: false
                    })
                    if (response.data.length > 0) {
                        this.setState({
                            infoRevalorizacion: response,
                            stateTable: true
                        })
                    } else { }
                } catch (error) {
                    console.log(error);
                }
            });
        }
    }

    render() {
        const { errors } = this.state;
        return (
            <React.Fragment>
                <div className="bg-white" style={{ width: '100%' }}>
                    <div className="container-fluid text-center">
                        <div className="d-flex py-3 justify-content-center align-items-center">
                            <img style={{ width: '130px', height: '130px' }} className="img-fluid" src={`${URLImgFront}/assets/img-variadas/lb_consulta.png`} alt="img-1" />
                            <p className="texto-contenido mt-5 text-justify">Asociado/a, conozca el rendimiento de sus aportes sociales obtenido durante el último año. </p>
                        </div>
                        <div className="px-0 mx-0 pt-5" style={{ background: '#f7f7f7' }}>
                            <div className="container pb-5">
                                {this.state.stateTable ? (
                                    <React.Fragment>
                                        <h5>Historial de Revalorizaciones</h5>
                                        <hr />

                                        <div className="table-responsive">
                                            <MDBTable>
                                                <MDBTableHead textWhite style={{ background: '#FA7500' }}>
                                                    <tr>
                                                        <th>Fecha de Transacción</th>
                                                        <th>Operación</th>
                                                        <th>Valor</th>
                                                    </tr>
                                                </MDBTableHead>

                                                <MDBTableBody>
                                                    {this.state.infoRevalorizacion.data.map((e, i) => {
                                                        if (e.respuesta) {
                                                            return (
                                                                <React.Fragment key={i}>
                                                                    <tr>
                                                                        <td><p className="text-left">{e.respuesta}</p></td>
                                                                    </tr>
                                                                </React.Fragment>
                                                            );
                                                        }
                                                        else {
                                                            var fecha = new Date(e.fectran);
                                                            return [
                                                                <React.Fragment key={i}>
                                                                    <tr>
                                                                        <td><p className="text-center">{fecha.getDate() + "/" + fecha.getMonth() + "/" + fecha.getFullYear()}</p></td>
                                                                        <td><p className="text-rigth"><b style={{ color: '#FA7500' }}>$</b> {formatterPeso.format(e.valefe)}</p></td>
                                                                        <td><p className="text-left">{e.nomboper}</p></td>
                                                                    </tr>
                                                                </React.Fragment>
                                                            ]
                                                        }
                                                    })}
                                                </MDBTableBody>
                                            </MDBTable>
                                        </div>
                                        <div className="mt-5">
                                            <span onClick={() => { this.setState({ stateTable: false, infoRevalorizacion: [], nit: '', fecini: '' }) }} className="px-5 py-3 text-white mt-5" style={{ background: 'gray', borderRadius: '30px', cursor: 'pointer' }}>Regresar</span>
                                        </div>
                                    </React.Fragment>
                                ) : (<React.Fragment>
                                    <div className="row">
                                        <div className="col-md-4 px-5">
                                            <p className="text-left" style={{ fontWeight: 'bold', color: 'gray' }}>NÚMERO DE DOCUMENTO</p>
                                            <MDBInput maxLength="20" name="nit" label="Ingrese su numero de documento" type="text" className="pt-3" value={this.state.nit} onChange={this.textInput} />
                                            {errors.nit && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '80px', left: '50px' }}>{errors.nit}</strong>}
                                        </div>
                                        <div className="col-md-4 px-5">
                                            <p className="text-left" style={{ fontWeight: 'bold', color: 'gray' }}>FECHA DE EXPEDICIÓN</p>
                                            <MDBInput name="fecini" type="date" className="pt-3" onChange={this.textInput} />
                                            {errors.fecini && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '80px', left: '50px' }}>{errors.fecini}</strong>}
                                        </div>
                                        <div className="col-md-4 d-flex align-items-center justify-content-center">
                                            <div>
                                                <span onClick={this.sendForm} className="px-5 text-white py-2" style={{ background: '#FA7500', borderRadius: '20px', cursor: 'pointer' }}>Consultar</span>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>)}
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.stateLoadign ? (
                    <Loading />
                ) : (<React.Fragment></React.Fragment>)}
            </React.Fragment>
        );
    }
}

export default Seccion2;