import React, { Component } from 'react';
import { URLImgFront } from '../../../config/URLDB';

class Seccion03 extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="bg-white">
                    <div className="mb-2 seccion03" style={{ width: '100%' }}>
                        <div className="d-flex justify-content-center pt-1 pt-md-3">
                            <img style={{ width: '100%', marginBottom: '0px', objectFit: 'contain' }} className="img-fluid" src={`${URLImgFront}/assets/EstrategiasComerciales/TPTG_PARTICIPAN.png`} alt="Estrategia" />
                        </div>
                    </div>
                    <div className="container">
                        <div className="mb-3 pt-2" style={{ background: '#fff', width: '100%' }}>
                            <div className="pt-4">
                                <img style={{ width: '100%', marginBottom: '0px', objectFit: 'contain' }} className="img-fluid" src={`${URLImgFront}/assets/EstrategiasComerciales/TPTG_PRODUCTOS.png`} alt="Estrategia" />
                            </div>
                        </div>
                    </div>
                    <div className="row pb-3 pb-md-5 text-center m-0" style={{ fontFamily: 'Posterama 1927', color: '#0B6A85', fontWeight: 'bold', fontSize:'3vw' }}>
                        <div className="col-12 pl-0 pr-0">
                            Condiciones de participación
                        </div>
                        <div className="col-12 pl-0 pr-0">
                            <small>por productos y asignación de números en cada uno de los sorteos:</small>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Seccion03;