import React, { Component } from 'react';
import { URLImgFront } from '../../config/URLDB';

class Seccion1 extends Component {

    render() {
        return (
            <React.Fragment>
                <div className="bg-white productos_vista" style={{ width: '100%', paddingTop: '120px' }}>
                    <div style={{ width: '100%', height: '60vh' }}>
                        <img style={{ position: 'absolute', right: '200px', top: '200px', width: '450px' }} className="img-fluid img_principal" src={`${URLImgFront}/assets/AvisoPrivacidad/avisoprivacidad.webp`} alt="img-aviso-privacidad" />
                    </div>
                    <div className="contenido_flotante" style={{ position: 'absolute', top: '280px', left: '160px' }}>
                        <span className="w-75 font-weight-bold texto1" style={{ fontSize: '40px', color: '#3f8592' }}>Aviso de privacidad</span><br />
                        <span className="w-75 texto2" style={{ fontSize: '60px', color: '#747373', fontWeight: 'bold' }}>PROTECCIÓN DE DATOS</span>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Seccion1;