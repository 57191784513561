import React, { Component } from 'react';

class Seccion2 extends Component {

    render() {
        return (
            <React.Fragment>
                <div style={{ width: '100%', backgroundColor: '#fff', paddingTop: '100px' }}>
                    <div style={{ textAlign: 'justify', paddingLeft: '60px', paddingRight: '60px', paddingBottom: '60px' }}>
                        <p>
                            La Cooperativa Latinoamericana de Ahorro y Crédito - UTRAHUILCA identificada con NIT 891.100.673-
                            9, legalmente constituida, con domicilio principal en la Carrera 6 No. 5 – 37 Neiva-Huila, PBX (8) 8728182
                            y página web <a href="https://www.utrahuilca.coop/" target="_black">www.utrahuilca.coop</a>, es una entidad vigilada por la Superintendencia de Economía
                            Solidaria, inscrita en FOGACOOP.
                        </p>
                        <p>
                            UTRAHUILCA, con fin de dar cumplimiento a lo establecido en la Ley 1581 de 2012 y el Decreto
                            Reglamentario 1377 de 2013 informa a sus asociados, proveedores, visitantes y comunidad en general,
                            que dispone del presente Aviso de Privacidad que pretende informar a los Titulares de la información,
                            acerca de la existencia de las Políticas de Tratamiento de Información que han sido adoptadas por la
                            Cooperativa, la forma de acceder a éstas y las características del tratamiento que se pretende dar a los
                            datos.
                        </p>
                        <p>
                            Así mismo comunica que para el desarrollo de su objeto social requiere de la información personal de
                            sus asociados, proveedores y/o terceros, haciéndose responsable del tratamiento de la base de datos
                            que contiene dicha información, encargándose de efectuar una adecuada recolección, tratamiento,
                            conservación y confidencialidad de los mismos, siempre salvaguardando los derechos de los titulares
                            de la información y atendiendo a las finalidades de cada uno de los datos recolectados en las bases de
                            datos.
                        </p>
                        <p>
                            Ante lo anterior, UTRAHUILCA advierte que a la fecha ha obtenido información de asociados,
                            proveedores y/o terceros que contiene datos personales de los mismos, con ocasión de las relaciones
                            comerciales y contractuales desarrolladas en el giro ordinario de sus negocios y requiere su autorización
                            para continuar con el tratamiento de dicha información para las siguientes finalidades:
                        </p>
                        <p style={{ marginLeft: '18px' }}>
                            <ol type="1">
                                <li style={{ fontWeight: 'bold' }}>Datos personales Clientes (Asociados).</li>
                                <ol type="a" style={{ marginLeft: '20px', marginTop: '20px' }}>
                                    <li>
                                        Comunicar y enviar información sobre las campañas comerciales relacionadas con la
                                        actualización de datos en la Cooperativa, dando a conocer los beneficios derivados de
                                        actualizar y los medios o canales dispuestos para dicho trámite.
                                    </li>
                                    <li>
                                        Comunicar y enviar información sobre productos y servicios, actividades comerciales,
                                        promocionales e invitaciones de UTRAHUILCA.
                                    </li>
                                    <li>
                                        Realizar estudios internos sobre hábitos de consumo de los servicios y productos ofrecidos
                                        por UTRAHUILCA.
                                    </li>
                                    <li>
                                        Realizar encuestas y/o sondeos de opinión sobre productos y servicios, que nos permitan
                                        realizar una segmentación del mercado, análisis de consumo, preferencias y evaluar la
                                        calidad de nuestros productos y servicios.
                                    </li>
                                    <li>
                                        Estudiar y atender las solicitudes de productos y servicios correspondientes al ejercicio de
                                        la actividad y objeto social de UTRAHUILCA.
                                    </li>
                                    <li>
                                        Realizar controles propios de UTRAHUILCA con el fin de prevenir actividades ilícitas y
                                        cumplir con las obligaciones legales en la materia.
                                    </li>
                                    <li>
                                        Brindar la atención oportuna a todas las solicitudes de nuestros asociados, tales como
                                        generación de extractos, certificados, constancias, entre otros, y para mantener
                                        comunicación por correo físico, teléfono, correo electrónico y/o redes sociales.
                                    </li>
                                    <li>
                                        Dar cumplimiento a obligaciones contraídas con las personas asociados.
                                    </li>
                                    <li>
                                        Gestionar el cumplimiento de las obligaciones económicas contraídas por los asociados
                                        con la Cooperativa de conformidad con la legislación vigente, a través de actividades
                                        propias de cobranza. Así mismo conocer e informar acerca del comportamiento crediticio,
                                        comercial y financiero de nuestros asociados, en los diferentes Buró de crédito y demás
                                        entidades que ejercen esta actividad.
                                    </li>
                                    <li>
                                        Confirmar, referenciar la información que los asociados indican en el proceso de vinculación
                                        o solicitud de productos y servicios de la Cooperativa.
                                    </li>
                                </ol>
                                <li style={{ marginTop: '20px', fontWeight: 'bold' }}>Datos personales Proveedores. </li>
                                <ol type="a" style={{ marginLeft: '20px', marginTop: '20px' }}>
                                    <li>
                                        Ejecutar los controles establecidos por UTRAHUILCA a los proveedores, con el fin de
                                        prevenir actividades ilícitas y cumplir con las obligaciones legales sobre la materia.
                                    </li>
                                    <li>
                                        Gestionar las relaciones contractuales establecidas con las personas que suministran los
                                        productos y/o servicios que UTRAHUILCA necesita de forma complementaria para cumplir
                                        su objeto social.
                                    </li>
                                    <li>
                                        Confirmar y referenciar la información que los proveedores indican en el proceso de
                                        vinculación a UTRAHUILCA.
                                    </li>
                                </ol>

                                <li style={{ marginTop: '20px', fontWeight: 'bold' }}>Datos personales Organismos de Administración y control.</li>
                                <ol type="a" style={{ marginLeft: '20px', marginTop: '20px' }}>
                                    <li>
                                        Gestionar las relaciones contractuales establecidas con las personas naturales que asumen
                                        cargos de dirección dentro de los organismos de administración y control de la Cooperativa.
                                        Ejecutar los controles establecidos por UTRAHUILCA a los proveedores, con el fin de
                                        prevenir actividades ilícitas y cumplir con las obligaciones legales sobre la materia.
                                    </li>
                                </ol>
                                <li style={{ marginTop: '20px', fontWeight: 'bold' }}>Datos personales Terceros.</li>
                                <ol type="a" style={{ marginLeft: '20px', marginTop: '20px' }}>
                                    <li>
                                        Gestionar el cumplimiento de las obligaciones económicas contraídas con la Cooperativa
                                        directa o indirectamente de conformidad con la legislación vigente, a través de actividades
                                        propias de cobranza. Así mismo conocer e informar acerca del comportamiento crediticio,
                                        comercial y financiero de nuestros asociados, en los diferentes Buró de crédito y demás
                                        entidades que ejercen esta actividad.
                                    </li>
                                    <li>
                                        Confirmar, referenciar la información que los asociados indican en el proceso de vinculación
                                        o solicitud de productos y servicios de la Cooperativa.
                                    </li>
                                    <li>
                                        Evaluar Candidatos a ser funcionarios de la Cooperativa.
                                    </li>
                                </ol>
                            </ol>
                        </p>
                        <p>
                            UTRAHUILCA, en su condición de responsable del tratamiento de los datos personales aplica las
                            políticas de tratamiento de la información contenida en el Manual Interno de Políticas y Procedimientos
                            para el Tratamiento de Datos Personales, publicado en la página web <a href="https://www.utrahuilca.coop/" target="_black">www.utrahuilca.coop</a>.
                        </p>
                        <p>
                            Los derechos que le asisten a los titulares de la información son:
                        </p>
                        <p>
                            <div style={{ marginLeft: '20px' }}>
                                <li>
                                    Conocer, actualizar, rectificar y/o solicitar la supresión de sus datos personales.
                                </li>
                                <li>
                                    Solicitar prueba de la autorización otorgada a UTRAHUILCA para el tratamiento de sus datos
                                    personales.
                                </li>
                                <li>
                                    Ser informado respecto al uso que se le ha dado a sus datos personales.
                                </li>
                                <li>
                                    Presentar quejas por el indebido Tratamiento de su información personal ante la
                                    Superintendencia de Industria y Comercio.
                                </li>
                                <li>
                                    Revocar la autorización otorgada cuando el titular lo considere.
                                </li>
                                <li>
                                    Presentar quejas por el tratamiento indebido de su información personal ante la entidad
                                    administrativa encargada de la protección de los datos personales.
                                </li>
                                <li>
                                    Acceder o consultar de forma gratuita sus datos personales que fueron objeto de tratamiento.
                                </li>
                            </div>
                        </p>
                        <p>
                            Para ejercer estos derechos, puede contactarse a través del correo electrónico a la dirección:
                        </p>
                        <p>
                            <a href="mailto:servicioalasociado@utrahuilca.com" target="_black">servicioalasociado@utrahuilca.com</a> o en la página web <a href="https://www.utrahuilca.coop/" target="_black">www.utrahuilca.coop</a>.
                        </p>
                        <p>
                            El Titular de la información puede acceder al contenido de nuestra Política de Tratamiento de
                            Información, que se encuentra publicado en la página web de la Cooperativa <a href="https://www.utrahuilca.coop/" target="black">www.utrahuilca.coop</a>.
                        </p>
                        <p>
                            Cualquier cambio que se efectúe a las políticas o al aviso de privacidad será informado en &nbsp;
                            <a href="https://www.utrahuilca.coop/" target="_black">www.utrahuilca.coop</a> y en los medios de comunicación dispuestos por UTRAHUILCA
                        </p>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Seccion2;