import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import './App.css';
import Simula from './components/Simulator/Simula';
//import Campañas from './pages/Campañas'; //2022/11/22
import Preguntas_Frecuentes from './pages/Preguntas_Frecuentes';
import Principal from './pages/Principal';
import ProductoDimanico from './components/Producto/ProductoDimanico';
// import Productos_Ahorros from './components/Producto/Producto_Ahorro';
import Red_Agencias from './pages/Red_Agencias';
import Solicitud from './components/Producto/ProductoDinamico/Solicitud';
import Seguros from './pages/Seguros';
import Convenios from './pages/Convenios';
import CreditoLibranza from './pages/CreditoLibranza';
import QuieroAsociarme from './pages/QuieroAsociarme';
import Asociados from './pages/Asociados';
import CDAT from './pages/CDAT';
// import CreditoDigital from './pages/CreditoDigital';
import SeccionTransaccional from './pages/SeccionTransaccional';
import PSE from './pages/PSE';
import Revalorizacion from './pages/Revalorizacion';
import Conozcanos from './pages/Conozcanos';
import DescargarFormatos from './pages/DescargarFormatos';
import Comunicados from './pages/Comunicados';
import EventosConcursos from './pages/EventosConcursos';
//import EventosCampañas from './pages/EventosCampañas'; //2022/11/22
import Coopsejos from './pages/Coopsejos';
import InfantilJuvenil from './pages/InfantilJuvenil';
import Login from './Administrador/Pages/Login';
import PrincipalAdmin from './Administrador/Pages/Principal';
import LoginFundacion from './Administrador/PagesFundacion/LoginFundacion'
import PrincipalFunda from './Administrador/PagesFundacion/PrincipalFundacion';
import Error404 from './pages/Error404';
import CanalesAtencion from './pages/CanalesAtencion';
import Servicios from './pages/Servicios';
import Beneficios from './pages/Beneficios';
import TrabajeConNosotros from './pages/TrabajeConNosotros';
import PQRSF from './pages/PQRSF';
import Detalles from './components/Coopsejos/Seccion2/Detalles/Detalles';
import Productos from './pages/Productos';
import FormSolicMultiportal from './components/Formularios/FormSolicMultiportal';
//import Reuniones_Cooperativas from './pages/Reuniones_Cooperativas';

import Documentos from './pages/Documentos';
//import Coolac from './pages/Coolac';
import ActualizacionWebDian from './pages/ActualizacionWebDian';

//import EstrategiasComerciales from './pages/EstrategiasComerciales';
//import EstrategiasComercialesMSS from './pages/EstrategiasComercialesMSS';
/* Se agrega nuevo import para la estrategia RQTO TODOS PONEN, TODOS GANAN - OASU y CCSV */
import EstrategiasComercialesTPTG from './pages/EstrategiasComercialesTPTG';
//2023/12/20
import AvisoPrivacidad from './pages/AvisoPrivacidad';
//import EleccionesDelegados from './pages/EleccionesDelegados';

function App() {
    return (
        <Router>
            <Switch>
                <Route exact path="/" component={Principal} initial={true} />
                <Route exact path="/Productos/:name/:id/:idtp" component={Productos} />
                {/* <Route exact path="/Productos/Ahorros/UtraCrecer" component={Productos_Ahorros} /> */}
                <Route exact path="/Productos/:id/:name" component={ProductoDimanico} initial={true} />
                <Route exact path="/Asesoria" component={Solicitud}  initial={true}/>
                <Route exact path="/Preguntas_Frecuentes" component={Preguntas_Frecuentes} />
                <Route exact path="/Red_Agencias" component={Red_Agencias} />
                <Route exact path="/Simulador/:name" component={Simula} />
                {/*<Route exact path="/Campaña/:id" component={Campañas} />*/}
                <Route exact path="/Seguros" component={Seguros} />
                <Route exact path="/Convenios/:name" component={Convenios} />
                <Route exact path="/Credito_Libranza" component={CreditoLibranza} />
                <Route exact path="/Quiero_Asociarme" component={QuieroAsociarme} />
                <Route exact path="/Asociados" component={Asociados} />
                <Route exact path="/CDAT" component={CDAT} />
                {/* <Route exact path="/Credito_Digital" component={CreditoDigital} /> */}
                <Route exact path="/Cooperativa_Virtual" component={SeccionTransaccional} />
                <Route exact path="/PSE" component={PSE} />
                <Route exact path="/Revalorizacion" component={Revalorizacion} />
                <Route exact path="/Conozcanos" component={Conozcanos} />
                <Route exact path="/Descargar_Formatos" component={DescargarFormatos} />
                <Route exact path="/Comunicados" component={Comunicados} />
                <Route exact path="/Eventos_Concursos" component={EventosConcursos} />
                {/*<Route exact path="/Campañas" component={EventosCampañas} />*/}
                <Route exact path="/Coopsejos" component={Coopsejos} />
                <Route exact path="/Infantil_Juvenil" component={InfantilJuvenil} />
                <Route exact path="/Canales_Atencion" component={CanalesAtencion} />
                <Route exact path="/Servicios" component={Servicios} />
                <Route exact path="/Beneficios" component={Beneficios} />
                <Route exact path="/Trabaje_con_nosotros" component={TrabajeConNosotros} />
                <Route exact path="/PQRSF" component={PQRSF} />
                <Route exact path="/Noticias/Detalles/:id/:id2" component={Detalles} />
                <Route exact path="/Solicitud_Multiportal" component={FormSolicMultiportal} />

                <Route path="/Documentos/:ruta1/:ruta2" component={Documentos}></Route>
                <Route exact path="/Documentos/:ruta1" component={Documentos}></Route>
                {/*<Route exact path="/Coolac_Utrahuilca" component={Coolac}></Route>*/}
                {/*<Route exact path="/Reuniones_Coop" component={Reuniones_Cooperativas}></Route>*/}
                <Route exact path="/ActualizacionWebDian" component={ActualizacionWebDian}></Route>

                {/* Ruta nueva para estrategias comerciales */}
                {/*<Route exact path="/AportesQuePremian" component={EstrategiasComerciales}></Route>*/}
                {/*<Route exact path="/MoviliceSusSuenos" component={EstrategiasComercialesMSS}></Route>*/}
                {/* Ruta nueva estrategia RQTO TODOS PONEN, TODOS GANAN - OASU y CCSV */}
                <Route exact path="/TodosPonenTodosGanan" component={EstrategiasComercialesTPTG}></Route>

                {/* Rutas para el Administrador */}
                <Route exact path="/admin/login/utra" component={Login} />
                <Route exact path="/admin/principal" component={PrincipalAdmin} />

                {/* Rutas para el administrador de la fundacion*/}
                <Route exact path="/fundacion/login" component={LoginFundacion} />
                <Route exact path="/fundacion/principal" component={PrincipalFunda} />

                {/* Ruta para aviso de privacidad */}
                <Route exact path="/AvisoPrivacidad" component={AvisoPrivacidad} />

                {/*Ruta para las elecciones de delegados*/}
                {/*<Route exact path="/EleccionesDelegados" component={EleccionesDelegados} />*/}

                <Route component={Error404} />
            </Switch>
        </Router>
    );
}

export default App;