import { MDBIcon, MDBInput, MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import React, { Component } from 'react';
import { CargueImagenes, PreguntasFrecuentesLista_ } from '../../../services/WebAPI_Utrahuilca';
import FileUploaderB from './Preguntas/FileUploaderB';
import FileUploader from './Preguntas/FileUploader';
import Swal from 'sweetalert2';
import { URLImgFront } from '../../../config/URLDB';

const validate = values => {
    const errors = {}
    if (values.prioridad === 'DEFAULT') {
        errors.prioridad = 'campo obligatorio'
    }
    if (!values.titulopreg) {
        errors.titulopreg = 'campo obligatorio'
    }
    if (!values.descpregfrec1) {
        errors.descpregfrec1 = 'campo obligatorio'
    }
    return errors;
};

class Preguntas extends Component {
    uploader = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            estadoNuevo: false,
            errors: {},
            estadoCambioImg1: true,
            estadoSinInfo: false,
            infoConveniosDetalles: [],
            idpregfrecuente: null,
            rutaiconpreg: '',
            prioridad: 'DEFAULT',
            titulopreg: '',
            descpregfrec1: '',
            descpregfrec2: '',
            descpregfrec3: '',
            descpregfrec4: '',
            editprioridad: 'DEFAULT',
            edittitulopreg: '',
            editdescpregfrec1: '',
            editdescpregfrec2: '',
            editdescpregfrec3: '',
            editdescpregfrec4: '',
            estadoCambio: false,
            estado: null
        };
    }

    async componentDidMount() {
        this.cargarInformacion();
    }

    cargarInformacion = () => {
        const data = {
            tran: 4,
            idpregfrecuente: 0,
            rutaiconpreg: 'NA',
            prioridad: 0,
            titulopreg: 'NA',
            descpregfrec1: 'NA',
            descpregfrec2: 'NA',
            descpregfrec3: 'NA',
            descpregfrec4: 'NA',
            estado: 0,
        }
        PreguntasFrecuentesLista_(data).then(response => {
            try {
                if (response.data.length > 0) {
                    if (!response.data[0].message) {
                        this.setState({
                            idSeleccionado: response.data[0].idpregfrecuente
                        })
                        setTimeout(() => {
                            this.setState({
                                infoConveniosDetalles: response.data,
                                rutaiconpreg: response.data[0].rutaiconpreg,
                                estado: response.data[0].estado,
                                estadoSinInfo: true,
                                estadoImagen: true,
                            })
                        }, 120);
                    } else {
                        this.setState({
                            estadoSinResultados: false,
                            estadoNuevo: true,
                            estadoCambioImg1: true,
                            estadoSinInfo: false
                        })
                    }
                } else { }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    text: 'Ocurrio un error al consumir el servicio!'
                })
            }
        });
    }

    textInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    cambioImagen1 = (name, file) => {
        this.setState({
            estadoCambioImg1: true
        });
    }

    cargarImagen = () => {
        var formData = new FormData();
        if (undefined === this.uploader.current) return;
        formData.append("file", this.uploader.current.getFileObject());
        formData.append("rutaImagen", '/Preguntas Frecuentes/Preguntas/' + this.uploader.current.getFileName());
        CargueImagenes(formData).then(response => {
            if (response) {
                this.cargarInformacion();
            }
        });
    }

    insertarDatos = () => {
        const { errors, ...sinErrors } = this.state;
        const result = validate(sinErrors);
        this.setState({ errors: result });
        if (!Object.keys(result).length) {
            // formulario validado
            const data = {
                tran: 1,
                idpregfrecuente: 0,
                rutaiconpreg: '/Preguntas Frecuentes/Preguntas/' + this.uploader.current.getFileName(),
                prioridad: parseInt(this.state.prioridad),
                titulopreg: this.state.titulopreg,
                descpregfrec1: this.state.descpregfrec1,
                descpregfrec2: this.state.descpregfrec2,
                descpregfrec3: this.state.descpregfrec3,
                descpregfrec4: this.state.descpregfrec4,
                estado: 1,
            }
            PreguntasFrecuentesLista_(data).then(response => {
                if (response.data.length > 0) {
                    if (response.data[0].message === "000000") {
                        this.cargarImagen();
                        Swal.fire({
                            icon: 'success',
                            title: 'Se agrego correctamente.'
                        })
                        this.setState({
                            estadoNuevo: false,
                            idpregfrecuente: null,
                            rutaiconpreg: '',
                            prioridad: 'DEFAULT',
                            titulopreg: '',
                            descpregfrec1: '',
                            descpregfrec2: '',
                            descpregfrec3: '',
                            descpregfrec4: '',
                            estado: null,
                            estadoImagen: false,
                            displayColorPicker: false,
                            rutafondoImg: '',
                            color: '',
                        })
                        this.cargarInformacion();
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error al insertar.'
                        })
                    }
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Ocurrio un error!'
                    })
                }
            });
        }
    }

    editar = (id) => {
        var seleccionado = [];
        this.state.infoConveniosDetalles.map((e) => {
            if (e.idpregfrecuente === id) {
                seleccionado.push(e)
            }
            return [];
        })
        this.setState({
            idpregfrecuente: seleccionado[0].idpregfrecuente,
            edittitulopreg: seleccionado[0].titulopreg,
            editdescpregfrec1: seleccionado[0].descpregfrec1,
            editdescpregfrec2: seleccionado[0].descpregfrec2,
            editdescpregfrec3: seleccionado[0].descpregfrec3,
            editdescpregfrec4: seleccionado[0].descpregfrec4,
            rutaiconpreg: seleccionado[0].rutaiconpreg,
            editprioridad: seleccionado[0].prioridad,
            estadoEditar: true
        })
    }

    cambio = (name, file) => {
        this.setState({
            estadoCambio: true
        });
    }

    actualizarDatos = (id) => {
        var ruta = '';
        var capNombre = this.uploader.current.getFileName();
        if (capNombre === undefined || capNombre === null) {
            ruta = this.state.rutaiconpreg;
        } else {
            ruta = '/Preguntas Frecuentes/Preguntas/' + this.uploader.current.getFileName();
        }
        var descpregfrec2 = "";
        var descpregfrec3 = "";
        var descpregfrec4 = "";
        if (this.state.editdescpregfrec2 === null || this.state.editdescpregfrec2 === "null") {
            descpregfrec2 = "";
        } else {
            descpregfrec2 = this.state.editdescpregfrec2;
        }
        if (this.state.editdescpregfrec3 === null || this.state.editdescpregfrec3 === "null") {
            descpregfrec3 = "";
        } else {
            descpregfrec3 = this.state.editdescpregfrec3;
        }
        if (this.state.editdescpregfrec4 === null || this.state.editdescpregfrec4 === "null") {
            descpregfrec4 = "";
        } else {
            descpregfrec4 = this.state.editdescpregfrec4;
        }
        const data = {
            tran: 1,
            idpregfrecuente: id,
            rutaiconpreg: ruta,
            prioridad: parseInt(this.state.editprioridad),
            titulopreg: this.state.edittitulopreg,
            descpregfrec1: this.state.editdescpregfrec1,
            descpregfrec2: descpregfrec2,
            descpregfrec3: descpregfrec3,
            descpregfrec4: descpregfrec4,
            estado: 1,
        }
        PreguntasFrecuentesLista_(data).then(response => {
            if (response.data.length > 0) {
                if (response.data[0].message === "000000") {
                    this.cargarImagen();
                    this.setState({
                        estadoCambio: false,
                        estadoEditar: false,
                        rutaiconpreg: '',
                        editprioridad: 'DEFAULT',
                        edittitulopreg: '',
                        editdescpregfrec1: '',
                        editdescpregfrec2: '',
                        editdescpregfrec3: '',
                        editdescpregfrec4: '',
                    })
                    Swal.fire({
                        icon: 'success',
                        title: 'Se actualizo satisfactoriamente'
                    })
                    this.cargarInformacion();
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Ocurrio un error al actualizar'
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Ocurrio un error!'
                })
            }
        });
    }

    activar = (id) => {
        const data = {
            tran: 6,
            idpregfrecuente: id,
            rutaiconpreg: 'NA',
            prioridad: 0,
            titulopreg: 'NA',
            descpregfrec1: 'NA',
            descpregfrec2: 'NA',
            descpregfrec3: 'NA',
            descpregfrec4: 'NA',
            estado: 1
        }
        PreguntasFrecuentesLista_(data).then(response => {
            if (response) {
                this.setState({
                    estadoS: false
                })
                Swal.fire({
                    icon: 'success',
                    title: 'Se activo satisfactoriamente'
                });
                this.cargarInformacion();
            }
        });
    }

    inactivar = (id) => {
        const data = {
            tran: 6,
            idpregfrecuente: id,
            rutaiconpreg: 'NA',
            prioridad: 0,
            titulopreg: 'NA',
            descpregfrec1: 'NA',
            descpregfrec2: 'NA',
            descpregfrec3: 'NA',
            descpregfrec4: 'NA',
            estado: 0
        }
        PreguntasFrecuentesLista_(data).then(response => {
            if (response) {
                this.setState({
                    estadoS: false
                })
                Swal.fire({
                    icon: 'success',
                    title: 'Se inactivo satisfactoriamente'
                });
                this.cargarInformacion();
            }
        });
    }

    handleNew = () => {
        this.setState({
            estadoNuevo: !this.state.estadoNuevo,
            errors: {}
        })
        if (this.state.estadoNuevo) {
            this.setState({
                errors: {}
            })
            this.cargarInformacion();
        }
    }

    render() {
        const { estadoNuevo, estadoCambioImg1, estadoCambio, estadoSinInfo, titulopreg, errors, descpregfrec1, descpregfrec2, descpregfrec3, descpregfrec4, estadoEditar, idpregfrecuente, infoConveniosDetalles } = this.state;
        return (
            <React.Fragment>
                <p className="font-weight-bold">Preguntas</p>
                <hr />
                {estadoSinInfo ? (
                    <React.Fragment>
                        <div className="plus_add_agencia align-items-center d-flex" onClick={() => { this.handleNew() }}>
                            {estadoNuevo ? <p>Cancelar &nbsp;<MDBIcon icon="times" size="lg" /></p> : <p>Agregar Nuevo &nbsp;<MDBIcon icon="plus-circle" size="lg" /></p>}
                        </div>
                    </React.Fragment>
                ) : (
                    <React.Fragment></React.Fragment>
                )}
                {estadoNuevo ? (
                    <React.Fragment>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 my-2 text-center">
                                    <FileUploaderB ref={this.uploader} estadoImg={estadoCambioImg1} FCambiar={this.cambioImagen1} />
                                </div>
                                <div className="col-md-6 my-2">
                                    <label>Prioridad Posición</label>
                                    <select name="prioridad" className="form-control" value={this.state.prioridad} onChange={this.textInput} >
                                        <option value="DEFAULT" disabled>Seleccione...</option>
                                        <option value="0">Superior</option>
                                        <option value="1">Inferior</option>
                                    </select>
                                    {errors.prioridad && <strong className="text-danger" style={{ fontSize: '13px' }}>{errors.prioridad}</strong>}
                                </div>
                                <div className="col-md-6 my-2">
                                    <MDBInput label="Titulo Pregunta" name="titulopreg" type="text" value={titulopreg} onChange={this.textInput} />
                                    {errors.titulopreg && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '50px' }}>{errors.titulopreg}</strong>}
                                </div>
                                <div className="col-md-6 my-2">
                                    <MDBInput label="Descripción 1" name="descpregfrec1" type="textarea" rows="5" value={descpregfrec1} onChange={this.textInput} />
                                    {errors.descpregfrec1 && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', marginTop: '-30px' }}>{errors.descpregfrec1}</strong>}
                                </div>
                                <div className="col-md-6 my-2">
                                    <MDBInput label="Descripción 2" name="descpregfrec2" type="textarea" rows="3" value={descpregfrec2} onChange={this.textInput} />
                                </div>
                                <div className="col-md-6 my-2">
                                    <MDBInput label="Descripción 3" name="descpregfrec3" type="textarea" rows="3" value={descpregfrec3} onChange={this.textInput} />
                                </div>
                                <div className="col-md-6 my-2">
                                    <MDBInput label="Descripción 4" name="descpregfrec4" type="textarea" rows="3" value={descpregfrec4} onChange={this.textInput} />
                                </div>
                            </div>
                        </div>
                        <div className="pt-2 mt-5 modal-footer w-100">
                            <button type="button" className="btn btn-primary px-5" onClick={this.insertarDatos}>Crear Producto &nbsp; <MDBIcon icon="save" /></button>
                        </div>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className="container-fluid">
                            <div className="table-responsive text-center w-100">
                                <MDBTable>
                                    <MDBTableHead>
                                        <tr className="font-weight-bold">
                                            <td>Imagen</td>
                                            <td>Prioridad Posición</td>
                                            <td>Titulo Pregunta</td>
                                            <td>Descripción 1</td>
                                            <td>Descripción 2</td>
                                            <td>Descripción 3</td>
                                            <td>Descripción 4</td>
                                            {estadoEditar ?
                                                (
                                                    <React.Fragment>
                                                        <td>Guardar</td>
                                                        <td>Cancelar</td>
                                                    </React.Fragment>
                                                ) : (
                                                    <React.Fragment>
                                                        <td>Editar</td>
                                                        <td>Inactivar</td>
                                                    </React.Fragment>
                                                )}
                                        </tr>
                                    </MDBTableHead>
                                    <MDBTableBody>
                                        {estadoEditar ?
                                            (
                                                <React.Fragment>
                                                    <tr>
                                                        <td>
                                                            <FileUploader ref={this.uploader} estadoImg={estadoCambio} ruta={this.state.rutaiconpreg} FCambiar={this.cambio} />
                                                        </td>
                                                        <td>
                                                            <select name="editprioridad" className="form-control" defaultValue={this.state.editprioridad} onChange={this.textInput} >
                                                                <option value="DEFAULT" disabled>Seleccione...</option>
                                                                <option value="0">Superior</option>
                                                                <option value="1">Inferior</option>
                                                            </select>
                                                        </td>
                                                        <td className="w-25"><textarea className="form-control" type="textarea" name="edittitulopreg" rows="10" value={this.state.edittitulopreg} onChange={this.textInput} /></td>
                                                        <td className="w-25"><textarea className="form-control" type="textarea" name="editdescpregfrec1" rows="10" value={this.state.editdescpregfrec1} onChange={this.textInput} /></td>
                                                        <td className="w-25"><textarea className="form-control" type="textarea" name="editdescpregfrec2" rows="10" value={this.state.editdescpregfrec2} onChange={this.textInput} /></td>
                                                        <td className="w-25"><textarea className="form-control" type="textarea" name="editdescpregfrec3" rows="10" value={this.state.editdescpregfrec3} onChange={this.textInput} /></td>
                                                        <td className="w-25"><textarea className="form-control" type="textarea" name="editdescpregfrec4" rows="10" value={this.state.editdescpregfrec4} onChange={this.textInput} /></td>
                                                        <td className="text-center" onClick={() => { this.actualizarDatos(idpregfrecuente) }}><MDBIcon icon="save" size="2x" style={{ color: 'blue', cursor: 'pointer' }} /></td>
                                                        <td className="text-center" onClick={() => { this.setState({ estadoEditar: !this.state.estadoEditar }) }}><MDBIcon icon="times" size="2x" style={{ color: 'red', cursor: 'pointer' }} /></td>
                                                    </tr>
                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment>
                                                    {infoConveniosDetalles.map((e, i) => {
                                                        let view;
                                                        if (e.estado === 1) {
                                                            view = <MDBIcon onClick={() => { this.inactivar(e.idpregfrecuente) }} icon="eye" size="2x" style={{ cursor: 'pointer' }} />
                                                        } else {
                                                            view = <MDBIcon onClick={() => { this.activar(e.idpregfrecuente) }} icon="eye-slash" size="2x" style={{ cursor: 'pointer' }} />
                                                        }
                                                        let tipoPrioridad = '';
                                                        if (e.prioridad === 1) {
                                                            tipoPrioridad = "Inferior";
                                                        } else {
                                                            tipoPrioridad = "Superior";
                                                        }
                                                        return [
                                                            <React.Fragment key={i}>
                                                                <tr>
                                                                    <td><img className="img-fluid" style={{ width: '75px' }} src={`${URLImgFront}/assets${e.rutaiconpreg}`} alt={e.rutaiconpreg} /></td>
                                                                    <td>{tipoPrioridad}</td>
                                                                    <td>{e.titulopreg}</td>
                                                                    <td>{e.descpregfrec1}</td>
                                                                    <td>{e.descpregfrec2}</td>
                                                                    <td>{e.descpregfrec3}</td>
                                                                    <td>{e.descpregfrec4}</td>
                                                                    <td className="text-center" onClick={() => { this.editar(e.idpregfrecuente) }}><MDBIcon icon="edit" size="2x" style={{ color: 'green', cursor: 'pointer' }} /></td>
                                                                    <td className="text-center">{view}</td>
                                                                </tr>
                                                            </React.Fragment>
                                                        ]
                                                    })}
                                                </React.Fragment>
                                            )}
                                    </MDBTableBody>
                                </MDBTable>
                            </div>
                        </div>
                    </React.Fragment>
                )}

            </React.Fragment>
        );
    }
}

export default Preguntas;