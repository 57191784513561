import { MDBIcon, MDBInput, MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import React, { Component } from 'react';
import FileUploader from './ProductosDetalles/FileUploader';
import FileUploaderAc from './ProductosDetalles/FileUploaderAc';
import Swal from 'sweetalert2';
import { CargueImagenes, ProductosDetallesGET_, ProductosDetalles_, ProductosSimula_ } from '../../../services/WebAPI_Utrahuilca';
import { URLImgFront } from '../../../config/URLDB';

var addItems = [];
var addBeneficios = [];
var addRequisitos = [];
var addItemsImage = [];
var addBeneficiosImage = [];
var addRequisitosImage = [];

const validate = values => {
    const errors = {}
    if (values.idprodutra === 'DEFAULT') {
        errors.idprodutra = 'campo obligatorio'
    }
    if (!values.txtprodvtadet1) {
        errors.txtprodvtadet1 = 'campo obligatorio'
    }
    if (!values.txtprodvtadet2) {
        errors.txtprodvtadet2 = 'campo obligatorio'
    }
    if (!values.txtprodvtadet3) {
        errors.txtprodvtadet3 = 'campo obligatorio'
    }
    if (values.cambioEstadoVista === 'DEFAULT') {
        errors.cambioEstadoVista = 'campo obligatorio'
    }
    return errors;
};
const validate2 = values => {
    const errors = {}
    if (values.idprodutra === 'DEFAULT') {
        errors.idprodutra = 'campo obligatorio'
    }
    if (!values.rutaproddet) {
        errors.rutaproddet = 'campo obligatorio'
    }
    return errors;
};

class ProductosDetalles extends Component {
    uploader = React.createRef();
    uploader1 = React.createRef();
    uploader2 = React.createRef();
    uploader3 = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            estadoNuevo: false,
            errors: {},
            infoProductos: [],
            stateView: false,
            stateActive: false,
            manejaItemState: false,
            manejaBeneficiosState: false,
            manejaRequisitosState: false,
            idprodutra: 'DEFAULT',
            rutaproddet: '',

            descitemprod: '',
            descbenefprod: '',
            descrequisprod: '',

            Items: [],
            Beneficios: [],
            Requisitos: [],
            ItemsImage: [],
            BeneficiosImage: [],
            RequisitosImage: [],

            stateManejaRutaBen: false,
            stateManejaRutaReq: false,
            stateEdit: true,

            estadoCambio: true,
            estadoCambio1: true,
            estadoSinResultados: true,
            infoProductosDetalles: [],
            stateImg: false,
            dataArray: [],
            estadoEditar: false,
            statevistaEstado: 'DEFAULT',
            editmanejaItems: 1,
            editmanejaben: 1,
            editmanejareq: 1,
            cambioEstadoVista: 'DEFAULT',
            editidprodutra: null
        };
    }

    //////////////////////////////////////

    agregarItems = () => {
        if (this.state.descitemprod !== '') {
            var ruta = '';
            var rutaFile = '';
            ruta = '/Productos/Producto Detalles/' + this.uploader1.current.getFileName();
            rutaFile = this.uploader1.current.getFileObject();
            addItems.push({ descitemprod: this.state.descitemprod, rutaiconitem: ruta })
            addItemsImage.push({ descitemprod: this.state.descitemprod, rutaiconitem: ruta, imagen: rutaFile })
            this.setState({
                Items: addItems,
                ItemsImage: addItemsImage,
                idprodvtadet: null,
                rutaiconitem: ''
            })
        } else {
            Swal.fire({
                icon: 'error',
                text: 'Campos vacios, intente nuevamente!'
            })
        }
    }

    agregarBeneficios = () => {
        if (this.state.descbenefprod !== '') {
            var ruta = '';
            var rutaFile = '';
            if (this.state.manejarutaimgBen === 0) {
                ruta = 'NA';
            } else {
                ruta = '/Productos/Producto Detalles/' + this.uploader2.current.getFileName();
                rutaFile = this.uploader2.current.getFileObject();
            }
            addBeneficios.push({ descbenefprod: this.state.descbenefprod, manejarutaimgBen: this.state.manejarutaimgBen, rutaimgbenefprod: ruta })
            addBeneficiosImage.push({ descbenefprod: this.state.descbenefprod, manejarutaimgBen: this.state.manejarutaimgBen, rutaimgbenefprod: ruta, imagen: rutaFile })
            this.setState({
                Beneficios: addBeneficios,
                BeneficiosImage: addBeneficiosImage,
                idprodvtadet: null,
                rutaimgbenefprod: '',
                manejarutaimgBen: this.state.manejarutaimgBen
            })
        } else {
            Swal.fire({
                icon: 'error',
                text: 'Campos vacios, intente nuevamente!'
            })
        }
    }

    agregarRequisitos = () => {
        if (this.state.descrequisprod !== '') {
            var ruta = '';
            var rutaFile = '';
            if (this.state.manejarutaimgReq === 0) {
                ruta = 'NA';
            } else {
                ruta = '/Productos/Producto Detalles/' + this.uploader3.current.getFileName();
                rutaFile = this.uploader3.current.getFileObject();
            }
            addRequisitos.push({ descrequisprod: this.state.descrequisprod, manejarutaimgReq: this.state.manejarutaimgReq, rutaimgrequistprod: ruta })
            addRequisitosImage.push({ descrequisprod: this.state.descrequisprod, manejarutaimgReq: this.state.manejarutaimgReq, rutaimgrequistprod: ruta, imagen: rutaFile })
            this.setState({
                Requisitos: addRequisitos,
                RequisitosImage: addRequisitosImage,
                idprodvtadet: null,
                rutaimgrequistprod: '',
                manejarutaimgReq: this.state.manejarutaimgReq
            })
        } else {
            Swal.fire({
                icon: 'error',
                text: 'Campos vacios, intente nuevamente!'
            })
        }
    }

    componentDidMount() {
        this.cargarSelect();
        this.cargarInformacion();
    }

    cargarInformacion = () => {
        const data = {
            tran: 4,
            idprodvtadet: 0,
            idprodutra: 0,
            txtprodvtdet1: 'NA',
            txtprodvtdet2: 'NA',
            txtprodvtdet3: 'NA',
            rutaimgdetalle: 'NA',
            idtipovista: 0,
            rutaproddet: 'NA',
            manejaitems: 0,
            descitemsprod: 'NA',
            manejabeneficios: 0,
            descbenefprod: 'NA',
            manejarequisitos: 0,
            descrequisprod: 'NA',
            infoadicional: 'NA',
            rutaformsolic: 'NA',
            estado: 1,
            usucrea: 'NA'
        }
        ProductosDetallesGET_(data).then(response => {
            try {
                if (response.data.length > 0) {
                    if (response.data.length >= 1) {
                        if (response.data[0].message === "004404") {
                            this.setState({
                                stateImg: false
                            })
                        } else {
                            this.setState({
                                infoProductosDetalles: response.data,
                                estadoSinResultados: false,
                                stateImg: true
                            });
                        }
                    } else {
                        this.setState({
                            stateImg: false
                        })
                    }
                } else {
                    console.log(response);
                }
            } catch (error) {
                console.log(error);
            }
        });
    }

    cargarSelect = () => {
        const data = {
            tran: 11,
            idprodutra: 0,
            nombprodutra: 'NA',
            descprodutra: 'NA',
            rutaimgprod: 'NA',
            rutavistaprod: 'NA',
            prolinea: 0,
            idtipoprod: 0,
            idtipopers: 0,
            estado: 1,
            usucrea: 'NA'
        }
        ProductosSimula_(data).then(response => {
            try {
                if (response.data.length >= 1) {
                    this.setState({
                        infoProductos: response.data,
                    })
                }
            } catch (error) {
                this.setState({
                    infoProductos: [],
                })
                Swal.fire({
                    icon: 'error',
                    text: 'Ocurrio un error al consumir el servicio, comuniquese con sistemas!'
                })
            }
        });
    }

    cambioEstadoVista = (e) => {
        if (e.target.value === "1") {
            this.setState({
                cambioEstadoVista: 1,
                statevistaEstado: 1,
                stateView: true,
                stateActive: true,
                vistaEstado: 1
            })
        } else if (e.target.value === "2") {
            this.setState({
                cambioEstadoVista: 2,
                statevistaEstado: 2,
                stateView: false,
                stateActive: true,
                vistaEstado: 2
            })
        }
    }

    ManejaItems = (e) => {
        if (e.target.value === "0") {
            this.setState({
                manejaitems: 0,
                editmanejaItems: 0,
                manejaItemState: false
            })
        } else if (e.target.value === "1") {
            this.setState({
                manejaitems: 1,
                editmanejaItems: 1,
                manejaItemState: true
            })
        }
    }

    ManejaBeneficios = (e) => {
        if (e.target.value === "0") {
            this.setState({
                manejabeneficios: 0,
                editmanejaben: 0,
                manejaBeneficiosState: false
            })
        } else if (e.target.value === "1") {
            this.setState({
                manejabeneficios: 1,
                editmanejaben: 1,
                manejaBeneficiosState: true
            })
        }
    }

    ManejaRequisitos = (e) => {
        if (e.target.value === "0") {
            this.setState({
                manejarequisitos: 0,
                editmanejareq: 0,
                manejaRequisitosState: false
            })
        } else if (e.target.value === "1") {
            this.setState({
                manejarequisitos: 1,
                editmanejareq: 1,
                manejaRequisitosState: true
            })
        }
    }

    textInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    eliminarItems = (data) => {
        var indice = addItems.indexOf(data);
        if (indice !== -1) {
            addItems.splice(indice, 1);
            this.setState({
                Items: addItems
            })
        }
    }

    eliminarBeneficio = (data) => {
        var indice = addBeneficios.indexOf(data);
        if (indice !== -1) {
            addBeneficios.splice(indice, 1);
            this.setState({
                Beneficios: addBeneficios
            })
        }
    }

    eliminarRequisito = (data) => {
        var indice = addRequisitos.indexOf(data);
        if (indice !== -1) {
            addRequisitos.splice(indice, 1);
            this.setState({
                Requisitos: addRequisitos
            })
        }
    }

    ManejaRutaBene = (e) => {
        if (e.target.value === "0") {
            this.setState({
                manejarutaimgBen: 0,
                stateManejaRutaBen: false
            })
        } else if (e.target.value === "1") {
            this.setState({
                manejarutaimgBen: 1,
                stateManejaRutaBen: true
            })
        }
    }

    ManejaRutaReq = (e) => {
        if (e.target.value === "0") {
            this.setState({
                manejarutaimgReq: 0,
                stateManejaRutaReq: false
            })
        } else if (e.target.value === "1") {
            this.setState({
                manejarutaimgReq: 1,
                stateManejaRutaReq: true
            })
        }
    }

    //////////////////////////////////////

    cambio = (name, file) => {
        this.setState({
            estadoCambio1: true
        });
    }

    cambioAct = (name, file) => {
        this.setState({
            estadoCambio: false
        });
    }

    cargarImagen = () => {
        var formData = new FormData();
        if (undefined === this.uploader.current) return;
        
        formData.append("file", this.uploader.current.getFileObject());
        formData.append("rutaImagen", '/Productos/Producto Detalles/' + this.uploader.current.getFileName());
        if (this.state.ItemsImage.length > 0) {
            for (let i = 0; i < this.state.ItemsImage.length; i++) {
                if (this.state.ItemsImage[i].rutaiconitem !== "NA") {
                    formData.append("file", this.state.ItemsImage[i].imagen);
                    formData.append("rutaImagen", this.state.ItemsImage[i].rutaiconitem);
                }
            }
        }
        if (this.state.BeneficiosImage.length > 0) {
            for (let i = 0; i < this.state.BeneficiosImage.length; i++) {
                if (this.state.BeneficiosImage[i].rutaimgbenefprod !== "NA") {
                    formData.append("file", this.state.BeneficiosImage[i].imagen);
                    formData.append("rutaImagen", this.state.BeneficiosImage[i].rutaimgbenefprod);
                }
            }
        }
        if (this.state.RequisitosImage.length > 0) {
            for (let i = 0; i < this.state.RequisitosImage.length; i++) {
                if (this.state.RequisitosImage[i].rutaimgrequistprod !== "NA") {
                    formData.append("file", this.state.RequisitosImage[i].imagen);
                    formData.append("rutaImagen", this.state.RequisitosImage[i].rutaimgrequistprod);
                }
            }
        }
        console.log(formData)
        CargueImagenes(formData).then(response => {
            if (response) {
                this.cargarInformacion();
                return;
            }
        });
    }

    insertarDatos = () => {
        if (this.state.cambioEstadoVista === "DEFAULT") {
            Swal.fire({
                icon: 'warning',
                text: 'Debe seleccionar si es generica o establecida'
            })
        } else {
            var data = '';
            var validar = 0;
            if (this.state.cambioEstadoVista === 1) {
                const { errors, ...sinErrors } = this.state;
                const result = validate(sinErrors);
                this.setState({ errors: result });
                if (!Object.keys(result).length) {
                    // formulario validado
                    validar = 1;
                    data = {
                        tran: 1,
                        idprodvtadet: 0,
                        idprodutra: parseInt(this.state.idprodutra),
                        txtprodvtadet1: this.state.txtprodvtadet1,
                        txtprodvtadet2: this.state.txtprodvtadet2,
                        txtprodvtadet3: this.state.txtprodvtadet3,
                        rutaimgdetalle: '/Productos/Producto Detalles/' + this.uploader.current.getFileName(),
                        manejaitems: parseInt(this.state.manejaitems),
                        descitemsprod: this.state.descitemprod,
                        proddetitems: this.state.Items,
                        idtipovista: 0,
                        rutaproddet: 'NA',
                        manejabeneficios: parseInt(this.state.manejabeneficios),
                        descbenefprod: this.state.descbenefprodInfo,
                        proddetbeneficios: this.state.Beneficios,
                        manejarequisitos: parseInt(this.state.manejarequisitos),
                        descrequisprod: this.state.descrequisprodInfo,
                        proddetrequisitos: this.state.Requisitos,
                        infoadicional: this.state.infoadicional,
                        estado: 1,
                        usucrea: sessionStorage.getItem('idusuario')
                    };
                }
            } else {
                const { errors, ...sinErrors } = this.state;
                const result = validate2(sinErrors);
                this.setState({ errors: result });
                if (!Object.keys(result).length) {
                    validar = 1;
                    data = {
                        tran: 1,
                        idprodvtadet: 0,
                        idprodutra: parseInt(this.state.idprodutra),
                        txtprodvtadet1: 'NA',
                        txtprodvtadet2: 'NA',
                        txtprodvtadet3: 'NA',
                        rutaimgdetalle: 'NA',
                        manejaitems: 0,
                        descitemsprod: 'NA',
                        proddetitems: 'NA',
                        idtipovista: 0,
                        rutaproddet: this.state.rutaproddet,
                        manejabeneficios: 0,
                        descbenefprod: 'NA',
                        proddetbeneficios: 'NA',
                        manejarequisitos: 0,
                        descrequisprod: 'NA',
                        proddetrequisitos: 'NA',
                        infoadicional: 'NA',
                        estado: 1,
                        usucrea: sessionStorage.getItem('idusuario')
                    };
                }
            }
            if (validar === 1) {
                ProductosDetalles_(data).then(response => {
                    try {
                        if (response.data.length > 0) {
                            if (response.data[0].message === "000000") {
                                if (this.state.cambioEstadoVista === 1) {
                                    this.cargarImagen();
                                }
                                Swal.fire({
                                    icon: 'success',
                                    title: 'Se agrego correctamente.'
                                })
                                this.setState({
                                    estadoNuevo: false,
                                    idprodutra: 'DEFAULT',
                                    txtprodvtadet1: '',
                                    txtprodvtadet2: '',
                                    txtprodvtadet3: '',
                                    rutaimgdetalle: '',
                                    manejaitems: 0,
                                    rutaproddet: this.state.rutaproddet,
                                    manejabeneficios: 0,
                                    Items: [],
                                    Beneficios: [],
                                    Requisitos: [],
                                    manejarequisitos: 0,
                                    infoadicional: '',
                                    stateView: false,
                                    stateActive: false,
                                    manejaItemState: false,
                                    manejaBeneficiosState: false,
                                    manejaRequisitosState: false,
                                    stateEdit: true,
                                    manejarutaimgBen: 'DEFAULT',
                                    manejarutaimgReq: 'DEFAULT',
                                })
                                addItems = [];
                                addBeneficios = [];
                                addRequisitos = [];
                                addItemsImage = [];
                                addBeneficiosImage = [];
                                addRequisitosImage = [];
                                this.cargarInformacion();
                                this.cargarSelect();
                            } else {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Error al insertar.'
                                })
                            }
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Ocurrio un error!'
                            })
                        }
                    } catch (error) {
                        Swal.fire({
                            icon: 'error',
                            text: 'Ocurrio un error al consumir el servicio!'
                        })
                    }
                });
            }
        }
    }

    editar = (id) => {
        this.setState({
            idSeleccionado: id
        })
        const data = {
            tran: 5,
            idprodvtadet: id,
            idprodutra: 0,
            txtprodvtdet1: 'NA',
            txtprodvtdet2: 'NA',
            txtprodvtdet3: 'NA',
            rutaimgdetalle: 'NA',
            idtipovista: 0,
            rutaproddet: 'NA',
            manejaitems: 0,
            descitemsprod: 'NA',
            manejabeneficios: 0,
            descbenefprod: 'NA',
            manejarequisitos: 0,
            descrequisprod: 'NA',
            infoadicional: 'NA',
            rutaformsolic: 'NA',
            estado: 1,
            usucrea: 'NA'
        }
        ProductosDetallesGET_(data).then(response => {
            try {
                if (response.data.length > 0) {
                    if (response.data.length >= 1) {
                        if (response.data[0].message === "004404") {
                            this.setState({
                                stateImg: false
                            })
                        } else {
                            if (response.data[0].rutaproddet !== "NA") {
                                this.setState({
                                    stateView: false,
                                    stateActive: true,
                                    vistaEstado: 2
                                })
                            } else {
                                this.setState({
                                    stateView: true,
                                    stateActive: true,
                                    vistaEstado: 1,
                                })
                            }
                            this.setState({
                                infoSelectData: response.data,
                                idprodvtadet: response.data[0].idprodvtadet,
                                editidprodutra: response.data[0].idprodutra,
                                edittxtprodvtdet1: response.data[0].txtprodvtdet1,
                                edittxtprodvtdet2: response.data[0].txtprodvtdet2,
                                edittxtprodvtdet3: response.data[0].txtprodvtdet3,
                                editrutaimgdetalle: response.data[0].rutaimgdetalle,
                                editinfoadicional: response.data[0].infoadicional,
                                editdescbenefprodInfo: response.data[0].descbenefprod,
                                editdescrequisprodInfo: response.data[0].descrequisprod,
                                editrutaproddet: response.data[0].rutaproddet,
                                editnombprodutra: response.data[0].nombprodutra,
                                estadoCambio1: false,
                                stateEdit: false,
                                stateManejaRutaBen: false,
                                stateManejaRutaReq: false,
                                manejaItemState: true,
                                manejaBeneficiosState: true,
                                manejaRequisitosState: true,
                                editmanejaItems: 1,
                                editmanejaben: 1,
                                editmanejareq: 1,
                            });
                            this.loadTable();
                        }
                    } else {
                        this.setState({
                            stateImg: false
                        })
                    }
                } else {
                    console.log(response);
                }
            } catch (error) {
                console.log(error);
            }
        });
    }

    loadTable = () => {
        var addItemsN = [];
        var addBeneficiosN = [];
        var addRequisitosN = [];
        const dataJson = this.state.infoSelectData;
        var jsonnuevo = [];
        for (var i = 0; i < dataJson.length; i++) {
            for (var j = i; j < dataJson.length; j++) {
                addItemsN.push({ "descitemprod": dataJson[j].descitemprod, "rutaiconitem": dataJson[j].rutaiconitem });
                addBeneficiosN.push({ "descbenefprod": dataJson[j].descbenefprod1, "rutaimgbenefprod": dataJson[j].rutaimgbenefprod });
                addRequisitosN.push({ "descrequisprod": dataJson[j].descrequisprod1, "rutaimgrequistprod": dataJson[j].rutaimgrequistprod });
                if (j === (dataJson.length - 1)) {
                    j = dataJson.length;
                    i = j - 1;
                    break;
                }
                else {
                    if (dataJson[j].idprodvtadet !== dataJson[j + 1].idprodvtadet) {
                        i = j;
                        j = dataJson.length;
                        break;
                    }
                }
            }
            addItemsN = new Set(addItemsN.map(JSON.stringify));
            let newaddItems = Array.from(addItemsN).map(JSON.parse);
            addBeneficiosN = new Set(addBeneficiosN.map(JSON.stringify));
            let newaddBeneficios = Array.from(addBeneficiosN).map(JSON.parse);
            addRequisitosN = new Set(addRequisitosN.map(JSON.stringify));
            let newaddRequisitos = Array.from(addRequisitosN).map(JSON.parse);

            jsonnuevo.push(
                {
                    newaddItems,
                    newaddBeneficios,
                    newaddRequisitos,
                }
            );
            addItemsN = null;
            addBeneficiosN = null;
            addRequisitosN = null;
        }
        addItems = jsonnuevo[0].newaddItems;
        addBeneficios = jsonnuevo[0].newaddBeneficios;
        addRequisitos = jsonnuevo[0].newaddRequisitos;
        this.setState({
            Items: jsonnuevo[0].newaddItems,
            Beneficios: jsonnuevo[0].newaddBeneficios,
            Requisitos: jsonnuevo[0].newaddRequisitos
        })
    }

    ActualizarDatos = (id) => {
        if (this.state.statevistaEstado === "DEFAULT") {
            Swal.fire({
                icon: 'warning',
                text: 'Debe indicar si es generica o establecida'
            })
        } else {
            var data = '';
            if (this.state.statevistaEstado === 1) {
                var ruta = '';
                var capNombre = this.uploader.current.getFileName();
                if (capNombre === undefined || capNombre === null) {
                    ruta = this.state.editrutaimgdetalle;
                } else {
                    ruta = '/Productos/Producto Detalles/' + this.uploader.current.getFileName();
                }
                data = {
                    tran: 1,
                    idprodvtadet: id,
                    idprodutra: parseInt(this.state.editidprodutra),
                    txtprodvtadet1: this.state.edittxtprodvtdet1,
                    txtprodvtadet2: this.state.edittxtprodvtdet2,
                    txtprodvtadet3: this.state.edittxtprodvtdet3,
                    rutaimgdetalle: ruta,
                    manejaitems: parseInt(this.state.editmanejaItems),
                    proddetitems: this.state.Items,
                    idtipovista: 0,
                    rutaproddet: 'NA',
                    manejabeneficios: parseInt(this.state.editmanejaben),
                    descbenefprod: this.state.editdescbenefprodInfo,
                    proddetbeneficios: this.state.Beneficios,
                    manejarequisitos: parseInt(this.state.editmanejareq),
                    descrequisprod: this.state.editdescrequisprodInfo,
                    proddetrequisitos: this.state.Requisitos,
                    infoadicional: this.state.editinfoadicional === null ? ' ' : this.state.editinfoadicional,
                    estado: 1,
                    usucrea: sessionStorage.getItem('idusuario')
                };
            } else {
                data = {
                    tran: 1,
                    idprodvtadet: id,
                    idprodutra: parseInt(this.state.editidprodutra),
                    txtprodvtadet1: 'NA',
                    txtprodvtadet2: 'NA',
                    txtprodvtadet3: 'NA',
                    rutaimgdetalle: 'NA',
                    manejaitems: 0,
                    proddetitems: 'NA',
                    idtipovista: 0,
                    rutaproddet: this.state.editrutaproddet,
                    manejabeneficios: 0,
                    descbenefprod: 'NA',
                    proddetbeneficios: 'NA',
                    manejarequisitos: 0,
                    descrequisprod: 'NA',
                    proddetrequisitos: 'NA',
                    infoadicional: 'NA',
                    estado: 1,
                    usucrea: sessionStorage.getItem('idusuario')
                };
            }
            ProductosDetalles_(data).then(response => {
                try {
                    if (response.data.length > 0) {
                        if (response.data[0].message === "000000") {
                            if (this.state.cambioEstadoVista === 1) {
                                this.cargarImagen();
                            }
                            Swal.fire({
                                icon: 'success',
                                title: 'Se agrego correctamente.'
                            })
                            this.setState({
                                estadoNuevo: false,
                                idprodutra: 'DEFAULT',
                                txtprodvtadet1: '',
                                txtprodvtadet2: '',
                                txtprodvtadet3: '',
                                rutaimgdetalle: '',
                                manejaitems: 0,
                                rutaproddet: this.state.rutaproddet,
                                manejabeneficios: 0,
                                Items: [],
                                Beneficios: [],
                                Requisitos: [],
                                manejarequisitos: 0,
                                infoadicional: '',
                                stateView: false,
                                stateActive: false,
                                manejaItemState: false,
                                manejaBeneficiosState: false,
                                manejaRequisitosState: false,
                                stateEdit: true
                            })
                            addItems = [];
                            addBeneficios = [];
                            addRequisitos = [];
                            addItemsImage = [];
                            addBeneficiosImage = [];
                            addRequisitosImage = [];
                            this.cargarInformacion();
                            this.cargarSelect();
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Error al insertar.'
                            })
                        }
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Ocurrio un error!'
                        })
                    }
                } catch (error) {
                    Swal.fire({
                        icon: 'error',
                        text: 'Ocurrio un error al consumir el servicio!'
                    })
                }
            });
        }

    }

    handleNew = () => {
        this.setState({
            estadoNuevo: !this.state.estadoNuevo,
            Items: [],
            Beneficios: [],
            Requisitos: [],
            manejarequisitos: 0,
            errors: {},
            infoadicional: '',
            stateView: false,
            stateActive: false,
            manejaItemState: false,
            manejaBeneficiosState: false,
            manejaRequisitosState: false,
            stateManejaRutaBen: false,
            stateManejaRutaReq: false
        })
        addItems = [];
        addBeneficios = [];
        addRequisitos = [];
        addItemsImage = [];
        addBeneficiosImage = [];
        addRequisitosImage = [];
        if (this.state.estadoNuevo) {
            this.setState({
                manejarequisitos: 0,
                Items: [],
                errors: {},
                Beneficios: [],
                Requisitos: [],
                infoadicional: '',
                stateView: false,
                stateActive: false,
                manejaItemState: false,
                manejaBeneficiosState: false,
                manejaRequisitosState: false,
                stateManejaRutaBen: false,
                stateManejaRutaReq: false
            })
            addItems.pop();
            addBeneficios.pop();
            addRequisitos.pop();
            addItemsImage.pop();
            addBeneficiosImage.pop();
            addRequisitosImage.pop();
            this.cargarInformacion();
        }
    }

    render() {
        const { estadoNuevo, infoProductos, Items, Beneficios, Requisitos, estadoCambio1, stateActive, stateView, stateEdit, errors } = this.state;
        return (
            <React.Fragment>
                <p className="font-weight-bold">Producto Vista Detalles</p>
                <hr />
                <div className="plus_add_agencia align-items-center d-flex" onClick={() => { this.handleNew() }}>
                    {estadoNuevo ? <p>Cancelar &nbsp;<MDBIcon icon="times" size="lg" /></p> : <p>Crear Vista Detalles &nbsp;<MDBIcon icon="plus-circle" size="lg" /></p>}
                </div>
                {estadoNuevo ? (
                    <React.Fragment>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 my-2 mt-3">
                                    <label>Seleccione el Producto</label>
                                    <select name="idprodutra" className="form-control" value={this.state.idprodutra} onChange={this.textInput}>
                                        <option value="DEFAULT" disabled>Selecciona...</option>
                                        {infoProductos.map((e, i) => {
                                            return [
                                                <option key={i} value={e.idprodutra}>{e.nombprodutra}</option>
                                            ]
                                        })}
                                    </select>
                                    {errors.idprodutra && <strong className="text-danger" style={{ fontSize: '13px' }}>{errors.idprodutra}</strong>}
                                </div>
                                <div className="col-md-6 my-2 mt-3">
                                    <label>Que tipo de vista desea?</label>
                                    <select name="cambioEstadoVista" className="form-control" defaultValue={'DEFAULT'} onChange={this.cambioEstadoVista}>
                                        <option value="DEFAULT" disabled>Seleccione...</option>
                                        <option value="1">Generica</option>
                                        <option value="2">Establecida</option>
                                    </select>
                                </div>
                                {stateActive ? (
                                    <React.Fragment>
                                        {stateView ? (
                                            <React.Fragment>
                                                <div className="col-md-12 my-2 text-center">
                                                    <FileUploader ref={this.uploader} estado={estadoCambio1} ruta={this.state.rutafotoagenc} FCambiar={this.cambio} />
                                                </div>
                                                <div className="col-md-4 my-2">
                                                    <MDBInput label="Texto 1" name="txtprodvtadet1" type="text" className="mr-5" value={this.state.txtprodvtadet1} onChange={this.textInput} />
                                                    {errors.txtprodvtadet1 && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '50px' }}>{errors.txtprodvtadet1}</strong>}
                                                </div>
                                                <div className="col-md-4 my-2">
                                                    <MDBInput label="Texto 2" name="txtprodvtadet2" type="text" className="mr-5" value={this.state.txtprodvtadet2} onChange={this.textInput} />
                                                    {errors.txtprodvtadet2 && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '50px' }}>{errors.txtprodvtadet2}</strong>}
                                                </div>
                                                <div className="col-md-4 my-2">
                                                    <MDBInput label="Texto 3" name="txtprodvtadet3" type="text" className="mr-5" value={this.state.txtprodvtadet3} onChange={this.textInput} />
                                                    {errors.txtprodvtadet3 && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '50px' }}>{errors.txtprodvtadet3}</strong>}
                                                </div>
                                                <div className="col-md-12 my-2">
                                                    <MDBInput label="Información Adicional" name="infoadicional" type="textarea" rows="5" className="mr-5" value={this.state.infoadicional} onChange={this.textInput} />
                                                </div>
                                                <div className="col-md-12 my-2">
                                                    <label>Maneja Items?</label>
                                                    <select name="manejaitems" className="form-control" defaultValue={'DEFAULT'} onChange={this.ManejaItems}>
                                                        <option value="DEFAULT" disabled>Seleccione...</option>
                                                        <option value="1">SI</option>
                                                        <option value="0">NO</option>
                                                    </select>
                                                </div>
                                                <hr />
                                                {this.state.manejaItemState ? (
                                                    <React.Fragment>
                                                        <div className="col-md-12 my-2">
                                                            <label className="font-weight-bold">Ingrese los Items</label>
                                                            <hr />
                                                            <div className="row">
                                                                <div className="col-md-6 my-2">
                                                                    <MDBInput label="Descripción" name="descitemprod" type="textarea" rows="5" className="mr-5" value={this.state.descitemprod} onChange={this.textInput} />
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <FileUploaderAc ref={this.uploader1} estado={true} FCambiar={this.cambioAct} />
                                                                </div>
                                                                <div onClick={() => { this.agregarItems() }} style={{ cursor: 'pointer', position: 'absolute', top: '100px', right: '-40px', zIndex: '999' }} >
                                                                    <MDBIcon icon="plus-circle" size="2x" />
                                                                </div>
                                                            </div>
                                                            <ul style={{ listStyle: 'none' }}>
                                                                {Items.map((e, i) => {
                                                                    return [
                                                                        <li key={i} className="justify-content-center d-flex pt-3 border border-warning">
                                                                            <p><b className="font-weight-bold">Descripción:</b> {e.descitemprod} &nbsp;&nbsp;</p>
                                                                            <p><b className="font-weight-bold">Ruta Imagen:</b> {e.rutaiconitem}</p>
                                                                            <MDBIcon onClick={() => { this.eliminarItems(e) }} icon="trash" className="text-danger" size="lg" style={{ position: 'absolute', right: '50px', marginTop: '5px', cursor: 'pointer' }} />
                                                                        </li>
                                                                    ]
                                                                })}
                                                            </ul>
                                                        </div>
                                                    </React.Fragment>
                                                ) : (
                                                    <React.Fragment></React.Fragment>
                                                )}
                                                <hr />
                                                <div className="col-md-12 my-2">
                                                    <label>Maneja Beneficios?</label>
                                                    <select name="manejabeneficios" className="form-control" defaultValue={'DEFAULT'} onChange={this.ManejaBeneficios}>
                                                        <option value="DEFAULT" disabled>Seleccione...</option>
                                                        <option value="1">SI</option>
                                                        <option value="0">NO</option>
                                                    </select>
                                                </div>
                                                {this.state.manejaBeneficiosState ? (
                                                    <React.Fragment>
                                                        <div className="col-md-12 my-2">
                                                            <MDBInput label="Información Adicional" name="descbenefprodInfo" type="textarea" rows="5" className="mr-5" value={this.state.descbenefprodInfo} onChange={this.textInput} />
                                                        </div>
                                                        <div className="col-md-12 my-2">
                                                            <label className="font-weight-bold">Ingrese los Beneficios</label>
                                                            <hr />
                                                            <div className="row">
                                                                <div className="col-md-6 my-2">
                                                                    <MDBInput label="Descripción" name="descbenefprod" type="textarea" rows="5" className="mr-5" value={this.state.descbenefprod} onChange={this.textInput} />
                                                                </div>
                                                                <div className="col-md-6 my-2">
                                                                    <label>Maneja Icono o Imagen?</label>
                                                                    <select name="manejarutaimgBen" className="form-control" value={this.state.manejarutaimgBen} onChange={this.ManejaRutaBene}>
                                                                        <option value="DEFAULT" disabled>Seleccione...</option>
                                                                        <option value="1">SI</option>
                                                                        <option value="0">NO</option>
                                                                    </select>
                                                                </div>
                                                                {this.state.stateManejaRutaBen ?
                                                                    (
                                                                        <React.Fragment>
                                                                            <div className="col-md-6">
                                                                                <FileUploaderAc ref={this.uploader2} estado={true} FCambiar={this.cambioAct} />
                                                                            </div>
                                                                        </React.Fragment>
                                                                    ) : (
                                                                        <React.Fragment></React.Fragment>
                                                                    )}
                                                                <div onClick={() => { this.agregarBeneficios() }} style={{ cursor: 'pointer', position: 'absolute', top: '100px', right: '-40px', zIndex: '999' }} >
                                                                    <MDBIcon icon="plus-circle" size="2x" />
                                                                </div>
                                                            </div>
                                                            <ul style={{ listStyle: 'none' }}>
                                                                {Beneficios.map((e, i) => {
                                                                    var stateImgBen = true;
                                                                    if (e.manejarutaimgBen === 0) {
                                                                        stateImgBen = true;
                                                                    } else {
                                                                        stateImgBen = false;
                                                                    }
                                                                    return [
                                                                        <li key={i} className="justify-content-center d-flex pt-3 border border-warning">
                                                                            <p><b className="font-weight-bold">Descripción:</b> {e.descbenefprod} &nbsp;&nbsp;</p>
                                                                            {stateImgBen ? <p></p> : <p><b className="font-weight-bold">Ruta Imagen:</b> {e.rutaimgbenefprod}</p>}
                                                                            <MDBIcon onClick={() => { this.eliminarBeneficio(e) }} icon="trash" className="text-danger" size="lg" style={{ position: 'absolute', right: '50px', marginTop: '5px', cursor: 'pointer' }} />
                                                                        </li>
                                                                    ]
                                                                })}
                                                            </ul>
                                                        </div>
                                                    </React.Fragment>
                                                ) : (
                                                    <React.Fragment></React.Fragment>
                                                )}
                                                <hr />
                                                <div className="col-md-12 my-2">
                                                    <label>Maneja Requisitos?</label>
                                                    <select name="manejarequisitos" className="form-control" defaultValue={'DEFAULT'} onChange={this.ManejaRequisitos}>
                                                        <option value="DEFAULT" disabled>Seleccione...</option>
                                                        <option value="1">SI</option>
                                                        <option value="0">NO</option>
                                                    </select>
                                                </div>
                                                {this.state.manejaRequisitosState ? (
                                                    <React.Fragment>
                                                        <div className="col-md-12 my-2">
                                                            <MDBInput label="Información Adicional" name="descrequisprodInfo" type="textarea" rows="5" className="mr-5" value={this.state.descrequisprodInfo} onChange={this.textInput} />
                                                        </div>
                                                        <div className="col-md-12 my-2">
                                                            <label className="font-weight-bold">Ingrese los Requisitos</label>
                                                            <hr />
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <MDBInput label="Descripción" name="descrequisprod" type="textarea" rows="5" className="mr-5" value={this.state.descrequisprod} onChange={this.textInput} />
                                                                </div>
                                                                <div className="col-md-6 my-2">
                                                                    <label>Maneja Icono o Imagen?</label>
                                                                    <select name="manejarutaimgReq" className="form-control" value={this.state.manejarutaimgReq} onChange={this.ManejaRutaReq}>
                                                                        <option value="DEFAULT" disabled>Seleccione...</option>
                                                                        <option value="1">SI</option>
                                                                        <option value="0">NO</option>
                                                                    </select>
                                                                </div>
                                                                {this.state.stateManejaRutaReq ?
                                                                    (
                                                                        <React.Fragment>
                                                                            <div className="col-md-6">
                                                                                <FileUploaderAc ref={this.uploader3} estado={true} FCambiar={this.cambioAct} />
                                                                            </div>
                                                                        </React.Fragment>
                                                                    ) : (
                                                                        <React.Fragment></React.Fragment>
                                                                    )}
                                                                <div onClick={() => { this.agregarRequisitos() }} style={{ cursor: 'pointer', position: 'absolute', top: '100px', right: '-40px', zIndex: '999' }} >
                                                                    <MDBIcon icon="plus-circle" size="2x" />
                                                                </div>
                                                            </div>
                                                            <ul style={{ listStyle: 'none' }}>
                                                                {Requisitos.map((e, i) => {
                                                                    var stateImgReq = true;
                                                                    if (e.manejarutaimgReq === 0) {
                                                                        stateImgReq = true;
                                                                    } else {
                                                                        stateImgReq = false;
                                                                    }
                                                                    return [
                                                                        <li key={i} className="justify-content-center d-flex pt-3 border border-warning">
                                                                            <p><b className="font-weight-bold">Descripción:</b> {e.descrequisprod} &nbsp;&nbsp;</p>
                                                                            {stateImgReq ? <p></p> : <p><b className="font-weight-bold">Ruta Imagen:</b> {e.rutaimgrequistprod}</p>}
                                                                            <MDBIcon onClick={() => { this.eliminarRequisito(e) }} icon="trash" className="text-danger" size="lg" style={{ position: 'absolute', right: '50px', marginTop: '5px', cursor: 'pointer' }} />
                                                                        </li>
                                                                    ]
                                                                })}
                                                            </ul>
                                                        </div>
                                                    </React.Fragment>
                                                ) : (
                                                    <React.Fragment></React.Fragment>
                                                )}
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment>
                                                <div className="col-md-12 my-2 mt-3">
                                                    <MDBInput label="Escriba la URL" name="rutaproddet" type="text" value={this.state.rutaproddet} onChange={this.textInput} />
                                                    {errors.rutaproddet && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '50px' }}>{errors.rutaproddet}</strong>}
                                                </div>
                                            </React.Fragment>
                                        )}
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment></React.Fragment>
                                )}
                            </div>
                        </div>
                        <div className="pt-2 mt-5 modal-footer w-100">
                            <button type="button" className="btn btn-primary px-5" onClick={this.insertarDatos}>Crear Vista Detalles &nbsp; <MDBIcon icon="save" /></button>
                        </div>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {stateEdit ?
                            (
                                <React.Fragment>
                                    <div className="container-fluid">
                                        <div className="table-responsive text-center">
                                            <MDBTable bordered>
                                                <MDBTableHead>
                                                    <tr className="font-weight-bold">
                                                        <td>Producto</td>
                                                        <td>Imagen Principal</td>
                                                        <td>Ruta Vista Establecida</td>
                                                        <td>Texto 1</td>
                                                        <td>Texto 2</td>
                                                        <td>Texto 3</td>
                                                        <td>Información Adicional</td>
                                                        <td>Editar</td>
                                                    </tr>
                                                </MDBTableHead>
                                                <MDBTableBody>
                                                    {this.state.estadoSinResultados ? (
                                                        <React.Fragment>
                                                            <tr>
                                                                <td>
                                                                    <div className="alert alert-warning">Sin resultados...</div>
                                                                </td>
                                                            </tr>
                                                        </React.Fragment>
                                                    ) : (
                                                        <React.Fragment>
                                                            {this.state.infoProductosDetalles.map((e, i) => {
                                                                var estadoImagen = '';
                                                                if (e.rutaimgdetalle === "NA") {
                                                                    estadoImagen = true;
                                                                } else {
                                                                    estadoImagen = false;
                                                                }
                                                                return (
                                                                    <React.Fragment key={i}>
                                                                        <tr>
                                                                            <td>{e.nombprodutra}</td>
                                                                            {this.state.stateImg ? (
                                                                                <td>{estadoImagen ? <p>Vista Establecida</p> : <img width="200" src={`${URLImgFront}/assets${e.rutaimgdetalle}`} alt={e.rutaimgdetalle} />}</td>
                                                                            ) : (
                                                                                <React.Fragment></React.Fragment>
                                                                            )}
                                                                            <td className="text-justify">{e.rutaproddet}</td>
                                                                            <td className="text-justify">{e.txtprodvtdet1}</td>
                                                                            <td className="text-justify">{e.txtprodvtdet2}</td>
                                                                            <td className="text-justify">{e.txtprodvtdet3}</td>
                                                                            <td className="text-justify">{e.infoadicional}</td>
                                                                            <td><MDBIcon onClick={() => { this.editar(e.idprodvtadet) }} icon="edit" size="2x" style={{ color: 'green', cursor: 'pointer' }} /></td>
                                                                        </tr>
                                                                    </React.Fragment>
                                                                );
                                                            })}
                                                        </React.Fragment>
                                                    )}
                                                </MDBTableBody>
                                            </MDBTable>
                                        </div>
                                    </div>
                                </React.Fragment>
                            ) : (<React.Fragment>
                                <div className="container">
                                    <div className="d-flex" onClick={() => { this.setState({ stateEdit: true }) }} style={{ position: 'absolute', cursor: 'pointer' }}><MDBIcon icon="times" className="text-danger pt-1" />&nbsp;<p style={{ borderBottom: '1px solid #000' }}>Cancelar</p></div>
                                    <div className="row pt-4">
                                        <div className="col-md-4 my-2 mt-3">
                                            <label>Producto Seleccionado</label>
                                            <input type="text" name="editnombprodutra" className="form-control" value={this.state.editnombprodutra} disabled />
                                        </div>
                                        <div className="col-md-4 my-2 mt-3">
                                            <label>Desea cambiar el producto - opcional</label>
                                            <select name="editidprodutra" className="form-control" defaultValue={'DEFAULT'} onChange={this.textInput}>
                                                <option value="DEFAULT" disabled>Seleccione...</option>
                                                {infoProductos.map((e, i) => {
                                                    return [
                                                        <option key={i} value={e.idprodutra}>{e.nombprodutra}</option>
                                                    ]
                                                })}
                                            </select>
                                        </div>
                                        <div className="col-md-444444 my-2 mt-3">
                                            <label>Desea cambiar el tipo de vista? - obligatorio</label>
                                            <select name="statevistaEstado" className="form-control" value={this.state.statevistaEstado} onChange={this.cambioEstadoVista}>
                                                <option value="DEFAULT" disabled>Seleccione...</option>
                                                <option value="1">Generica</option>
                                                <option value="2">Establecida</option>
                                            </select>
                                        </div>
                                        {stateActive ? (
                                            <React.Fragment>
                                                {stateView ? (
                                                    <React.Fragment>
                                                        <div className="col-md-12 my-2 text-center">
                                                            <FileUploader ref={this.uploader} estado={estadoCambio1} ruta={this.state.editrutaimgdetalle} FCambiar={this.cambio} />
                                                        </div>
                                                        <div className="col-md-4 my-2">
                                                            <MDBInput label="Texto 1" name="edittxtprodvtdet1" type="text" className="mr-5" value={this.state.edittxtprodvtdet1} onChange={this.textInput} />
                                                        </div>
                                                        <div className="col-md-4 my-2">
                                                            <MDBInput label="Texto 2" name="edittxtprodvtdet2" type="text" className="mr-5" value={this.state.edittxtprodvtdet2} onChange={this.textInput} />
                                                        </div>
                                                        <div className="col-md-4 my-2">
                                                            <MDBInput label="Texto 3" name="edittxtprodvtdet3" type="text" className="mr-5" value={this.state.edittxtprodvtdet3} onChange={this.textInput} />
                                                        </div>
                                                        <div className="col-md-12 my-2">
                                                            <MDBInput label="Información Adicional" name="editinfoadicional" type="textarea" rows="5" className="mr-5" value={this.state.editinfoadicional} onChange={this.textInput} />
                                                        </div>
                                                        <div className="col-md-12 my-2">
                                                            <label>Maneja Items?</label>
                                                            <select name="editmanejaitems" className="form-control" value={this.state.editmanejaitems} onChange={this.ManejaItems}>
                                                                <option value="DEFAULT" disabled>Seleccione...</option>
                                                                <option value="1">SI</option>
                                                                <option value="0">NO</option>
                                                            </select>
                                                        </div>
                                                        <hr />
                                                        {this.state.manejaItemState ? (
                                                            <React.Fragment>
                                                                <div className="col-md-12 my-2">
                                                                    <label className="font-weight-bold">Ingrese los Items</label>
                                                                    <hr />
                                                                    <div className="row">
                                                                        <div className="col-md-6 my-2">
                                                                            <MDBInput label="Descripción" name="descitemprod" type="textarea" rows="5" className="mr-5" value={this.state.editdescitemprod} onChange={this.textInput} />
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <FileUploaderAc ref={this.uploader1} estado={true} FCambiar={this.cambioAct} />
                                                                        </div>
                                                                        <div onClick={() => { this.agregarItems() }} style={{ cursor: 'pointer', position: 'absolute', top: '100px', right: '-40px', zIndex: '999' }} >
                                                                            <MDBIcon icon="plus-circle" size="2x" />
                                                                        </div>
                                                                    </div>
                                                                    <ul style={{ listStyle: 'none' }}>
                                                                        {Items.map((e, i) => {
                                                                            if (e.rutaiconitem === 'N/A' && e.descitemprod === 'N/A') {
                                                                                return [];
                                                                            } else {
                                                                                return [
                                                                                    <li key={i} className="justify-content-center d-flex pt-3 border border-warning">
                                                                                        <p className="w-50"><b className="font-weight-bold">Descripción:</b> {e.descitemprod} &nbsp;&nbsp;</p>
                                                                                        <p className="d-flex"><b className="font-weight-bold">Imagen:</b> <img src={`${URLImgFront}/assets${e.rutaiconitem}`} alt={e.rutaiconitem} style={{ position: 'absolute', width: '45px', height: '45px', marginLeft: '70px', marginTop: '-10px' }} /></p>
                                                                                        <MDBIcon onClick={() => { this.eliminarItems(e) }} icon="trash" className="text-danger" size="lg" style={{ position: 'absolute', right: '50px', marginTop: '5px', cursor: 'pointer' }} />
                                                                                    </li>
                                                                                ]
                                                                            }
                                                                        })}
                                                                    </ul>
                                                                </div>
                                                            </React.Fragment>
                                                        ) : (
                                                            <React.Fragment></React.Fragment>
                                                        )}
                                                        <hr />
                                                        <div className="col-md-12 my-2">
                                                            <label>Maneja Beneficios?</label>
                                                            <select name="editmanejaBen" className="form-control" value={this.state.editmanejaBen} onChange={this.ManejaBeneficios}>
                                                                <option value="DEFAULT" disabled>Seleccione...</option>
                                                                <option value="1">SI</option>
                                                                <option value="0">NO</option>
                                                            </select>
                                                        </div>
                                                        {this.state.manejaBeneficiosState ? (
                                                            <React.Fragment>
                                                                <div className="col-md-12 my-2">
                                                                    <MDBInput label="Información Adicional" name="editdescbenefprodInfo" type="textarea" rows="5" className="mr-5" value={this.state.editdescbenefprodInfo} onChange={this.textInput} />
                                                                </div>
                                                                <div className="col-md-12 my-2">
                                                                    <label className="font-weight-bold">Ingrese los Beneficios</label>
                                                                    <hr />
                                                                    <div className="row">
                                                                        <div className="col-md-6 my-2">
                                                                            <MDBInput label="Descripción" name="descbenefprod" type="textarea" rows="5" className="mr-5" value={this.state.editdescbenefprod} onChange={this.textInput} />
                                                                        </div>
                                                                        <div className="col-md-6 my-2">
                                                                            <label>Maneja Icono o Imagen?</label>
                                                                            <select name="manejarutaimg" className="form-control" defaultValue={'DEFAULT'} onChange={this.ManejaRutaBene}>
                                                                                <option value="DEFAULT" disabled>Seleccione...</option>
                                                                                <option value="1">SI</option>
                                                                                <option value="0">NO</option>
                                                                            </select>
                                                                        </div>
                                                                        {this.state.stateManejaRutaBen ?
                                                                            (
                                                                                <React.Fragment>
                                                                                    <div className="col-md-6">
                                                                                        <FileUploaderAc ref={this.uploader2} estado={true} FCambiar={this.cambioAct} />
                                                                                    </div>
                                                                                </React.Fragment>
                                                                            ) : (
                                                                                <React.Fragment></React.Fragment>
                                                                            )}
                                                                        <div onClick={() => { this.agregarBeneficios() }} style={{ cursor: 'pointer', position: 'absolute', top: '100px', right: '-40px', zIndex: '999' }} >
                                                                            <MDBIcon icon="plus-circle" size="2x" />
                                                                        </div>
                                                                    </div>
                                                                    <ul style={{ listStyle: 'none' }}>
                                                                        {Beneficios.map((e, i) => {
                                                                            var stateImgBen = true;
                                                                            if (e.rutaimgbenefprod === 'NA') {
                                                                                stateImgBen = true;
                                                                            } else {
                                                                                stateImgBen = false;
                                                                            }
                                                                            if (e.rutaimgbenefprod === 'N/A' && e.descbenefprod === 'N/A') {
                                                                                return [];
                                                                            } else {
                                                                                return [
                                                                                    <li key={i} className="justify-content-center d-flex pt-3 border border-warning">
                                                                                        <p className="w-50"><b className="font-weight-bold">Descripción:</b> {e.descbenefprod} &nbsp;&nbsp;</p>
                                                                                        {stateImgBen ? <p></p> : <p className="d-flex"><b className="font-weight-bold">Imagen:</b> <img src={`${URLImgFront}/assets${e.rutaimgbenefprod}`} alt={e.rutaimgbenefprod} style={{ position: 'absolute', width: '45px', height: '45px', marginLeft: '70px', marginTop: '-10px' }} /></p>}
                                                                                        <MDBIcon onClick={() => { this.eliminarBeneficio(e) }} icon="trash" className="text-danger" size="lg" style={{ position: 'absolute', right: '50px', marginTop: '5px', cursor: 'pointer' }} />
                                                                                    </li>
                                                                                ]
                                                                            }
                                                                        })}
                                                                    </ul>
                                                                </div>
                                                            </React.Fragment>
                                                        ) : (
                                                            <React.Fragment></React.Fragment>
                                                        )}
                                                        <hr />
                                                        <div className="col-md-12 my-2 pb-5">
                                                            <label>Maneja Requisitos?</label>
                                                            <select name="editmanejaReq" className="form-control" value={this.state.editmanejaReq} onChange={this.ManejaRequisitos}>
                                                                <option value="DEFAULT" disabled>Seleccione...</option>
                                                                <option value="1">SI</option>
                                                                <option value="0">NO</option>
                                                            </select>
                                                        </div>
                                                        {this.state.manejaRequisitosState ? (
                                                            <React.Fragment>
                                                                <div className="col-md-12 my-2">
                                                                    <MDBInput label="Información Adicional" name="editdescrequisprodInfo" type="textarea" rows="5" className="mr-5" value={this.state.editdescrequisprodInfo} onChange={this.textInput} />
                                                                </div>
                                                                <div className="col-md-12 my-2">
                                                                    <label className="font-weight-bold">Ingrese los Requisitos</label>
                                                                    <hr />
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <MDBInput label="Descripción" name="descrequisprod" type="textarea" rows="5" className="mr-5" value={this.state.editdescrequisprod} onChange={this.textInput} />
                                                                        </div>
                                                                        <div className="col-md-6 my-2">
                                                                            <label>Maneja Icono o Imagen?</label>
                                                                            <select name="manejarutaimg" className="form-control" defaultValue={'DEFAULT'} onChange={this.ManejaRutaReq}>
                                                                                <option value="DEFAULT" disabled>Seleccione...</option>
                                                                                <option value="1">SI</option>
                                                                                <option value="0">NO</option>
                                                                            </select>
                                                                        </div>
                                                                        {this.state.stateManejaRutaReq ?
                                                                            (
                                                                                <React.Fragment>
                                                                                    <div className="col-md-6">
                                                                                        <FileUploaderAc ref={this.uploader3} estado={true} FCambiar={this.cambioAct} />
                                                                                    </div>
                                                                                </React.Fragment>
                                                                            ) : (
                                                                                <React.Fragment></React.Fragment>
                                                                            )}
                                                                        <div onClick={() => { this.agregarRequisitos() }} style={{ cursor: 'pointer', position: 'absolute', top: '100px', right: '-40px', zIndex: '999' }} >
                                                                            <MDBIcon icon="plus-circle" size="2x" />
                                                                        </div>
                                                                    </div>
                                                                    <ul style={{ listStyle: 'none' }}>
                                                                        {Requisitos.map((e, i) => {
                                                                            var stateImgReq = true;
                                                                            if (e.rutaimgrequistprod === 'NA') {
                                                                                stateImgReq = true;
                                                                            } else {
                                                                                stateImgReq = false;
                                                                            }
                                                                            if (e.rutaimgrequistprod === 'N/A' && e.descrequisprod === 'N/A') {
                                                                                return [];
                                                                            } else {
                                                                                return [
                                                                                    <li key={i} className="justify-content-center d-flex pt-3 border border-warning">
                                                                                        <p className="w-50"><b className="font-weight-bold">Descripción:</b> {e.descrequisprod} &nbsp;&nbsp;</p>
                                                                                        {stateImgReq ? <p></p> : <span className="d-flex"><span className="font-weight-bold">Imagen:</span> <img src={`${URLImgFront}/assets${e.rutaimgrequistprod}`} alt={e.rutaimgrequistprod} style={{ position: 'absolute', width: '45px', height: '45px', marginLeft: '70px', marginTop: '-10px' }} /></span>}
                                                                                        <MDBIcon onClick={() => { this.eliminarRequisito(e) }} icon="trash" className="text-danger" size="lg" style={{ position: 'absolute', right: '50px', marginTop: '5px', cursor: 'pointer' }} />
                                                                                    </li>
                                                                                ];
                                                                            }
                                                                        })}
                                                                    </ul>
                                                                </div>
                                                            </React.Fragment>
                                                        ) : (
                                                            <React.Fragment></React.Fragment>
                                                        )}
                                                    </React.Fragment>
                                                ) : (
                                                    <React.Fragment>
                                                        <div className="col-md-12 my-2 mt-3">
                                                            <MDBInput label="Escriba la URL" name="editrutaproddet" type="text" value={this.state.editrutaproddet} onChange={this.textInput} />
                                                        </div>
                                                    </React.Fragment>
                                                )}
                                                <div className="pt-2 mt-5 modal-footer w-100">
                                                    <button type="submit" className="btn btn-success px-5" onClick={() => { this.ActualizarDatos(this.state.idSeleccionado) }}>Guardar cambios &nbsp; <MDBIcon icon="save" /></button>
                                                </div>
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment></React.Fragment>
                                        )}
                                    </div>
                                </div>
                            </React.Fragment>)}
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

export default ProductosDetalles;