import React, { Component } from 'react';
import { URLImgFront } from '../../config/URLDB';
import { Coopsejos_ } from '../../services/WebAPI_Utrahuilca';

class Seccion1 extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    async componentDidMount() {
        this.cargarInformacion();
    }

    cargarInformacion = () => {
        const data = {
            tran: 4,
            idvtacoopsejos: 0,
            nomvtacoopsejos: 'NA',
            rutaimgcoopsejos: 'NA',
            txtcoopsejos1: 'NA',
            txtcoopsejos2: 'NA',
            colortexto: 'NA',
            estado: 1,
            usucrea: 'NA'
        }
        Coopsejos_(data).then(response => {
            try {
                if (response.data.length > 0) {
                    if (!response.data[0].message) {
                        setTimeout(() => {
                            this.setState({
                                rutaimgcoopsejos: response.data[0].rutaimgcoopsejos,
                                txtcoopsejos1: response.data[0].txtcoopsejos1,
                                txtcoopsejos2: response.data[0].txtcoopsejos2,
                                color: response.data[0].colortexto,
                                estadoImagen: true,
                            })
                        }, 120);
                    } else {
                        this.setState({
                            estadoImagen: false
                        })
                    }
                } else { }
            } catch (error) {
                console.log(error);
            }
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className="coopsejos_diseño" style={{ width: '100%', paddingTop: '120px' }}>
                    <img style={{ width: '100%', height: '30vh', objectFit: 'cover' }} className="img-fluid" src={`${URLImgFront}/assets${this.state.rutaimgcoopsejos}`} alt="img1" />
                    <div className="contenido_text_cp text-center" style={{ position: 'absolute', top: '200px', left: '0', right: '0', zIndex: '2' }}>
                        <p style={{ fontSize: '20px', color: '' + this.state.color + '' }}>{this.state.txtcoopsejos1}</p>
                        <p style={{ fontSize: '40px', color: '' + this.state.color + '', fontWeight: 'bold' }}>{this.state.txtcoopsejos2}</p>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Seccion1;