import { MDBIcon, MDBInput, MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import React, { Component } from 'react';
import { CargueImagenes, ComunicadosDetalles_, CargueDocumentos } from '../../../services/WebAPI_Utrahuilca';
import FileUploaderB from './ComunicadosDetalles/FileUploaderB';
import FileUploader from './ComunicadosDetalles/FileUploader';
import Swal from 'sweetalert2';
import { URLImgFront } from '../../../config/URLDB';

const validate = values => {
    const errors = {}
    if (!values.titulocomunic) {
        errors.titulocomunic = 'campo obligatorio'
    }
    if (!values.desccomunic) {
        errors.desccomunic = 'campo obligatorio'
    }
    if (values.selectedFile === null) {
        errors.selectedFile = 'campo obligatorio'
    }
    if (values.codcomunicado === 'DEFAULT') {
        errors.codcomunicado = 'campo obligatorio'
    }
    return errors;
};

class ConveniosDetalles extends Component {
    uploader = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            estadoNuevo: false,
            errors: {},
            estadoCambioImg1: true,
            estadoSinInfo: false,
            infoComunicados: [],
            idcomunicado: null,
            codcomunicado: 'DEFAULT',
            rutaimgcomunic: '',
            titulocomunic: '',
            desccomunic: '',
            rutalinkpdf: '',
            estadoCambio: false,
            estado: null,
            selectedFile: null,
        };
    }

    async componentDidMount() {
        this.cargarInformacion();
    }

    cargarInformacion = () => {
        const data = {
            tran: 4,
            idcomunicado: 0,
            codcomunicado: 0,
            rutaimgcomunic: 'NA',
            titulocomunic: 'NA',
            desccomunic: 'NA',
            rutalinkpdf: 'NA',
            estado: 0,
            usucrea: 'NA'
        }
        ComunicadosDetalles_(data).then(response => {
            try {
                if (response.data.length > 0) {
                    if (!response.data[0].message) {
                        this.setState({
                            idSeleccionado: response.data[0].idcomunicado
                        })
                        setTimeout(() => {
                            this.setState({
                                infoComunicados: response.data,
                                rutaimgcomunic: response.data[0].rutaimgcomunic,
                                estado: response.data[0].estado,
                                estadoSinInfo: true,
                                estadoImagen: true,
                            })
                        }, 120);
                    } else {
                        this.setState({
                            estadoSinResultados: false,
                            estadoNuevo: true,
                            estadoCambioImg1: true,
                            estadoSinInfo: false
                        })
                    }
                } else { }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    text: 'Ocurrio un error al consumir el servicio, comuniquese con sistemas!'
                })
            }
        });
    }

    textInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onFileChange = event => {
        this.setState({ selectedFile: event.target.files[0] });
    };

    cambioImagen1 = (name, file) => {
        this.setState({
            estadoCambioImg1: true
        });
    }

    cargarImagen = () => {
        var formData = new FormData();
        if (undefined === this.uploader.current) return;
        formData.append("file", this.uploader.current.getFileObject());
        formData.append("rutaImagen", '/Comunicados/Comunicados Detalles/' + this.uploader.current.getFileName());
        CargueImagenes(formData).then(response => {
            if (response) {
                this.cargarInformacion();
            }
        });
    }

    cargarArchivos = () => {
        var formData = new FormData();
        if (undefined === this.state.selectedFile || null === this.state.selectedFile) return;
        formData.append("file", this.state.selectedFile, this.state.selectedFile.name);
        formData.append("rutaImagen", '/Archivos/' + this.state.selectedFile.name);
        CargueDocumentos(formData).then(response => {
            if (response) {
                this.cargarInformacion();
            }
        });
    }

    insertarDatos = () => {
        const { errors, ...sinErrors } = this.state;
        const result = validate(sinErrors);
        this.setState({ errors: result });
        if (!Object.keys(result).length) {
            // formulario validado
            const data = {
                tran: 1,
                idcomunicado: 0,
                codcomunicado: parseInt(this.state.codcomunicado),
                rutaimgcomunic: '/Comunicados/Comunicados Detalles/' + this.uploader.current.getFileName(),
                titulocomunic: this.state.titulocomunic,
                desccomunic: this.state.desccomunic,
                rutalinkpdf: this.state.selectedFile.name,
                estado: 1,
                usucrea: sessionStorage.getItem('idusuario')
            }
            ComunicadosDetalles_(data).then(response => {
                if (response.data.length > 0) {
                    if (response.data[0].message === "000000") {
                        this.cargarImagen();
                        this.cargarArchivos();
                        Swal.fire({
                            icon: 'success',
                            title: 'Se agrego correctamente.'
                        })
                        this.setState({
                            estadoNuevo: false,
                            idcomunicado: null,
                            codcomunicado: 'DEFAULT',
                            rutaimgcomunic: '',
                            titulocomunic: '',
                            desccomunic: '',
                            rutalinkpdf: '',
                            estado: null,
                            estadoImagen: false,
                            displayColorPicker: false,
                            rutafondoImg: '',
                            color: '',
                        })
                        this.cargarInformacion();
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error al insertar.'
                        })
                    }
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Ocurrio un error!'
                    })
                }
            });
        }
    }

    editar = (id) => {
        var seleccionado = [];
        this.state.infoComunicados.map((e, i) => {
            if (e.idcomunicado === id) {
                seleccionado.push(e)
            }
            return [];
        })
        this.setState({
            idcomunicado: seleccionado[0].idcomunicado,
            codcomunicado: seleccionado[0].codcomunicado,
            titulocomunic: seleccionado[0].titulocomunic,
            desccomunic: seleccionado[0].desccomunic,
            rutalinkpdf: seleccionado[0].rutalinkpdf,
            rutaimgcomunic: seleccionado[0].rutaimgcomunic,
            estadoEditar: true
        })
    }

    cambio = (name, file) => {
        this.setState({
            estadoCambio: true
        });
    }

    actualizarDatos = (id) => {
        var ruta = '';
        var rutaPDF = '';
        var capNombre = this.uploader.current.getFileName();
        if (capNombre === undefined || capNombre === null) {
            ruta = this.state.rutaimgcomunic;
        } else {
            ruta = '/Comunicados/Comunicados Detalles/' + this.uploader.current.getFileName();
        }
        if (this.state.selectedFile === undefined || this.state.selectedFile === null) {
            rutaPDF = this.state.rutalinkpdf;
        } else {
            rutaPDF = this.state.selectedFile.name;
        }
        const data = {
            tran: 1,
            idcomunicado: id,
            codcomunicado: parseInt(this.state.codcomunicado),
            rutaimgcomunic: ruta,
            titulocomunic: this.state.titulocomunic,
            desccomunic: this.state.desccomunic,
            rutalinkpdf: rutaPDF,
            estado: 1,
            usucrea: sessionStorage.getItem('idusuario')
        }
        ComunicadosDetalles_(data).then(response => {
            if (response.data.length > 0) {
                if (response.data[0].message === "000000") {
                    this.cargarImagen();
                    this.cargarArchivos();
                    this.setState({
                        estadoCambio: false,
                        estadoEditar: false,
                        rutaimgcomunic: '',
                        titulocomunic: '',
                        desccomunic: '',
                        rutalinkpdf: '',
                    })
                    Swal.fire({
                        icon: 'success',
                        title: 'Se actualizo satisfactoriamente'
                    })
                    this.cargarInformacion();
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Ocurrio un error al actualizar'
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Ocurrio un error!'
                })
            }
        });
    }

    activar = (id) => {
        const data = {
            tran: 6,
            idcomunicado: id,
            codcomunicado: 0,
            rutaimgcomunic: 'NA',
            titulocomunic: 'NA',
            desccomunic: 'NA',
            rutalinkpdf: 'NA',
            estado: 1,
            usucrea: sessionStorage.getItem('idusuario')
        }
        ComunicadosDetalles_(data).then(response => {
            if (response) {
                this.setState({
                    estadoS: false
                })
                Swal.fire({
                    icon: 'success',
                    title: 'Se activo satisfactoriamente'
                });
                this.cargarInformacion();
            }
        });
    }

    inactivar = (id) => {
        const data = {
            tran: 6,
            idcomunicado: id,
            codcomunicado: 0,
            rutaimgcomunic: 'NA',
            titulocomunic: 'NA',
            desccomunic: 'NA',
            rutalinkpdf: 'NA',
            estado: 0,
            usucrea: sessionStorage.getItem('idusuario')
        }
        ComunicadosDetalles_(data).then(response => {
            if (response) {
                this.setState({
                    estadoS: false
                })
                Swal.fire({
                    icon: 'success',
                    title: 'Se inactivo satisfactoriamente'
                });
                this.cargarInformacion();
            }
        });
    }

    handleNew = () => {
        this.setState({
            errors: {},
            estadoNuevo: !this.state.estadoNuevo,
        })
        if (this.state.estadoNuevo) {
            this.setState({
                errors: {}
            })
            this.cargarInformacion();
        }
    }

    render() {
        const { estadoNuevo, estadoCambioImg1, estadoCambio, estadoSinInfo, errors, titulocomunic, desccomunic, estadoEditar, idcomunicado, codcomunicado, infoComunicados } = this.state;
        return (
            <React.Fragment>
                <p className="font-weight-bold">Comunicados/Formatos</p>
                <hr />
                {estadoSinInfo ? (
                    <React.Fragment>
                        <div className="plus_add_agencia align-items-center d-flex" onClick={() => { this.handleNew() }}>
                            {estadoNuevo ? <p>Cancelar &nbsp;<MDBIcon icon="times" size="lg" /></p> : <p>Agregar Nuevo &nbsp;<MDBIcon icon="plus-circle" size="lg" /></p>}
                        </div>
                    </React.Fragment>
                ) : (
                    <React.Fragment></React.Fragment>
                )}
                {estadoNuevo ? (
                    <React.Fragment>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 my-2 text-center">
                                    <FileUploaderB ref={this.uploader} estadoImg={estadoCambioImg1} FCambiar={this.cambioImagen1} />
                                </div>
                                <div className="col-md-6 my-2">
                                    <label>Tipo de Vista</label>
                                    <select name="codcomunicado" className="form-control" value={codcomunicado} onChange={this.textInput}>
                                        <option value="DEFAULT" disabled>Seleccione...</option>
                                        <option value="1">Comunicados</option>
                                        <option value="2">Formatos</option>
                                    </select>
                                    {errors.codcomunicado && <strong className="text-danger" style={{ fontSize: '13px' }}>{errors.codcomunicado}</strong>}
                                </div>
                                <div className="col-md-6 my-2">
                                    <label>Titulo</label>
                                    <MDBInput name="titulocomunic" type="text" value={titulocomunic} onChange={this.textInput} />
                                    {errors.titulocomunic && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '80px' }}>{errors.titulocomunic}</strong>}
                                </div>
                                <div className="col-md-6 my-2">
                                    <label>Descripción</label>
                                    <MDBInput name="desccomunic" type="textarea" value={desccomunic} onChange={this.textInput} />
                                    {errors.desccomunic && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', marginTop: '-35px' }}>{errors.desccomunic}</strong>}
                                </div>
                                <div className="col-md-6 my-2">
                                    <label>Seleccione documento</label><br /><br />
                                    <input type="file" onChange={this.onFileChange} /><br />
                                    {errors.selectedFile && <strong className="text-danger" style={{ fontSize: '13px' }}>{errors.selectedFile}</strong>}
                                </div>
                            </div>
                        </div>
                        <div className="pt-2 mt-5 modal-footer w-100">
                            <button type="button" className="btn btn-primary px-5" onClick={this.insertarDatos}>Crear Producto &nbsp; <MDBIcon icon="save" /></button>
                        </div>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className="container-fluid">
                            <div className="table-responsive text-center w-100">
                                <MDBTable>
                                    <MDBTableHead>
                                        <tr className="font-weight-bold">
                                            <td>Imagen</td>
                                            <td>Tipo de Vista</td>
                                            <td>Titulo</td>
                                            <td>Descripción</td>
                                            <td>Ruta PDF</td>
                                            {estadoEditar ?
                                                (
                                                    <React.Fragment>
                                                        <td>Guardar</td>
                                                        <td>Cancelar</td>
                                                    </React.Fragment>
                                                ) : (
                                                    <React.Fragment>
                                                        <td>Editar</td>
                                                        <td>Inactivar</td>
                                                    </React.Fragment>
                                                )}
                                        </tr>
                                    </MDBTableHead>
                                    <MDBTableBody>
                                        {estadoEditar ?
                                            (
                                                <React.Fragment>
                                                    <tr>
                                                        <td>
                                                            <FileUploader ref={this.uploader} estadoImg={estadoCambio} ruta={this.state.rutaimgcomunic} FCambiar={this.cambio} />
                                                        </td>
                                                        <td className="w-25">
                                                            <select name="codcomunicado" className="form-control" value={this.state.codcomunicado} onChange={this.textInput}>
                                                                <option value="DEFAULT" disabled>Seleccione...</option>
                                                                <option value="1">Comunicados</option>
                                                                <option value="2">Formatos</option>
                                                            </select>
                                                        </td>
                                                        <td className="w-25"><textarea className="form-control" type="textarea" rows="5" name="titulocomunic" value={this.state.titulocomunic} onChange={this.textInput} /></td>
                                                        <td className="w-25"><textarea className="form-control" type="textarea" rows="5" name="desccomunic" value={this.state.desccomunic} onChange={this.textInput} /></td>
                                                        <td className="w-25">
                                                            {this.state.rutalinkpdf}
                                                            <hr />
                                                            <input type="file" onChange={this.onFileChange} />
                                                        </td>
                                                        <td className="text-center" onClick={() => { this.actualizarDatos(idcomunicado) }}><MDBIcon icon="save" size="2x" style={{ color: 'blue', cursor: 'pointer' }} /></td>
                                                        <td className="text-center" onClick={() => { this.setState({ estadoEditar: !this.state.estadoEditar }) }}><MDBIcon icon="times" size="2x" style={{ color: 'red', cursor: 'pointer' }} /></td>
                                                    </tr>
                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment>
                                                    {infoComunicados.map((e, i) => {
                                                        let view;
                                                        if (e.estado === 1) {
                                                            view = <MDBIcon onClick={() => { this.inactivar(e.idcomunicado) }} icon="eye" size="2x" style={{ cursor: 'pointer' }} />
                                                        } else {
                                                            view = <MDBIcon onClick={() => { this.activar(e.idcomunicado) }} icon="eye-slash" size="2x" style={{ cursor: 'pointer' }} />
                                                        }
                                                        return [
                                                            <React.Fragment key={i}>
                                                                <tr>
                                                                    <td><img width="180" src={`${URLImgFront}/assets${e.rutaimgcomunic}`} alt={e.rutaimgcomunic} /></td>
                                                                    <td>{e.codcomunicado === 1 ? 'Comunicados' : 'Formatos'}</td>
                                                                    <td>{e.titulocomunic}</td>
                                                                    <td>{e.desccomunic}</td>
                                                                    <td>{e.rutalinkpdf}</td>
                                                                    <td className="text-center" onClick={() => { this.editar(e.idcomunicado) }}><MDBIcon icon="edit" size="2x" style={{ color: 'green', cursor: 'pointer' }} /></td>
                                                                    <td className="text-center">{view}</td>
                                                                </tr>
                                                            </React.Fragment>
                                                        ]
                                                    })}
                                                </React.Fragment>
                                            )}
                                    </MDBTableBody>
                                </MDBTable>
                            </div>
                        </div>
                    </React.Fragment>
                )}

            </React.Fragment>
        );
    }
}

export default ConveniosDetalles;