import React, { Component } from 'react';
import Select from "react-select";

class MunicipiosLista extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOrigen: null,
        };
    }

    handleChangeOrigen = selectedOrigen => {
        this.props.MuniSelect(selectedOrigen);
    };

    render() {
        const { selectedOrigen } = this.props;
        const municipioLista = this.props.infoMunicipios.map((e) => ({
            label: e.mpiodpto,
            value: e.idmunicipio
        }))
        return (
            <React.Fragment>
                <Select
                    className="font-weight-normal"
                    placeholder="Selecciona..."
                    name="origen"
                    value={selectedOrigen}
                    onChange={this.handleChangeOrigen}
                    options={municipioLista}
                    noOptionsMessage={() => 'Sin resultados!'}
                />
            </React.Fragment>
        );
    }
}

export default MunicipiosLista;