import React, { Component } from 'react';
import 'swiper/css/swiper.min.css';
import { URLImgFront } from '../../config/URLDB';
import { MDBTable, MDBTableBody, MDBTableHead, MDBInput, MDBTooltip } from 'mdbreact';
import { ListRedAgencias, ListProductos, ListProdPagar, ProcesarPago, ProcesarInicioPago } from '../../services/WebAPI_Utrahuilca';
import ReCAPTCHA from "react-google-recaptcha";
import Select from "react-select";
import Swal from 'sweetalert2';
import Loading from '../Loading';

const validate = values => {
    const errors = {}
    if (values.t_product === 'DEFAULT') {
        errors.t_product = 'campo obligatorio'
    }

    if (values.t_product !== 'DEFAULT') {
        if (values.t_product !== '3') {
            if (values.selectedOrigen === 'DEFAULT' || values.selectedOrigen === null) {
                errors.selectedOrigen = 'campo obligatorio'
            }

            if (values.selectedProducto === 'DEFAULT') {
                errors.selectedProducto = 'campo obligatorio'
            }
        }
    }

    if (!values.cuenta) {
        errors.cuenta = 'campo obligatorio'
    }
    return errors;
};

const validateForm = values => {
    const errors = {}

    if (values.tipodocum === 'DEFAULT' || values.tipodocum === null) {
        errors.tipodocum = 'Campo Obligatorio'
    }

    if (!values.nrodocumento || values.nrodocumento === null || values.nrodocumento === '') {
        errors.nrodocumento = 'Campo Obligatorio'
    }

    if (!values.nombres) {
        errors.nombres = 'Campo Obligatorio'
    }

    if (!values.apellidos) {
        errors.apellidos = 'Campo Obligatorio'
    }

    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    if (!pattern.test(values.email)) {
        errors.email = "Introduce una dirección de correo electrónico válida.";
    }

    if (!values.email) {
        errors.email = 'Campo Obligatorio'
    }

    if (!values.nrocelular) {
        errors.nrocelular = 'Campo Obligatorio'
    }

    if (!values.descripcion) {
        errors.descripcion = 'Campo Obligatorio'
    }

    return errors;
}

const formatterPeso = new Intl.NumberFormat('es-CO', {
    currency: 'COP',
    minimumFractionDigits: 2
});

const formatterPesoValidar = new Intl.NumberFormat('es-CO', {
    currency: 'COP',
    minimumFractionDigits: 0
});

class Seccion2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            statePSE: false,
            stateBtn: true,
            t_product: 'DEFAULT',
            valor: '',
            stateInput: 0,
            statePago: false,
            infoAgencias: [],
            selectedOrigen: null,
            infoProductos: [],
            selectedProducto: 'DEFAULT',
            cuenta: '',
            valorPagar: null,
            tipodocum: 'DEFAULT',

            stateLoadign: false
        };
        this.recaptchaRef = React.createRef();
    }

    validRecat = () => {
        if (this.recaptchaRef.current.getValue()) {
            this.setState({
                stateBtn: false
            })
        } else {
            this.setState({
                stateBtn: true
            })
        }
    }

    textInput = (e) => {
        if (e.target.name === "cuenta") {
            let regex = new RegExp("^[0-9]+$");
            if (regex.test(e.target.value) || e.target.value === "") {
                this.setState({
                    [e.target.name]: e.target.value
                });
            }
        } else if (e.target.name === "t_product") {
            this.setState({
                cuenta: '',
                [e.target.name]: e.target.value
            });

            this.setState({ selectedOrigen: null, selectedProducto: 'DEFAULT', stateBtn: true })

            if (e.target.value !== 'DEFAULT') {
                if (e.target.value !== '3') {
                    this.cargarProductos(e.target.value);
                }
            }
        } else {
            this.setState({
                errors: {},
                [e.target.name]: e.target.value,
            })
        }
    }

    componentDidMount() {
        this.cargarAgencias();
    }

    cargarAgencias = () => {
        const data = {
            prmto: 6,
            redAgencia: 0,
            tipoAgencia: 0,
            municipio: 0,
            estado: 0,
            usuario: '0'
        }
        ListRedAgencias(data).then(response => {
            try {
                if (response.data.length > 0) {
                    this.setState({
                        infoAgencias: response.data
                    })
                } else { }
            } catch (error) {
                console.log(error);
            }
        });
    }

    cargarProductos = (valor) => {        
        const data = { prmto: 1, usuario: '0', tipoProd: parseInt(valor) };
        ListProductos(data).then(response => {
            try {
                if (response.data.length <= 0) {
                    Swal.fire({
                        icon: 'warning',
                        text: 'Sin resultados de productos'
                    })
                }
                else {
                    if (response.data[0].message) {
                        Swal.fire({
                            icon: 'warning',
                            text: 'Se presentó un problema al traer productos'
                        })
                    }
                    else {
                        this.setState({ infoProductos: response.data })
                    }
                }
            } catch (error) {
                console.log(error);
            }
        });
    }

    handleChangeOrigen = selectedOrigen => {
        this.setState({
            selectedOrigen: selectedOrigen
        })
    };

    formatearMiles = (e) => {
        var num = e.target.value.replace(/\./g, '');
        if (e.target.value === "") {
            this.setState({
                stateDollar: false
            })
        } else {
            this.setState({
                stateDollar: true
            })
        }
        if (!isNaN(num)) {
            num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.');
            num = num.split('').reverse().join('').replace(/^[.]/, '');
            this.setState({
                valor: num
            })
        }
        else {
            this.setState({
                valor: e.target.value.replace(/[^\d.]*/g, '')
            })
        }
    }

    sendForm = () => {
        const { errors, ...sinErrors } = this.state;
        const result = validate(sinErrors);
        this.setState({ errors: result });

        if (!Object.keys(result).length) {
            // formulario validado
            this.setState({
                stateLoadign: true
            })
            const data = {
                tipoProd: parseInt(this.state.t_product),
                codage: this.state.selectedOrigen === null ? 0 : parseInt(this.state.selectedOrigen.value),
                prolinea: this.state.selectedProducto === 'DEFAULT' ? 0 : parseInt(this.state.selectedProducto),
                codcue: parseInt(this.state.cuenta)
            }

            ListProdPagar(data).then(response => {
                try {
                    this.setState({
                        stateLoadign: false
                    })
                    if (response.data.length <= 0) {
                        Swal.fire({
                            icon: 'warning',
                            text: 'Sin resultados en la petición'
                        })

                        this.setState({ t_product: 'DEFAULT', selectedOrigen: null, selectedProducto: 'DEFAULT', cuenta: '', stateBtn: true })
                    } else {
                        if (response.data[0].respuesta) {
                            Swal.fire({
                                icon: 'warning',
                                text: response.data[0].respuesta
                            })

                            this.setState({ t_product: 'DEFAULT', selectedOrigen: null, selectedProducto: 'DEFAULT', cuenta: '', stateBtn: true })
                        }
                        else {
                            this.setState({
                                infoPago: response.data,
                                valorPagar: formatterPeso.format(response.data[0].couta_sugerida),
                                valorMostrar: formatterPeso.format(response.data[0].couta_sugerida),
                                valorPagarVal: formatterPesoValidar.format(response.data[0].couta_sugerida),
                                statePSE: true
                            })
                        }
                    }
                } catch (error) {
                    console.log(error);
                }
            });
        }
    }

    pagarPSEValidar = () => {
        if (this.state.stateInput !== 0) {
            if ((this.state.valor.length > 0) || (this.state.valorPagar.length > 0)) {
                var valorCargar;
                if (this.state.stateInput === 1) {
                    valorCargar = this.state.valor;
                }
                else {
                    valorCargar = this.state.valorPagar;
                }

                const data = {
                    tipoProd: parseInt(this.state.t_product),
                    codage: parseInt(this.state.infoPago[0].codage),
                    prolinea: parseInt(this.state.infoPago[0].prolinea),
                    codcue: parseInt(this.state.infoPago[0].codcue),
                    valorPago: parseFloat(valorCargar.replace(/\./g, ''))
                };
                ProcesarPago(data).then(response => {
                    try {
                        if (response.data.length <= 0) {
                            Swal.fire({
                                icon: 'warning',
                                text: 'Oops, se presentó un problema al procesar la solicitud. Intente de nuevo!'
                            })
                        }
                        else {
                            if (response.data[0].respuesta) {
                                Swal.fire({
                                    icon: 'warning',
                                    text: response.data[0].respuesta
                                })
                            }
                            else {
                                this.setState({
                                    statePago: true,
                                    valorMostrar: valorCargar
                                })
                            }
                        }
                    } catch (error) {
                        console.log(error);
                    }
                });
            } else {
                Swal.fire({ icon: 'warning', text: 'Por favor ingrese un valor!' })
            }
        }
        else {
            Swal.fire({ icon: 'warning', text: 'Por favor seleccione un valor!' })
        }
    }

    procInicioPago = () => {
        const { errors, ...sinErrors } = this.state;
        const result = validateForm(sinErrors);
        this.setState({ errors: result });

        if (!Object.keys(result).length) {
            this.setState({ stateLoadign: true })

            const data = {
                tipoProd: parseInt(this.state.t_product),
                codage: parseInt(this.state.infoPago[0].codage),
                prolinea: parseInt(this.state.infoPago[0].prolinea),
                codcue: parseInt(this.state.infoPago[0].codcue),
                valorPago: parseFloat(this.state.valorMostrar.replace(/\./g, '')),
                tipodocum: this.state.tipodocum,
                nrodocumento: this.state.nrodocumento,
                nombres: this.state.nombres,
                apellidos: this.state.apellidos,
                email: this.state.email,
                nrocelular: this.state.nrocelular,
                descripcion: this.state.descripcion
            };

            ProcesarInicioPago(data).then(response => {
                try {
                    this.setState({ stateLoadign: false });

                    if (response.data.length <= 0) {
                        Swal.fire({
                            icon: 'warning',
                            text: 'No se logró iniciar el pago'
                        });
                    } else {
                        if (response.data[0].respuesta) {
                            Swal.fire({
                                icon: 'warning',
                                text: response.data[0].respuesta
                            });
                        }
                        else {
                            if (response.data[0].correcto) {
                                window.open(response.data[0].correcto, '_blank');
                            }
                            else {
                                Swal.fire({
                                    icon: 'warning',
                                    text: 'Ocurrió un problema al inciar pago'
                                });
                            }
                        }
                    }
                } catch (e) {
                    console.log(e)
                }
            });
        }
    };

    render() {
        const agenciasLista = this.state.infoAgencias.map((e) => ({
            label: e.nombreagenc,
            value: e.codageutra
        }))
        const { errors } = this.state;

        return (
            <React.Fragment>
                <div className="px-0 pb-5 pt-3 my-0" style={{ width: '100%', background: '#f7f7f7' }}>
                    <div className="container-fluid my-0 px-0 py-0 text-center">
                        <div className="d-flex pb-3 justify-content-center align-items-center">
                            <img style={{ width: '130px', height: '130px' }} className="img-fluid" src={`${URLImgFront}/assets/img-variadas/ICONO PSE.png`} alt="ICONO PSE" />
                            <p className="texto-contenido">A través de la plataforma de pagos PSE, usted podrá mantener sus obligaciones y compromisos al día, desde cualquier lugar y en cualquier momento.</p>
                        </div>
                        <div className="container-fluid d-flex justify-content-center">
                            {this.state.statePago ? (
                                <React.Fragment>
                                    <div className="container mb-5">
                                        <h5>Diligencie los siguientes campos</h5>
                                        <hr />
                                        <br />
                                        <div className="row">
                                            <div className="col-md-12 text-left pb-3">
                                                <p className="font-weight-bold">Valor a pagar <b className="text-danger">$</b> {this.state.valorMostrar}</p>
                                            </div>

                                            <div className="col-md-12 text-left pb-3">
                                                <p className="font-weight-bold">Producto {`${this.state.infoPago[0].codage} - ${this.state.infoPago[0].prolinea} - ${this.state.infoPago[0].nombprod}`}</p>
                                            </div>

                                            <div className="col-md-6 text-left pt-2">
                                                <label className="font-weight-bold">Tipo de documento <b className="text-danger">*</b></label>
                                                <select name="tipodocum" className="form-control mt-2 bg-transparent" value={this.state.tipodocum} onChange={this.textInput}>
                                                    <option value={'DEFAULT'} disabled>Seleccione...</option>
                                                    <option value="C">Cédula de Ciudadania</option>
                                                    <option value="E">Cédula de Extranjería</option>
                                                    <option value="N">NIT</option>
                                                    <option value="P">Pasaporte</option>
                                                </select>
                                                {errors.tipodocum && <strong className="text-danger text-left" style={{ fontSize: '13px', position: 'absolute', left: '20px' }}>{errors.tipodocum}</strong>}
                                            </div>

                                            <div className="col-md-6 text-left pt-2">
                                                <label className="font-weight-bold">Numero de identificacion <b className="text-danger">*</b></label>
                                                <MDBInput name="nrodocumento" label="Ingrese numero de documento" type="text" maxLength="20" value={this.state.nrodocumento} className="pt-3" onChange={this.textInput} />
                                                {errors.nrodocumento && <strong className="text-danger text-left" style={{ fontSize: '13px', left: '20px', paddingBottom: '-10px' }}>{errors.nrodocumento}</strong>}
                                            </div>

                                            <div className="col-md-6 text-left pt-2">
                                                <label className="font-weight-bold">Nombres <b className="text-danger">*</b></label>
                                                <MDBInput name="nombres" label="Ingrese nombres" type="text" maxLength="150" value={this.state.nombres} className="pt-3" onChange={this.textInput} />
                                                {errors.nombres && <strong className="text-danger text-left" style={{ fontSize: '13px', left: '20px', paddingBottom: '-10px' }}>{errors.nombres}</strong>}
                                            </div>

                                            <div className="col-md-6 text-left pt-2">
                                                <label className="font-weight-bold">Apellidos <b className="text-danger">*</b></label>
                                                <MDBInput name="apellidos" label="Ingrese apellidos" type="text" maxLength="150" value={this.state.apellidos} className="pt-3" onChange={this.textInput} />
                                                {errors.apellidos && <strong className="text-danger text-left" style={{ fontSize: '13px', left: '20px', paddingBottom: '-10px' }}>{errors.apellidos}</strong>}
                                            </div>

                                            <div className="col-md-6 text-left pt-2">
                                                <label className="font-weight-bold">Email <b className="text-danger">*</b></label>
                                                <MDBInput name="email" label="Ingrese correo electrónico" type="email" maxLength="150" value={this.state.email} className="pt-3" onChange={this.textInput} />
                                                {errors.email && <strong className="text-danger text-left" style={{ fontSize: '13px', left: '20px', paddingBottom: '-10px' }}>{errors.email}</strong>}
                                            </div>

                                            <div className="col-md-6 text-left pt-2">
                                                <label className="font-weight-bold">Celular <b className="text-danger">*</b></label>
                                                <MDBInput name="nrocelular" label="Ingrese su celular" type="number" maxLength="20" value={this.state.nrocelular} className="pt-3" onChange={this.textInput} />
                                                {errors.nrocelular && <strong className="text-danger text-left" style={{ fontSize: '13px', left: '20px', paddingBottom: '-10px' }}>{errors.nrocelular}</strong>}
                                            </div>

                                            <div className="col-md-12 text-left">
                                                <label className="font-weight-bold">Descripción de Pago</label>
                                                <MDBInput name="descripcion" label="Ingrese descripción del pago" type="textarea" maxLength="70" rows="7" value={this.state.descripcion} className="pt-3" onChange={this.textInput} />
                                                {errors.descripcion && <strong className="text-danger text-left" style={{ fontSize: '13px', position: 'absolute', left: '20px' }}>{errors.descripcion}</strong>}
                                            </div>
                                        </div>
                                        <div className="pt-4">
                                            <span onClick={() => { this.setState({ statePago: false, valor: '' }) }} className="px-5 py-3 text-white mt-5" style={{ background: 'gray', borderRadius: '30px', cursor: 'pointer' }}>Regresar</span>
                                            &nbsp;<span onClick={() => { this.procInicioPago() }} className="px-5 py-3 text-white mt-5" style={{ background: '#FA7500', borderRadius: '30px', cursor: 'pointer' }}>Pagar</span>
                                        </div>
                                    </div>
                                </React.Fragment>
                            ) : (<React.Fragment>
                                {this.state.statePSE ? (
                                    <React.Fragment>
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-12">
                                                    <h5>Por favor verifique su pago</h5>
                                                    <hr />
                                                </div>

                                                <div className="col-12">
                                                    <div className="table-responsive">
                                                        <MDBTable className="table table-striped" style={{ width: '100%' }}>
                                                            <MDBTableHead textWhite style={{ background: '#FA7500' }}>
                                                                <tr>
                                                                    <th scope="col">Fecha de Pago</th>
                                                                    <th scope="col">Nombre/Razon Social</th>
                                                                    <th scope="col">Producto</th>
                                                                    <th scope="col">Nro Cuenta</th>
                                                                    <th scope="col">Valor Sugerido</th>
                                                                    <th scope="col">Pagar Otro Valor</th>
                                                                    <th scope="col">Pagar</th>
                                                                </tr>
                                                            </MDBTableHead>

                                                            <MDBTableBody>
                                                                {this.state.infoPago.map((e, i) => {
                                                                    var fecha = new Date(e.fecpago);
                                                                    return [
                                                                        <React.Fragment key={i}>
                                                                            <tr>
                                                                                <td><p className="text-center">{fecha.getDate() + "/" + (fecha.getMonth() + 1) + "/" + fecha.getFullYear()}</p></td>
                                                                                <td><p className="text-center">{e.nomcom}</p></td>
                                                                                <td><p className="text-left">{e.nombprod}</p></td>
                                                                                <td><p className="text-right">{e.codcue}</p></td>
                                                                                <td><div className="align-items-center"><input type="radio" name="validValor" onClick={() => { this.setState({ stateInput: 2 }) }} value={true} />&nbsp;<span className="font-weight-bold"><b style={{ color: '#FA7500' }}>$</b> {formatterPeso.format(e.couta_sugerida)}</span></div></td>
                                                                                <td><div className="align-items-center"><input type="radio" name="validValor" onClick={() => { this.setState({ stateInput: 1 }) }} value={true} />&nbsp;<input disabled={(!this.state.stateInput || this.state.stateInput === 1) ? false : true} type="text" name="valor" className="form-control-sm" placeholder="Ingrese monto" onKeyUp={this.formatearMiles} onChange={this.formatearMiles} value={this.state.valor} /></div></td>
                                                                                <td className="py-2 my-0">
                                                                                    <MDBTooltip
                                                                                        domElement
                                                                                        tag="span"
                                                                                        placement="top">
                                                                                        <img onClick={() => { this.pagarPSEValidar() }} style={{ cursor: 'pointer' }} width="50" className="img-fluid" src={`${URLImgFront}/assets/img-variadas/logo_pse.png`} alt="logo_pse" />
                                                                                        <div>Pagar con PSE</div>
                                                                                    </MDBTooltip>
                                                                                </td>
                                                                            </tr>
                                                                        </React.Fragment>
                                                                    ]
                                                                })}
                                                            </MDBTableBody>
                                                        </MDBTable>
                                                    </div>
                                                </div>
                                                
                                                <div className="col-12 mt-5">
                                                    <span onClick={() => { this.setState({ statePSE: false, stateInput: 0, selectedOrigen: null, selectedProducto: 'DEFAULT', stateBtn: true, cuenta: '', t_product: 'DEFAULT', infoPago: null, valorPagar: 0, valor: '', valorMostrar: 0, valorPagarVal: 0, validValor: 0 }) }} className="px-5 py-3 text-white mt-5" style={{ background: 'gray', borderRadius: '30px', cursor: 'pointer' }}>Regresar</span>
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                ) : (<React.Fragment>
                                    <div className="container">
                                        <div className="card">
                                            <div className="row px-4">
                                                <div className="col-md-12 pt-4 pb-3">
                                                    <p className="text-left" style={{ fontWeight: 'bold', color: '#FA7500' }}>TIPO DE PRODUCTO</p>
                                                    <select name="t_product" className="form-control" value={this.state.t_product} onChange={this.textInput}>
                                                        <option value="DEFAULT" disabled>Seleccione...</option>
                                                        <option value="1">Aporte</option>
                                                        <option value="2">Ahorro</option>
                                                        <option value="3">Crédito</option>
                                                    </select>
                                                    {errors.t_product && <strong className="text-danger text-left" style={{ fontSize: '13px', position: 'absolute', left: '20px' }}>{errors.t_product}</strong>}
                                                </div>
                                                {(this.state.t_product === "1" || this.state.t_product === "2") ? (
                                                    <React.Fragment>
                                                        <div className="col-md-12 pt-2">
                                                            <p className="text-left" style={{ fontWeight: 'bold', color: '#FA7500' }}>SELECCIONE EL PRODUCTO</p>
                                                            <div className="row pb-5">
                                                                <div className="col-md-4 text-left">
                                                                    <label>Agencia <b className="text-danger">*</b></label>
                                                                    <Select
                                                                        className="font-weight-normal"
                                                                        placeholder="Seleccione..."
                                                                        name="selectedOrigen"
                                                                        value={this.state.selectedOrigen}
                                                                        onChange={this.handleChangeOrigen}
                                                                        options={agenciasLista}
                                                                        noOptionsMessage={() => 'Sin resultados!'}
                                                                    />
                                                                    {errors.selectedOrigen && <strong className="text-danger text-left" style={{ fontSize: '13px', position: 'absolute', left: '20px' }}>{errors.selectedOrigen}</strong>}
                                                                </div>

                                                                <div className="col-md-4 text-left">
                                                                    <label>Producto <b className="text-danger">*</b></label>
                                                                    <select name="selectedProducto" value={this.state.selectedProducto} className="form-control" onChange={this.textInput}>
                                                                        <option value="DEFAULT" disabled>Seleccione...</option>
                                                                        {this.state.infoProductos.map((e, i) => {
                                                                            return [
                                                                                <option key={i} value={e.codpro}>{e.desprod}</option>
                                                                            ]
                                                                        })}
                                                                    </select>
                                                                    {errors.selectedProducto && <strong className="text-danger text-left" style={{ fontSize: '13px', position: 'absolute', left: '20px' }}>{errors.selectedProducto}</strong>}
                                                                </div>
                                                                <div className="col-md-4 text-left">
                                                                    <label>Digite Cuenta <b className="text-danger">*</b></label>
                                                                    <input name="cuenta" maxLength="8" type="text" placeholder="max. 8 digitos" className="form-control" value={this.state.cuenta} onChange={this.textInput} />
                                                                    {errors.cuenta && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '70px', left: '17px' }}>{errors.cuenta}</strong>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                ) : (<React.Fragment></React.Fragment>)}
                                                {this.state.t_product === "3" ? (
                                                    <React.Fragment>
                                                        <div className="col-md-12 pt-2 pb-4">
                                                            <p className="text-left" style={{ fontWeight: 'bold', color: '#FA7500' }}>NÚMERO DE PRODUCTO</p>
                                                            <input name="cuenta" maxLength="8" type="text" placeholder="max. 8 digitos" className="form-control" value={this.state.cuenta} onChange={this.textInput} />
                                                            {errors.cuenta && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '100px', left: '17px' }}>{errors.cuenta}</strong>}
                                                        </div>
                                                    </React.Fragment>
                                                ) : (<React.Fragment></React.Fragment>)}
                                                <div className="col-md-12">
                                                    <div className="d-flex justify-content-center">
                                                        <form onSubmit={this.onSubmit} >
                                                            <ReCAPTCHA
                                                                ref={this.recaptchaRef}
                                                                sitekey="6LfTTPgbAAAAAOKQp-b36jm-sJGpLIKw8cnZTzu-"
                                                                onChange={this.validRecat}
                                                            />
                                                        </form>
                                                    </div>
                                                </div>
                                                        
                                                <div className="col-md-12 pt-4 pb-5  mt-3 d-flex align-items-center justify-content-center">
                                                    <div>
                                                        <button onClick={this.sendForm} disabled={this.state.stateBtn} className="btn px-5" style={{ background: '#FA7500', color: 'white', borderRadius: '20px' }}>Consultar</button>
                                                    </div>
                                                </div>

                                                <div className="col-12 text-right mb-2"><a href={`${URLImgFront}/assets/Archivos/ManualDeUsoPSE.pdf`} style={{ textDecoration: 'underline !important'}} download="ManualUso.pdf" target={'_blank'} rel="noopener noreferrer">Manual de uso</a></div>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>)}
                            </React.Fragment>)}
                        </div>
                    </div>
                    {this.state.stateLoadign ? (
                        <Loading />
                    ) : (<React.Fragment></React.Fragment>)}
                </div>
            </React.Fragment >
        );
    }
}

export default Seccion2;