import { MDBIcon, MDBInput, MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import React, { Component } from 'react';
import { CVistaPrincipalVideos_, GrVistaPrincipalVideos_ } from '../../../services/WebAPI_Utrahuilca';
import Swal from 'sweetalert2';

const validate = values => {
    const errors = {}
    if (!values.ctitulovideo) {
        errors.ctitulovideo = 'campo obligatorio'
    }
    if (!values.cenlacevideo) {
        errors.cenlacevideo = 'campo obligatorio'
    }
    return errors;
};

class VideosNuestAsociados extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            estadoNuevo: false,
            idprincvid: 0,
            ctitulovideo: '',
            cenlacevideo: '',
            etitulovideo: '',
            eenlacevideo: '',
            editar: false,
            resultados: false,
            datosPrincVideos: []
        };
    }

    componentDidMount() {
        this.cargarInformacion();
    }

    cargarInformacion = () => {
        const data = {
            tran: 4,
            idprincvid: 0,
            titulovid: 'N/A',
            enlacevid: 'N/A',
            estadovid: 1,
            usuario: 0
        }
        CVistaPrincipalVideos_(data).then(response => {
            try {
                if (response.data.length > 0) {
                    if (response.data[0].message) {
                        this.setState({ editar : false, resultados : false, datosPrincVideos : [] })
                    } else {
                        this.setState({ editar : false, resultados : true, datosPrincVideos : response.data })
                    }
                }
            } catch (error) {
                console.log();
            }
        });
    }

    GrabarDatos = () => {
        const { errors, ...sinErrors } = this.state;
        const result = validate(sinErrors);
        this.setState({ errors: result });
        if (!Object.keys(result).length) {
            // formulario validado
            const data = {
                idprincvideo: 0,
                titulovideo: this.state.ctitulovideo,
                enlacevideo: this.state.cenlacevideo,
                estado: 1,
                idusucrea: sessionStorage.getItem('idusuario'),
            }
            GrVistaPrincipalVideos_(data).then(response => {
                if (response.data.length > 0) {
                    if (response.data[0].message === "000000") {
                        Swal.fire({
                            icon: 'success',
                            title: 'Se agrego correctamente.'
                        })
                        this.setState({
                            estadoNuevo: false,
                            ctitulovideo: '',
                            cenlacevideo: ''
                        })
                        this.cargarInformacion();
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error al insertar.'
                        })
                    }
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Ocurrio un error!'
                    })
                }
            });
        }
    }

    ActualizarDatos = (id) => {
        // formulario validado
        const data = {
            idprincvideo: id,
            titulovideo: this.state.etitulovideo,
            enlacevideo: this.state.eenlacevideo,
            estado: 1,
            idusucrea: sessionStorage.getItem('idusuario'),
        }
        GrVistaPrincipalVideos_(data).then(response => {
            if (response.data.length > 0) {
                if (response.data[0].message === "000000") {
                    Swal.fire({
                        icon: 'success',
                        title: 'Se actualizo correctamente.'
                    })
                    this.setState({
                        estadoNuevo: false,
                        ctitulovideo: '',
                        cenlacevideo: ''
                    })
                    this.cargarInformacion();
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error al actualizar.'
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Ocurrio un error!'
                })
            }
        });
    }

    EditarReg = (id) => {
        var seleccionado = [];
        this.state.datosPrincVideos.map((e, i) => {
            if (e.idprincvideo === id) {
                seleccionado.push(e)
            }
            return [];
        })
        this.setState({
            idprincvid: seleccionado[0].idprincvideo,
            etitulovideo: seleccionado[0].titulovideo,
            eenlacevideo: seleccionado[0].enlacevideo,
            editar: true
        })
    }

    ActivarReg = (id) => {
        const data = {
            tran: 2,
            idprincvid: id,
            titulovid: 'N/A',
            enlacevid: 'N/A',
            estadovid: 1,
            usuario: sessionStorage.getItem('idusuario')
        }
        CVistaPrincipalVideos_(data).then(response => {
            if (response.data.length > 0) {
                if (response.data[0].message === "000000") {
                    Swal.fire({
                        icon: 'success',
                        title: 'Se activo satisfactoriamente'
                    });
                }
                else {
                    Swal.fire({
                        icon: 'warning',
                        title: 'No se puedo inactivar el registro'
                    });
                }
            }
            else {
                Swal.fire({
                    icon: 'warning',
                    title: 'Ocurrió un problema al evaluar la solicitud'
                });
            }
            this.cargarInformacion();
        });
    }

    InactivarReg = (id) => {
        const data = {
            tran: 2,
            idprincvid: id,
            titulovid: 'N/A',
            enlacevid: 'N/A',
            estadovid: 0,
            usuario: sessionStorage.getItem('idusuario')
        }
        CVistaPrincipalVideos_(data).then(response => {
            if (response) {
                this.setState({
                    estadoS: false
                })
                Swal.fire({
                    icon: 'success',
                    title: 'Se inactivo satisfactoriamente'
                });
                this.cargarInformacion();
            }
        });
    }

    textInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleNew = () => {
        this.setState({
            estadoNuevo: !this.state.estadoNuevo,
            errors: {},
        })
        if (this.state.estadoNuevo) {
            this.setState({
                errors: {},
            })
            this.cargarInformacion();
        }
    }

    render() {
        const { estadoNuevo, ctitulovideo, cenlacevideo, editar, resultados, datosPrincVideos, idprincvid, errors } = this.state;

        return (
            <React.Fragment>
                <p className="font-weight-bold">Nuestros Asociados (Enlaces Videos)</p>
                <hr />
                <div className="plus_add_agencia align-items-center d-flex" onClick={() => { this.handleNew() }}>
                    {estadoNuevo ? <p>Cancelar &nbsp;<MDBIcon icon="times" size="lg" /></p> : <p>Crear Enlace Video &nbsp;<MDBIcon icon="plus-circle" size="lg" /></p>}
                </div>

                {estadoNuevo ?
                    (
                        <React.Fragment>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6 text-center">
                                        <MDBInput label="Titulo Video" name="ctitulovideo" type="textarea" value={ctitulovideo} onChange={this.textInput} />
                                        {errors.ctitulovideo && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '50px' }}>{errors.ctitulovideo}</strong>}
                                    </div>

                                    <div className="col-md-6 text-center">
                                        <MDBInput label="Enlace" name="cenlacevideo" type="text" value={cenlacevideo} onChange={this.textInput} />
                                        {errors.cenlacevideo && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '50px' }}>{errors.cenlacevideo}</strong>}
                                    </div>
                                </div>
                            </div>
                            <div className="pt-2 mt-5 modal-footer w-100">
                                <button type="button" className="btn btn-primary px-5" onClick={this.GrabarDatos}>Crear Registro Video &nbsp; <MDBIcon icon="save" /></button>
                            </div>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <div className="container">
                                <div className="table-responsive text-center w-100">
                                    <MDBTable>
                                        <MDBTableHead>
                                            <tr className="font-weight-bold">
                                                <td>Título</td>
                                                <td>Enlace</td>
                                                {editar ?
                                                    (
                                                        <React.Fragment>
                                                            <td>Guardar</td>
                                                            <td>Cancelar</td>
                                                        </React.Fragment>
                                                    ) : (
                                                        <React.Fragment>
                                                            <td>Editar</td>
                                                            <td>Estado</td>
                                                        </React.Fragment>
                                                    )
                                                }
                                            </tr>
                                        </MDBTableHead>
                                        <MDBTableBody>
                                            {resultados ?
                                                (
                                                    <React.Fragment>
                                                        {editar ?
                                                            (
                                                                <React.Fragment>
                                                                    <tr>
                                                                        <td className="w-50"><textarea className="form-control" type="text" maxLength="150" rows="5" name="etitulovideo" value={this.state.etitulovideo} onChange={this.textInput} /></td>
                                                                        <td className="w-50"><input className="form-control" type="text" maxLength="100" name="eenlacevideo" value={this.state.eenlacevideo} onChange={this.textInput} /></td>
                                                                        <td className="text-center" onClick={() => { this.ActualizarDatos(idprincvid) }}><MDBIcon icon="save" size="2x" style={{ color: 'blue', cursor: 'pointer' }} /></td>
                                                                        <td className="text-center" onClick={() => { this.setState({ editar: !editar }) }}><MDBIcon icon="times" size="2x" style={{ color: 'red', cursor: 'pointer' }} /></td>
                                                                    </tr>
                                                                </React.Fragment>
                                                            ) : (
                                                                <React.Fragment>
                                                                    {datosPrincVideos.map((e, i) => {
                                                                        let view;
                                                                        if (e.estado === 1) {
                                                                            view = <MDBIcon onClick={() => { this.InactivarReg(e.idprincvideo) }} icon="eye" size="2x" style={{ cursor: 'pointer' }} />
                                                                        } else {
                                                                            view = <MDBIcon onClick={() => { this.ActivarReg(e.idprincvideo) }} icon="eye-slash" size="2x" style={{ cursor: 'pointer' }} />
                                                                        }

                                                                        return [
                                                                            <React.Fragment key={i}>
                                                                                <tr>
                                                                                    <td align="left">{e.titulovideo}</td>
                                                                                    <td>{e.enlacevideo}</td>
                                                                                    <td className="text-center" onClick={() => { this.EditarReg(e.idprincvideo) }}><MDBIcon icon="edit" size="2x" style={{ color: 'green', cursor: 'pointer' }} /></td>
                                                                                    <td className="text-center">{view}</td>
                                                                                </tr>
                                                                            </React.Fragment>
                                                                        ]
                                                                    })}
                                                                </React.Fragment>
                                                            )
                                                        }
                                                    </React.Fragment>
                                                ): (
                                                    <React.Fragment>
                                                        <tr className="alert alert-info text-center w-100">
                                                            <td colSpan="4">Sin resultados</td>
                                                        </tr>
                                                   </React.Fragment>
                                                )
                                            }
                                        </MDBTableBody>
                                    </MDBTable>
                                </div>
                            </div>
                        </React.Fragment>
                    )
                }
            </React.Fragment>
        )
    }
}

export default VideosNuestAsociados