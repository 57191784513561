import React, { useState, useEffect } from 'react';
import { URLImgFront } from '../../../config/URLDB';
import { SeccionPNaturalJuridica_ } from '../../../services/WebAPI_Utrahuilca';
import Iframes from '../../Iframes/Iframes';

const DetalleItemTipoPersona = ({ data }) => {
    const [coopvirtualdetalle, setCoopvirtualdetalle] = useState([]);

    useEffect(() => {
        const fetchDetalles = async () => {
            try {
                const datos = {
                    tran: 7,
                    idcoopvirtdet: 0,
                    rutaimgdeta: 'NA',
                    titcoopvirtdet: 'NA',
                    desccoopvirtdet: 'NA',
                    piecoopvirtdet: 'NA',
                    manejaenlace: 1,
                    rutaenlace: 'NA',
                    estado: 1,
                    usucrea: 'NA',
                    tipocargue: 0,
                    idmaestrocoopv: parseInt(data)
                }

                SeccionPNaturalJuridica_(datos).then(response => {
                    try {
                        if (response.data.length > 0) {
                            if (response.data.length >= 1) {
                                if (response.data[0].message === "004404") {
                                    setCoopvirtualdetalle([]);
                                }
                                else {
                                    setCoopvirtualdetalle(response.data);
                                }
                            }
                            else {
                                setCoopvirtualdetalle([]);
                            }
                        }
                        else {
                            setCoopvirtualdetalle([]);
                        }
                    } catch (error) {
                        console.log(error);
                    }
                });

            } catch (error) {
                console.error('Error fetching detail:', error);
            }
        };

        fetchDetalles();
    }, [data]);

    return (
        <div>
            {coopvirtualdetalle.length > 0 ? (
                coopvirtualdetalle.map((f, j) => {
                    return [
                        <React.Fragment key={j}>
                            <div className="container row py-2">
                                <div className="col-md-6">
                                    <React.Fragment>
                                        {f.tipocargue === 1 ? (
                                            <Iframes ancho={'100%'} alto={'335px'} url={f.rutaimgdeta} />
                                        ) : (
                                            <img className="img-fluid" style={{ width: '100%' }} src={`${URLImgFront}/assets${f.rutaimgdeta}`} alt={f.rutaimgdeta} />
                                        )}
                                    </React.Fragment>
                                </div>

                                <div className="col-md-6 d-flex">
                                    <div className="pl-5 pb-5 tarjetas_contenido">
                                        <p className="font-weight-bold pl-5" style={{ fontSize: '24px' }}>{f.titcoopvirtdet}</p>
                                        <p className="w-75 pl-5" style={{ fontSize: '22px' }}>{f.desccoopvirtdet}</p>
                                        <p className="font-weight-bold pl-5" style={{ fontSize: '15px' }}>{f.piecoopvirtdet} </p>
                                        {f.manejaenlace === 1 ? (
                                            <a href={`${f.rutaenlace}`} target={'_blank'} rel="noopener noreferrer">
                                                <span className="mt-5 font-weight-bold px-5 text-white" style={{ background: '#FA7500', fontSize: '17px', borderRadius: '20px', position: 'absolute', left: '30%', bottom: '30px' }}>{f.titcoopvirtdet}</span>
                                            </a>
                                        ) : (<React.Fragment></React.Fragment>)}
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    ]
                })
                ): (
                    <div></div>
                )
            }
        </div>
    );
};

export default DetalleItemTipoPersona;