import { MDBIcon, MDBInput, MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import React, { Component } from 'react';
import { Agencias_, CargueImagenes, Municipios_ } from '../../../services/WebAPI_Utrahuilca';
import FileUploader from './Agencias/FileUploader';
import FileUploaderA from './Agencias/FileUploaderA';
import Swal from 'sweetalert2';
import MunicipiosLista from './MunicipiosLista/MunicipiosLista';
import { URLImgFront } from '../../../config/URLDB';

const validate = values => {
    const errors = {}
    if (values.idmunicipio === 'DEFAULT') {
        errors.idmunicipio = 'campo obligatorio'
    }
    if (!values.nombreagenc) {
        errors.nombreagenc = 'campo obligatorio'
    }
    if (values.idtipoagencia === 'DEFAULT') {
        errors.idtipoagencia = 'campo obligatorio'
    } 
    if (!values.latitud) {
        errors.latitud = 'campo obligatorio'
    }
    if (!values.longitud) {
        errors.longitud = 'campo obligatorio'
    }
    if (!values.zoom) {
        errors.zoom = 'campo obligatorio'
    }
    return errors;
};

class Agencias extends Component {
    uploader = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            estadoNuevo: false,
            errors: {},
            selectedOrigen: null,
            municipalityId: null,
            infoMunicipios: [],
            infoAgencias: [],
            idtipoagencia: 'DEFAULT',
            idmunicipio: 'DEFAULT',
            estadoEditar: false,
            idredagencia: null,
            nombreagenc: '',
            rutaiconomapa: '',
            latitud: '',
            longitud: '',
            zoom: null,
            estadoCambioImg: true,
            estadoSinResultados: false,
            estadoCambio: false,
            errorUndefined: false,
        };
    }

    componentDidMount() {
        this.cargarMunicipios();
        this.cargarInformacion();
    }

    cargarInformacion = () => {
        const data = {
            tran: 11,
            idredagencia: 0,
            idtipoagencia: 0,
            idmunicipio: 0,
            nombreagenc: 'NA',
            descagencia: 'NA',
            rutaiconomapa: 'NA',
            latitud: 'NA',
            longitud: 'NA',
            zoom: 0,
            estado: 0,
            usucrea: 'NA'
        }
        Agencias_(data).then(response => {
            try {
                if (response.data.length > 0) {
                    if (response.data[0].message === "004404") {
                        this.setState({
                            estadoSinResultados: false,
                            errorUndefined: true

                        })
                    } else {
                        if (response.data.length >= 1) {
                            this.setState({
                                infoAgencias: response.data,
                                idredagencia: response.data[0].idredagencia,
                                estadoSinResultados: true,
                                errorUndefined: true
                            })
                        } else {
                            this.setState({
                                estadoSinResultados: false
                            })
                        }
                    }
                } else { }
            } catch (error) {
                this.setState({
                    infoMunicipios: [],
                    infoAgencias: [],
                })
                Swal.fire({
                    icon: 'error',
                    text: 'Ocurrio un error al consumir el servicio!'
                })
            }
        });
    }

    cargarMunicipios = () => {
        const data = {
            tran: 8,
            iddepartamento: 0,
            estado: 1,
        }
        Municipios_(data).then(response => {
            if (response) {
                this.setState({
                    infoMunicipios: response.data
                })
            } else { }
        });
    }

    textInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    cargarImagen = () => {
        var formData = new FormData();
        if (undefined === this.uploader.current) return;
        
        formData.append("file", this.uploader.current.getFileObject());
        formData.append("rutaImagen", '/Red de Agencias/Agencias/' + this.uploader.current.getFileName());
        CargueImagenes(formData).then(response => {
            if (response) {
                this.cargarInformacion();
            }
        });
    }

    insertarDatos = () => {
        const { errors, ...sinErrors } = this.state;
        const result = validate(sinErrors);
        this.setState({ errors: result });
        if (!Object.keys(result).length) {
            // formulario validado
            const data = {
                tran: 1,
                idredagencia: 0,
                idtipoagencia: parseInt(this.state.idtipoagencia),
                idmunicipio: this.state.idmunicipio,
                nombreagenc: this.state.nombreagenc,
                descagencia: 'NA',
                rutaiconomapa: '/Red de Agencias/Agencias/' + this.uploader.current.getFileName(),
                latitud: this.state.latitud,
                longitud: this.state.longitud,
                zoom: parseInt(this.state.zoom),
                estado: 1,
                usucrea: sessionStorage.getItem('idusuario')
            }
            Agencias_(data).then(response => {
                if (response.data.length > 0) {
                    if (response.data[0].message === "000000") {
                        this.cargarImagen();
                        Swal.fire({
                            icon: 'success',
                            title: 'Se agrego correctamente.'
                        })
                        this.setState({
                            estadoNuevo: false,
                            nombreagenc: '',
                            descagencia: '',
                            rutaiconomapa: '',
                            latitud: '',
                            longitud: '',
                            zoom: null,
                        })
                        this.cargarInformacion();
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error al insertar.'
                        })
                    }
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Ocurrio un error!'
                    })
                }
            });
        }
    }

    cambioImagen = (name, file) => {
        this.setState({
            estadoCambioImg: true
        });
    }

    cambio = (name, file) => {
        this.setState({
            estadoCambio: true
        });
    }

    editar = (id) => {
        var seleccionado = [];
        this.state.infoAgencias.map((e, i) => {
            if (e.idredagencia === id) {
                seleccionado.push(e)
            }
            return [];
        })
        this.setState({
            idredagencia: seleccionado[0].idredagencia,
            nombreagenc: seleccionado[0].nombreagenc,
            idtipoagencia: seleccionado[0].idtipoagencia,
            idmunicipio: seleccionado[0].idmunicipio,
            rutaiconomapa: seleccionado[0].rutaiconomapa,
            latitud: seleccionado[0].latitud,
            longitud: seleccionado[0].longitud,
            zoom: seleccionado[0].zoom,
            estadoEditar: true
        })
    }

    actualizarDatos = (id) => {
        var ruta = '';
        var capNombre = this.uploader.current.getFileName();
        if (capNombre === undefined || capNombre === null) {
            ruta = this.state.rutaiconomapa;
        } else {
            ruta = '/Red de Agencias/Agencias/' + this.uploader.current.getFileName();
        }
        const data = {
            tran: 1,
            idredagencia: this.state.idredagencia,
            idtipoagencia: parseInt(this.state.idtipoagencia),
            idmunicipio: this.state.idmunicipio,
            nombreagenc: this.state.nombreagenc,
            descagencia: 'NA',
            rutaiconomapa: ruta,
            latitud: this.state.latitud,
            longitud: this.state.longitud,
            zoom: parseInt(this.state.zoom),
            estado: 1,
            usucrea: sessionStorage.getItem('idusuario')
        }
        Agencias_(data).then(response => {
            if (response.data.length > 0) {
                if (response.data[0].message === "000000") {
                    this.cargarImagen();
                    setTimeout(() => {
                        this.setState({
                            estadoCambio: false,
                            estadoEditar: false
                        })
                    }, 50);
                    Swal.fire({
                        icon: 'success',
                        title: 'Se actualizo satisfactoriamente'
                    })
                    this.cargarInformacion();
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Ocurrio un error al actualizar'
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Ocurrio un error!'
                })
            }
        });
    }

    MuniSelect = (selectedOrigen) => {
        this.setState({
            idmunicipio: selectedOrigen.value
        })
    }

    activar = (id) => {
        const data = {
            tran: 6,
            idredagencia: id,
            idtipoagencia: 0,
            idmunicipio: 0,
            nombreagenc: 'NA',
            descagencia: 'NA',
            rutaiconomapa: 'NA',
            latitud: 'NA',
            longitud: 'NA',
            zoom: 0,
            estado: 1,
            usucrea: sessionStorage.getItem('idusuario')
        }
        Agencias_(data).then(response => {
            if (response) {
                this.setState({
                    estadoS: false
                })
                Swal.fire({
                    icon: 'success',
                    title: 'Se activo satisfactoriamente'
                });
                this.cargarInformacion();
            }
        });
    }

    inactivar = (id) => {
        const data = {
            tran: 6,
            idredagencia: id,
            idtipoagencia: 0,
            idmunicipio: 0,
            nombreagenc: 'NA',
            descagencia: 'NA',
            rutaiconomapa: 'NA',
            latitud: 'NA',
            longitud: 'NA',
            zoom: 0,
            estado: 0,
            usucrea: sessionStorage.getItem('idusuario')
        }
        Agencias_(data).then(response => {
            if (response) {
                this.setState({
                    estadoS: false
                })
                Swal.fire({
                    icon: 'success',
                    title: 'Se inactivo satisfactoriamente'
                });
                this.cargarInformacion();
            }
        });
    }

    handleNew = () => {
        this.setState({
            estadoNuevo: !this.state.estadoNuevo,
            errors: {},
        })
        if (this.state.estadoNuevo) {
            this.setState({
                errors: {},
            })
            this.cargarInformacion();
        }
    }

    render() {
        const { estadoNuevo, errorUndefined, infoMunicipios, estadoCambioImg, idredagencia, estadoSinResultados, infoAgencias, estadoEditar, estadoCambio, errors } = this.state;
        return (
            <React.Fragment>
                {errorUndefined ? (
                    <React.Fragment>
                        <p className="font-weight-bold">Agencias</p>
                        <hr />
                        <div className="plus_add_agencia align-items-center d-flex" onClick={() => { this.handleNew() }}>
                            {estadoNuevo ? <p>Cancelar &nbsp;<MDBIcon icon="times" size="lg" /></p> : <p>Crear Nueva Agencia &nbsp;<MDBIcon icon="plus-circle" size="lg" /></p>}
                        </div>
                        {estadoNuevo ? (
                            <React.Fragment>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-6 my-2 text-center">
                                            <FileUploaderA ref={this.uploader} estadoImg={estadoCambioImg} FCambiar={this.cambioImagen} />
                                        </div>
                                        <div className="col-md-6 my-2">
                                            <MDBInput name="nombreagenc" label="Nombre de la Agencia" type="text" onChange={this.textInput} />
                                            {errors.nombreagenc && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '50px' }}>{errors.nombreagenc}</strong>}
                                        </div>
                                        <div className="col-md-6 my-2">
                                            <label>Ciudad / Municipio</label>
                                            <MunicipiosLista infoMunicipios={infoMunicipios} MuniSelect={this.MuniSelect} />
                                            {errors.idmunicipio && <strong className="text-danger" style={{ fontSize: '13px' }}>{errors.idmunicipio}</strong>}
                                        </div>
                                        <div className="col-md-6 my-2">
                                            <label>Tipo de Agencia</label>
                                            <select name="idtipoagencia" className="form-control" defaultValue={'DEFAULT'} onChange={this.textInput}>
                                                <option value={'DEFAULT'} disabled>Selecciona...</option>
                                                <option value="1">Principal</option>
                                                <option value="2">Mini Basica</option>
                                            </select>
                                            {errors.idtipoagencia && <strong className="text-danger" style={{ fontSize: '13px' }}>{errors.idtipoagencia}</strong>}
                                        </div>
                                        <div className="col-md-4 my-2">
                                            <MDBInput name="latitud" label="Latitud" type="text" onChange={this.textInput} />
                                            {errors.latitud && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '50px' }}>{errors.latitud}</strong>}
                                        </div>
                                        <div className="col-md-4 my-2">
                                            <MDBInput name="longitud" label="Longitud" type="text" onChange={this.textInput} />
                                            {errors.longitud && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '50px' }}>{errors.longitud}</strong>}
                                        </div>
                                        <div className="col-md-4 my-2">
                                            <MDBInput name="zoom" label="Zoom" type="number" onChange={this.textInput} />
                                            {errors.zoom && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '50px' }}>{errors.zoom}</strong>}
                                        </div>
                                    </div>
                                </div>
                                <div className="pt-2 mt-5 modal-footer w-100">
                                    <button type="button" className="btn btn-primary px-5" onClick={this.insertarDatos}>Crear Agencia &nbsp; <MDBIcon icon="save" /></button>
                                </div>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <div className={estadoEditar ? "container-fluid" : "container"}>
                                    <div className="table-responsive text-center">
                                        <MDBTable>
                                            <MDBTableHead>
                                                <tr className="font-weight-bold">
                                                    <td>Icono</td>
                                                    <td>Nombre Agencia</td>
                                                    <td>Ciudad/Municipio</td>
                                                    <td>Tipo de Agencia</td>
                                                    <td>Latitud</td>
                                                    <td>Longitud</td>
                                                    <td>Zoom</td>
                                                    <td>Editar</td>
                                                    <td>Inactivar</td>
                                                </tr>
                                            </MDBTableHead>
                                            <MDBTableBody>
                                                {estadoSinResultados ? (
                                                    <React.Fragment>
                                                        {estadoEditar ?
                                                            (
                                                                <React.Fragment>
                                                                    <tr>
                                                                        <td>
                                                                            <FileUploader ref={this.uploader} estado={estadoCambio} ruta={this.state.rutaiconomapa} FCambiar={this.cambio} />
                                                                        </td>
                                                                        <td className="w-25"><input className="form-control" type="text" name="nombreagenc" value={this.state.nombreagenc} onChange={this.textInput} /></td>
                                                                        <td className="w-25">
                                                                            <MunicipiosLista infoMunicipios={infoMunicipios} MuniSelect={this.MuniSelect} />
                                                                        </td>
                                                                        <td>
                                                                            <select name="idtipoagencia" className="form-control" defaultValue={'DEFAULT'} onChange={this.textInput}>
                                                                                <option value={'DEFAULT'} disabled>Selecciona...</option>
                                                                                <option value="1">Principal</option>
                                                                                <option value="2">Mini Basica</option>
                                                                            </select>
                                                                        </td>
                                                                        <td className="w-25"><input className="form-control" type="text" name="latitud" value={this.state.latitud} onChange={this.textInput} /></td>
                                                                        <td className="w-25"><input className="form-control" type="text" name="longitud" value={this.state.longitud} onChange={this.textInput} /></td>
                                                                        <td className="w-25"><input className="form-control px-0 mx-0" type="text" name="zoom" value={this.state.zoom} onChange={this.textInput} /></td>
                                                                        <td className="text-center" onClick={() => { this.actualizarDatos(idredagencia) }}><MDBIcon icon="save" size="2x" style={{ color: 'blue', cursor: 'pointer' }} /></td>
                                                                        <td className="text-center" onClick={() => { this.setState({ estadoEditar: !this.state.estadoEditar }) }}><MDBIcon icon="times" size="2x" style={{ color: 'red', cursor: 'pointer' }} /></td>
                                                                    </tr>
                                                                </React.Fragment>
                                                            ) : (
                                                                <React.Fragment>
                                                                    {infoAgencias.map((e, i) => {
                                                                        let view;
                                                                        let tipoagencia;
                                                                        if (e.estado === 1) {
                                                                            view = <MDBIcon onClick={() => { this.inactivar(e.idredagencia) }} icon="eye" size="2x" style={{ cursor: 'pointer' }} />
                                                                        } else {
                                                                            view = <MDBIcon onClick={() => { this.activar(e.idredagencia) }} icon="eye-slash" size="2x" style={{ cursor: 'pointer' }} />
                                                                        }
                                                                        if (e.idtipoagencia === 1) {
                                                                            tipoagencia = "Principal"
                                                                        } else if (e.idtipoagencia === 2) {
                                                                            tipoagencia = "Mini Básica"
                                                                        }
                                                                        return [
                                                                            <React.Fragment key={i}>
                                                                                <tr>
                                                                                    <td><img width="40" src={`${URLImgFront}/assets${e.rutaiconomapa}`} alt={e.rutaiconomapa} /></td>
                                                                                    <td>{e.nombreagenc}</td>
                                                                                    <td>{e.mpiodpto}</td>
                                                                                    <td>{tipoagencia}</td>
                                                                                    <td>{e.latitud}</td>
                                                                                    <td>{e.longitud}</td>
                                                                                    <td>{e.zoom}</td>
                                                                                    <td className="text-center" onClick={() => { this.editar(e.idredagencia) }}><MDBIcon icon="edit" size="2x" style={{ color: 'green', cursor: 'pointer' }} /></td>
                                                                                    <td className="text-center">{view}</td>
                                                                                </tr>
                                                                            </React.Fragment>
                                                                        ]
                                                                    })}
                                                                </React.Fragment>
                                                            )}
                                                    </React.Fragment>
                                                ) : (
                                                    <React.Fragment>
                                                        <tr className="alert alert-info text-center w-100">
                                                            <td>
                                                                Sin resultados
                                                            </td>
                                                        </tr>
                                                    </React.Fragment>
                                                )}
                                            </MDBTableBody>
                                        </MDBTable>
                                    </div>
                                </div>
                            </React.Fragment>
                        )}

                    </React.Fragment>
                ) : (
                    <React.Fragment></React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

export default Agencias;