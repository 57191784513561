import React, { Component } from 'react';
import 'swiper/css/swiper.min.css';
import CasosExito from './Seccion2/CasosExito';
//import EducacionFinanciera from './Seccion2/EducacionFinanciera';
import Eventos from './Seccion2/Eventos';
import Noticias from './Seccion2/Noticias';

class Seccion2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eventos: false,
            educacionFinanciera: false,
            noticias: true,
            casosExito: false
        };
    }

    cambioSeccion = (id) => {
        if (id === 1) {
            this.setState({
                eventos: true,
                educacionFinanciera: false,
                noticias: false,
                casosExito: false
            });
        }
        /*if (id === 2) {
            this.setState({
                eventos: false,
                educacionFinanciera: true,
                noticias: false,
                casosExito: false
            });
        }*/
        if (id === 3) {
            this.setState({
                eventos: false,
                educacionFinanciera: false,
                noticias: true,
                casosExito: false
            });
        }
        if (id === 4) {
            this.setState({
                eventos: false,
                educacionFinanciera: false,
                noticias: false,
                casosExito: true
            });
        }
    }

    render() {
        const { eventos, /*educacionFinanciera,*/ noticias, casosExito } = this.state;
        return (
            <React.Fragment>
                <div className="diseño_seccion2_coopsejos" style={{ width: '100%' }}>
                    <div className="container-fluid">
                        <div className="row justify-content-center px-0 py-4">
                            <div className="col-md-1 text-center">
                                <p className="font-weight-bold" onClick={() => { this.cambioSeccion(1) }} style={eventos ? { cursor: 'pointer', borderBottom: '2px solid #FA7500' } : { cursor: 'pointer', borderBottom: 'none' }}>EVENTOS</p>
                            </div>
                            {/*<div className="col-md-2 text-center">
                                <p className="font-weight-bold" onClick={() => { this.cambioSeccion(2) }} style={educacionFinanciera ? { cursor: 'pointer', borderBottom: '2px solid #FA7500' } : { cursor: 'pointer', borderBottom: 'none' }}>EDUCACIÓN FINANCIERA</p>
                            </div>*/}
                            <div className="col-md-1 text-center">
                                <p className="font-weight-bold" onClick={() => { this.cambioSeccion(3) }} style={noticias ? { cursor: 'pointer', borderBottom: '2px solid #FA7500' } : { cursor: 'pointer', borderBottom: 'none' }}>NOTICIAS</p>
                            </div>
                            <div className="col-md-2 text-center">
                                <p className="font-weight-bold" onClick={() => { this.cambioSeccion(4) }} style={casosExito ? { cursor: 'pointer', borderBottom: '2px solid #FA7500' } : { cursor: 'pointer', borderBottom: 'none' }}>CASOS DE ÉXITO</p>
                            </div>
                        </div>
                    </div>
                    {eventos ? (
                        <Eventos />
                    ) : (<div></div>)}
                    {/*{educacionFinanciera ? (
                        <EducacionFinanciera />
                    ) : (<div></div>)}*/}
                    {noticias ? (
                        <Noticias />
                    ) : (<div></div>)}
                    {casosExito ? (
                        <CasosExito />
                    ) : (<div></div>)}
                </div>
            </React.Fragment>
        );
    }
}

export default Seccion2;