import { Link, withRouter } from 'react-router-dom';
import React, { Component } from 'react';
import { MDBIcon } from 'mdbreact';
import { URLImgFront } from '../../config/URLDB';
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.min.css';

class Seccion2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            slidesPerView: 3,
            stateSwiper: false
        };
    }

    componentDidMount() {
        if (window.screen.width >= 550 && window.screen.width <= 767) {
            this.setState({
                slidesPerView: 2,
                stateSwiper: true
            })
        } else if (window.screen.width >= 60 && window.screen.width <= 549) {
            this.setState({
                slidesPerView: 1,
                stateSwiper: true
            })
        }else{
            this.setState({
                slidesPerView: 3,
                stateSwiper: true
            })
        }
    }

    changeView = (idl, id) => {
        localStorage.setItem('idtipoprod', idl)
        localStorage.setItem('idtipopers', id)
        var name = '';
        if (idl === 2) { name = 'Credito'; localStorage.setItem('nombLinea', 'Crédito') } else { name = 'Ahorros'; localStorage.setItem('nombLinea', 'Ahorros') }
        if (id === 1) { localStorage.setItem('nombTproduct', 'Natural'); localStorage.setItem('TipoPers', 'Personas') } else { localStorage.setItem('nombTproduct', 'Jurídica'); localStorage.setItem('TipoPers', 'Empresas') }
        this.props.history.push('./Productos/' + name + '/' + idl + '/' + id);
    }

    render() {
        const params = {
            slidesPerView: this.state.slidesPerView,
            spaceBetween: 30,
            freeMode: true,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            autoplay: {
                delay: 4000,
                disableOnInteraction: false,
            },
            shouldSwiperUpdate: true
        }
        return (
            <React.Fragment>
                <div className="bg-white">
                    <div className="container">
                        <div className="mt-3 pb-5">
                            <div className="py-3 text-center">
                                <p style={{ fontSize: '30px', fontWeight: 'bold' }}>PERSONAS</p>
                            </div>
                            {this.state.stateSwiper ? (<React.Fragment>
                                <Swiper {...params}>
                                    <div onClick={() => { this.changeView(1, 1) }} className="card my-3" style={{ cursor: 'pointer' }}>
                                        <div className="card-header px-0 py-0">
                                            <img className="img-fluid w-100" src={`${URLImgFront}/assets/img-variadas/IMG CASILLA AHORRO.png`} alt="IMG CASILLA AHORRO" />
                                        </div>
                                        <div className="card-body text-center py-4">
                                            <p className="font-weight-bold text-dark">AHORROS</p>
                                            <div className="mt-5 bg-white w-100 font-weight-bold mx-0" style={{ color: '#95bac1', fontSize: '17px' }}>
                                                Ver cuentas
                                                <MDBIcon
                                                    icon="arrow-right"
                                                    size="lg"
                                                    className="position-absolute"
                                                    style={{ right: '20px', color: '#ed6b4e' }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div onClick={() => { this.changeView(2, 1) }} className="card my-3" style={{ cursor: 'pointer' }}>
                                        <div className="card-header px-0 py-0">
                                            <img className="img-fluid w-100" src={`${URLImgFront}/assets/img-variadas/IMG CASILLA CRÉDITO.png`} alt="IMG CASILLA CRÉDITO" />
                                        </div>
                                        <div className="card-body text-center py-4">
                                            <p className="font-weight-bold text-dark">CRÉDITOS</p>
                                            <div className="mt-5 bg-white w-100 font-weight-bold mx-0" style={{ color: '#95bac1', fontSize: '17px' }} >
                                                Entérate de más
                                                <MDBIcon
                                                    icon="arrow-right"
                                                    size="lg"
                                                    className="position-absolute"
                                                    style={{ right: '20px', color: '#ed6b4e' }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <Link to="/Convenios" className="card my-3">
                                        <div className="card-header px-0 py-0">
                                            <img className="img-fluid w-100" src={`${URLImgFront}/assets/img-variadas/IMG CASILLA CONVENIOS.png`} alt="IMG CASILLA CONVENIOS" />
                                        </div>
                                        <div className="card-body text-center py-4">
                                            <p className="font-weight-bold text-dark">CONVENIOS</p>
                                            <div className="mt-5 bg-white w-100 font-weight-bold mx-0" style={{ color: '#95bac1', fontSize: '17px' }} >
                                                Quiero ver más
                                                <MDBIcon
                                                    icon="arrow-right"
                                                    size="lg"
                                                    className="position-absolute"
                                                    style={{ right: '20px', color: '#ed6b4e' }}
                                                />
                                            </div>
                                        </div>
                                    </Link>
                                    <Link to="/Infantil_Juvenil" className="card my-3">
                                        <div className="card-header px-0 py-0">
                                            <img className="img-fluid w-100" src={`${URLImgFront}/assets/img-variadas/IMG CASILLA INFANTIL JUVENIL.png`} alt="IMG CASILLA INFANTIL JUVENIL" />
                                        </div>
                                        <div className="card-body text-center py-4">
                                            <p className="font-weight-bold text-dark">INFANTIL JUVENIL</p>
                                            <div className="mt-5 bg-white w-100 font-weight-bold mx-0" style={{ color: '#95bac1', fontSize: '17px' }} >
                                                Quiero ver más
                                                <MDBIcon
                                                    icon="arrow-right"
                                                    size="lg"
                                                    className="position-absolute"
                                                    style={{ right: '20px', color: '#ed6b4e' }}
                                                />
                                            </div>
                                        </div>
                                    </Link>
                                    <Link to="/Seguros" className="card my-3">
                                        <div className="card-header px-0 py-0">
                                            <img className="img-fluid w-100" src={`${URLImgFront}/assets/img-variadas/IMG CASILLA SEGUROS.png`} alt="IMG CASILLA SEGUROS" />
                                        </div>
                                        <div className="card-body text-center py-4">
                                            <p className="font-weight-bold text-dark">SEGUROS</p>
                                            <div className="mt-5 bg-white w-100 font-weight-bold mx-0" style={{ color: '#95bac1', fontSize: '17px' }} >
                                                Quiero ver más
                                                <MDBIcon
                                                    icon="arrow-right"
                                                    size="lg"
                                                    className="position-absolute"
                                                    style={{ right: '20px', color: '#ed6b4e' }}
                                                />
                                            </div>
                                        </div>
                                    </Link>
                                </Swiper>
                            </React.Fragment>) : (<React.Fragment></React.Fragment>)}
                        </div>
                        <div className="mt-3 pb-5">
                            <div className="py-3 text-center">
                                <p style={{ fontSize: '30px', fontWeight: 'bold' }}>EMPRESAS</p>
                            </div>
                            {this.state.stateSwiper ? (<React.Fragment>
                                <Swiper {...params}>
                                    <div onClick={() => { this.changeView(1, 0) }} className="card my-3" style={{ cursor: 'pointer' }}>
                                        <div className="card-header px-0 py-0">
                                            <img className="img-fluid w-100" src={`${URLImgFront}/assets/img-variadas/IMG CASILLA AHORRO EMPRESAS.png`} alt="IMG CASILLA AHORRO EMPRESAS" />
                                        </div>
                                        <div className="card-body text-center py-4">
                                            <p className="font-weight-bold text-dark">AHORROS</p>
                                            <div className="mt-5 bg-white w-100 font-weight-bold mx-0" style={{ color: '#95bac1', fontSize: '17px' }}>
                                                Ver cuentas
                                                <MDBIcon
                                                    icon="arrow-right"
                                                    size="lg"
                                                    className="position-absolute"
                                                    style={{ right: '20px', color: '#ed6b4e' }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div onClick={() => { this.changeView(1, 0) }} className="card my-3" style={{ cursor: 'pointer' }}>
                                        <div className="card-header px-0 py-0">
                                            <img className="img-fluid w-100" src={`${URLImgFront}/assets/img-variadas/IMG CASILLA CRÉDITO EMPRESAS.png`} alt="IMG CASILLA CRÉDITO EMPRESAS" />
                                        </div>
                                        <div className="card-body text-center py-4">
                                            <p className="font-weight-bold text-dark">CRÉDITOS</p>
                                            <div className="mt-5 bg-white w-100 font-weight-bold mx-0" style={{ color: '#95bac1', fontSize: '17px' }} >
                                                Entérate de más
                                                <MDBIcon
                                                    icon="arrow-right"
                                                    size="lg"
                                                    className="position-absolute"
                                                    style={{ right: '20px', color: '#ed6b4e' }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <Link to="/Convenios" className="card my-3">
                                        <div className="card-header px-0 py-0">
                                            <img className="img-fluid w-100" src={`${URLImgFront}/assets/img-variadas/IMG CASILLA CONVENIOS EMPRESAS.png`} alt="IMG CASILLA CONVENIOS EMPRESAS" />
                                        </div>
                                        <div className="card-body text-center py-4">
                                            <p className="font-weight-bold text-dark">CONVENIOS</p>
                                            <div className="mt-5 bg-white w-100 font-weight-bold mx-0" style={{ color: '#95bac1', fontSize: '17px' }} >
                                                Quiero ver más
                                                <MDBIcon
                                                    icon="arrow-right"
                                                    size="lg"
                                                    className="position-absolute"
                                                    style={{ right: '20px', color: '#ed6b4e' }}
                                                />
                                            </div>
                                        </div>
                                    </Link>
                                    <Link to="/Seguros" className="card my-3">
                                        <div className="card-header px-0 py-0">
                                            <img className="img-fluid w-100" src={`${URLImgFront}/assets/img-variadas/IMG CASILLA SEGUROS EMPRESAS.png`} alt="IMG CASILLA SEGUROS EMPRESAS" />
                                        </div>
                                        <div className="card-body text-center py-4">
                                            <p className="font-weight-bold text-dark">SEGUROS</p>
                                            <div className="mt-5 bg-white w-100 font-weight-bold mx-0" style={{ color: '#95bac1', fontSize: '17px' }} >
                                                Quiero ver más
                                                <MDBIcon
                                                    icon="arrow-right"
                                                    size="lg"
                                                    className="position-absolute"
                                                    style={{ right: '20px', color: '#ed6b4e' }}
                                                />
                                            </div>
                                        </div>
                                    </Link>
                                </Swiper>
                            </React.Fragment>) : (<React.Fragment></React.Fragment>)}
                        </div>
                    </div>
                </div>
                <Link to="/Asesoria">
                    <img style={{cursor: 'pointer', width: '100%'}} className="img-fluid" src={`${URLImgFront}/assets/img-variadas/FORMULARIO DE CONTACTO SERVICIOS.png`} alt="img-cont" />
                </Link>
            </React.Fragment>
        );
    }
}

export default withRouter(Seccion2);
