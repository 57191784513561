import { MDBIcon } from 'mdbreact';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';
import { URLImgFront } from '../../config/URLDB';

class Seccion2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            slidesPerView: 3,
            stateSwiper: false
        };
    }

    componentDidMount() {
        if (window.screen.width >= 550 && window.screen.width <= 767) {
            this.setState({
                slidesPerView: 2,
                stateSwiper: true
            })
        } else if (window.screen.width >= 60 && window.screen.width <= 549) {
            this.setState({
                slidesPerView: 1,
                stateSwiper: true
            })
        } else {
            this.setState({
                slidesPerView: 3,
                stateSwiper: true
            })
        }
    }

    render() {
        const params = {
            slidesPerView: this.state.slidesPerView,
            spaceBetween: 30,
            freeMode: true,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            autoplay: {
                delay: 3000,
                disableOnInteraction: false,
            },
            shouldSwiperUpdate: true
        }
        return (
            <React.Fragment>
                <div className="contenido-asociados" style={{ width: '100%' }}>
                    <div className="container">
                        <p className="text-justify" style={{ color: '#000', fontSize: '20px' }}>Hemos creado un espacio para que nuestros asociados/as conozcan todos los beneficios a los que pueden acceder, por ser propietario y gestor de una de las Cooperativas más grandes de Colombia. </p>
                    </div>
                    <div style={{ background: '#f7f7f7' }}>
                        <div className="container py-5">
                            {this.state.stateSwiper ? (<React.Fragment>
                                <Swiper {...params} className="rounded py-3 px-5">
                                    <div className="card my-3">
                                        <Link to="/Beneficios">
                                            <div className="card-header px-0 py-0">
                                                <img className="img-fluid w-100" src={`${URLImgFront}/assets/Asociados/Asociados Detalles/BENEFICIOS.png`} alt="img-BENEFICIOS" />
                                            </div>
                                            <div className="card-body py-4 text-dark">
                                                <p className="pb-2 pl-2 font-weight-bold text-center" style={{ fontSize: '20px' }}>BENEFICIOS</p>
                                                <div className="bg-white w-100 font-weight-bold mx-0 text-center" style={{ color: '#95bac1', fontSize: '17px' }}>Conozca más <MDBIcon icon="arrow-right" size="lg" className="position-absolute" style={{ right: '20px', color: '#ed6b4e' }} /></div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="card my-3">
                                        <Link to="/Revalorizacion">
                                            <div className="card-header px-0 py-0">
                                                <img className="img-fluid w-100" src={`${URLImgFront}/assets/Asociados/Asociados Detalles/REVALORIZACIÓN.png`} alt="img-REVALORIZACIÓN" />
                                            </div>
                                            <div className="card-body py-4 text-dark">
                                                <p className="pb-2 pl-2 font-weight-bold text-center" style={{ fontSize: '20px' }}>REVALORIZACIÓN</p>
                                                <div className="bg-white w-100 text-center font-weight-bold mx-0" style={{ color: '#95bac1', fontSize: '17px' }}>Conozca más <MDBIcon icon="arrow-right" size="lg" className="position-absolute" style={{ right: '20px', color: '#ed6b4e' }} /></div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="card my-3">
                                        <Link to="/Cooperativa_Virtual">
                                            <div className="card-header px-0 py-0">
                                                <img className="img-fluid w-100" src={`${URLImgFront}/assets/Asociados/Asociados Detalles/COOPERATIVA VIRTUAL.png`} alt="img-COOPERATIVA VIRTUAL" />
                                            </div>
                                            <div className="card-body py-4 text-dark">
                                                <p className="pb-2 pl-2 font-weight-bold text-center" style={{ fontSize: '20px' }}>COOPERATIVA VIRTUAL</p>
                                                <div className="bg-white w-100 text-center font-weight-bold mx-0" style={{ color: '#95bac1', fontSize: '17px' }}>Conozca más <MDBIcon icon="arrow-right" size="lg" className="position-absolute" style={{ right: '20px', color: '#ed6b4e' }} /></div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="card my-3">
                                        <Link to="/Convenios/Personas">
                                            <div className="card-header px-0 py-0">
                                                <img className="img-fluid w-100" src={`${URLImgFront}/assets/Asociados/Asociados Detalles/CONVENIOS.png`} alt="img-CONVENIOS" />
                                            </div>
                                            <div className="card-body py-4 text-dark">
                                                <p className="pb-2 pl-2 font-weight-bold text-center" style={{ fontSize: '20px' }}>CONVENIOS</p>
                                                <div className="bg-white w-100 text-center font-weight-bold mx-0" style={{ color: '#95bac1', fontSize: '17px' }}>Conozca más <MDBIcon icon="arrow-right" size="lg" className="position-absolute" style={{ right: '20px', color: '#ed6b4e' }} /></div>
                                            </div>
                                        </Link>
                                    </div>
                                    {/*<div className="card my-3">
                                        <Link to="/Campañas">
                                            <div className="card-header px-0 py-0">
                                                <img className="img-fluid w-100" src={`${URLImgFront}/assets/Asociados/Asociados Detalles/CAMPAÑAS.png`} alt="img-CAMPAÑAS" />
                                            </div>
                                            <div className="card-body py-4 text-dark">
                                                <p className="pb-2 pl-2 font-weight-bold text-center" style={{ fontSize: '20px' }}>CAMPAÑAS</p>
                                                <div className="bg-white w-100 text-center font-weight-bold mx-0" style={{ color: '#95bac1', fontSize: '17px' }}>Conozca más <MDBIcon icon="arrow-right" size="lg" className="position-absolute" style={{ right: '20px', color: '#ed6b4e' }} /></div>
                                            </div>
                                        </Link>
                                    </div>*/}
                                    <div className="card my-3">
                                        <Link to="/eventos_concursos">
                                            <div className="card-header px-0 py-0">
                                                <img className="img-fluid w-100" src={`${URLImgFront}/assets/Asociados/Asociados Detalles/EVENTOS Y CONCURSOS.png`} alt="img-EVENTOS Y CONCURSOS" />
                                            </div>
                                            <div className="card-body py-4 text-dark">
                                                <p className="pb-2 pl-2 font-weight-bold text-center" style={{ fontSize: '20px' }}>EVENTOS Y CONCURSOS</p>
                                                <div className="bg-white w-100 text-center font-weight-bold mx-0" style={{ color: '#95bac1', fontSize: '17px' }}>Conozca más <MDBIcon icon="arrow-right" size="lg" className="position-absolute" style={{ right: '20px', color: '#ed6b4e' }} /></div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="card my-3">
                                        <Link to="/Coopsejos">
                                            <div className="card-header px-0 py-0">
                                                <img className="img-fluid w-100" src={`${URLImgFront}/assets/Asociados/Asociados Detalles/COOPSEJOS.png`} alt="img-COOPSEJOS" />
                                            </div>
                                            <div className="card-body py-4 text-dark">
                                                <p className="pb-2 pl-2 font-weight-bold text-center" style={{ fontSize: '20px' }}>COOPSEJOS</p>
                                                <div className="bg-white w-100 text-center font-weight-bold mx-0" style={{ color: '#95bac1', fontSize: '17px' }}>Conozca más <MDBIcon icon="arrow-right" size="lg" className="position-absolute" style={{ right: '20px', color: '#ed6b4e' }} /></div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="card my-3">
                                        <Link to="/Simulador/Credito">
                                            <div className="card-header px-0 py-0">
                                                <img className="img-fluid w-100" src={`${URLImgFront}/assets/Asociados/Asociados Detalles/SIMULADOR DE CRÉDITO.png`} alt="img-SIMULADOR DE CRÉDITO" />
                                            </div>
                                            <div className="card-body py-4 text-dark">
                                                <p className="pb-2 pl-2 font-weight-bold text-center" style={{ fontSize: '20px' }}>SIMULADOR DE CRÉDITO</p>
                                                <div className="bg-white w-100 text-center font-weight-bold mx-0" style={{ color: '#95bac1', fontSize: '17px' }}>Conozca más <MDBIcon icon="arrow-right" size="lg" className="position-absolute" style={{ right: '20px', color: '#ed6b4e' }} /></div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="card my-3">
                                        <Link to="/Simulador/Ahorros">
                                            <div className="card-header px-0 py-0">
                                                <img className="img-fluid w-100" src={`${URLImgFront}/assets/Asociados/Asociados Detalles/SIMULADOR DE AHORRO.png`} alt="img-SIMULADOR DE AHORRO" />
                                            </div>
                                            <div className="card-body py-4 text-dark">
                                                <p className="pb-2 pl-2 font-weight-bold text-center" style={{ fontSize: '20px' }}>SIMULADOR DE AHORRO</p>
                                                <div className="bg-white w-100 text-center font-weight-bold mx-0" style={{ color: '#95bac1', fontSize: '17px' }}>Conozca más <MDBIcon icon="arrow-right" size="lg" className="position-absolute" style={{ right: '20px', color: '#ed6b4e' }} /></div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="card my-3">
                                        <a href="http://www.fundautrahuilca.org/" target={'_blank'} rel="noopener noreferrer">
                                            <div className="card-header px-0 py-0">
                                                <img className="img-fluid w-100" src={`${URLImgFront}/assets/Asociados/Asociados Detalles/FUNDAUTRAHUILCA.png`} alt="img-FUNDAUTRAHUILCA" />
                                            </div>
                                            <div className="card-body py-4 text-dark">
                                                <p className="pb-2 pl-2 font-weight-bold text-center" style={{ fontSize: '20px' }}>FUNDAUTRAHUILCA</p>
                                                <div className="bg-white w-100 text-center font-weight-bold mx-0" style={{ color: '#95bac1', fontSize: '17px' }}>Conozca más <MDBIcon icon="arrow-right" size="lg" className="position-absolute" style={{ right: '20px', color: '#ed6b4e' }} /></div>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="card my-3">
                                        <Link to="/Canales_Atencion">
                                            <div className="card-header px-0 py-0">
                                                <img className="img-fluid w-100" src={`${URLImgFront}/assets/Asociados/Asociados Detalles/CANALES DE ATENCIÓN.png`} alt="img-CANALES DE ATENCIÓN" />
                                            </div>
                                            <div className="card-body py-4 text-dark">
                                                <p className="pb-2 pl-2 font-weight-bold text-center" style={{ fontSize: '20px' }}>CANALES DE ATENCIÓN</p>
                                                <div className="bg-white w-100 text-center font-weight-bold mx-0" style={{ color: '#95bac1', fontSize: '17px' }}>Conozca más <MDBIcon icon="arrow-right" size="lg" className="position-absolute" style={{ right: '20px', color: '#ed6b4e' }} /></div>
                                            </div>
                                        </Link>
                                    </div>
                                </Swiper>
                            </React.Fragment>) : (<React.Fragment></React.Fragment>)}
                        </div>
                    </div>
                    <div className="container py-3">
                        <div className="row py-5 text-center">
                            <div className="col-md-12 py-4 text-center">
                                <p className="font-weight-bold">Conozca más de la Cooperativa UTRAHUILCA en nuestras redes sociales. </p>
                            </div>
                            <div className="col-md-3">
                                <a href="https://www.facebook.com/utrahuilca" target={'_blank'} rel="noopener noreferrer">
                                    <img style={{ width: '100px', height: '100px' }} className="img-fluid" src={`${URLImgFront}/assets/img-variadas/ÍCONO facebook.png`} alt="ÍCONO facebook" />
                                </a>
                            </div>
                            <div className="col-md-3">
                                <a href="https://www.instagram.com/utrahuilca/" target={'_blank'} rel="noopener noreferrer">
                                    <img style={{ width: '100px', height: '100px' }} className="img-fluid" src={`${URLImgFront}/assets/img-variadas/ÍCONO INSTAGRAM.png`} alt="ÍCONO INSTAGRAM" />
                                </a>                            </div>
                            <div className="col-md-3">
                                <a href="https://twitter.com/Utrahuilca" target={'_blank'} rel="noopener noreferrer">
                                    <img style={{ width: '100px', height: '100px' }} className="img-fluid" src={`${URLImgFront}/assets/img-variadas/ÍCONO TWITTER.png`} alt="ÍCONO TWITTER" />
                                </a>
                            </div>
                            <div className="col-md-3">
                                <a href="https://www.youtube.com/channel/UCZa_TvUo3FlzVz-jnn_67jw" target={'_blank'} rel="noopener noreferrer">
                                    <img style={{ width: '100px', height: '100px' }} className="img-fluid" src={`${URLImgFront}/assets/img-variadas/ÍCONO youtube.png`} alt="ÍCONO youtube" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment >
        );
    }
}

export default Seccion2;