import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Seccion3 extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    render() {
        return (
            <React.Fragment>
                <div className="diseño_seccion3_cdat" style={{ width: '100%', background: '#41858e' }}>
                    <div className="container-fluid pt-5 pl-5 pr-5 text-white">
                        <h4 className="font-weight-bold">Beneficios</h4>
                        <div className="row text-white mt-5">
                            <div className="col-md-6 px-5 py-5" style={{ borderBottom: '2px solid white', borderRight: '2px solid white' }}>
                                <p>Es una excelente alternativa para nuestros asociados(as) que mantienen su dinero rentando a excelentes tasas de interés, por encima del mercado financiero tradicional.</p>
                            </div>
                            <div className="col-md-6 px-5 py-5" style={{ borderBottom: '2px solid white' }}>
                                <p>Obtiene rentabilidad y seguridad en el manejo del dinero más todos los beneficios económicos y sociales que le ofrece Utrahuilca.</p>
                            </div>
                            <div className="col-md-6 px-5 py-5" style={{ borderBottom: '2px solid white', borderRight: '2px solid white' }}>
                                <p>La cooperativa asume el impuesto del 4x 1.000, como beneficio económico para sus asociados.</p>
                            </div>
                            <div className="col-md-6 px-5 py-5" style={{ borderBottom: '2px solid white' }}>
                                <p>Disponibilidad inmediata de su inversión y sus rendimientos a la fecha pactada.</p>
                            </div>
                            <div className="col-md-6 px-5 py-5" style={{ borderBottom: '2px solid white', borderRight: '2px solid white' }}>
                                <p>Abono Automático de los intereses y/o capital a la Cuenta de Ahorros.</p>
                            </div>
                            <div className="col-md-6 px-5 py-5" style={{ borderBottom: '2px solid white' }}>
                                <p>El pago de intereses en cheque no tiene ningún costo y su constitución en cheque genera intereses desde el primer día, sin haber hecho canje.</p>
                            </div>
                            <div className="col-md-6 px-5 py-5" style={{ borderBottom: '2px solid white', borderRight: '2px solid white' }}>
                                <p>Flexibilidad de plazos de acuerdo con sus necesidades de inversión.</p>
                            </div>
                            <div className="col-md-6 px-5 py-5" style={{ borderBottom: '2px solid white' }}>
                                <p>La constitución de certificados establece una excelente referencia comercial y financiera.</p>
                            </div>
                            <div className="col-md-6 px-5 py-5" style={{ borderBottom: '2px solid white', borderRight: '2px solid white' }}>
                                <p>Con la constitución de cdats adquiere beneficios para obtener descuentos especiales en su previsión exequial, que sumadas a la captación de ahorros y aportes puede permitirle al asociado que su póliza sea gratis.</p>
                            </div>
                            <div className="col-md-6 px-5 py-5" style={{ borderBottom: '2px solid white' }}>
                                <p>Se beneficia con seguro de depósito FOGACOOP hasta por 50 millones de pesos.</p>
                            </div>
                            <div className="col-md-6 px-5 py-5" style={{ borderBottom: '2px solid white', borderRight: '2px solid white' }}>
                                <p>En caso de no ser reclamado en la fecha de vencimiento, se renovará automáticamente bajo las mismas condiciones pero con la tasa vigente del mes en curso.</p>
                            </div>
                            <div className="col-md-6 px-5 py-5" style={{ borderBottom: '2px solid white' }}>
                                <p>Créditos ágiles hasta por el 100% del valor del Certificado de Ahorro a Termino, incluyendo los intereses del crédito.</p>
                            </div>
                            <div className="col-md-6 px-5 py-5" style={{ borderBottom: '2px solid white', borderRight: '2px solid white' }}>
                                <p>Inembargabilidad de los ahorros hasta por montos establecidos en la ley.</p>
                            </div>
                            <div className="col-md-6 px-5 py-5" style={{ borderBottom: '2px solid white' }}>
                                <p>Restitución de los depósitos a los herederos del titular fallecido sin previo juicio de sucesión.</p>
                            </div>
                            <div className="col-md-6 px-5 py-5" style={{ borderBottom: '2px solid white', borderRight: '2px solid white' }}>
                                <p>Goza de la participación de sorteos o estímulos de acuerdo a Reglamentación.</p>
                            </div>
                            <div className="col-md-12 py-5">
                                <div className="container">
                                    <div className="row d-flex justify-content-center text-center">
                                        <div className="col-md-6 my-3 px-0">
                                            <Link to="/Simulador/Ahorros">
                                                <span className="px-5 py-2 mt-3" style={{ background: '#f07f00', color: 'white', fontWeight: 'bold', borderRadius: '20px', cursor: 'pointer' }}>Simular Ahorro</span>
                                            </Link>
                                        </div>
                                        <div className="col-md-6 my-3 px-0">
                                            <Link to="/Asesoria">
                                                <span className="px-5 py-2 mt-3" style={{ background: '#f07f00', color: 'white', fontWeight: 'bold', borderRadius: '20px', cursor: 'pointer' }}>Formulario de Asesoría</span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="bg-white inferior">
                        <div className="container-fluid py-5 pl-5">
                            <p className="my-0 pl-5">Goza de la participación de sorteos o estímulos de acuerdo a Reglamentación.</p>
                        </div>
                    </div> */}
                </div>
            </React.Fragment>
        );
    }
}

export default Seccion3;