import React, { Component } from 'react';
import { URLImgFront } from '../../config/URLDB';

class Seccion1 extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    render() {
        return (
            <React.Fragment>
                <div className="pse_diseño pt-5" style={{ width: '100%' }}>
                    <img className="img-fluid pt-5" style={{ width: '100%', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', objectFit: 'cover' }} src={`${URLImgFront}/assets/PSE/ImagenTexto/PORTADA_PSE.jpg`} />
                </div>
            </React.Fragment>
        );
    }
}

export default Seccion1;