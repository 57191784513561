import { MDBIcon, MDBInput } from 'mdbreact';
import React, { Component } from 'react';
import 'swiper/css/swiper.min.css';
import ReCAPTCHA from "react-google-recaptcha";
import { PQRSF_ } from '../../services/WebAPI_Utrahuilca';
import Swal from 'sweetalert2';
import Loading from '../Loading';

const validate = values => {
    const errors = {}
    if (values.tipoiden === 'DEFAULT') {
        errors.tipoiden = 'campo obligatorio'
    }
    if (!values.nombres) {
        errors.nombres = 'campo obligatorio'
    }
    if (!values.apellidos) {
        errors.apellidos = 'campo obligatorio'
    }
    if (!values.nit) {
        errors.nit = 'campo obligatorio'
    }
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    if (!pattern.test(values.correo)) {
        errors.correo = "Introduce una dirección de correo electrónico válida.";
    }
    if (!values.correo) {
        errors.correo = 'campo obligatorio'
    }
    if (!values.telcel) {
        errors.telcel = 'campo obligatorio'
    }
    if (!values.direccion) {
        errors.direccion = 'campo obligatorio'
    }
    if (values.t_pqrsf === 'DEFAULT') {
        errors.t_pqrsf = 'campo obligatorio'
    }
    if (!values.mensaje) {
        errors.mensaje = 'campo obligatorio'
    }
    if (values.t_respuesta === 'DEFAULT') {
        errors.t_respuesta = 'campo obligatorio'
    }
    if(values.checkbox === false){
        errors.checkbox = 'campo obligatorio'
    }
    return errors;
};

class Seccion2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stateBtn: true,
            errors: {},
            tipoiden: 'DEFAULT',
            t_pqrsf: 'DEFAULT',
            t_respuesta: 'DEFAULT',
            mensaje: '',
            telcel: '',
            stateLoadign: false,
            nit: '',
            checkbox: false
        };
        this.recaptchaRef = React.createRef();
    }

    validRecat = () => {
        if (this.recaptchaRef.current.getValue()) {
            this.setState({
                stateBtn: false
            })
        } else {
            this.setState({
                stateBtn: true
            })
        }
    }

    textInput = (e) => {
        if (e.target.name === "mensaje") {
            let regex = new RegExp("^[a-zA-Z0-9 ]+$");
            if (regex.test(e.target.value) || e.target.value === "") {
                this.setState({
                    [e.target.name]: e.target.value
                });
            }
        } else if (e.target.name === "telcel") {
            let regex = new RegExp("^[0-9]+$");
            if (regex.test(e.target.value) || e.target.value === "") {
                this.setState({
                    [e.target.name]: e.target.value
                });
            }
        } else if (e.target.name === "nit") {
            let regex = new RegExp("^[a-zA-Z0-9]+$");
            if (regex.test(e.target.value) || e.target.value === "") {
                this.setState({
                    [e.target.name]: e.target.value
                });
            }
        } else {
            this.setState({
                [e.target.name]: e.target.value
            })
        }
    }

    sendForm = () => {
        const { errors, ...sinErrors } = this.state;
        const result = validate(sinErrors);
        this.setState({ errors: result });
        if (!Object.keys(result).length) {
            // formulario validado
            this.setState({
                stateLoadign: true
            })
            const data = {
                tipodoc: this.state.tipoiden,
                nrodocumento: this.state.nit,
                nombres: this.state.nombres,
                apellidos: this.state.apellidos,
                email: this.state.correo,
                asunto: 'PQRSF Asociados Utrahuilca',
                direccion: this.state.direccion,
                nrocelular: this.state.telcel,
                tiposolic: this.state.t_pqrsf,
                msjpqrs: this.state.mensaje,
                tiporesp: this.state.t_respuesta,
                ip: '0',
                configenvio: 2
            }
            PQRSF_(data).then(response => {
                try {
                    this.setState({
                        stateLoadign: false
                    })
                    if (response[0] === "000000") {
                        var checkBox = document.querySelector("#checkboxa");
                        checkBox.checked = false;
                        this.setState({
                            tipoiden: 'DEFAULT',
                            nit: '',
                            nombres: '',
                            apellidos: '',
                            correo: '',
                            direccion: '',
                            telcel: '',
                            t_pqrsf: 'DEFAULT',
                            t_respuesta: 'DEFAULT',
                            mensaje: '',
                            tiporesp: 'DEFAULT',
                            stateBtn: true
                        })

                        Swal.fire({
                            icon: 'success',
                            title: response[1],
                            text: 'Ticket Nro: ' + response[2]
                        })
                    } else {
                        Swal.fire({
                            icon: 'warning',
                            text: response[1]
                        })
                    }
                } catch (error) {
                    console.log(error);
                }
            });
        }
    }

    render() {
        const { errors } = this.state;
        return (
            <React.Fragment>
                <div className="bg-white" style={{ width: '100%' }}>
                    <div className="container text-center px-0">
                        <div className="d-flex pb-3 justify-content-center align-items-center">
                            <p className="texto-contenido mt-3 text-justify px-4">Apreciado Asociado/a Usuario/a: Para nosotros es muy importante contar con su opinión, en pro de mejorar nuestro portafolio de productos y servicios que ofrecemos a nuestra base social y comunidad en general. Usted podrá registrar su petición, queja, reclamo, sugerencia, felicitación o agradecimiento, sobre temas de nuestra competencia, de igual forma navegar y consultar información relacionada a la Cooperativa UTRAHUILCA.</p>
                        </div>
                    </div>
                    <div style={{ background: '#f7f7f7' }}>
                        <div className="container py-5">
                            <div className="row">
                                <div className="col-md-6">
                                    <p className="text-left" style={{ fontSize: '20px', fontWeight: 'bold', color: 'gray' }}>Nombres <b className="text-danger">*</b></p>
                                    <MDBInput maxLength="150" name="nombres" value={this.state.nombres} label="Ingrese su nombre completo" type="text" onChange={this.textInput} />
                                    {errors.nombres && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '75px' }}>{errors.nombres}</strong>}
                                </div>
                                <div className="col-md-6">
                                    <p className="text-left" style={{ fontSize: '20px', fontWeight: 'bold', color: 'gray' }}>Apellidos <b className="text-danger">*</b></p>
                                    <MDBInput maxLength="150" name="apellidos" value={this.state.apellidos} label="Ingrese su apellido completo" type="text" onChange={this.textInput} />
                                    {errors.apellidos && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '75px' }}>{errors.apellidos}</strong>}
                                </div>
                                <div className="col-md-6">
                                    <p className="text-left" style={{ fontSize: '20px', fontWeight: 'bold', color: 'gray' }}>Email <b className="text-danger">*</b></p>
                                    <MDBInput maxLength="150" name="correo" value={this.state.correo} label="Correo electrónico" type="email" onChange={this.textInput} />
                                    {errors.correo && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '75px' }}>{errors.correo}</strong>}
                                </div>
                                <div className="col-md-6">
                                    <p className="text-left" style={{ fontSize: '20px', fontWeight: 'bold', color: 'gray' }}>Tipo de Documento  <b className="text-danger">*</b></p>
                                    <select name="tipoiden" value={this.state.tipoiden} className="form-control" onChange={this.textInput}>
                                        <option value="DEFAULT" disabled>Seleccione...</option>
                                        <option value="C">Cédula de Ciudadania</option>
                                        <option value="E">Cédula de Extranjería</option>
                                        <option value="O">Otro Documento</option>
                                        <option value="P">Pasaporte</option>
                                    </select>
                                    {errors.tipoiden && <strong className="text-danger" style={{ fontSize: '13px' }}>{errors.tipoiden}</strong>}
                                </div>
                                <div className="col-md-6 mt-2">
                                    <p className="text-left" style={{ fontSize: '20px', fontWeight: 'bold', color: 'gray' }}>Número Identificación <b className="text-danger">*</b></p>
                                    <MDBInput maxLength="20" name="nit" value={this.state.nit} label="Ingrese número de identificación" type="text" onChange={this.textInput} />
                                    {errors.nit && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '75px' }}>{errors.nit}</strong>}
                                </div>
                                <div className="col-md-6">
                                    <p className="text-left" style={{ fontSize: '20px', fontWeight: 'bold', color: 'gray' }}>Celular <b className="text-danger">*</b></p>
                                    <MDBInput maxLength="20" validate name="telcel" value={this.state.telcel} label="Ingrese número de celular" type="text" onChange={this.textInput} />
                                    {errors.telcel && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '75px' }}>{errors.telcel}</strong>}
                                </div>
                                <div className="col-md-6">
                                    <p className="text-left" style={{ fontSize: '20px', fontWeight: 'bold', color: 'gray' }}>Dirección <b className="text-danger">*</b></p>
                                    <MDBInput maxLength="50" name="direccion" value={this.state.direccion} label="Ingrese su dirección" type="text" onChange={this.textInput} />
                                    {errors.direccion && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '75px' }}>{errors.direccion}</strong>}
                                </div>
                                <div className="col-md-6">
                                    <p className="text-left" style={{ fontSize: '20px', fontWeight: 'bold', color: 'gray' }}>Tipo de PQRSF <b className="text-danger">*</b></p>
                                    <select name="t_pqrsf" value={this.state.t_pqrsf} className="form-control" onChange={this.textInput}>
                                        <option value="DEFAULT" disabled>Seleccione...</option>
                                        <option value="Queja">Queja</option>
                                        <option value="Reclamo">Reclamo</option>
                                        <option value="Denuncia">Denuncia</option>
                                        <option value="Petición">Petición</option>
                                        <option value="Sugerencia">Sugerencia</option>
                                        <option value="Consulta">Consulta</option>
                                    </select>
                                    {errors.t_pqrsf && <strong className="text-danger" style={{ fontSize: '13px' }}>{errors.t_pqrsf}</strong>}
                                </div>
                                <div className="col-md-12 mt-2">
                                    <p className="text-left pb-3" style={{ fontSize: '20px', fontWeight: 'bold', color: 'gray' }}>Mensaje <b className="text-danger">*</b></p>
                                    <MDBInput name="mensaje" value={this.state.mensaje} label="Escriba su solicitud" type="textarea" rows="5" onChange={this.textInput} />
                                    {errors.mensaje && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', bottom: '17px' }}>{errors.mensaje}</strong>}
                                </div>

                                <div className="col-md-12">
                                    <p className="text-left" style={{ fontSize: '20px', fontWeight: 'bold', color: 'gray' }}>Tipo de Respuesta <b className="text-danger">*</b></p>
                                    <select name="t_respuesta" value={this.state.t_respuesta} className="form-control" onChange={this.textInput}>
                                        <option value="DEFAULT" disabled>Seleccione...</option>
                                        <option value="Correo Electrónico">Correo Electrónico</option>
                                        <option value="Llamadas">Llamadas</option>
                                        <option value="Notificación por medio de una carta a la dirección suministrada">Notificación por medio de una carta a la dirección suministrada</option>
                                    </select>
                                    {errors.t_respuesta && <strong className="text-danger" style={{ fontSize: '13px' }}>{errors.t_respuesta}</strong>}
                                </div>

                                <div className="col-md-12 mt-4">
                                    <p className="text-left" style={{ fontSize: '20px', fontWeight: 'bold', color: 'gray' }}>Terminos y Condiciones</p>
                                    <p className="text-justify">Autorizo a la Cooperativa latinoamericana de ahorro y Crédito UTRAHUILCA con domicilio principal en la Cra 6 # 5-37 Oficina Principal Neiva - Huila , Colombia, telefono: 8728181 Ext:101, en su calidad de responsable del tratamiento de datos personales para recolectar, almacenar, procesar, actualizar, modificar y eliminar los datos suministrados con el fin de realizar la gestión de trámites relacionados con la presente solicitud. En cumplimiento de la Ley 1581 de 2012 y Decreto 1377 de 2013, declaro que he sido informado de manera clara y expresa las finalidades para las cuales se recopilan mis datos, así mismo que conozco el derecho a conocer, actualizar, corregir y suprimir la información y revocar la autorización. Para mayor información puedo consultar la Política de Tratamiento de Datos Personales de la Cooperativa latinoamericana de ahorro y Crédito UTRAHUILCA que se encuentra en la página web. http://www.utrahuilca.coop/</p>
                                    <div className="pt-1 d-flex align-items-center">
                                        <input type="checkbox" id="checkboxa" onClick={() => { this.setState({ checkbox: !this.state.checkbox }) }} /><span className="font-weight-bold mt-0 pt-0">&nbsp; Acepto términos y condiciones </span>
                                    </div>
                                    {errors.checkbox && <strong className="text-danger" style={{ fontSize: '13px' }}>{errors.checkbox}</strong>}
                                    <div className="pt-3 d-flex justify-content-center">
                                        <form onSubmit={this.onSubmit} >
                                            <ReCAPTCHA
                                                ref={this.recaptchaRef}
                                                sitekey="6LfTTPgbAAAAAOKQp-b36jm-sJGpLIKw8cnZTzu-"
                                                onChange={this.validRecat}
                                            />
                                        </form>
                                    </div>
                                </div>
                                <div className="col-md-12 text-center mt-5">
                                    <button className="btn px-5" onClick={this.sendForm} disabled={this.state.stateBtn} style={{ background: '#FA7500', color: 'white', borderRadius: '20px' }}>Enviar Solicitud <MDBIcon far icon="paper-plane" /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.stateLoadign ? (
                    <Loading />
                ) : (<React.Fragment></React.Fragment>)}
            </React.Fragment>
        );
    }
}

export default Seccion2;