import React, { Component } from 'react';
import 'swiper/css/swiper.min.css';
import { Link } from 'react-router-dom';
import { URLImgFront } from '../../config/URLDB';
import Chat from '../Mensaje_Flotante/Chat/Chat';
import Llamanos from '../Mensaje_Flotante/LLamanos/Llamanos';
// import Llamamos from '../Mensaje_Flotante/Nosotros_te_llamamos/Llamamos';

class Seccion2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stateContenido: false,
            stateChat: false,
            stateLlamamos: false,
            stateLlamanos: false
        };
    }

    activarChat = () => {
        this.setState({
            stateContenido: !this.state.stateContenido,
            stateChat: true
        })
    }

    activarLlamamos = () => {
        this.setState({
            stateContenido: !this.state.stateContenido,
            stateLlamamos: true
        })
    }

    activarLlamanos = () => {
        this.setState({
            stateContenido: !this.state.stateContenido,
            stateLlamanos: true
        })
    }

    cerrarChat = () => {
        this.setState({
            stateContenido: !this.state.stateContenido,
            stateChat: false,
            stateLlamamos: false,
            stateLlamanos: false
        })
    }

    render() {
        const { stateChat, stateLlamanos } = this.state;
        return (
            <React.Fragment>
                <div className="bg-white" style={{ width: '100%' }}>
                    <div className="container-fluid text-center px-0">
                        <div className="d-flex pb-3 justify-content-center">
                            <p className="texto-contenido mt-5">En UTRAHUILCA siempre será un gusto de atenderlo.</p>
                        </div>
                        <div className="container">
                            <div className="row pt-3 pb-5 itemsCa d-flex justify-content-center">
                                {/* <div className="col-md-2 item" onClick={this.activarChat}   >
                                    <img style={{ width: '105px' }} src={`${URLImgFront}/assets/img-variadas/utrahuilcaChat.png`} alt="utrahuilcaChat" />
                                    <p>UTRAHUILCA CHAT</p>
                                </div> */}
                                <Link to="/Asesoria" className="col-md-2 item"  >
                                    <img style={{ width: '105px' }} src={`${URLImgFront}/assets/img-variadas/ICONO FORMULARIO DE ASESORÍA.png`} alt="ICONO FORMULARIO DE ASESORÍA" />
                                    <p>FORMULARIO DE ASESORÍA</p>
                                </Link>
                                {/* <div className="col-md-2 item" onClick={this.activarLlamamos}>
                                    <img style={{ width: '105px' }} src={`${URLImgFront}/assets/img-variadas/nosotroslollamamos.png`} alt="nosotrosLlamamos" />
                                    <p>NOSOTROS LO LLAMAMOS</p>
                                </div> */}
                                <div className="col-md-2 item" onClick={this.activarLlamanos}>
                                    <img style={{ width: '105px' }} src={`${URLImgFront}/assets/img-variadas/llamenos.png`} alt="llamenos" />
                                    <p>LLÁMENOS</p>
                                </div>
                                <Link to="/Red_Agencias" className="col-md-2 item">
                                    <img style={{ width: '105px' }} src={`${URLImgFront}/assets/img-variadas/puntosAtencion.png`} alt="puntosAtencion" />
                                    <p>PUNTOS DE ATENCIÓN</p>
                                </Link>
                                <Link to="/PQRSF" className="col-md-2 item">
                                    <img style={{ width: '105px' }} src={`${URLImgFront}/assets/img-variadas/pqrsf.png`} alt="pqrsf" />
                                    <p>PQRSF</p>
                                </Link>
                            </div>
                            <div className="col-md-12 pb-5" style={{ color: 'gray', fontWeight: 'bold' }}>
                                <p className="my-0">HORARIO DE ATENCIÓN PARA CHAT Y LÍNEAS TELEFÓNICAS:</p>
                                <p className="my-0">Lunes a viernes de 7:00 a.m. a 7:00 p.m. Jornada Contínua / Sábados 8:00 a.m. a 3:00 m. Jornada Contínua</p>
                            </div>
                        </div>
                    </div>
                </div>
                {stateChat ? (
                    <Chat cerrarM={this.cerrarChat} />
                ) : (
                    <React.Fragment></React.Fragment>
                )}
                {/* {stateLlamamos ? (
                    <Llamamos cerrarM={this.cerrarChat} />
                ) : (
                    <React.Fragment></React.Fragment>
                )} */}
                {stateLlamanos ? (
                    <Llamanos cerrarM={this.cerrarChat} />
                ) : (
                    <React.Fragment></React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

export default Seccion2;