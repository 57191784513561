import { MDBIcon } from 'mdbreact';
import React, { Component } from 'react';
import { URLImgFront } from '../../../../config/URLDB';

class FileUploader extends Component {
    form = React.createRef();
    state = {
        active: false,
        imageSrc: "",
        imageName: "",
        image: [],
        loaded: false
    };
    constructor(props) {
        super(props);

        this.state = {
            active: false,
            imageSrc: "",
            imageName: "",
            image: [],
            loaded: false
        };

        this.onDragEnter = this.onDragEnter.bind(this);
        this.onDragLeave = this.onDragLeave.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
    }

    onDragEnter(e) {
        this.setState({ active: true });
    }

    onDragLeave(e) {
        this.setState({ active: false });
    }

    onDragOver(e) {
        e.preventDefault();
    }

    onDrop(e) {
        e.preventDefault();
        this.setState({ active: false });
        this.onFileChange(e, e.dataTransfer.files[0]);
    }

    onFileChange(e, files) {
        var file = files || e.target.files[0],
            pattern = /image-*/,
            reader = new FileReader();
        // console.log(file.name);
        if (!file.type.match(pattern)) {
            alert("Formato inválido");
            return;
        }
        // console.log(this);
        this.setState({ loaded: false });

        reader.onload = e => {
            this.setState({
                imageSrc: reader.result,
                loaded: true,
                imageName: file.name
            });
            // console.log(reader.result);
        };
        reader.readAsDataURL(file);
    }

    getFileName() {
        return this.state.imageName;
    }
    getFileObject() {
        return this.refs.input.files[0];
    }

    getFileString() {
        return this.state.imageSrc;
    }

    render() {
        let state = this.state;
        return (
            <form name="form" ref={this.formRef} id="form">
                <label
                    onDragEnter={this.onDragEnter}
                    onDragLeave={this.onDragLeave}
                    onDragOver={this.onDragOver}
                    onDrop={this.onDrop}
                >
                    {this.props.estadoImg ? (
                        <React.Fragment>
                            <img className="img-fluid py-4" style={{ height: '400px' }} src={state.imageSrc} alt={state.imageSrc} /><br />
                            <MDBIcon icon="upload" />&nbsp;
                            <input
                                type="file"
                                accept="image/*"
                                onChange={this.onFileChange}
                                ref="input"
                            />
                        </React.Fragment>

                    ) : (
                        <React.Fragment>
                            <img className="img-fluid py-4" style={{ width: '40px' }} src={`${URLImgFront}/assets${this.props.ruta}`} alt={this.props.rutaModal} /><br />
                            <MDBIcon icon="image" />&nbsp;
                            <input
                                type="file"
                                accept="image/*"
                                onChange={this.onFileChange}
                                ref="input"
                            />
                        </React.Fragment>
                    )}
                </label>
                <p style={{ color: 'gray' }}>Resolución definida: Altura 656px, Formato: JPG</p>
            </form>
        );
    }
}

export default FileUploader;