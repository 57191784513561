import React, { Component } from 'react';
import { URLImgFront } from '../../../config/URLDB';
import { NoticiasEventosDetalles_ } from '../../../services/WebAPI_Utrahuilca';

class Eventos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            infoNoticiasDetalles: [],
            prioridad1: [],
            prioridad2: [],
            sinInfo: true
        };
    }

    async componentDidMount() {
        this.cargarInformacion();
    }

    cargarInformacion = () => {
        const data = {
            tran: 7,
            idnotidet: 0,
            codcoopsejos: 1,
            titulonotidet: 'NA',
            descripnotidet: 'NA',
            urlnotidet: 'NA',
            imgnotidet: 'NA',
            fecinipublinotidet: '1900/01/01',
            fecfinpublinotidet: '1900/01/01',
            idtiponoticia: 0,
            prioridadnotidet: 0,
            estado: 1,
            usucrea: 'NA'
        }
        NoticiasEventosDetalles_(data).then(response => {
            try {
                if (response.data.length > 0) {
                    if (response.data[0].message === "004404") {
                        this.setState({
                            sinInfo: true
                        })
                    } else {
                        var prioridad1 = [];
                        var prioridad2 = [];
                        response.data.map((e) => {
                            if (e.prioridadnotidet === 1) {
                                prioridad1.push(e);
                            } else {
                                prioridad2.push(e);
                            }
                            return [];
                        })
                        this.setState({
                            prioridad1: prioridad1,
                            prioridad2: prioridad2,
                            sinInfo: false
                        })
                    }
                } else { }
            } catch (error) {
                console.log();
            }
        });
    }

    render() {
        return (
            <React.Fragment>
                {this.state.sinInfo ? (<React.Fragment>
                    <div className="container">
                        <div className="alert alert-info">Actualmente no presentamos eventos.</div>
                    </div>
                </React.Fragment>) : (
                    <React.Fragment>
                        <div className="px-3" style={{ background: '#3f8592' }}>
                            <div className="container">
                                {this.state.prioridad1.map((e, i) => {
                                    return [
                                        <div className="row py-5" key={i}>
                                            <div className="col-md-6 px-0 py-0">
                                                <img className="img-fluid" style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={`${URLImgFront}/assets${e.imgnotidet}`} alt={e.imgnotidet} />
                                            </div>
                                            <div className="col-md-6 px-5 bg-white py-4 pb-5">
                                                <p className="text-left pt-2" style={{ fontSize: '15px', color: 'gray' }}>{e.fecinipublinotidet} - {e.fecfinpublinotidet}</p>
                                                <p className="font-weight-bold pt-4" style={{ fontSize: '25px' }}>{e.titulonotidet}</p>
                                                <p>{e.descripnotidet}</p>
                                                {e.urlnotidet === 'N/A' ? (
                                                    <React.Fragment></React.Fragment>
                                                ) : (<React.Fragment>
                                                    <a href={e.urlnotidet} target={'_blank'} rel="noopener noreferrer">
                                                        <p className="px-5 py-1 position-absolute" style={{ background: '#3f8592', color: 'white', fontWeight: 'bold', borderRadius: '20px', cursor: 'pointer' }}>Conozca más </p>
                                                    </a>
                                                </React.Fragment>)}
                                            </div>
                                        </div>
                                    ]
                                })}

                            </div>
                        </div>
                        <div className="container py-5">
                            <div className="row">
                                {this.state.prioridad2.map((e, i) => {
                                    return [
                                        <div className="col-md-6 pl-3 my-3" key={i}>
                                            <div className="row">
                                                <div className="col-md-6 pr-0">
                                                    <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} width="200" className="img-fluid" src={`${URLImgFront}/assets${e.imgnotidet}`} alt={e.imgnotidet} />
                                                </div>
                                                <div className="col-md-6 d-flex justify-content-center align-items-center" style={{ background: '#f7f7f7' }}>
                                                    <div className="px-3">
                                                        <p className="text-left pt-2" style={{ fontSize: '15px', color: 'gray' }}>{e.fecinipublinotidet} - {e.fecfinpublinotidet}</p>
                                                        <p className="text-left font-weight-bold" style={{ fontSize: '25px' }}>{e.titulonotidet}</p>
                                                        <p>{e.descripnotidet}</p>
                                                        {e.urlnotidet === 'N/A' ? (
                                                            <React.Fragment></React.Fragment>
                                                        ) : (<React.Fragment>
                                                            <a href={e.urlnotidet} target={'_blank'} rel="noopener noreferrer">
                                                                <p className="px-5 py-1 text-center" style={{ background: '#3f8592', color: 'white', fontWeight: 'bold', borderRadius: '20px', cursor: 'pointer' }}>Conozca más </p>
                                                            </a>
                                                        </React.Fragment>)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ]
                                })}
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

export default Eventos;