import React, { Component } from 'react';
import '../responsive/Productos.css';
import Footer from '../components/Principal/Footer';
import Header from '../components/Principal/Header';
import MensajeF from '../components/Mensaje_Flotante/MensajeF';
import Seccion1 from '../components/Productos/Seccion1';
import Seccion2 from '../components/Productos/Seccion2';
import Seccion3 from '../components/Productos/Seccion3';
import { PersonasVista_ } from '../services/WebAPI_Utrahuilca';


class Productos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadpage: false,
            idtipoprod: null,
            idtipopers: null,
        };
    }

    componentDidMount() {
        this.cargarProductos();
        setTimeout(() => {
            this.setState({
                loadpage: true
            })
        }, 0.1)
    }

    componentDidUpdate() {
        if (this.state.idtipoprod !== parseInt(localStorage.getItem('idtipoprod')) || this.state.idtipopers !== parseInt(localStorage.getItem('idtipopers'))) {
            this.cargarProductos();
        } else { }
    }

    cargarProductos() {
        const data = {
            tran: 8,
            idvtaprod: 0,
            idtipoprod: parseInt(localStorage.getItem('idtipoprod')),
            idtipopers: parseInt(localStorage.getItem('idtipopers')),
            nombvtaprod: 'NA',
            rutaimgfondprod: 'NA',
            txtvistprod1: 'NA',
            txtvistprod2: 'NA',
            rutaimginferior: 'NA',
            posiciontexto: 0,
            colortexto: 'NA',
            titulo1inferior: 'NA',
            desc1inferior: 'NA',
            titulo2inferior: 'NA',
            desc2inferior: 'NA',
            estado: 1,
            usucrea: 'NA'
        }
        PersonasVista_(data).then(response => {
            try {
                if (response.data.length > 0) {
                    if (!response.data[0].message) {
                        this.setState({
                            idtipoprod: response.data[0].idtipoprod,
                            idtipopers: response.data[0].idtipopers,
                            rutaimgfondprod: response.data[0].rutaimgfondprod,
                            posiciontexto: response.data[0].posiciontexto,
                            colortexto: response.data[0].colortexto,
                            txtvistprod1: response.data[0].txtvistprod1,
                            txtvistprod2: response.data[0].txtvistprod2,
                            rutaimginferior: response.data[0].rutaimginferior,
                            titulo1inferior: response.data[0].titulo1inferior,
                            desc1inferior: response.data[0].desc1inferior,
                            titulo2inferior: response.data[0].titulo2inferior,
                            desc2inferior: response.data[0].desc2inferior,
                        })
                    } else {
                    }
                } else { }
            } catch (error) {
                console.log(error);
            }
        });
    }

    render() {
        return (
            <React.Fragment>
                <Header id={parseInt(localStorage.getItem('idtipoprod'))} />
                <MensajeF />
                {this.state.loadpage ? (
                    <div>
                        <Seccion1
                            rutaimgfondprod={this.state.rutaimgfondprod}
                            posiciontexto={this.state.posiciontexto}
                            colortexto={this.state.colortexto}
                            txtvistprod1={this.state.txtvistprod1}
                            txtvistprod2={this.state.txtvistprod2}
                        />
                        <Seccion2 />
                        <Seccion3
                            rutaimginferior={this.state.rutaimginferior}
                            titulo1inferior={this.state.titulo1inferior}
                            desc1inferior={this.state.desc1inferior}
                            titulo2inferior={this.state.titulo2inferior}
                            desc2inferior={this.state.desc2inferior}
                        />
                    </div>
                ) : (<React.Fragment></React.Fragment>)}
                <Footer />
            </React.Fragment>
        );
    }
}

export default Productos;