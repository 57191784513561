import { MDBIcon } from 'mdbreact';
import React, { Component } from 'react';
import { URLImgFront } from '../../config/URLDB';
import { QuieroAsociarme_ } from '../../services/WebAPI_Utrahuilca';

class Seccion1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stateImg: false,
            stateOri: false
        };
    }

    async componentDidMount() {
        const data = {
            tran: 7,
            idquieroasoc: 0,
            nombasociarme: 'NA',
            rutaimgasociarm: 'NA',
            txtasociarme1: 'NA',
            txtasociarme2: 'NA',
            colortexto: 'NA',
            estado: 1,
            usucrea: 'NA'
        }
        QuieroAsociarme_(data).then(response => {
            try {
                if (response.data.length > 0) {
                    if (response.data.length >= 1) {
                        if (response.data[0].message === "004404") {
                            this.setState({
                                stateImg: false
                            })
                        } else {
                            if (response.data[0].posiciontexto === 1) {
                                this.setState({
                                    left: "5%",
                                    stateOri: true
                                })
                            } else {
                                this.setState({
                                    right: "8%",
                                    stateOri: false
                                })
                            }
                            this.setState({
                                idquieroasoc: response.data[0].idquieroasoc,
                                nombasociarme: response.data[0].nombasociarme,
                                rutaimgasociarm: response.data[0].rutaimgasociarm,
                                txtasociarme1: response.data[0].txtasociarme1,
                                txtasociarme2: response.data[0].txtasociarme2,
                                colortexto: response.data[0].colortexto,
                                stateImg: true
                            });
                        }
                    } else {
                        this.setState({
                            stateImg: false
                        })
                    }
                } else {
                    console.log(response);
                }
            } catch (error) {
                console.log(error);
            }
        });
    }

    render() {
        const { stateImg } = this.state;
        return (
            <React.Fragment>
                <div className="diseño_quiero_asociarme" style={{ width: '100%', paddingTop: '120px' }}>
                    {stateImg ?
                        (
                            <img style={{ width: '100%', height: '100vh', marginBottom: '15px', objectFit: 'cover' }} className="img-fluid" src={`${URLImgFront}/assets${this.state.rutaimgasociarm}`} alt="img1" />
                        ) : (
                            <React.Fragment></React.Fragment>
                        )}
                    <span className="ml-4 pt-4 font-weight-bold" style={{ fontSize: '20px' }}><MDBIcon icon="caret-right" /> Quiero Asociarme</span>
                    <div className="contenido_text_q" style={{ position: 'absolute', bottom: '5%', right: '10%' }}>
                        <p className="texto_1 text-center mb-0" style={{ fontSize: '30px', color: '' + this.state.colortexto + '' }}>{this.state.txtasociarme1}</p>
                        <p className="texto_2 pr-0 mr-0 text-center mt-0" style={{ fontSize: '40px', color: '' + this.state.colortexto + '', fontWeight: 'bold' }}>{this.state.txtasociarme2}</p>
                        {/* <Link to="/Beneficios">
                            <span style={{ fontSize: '30px', position: 'absolute', right: '-280px', bottom: '-70px', borderBottom: '1px solid ' + this.state.colortexto + '', color: '' + this.state.colortexto + '', cursor: 'pointer' }}>Ver Beneficios <MDBIcon icon="caret-right" size="xs" className="pl-4 pt-2 icon" style={{ color: '' + this.state.colortexto + '', position: 'absolute', right: '-20px', bottom: '10px' }} /></span>
                        </Link> */}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Seccion1;