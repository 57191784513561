import { MDBIcon, MDBInput, MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import React, { Component } from 'react';
import { CargueImagenes, ConvenioDetalles_ } from '../../../services/WebAPI_Utrahuilca';
import FileUploaderB from './AsociadosDetalles/FileUploaderB';
import FileUploader from './AsociadosDetalles/FileUploader';
import FileUploaderD from './AsociadosDetalles/FileUploaderD';
import Swal from 'sweetalert2';
import reactCSS from 'reactcss';
import { SketchPicker } from 'react-color';
import { URLImgFront } from '../../../config/URLDB';

var DescripcionVista = [];

class AsociadosDetalles extends Component {
    uploader = React.createRef();
    uploaderIT = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            estadoNuevo: false,
            estadoCambioImg1: true,
            estadoSinInfo: false,
            infoConveniosDetalles: [],
            idconveniodet: null,
            rutaimgconven: '',
            titconvenio1: '',
            descconvenio1: '',
            titconvenio2: '',
            descconvenio2: '',
            estadoCambio: false,
            estado: null,
            estadoVista: false,
            estadoVistaGenerica: false,
            displayColorPicker: false,
            nombreasodet: '',
            nombrelink: '',
            linkvistadet: '',
            color: '',
            posiciontexto: 'DEFAULT',
            DescripcionVistaA: [],
            DescripcionIT: '',
            posiciontextoIT: 'DEFAULT',
            estadoCambioImgIT: true,
            titulo1IT: '',
            titulo2IT: ''
        };
    }

    async componentDidMount() {
        this.cargarInformacion();
    }

    cargarInformacion = () => {
        const data = {
            tran: 4,
            idconveniodet: 0,
            rutaimgconven: 'NA',
            titconvenio1: 'NA',
            descconvenio1: 'NA',
            titconvenio2: 'NA',
            descconvenio2: 'NA',
            estado: 0,
            usucrea: 'NA'
        }
        ConvenioDetalles_(data).then(response => {
            try {
                if (response.data.length > 0) {
                    if (!response.data[0].message) {
                        this.setState({
                            idSeleccionado: response.data[0].idconveniodet
                        })
                        setTimeout(() => {
                            this.setState({
                                infoConveniosDetalles: response.data,
                                rutaimgconven: response.data[0].rutaimgconven,
                                // titconvenio1: response.data[0].titconvenio1,
                                // descconvenio1: response.data[0].descconvenio1,
                                // titconvenio2: response.data[0].titconvenio2,
                                // descconvenio2: response.data[0].descconvenio2,
                                estado: response.data[0].estado,
                                estadoSinInfo: true,
                                estadoImagen: true,
                            })
                        }, 120);
                    } else {
                        this.setState({
                            estadoSinResultados: false,
                            estadoNuevo: true,
                            estadoCambioImg1: true,
                            estadoSinInfo: false
                        })
                    }
                } else { }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    text: 'Ocurrio un error al consumir el servicio, comuniquese con sistemas!'
                })
            }
        });
    }

    textInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    cambioImagen1 = (name, file) => {
        this.setState({
            estadoCambioImg1: true
        });
    }

    cambioImagenIT = (name, file) => {
        this.setState({
            estadoCambioImgIT: true
        });
    }

    cargarImagen = () => {
        var formData = new FormData();
        if (undefined === this.uploader.current) return;
        formData.append("file", this.uploader.current.getFileObject());
        formData.append("rutaImagen", '/Convenios/Convenio Detalles/' + this.uploader.current.getFileName());
        CargueImagenes(formData).then(response => {
            if (response) {
                this.cargarInformacion();
            }
        });
    }

    insertarDatos = () => {
        const data = {
            tran: 1,
            idconveniodet: 0,
            rutaimgconven: '/Convenios/Convenio Detalles/' + this.uploader.current.getFileName(),
            titconvenio1: this.state.titconvenio1,
            descconvenio1: this.state.descconvenio1,
            titconvenio2: this.state.titconvenio2,
            descconvenio2: this.state.descconvenio2,
            estado: 1,
        }
        ConvenioDetalles_(data).then(response => {
            if (response.data.length > 0) {
                if (response.data[0].message === "000000") {
                    this.cargarImagen();
                    Swal.fire({
                        icon: 'success',
                        title: 'Se agrego correctamente.'
                    })
                    this.setState({
                        estadoNuevo: false,
                        idconveniodet: null,
                        rutaimgconven: '',
                        titconvenio1: '',
                        descconvenio1: '',
                        titconvenio2: '',
                        descconvenio2: '',
                        estado: null,
                        estadoImagen: false,
                        displayColorPicker: false,
                        rutafondoImg: '',
                        color: '',
                    })
                    this.cargarInformacion();
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error al insertar.'
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Ocurrio un error!'
                })
            }
        });
    }

    editar = (id) => {
        var seleccionado = [];
        this.state.infoConveniosDetalles.map((e, i) => {
            if (e.idconveniodet === id) {
                seleccionado.push(e)
            }
            return [];
        })
        this.setState({
            idconveniodet: seleccionado[0].idconveniodet,
            titconvenio1: seleccionado[0].titconvenio1,
            descconvenio1: seleccionado[0].descconvenio1,
            titconvenio2: seleccionado[0].titconvenio2,
            descconvenio2: seleccionado[0].descconvenio2,
            rutaimgconven: seleccionado[0].rutaimgconven,
            estadoEditar: true
        })
    }

    cambio = (name, file) => {
        this.setState({
            estadoCambio: true
        });
    }

    actualizarDatos = (id) => {
        var ruta = '';
        var capNombre = this.uploader.current.getFileName();
        if (capNombre === undefined || capNombre === null) {
            ruta = this.state.rutaimgconven;
        } else {
            ruta = '/Convenios/Convenio Detalles/' + this.uploader.current.getFileName();
        }
        const data = {
            tran: 1,
            idconveniodet: id,
            rutaimgconven: ruta,
            titconvenio1: this.state.titconvenio1,
            descconvenio1: this.state.descconvenio1,
            titconvenio2: this.state.titconvenio2,
            descconvenio2: this.state.descconvenio2,
            estado: 1,
        }
        ConvenioDetalles_(data).then(response => {
            if (response.data.length > 0) {
                if (response.data[0].message === "000000") {
                    this.cargarImagen();
                    this.setState({
                        estadoCambio: false,
                        estadoEditar: false,
                        rutaimgconven: '',
                        titconvenio1: '',
                        descconvenio1: '',
                        titconvenio2: '',
                        descconvenio2: ''
                    })
                    Swal.fire({
                        icon: 'success',
                        title: 'Se actualizo satisfactoriamente'
                    })
                    this.cargarInformacion();
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Ocurrio un error al actualizar'
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Ocurrio un error!'
                })
            }
        });
    }

    activar = (id) => {
        const data = {
            tran: 6,
            idconveniodet: id,
            rutaimgconven: 'NA',
            titconvenio1: 'NA',
            descconvenio1: 'NA',
            titconvenio2: 'NA',
            descconvenio2: 'NA',
            estado: 1
        }
        ConvenioDetalles_(data).then(response => {
            if (response) {
                this.setState({
                    estadoS: false
                })
                Swal.fire({
                    icon: 'success',
                    title: 'Se activo satisfactoriamente'
                });
                this.cargarInformacion();
            }
        });
    }

    inactivar = (id) => {
        const data = {
            tran: 6,
            idconveniodet: id,
            rutaimgconven: 'NA',
            titconvenio1: 'NA',
            descconvenio1: 'NA',
            titconvenio2: 'NA',
            descconvenio2: 'NA',
            estado: 0
        }
        ConvenioDetalles_(data).then(response => {
            if (response) {
                this.setState({
                    estadoS: false
                })
                Swal.fire({
                    icon: 'success',
                    title: 'Se inactivo satisfactoriamente'
                });
                this.cargarInformacion();
            }
        });
    }

    cambioEstadoVista = (e) => {
        if (e.target.value === "1") {
            this.setState({
                estadoVista: true,
                estadoVistaGenerica: true
            })
        } else if (e.target.value === "2") {
            this.setState({
                estadoVista: true,
                estadoVistaGenerica: false
            })
        }
    }

    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    };

    handleClose = () => {
        this.setState({ displayColorPicker: false })
    };

    handleChange = (color) => {
        this.setState({ color: color.hex })
    };

    agregarDescripcionV = () => {
        DescripcionVista.push({ ruta: '/Asociados/Asociados Detalles/' + this.uploaderIT.current.getFileName(), DescripcionIT: this.state.DescripcionIT, posiciontextoIT: this.state.posiciontextoIT })
        this.setState({
            DescripcionVistaA: DescripcionVista,
            DescripcionIT: '',
            posiciontextoIT: 'DEFAULT',
            estadoCambioImgIT: true
        })
    }

    eliminarDescripcionV = (data) => {
        var indice = DescripcionVista.indexOf(data);
        if (indice !== -1) {
            DescripcionVista.splice(indice, 1);
            this.setState({
                DescripcionVistaA: DescripcionVista
            })
        }
    }

    render() {
        const { estadoNuevo, estadoCambioImg1, estadoCambio, estadoSinInfo, posiciontexto, estadoEditar, nombreasodet, nombrelink, linkvistadet,
            idconveniodet, infoConveniosDetalles, estadoVista, estadoVistaGenerica, DescripcionVistaA, estadoCambioImgIT } = this.state;
        const styles = reactCSS({
            'default': {
                color: {
                    width: '36px',
                    height: '14px',
                    borderRadius: '2px',
                    background: this.state.color,
                    // background: `rgba(${this.state.color.r}, ${this.state.color.g}, ${this.state.color.b}, ${this.state.color.a})`,
                },
                swatch: {
                    padding: '5px',
                    background: '#fff',
                    borderRadius: '1px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                    display: 'inline-block',
                    cursor: 'pointer',
                },
                popover: {
                    position: 'absolute',
                    zIndex: '2',
                },
                cover: {
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                },
            },
        });
        return (
            <React.Fragment>
                <p className="font-weight-bold">Detalles Asociados</p>
                <hr />
                {estadoSinInfo ? (
                    <React.Fragment>
                        <div className="plus_add_agencia align-items-center d-flex" onClick={() => { this.setState({ estadoNuevo: !this.state.estadoNuevo }) }}>
                            {estadoNuevo ? <p>Cancelar &nbsp;<MDBIcon icon="times" size="lg" /></p> : <p>Agregar Nuevo &nbsp;<MDBIcon icon="plus-circle" size="lg" /></p>}
                        </div>
                    </React.Fragment>
                ) : (
                    <React.Fragment></React.Fragment>
                )}
                {estadoNuevo ? (
                    <React.Fragment>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 my-2 text-center">
                                    <FileUploaderB ref={this.uploader} estadoImg={estadoCambioImg1} FCambiar={this.cambioImagen1} />
                                </div>
                                <div className="col-md-4 my-2">
                                    <MDBInput label="Titulo" name="nombreasodet" type="text" value={nombreasodet} onChange={this.textInput} />
                                </div>
                                <div className="col-md-4 my-2">
                                    <MDBInput label="Nombre Link" name="nombrelink" type="text" value={nombrelink} onChange={this.textInput} />
                                </div>
                                <div className="col-md-4 my-2">
                                    <label>Que tipo de vista desea?</label>
                                    <select name="cambioEstadoVista" className="form-control" defaultValue={'DEFAULT'} onChange={this.cambioEstadoVista}>
                                        <option value="DEFAULT" disabled>Seleccione...</option>
                                        <option value="1">Generica</option>
                                        <option value="2">Establecida</option>
                                    </select>
                                </div>
                                {estadoVista ? (
                                    <React.Fragment>
                                        {estadoVistaGenerica ? (
                                            <React.Fragment>
                                                <div className="col-md-12 mt-5 text-center">
                                                    <h4 className="font-weight-bold">Ingrese la información de la vista dinámica</h4>
                                                    <hr />
                                                </div>
                                                <div className="col-md-12 my-2 text-center">
                                                    <FileUploaderB ref={this.uploader} estadoImg={estadoCambioImg1} FCambiar={this.cambioImagen1} />
                                                </div>
                                                <div className="col-md-6 my-2">
                                                    <MDBInput label="Titulo 1" name="titulo1IT" type="text" value={this.state.titulo1IT} onChange={this.textInput} />
                                                </div>
                                                <div className="col-md-6 my-2">
                                                    <MDBInput label="Titulo 2" name="titulo2IT" type="text" value={this.state.titulo2IT} onChange={this.textInput} />
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    <label>Posición Texto</label>
                                                    <select name="posiciontexto" className="form-control" defaultValue={posiciontexto} onChange={this.textInput}>
                                                        <option value="DEFAULT" disabled>Seleccione..</option>
                                                        <option value="1">Izquierda</option>
                                                        <option value="0">Derecha</option>
                                                    </select>
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    <p>Color Texto</p>
                                                    <div style={styles.swatch} onClick={this.handleClick}>
                                                        <div style={styles.color} />
                                                    </div>
                                                    {this.state.displayColorPicker ? <div style={styles.popover}>
                                                        <div style={styles.cover} onClick={this.handleClose} />
                                                        <SketchPicker color={this.state.color} onChange={this.handleChange} />
                                                    </div> : null}
                                                </div>
                                                <div className="col-md-12 pt-5">
                                                    <h6 className="font-weight-bold">Descripción de la vista</h6>
                                                    <span>Indicar si desea el (texto o imagen) a la derecha e izquierda.</span>
                                                    <hr />
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            {DescripcionVistaA.map((e, i) => {
                                                                var posicionTexto = '';
                                                                if (e.posiciontextoIT === "0") {
                                                                    posicionTexto = 'Imagen Izquierda - Texto Derecha';
                                                                } else {
                                                                    posicionTexto = 'Imagen Derecha - Texto Izquierda';
                                                                }
                                                                return [
                                                                    <React.Fragment key={i}>
                                                                        <div className="row border">
                                                                            <div className="col-md-4 d-flex align-items-center">
                                                                                <div className="text-center">
                                                                                    <label className="font-weight-bold">Ruta Imagen</label>
                                                                                    <p>{e.ruta}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4 mt-2">
                                                                                <MDBInput label="Descripción" value={e.DescripcionIT} type="textarea" rows="5" disabled />
                                                                            </div>
                                                                            <div className="col-md-4 d-flex align-items-center">
                                                                                <div className="text-center">
                                                                                    <label className="font-weight-bold">Posición Texto - Imagen</label>
                                                                                    <p>{posicionTexto}</p>
                                                                                </div>
                                                                            </div>
                                                                            <MDBIcon onClick={() => { this.eliminarDescripcionV(e) }} icon="trash" className="text-danger" size="lg" style={{ position: 'absolute', right: '30px', marginTop: '70px', cursor: 'pointer' }} />
                                                                        </div>
                                                                    </React.Fragment>
                                                                ]
                                                            })}
                                                        </div>
                                                        <div className="col-md-4 my-2 text-center">
                                                            <FileUploaderD ref={this.uploaderIT} estadoImg={estadoCambioImgIT} FCambiar={this.cambioImagenIT} />
                                                        </div>
                                                        <div className="col-md-4 my-2">
                                                            <MDBInput label="Descripción" name="DescripcionIT" type="textarea" rows="10" value={this.state.DescripcionIT} onChange={this.textInput} />
                                                        </div>
                                                        <div className="col-md-4 mt-3">
                                                            <label className="font-weight-bold">Posición Texto - Imagen</label>
                                                            <select name="posiciontextoIT" className="form-control" defaultValue={this.state.posiciontextoIT} onChange={this.textInput}>
                                                                <option value="DEFAULT" disabled>Seleccione..</option>
                                                                <option value="0">Imagen Izquierda - Texto Derecha</option>
                                                                <option value="1">Imagen Derecha - Texto Izquierda</option>
                                                            </select>
                                                        </div>
                                                        <div className="d-flex align-items-center" onClick={this.agregarDescripcionV} style={{ cursor: 'pointer', position: 'absolute', bottom: '0px', right: '-40px', zIndex: '999' }} >
                                                            <MDBIcon icon="plus-circle" size="2x" /><h6 className="font-weight-bold mt-2">&nbsp;Adicionar</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment>
                                                <div className="col-md-4 my-2">
                                                    <MDBInput label="Escriba la URL" name="linkvistadet" type="text" value={linkvistadet} onChange={this.textInput} />
                                                </div>
                                            </React.Fragment>
                                        )}
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment></React.Fragment>
                                )}
                            </div>
                        </div>
                        <div className="pt-2 mt-5 modal-footer w-100">
                            <button type="button" className="btn btn-primary px-5" onClick={this.insertarDatos}>Crear Producto &nbsp; <MDBIcon icon="save" /></button>
                        </div>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className="container-fluid">
                            <div className="table-responsive text-center w-100">
                                <MDBTable>
                                    <MDBTableHead>
                                        <tr className="font-weight-bold">
                                            <td>Imagen</td>
                                            <td>Titulo 1</td>
                                            <td>Descripción 1</td>
                                            <td>Titulo 2</td>
                                            <td>Descripción 2</td>
                                            {estadoEditar ?
                                                (
                                                    <React.Fragment>
                                                        <td>Guardar</td>
                                                        <td>Cancelar</td>
                                                    </React.Fragment>
                                                ) : (
                                                    <React.Fragment>
                                                        <td>Editar</td>
                                                        <td>Inactivar</td>
                                                    </React.Fragment>
                                                )}
                                        </tr>
                                    </MDBTableHead>
                                    <MDBTableBody>
                                        {estadoEditar ?
                                            (
                                                <React.Fragment>
                                                    <tr>
                                                        <td>
                                                            <FileUploader ref={this.uploader} estadoImg={estadoCambio} rutaModal={this.state.rutaimgconven} FCambiar={this.cambio} />
                                                        </td>
                                                        <td className="w-25"><input className="form-control" type="text" name="titconvenio1" value={this.state.titconvenio1} onChange={this.textInput} /></td>
                                                        <td className="w-25"><input className="form-control" type="text" name="descconvenio1" value={this.state.descconvenio1} onChange={this.textInput} /></td>
                                                        <td className="w-25"><input className="form-control" type="text" name="titconvenio2" value={this.state.titconvenio2} onChange={this.textInput} /></td>
                                                        <td className="w-25"><input className="form-control" type="text" name="descconvenio2" value={this.state.descconvenio2} onChange={this.textInput} /></td>
                                                        <td className="text-center" onClick={() => { this.actualizarDatos(idconveniodet) }}><MDBIcon icon="save" size="2x" style={{ color: 'blue', cursor: 'pointer' }} /></td>
                                                        <td className="text-center" onClick={() => { this.setState({ estadoEditar: !this.state.estadoEditar }) }}><MDBIcon icon="times" size="2x" style={{ color: 'red', cursor: 'pointer' }} /></td>
                                                    </tr>
                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment>
                                                    {infoConveniosDetalles.map((e, i) => {
                                                        let view;
                                                        if (e.estado === 1) {
                                                            view = <MDBIcon onClick={() => { this.inactivar(e.idconveniodet) }} icon="eye" size="2x" style={{ cursor: 'pointer' }} />
                                                        } else {
                                                            view = <MDBIcon onClick={() => { this.activar(e.idconveniodet) }} icon="eye-slash" size="2x" style={{ cursor: 'pointer' }} />
                                                        }
                                                        return [
                                                            <React.Fragment key={i}>
                                                                <tr>
                                                                    <td><img width="180" src={`${URLImgFront}/assets${e.rutaimgconven}`} alt={e.rutaimgconven} /></td>
                                                                    <td>{e.titconvenio1}</td>
                                                                    <td>{e.descconvenio1}</td>
                                                                    <td>{e.titconvenio2}</td>
                                                                    <td>{e.descconvenio2}</td>
                                                                    <td className="text-center" onClick={() => { this.editar(e.idconveniodet) }}><MDBIcon icon="edit" size="2x" style={{ color: 'green', cursor: 'pointer' }} /></td>
                                                                    <td className="text-center">{view}</td>
                                                                </tr>
                                                            </React.Fragment>
                                                        ]
                                                    })}
                                                </React.Fragment>
                                            )}
                                    </MDBTableBody>
                                </MDBTable>
                            </div>
                        </div>
                    </React.Fragment>
                )}

            </React.Fragment>
        );
    }
}

export default AsociadosDetalles;