import React, { Component } from 'react';
import 'swiper/css/swiper.min.css';
import { URLImgFront } from '../../config/URLDB';
import BAhorro from './Beneficios Items/BAhorro';
import BAsociado from './Beneficios Items/BAsociado';
import BCredito from './Beneficios Items/BCredito';

class Seccion2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stateAhorro: true,
            stateAsociados: false,
            stateCredito: false,
        };
    }

    cambioVista = (id) => {
        if (id === 1) {
            this.setState({
                stateAhorro: false,
                stateAsociados: true,
                stateCredito: false,
            })
        } else if (id === 2) {
            this.setState({
                stateAhorro: true,
                stateAsociados: false,
                stateCredito: false,
            })
        } else {
            this.setState({
                stateAhorro: false,
                stateAsociados: false,
                stateCredito: true,
            })
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="bg-white" style={{ width: '100%' }}>
                    <div className="container text-center py-5">
                        <p className="texto-contenido font-weight-bold" style={{ fontSize: '30px' }}>"Nos importa su bienestar y el de su núcleo familiar"</p>
                        <div className="row py-5 px-5">
                            <div onClick={() => { this.cambioVista(1) }} className="col-md-4 pb-5 pt-4" style={this.state.stateAsociados ? { background: '#3f8592', color: '#fff', cursor: 'pointer' } : { background: '#f7f7f7', color: '#000000', cursor: 'pointer' }}>
                                <img width="125" className="img-fluid" src={`${URLImgFront}/assets/iconos/BENEFICIOS POR SER ASOCIADOS.png`} alt="BENEFICIOS POR SER ASOCIADOS" />
                                <p className="pt-4">Beneficio por ser asociado</p>
                            </div>
                            <div onClick={() => { this.cambioVista(2) }} className="col-md-4 pb-5 pt-4" style={this.state.stateAhorro ? { background: '#3f8592', color: '#fff', cursor: 'pointer' } : { background: '#f7f7f7', color: '#000000', cursor: 'pointer' }}>
                                <img width="125" className="img-fluid" src={`${URLImgFront}/assets/iconos/BENEFICIOS AHORRO.png`} alt="BENEFICIOS AHORRO" />
                                <p className="pt-4">Beneficios de ahorro</p>
                            </div>
                            <div onClick={() => { this.cambioVista(3) }} className="col-md-4 pb-5 pt-4" style={this.state.stateCredito ? { background: '#3f8592', color: '#fff', cursor: 'pointer' } : { background: '#f7f7f7', color: '#000000', cursor: 'pointer' }}>
                                <img width="125" className="img-fluid" src={`${URLImgFront}/assets/iconos/BENEFICIOS CRÉDITO.png`} alt="BENEFICIOS CRÉDITO" />
                                <p className="pt-4">Beneficios de crédito</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="py-4 pb-5 mt-5" style={{ background: '#f7f7f7' }}>
                    <div className="container px-5">
                        {this.state.stateAhorro ? (<BAhorro />) : (<React.Fragment></React.Fragment>)}
                        {this.state.stateAsociados ? (<BAsociado />) : (<React.Fragment></React.Fragment>)}
                        {this.state.stateCredito ? (<BCredito />) : (<React.Fragment></React.Fragment>)}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Seccion2;