import React, { Component } from 'react';
import { CargueImagenes, NoticiasDetallesEspecificas_, NoticiasDetallesEspecificasAdmin_, NoticiasDetallesEspecificasPOST_ } from '../../../../../services/WebAPI_Utrahuilca';
import FileUploaderB from '../FileUploader/FileUploaderB';
import FileUploader from '../FileUploader/FileUploader';
import Swal from 'sweetalert2';
import { MDBIcon, MDBInput, MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import { URLImgFront } from '../../../../../config/URLDB';

const validate = values => {
    const errors = {}
    if (values.tipourlnotiespecif === "DEFAULT") {
        errors.tipourlnotiespecif = 'campo obligatorio'
    }
    if (!values.txtnotiespecif1) {
        errors.txtnotiespecif1 = 'campo obligatorio'
    }
    if (!values.txtnotiespecif2) {
        errors.txtnotiespecif2 = 'campo obligatorio'
    }
    return errors;
};

class Detalles extends Component {
    uploader = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            tipourlnotiespecif: 'DEFAULT',
            infoNoticiasDetallesEspe: [],
            insertarImagen: false,
            editinsertarImagen: false,

            edittipourlnotiespecif: 'DEFAULT',
        };
    }

    async componentDidMount() {
        this.cargarInformacion();
    }

    cargarInformacion = () => {
        const data = {
            tran: 5,
            idnotiespecif: 0,
            idnotidet: parseInt(this.props.idDetallesNoticias),
            ordennotiespecif: 0,
            urlnotiespecif: 'NA',
            txtnotiespecif1: 'NA',
            txtnotiespecif2: 'NA',
            txtnotiespecif3: 'NA',
            txtnotiespecif4: 'NA',
            tipourlnotiespecif: 0,
            estadonotiespecif: 0,
            usucrea: 'NA'
        }
        NoticiasDetallesEspecificas_(data).then(response => {
            try {
                if (response.data.length > 0) {
                    if (!response.data[0].message) {
                        this.setState({
                            idSeleccionado: response.data[0].idnotiespecif
                        })
                        setTimeout(() => {
                            this.setState({
                                infoNoticiasDetallesEspe: response.data
                            })
                        }, 120);
                    } else { }
                } else { }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    text: 'Ocurrio un error al consumir el servicio, comuniquese con sistemas!'
                })
            }
        });
    }

    textInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    cargarImagen = () => {
        var formData = new FormData();
        if (undefined === this.uploader.current) return;
        formData.append("file", this.uploader.current.getFileObject());
        formData.append("rutaImagen", '/Coopsejos/Noticias Detalles/Detalles/' + this.uploader.current.getFileName());
        CargueImagenes(formData).then(response => {
            if (response) {
            }
        });
    }

    SendData = () => {
        const { errors, ...sinErrors } = this.state;
        const result = validate(sinErrors);
        this.setState({ errors: result });
        if (!Object.keys(result).length) {
            var ruta = '';
            if (this.state.tipourlnotiespecif === "3" || this.state.tipourlnotiespecif === 3) {
                ruta = 'N/A';
            } else {
                if (this.state.tipourlnotiespecif === "2") {
                    ruta = this.state.urlnotiespecif;
                } else {
                    ruta = '/Coopsejos/Noticias Detalles/Detalles/' + this.uploader.current.getFileName();
                    this.setState({
                        insertarImagen: true
                    })
                }
            }
            const data = {
                idnotiespecif: 0,
                idnotidet: parseInt(this.props.idDetallesNoticias),
                ordennotiespecif: parseInt(this.state.ordennotiespecif),
                urlnotiespecif: ruta,
                txtnotiespecif1: this.state.txtnotiespecif1,
                txtnotiespecif2: this.state.txtnotiespecif2,
                txtnotiespecif3: this.state.txtnotiespecif3,
                txtnotiespecif4: this.state.txtnotiespecif4,
                tipourlnotiespecif: parseInt(this.state.tipourlnotiespecif),
                estadonotiespecif: 1,
                usucrea: sessionStorage.getItem('idusuario')
            }
            NoticiasDetallesEspecificasPOST_(data).then(response => {
                try {
                    if (response.data.length > 0) {
                        if (response.data[0].message === "000000") {
                            if (this.state.insertarImagen) {
                                this.cargarImagen();
                            }
                            Swal.fire({
                                icon: 'success',
                                title: 'Se agrego correctamente.'
                            })
                            this.setState({
                                idnotidet: null,
                                ordennotiespecif: '',
                                urlnotiespecif: '',
                                txtnotiespecif1: '',
                                txtnotiespecif2: '',
                                txtnotiespecif3: '',
                                txtnotiespecif4: '',
                                tipourlnotiespecif: "DEFAULT",
                                estadonotiespecif: null,
                            })
                            this.cargarInformacion();
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Error al insertar.'
                            })
                        }
                    } else { }
                } catch (error) {
                    Swal.fire({
                        icon: 'error',
                        text: 'Ocurrio un error al consumir el servicio, comuniquese con sistemas!'
                    })
                }
            });
        }
    }

    editar = (id) => {
        var seleccionado = [];
        this.state.infoNoticiasDetallesEspe.map((e, i) => {
            if (e.idnotiespecif === id) {
                seleccionado.push(e)
            }
            return [];
        })
        this.setState({
            idnotiespecif: seleccionado[0].idnotiespecif,
            idnotidet: seleccionado[0].idnotidet,
            editordennotiespecif: seleccionado[0].ordennotiespecif,
            editurlnotiespecif: seleccionado[0].urlnotiespecif,
            edittxtnotiespecif1: seleccionado[0].txtnotiespecif1,
            edittxtnotiespecif2: seleccionado[0].txtnotiespecif2,
            edittxtnotiespecif3: seleccionado[0].txtnotiespecif3,
            edittxtnotiespecif4: seleccionado[0].txtnotiespecif4,
            edittipourlnotiespecif: seleccionado[0].tipourlnotiespecif,
            estadoEditar: true
        })
    }

    cambio = (name, file) => {
        this.setState({
            estadoCambio: true
        });
    }

    actualizarDatos = (id) => {
        var ruta = '';
        if (this.state.edittipourlnotiespecif === "3" || this.state.edittipourlnotiespecif === 3) {
            ruta = 'N/A';
        } else {
            if (this.state.edittipourlnotiespecif === "2" || this.state.edittipourlnotiespecif === 2) {
                ruta = this.state.editurlnotiespecif;
            } else {
                var capNombre = this.uploader.current.getFileName();
                if (capNombre === undefined || capNombre === null) {
                    ruta = this.state.editurlnotiespecif;
                } else {
                    ruta = '/Coopsejos/Noticias Detalles/Detalles/' + this.uploader.current.getFileName();
                    this.setState({
                        editinsertarImagen: true
                    })
                }
            }
        }
        const data = {
            idnotiespecif: id,
            idnotidet: parseInt(this.state.idnotidet),
            ordennotiespecif: parseInt(this.state.editordennotiespecif),
            urlnotiespecif: ruta,
            txtnotiespecif1: this.state.edittxtnotiespecif1,
            txtnotiespecif2: this.state.edittxtnotiespecif2,
            txtnotiespecif3: this.state.edittxtnotiespecif3,
            txtnotiespecif4: this.state.edittxtnotiespecif4,
            tipourlnotiespecif: parseInt(this.state.edittipourlnotiespecif),
            estadonotiespecif: 1,
            usucrea: sessionStorage.getItem('idusuario')
        }
        NoticiasDetallesEspecificasPOST_(data).then(response => {
            if (response.data.length > 0) {
                if (response.data[0].message === "000000") {
                    if (this.state.editinsertarImagen) {
                        this.cargarImagen();
                    }
                    this.setState({
                        estadoCambio: false,
                        estadoEditar: false,
                        editordennotiespecif: '',
                        edittxtnotiespecif1: '',
                        edittxtnotiespecif2: '',
                        edittxtnotiespecif3: '',
                        edittxtnotiespecif4: '',
                        edittipourlnotiespecif: 'DEFAULT',
                    })
                    Swal.fire({
                        icon: 'success',
                        title: 'Se actualizo satisfactoriamente'
                    })
                    this.cargarInformacion();
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Ocurrio un error al actualizar'
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Ocurrio un error!'
                })
            }
        });
    }

    activar = (id) => {
        const data = {
            idnotiespecif: id,
            estadonotiespecif: 1,
            usucrea: sessionStorage.getItem('idusuario')
        }
        NoticiasDetallesEspecificasAdmin_(data).then(response => {
            if (response) {
                this.setState({
                    estadoS: false
                })
                Swal.fire({
                    icon: 'success',
                    title: 'Se activo satisfactoriamente'
                });
                this.cargarInformacion();
            }
        });
    }

    inactivar = (id) => {
        const data = {
            idnotiespecif: id,
            estadonotiespecif: 0,
            usucrea: sessionStorage.getItem('idusuario')
        }
        NoticiasDetallesEspecificasAdmin_(data).then(response => {
            if (response) {
                this.setState({
                    estadoS: false
                })
                Swal.fire({
                    icon: 'success',
                    title: 'Se inactivo satisfactoriamente'
                });
                this.cargarInformacion();
            }
        });
    }

    render() {
        const { errors } = this.state;
        return (
            <React.Fragment>
                <div>
                    <div className="row py-5">
                        <div className="col-md-12">
                            <p>Digite los campos que detallan la noticia.</p>
                            <hr />
                        </div>
                        <div className="col-md-6 my-2">
                            <label>Imagen / Video</label>
                            <select name="tipourlnotiespecif" className="form-control" value={this.state.tipourlnotiespecif} onChange={this.textInput}>
                                <option value="DEFAULT" disabled>Seleccione...</option>
                                <option value="1">Imagen</option>
                                <option value="2">Video</option>
                                <option value="3">No aplica</option>
                            </select>
                            {errors.tipourlnotiespecif && <strong className="text-danger" style={{ fontSize: '13px' }}>{errors.tipourlnotiespecif}</strong>}
                        </div>
                        {this.state.tipourlnotiespecif === "DEFAULT" ? (<React.Fragment></React.Fragment>) : (
                            <React.Fragment>
                                {this.state.tipourlnotiespecif === "1" ?
                                    (
                                        <React.Fragment>
                                            <div className="col-md-6">
                                                <FileUploaderB ref={this.uploader} estadoImg={true} FCambiar={this.cambioImagen1} />
                                            </div>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            {this.state.tipourlnotiespecif === "2" ?
                                                (
                                                    <React.Fragment>
                                                        <div className="col-md-6">
                                                            <label>URL Video</label>
                                                            <MDBInput label="Digite el ID del video de youtube" name="urlnotiespecif" type="text" value={this.state.urlnotiespecif} onChange={this.textInput} />
                                                        </div>
                                                    </React.Fragment>
                                                ) : (
                                                    <React.Fragment></React.Fragment>
                                                )}
                                        </React.Fragment>
                                    )}

                            </React.Fragment>
                        )}
                        <div className="col-md-6 my-2">
                            <label>Posicion</label>
                            <MDBInput label="Enumere la posición" name="ordennotiespecif" type="number" value={this.state.ordennotiespecif} onChange={this.textInput} />
                            {errors.ordennotiespecif && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '80px' }}>{errors.ordennotiespecif}</strong>}
                        </div>
                        <div className="col-md-6 my-2">
                            <label>Descripcion 1</label>
                            <MDBInput label="Digite parrafo 1" name="txtnotiespecif1" type="textarea" rows="7" value={this.state.txtnotiespecif1} onChange={this.textInput} />
                            {errors.txtnotiespecif1 && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '80px' }}>{errors.txtnotiespecif1}</strong>}
                        </div>
                        <div className="col-md-6 my-2">
                            <label>Descripcion 2</label>
                            <MDBInput label="Digite parrafo 2" name="txtnotiespecif2" type="textarea" rows="7" value={this.state.txtnotiespecif2} onChange={this.textInput} />
                            {errors.txtnotiespecif2 && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '80px' }}>{errors.txtnotiespecif2}</strong>}
                        </div>
                        <div className="col-md-6 my-2">
                            <label>Descripcion 3</label>
                            <MDBInput label="Digite parrafo 3" name="txtnotiespecif3" type="textarea" rows="7" value={this.state.txtnotiespecif3} onChange={this.textInput} />
                        </div>
                        <div className="col-md-6 my-2">
                            <label>Descripcion 4</label>
                            <MDBInput label="Digite parrafo 4" name="txtnotiespecif4" type="textarea" rows="7" value={this.state.txtnotiespecif4} onChange={this.textInput} />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button onClick={this.SendData} className="btn btn-info">Agregar</button>
                    </div>
                </div>
                <div className="container-fluid py-5">
                    <div className="table-responsive text-center w-100">
                        <MDBTable>
                            <MDBTableHead>
                                <tr className="font-weight-bold">
                                    <td>Imagen / URL Video</td>
                                    {this.state.edittipourlnotiespecif !== "DEFAULT" ?
                                        (
                                            <React.Fragment>
                                                <td>Ingrese Nuevo</td>
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment></React.Fragment>
                                        )}
                                    <td>Posicion Vista</td>
                                    <td>Descripción 1</td>
                                    <td>Descripción 2</td>
                                    <td>Descripción 3</td>
                                    <td>Descripción 4</td>
                                    {this.state.estadoEditar ?
                                        (
                                            <React.Fragment>
                                                <td>Guardar</td>
                                                <td>Cancelar</td>
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment>
                                                <td>Editar</td>
                                                <td>Inactivar</td>
                                            </React.Fragment>
                                        )}
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                                {this.state.estadoEditar ?
                                    (
                                        <React.Fragment>
                                            <tr>
                                                <td>
                                                    <select name="edittipourlnotiespecif" className="form-control" value={this.state.edittipourlnotiespecif} onChange={this.textInput}>
                                                        <option value="DEFAULT" disabled>Seleccione...</option>
                                                        <option value="1">Imagen</option>
                                                        <option value="2">Video</option>
                                                        <option value="3">No aplica</option>
                                                    </select>
                                                </td>
                                                {this.state.edittipourlnotiespecif === "DEFAULT" ? (<React.Fragment></React.Fragment>) : (
                                                    <React.Fragment>
                                                        {this.state.edittipourlnotiespecif === "1" || this.state.edittipourlnotiespecif === 1 ?
                                                            (
                                                                <React.Fragment>
                                                                    <td>
                                                                        <FileUploader ref={this.uploader} estadoImg={this.state.estadoCambio} ruta={this.state.editurlnotiespecif} FCambiar={this.cambio} />
                                                                    </td>
                                                                </React.Fragment>
                                                            ) : (
                                                                <React.Fragment>
                                                                    {this.state.edittipourlnotiespecif === "2" || this.state.edittipourlnotiespecif === 2 ?
                                                                        (
                                                                            <React.Fragment>
                                                                                <td className="w-25">
                                                                                    <MDBInput label="Digite el ID del video de youtube" name="editurlnotiespecif" type="text" value={this.state.editurlnotiespecif} onChange={this.textInput} />
                                                                                </td>
                                                                            </React.Fragment>
                                                                        ) : (
                                                                            <React.Fragment>
                                                                                <td>
                                                                                </td>
                                                                            </React.Fragment>
                                                                        )}
                                                                </React.Fragment>
                                                            )}
                                                    </React.Fragment>
                                                )}
                                                <td><textarea className="form-control" type="textarea" rows="5" name="editordennotiespecif" value={this.state.editordennotiespecif} onChange={this.textInput} /></td>
                                                <td><textarea className="form-control" type="textarea" rows="10" name="edittxtnotiespecif1" value={this.state.edittxtnotiespecif1} onChange={this.textInput} /></td>
                                                <td><textarea className="form-control" type="textarea" rows="10" name="edittxtnotiespecif2" value={this.state.edittxtnotiespecif2} onChange={this.textInput} /></td>
                                                <td><textarea className="form-control" type="textarea" rows="10" name="edittxtnotiespecif3" value={this.state.edittxtnotiespecif3} onChange={this.textInput} /></td>
                                                <td><textarea className="form-control" type="textarea" rows="10" name="edittxtnotiespecif4" value={this.state.edittxtnotiespecif4} onChange={this.textInput} /></td>
                                                <td className="text-center" onClick={() => { this.actualizarDatos(this.state.idnotiespecif) }}><MDBIcon icon="save" size="2x" style={{ color: 'blue', cursor: 'pointer' }} /></td>
                                                <td className="text-center" onClick={() => { this.setState({ estadoEditar: !this.state.estadoEditar, edittipourlnotiespecif: 'DEFAULT' }) }}><MDBIcon icon="times" size="2x" style={{ color: 'red', cursor: 'pointer' }} /></td>
                                            </tr>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            {this.state.infoNoticiasDetallesEspe.map((e, i) => {
                                                let view;
                                                if (e.estadonotiespecif === 1) {
                                                    view = <MDBIcon onClick={() => { this.inactivar(e.idnotiespecif) }} icon="eye" size="2x" style={{ cursor: 'pointer' }} />
                                                } else {
                                                    view = <MDBIcon onClick={() => { this.activar(e.idnotiespecif) }} icon="eye-slash" size="2x" style={{ cursor: 'pointer' }} />
                                                }
                                                return [
                                                    <React.Fragment key={i}>
                                                        <tr>
                                                            <td>{e.tipourlnotiespecif === 1 ? <img width="180" src={`${URLImgFront}/assets${e.urlnotiespecif}`} alt={e.urlnotiespecif} /> : <p>{e.urlnotiespecif}</p>}</td>
                                                            <td>{e.ordennotiespecif}</td>
                                                            <td>{e.txtnotiespecif1}</td>
                                                            <td>{e.txtnotiespecif2}</td>
                                                            <td>{e.txtnotiespecif3}</td>
                                                            <td>{e.txtnotiespecif4}</td>
                                                            <td className="text-center" onClick={() => { this.editar(e.idnotiespecif) }}><MDBIcon icon="edit" size="2x" style={{ color: 'green', cursor: 'pointer' }} /></td>
                                                            <td className="text-center">{view}</td>
                                                        </tr>
                                                    </React.Fragment>
                                                ]
                                            })}
                                        </React.Fragment>
                                    )}
                            </MDBTableBody>
                        </MDBTable>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Detalles;