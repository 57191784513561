import React, { Component } from 'react';
import FileUploader from "./Slider Principal/FileUploader";
import FileUploaderUpdate from "./Slider Principal/FileUploaderUpdate";
import { MDBIcon, MDBInput } from 'mdbreact';
import Swal from 'sweetalert2';
import { CargueImagenes, SliderPrincipal_, CargueDocumentos } from '../../../services/WebAPI_Utrahuilca';
import reactCSS from 'reactcss';
import { SketchPicker } from 'react-color';

const validate = values => {
    const errors = {}
    if (!values.nombreSlider1) {
        errors.nombreSlider1 = 'campo obligatorio'
    }
    if (values.idtipoenlace === 'DEFAULT') {
        errors.idtipoenlace = 'campo obligatorio'
    }
    return errors;
};

class SliderPrincipal extends Component {
    uploader = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            estadoNuevo: false,
            errors: {},
            nombreImagen: '',
            rutaImagen: '',
            estadoCambioImg1: true,
            estadoCambioImg: false,
            idtipoenlace: 'DEFAULT',
            nombreSlider1: '',
            texto1: '',
            texto2: '',
            texto3: '',
            infoSliderPrincipal: [],
            nombreSlider: '',
            editposiciontexto: 0,
            editarTexto1: '',
            editarTexto2: '',
            editarTexto3: '',
            estado: null,
            rutafondoslider: '',
            est: false,
            estadoImagen: false,
            idSeleccionado: null,
            displayColorPicker: false,
            color: '#ffffff',
            posiciontexto: 'DEFAULT',
            stateChangeInput: false,
            selectedFile: null,
            envioDocumento: false
        }
    }

    async componentDidMount() {
        this.cargarInformacion();
    }

    cargarInformacion = () => {
        const data = {
            tran: 4,
            id: 0,
            nombreImagen: 'NA',
            rutaModal: 'NA',
            texto1: 'NA',
            texto2: 'NA',
            texto3: 'NA',
            estado: 0,
            idtipoenlace: 1,
            rutatipoenlace: 'NA',
            posiciontexto: 0,
            colortexto: "NA",
            usucrea: 'NA'
        }
        SliderPrincipal_(data).then(response => {
            if (response.data.length > 0) {
                this.setState({
                    idSeleccionado: response.data[0].idsliderprinc
                })
                setTimeout(() => {
                    this.setState({
                        infoSliderPrincipal: response.data,
                        rutafondoslider: response.data[0].rutafondoslider,
                        nombreSlider: response.data[0].nombsliderprinc,
                        editarTexto1: response.data[0].txtslider1,
                        editarTexto2: response.data[0].txtslider2,
                        editarTexto3: response.data[0].txtslider3,
                        editposiciontexto: response.data[0].posiciontexto,
                        editrutatipoenlace: response.data[0].rutatipoenlace,
                        selectedFile: null,
                        idtipoenlace: String(response.data[0].idtipoenlace),
                        estado: response.data[0].estado,
                        estadoImagen: true,
                        color: response.data[0].colortexto,
                    })
                    document.getElementById('' + response.data[0].idsliderprinc + '').style.background = "#f47216";
                    document.getElementById('' + response.data[0].idsliderprinc + '').style.color = "#fff";
                    document.getElementById('' + response.data[0].idsliderprinc + '').style.fontWeight = "bold";
                }, 120);
            } else {
                this.setState({
                    estadoNuevo: true
                })
            }
        });
    }

    onFileChange = event => {
        this.setState({ selectedFile: event.target.files[0] });
    };

    cambioImagen1 = (name, file) => {
        this.setState({
            estadoCambioImg1: true
        });
    }

    cambioImagen = (name, file) => {
        this.setState({
            estadoCambioImg: true
        });
    }

    textInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    insertarDatos = () => {
        const { errors, ...sinErrors } = this.state;
        const result = validate(sinErrors);
        this.setState({ errors: result });
        if (!Object.keys(result).length) {
            // formulario validado
            var rutatipoenlace = '';
            var texto1 = '';
            var texto2 = '';
            var texto3 = '';
            if (this.state.texto1 === null || this.state.texto1 === "") { texto1 = 'N/A' } else { texto1 = this.state.texto1 }
            if (this.state.texto2 === null || this.state.texto2 === "") { texto2 = 'N/A' } else { texto2 = this.state.texto2 }
            if (this.state.texto3 === null || this.state.texto3 === "") { texto3 = 'N/A' } else { texto3 = this.state.texto3 }
            if (this.state.idtipoenlace === "0") {
                rutatipoenlace = "N/A";
            } else if (this.state.idtipoenlace === "1") {
                rutatipoenlace = this.state.rutatipoenlace
            } else if (this.state.idtipoenlace === "2") {
                rutatipoenlace = this.state.selectedFile.name;
                this.setState({
                    envioDocumento: true
                })
            } else if (this.state.idtipoenlace === "3") {
                rutatipoenlace = "N/A";
            }
            const data = {
                tran: 1,
                id: 0,
                nombreImagen: this.state.nombreSlider1,
                rutaModal: '/Principal/Slider Principal/' + this.uploader.current.getFileName(),
                texto1: texto1,
                texto2: texto2,
                texto3: texto3,
                estado: 1,
                idtipoenlace: parseInt(this.state.idtipoenlace),
                rutatipoenlace: rutatipoenlace,
                posiciontexto: this.state.posiciontexto === "DEFAULT" ? 0 : parseInt(this.state.posiciontexto),
                colortexto: this.state.color,
                usucrea: sessionStorage.getItem('idusuario')
            }
            SliderPrincipal_(data).then(response => {
                if (response.data.length > 0) {
                    if (response.data[0].message === "000000") {
                        this.cargarImagen();
                        if (this.state.envioDocumento === true) {
                            this.cargarArchivos();
                        }
                        Swal.fire({
                            icon: 'success',
                            title: 'Se inserto correctamente.'
                        })
                        this.setState({
                            estadoNuevo: false,
                            nombreSlider: '',
                            texto1: '',
                            texto2: '',
                            texto3: '',
                            idtipoenlace: "DEFAULT",
                            color: '#ffffff',
                            posiciontexto: 'DEFAULT',
                            selectedFile: null,
                            envioDocumento: false
                        })
                        this.cargarInformacion();
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error al insertar.'
                        })
                    }
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Ocurrio un error!'
                    })
                }
            });
        }
    }

    actualizarDatos = (id) => {
        var ruta = '';
        var capNombre = this.uploader.current.getFileName();
        if (capNombre === undefined || capNombre === null) {
            ruta = this.state.rutafondoslider;
        } else {
            ruta = '/Principal/Slider Principal/' + this.uploader.current.getFileName();
        }
        var editarTexto1 = '';
        var editarTexto2 = '';
        var editarTexto3 = '';
        var editrutatipoenlace = '';
        if (this.state.editarTexto1 === null || this.state.editarTexto1 === "") { editarTexto1 = 'N/A' } else { editarTexto1 = this.state.editarTexto1 }
        if (this.state.editarTexto2 === null || this.state.editarTexto2 === "") { editarTexto2 = 'N/A' } else { editarTexto2 = this.state.editarTexto2 }
        if (this.state.editarTexto3 === null || this.state.editarTexto3 === "") { editarTexto3 = 'N/A' } else { editarTexto3 = this.state.editarTexto3 }
        if (this.state.idtipoenlace === "0") {
            editrutatipoenlace = 'N/A'
        } else if (this.state.idtipoenlace === "1") {
            editrutatipoenlace = this.state.editrutatipoenlace
        } else if (this.state.idtipoenlace === "2") {
            editrutatipoenlace = this.state.selectedFile.name;
            this.setState({
                envioDocumento: true
            })
        } else if (this.state.idtipoenlace === "3") {
            editrutatipoenlace = 'N/A'
        }
        const data = {
            tran: 1,
            id: id,
            nombreImagen: this.state.nombreSlider,
            rutaModal: ruta,
            texto1: editarTexto1,
            texto2: editarTexto2,
            texto3: editarTexto3,
            estado: this.state.estado,
            idtipoenlace: parseInt(this.state.idtipoenlace),
            rutatipoenlace: editrutatipoenlace,
            posiciontexto: parseInt(this.state.editposiciontexto),
            colortexto: this.state.color,
            usucrea: sessionStorage.getItem('idusuario')
        }
        SliderPrincipal_(data).then(response => {
            if (response.data.length > 0) {
                if (response.data[0].message === "000000") {
                    this.cargarImagen();
                    if (this.state.envioDocumento === true) {
                        this.cargarArchivos();
                    }
                    this.setState({
                        estadoCambioImg: false,
                        color: '#ffffff',
                        editposiciontexto: null,
                        selectedFile: null,
                        envioDocumento: false
                    })
                    Swal.fire({
                        icon: 'success',
                        title: 'Se actualizo correctamente.'
                    })
                    document.getElementById('' + this.state.idSeleccionado + '').style.background = "#fff";
                    document.getElementById('' + this.state.idSeleccionado + '').style.color = "#000";
                    document.getElementById('' + this.state.idSeleccionado + '').style.fontWeight = "normal";
                    this.cargarInformacion();
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error al actualizar.'
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Ocurrio un error!'
                })
            }
        });
    }

    cargarArchivos = () => {
        var formData = new FormData();
        if (undefined === this.state.selectedFile || null === this.state.selectedFile) return;
        formData.append("file", this.state.selectedFile, this.state.selectedFile.name);
        formData.append("rutaImagen", '/Archivos/' + this.state.selectedFile.name);
        CargueDocumentos(formData).then(response => {
            if (response) {
                this.cargarInformacion();
            }
        });
    }

    cargarImagen = () => {
        var formData = new FormData();
        if (undefined === this.uploader.current) return;
        formData.append("file", this.uploader.current.getFileObject());
        formData.append("rutaImagen", '/Principal/Slider Principal/' + this.uploader.current.getFileName());
        CargueImagenes(formData).then(response => {
            if (response) {
                this.cargarInformacion();
            }
        });
    }

    busquedaId = (id) => {
        document.getElementById('' + this.state.idSeleccionado + '').style.background = "#fff";
        document.getElementById('' + this.state.idSeleccionado + '').style.color = "#000";
        document.getElementById('' + this.state.idSeleccionado + '').style.fontWeight = "normal";
        document.getElementById('' + id + '').style.background = "#f47216";
        document.getElementById('' + id + '').style.color = "#fff";
        document.getElementById('' + id + '').style.fontWeight = "bold";
        this.setState({
            estadoCambioImg: false,
            est: !this.state.est,
            idSeleccionado: id
        })
        const data = {
            tran: 5,
            id: id,
            nombreImagen: 'NA',
            rutaModal: 'NA',
            texto1: 'NA',
            texto2: 'NA',
            texto3: 'NA',
            estado: this.state.estado,
            idtipoenlace: 1,
            rutatipoenlace: 'NA',
            posiciontexto: 1,
            colortexto: "NA",
            usucrea: 'NA'
        }
        SliderPrincipal_(data).then(response => {
            if (response.data.length > 0) {
                this.setState({
                    rutafondoslider: response.data[0].rutafondoslider,
                    nombreSlider: response.data[0].nombsliderprinc,
                    editarTexto1: response.data[0].txtslider1,
                    editarTexto2: response.data[0].txtslider2,
                    editarTexto3: response.data[0].txtslider3,
                    editrutatipoenlace: response.data[0].rutatipoenlace,
                    idtipoenlace: String(response.data[0].idtipoenlace),
                    color: response.data[0].colortexto,
                    editposiciontexto: response.data[0].posiciontexto

                })
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'No hay información'
                })
            }
        });
    }

    activar = (id) => {
        const data = {
            tran: 8,
            id: id,
            nombreImagen: 'NA',
            rutaModal: 'NA',
            texto1: 'NA',
            texto2: 'NA',
            texto3: 'NA',
            estado: 1,
            idtipoenlace: 1,
            rutatipoenlace: 'NA',
            posiciontexto: 1,
            colortexto: "NA",
            usucrea: sessionStorage.getItem('idusuario')
        }
        SliderPrincipal_(data).then(response => {
            if (response) {
                this.setState({
                    estadoS: false
                })
                Swal.fire({
                    icon: 'success',
                    title: 'Se activo satisfactoriamente'
                });
                document.getElementById('' + this.state.idSeleccionado + '').style.background = "#fff";
                document.getElementById('' + this.state.idSeleccionado + '').style.color = "#000";
                document.getElementById('' + this.state.idSeleccionado + '').style.fontWeight = "normal";
                this.cargarInformacion();
            }
        });
    }

    inactivar = (id) => {
        const data = {
            tran: 8,
            id: id,
            nombreImagen: 'NA',
            rutaModal: 'NA',
            texto1: 'NA',
            texto2: 'NA',
            texto3: 'NA',
            estado: 0,
            idtipoenlace: 1,
            rutatipoenlace: 'NA',
            posiciontexto: 1,
            colortexto: "NA",
            usucrea: sessionStorage.getItem('idusuario')
        }
        SliderPrincipal_(data).then(response => {
            if (response) {
                this.setState({
                    estadoS: false
                })
                Swal.fire({
                    icon: 'success',
                    title: 'Se inactivo satisfactoriamente'
                });
                document.getElementById('' + this.state.idSeleccionado + '').style.background = "#fff";
                document.getElementById('' + this.state.idSeleccionado + '').style.color = "#000";
                document.getElementById('' + this.state.idSeleccionado + '').style.fontWeight = "normal";
                this.cargarInformacion();

            }
        });
    }

    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    };

    handleClose = () => {
        this.setState({ displayColorPicker: false })
    };

    handleChange = (color) => {
        this.setState({ color: color.hex })
    };

    handleNew = () => {
        this.setState({
            estadoNuevo: !this.state.estadoNuevo,
            errors: {},
            nombreSlider1: '',
            idtipoenlace: 'DEFAULT',
        })
        if (this.state.estadoNuevo) {
            this.setState({
                errors: {},
                nombreSlider1: '',
                idtipoenlace: 'DEFAULT',
            })
            this.cargarInformacion();
        }
    }

    render() {
        const { estadoNuevo, estadoCambioImg1, estadoCambioImg, nombreSlider, nombreSlider1, texto1, texto2, texto3, posiciontexto, infoSliderPrincipal, editarTexto1, editarTexto2, editarTexto3, rutafondoslider, estadoImagen, errors } = this.state;
        const styles = reactCSS({
            'default': {
                color: {
                    width: '36px',
                    height: '14px',
                    borderRadius: '2px',
                    background: this.state.color,
                },
                swatch: {
                    padding: '5px',
                    background: '#fff',
                    borderRadius: '1px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                    display: 'inline-block',
                    cursor: 'pointer',
                },
                popover: {
                    position: 'absolute',
                    zIndex: '2',
                },
                cover: {
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                },
            },
        });
        return (
            <React.Fragment>
                <p className="font-weight-bold">Slider Principal</p>
                <hr />
                <div className="plus_add_agencia align-items-center d-flex" onClick={() => { this.handleNew() }}>
                    {estadoNuevo ? <p>Cancelar &nbsp;<MDBIcon icon="times" size="lg" /></p> : <p>Agregar Nuevo &nbsp;<MDBIcon icon="plus-circle" size="lg" /></p>}
                </div>
                {estadoNuevo ? (
                    <React.Fragment>
                        <div className="justify-content-center d-flex">
                            <div className="text-center">
                                <FileUploader ref={this.uploader} estadoImg={estadoCambioImg1} FCambiar={this.cambioImagen1} />
                            </div>
                        </div>
                        <div className="container mt-5 pt-5 pb-5">
                            <div className="row">
                                <div className="col-md-6">
                                    <MDBInput label="Nombre Slider" name="nombreSlider1" type="text" value={nombreSlider1} onChange={this.textInput} />
                                    {errors.nombreSlider1 && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '50px' }}>{errors.nombreSlider1}</strong>}
                                </div>
                                <div className="col-md-6">
                                    <MDBInput label="Texto 1" name="texto1" type="text" value={texto1} onChange={this.textInput} />
                                </div>
                                <div className="col-md-6">
                                    <MDBInput label="Texto 2" name="texto2" type="text" value={texto2} onChange={this.textInput} />
                                </div>
                                <div className="col-md-6">
                                    <MDBInput label="Texto 3" name="texto3" type="text" value={texto3} onChange={this.textInput} />
                                </div>
                                <div className="col-md-6 mt-3">
                                    <label>Posición Texto</label>
                                    <select name="posiciontexto" className="form-control" value={posiciontexto} onChange={this.textInput}>
                                        <option value="DEFAULT" disabled>Seleccione..</option>
                                        <option value="1">Izquierda</option>
                                        <option value="0">Derecha</option>
                                    </select>
                                </div>
                                <div className="col-md-6 mt-3">
                                    <p>Color Texto</p>
                                    <div style={styles.swatch} onClick={this.handleClick}>
                                        <div style={styles.color} />
                                    </div>
                                    {this.state.displayColorPicker ? <div style={styles.popover}>
                                        <div style={styles.cover} onClick={this.handleClose} />
                                        <SketchPicker color={this.state.color} onChange={this.handleChange} />
                                    </div> : null}
                                </div>
                                <div className="col-md-6 mt-3">
                                    <label>Tipo de Enlace</label>
                                    <select name="idtipoenlace" className="form-control" value={this.state.idtipoenlace} onChange={this.textInput}>
                                        <option value="DEFAULT" disabled> Seleccione..</option>
                                        <option value="0"> Campaña</option>
                                        <option value="1"> URL redireccionar</option>
                                        <option value="2"> Documento</option>
                                        <option value="3"> Ninguno</option>
                                    </select>
                                    {errors.idtipoenlace && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '75px' }}>{errors.idtipoenlace}</strong>}
                                </div>
                                {this.state.idtipoenlace === "1" ? (<React.Fragment>
                                    <div className="col-md-6 pt-5">
                                        <MDBInput label="Url redireccionar - no obligatorio" name="rutatipoenlace" type="text" value={this.state.rutatipoenlace} onChange={this.textInput} />
                                    </div>
                                </React.Fragment>) : (<React.Fragment></React.Fragment>)}
                                {this.state.idtipoenlace === "2" ? (<React.Fragment>
                                    <div className="col-md-6 pt-5">
                                        <input type="file" onChange={this.onFileChange} />
                                    </div>
                                </React.Fragment>) : (<React.Fragment></React.Fragment>)}
                            </div>
                        </div>
                        <div className="pt-2 mt-5 modal-footer w-100">
                            <button type="button" className="btn btn-primary px-5" onClick={this.insertarDatos}>Guardar nuevo &nbsp; <MDBIcon icon="save" /></button>
                        </div>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className="listado_banner pt-5">
                            <p>Seleccionar banner</p>
                            <ul>
                                {infoSliderPrincipal.map((e, i) => {
                                    let view;
                                    if (e.estado === 1) {
                                        view = <MDBIcon onClick={() => { this.inactivar(e.idsliderprinc) }} icon="eye" size="lg" style={{ color: 'black', position: 'absolute', right: '-38px', marginTop: '5px' }} />
                                    } else {
                                        view = <MDBIcon onClick={() => { this.activar(e.idsliderprinc) }} icon="eye-slash" size="lg" style={{ color: 'black', position: 'absolute', right: '-38px', marginTop: '5px' }} />
                                    }
                                    return [
                                        <li key={i} id={e.idsliderprinc} onClick={() => { this.busquedaId(e.idsliderprinc) }}>{e.nombsliderprinc} {view}</li>
                                    ]
                                })}
                            </ul>
                        </div>
                        <div className="justify-content-center d-flex">
                            <div className="text-center">
                                {estadoImagen ? (
                                    <FileUploaderUpdate ref={this.uploader} ruta={rutafondoslider} estadoImg={estadoCambioImg} FCambiar={this.cambioImagen} />
                                ) : (
                                    <React.Fragment></React.Fragment>
                                )}
                            </div>
                        </div>
                        <div className="container mt-5 pt-5 pb-5">
                            <div className="row">
                                <div className="col-md-6">
                                    <MDBInput label="Nombre Slider" name="nombreSlider" type="text" value={nombreSlider} onChange={this.textInput} />
                                </div>
                                <div className="col-md-6">
                                    <MDBInput label="Texto 1" type="text" name="editarTexto1" value={editarTexto1} onChange={this.textInput} />
                                </div>
                                <div className="col-md-6">
                                    <MDBInput label="Texto 2" type="text" name="editarTexto2" value={editarTexto2} onChange={this.textInput} />
                                </div>
                                <div className="col-md-6">
                                    <MDBInput label="Texto 3" type="text" name="editarTexto3" value={editarTexto3} onChange={this.textInput} />
                                </div>
                                <div className="col-md-6 mt-3">
                                    <label>Posición Texto</label>
                                    <select name="editposiciontexto" className="form-control" value={this.state.editposiciontexto} onChange={this.textInput}>
                                        <option value="DEFAULT" disabled>Seleccione..</option>
                                        <option value="1">Izquierda</option>
                                        <option value="0">Derecha</option>
                                    </select>
                                </div>
                                <div className="col-md-6 mt-3">
                                    <p>Color Texto</p>
                                    <div style={styles.swatch} onClick={this.handleClick}>
                                        <div style={styles.color} />
                                    </div>
                                    {this.state.displayColorPicker ? <div style={styles.popover}>
                                        <div style={styles.cover} onClick={this.handleClose} />
                                        <SketchPicker color={this.state.color} onChange={this.handleChange} />
                                    </div> : null}
                                </div>
                                <div className="col-md-6 mt-3">
                                    <label>Tipo de Enlace</label>
                                    <select name="idtipoenlace" className="form-control" value={this.state.idtipoenlace} onChange={this.textInput}>
                                        <option value="DEFAULT" disabled> Seleccione..</option>
                                        <option value="0"> Campaña</option>
                                        <option value="1"> URL redireccionar</option>
                                        <option value="2"> Documento</option>
                                        <option value="3"> Ninguno</option>
                                    </select>
                                </div>
                                {this.state.idtipoenlace === "1" ? (<React.Fragment>
                                    <div className="col-md-6 pt-5">
                                        <MDBInput label="Url redireccionar - no obligatorio" name="editrutatipoenlace" type="text" value={this.state.editrutatipoenlace} onChange={this.textInput} />
                                    </div>
                                </React.Fragment>) : (<React.Fragment></React.Fragment>)}
                                {this.state.idtipoenlace === "2" ? (<React.Fragment>
                                    <div className="col-md-6 pt-5">
                                        <input type="file" onChange={this.onFileChange} />
                                    </div>
                                </React.Fragment>) : (<React.Fragment></React.Fragment>)}
                            </div>
                        </div>
                        <div className="pt-2 mt-5 modal-footer w-100">
                            <button type="submit" className="btn btn-success px-5" onClick={() => { this.actualizarDatos(this.state.idSeleccionado) }}>Guardar cambios &nbsp; <MDBIcon icon="save" /></button>
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

export default SliderPrincipal;