import React, { Component } from 'react';
import { MDBIcon, MDBInput } from 'mdbreact';
import { ConsultarEstadoAportes } from '../../../services/WebAPI_Utrahuilca';
import Swal from 'sweetalert2';
import Loading from '../../../components/Loading';

const validate = values => {
    const errors = {}
    if (!values.identificacion) {
        errors.identificacion = 'campo obligatorio'
    }
    if (values.tipoIdentificacion === 'DEFAULT') {
        errors.tipoIdentificacion = 'campo obligatorio'
    }
    return errors;
};


class ConsultarAsociado extends Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            tipoIdentificacion: 'DEFAULT',
            identificacion: '',
            numident: '',
            nombre: '',
            tipoVinculo: '',
            fechaVinculo: '',
            estadoAportes: '',
            stateLoadign: false
        }
    }

    textInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    buscarDatos = () => {
        const { errors, ...sinErrors } = this.state;
        const result = validate(sinErrors);
        this.setState({ errors: result });

        if (!Object.keys(result).length) {
            this.setState({
                stateLoadign: true,
                numident: '',
                nombre: '',
                tipoVinculo: '',
                fechaVinculo: '',
                estadoAportes: ''
            });
            const data = {
                tipoiden: this.state.tipoIdentificacion,
                nit: this.state.identificacion,
                usuario: sessionStorage.getItem('idusuariofundacion')
            }
            ConsultarEstadoAportes(data).then(response => {
                try
                {
                    if (response.data.length > 0) {
                        if (response.data.length >= 1) {
                            if (response.data[0].codresp) {
                                Swal.fire({
                                    icon: 'error',
                                    title: response.data[0].desresp
                                })
                            }
                            else {
                                var fecha = new Date(response.data[0].fecingaso);
                                this.setState({
                                    numident: this.state.identificacion + " - ",
                                    nombre: response.data[0].nomcom,
                                    tipoVinculo: response.data[0].desvin,
                                    fechaVinculo: fecha.getDate() + "/" + (fecha.getMonth()+1) + "/" + fecha.getFullYear(),
                                    estadoAportes: response.data[0].estaportes,
                                    tipoIdentificacion: 'DEFAULT',
                                    identificacion: ''
                                })
                            }
                        }
                    }
                }
                catch (error) {
                    this.setState({
                        tipoIdentificacion: 'DEFAULT',
                        identificacion: ''
                    });
                    Swal.fire({
                        icon: 'error',
                        title: response.data[0].descMessage
                    })
                }
                this.setState({
                    stateLoadign: false
                });
            });
        }
    }

    limpiarDatos = () => {
        this.setState({
            numident: '',
            nombre: '',
            tipoVinculo: '',
            fechaVinculo: '',
            estadoAportes: '',
            tipoIdentificacion: 'DEFAULT',
            identificacion: ''
        })
    }

    render() {
        const { tipoIdentificacion, identificacion, numident, nombre, tipoVinculo, fechaVinculo, estadoAportes, errors } = this.state;
        
        return (
            <React.Fragment>
                <p className="font-weight-bold">Consultar Asociado</p>
                <hr />
                <React.Fragment>
                    <div className="container mt-5 pt-5 pb-5">
                        <div className="row">
                            <div className="col-md-4">
                                <label>Tipo Identificación</label>
                                <select name="tipoIdentificacion" className="form-control" value={tipoIdentificacion} onChange={this.textInput}>
                                    <option value="DEFAULT" disabled>Seleccione..</option>
                                    <option value="C">CEDULA CIUDADANIA</option>
                                    <option value="E">CEDULA EXTRANJERIA</option>
                                    <option value="F">CEDULA DE EXTRANJERÍA INFANTIL</option>
                                    <option value="I">TARJETA IDENTIDAD</option>
                                    <option value="M">PERMISO ESPECIAL DE PERMANENCIA</option>
                                    <option value="N">NIT</option>
                                    <option value="O">OTROS</option>
                                    <option value="P">PASAPORTE</option>
                                    <option value="R">REGISTRO CIVIL</option>
                                </select>
                                {errors.tipoIdentificacion && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '75px' }}>{errors.tipoIdentificacion}</strong>}
                            </div>
                            <div className="col-md-4 mt-4">
                                <MDBInput label="Identificación" name="identificacion" type="text" value={identificacion} onChange={this.textInput} />
                                {errors.identificacion && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '50px' }}>{errors.identificacion}</strong>}
                            </div>
                            <div className="col-md-3 mt-3">
                                <button type="button" className="btn btn-success px-3" onClick={this.buscarDatos}>Buscar &nbsp; <MDBIcon icon="search-plus xs" /></button>
                                <button type="button" className="btn btn-warning px-3" onClick={this.limpiarDatos}>Limpiar &nbsp; <MDBIcon icon="trash-alt xs" /></button>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer"></div>
                    <div className="alert alert-info container" role="alert">
                        <h4 className="alert-heading">Nombre Persona:</h4>
                        <p>{numident} {nombre}</p>
                        <hr />
                        <div className="row">
                            <div className="col-md-3">
                                <strong className="alert-heading"><b>Tipo de Vinculo:</b></strong>
                            </div>
                            <div className="col-md-3">
                                <span>{tipoVinculo}</span>
                            </div>
                            <div className="col-md-3">
                                <strong className="alert-heading"><b>Fecha Vinculación Desde:</b></strong>
                            </div>
                            <div className="col-md-3 text-left">
                                <span>{fechaVinculo}</span>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-md-3">
                                <strong className="alert-heading"><b>Estado en Aportes:</b></strong>
                            </div>
                            <div className="col-md-3">
                                <span>{estadoAportes}</span>
                            </div>
                        </div>
                    </div>
                    {this.state.stateLoadign ? (
                        <Loading />
                    ) : (<React.Fragment></React.Fragment>)}
                </React.Fragment>
            </React.Fragment>
        );
    }
}

export default ConsultarAsociado;