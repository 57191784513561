import { MDBIcon } from 'mdbreact';
import React, { Component } from 'react';
import { URLImgFront } from '../../config/URLDB';

class Seccion1 extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    render() {
        return (
            <React.Fragment>
                <div className="diseño_responsive_view" style={{ width: '100%', paddingTop: '120px' }}>
                    <img style={{ width: '100%', height: '97vh', marginBottom: '15px', objectFit: 'cover' }} className="img-fluid" src={`${URLImgFront}/assets/img-variadas/portadaseccion_trabajeconnosotros.jpg`} alt="PORTADA TRABAJE CON NOSOTROS" />
                    <span className="ml-4 pt-4" style={{ fontSize: '20px' }}><MDBIcon icon="caret-right" className="ml-2 mr-1" /> Trabaje con Nosotros</span>
                    {/*<div className="vista_p_ahorro_credito" style={{ position: 'absolute', bottom: '230px', right: '10%' }}>*/}
                    {/*    <p className="my-0 text-right" style={{ fontSize: '60px', color: 'rgb(47, 140, 62)' }}>Haga parte de la</p>*/}
                    {/*    <p className="pb-5 text-right" style={{ fontSize: '60px', color: 'rgb(47, 140, 62)', fontWeight: 'bold' }}>FAMILIA UTRAHUILCA</p>*/}
                    {/*</div>*/}
                </div>
            </React.Fragment>
        );
    }
}

export default Seccion1;