import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { URLImgFront } from '../../config/URLDB';

class Seccion2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            seleccionada: 1
        };
    }
    render() {
        return (
            <React.Fragment>
                <div className="bg-white" style={{ width: '100%' }}>
                    <div className="container text-center mt-3 pt-4">
                        <p className="texto-contenido text-justify">Conozca los beneficios y coberturas del portafolio de seguros Utrahuilca.</p>
                        <p className="font-weight-bold pt-3 texto-contenido text-justify">Puede personalizar la cobertura y valor asegurado de acuerdo a sus necesidades.</p>
                        <div className="row py-4 mb-4">
                            <div className="col-md-6 seguros_item py-2" style={this.state.seleccionada === 1 ? { background: '#008694', color: '#fff', cursor: 'pointer' } :  { background: '#ffffff', color: '#000000', cursor: 'pointer' }} onClick={() => { this.setState({ seleccionada: 1 }) }}>
                                <img width="150" className="img-fluid" src={`${URLImgFront}/assets/img-variadas/ÍCONO SEGUROS - SOAT.png`} alt="ÍCONO SEGUROS - SOAT" />
                                <p className="pt-4 mb-0 font-weight-bold">SOAT</p>
                                <span>para todo tipo de vehículos</span>
                            </div>
                            <div className="col-md-6 seguros_item py-2" style={this.state.seleccionada === 2 ? { background: '#008694', color: '#fff', cursor: 'pointer' } :  { background: '#ffffff', color: '#000000', cursor: 'pointer' }} onClick={() => { this.setState({ seleccionada: 2 }) }}>
                                <img width="150" className="img-fluid" src={`${URLImgFront}/assets/img-variadas/ÍCONO SEGUROS - EXEQUIALES.png`} alt="ÍCONO SEGUROS - EXEQUIALES" />
                                <p className="pt-4 font-weight-bold">Planes Exequiales Los Olivos Emcofun</p>
                            </div>
                            <div className="col-md-12 mt-4 justify-content-center d-flex" style={{ background: '#f7f7f7' }}>
                                {this.state.seleccionada === 1 ? (
                                    <div className="py-5 text-left px-5">
                                        <p className="font-weight-bold text-center">SOAT para todo tipo de vehículos</p>
                                        <p className="text-justify">Es un seguro obligatorio para todos los vehículos que transiten por el territorio nacional. Ampara los daños corporales que se causen a las personas en accidentes de tránsito. Se incluyen los vehículos extranjeros que circulen por las carreteras del país. Aunque no se tienen en cuenta aquellos vehículos que se movilicen por vías férreas y la maquinaria agrícola.</p>
                                        <p>En caso de accidente de tránsito, el seguro obligatorio cubre a todas las víctimas:</p>
                                        <ul className="text-center text-left" style={{ listStyle: 'none' }}>
                                            <li><p>Conductor.</p></li>
                                            <li><p>Ocupantes particulares.</p></li>
                                            <li><p>Pasajeros (vehículos de servicio público).</p></li>
                                            <li><p>Peatones.</p></li>
                                        </ul>
                                        <p className="text-justify">Vigencia: Su vigencia es anual, excepto en seguros expedidos con carácter transitorio. Para los vehículos que circulen por las zonas fronterizas, para los vehículos importados que se desplacen del puerto a los concesionarios para su venta al público y para los vehículos antiguos o clásicos la vigencia de dicha póliza no podrá ser menor a un trimestre.</p>
                                    </div>
                                ) : (
                                    <div className="py-5 text-left px-5">
                                        <p className="font-weight-bold text-center">Planes Exequiales Los Olivos Emcofun</p>
                                        <p className="text-justify"><b className="font-weight-bold text-justify">Unión de Siempre 1:</b> Titular de edad de ingreso de 18 años hasta 40 años. En renovación sin límite de edad. </p>
                                        <p className="text-justify"><b className="font-weight-bold text-justify">Unión de Siempre 2:</b> Titular con edad de ingreso de 41 años hasta 65 años. En renovación sin límite de edad. </p>
                                        <p className="text-justify"><b className="font-weight-bold text-justify">Grupo familiar: </b> Es el grupo familiar primario que depende del estado civil del titular.</p>
                                        <p className="text-justify">Grupo familiar con titular con estado civil soltero: Padres o padrastros (máximo 2 de estos), con edad de ingreso hasta 65 años en renovación sin límite de edad. </p>
                                        <p className="text-justify">Hermanos o hermanastros menores de 30 años, solteros y sin hijos. En renovación con permanencia hasta los 30 años. </p>
                                        <p className="text-justify">Hermanos o hermanastros con discapacidad valorada en un 50% o más, sin límite de edad de ingreso y permanencia.</p>
                                        <p className="text-justify">Grupo familiar con titular con estado civil casado/ unión libre.</p>
                                        <p className="text-justify">Cónyuge con edad de ingreso hasta los 65 años. En renovación sin límite de edad. Padres/padrastros o suegros (máximo 2 de estos), con edad de ingreso hasta los 65 años en renovación sin límite de edad.</p>
                                        <p className="text-justify">Hijos o hijastros menores de 30 años, solteros y sin hijos, con permanencia hasta los 30 años.</p>
                                        <p className="text-justify">Hijos o hijastros con discapacidad valorada en un 50% o más, sin límite de edad de ingreso y permanencia. </p>
                                        <p><b className="font-weight-bold text-justify">Unión que empieza:</b> (Grupo familiar sin padres, ni suegros).</p>
                                        <p className="text-justify">Titular con edad de ingreso de 18 años hasta 40 años. En renovación sin límite de edad.</p>
                                        <p className="text-justify">Grupo familiar con titular con estado civil casado / unión libre. </p>
                                        <p className="text-justify">Cónyuge con edad de ingreso hasta los 40 años. En renovación sin límite de edad. Máximo 3 hijos: hijos o hijastros menores de 30 años, solteros y sin hijos. En renovación con permanencia hasta los 30 años. </p>
                                        <p className="text-justify">E hijos con discapacidad valorada en un 50% sin límite de edad de ingreso y permanencia. </p>
                                        <p><b className="font-weight-bold text-justify">Unión Fraterna 1:</b> : Grupo familiar con titular con estado civil casado / unión libre. Titular con edad de ingreso de 18 años hasta 40 años. En renovación sin límite edad. Cónyuge con edad de ingreso hasta lo 40 años. En renovación sin límite de edad. </p>
                                        <p><b className="font-weight-bold text-justify">Unión Fraterna 2:</b> Titular más dos hermanos.</p>
                                        <p className="text-justify">Grupo familiar con titular con estado civil soltero: Edad de ingreso de 18 años hasta 40 años.</p>
                                        <p className="text-justify">Máximo 2 hermanos con edad de ingreso menores de 30 años, solteros y sin hijos. Con permanencia hasta los 30 años. </p>
                                        <p><b className="font-weight-bold text-justify">Unidos:</b>(Titular + 6 beneficiarios): Cónyuge o compañero permanente, exesposo/a, hijos, padres, padrastros, hermanos, tíos, nietos, abuelos, bisnietos, sobrinos, primos, suegros, cuñados, yernos, nuera, hijastros, hermanastros, abuelastros, ahijados, personal del servicio doméstico.</p>
                                        <p className="text-justify">Titular con edad de ingreso de 18 años hasta los 45 años. En renovación sin límite de edad.</p>
                                        <p><b className="font-weight-bold text-justify">Grupo familiar:</b>2 beneficiarios con edad de ingreso hasta los 65 años. En renovación sin límite de edad.</p>
                                        <p className="text-justify">4 beneficiarios con edad de ingreso hasta los 40 años. En renovación sin límite de edad. </p>
                                        <p><b className="font-weight-bold text-justify">Unifamiliar:</b>(Titular + 6 beneficiarios) (Grupo familiar el mismo de unidos) Titular con edad de ingreso de 18 años hasta 45 años. En renovación sin límite de edad.</p>
                                        <p className="text-justify">Grupo familiar</p>
                                        <p className="text-justify">2 beneficiarios con edad de ingreso hasta los 65 años. En renovación sin límite de edad.</p>
                                        <p className="text-justify">4 beneficiarios con edad de ingreso hasta los 40 años. En renovación sin límite de edad. </p>
                                    </div>
                                )}
                            </div>
                            <Link to="/Canales_Atencion" className="col-md-12 pt-5">
                                <span className="px-5 mb-5 py-2 mt-3 texto-contenido" style={{ background: '#3f8592', color: 'white', fontWeight: 'bold', borderRadius: '20px', cursor: 'pointer' }}>CANALES DE ATENCIÓN</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Seccion2;