import { MDBIcon } from 'mdbreact';
import React, { Component } from 'react';
import { URLImgFront } from '../../../config/URLDB';
import { CasosDeExito_ } from '../../../services/WebAPI_Utrahuilca';

class CasosExito extends Component {
    constructor(props) {
        super(props);
        this.state = {
            estadoSinInfo: false,
            infoCasosExito: []
        };
    }

    async componentDidMount() {
        this.cargarInformacion();
    }

    cargarInformacion = () => {
        const data = {
            tran: 7,
            idcsdet: 0,
            imgprincipalcsdet: 'NA',
            titulo1csdet: 'NA',
            titulo2csdet: 'NA',
            descripcioncsdet: 'NA',
            redessocialescsdet: 0,
            urlcsdetface: 'NA',
            urlcsdetyout: 'NA',
            urlcsdetinst: 'NA',
            urlwebcsdet: 'NA',
            prioridadcsdet: 0,
            estado: 1,
            usucrea: 'NA'
        }
        CasosDeExito_(data).then(response => {
            try {
                if (response.data.length > 0) {
                    if (!response.data[0].message) {
                        this.setState({
                            infoCasosExito: response.data,
                            estadoSinInfo: true
                        })
                    } else { }
                } else { }
            } catch (error) {
                console.log(error);
            }
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className="container py-5">
                    <div className="row">
                        <div className="col-md-12">
                            {this.state.infoCasosExito.map((e, i) => {
                                if (e.prioridadcsdet === 1) {
                                    return [
                                        <React.Fragment key={i}>
                                            <div className="container">
                                                <div className="row my-3">
                                                    <div className="col-md-6 px-0 py-0">
                                                        <img className="img-fluid" style={{ width: '100%' }} src={`${URLImgFront}/assets${e.imgprincipalcsdet}`} alt={e.imgprincipalcsdet} />
                                                    </div>
                                                    <div className="col-md-6 d-flex align-items-center" style={{ background: '#f7f7f7' }}>
                                                        <div className="px-3">
                                                            <p className="text-left pt-2" style={{ fontSize: '15px', color: 'gray' }}>{e.titulo1csdet}</p>
                                                            <p className="text-left font-weight-bold" style={{ fontSize: '25px' }}>{e.titulo2csdet}</p>
                                                            <p>{e.descripcioncsdet}</p>
                                                            <div className="d-flex mt-5 pt-3 pb-3">
                                                                {e.redessocialescsdet === 1 ? (
                                                                    <React.Fragment>
                                                                        {e.urlcsdetface === 'NA' ? '' : <a href={e.urlcsdetface} target={'_blank'} rel="noopener noreferrer"><MDBIcon fab icon="facebook-square" size="lg" className="px-2" style={{ cursor: 'pointer', color: '#3f8592' }} /></a>}
                                                                        {e.urlcsdetyout === 'NA' ? '' : <a href={e.urlcsdetyout} target={'_blank'} rel="noopener noreferrer"><MDBIcon fab icon="youtube" size="lg" className="px-2" style={{ cursor: 'pointer', color: '#3f8592' }} /></a>}
                                                                        {e.urlcsdetinst === 'NA' ? '' : <a href={e.urlcsdetinst} target={'_blank'} rel="noopener noreferrer"><MDBIcon fab icon="instagram" size="lg" className="px-2" style={{ cursor: 'pointer', color: '#3f8592' }} /></a>}
                                                                    </React.Fragment>
                                                                ) : (
                                                                    <React.Fragment></React.Fragment>
                                                                )}
                                                                {e.urlwebcsdet === 'NA' ? '' : <a href={e.urlwebcsdet} target={'_blank'} rel="noopener noreferrer"><MDBIcon fab icon="chrome" size="lg" className="px-2" style={{ cursor: 'pointer', color: '#3f8592' }} /></a>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    ]
                                } else {
                                    return [];
                                }
                            })}
                        </div>
                        <div className="col-md-12 mt-5">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="container">
                                        <div className="row">
                                            {this.state.infoCasosExito.map((e, i) => {
                                                if (e.prioridadcsdet === 2) {
                                                    return [
                                                        <React.Fragment key={i}>
                                                            <div className="col-md-12 my-3 px-0" style={{ background: '#f7f7f7' }}>
                                                                <img className="img-fluid" style={{ width: '100%' }} src={`${URLImgFront}/assets${e.imgprincipalcsdet}`} alt={e.imgprincipalcsdet} />
                                                                <p className="px-4 pt-4 text-left" style={{ fontSize: '15px', color: 'gray' }}>{e.titulo1csdet}</p>
                                                                <p className="px-4 text-left font-weight-bold" style={{ fontSize: '25px' }}>{e.titulo2csdet}</p>
                                                                <p className="px-4 pb-4">{e.descripcioncsdet}</p>
                                                                <div className="d-flex pb-3 pl-3">
                                                                    {e.redessocialescsdet === 1 ? (
                                                                        <React.Fragment>
                                                                            {e.urlcsdetface === 'NA' ? '' : <a href={e.urlcsdetface} target={'_blank'} rel="noopener noreferrer"><MDBIcon fab icon="facebook-square" size="lg" className="px-2" style={{ cursor: 'pointer', color: '#3f8592' }} /></a>}
                                                                            {e.urlcsdetyout === 'NA' ? '' : <a href={e.urlcsdetyout} target={'_blank'} rel="noopener noreferrer"><MDBIcon fab icon="youtube" size="lg" className="px-2" style={{ cursor: 'pointer', color: '#3f8592' }} /></a>}
                                                                            {e.urlcsdetinst === 'NA' ? '' : <a href={e.urlcsdetinst} target={'_blank'} rel="noopener noreferrer"><MDBIcon fab icon="instagram" size="lg" className="px-2" style={{ cursor: 'pointer', color: '#3f8592' }} /></a>}
                                                                        </React.Fragment>
                                                                    ) : (
                                                                        <React.Fragment></React.Fragment>
                                                                    )}
                                                                    {e.urlwebcsdet === 'NA' ? '' : <a href={e.urlwebcsdet} target={'_blank'} rel="noopener noreferrer"><MDBIcon fab icon="chrome" size="lg" className="px-2" style={{ cursor: 'pointer', color: '#3f8592' }} /></a>}
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                    ]
                                                } else {
                                                    return [];
                                                }
                                            })}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 pl-5 tarjeta_contenido">
                                    <div className="container">
                                        <div className="row">
                                            {this.state.infoCasosExito.map((e, i) => {
                                                if (e.prioridadcsdet === 3) {
                                                    return [
                                                        <React.Fragment key={i}>
                                                            <div className="col-md-12 my-3 px-3">
                                                                <div className="container">
                                                                    <div className="row">
                                                                        <div className="col-md-6" style={{ background: '#f7f7f7' }}>
                                                                            <div className="px-3">
                                                                                <p className="text-left pt-3" style={{ fontSize: '15px', color: 'gray' }}>{e.titulo1csdet}</p>
                                                                                <p className="font-weight-bold">{e.titulo2csdet}</p>
                                                                                <p className="pb-4">{e.descripcioncsdet}</p>
                                                                            </div>
                                                                            <div className="d-flex pb-3 pl-3">
                                                                                {e.redessocialescsdet === 1 ? (
                                                                                    <React.Fragment>
                                                                                        {e.urlcsdetface === 'NA' ? '' : <a href={e.urlcsdetface} target={'_blank'} rel="noopener noreferrer"><MDBIcon fab icon="facebook-square" size="lg" className="px-2" style={{ cursor: 'pointer', color: '#3f8592' }} /></a>}
                                                                                        {e.urlcsdetyout === 'NA' ? '' : <a href={e.urlcsdetyout} target={'_blank'} rel="noopener noreferrer"><MDBIcon fab icon="youtube" size="lg" className="px-2" style={{ cursor: 'pointer', color: '#3f8592' }} /></a>}
                                                                                        {e.urlcsdetinst === 'NA' ? '' : <a href={e.urlcsdetinst} target={'_blank'} rel="noopener noreferrer"><MDBIcon fab icon="instagram" size="lg" className="px-2" style={{ cursor: 'pointer', color: '#3f8592' }} /></a>}
                                                                                    </React.Fragment>
                                                                                ) : (
                                                                                    <React.Fragment></React.Fragment>
                                                                                )}
                                                                                {e.urlwebcsdet === 'NA' ? '' : <a href={e.urlwebcsdet} target={'_blank'} rel="noopener noreferrer"><MDBIcon fab icon="chrome" size="lg" className="px-2" style={{ cursor: 'pointer', color: '#3f8592' }} /></a>}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6 px-0 py-0">
                                                                            <img className="img-fluid" style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={`${URLImgFront}/assets${e.imgprincipalcsdet}`} alt={e.imgprincipalcsdet} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                    ]
                                                } else {
                                                    return [];
                                                }
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default CasosExito;