import { MDBIcon } from 'mdbreact';
import React, { Component } from 'react';
import { URLImgFront } from '../../config/URLDB';

class Personas extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    render() {
        return (
            <React.Fragment>
                <div className="diseño_cdat" style={{ width: '100%', paddingTop: '120px' }}>
                    <div className="contenido_cdat" style={{ position: 'absolute', top: '450px', left: '80px' }}>
                        <p className="my-0 texto1" style={{ fontSize: '20px', color: '#fff' }}>Una opción de inversión estable y segura</p>
                        <p className="my-0 w-75 texto2" style={{ fontSize: '50px', color: '#fff', fontWeight: 'bold' }}>C D A T</p>
                        <p className="my-0 pb-4 text-white texto1">Elige el plazo y la forma de pago.</p>
                        {/* <span className="position-absolute mt-5 px-4" style={{ fontSize: '25px', background: '#fff', borderRadius: '20px', color: '#3f8592', cursor: 'pointer' }}>Abrir una cuenta</span> */}
                    </div>
                    <img style={{ width: '100%', height: '70vh', marginBottom: '15px', objectFit: 'cover' }} className="img-fluid" src={`${URLImgFront}/assets/img_principal/CDAT.png`} alt="img1" />
                    <span className="pt-4 font-weight-bold text-white pie" style={{ fontSize: '20px', position: 'absolute', top: '15%', left: '10px' }}><MDBIcon icon="caret-right" className="ml-2 mr-1" /> Asociados <MDBIcon icon="caret-right" className="ml-2 mr-1" /> CDAT</span>
                </div>
            </React.Fragment>
        );
    }
}

export default Personas;