import React, { Component } from 'react';

class Iframes extends Component {
    render() {
        return (
            //<section className="video">
            //    <iframe className="img-fluid iframe-video" title="video" style={{ width: '' + this.props.ancho + '', height: '' + this.props.alto + '' }} src={`https://www.youtube.com/embed/${this.props.url}`} video="true" player="true" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            //</section>
            <div className="img-fluid iframe-video" style={{ width: '' + this.props.ancho + '', height: '' + this.props.alto + '' }}>
                <lite-youtube videoid={this.props.url}></lite-youtube>
            </div>
        );
    }
}

export default Iframes;
