import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { URLImgFront } from '../../../config/URLDB';
import { TasasyTarifas_ } from '../../../services/WebAPI_Utrahuilca';
import Typed from 'typed.js';

class HeaderConstructor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            busqueda: '',
            inputSearch: false
        };
    }

    componentDidMount() {
        
        const options = {
            strings: [
                '<i style="font-size: 15px></i>',
                '<b style="font-size: 15px">¿En qué podemos ayudarle?</b>',
            ],
            typeSpeed: 75,
            backSpeed: 75,
            startDelay: 800,
            smartBackspace: true,
            shuffle: false,
            backDelay: 1100,
            loop: true,
            loopCount: true,
            showCursor: true,
            contentType: 'html'
        };
        this.typed = new Typed(this.el, options);
        (function () {
            var cx = '8bf31f9a1d6dc2acc';
            var gcse = document.createElement('script');
            gcse.type = 'text/javascript';
            gcse.async = true;
            gcse.src = 'https://cse.google.com/cse.js?cx=' + cx;
            var s = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(gcse, s);
        })();
    }

    componentWillUnmount() {
        this.typed.destroy();
    }

    changeView = (idl, id) => {
        localStorage.setItem('idtipoprod', idl)
        localStorage.setItem('idtipopers', id)
        var name = '';
        if (idl === 2) { name = 'Credito'; localStorage.setItem('nombLinea', 'Crédito') } else { name = 'Ahorros'; localStorage.setItem('nombLinea', 'Ahorros') }
        if (id === 1) { localStorage.setItem('nombTproduct', 'Natural'); localStorage.setItem('TipoPers', 'Personas') } else { localStorage.setItem('nombTproduct', 'Jurídica'); localStorage.setItem('TipoPers', 'Empresas') }
        this.props.history.push('/Productos/' + name + '/' + idl + '/' + id);
    }

    descargarTasasTarifas = (id) => {
        const data = {
            tran: 7,
            idtasastarifas: id,
            nomtasastarifas: 'NA',
            rutatasastarifas: 'NA',
            estado: 0,
            usucrea: 'NA'
        }
        TasasyTarifas_(data).then(response => {
            window.open(`${URLImgFront}/assets/Archivos/${response.data[0].rutatasastarifas}`, '_blank');
        });
    }

    eventoInput() {
        document.getElementById('texto_escribiendo').style.display = "none";
        document.getElementById('___gcse_0').style.display = "block";

        // if(inputSearch){
        //     searchGoogle.css
        // }else{

        // }
    }
    render() {
        return (
            <React.Fragment>
                <div className="header-principal" style={{ zIndex: '999' }}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-2 te_ayudamoss" style={{ borderRight: '1px solid #ffffff' }}>
                                <div>
                                    <div className="gcse-search"></div>
                                    <div className="texto_escribiendo" id="texto_escribiendo" style={this.state.inputSearch ? { visibility: 'hidden' } : { visibility: 'visible' }} onClick={this.eventoInput}>
                                        <span style={{ whiteSpace: 'pre' }} ref={(el) => { this.el = el; }}></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5 espacios_sobrantes"></div>
                            <div className="col-md-2 d-flex justify-content-center submenu">
                                <p className="mt-3 text-white">Nuestra Cooperativa</p>
                                <ul className="sub_menu coop text-center" style={{ listStyle: 'none', background: '#f07f00', color: '#fff' }}>
                                    <li>
                                        <Link to="/Conozcanos">
                                            <p className="my-0 text-white c_text">Conózcanos</p>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/Red_Agencias">
                                            <p className="my-0 text-white c_text">Agencias</p>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/Coopsejos">
                                            <p className="my-0 text-white c_text">Coopsejos</p>
                                        </Link>
                                    </li>

                                    <li>
                                        <p className="my-0 text-white c_text" onClick={() => { this.descargarTasasTarifas(1) }}>Tasas</p>
                                    </li>

                                    <li>
                                        <p className="my-0 text-white c_text" onClick={() => { this.descargarTasasTarifas(2) }}>Tarifas</p>
                                    </li>

                                    <li><Link to={`/Documentos/BalanceSocialYBeneficioSolidarioUtrah2023.pdf/0`} target='_blank'><p className="my-0 text-white c_text">Balance Social 2023</p></Link></li>

                                    <li>
                                        <Link to="/Comunicados">
                                            <p className="my-0 text-white c_text">Comunicados</p>
                                        </Link>
                                    </li>

                                    <li>
                                        <Link to="/Trabaje_con_nosotros">
                                            <p className="my-0 text-white c_text">Trabaje con nosotros</p>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/Preguntas_Frecuentes">
                                            <p className="my-0 text-white c_text">Preguntas frecuentes</p>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/Canales_Atencion">
                                            <p className="my-0 text-white c_text">Canales de Atención</p>
                                        </Link>
                                    </li>

                                    {/*<li>
                                        <Link to="/Coolac_Utrahuilca">
                                            <p className="my-0 text-white c_text">Incorporación Coolac</p>
                                        </Link>
                                    </li>*/}
                                    {/*<li>
                                        <Link to="/Reuniones_Coop">
                                            <p className="my-0 text-white c_text">Reuniones Cooperativas 2024</p>
                                        </Link>
                                    </li>*/}

                                    <li>
                                        <Link to="/ActualizacionWebDian">
                                            <p className="my-0 text-white c_text">Actualización Web DIAN</p>
                                        </Link>
                                    </li>

                                    {/*<li>
                                        <Link to="/AportesQuePremian">
                                            <p className="my-0 text-white c_text">Aportes que Premian</p>
                                        </Link>
                                    </li>*/}

                                    {/*<li>
                                        <Link to="/MoviliceSusSuenos">
                                            <p className="my-0 text-white c_text">Movilice sus Sueños</p>
                                        </Link>
                                    </li>*/}

                                    {/* Nuevo link RQTO TODOS PONEN, TODOS GANAN - OASU, CCSV */}
                                    <li>
                                        <Link to="/TodosPonenTodosGanan">
                                            <p className="my-0 text-white c_text">Todos Ponen Todos Ganan</p>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <Link to="/Cooperativa_Virtual" className="col-md-2 text-center  px-0 mx-0 py-0 my-0 cooperativa-gif" style={{ cursor: 'pointer', backgroundImage: `url("${URLImgFront}/assets/img-variadas/BOTÓN COOPERATIVA VIRTUAL.gif")`, height: '60px', width: '100%', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                            </Link>
                            <div className="col-md-1 submenu text-center">
                                <Link to={`/Simulador/${this.props.id === 1 ? 'Ahorros' : 'Credito'}`}>
                                    <p className="text-white mt-3">Simulador</p>
                                </Link>
                            </div>
                        </div>
                        <div className="row menu">
                            <Link to="/" className="col-md-2 text-center img-logo" style={{ cursor: 'pointer' }}>
                                <img className="pl-5 ml-2 my-2" style={{ cursor: 'pointer', height: '45px' }} src={`${URLImgFront}/assets/logo/logo.png`} alt="logo" />
                            </Link>
                            <div className="col-md-5 espacios_sobrantes"></div>
                            <div className="col-md-1 d-flex justify-content-center submenu quiero_asociarme_text">
                                <p className="pt-2 text-dark font-weight-bold">Personas</p>
                                <ul className="sub_menu text-center" style={{ listStyle: 'none' }}>
                                    <li onClick={() => { this.changeView(1, 1) }}>
                                        {/* <Link to="/Productos/Ahorros/1/1"> */}
                                        <p className="my-0 text-dark">Ahorros</p>
                                        {/* </Link> */}
                                    </li>
                                    <li onClick={() => { this.changeView(2, 1) }}>
                                        {/* <Link to="/Productos/Credito/2/1"> */}
                                        <p className="my-0 text-dark">Crédito</p>
                                        {/* </Link> */}
                                    </li>
                                    <li>
                                        <Link to="/Seguros">
                                            <p className="my-0 text-dark">Seguros</p>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/Convenios/Personas">
                                            <p className="my-0 text-dark">Convenios</p>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/Infantil_Juvenil">
                                            <p className="my-0 text-dark">Infantil juvenil</p>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/Descargar_Formatos">
                                            <p className="my-0 text-dark">Formatos</p>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-1 d-flex justify-content-center submenu quiero_asociarme_text">
                                <p className="pt-2 text-dark font-weight-bold">Empresas</p>
                                <ul className="sub_menu text-center" style={{ listStyle: 'none' }}>
                                    <li onClick={() => { this.changeView(1, 0) }}>
                                        {/* <Link to="/Productos/Ahorros/1/0"> */}
                                        <p className="my-0 text-dark">Ahorros</p>
                                        {/* </Link> */}
                                    </li>
                                    <li onClick={() => { this.changeView(2, 0) }}>
                                        {/* <Link to="/Productos/Credito/2/0"> */}
                                        <p className="my-0 text-dark">Crédito</p>
                                        {/* </Link> */}
                                    </li>
                                    <li>
                                        <Link to="/Seguros">
                                            <p className="my-0 text-dark">Seguros</p>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/Descargar_Formatos">
                                            <p className="my-0 text-dark">Formatos</p>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/Convenios/Empresas">
                                            <p className="my-0 text-dark">Convenios</p>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-1 d-flex justify-content-center submenu quiero_asociarme_text">
                                <Link to="/Asociados">
                                    <p className="text-white px-4 font-weight-bold" style={{ background: '#f07f00', color: 'white', padding: '5px', borderRadius: '15px' }}>Asociados</p>
                                </Link>
                            </div>
                            <div className="col-md-2 d-flex justify-content-center submenu quiero_asociarme_text">
                                <Link to="/Quiero_Asociarme">
                                    <p className="text-white px-5 font-weight-bold" style={{ background: '#428793', color: 'white', padding: '5px', borderRadius: '15px' }}>Quiero asociarme</p>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment >
        );
    }
}

export default withRouter(HeaderConstructor);