import { MDBIcon, MDBInput } from 'mdbreact';
import React, { Component } from 'react';
import { URLImgFront } from '../../../config/URLDB';

class Chat extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stateMinimizar: true
        };
    }

    minimizar = () => {
        this.setState({
            stateMinimizar: !this.state.stateMinimizar
        })
    }

    Btncerrar = () => {
        this.props.cerrarM();
    }

    render() {
        const { stateMinimizar } = this.state;
        return (
            <React.Fragment>
                {stateMinimizar ? (
                    <React.Fragment>
                        <div className="diseño-flotante" style={{ width: '470px', height: '83%', overflowY: 'scroll', overflowX: 'hidden', bottom: '0px' }}>
                            <div className="card">
                                <div className="card-header py-0 px-0">
                                    <img width="70" className="img-fluid" src={`${URLImgFront}/assets/logo/iconoU.png`} alt="iconoU" />
                                    <p className="mt-3">Utrahuilca Chat</p>
                                    <div className="icons">
                                        <MDBIcon icon="minus" className="mx-2 icon" onClick={this.minimizar} />
                                        <MDBIcon icon="times" className="mx-2 icon" onClick={this.Btncerrar} />
                                    </div>
                                </div>
                                <div className="card-body py-0 px-0" style={{ background: '#f7f7f7' }}>
                                    <div className="container px-0">
                                        <div className="row">
                                            <div className="col-md-12 px-4 mx-3 py-3" style={{ background: '#fff' }}>
                                                <p>Para poder ayudarte necesitamos que ingreses la siguiente información</p>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-md-6 px-3">
                                                            <select className="browser-default custom-select">
                                                                <option>CC - Cédula de Ciudadania</option>
                                                                <option value="1">SI</option>
                                                                <option value="2">NO</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-md-6 px-3 pt-3">
                                                            <MDBInput label="Ingrese número de documento" type="text" className="mt-0" />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <MDBInput label="Nombre" type="text" className="mt-0" />
                                                        </div>
                                                        <div className="col-md-6 px-3">
                                                            <MDBInput label="Apellido" type="text" className="mt-0" />
                                                        </div>
                                                        <div className="col-md-12 px-3">
                                                            <MDBInput label="Correo Electrónico" type="text" className="mt-0" />
                                                        </div>
                                                        <div className="col-md-12 px-3">
                                                            <MDBInput label="Teléfono" type="text" className="mt-0" />
                                                        </div>
                                                        <div className="col-md-12 px-3">
                                                            <MDBInput label="En que podemos ayudarte" type="textarea" rows="3" className="mt-0" />
                                                        </div>
                                                        <div className="col-md-12 py-2 pb-5 px-3">
                                                            <div className="row">
                                                                <div className="col-md-7 d-flex">
                                                                    <input type="checkbox" className="form-control" />
                                                                    <p className="pl-3" style={{ color: 'gray' }}>He leído y acepto el tratamiento de mi información personal</p>
                                                                </div>
                                                                <div className="col-md-5">
                                                                    <span className="px-5 mt-3 py-1" style={{ background: '#3f8592', color: 'white', borderRadius: '20px', fontWeight: 'bold', fontSize: '20px', cursor: 'pointer' }}>Enviar</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12 py-3" style={{ background: '#f38b21' }}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                ) : (
                        <React.Fragment>
                            <div className="diseño-flotante" style={{ width: '470px' }}>
                                <div className="card">
                                    <div className="card-header py-0 px-0">
                                        <img width="70" className="img-fluid" src={`${URLImgFront}/assets/logo/iconoU.png`} alt="iconoU" />
                                        <p className="mt-3">Utrahuilca Chat</p>
                                        <div className="icons">
                                            <MDBIcon icon="minus" className="mx-2 icon" onClick={this.minimizar} />
                                            <MDBIcon icon="times" className="mx-2 icon" onClick={this.Btncerrar} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    )}
            </React.Fragment>
        );
    }
}

export default Chat;