import { MDBIcon, MDBInput, MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import React, { Component } from 'react';
import { CargueImagenes, SeccionPNaturalJuridica_, GrabarSeccionPNaturalJuridica_, SeccionPMaestroCoopVirtual_ } from '../../../services/WebAPI_Utrahuilca';
import FileUploaderB from './SPersona/FileUploaderB';
import FileUploader from './SPersona/FileUploader';
import { URLImgFront } from '../../../config/URLDB';
import Swal from 'sweetalert2';

const validate = values => {
    const errors = {}
    if (values.idtipopers === 'DEFAULT') {
        errors.idtipopers = 'campo obligatorio'
    }
    if (!values.titcoopvirtdet) {
        errors.titcoopvirtdet = 'campo obligatorio'
    }
    if (values.idmaestrocoopv === 'DEFAULT') {
        errors.idmaestrocoopv = 'campo obligatorio'
    }
    if (!values.desccoopvirtdet) {
        errors.desccoopvirtdet = 'campo obligatorio'
    }
    if (!values.piecoopvirtdet) {
        errors.piecoopvirtdet = 'campo obligatorio'
    }
    if (values.manejaenlace === 'DEFAULT') {
        errors.manejaenlace = 'campo obligatorio'
    }
    if (values.tipocargue === 'DEFAULT') {
        errors.tipocargue = 'campo obligatorio'
    }
    else {
        if (values.tipocargue === "1") {
            if (values.rutaimgdeta === '') {
                errors.rutaimgdeta = 'Falta Ingresar Enlace'
            }
        }
    }

    return errors;
};

class SPersona extends Component {
    uploader = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            estadoNuevo: false,
            errors: {},
            estadoCambioImg1: true,
            estadoSinInfo: false,
            infoSPersona: [],
            idcoopvirtdet: null,
            idtipopers: 'DEFAULT',
            idmaestrocoopv: 'DEFAULT',
            rutaimgdeta: '',
            titcoopvirtdet: '',
            desccoopvirtdet: '',
            piecoopvirtdet: '',
            editidtipopers: 'DEFAULT',
            editidmaestrocoopv: 'DEFAULT',
            edittitcoopvirtdet: '',
            editdesccoopvirtdet: '',
            editpiecoopvirtdet: '',
            estadoCambio: false,
            estado: null,
            manejaenlace: 'DEFAULT',
            rutaenlace: '',
            tipocargue: 'DEFAULT',
            edittipocargue: 'DEFAULT',
            infomaestroacordeon: []
        };
    }

    async componentDidMount() {
        this.cargarInformacion();
    }

    cargarInformacion = () => {
        const data = {
            tran: 4,
            idcoopvirtdet: 0,
            idtipopers: 0,
            rutaimgdeta: 'NA',
            titcoopvirtdet: 'NA',
            desccoopvirtdet: 'NA',
            piecoopvirtdet: 'NA',
            manejaenlace: 1,
            rutaenlace: 'NA',
            estado: 0,
            usucrea: 'NA',
            tipocargue: 0,
            idmaestrocoopv: 0
        }
        SeccionPNaturalJuridica_(data).then(response => {
            try {
                if (response.data.length > 0) {
                    if (!response.data[0].message) {
                        this.setState({
                            idSeleccionado: response.data[0].idcoopvirtdet
                        })
                        setTimeout(() => {
                            this.setState({
                                infoSPersona: response.data,
                                estadoSinInfo: true,
                                estadoImagen: true,
                            })
                        }, 120);
                    } else {
                        this.setState({
                            estadoSinResultados: false,
                            estadoNuevo: true,
                            estadoCambioImg1: true,
                            estadoSinInfo: false
                        })
                    }
                } else { }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    text: 'Ocurrio un error al consumir el servicio, comuniquese con sistemas!'
                })
            }
        });
    }

    textInput = (e) => {
        if (e.target.name === 'tipocargue') {
            this.setState({ rutaimgdeta: '' });
        }
        else if (e.target.name === 'edittipocargue') {
            this.setState({ rutaimgdeta: '' });
        }
        else if (e.target.name === 'idtipopers' || e.target.name === 'editidtipopers') {
            if (e.target.value !== 'DEFAULT') {
                this.CargarInfoAcordeon(e.target.value);
            }
        }

        this.setState({
            [e.target.name]: e.target.value
        })
    }

    cambioImagen1 = (name, file) => {
        this.setState({
            estadoCambioImg1: true
        });
    }

    cargarImagen = () => {
        var formData = new FormData();
        if (undefined === this.uploader.current) return;

        formData.append("file", this.uploader.current.getFileObject());
        formData.append("rutaImagen", '/Secc - Transaccional/Personas/' + this.uploader.current.getFileName());
        CargueImagenes(formData).then(response => {
            if (response) {
                this.cargarInformacion();
            }
        });
    }

    insertarDatos = () => {
        const { errors, ...sinErrors } = this.state;
        const result = validate(sinErrors);
        this.setState({ errors: result });
        if (!Object.keys(result).length) {
            var rutaimgguardar;
            if (this.state.tipocargue === 0) {
                rutaimgguardar = '/Secc - Transaccional/Personas/' + this.uploader.current.getFileName();
            }
            else {
                rutaimgguardar = this.state.rutaimgdeta;
            }

            // formulario validado
            const data = {
                tran: 1,
                idcoopvirtdet: 0,
                idtipopers: parseInt(this.state.idtipopers),
                rutaimgdeta: rutaimgguardar,
                titcoopvirtdet: this.state.titcoopvirtdet,
                desccoopvirtdet: this.state.desccoopvirtdet,
                piecoopvirtdet: this.state.piecoopvirtdet,
                manejaenlace: this.state.manejaenlace,
                rutaenlace: this.state.rutaenlace === '' ? 'NA' : this.state.rutaenlace,
                estado: 1,
                usucrea: sessionStorage.getItem('idusuario'),
                tipocargue: parseInt(this.state.tipocargue),
                idmaestrocoopv: parseInt(this.state.idmaestrocoopv)
            }

            GrabarSeccionPNaturalJuridica_(data).then(response => {
                if (response.data.length > 0) {
                    if (response.data[0].message === "000000") {

                        if (this.state.tipocargue == 0) {
                            this.cargarImagen();
                        }
                        Swal.fire({
                            icon: 'success',
                            title: 'Se agrego correctamente.'
                        })
                        this.setState({
                            estadoNuevo: false,
                            idcoopvirtdet: null,
                            estadoImagen: false,
                            displayColorPicker: false,
                            rutafondoImg: '',
                            idtipopers: 'DEFAULT',
                            idmaestrocoopv: 'DEFAULT',
                            rutaimgdeta: '',
                            titcoopvirtdet: '',
                            desccoopvirtdet: '',
                            piecoopvirtdet: '',
                            color: '',
                            tipocargue: 'DEFAULT',
                            manejaenlace: 'DEFAULT',
                            rutaenlace: ''
                        })
                        this.cargarInformacion();
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error al insertar.'
                        })
                    }
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Ocurrio un error!'
                    })
                }
            });

            //SeccionPNaturalJuridica_(data).then(response => {
            //    if (response.data.length > 0) {
            //        if (response.data[0].message === "000000") {
            //            this.cargarImagen();
            //            Swal.fire({
            //                icon: 'success',
            //                title: 'Se agrego correctamente.'
            //            })
            //            this.setState({
            //                estadoNuevo: false,
            //                idcoopvirtdet: null,
            //                estadoImagen: false,
            //                displayColorPicker: false,
            //                rutafondoImg: '',
            //                idtipopers: 0,
            //                rutaimgdeta: '',
            //                titcoopvirtdet: '',
            //                desccoopvirtdet: '',
            //                piecoopvirtdet: '',
            //                color: '',
            //                tipocargue: 0
            //            })
            //            if (this.state.tipocargue === 0) {
            //                this.cargarInformacion();
            //            }
            //        } else {
            //            Swal.fire({
            //                icon: 'error',
            //                title: 'Error al insertar.'
            //            })
            //        }
            //    } else {
            //        Swal.fire({
            //            icon: 'error',
            //            title: 'Ocurrio un error!'
            //        })
            //    }
            //});
        }
    }

    editar = (id) => {
        var seleccionado = [];
        this.state.infoSPersona.map((e, i) => {
            if (e.idcoopvirtdet === id) {
                seleccionado.push(e)
            }
            return [];
        })

        this.setState({
            idcoopvirtdet: seleccionado[0].idcoopvirtdet,
            editidtipopers: seleccionado[0].idtipopers,
            edittitcoopvirtdet: seleccionado[0].titcoopvirtdet,
            editdesccoopvirtdet: seleccionado[0].desccoopvirtdet,
            editpiecoopvirtdet: seleccionado[0].piecoopvirtdet,
            editmanejaenlace: seleccionado[0].manejaenlace,
            editrutaenlace: seleccionado[0].rutaenlace,
            rutaimgdeta: seleccionado[0].rutaimgdeta,
            estadoEditar: true,
            edittipocargue: seleccionado[0].tipocargue.toString(),
            editidmaestrocoopv: seleccionado[0].idmaestrocoopv,
        })
    }

    cambio = (name, file) => {
        this.setState({
            estadoCambio: true
        });
    }

    actualizarDatos = (id) => {
        var ruta = '';

        if (this.state.edittipocargue === '0') {
            var capNombre = this.uploader.current.getFileName();
            if (capNombre === undefined || capNombre === null) {
                ruta = this.state.rutaimgdeta;
            } else {
                ruta = '/Secc - Transaccional/Personas/' + this.uploader.current.getFileName();
            }
        } else {
            ruta = this.state.rutaimgdeta;
        }

        const data = {
            tran: 1,
            idcoopvirtdet: id,
            idtipopers: parseInt(this.state.editidtipopers),
            rutaimgdeta: ruta,
            titcoopvirtdet: this.state.edittitcoopvirtdet,
            desccoopvirtdet: this.state.editdesccoopvirtdet,
            piecoopvirtdet: this.state.editpiecoopvirtdet,
            manejaenlace: this.state.editmanejaenlace,
            rutaenlace: this.state.editrutaenlace === '' ? 'NA' : this.state.editrutaenlace,
            estado: 1,
            usucrea: sessionStorage.getItem('idusuario'),
            tipocargue: parseInt(this.state.edittipocargue),
            idmaestrocoopv: parseInt(this.state.editidmaestrocoopv)
        }

        GrabarSeccionPNaturalJuridica_(data).then(response => {
            if (response.data.length > 0) {
                if (response.data[0].message === "000000") {
                    if (this.state.edittipocargue == 0) {
                        this.cargarImagen();
                    }
                    Swal.fire({
                        icon: 'success',
                        title: 'Se agrego correctamente.'
                    })
                    this.setState({
                        estadoCambio: false,
                        estadoEditar: false,
                        rutaimgdeta: '',
                        editidtipopers: 'DEFAULT',
                        editidmaestrocoopv: 'DEFAULT',
                        edittitcoopvirtdet: '',
                        editdesccoopvirtdet: '',
                        editpiecoopvirtdet: '',
                        edittipocargue: 'DEFAULT'
                    })
                    Swal.fire({
                        icon: 'success',
                        title: 'Se actualizo satisfactoriamente'
                    })
                    this.cargarInformacion();
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error al insertar.'
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Ocurrio un error!'
                })
            }
        });


        //SeccionPNaturalJuridica_(data).then(response => {
        //    if (response.data.length > 0) {
        //        if (response.data[0].message === "000000") {

        //            if (this.state.edittipocargue === 0) {
        //                this.cargarImagen();
        //            }

        //            this.setState({
        //                estadoCambio: false,
        //                estadoEditar: false,
        //                rutaimgdeta: '',
        //                editidtipopers: 'DEFAULT',
        //                edittitcoopvirtdet: '',
        //                editdesccoopvirtdet: '',
        //                editpiecoopvirtdet: '',
        //                edittipocargue: 'DEFAULT'
        //            })
        //            Swal.fire({
        //                icon: 'success',
        //                title: 'Se actualizo satisfactoriamente'
        //            })
        //            this.cargarInformacion();
        //        } else {
        //            Swal.fire({
        //                icon: 'error',
        //                title: 'Ocurrio un error al actualizar'
        //            })
        //        }
        //    } else {
        //        Swal.fire({
        //            icon: 'error',
        //            title: 'Ocurrio un error!'
        //        })
        //    }
        //});
    }

    activar = (id) => {
        const data = {
            tran: 6,
            idcoopvirtdet: id,
            idtipopers: 0,
            rutaimgdeta: 'NA',
            titcoopvirtdet: 'NA',
            desccoopvirtdet: 'NA',
            piecoopvirtdet: 'NA',
            manejaenlace: 1,
            rutaenlace: 'NA',
            estado: 1,
            usucrea: sessionStorage.getItem('idusuario'),
            tipocargue: 0,
            idmaestrocoopv: 0
        }
        SeccionPNaturalJuridica_(data).then(response => {
            if (response) {
                this.setState({
                    estadoS: false
                })
                Swal.fire({
                    icon: 'success',
                    title: 'Se activo satisfactoriamente'
                });
                this.cargarInformacion();
            }
        });
    }

    inactivar = (id) => {
        const data = {
            tran: 6,
            idcoopvirtdet: id,
            idtipopers: 0,
            rutaimgdeta: 'NA',
            titcoopvirtdet: 'NA',
            desccoopvirtdet: 'NA',
            piecoopvirtdet: 'NA',
            manejaenlace: 1,
            rutaenlace: 'NA',
            estado: 0,
            usucrea: sessionStorage.getItem('idusuario'),
            tipocargue: 0,
            idmaestrocoopv: 0
        }
        SeccionPNaturalJuridica_(data).then(response => {
            if (response) {
                this.setState({
                    estadoS: false
                })
                Swal.fire({
                    icon: 'success',
                    title: 'Se inactivo satisfactoriamente'
                });
                this.cargarInformacion();
            }
        });
    }

    CargarInfoAcordeon = (id) => {
        const data = {
            tran: 7,
            idmaestrocoopv: 0,
            idtipopers: id,
            titulo: 'NA',
            rutaiconotit: 'NA',
            descripcion: 'NA',
            estado: 1,
            idusucrea: 0
        }
        SeccionPMaestroCoopVirtual_(data).then(response => {
            try {
                if (response.data.length > 0) {
                    if (response.data.length >= 1) {
                        if (response.data[0].message === "004404") {
                            this.setState({
                                infomaestroacordeon: []
                            })
                        } else {
                            this.setState({
                                infomaestroacordeon: response.data,
                            });
                        }
                    } else {
                        this.setState({
                            infomaestroacordeon: []
                        })
                    }
                } else {
                    this.setState({
                        infomaestroacordeon: []
                    })
                }
            } catch (error) {
                console.log(error);
            }
        });
    }

    handleNew = () => {
        this.setState({
            errors: {},
            estadoNuevo: !this.state.estadoNuevo,
        })
        if (this.state.estadoNuevo) {
            this.setState({
                errors: {}
            })
            this.cargarInformacion();
        }
    }

    render() {
        const { estadoNuevo, estadoCambioImg1, estadoCambio, estadoSinInfo, titcoopvirtdet, errors, desccoopvirtdet, rutaimgdeta, piecoopvirtdet, estadoEditar, idcoopvirtdet, idtipopers, idmaestrocoopv, editidmaestrocoopv, infoSPersona } = this.state;
        return (
            <React.Fragment>
                <p className="font-weight-bold">Persona (Natural - Juridica)</p>
                <hr />
                {estadoSinInfo ? (
                    <React.Fragment>
                        <div className="plus_add_agencia align-items-center d-flex" onClick={() => { this.handleNew() }}>
                            {estadoNuevo ? <p>Cancelar &nbsp;<MDBIcon icon="times" size="lg" /></p> : <p>Agregar Nuevo &nbsp;<MDBIcon icon="plus-circle" size="lg" /></p>}
                        </div>
                    </React.Fragment>
                ) : (
                    <React.Fragment></React.Fragment>
                )}
                {estadoNuevo ? (
                    <React.Fragment>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 my-2">
                                    <label>Tipo Cargue</label>
                                    <select className="form-control" name="tipocargue" value={this.state.tipocargue} onChange={this.textInput}>
                                        <option value="DEFAULT" disabled>Seleccione...</option>
                                        <option value="0">Imagen</option>
                                        <option value="1">Enlace</option>
                                    </select>
                                    {errors.tipocargue && <strong className="text-danger" style={{ fontSize: '13px' }}>{errors.tipocargue}</strong>}
                                </div>

                                <div className="col-md-6 my-2">
                                    <label>Tipo de Persona</label>
                                    <select className="form-control" name="idtipopers" value={idtipopers} onChange={this.textInput}>
                                        <option value="DEFAULT" disabled>Seleccione...</option>
                                        <option value="0">Persona Juridica</option>
                                        <option value="1">Persona Natural</option>
                                    </select>
                                    {errors.idtipopers && <strong className="text-danger" style={{ fontSize: '13px' }}>{errors.idtipopers}</strong>}
                                </div>

                                <div className="col-md-12 my-2">
                                    <label>Elemento del Acordeón</label>
                                    <select className="form-control" name="idmaestrocoopv" value={idmaestrocoopv} onChange={this.textInput}>
                                        <option value="DEFAULT" disabled>Seleccione...</option>
                                        {this.state.infomaestroacordeon.map((e, i) => {
                                            return [
                                                <option key={i} value={e.idmaestrocoopv}>{e.titulo}</option>
                                            ]
                                        })}
                                    </select>
                                    {errors.idmaestrocoopv && <strong className="text-danger" style={{ fontSize: '13px' }}>{errors.idmaestrocoopv}</strong>}
                                </div>

                                {this.state.tipocargue === "0" ?
                                    (
                                        <div className="col-md-12 my-2 text-center">
                                            <FileUploaderB ref={this.uploader} estadoImg={estadoCambioImg1} FCambiar={this.cambioImagen1} />
                                        </div>
                                    ) : (
                                        <div className="col-md-6 my-2 text-center">
                                            <MDBInput label="Enlace" name="rutaimgdeta" type="text" value={rutaimgdeta} onChange={this.textInput} />
                                            {errors.rutaimgdeta && <strong className="text-danger" style={{ fontSize: '13px' }}>{errors.rutaimgdeta}</strong>}
                                        </div>
                                    )
                                }
                                
                                <div className="col-md-6 my-2">
                                    <MDBInput label="Titulo" name="titcoopvirtdet" type="text" value={titcoopvirtdet} onChange={this.textInput} />
                                    {errors.titcoopvirtdet && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '50px' }}>{errors.titcoopvirtdet}</strong>}
                                </div>

                                <div className="col-md-6 my-2">
                                    <MDBInput label="Descripción" name="desccoopvirtdet" type="textarea" value={desccoopvirtdet} onChange={this.textInput} />
                                    {errors.desccoopvirtdet && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '58px' }}>{errors.desccoopvirtdet}</strong>}
                                </div>

                                <div className="col-md-6 my-2">
                                    <MDBInput label="Pie de texto" name="piecoopvirtdet" type="text" value={piecoopvirtdet} onChange={this.textInput} />
                                    {errors.piecoopvirtdet && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '50px' }}>{errors.piecoopvirtdet}</strong>}
                                </div>

                                <div className="col-md-6 my-2">
                                    <label>Maneja ruta</label>
                                    <select className="form-control" name="manejaenlace" value={this.state.manejaenlace} onChange={this.textInput}>
                                        <option value="DEFAULT" disabled>Seleccione...</option>
                                        <option value="0">NO</option>
                                        <option value="1">SI</option>
                                    </select>
                                    {errors.manejaenlace && <strong className="text-danger" style={{ fontSize: '13px' }}>{errors.manejaenlace}</strong>}
                                </div>

                                {this.state.manejaenlace === "1" ?
                                    (
                                        <div className="col-md-6 my-2 pt-4">
                                            <MDBInput label="Ruta de enlace" name="rutaenlace" type="text" value={this.state.rutaenlace} onChange={this.textInput} />
                                        </div>
                                    ) : (<React.Fragment></React.Fragment>)}
                            </div>
                        </div>

                        <div className="pt-2 mt-5 modal-footer w-100">
                            <button type="button" className="btn btn-primary px-5" onClick={this.insertarDatos}>Crear Producto &nbsp; <MDBIcon icon="save" /></button>
                        </div>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className="container-fluid">
                            <div className="table-responsive text-center w-100">
                                <MDBTable>
                                    <MDBTableHead>
                                        <tr className="font-weight-bold">
                                            <td>Tipo Cargue</td>
                                            <td>Enlace</td>
                                            <td>Tipo de Persona</td>
                                            <td>Elemento</td>
                                            <td>Titulo</td>
                                            <td>Descripción</td>
                                            <td>Pie de texto</td>
                                            <td>Maneja ruta</td>
                                            <td>Ruta enlace</td>
                                            {estadoEditar ?
                                                (
                                                    <React.Fragment>
                                                        <td>Guardar</td>
                                                        <td>Cancelar</td>
                                                    </React.Fragment>
                                                ) : (
                                                    <React.Fragment>
                                                        <td>Editar</td>
                                                        <td>Inactivar</td>
                                                    </React.Fragment>
                                                )}
                                        </tr>
                                    </MDBTableHead>
                                    <MDBTableBody>
                                        {estadoEditar ?
                                            (
                                                <React.Fragment>
                                                    <tr>
                                                        <td style={{ width: '6%' }}>
                                                            <select className="form-control" name="edittipocargue" value={this.state.edittipocargue} onChange={this.textInput}>
                                                                <option value="DEFAULT" disabled>Seleccione...</option>
                                                                <option value="0">Imagen</option>
                                                                <option value="1">Enlace</option>
                                                            </select>
                                                        </td>
                                                        {this.state.edittipocargue === "0" ? (
                                                            <td><FileUploader ref={this.uploader} estadoImg={estadoCambio} ruta={this.state.rutaimgdeta} FCambiar={this.cambio} /></td>
                                                        ) : (
                                                            <td style={{ width: '12%' }}><input label="Enlace" className="form-control" type="text" name="rutaimgdeta" value={this.state.rutaimgdeta} onChange={this.textInput} /></td>
                                                        )}
                                                        <td style={{ width: '14%' }}>
                                                            <select className="form-control" name="editidtipopers" defaultValue={this.state.editidtipopers} onChange={this.textInput}>
                                                                <option value="DEFAULT" disabled>Seleccione...</option>
                                                                <option value="0">Persona Juridica</option>
                                                                <option value="1">Persona Natural</option>
                                                            </select>
                                                        </td>
                                                        <td className="w-50">
                                                            <select className="form-control" name="editidmaestrocoopv" value={editidmaestrocoopv} onChange={this.textInput}>
                                                                <option value="DEFAULT" disabled>Seleccione...</option>
                                                                {this.state.infomaestroacordeon.map((e, i) => {
                                                                    return [
                                                                        <option key={i} value={e.idmaestrocoopv}>{e.titulo}</option>
                                                                    ]
                                                                })}
                                                            </select>
                                                        </td>
                                                        <td className="w-25"><input className="form-control" type="text" name="edittitcoopvirtdet" value={this.state.edittitcoopvirtdet} onChange={this.textInput} /></td>
                                                        <td className="w-25"><textarea className="form-control" type="textarea" name="editdesccoopvirtdet" rows="10" value={this.state.editdesccoopvirtdet} onChange={this.textInput} /></td>
                                                        <td className="w-25"><input className="form-control" type="text" name="editpiecoopvirtdet" value={this.state.editpiecoopvirtdet} onChange={this.textInput} /></td>
                                                        <td className="w-25">
                                                            <select className="form-control" name="editmanejaenlace" value={this.state.editmanejaenlace} onChange={this.textInput}>
                                                                <option value="DEFAULT" disabled>Seleccione...</option>
                                                                <option value="0">NO</option>
                                                                <option value="1">SI</option>
                                                            </select>
                                                        </td>
                                                        <td className="w-25">
                                                            <input className="form-control" type="text" name="editrutaenlace" value={this.state.editrutaenlace} onChange={this.textInput} />
                                                        </td>
                                                        <td className="text-center" onClick={() => { this.actualizarDatos(idcoopvirtdet) }}><MDBIcon icon="save" size="2x" style={{ color: 'blue', cursor: 'pointer' }} /></td>
                                                        <td className="text-center" onClick={() => { this.setState({ estadoEditar: !this.state.estadoEditar }) }}><MDBIcon icon="times" size="2x" style={{ color: 'red', cursor: 'pointer' }} /></td>
                                                    </tr>
                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment>
                                                    {infoSPersona.map((e, i) => {
                                                        let view;
                                                        if (e.estado === 1) {
                                                            view = <MDBIcon onClick={() => { this.inactivar(e.idcoopvirtdet) }} icon="eye" size="2x" style={{ cursor: 'pointer' }} />
                                                        } else {
                                                            view = <MDBIcon onClick={() => { this.activar(e.idcoopvirtdet) }} icon="eye-slash" size="2x" style={{ cursor: 'pointer' }} />
                                                        }
                                                        let tipoPersona = '';
                                                        if (e.idtipopers === 1) {
                                                            tipoPersona = "Persona Natural";
                                                        } else {
                                                            tipoPersona = "Persona Juridica";
                                                        }
                                                        return [
                                                            <React.Fragment key={i}>
                                                                <tr>
                                                                    <td>
                                                                        {e.tipocargue === 0 ? 'Imagen' : 'Enlace'}
                                                                        {/*<img width="180" src={`${URLImgFront}/assets${e.rutaimgdeta}`} alt={e.rutaimgdeta} />*/}
                                                                    </td>
                                                                    {e.tipocargue === 0 ? (
                                                                        <td><img src={`${URLImgFront}/assets` + e.rutaimgdeta} alt="Imagen" width="150px" /></td>
                                                                    ) : (
                                                                        <td>{e.rutaimgdeta}</td>
                                                                    )}
                                                                    <td>{tipoPersona}</td>
                                                                    <td>{e.titcoopvirtdet}</td>
                                                                    <td>{e.desccoopvirtdet}</td>
                                                                    <td>{e.piecoopvirtdet}</td>
                                                                    <td>{e.manejaenlace === 0 ? 'NO' : 'SI'}</td>
                                                                    <td>{e.rutaenlace}</td>
                                                                    <td className="text-center" onClick={() => { this.editar(e.idcoopvirtdet) }}><MDBIcon icon="edit" size="2x" style={{ color: 'green', cursor: 'pointer' }} /></td>
                                                                    <td className="text-center">{view}</td>
                                                                </tr>
                                                            </React.Fragment>
                                                        ]
                                                    })}
                                                </React.Fragment>
                                            )}
                                    </MDBTableBody>
                                </MDBTable>
                            </div>
                        </div>
                    </React.Fragment>
                )}

            </React.Fragment>
        );
    }
}

export default SPersona;