import { MDBIcon } from 'mdbreact';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { BeneficiosPrincipal_ } from '../../services/WebAPI_Utrahuilca';
import { URLImgFront } from '../../config/URLDB';

class Seccion1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            infoBeneficios: [],
            stateBene: true
        };
    }
    componentDidMount() {
        Aos.init({ duration: 1000 });
        this.cargarInformacion();
    }

    cargarInformacion = () => {
        const data = {
            tran: 4,
            idbenefutra: 0,
            titulobenef: 'NA',
            rutaiconobenef: 'NA',
            descbenef: 'NA',
            estado: 0,
            usucrea: 'NA'
        }
        BeneficiosPrincipal_(data).then(response => {
            try {
                if (response.data.length > 0) {
                    if (response.data.length >= 1) {
                        if (response.data[0].message === "004404") {
                            this.setState({
                                stateBene: false
                            })
                        } else {
                            this.setState({
                                infoBeneficios: response.data,
                                stateBene: true
                            })
                        }
                    } else {
                        this.setState({
                            stateBene: false
                        })
                    }
                } else { }
            } catch (error) {
                console.log(error);
            }
        });
    }

    render() {
        // const { infoBeneficios } = this.state;
        return (
            <React.Fragment>
                <div className="section1">
                    <div>
                        <div className="py-5" style={{ backgroundImage: `url("${URLImgFront}/assets/img-variadas/fondo_principal.png")`, height: '100%', width: '100%', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', objectFit: 'cover', backgroundAttachment: 'fixed' }}>
                            <div className="titulo text-center justify-content-center d-flex">
                                <p className="font-weight-bold py-2 w-25 text-center text-white" style={{ fontSize: '20px' }}>CONOZCA MÁS</p>
                            </div>
                            <div className="container">
                                <div className="row mt-3 px-2">
                                    <div className="col-md-4 my-3 " data-aos="fade-right">
                                        <Link to="/Servicios">
                                            <div className="card card_online">
                                                <div className="card-header px-0 py-0">
                                                    <img className="img-fluid" style={{ width: '100%' }} src={`${URLImgFront}/assets/img-variadas/IMAG NUESTROS SERVICIOS.png`} alt="IMAG NUESTROS SERVICIOS" />
                                                </div>
                                                <div className="card-body text-center py-4 text-dark">
                                                    <p>Nuestros Servicios</p>
                                                    <div className="mt-5 bg-white w-100 font-weight-bold mx-0" style={{ color: '#95bac1', fontSize: '17px' }}>Conózcalos <MDBIcon icon="arrow-right" size="lg" className="position-absolute" style={{ right: '20px', color: '#ed6b4e' }} /></div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-md-4 my-3" data-aos="fade-down">
                                        <Link to="/Cooperativa_Virtual">
                                            <div className="card card_online">
                                                <div className="card-header px-0 py-0">
                                                    <img className="img-fluid" style={{ width: '100%' }} src={`${URLImgFront}/assets/img-variadas/IMAG COOPERATIVA VIRTUAL.png`} alt="IMAG COOPERATIVA VIRTUAL" />
                                                </div>
                                                <div className="card-body text-center py-4 text-dark">
                                                    <p>Cooperativa Virtual</p>
                                                    <div className="mt-5 bg-white w-100 font-weight-bold mx-0" style={{ color: '#95bac1', fontSize: '17px' }}>Realice sus operaciones en línea <MDBIcon icon="arrow-right" size="lg" className="position-absolute" style={{ right: '10px', color: '#ed6b4e' }} /></div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-md-4 my-3" data-aos="fade-up">
                                        <Link to="/Beneficios">
                                            <div className="card card_online">
                                                <div className="card-header px-0 py-0">
                                                    <img className="img-fluid" style={{ width: '100%' }} src={`${URLImgFront}/assets/img-variadas/IMAG BENEFICIOS.png`} alt="IMAG BENEFICIOS" />
                                                </div>
                                                <div className="card-body text-center py-4 text-dark">
                                                    <p>Beneficios</p>
                                                    <div className="mt-5 bg-white w-100 font-weight-bold mx-0" style={{ color: '#95bac1', fontSize: '17px' }}>Conózcalos <MDBIcon icon="arrow-right" size="lg" className="position-absolute" style={{ right: '20px', color: '#ed6b4e' }} /></div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className="row px-2">
                                    <div className="col-md-4 my-3" data-aos="fade-right">
                                        <Link to="/Descargar_Formatos">
                                            <div className="card card_online">
                                                <div className="card-header px-0 py-0">
                                                    <img className="img-fluid" style={{ width: '100%' }} src={`${URLImgFront}/assets/img-variadas/IMAG FORMATOS.png`} alt="IMAG FORMATOS" />
                                                </div>
                                                <div className="card-body text-center py-4 text-dark">
                                                    <p>Formatos de solicitud</p>
                                                    <div className="mt-5 bg-white w-100 font-weight-bold mx-0" style={{ color: '#95bac1', fontSize: '17px' }}>Descargarlos ahora <MDBIcon icon="arrow-right" size="lg" className="position-absolute" style={{ right: '20px', color: '#ed6b4e' }} /></div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-md-4 my-3" data-aos="fade-up">
                                        <Link to="/coopsejos">
                                            <div className="card card_online">
                                                <div className="card-header px-0 py-0">
                                                    <img className="img-fluid" style={{ width: '100%' }} src={`${URLImgFront}/assets/img-variadas/IMAG NOTICIAS.png`} alt="IMAG NOTICIAS" />
                                                </div>
                                                <div className="card-body text-center py-4 text-dark">
                                                    <p>Noticias</p>
                                                    <div className="mt-5 bg-white w-100 font-weight-bold mx-0" style={{ color: '#95bac1', fontSize: '17px' }}>Entérese <MDBIcon icon="arrow-right" size="lg" className="position-absolute" style={{ right: '20px', color: '#ed6b4e' }} /></div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-md-4 my-3" data-aos="fade-up">
                                        <a href="http://www.fundautrahuilca.org/" target={'_blank'} rel="noopener noreferrer">
                                            <div className="card card_online">
                                                <div className="card-header px-0 py-0">
                                                    <img className="img-fluid" style={{ width: '100%' }} src={`${URLImgFront}/assets/img-variadas/IMG FUNDAUTRAHUILCA.png`} alt="IMG FUNDAUTRAHUILCA" />
                                                </div>
                                                <div className="card-body text-center py-4 text-dark">
                                                    <p className="text-dark">Fundación Social Utrahuilca</p>
                                                    <div className="mt-5 bg-white w-100 font-weight-bold mx-0" style={{ color: '#95bac1', fontSize: '17px' }}>Sitio Web <MDBIcon icon="arrow-right" size="lg" className="position-absolute" style={{ right: '20px', color: '#ed6b4e' }} /></div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Seccion1;