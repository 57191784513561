import React, { Component } from 'react';
import { URLImgFront } from '../config/URLDB';

class Loading extends Component {
    render() {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ position: 'fixed', background: 'rgba(0,0,0,.4)', zIndex: '9999999', width: '100%', height: '100vh', top: '0', left: '0', right: '0' }}>
                <img width="70" src={`${URLImgFront}/assets/img-variadas/loading.gif`} alt="loading.gif" />
            </div>
        );
    }
}

export default Loading;