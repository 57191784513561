import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './style.css';
import './Responsive.css';
import HeaderConstructor from './Header/HeaderConstructor';
import { MDBCollapse, MDBIcon } from 'mdbreact';
import { URLImgFront } from '../../config/URLDB';
import './lite-youtube.css';
import './lite-youtube.js';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            menuResponsive: false
        };
    }

    toggleCollapse = () => {
        this.setState({ isOpen: !this.state.isOpen });
    }

    render() {
        return (
            <React.Fragment>
                <div className="design-responsive-header-principal menu_principal" id="header" style={{ position: 'absolute', zIndex: '99', height: '118px', width: '100%' }}>
                    <nav className="navbar navbar-expand-lg bg-white" style={{ height: '100%', width: '100%' }}>
                        <Link to="/" style={{ cursor: 'pointer' }}>
                            <img className="img-logo_r d-none" style={{ cursor: 'pointer !important', objectFit: 'contain', height: '55px' }} src={`${URLImgFront}/assets/logo/logo.png`} alt="logo" />
                        </Link>
                        <button className="navbar-toggler" type="button" onClick={this.toggleCollapse} data-toggle="collapse" data-target="#mobile_nav" aria-controls="mobile_nav" aria-expanded="false" aria-label="Toggle navigation">
                            <MDBIcon icon="bars" />
                        </button>
                        <MDBCollapse className="collapse navbar-collapse" isOpen={this.state.isOpen} id="mobile_nav">
                            <HeaderConstructor id={this.props.id} />
                        </MDBCollapse>
                    </nav>
                </div>

            </React.Fragment>
        );
    }
}

export default Header;