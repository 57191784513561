import React, { Component } from 'react';
import { URLImgFront } from '../../config/URLDB';
import { Conozcanos_ } from '../../services/WebAPI_Utrahuilca';

class Seccion1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stateImg: false,
        };
    }

    async componentDidMount() {
        const data = {
            tran: 7,
            idvtaconozcanos: 0,
            nombreconozcanos: 'NA',
            rutaimgconozc: 'NA',
            txtconozcanos1: 'NA',
            txtconozcanos2: 'NA',
            colortexto: 'NA',
            colorlinea: 'NA',
            estado: 1,
            usucrea: 'NA'
        }
        Conozcanos_(data).then(response => {
            try {
                if (response.data.length > 0) {
                    if (response.data.length >= 1) {
                        if (response.data[0].message === "004404") {
                            this.setState({
                                stateImg: false
                            })
                        } else {
                            this.setState({
                                idvtaconozcanos: response.data[0].idvtaconozcanos,
                                nombreconozcanos: response.data[0].nombreconozcanos,
                                rutaimgconozc: response.data[0].rutaimgconozc,
                                txtconozcanos1: response.data[0].txtconozcanos1,
                                txtconozcanos2: response.data[0].txtconozcanos2,
                                colortexto: response.data[0].colortexto,
                                colorlinea: response.data[0].colorlinea,
                                stateImg: true
                            });
                        }
                    } else {
                        this.setState({
                            stateImg: false
                        })
                    }
                } else {
                    this.setState({
                        estadoNuevo: true
                    })
                }
            } catch (error) {
                console.log(error);
            }
        });
    }

    render() {
        const { stateImg } = this.state;
        return (
            <React.Fragment>
                <div className="bg-white" style={{ width: '100%', paddingTop: '120px' }}>
                    {stateImg ?
                        (
                            <img style={{ width: '100%' }} className="img-fluid" src={`${URLImgFront}/assets${this.state.rutaimgconozc}`} alt={this.state.rutaimgconozc} />
                        ) : (
                            <React.Fragment></React.Fragment>
                        )}
                    <div className="contenido_text_c">
                        <p className="font-weight-bold texto texto1" style={{ fontSize: '65px', color: '' + this.state.colortexto + '', position: 'absolute', marginLeft: 'auto', marginRight: 'auto', left: '0', right: '0', bottom: '250px', textAlign: 'center' }}>{this.state.txtconozcanos1}</p>
                        <p className="font-weight-bold texto texto2" style={{ fontSize: '20px', color: '' + this.state.colortexto + '', borderBottom: '2px solid ' + this.state.colorlinea + '', position: 'absolute', marginLeft: 'auto', marginRight: 'auto', left: '0', right: '0', bottom: '200px', textAlign: 'center', width: '450px' }}>{this.state.txtconozcanos2}</p>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Seccion1;