import React, { Component } from 'react';
import { URLImgFront } from '../../config/URLDB';

class Seccion1 extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    render() {
        return (
            <React.Fragment>
                <div className="seccion_transaccional_diseño" style={{ width: '100%', paddingTop: '120px' }}>
                    <img style={{ width: '100%', height: '40vh', objectFit: 'cover' }} className="img-fluid" src={`${URLImgFront}/assets/img_principal/PORTADA CONCURSOS.png`} alt="PORTADA CONCURSOS" />
                    <div className="contenido_text_tra text-center" style={{ position: 'absolute', top: '280px', left: '0', right: '0', zIndex: '2' }}>
                        <p style={{ fontSize: '20px', color: '#fff' }}>Nuestros </p>
                        <p style={{ fontSize: '40px', color: '#fff', fontWeight: 'bold' }}>EVENTOS Y CONCURSOS</p>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Seccion1;