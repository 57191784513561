import React, { Component } from 'react';
import SideNav, { NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import { MDBIcon } from 'mdbreact';
import ImagenTextoMP from '../Components/Modal Principal/ImagenTexto';
import Inicio from '../Components/Inicio';
import SliderPrincipal from '../Components/Principal/SliderPrincipal';
import DetallesCampañas from '../Components/Principal/DetallesCampañas';
import Beneficios from '../Components/Principal/Beneficios';
import VideosNuestAsociados from '../Components/Principal/VideosNuestAsociados'; //2022-11-22 NUEVO
import Agencias from '../Components/Red de Agencias/Agencias';
import AgenciaDetalles from '../Components/Red de Agencias/AgenciaDetalles';
import PersonaA from '../Components/Producto Ahorro/Persona/ImagenTexto';
import PersonaC from '../Components/Producto Credito/Persona/ImagenTexto';
import ImagenTextoSe from '../Components/Seguros/ImagenTexto';
import ImagenTextoCo from '../Components/Convenios/ImagenTexto';
import ConveniosDetalles from '../Components/Convenios/ConveniosDetalles';
import ImagenTextoA from '../Components/Asociados/ImagenTexto';
import AsociadosDetalles from '../Components/Asociados/AsociadosDetalles';
import ImagenTextoQA from '../Components/Quiero Asociarme/ImagenTexto';
import ImagenTextoTC from '../Components/Conozcanos/ImagenTexto';
import TrabajaConNosotros from '../Components/Conozcanos/TrabajaConNosotros';
import ImagenTextoST from '../Components/Seccion Transaccional/ImagenTexto';
import SPersona from '../Components/Seccion Transaccional/SPersona';
import Preguntas from '../Components/Preguntas Frecuentes/Preguntas';
import ImagenTextoPF from '../Components/Preguntas Frecuentes/ImagenTexto';
import ImagenTextoCoD from '../Components/Comunicados/ImagenTexto';
import ComunicadosDetalles from '../Components/Comunicados/ComunicadosDetalles';
import Llamanos from '../Components/Llamanos/Numeros';
import SCrédito from '../Components/Simulador/S_Crédito';
import SAhorro from '../Components/Simulador/S_Ahorro';
import { URLImgFront } from '../../config/URLDB';
import Producto from '../Components/Productos/Producto';
import ProductosDetalles from '../Components/Productos/ProductosDetalles';
import { getProfile } from '../../services/WebAPI_Utrahuilca';
import Swal from 'sweetalert2';
import TasasTarifas from '../Components/TasasTarifas/TasasTarifas';
import Coopsejos from '../Components/Conozcanos/Coopsejos';

class Principal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
            active: '1',
            nombre: '',
            cargo: '',
        };
    }

    componentDidMount() {
        this.loadProfile();
    }

    loadProfile() {
        if (sessionStorage.length > 0) {
            const dataparametro = {
                idusuario: parseInt(sessionStorage.getItem("idusuario")),
                usuario: sessionStorage.getItem("usuario"),
            }
            getProfile(dataparametro).then(response => {
                try {
                    if (response.data.length > 0) {
                        this.setState({
                            nombre: response.data[0].nombrecomp,
                            cargo: response.data[0].cargo
                        })
                    } else {
                        sessionStorage.removeItem('idusuario');
                        sessionStorage.removeItem('usuario');
                        this.props.history.push('/admin/login/utra');
                    }
                } catch (error) {
                    Swal.fire({
                        icon: 'error',
                        title: response.data[0].descMessage
                    })
                    sessionStorage.removeItem('idusuario');
                    sessionStorage.removeItem('usuario');
                    this.props.history.push('/admin/login/utra');
                }
            });
        } else {
            sessionStorage.removeItem('idusuario');
            sessionStorage.removeItem('usuario');
            this.props.history.push('/admin/login/utra');
        }
    }


    render() {
        const { expanded, active } = this.state;
        return (
            <React.Fragment>
                <SideNav
                    onSelect={(selected) => {
                        this.setState({
                            active: selected
                        });
                    }}
                    expanded={expanded}
                    onToggle={(expanded) => {
                        this.setState({ expanded });
                    }}
                    className="sidenav-design"
                >
                    <SideNav.Toggle
                        className="sideNavToggle"
                        onClick={() => this.setState({ expanded: !expanded })}
                    />
                    <SideNav.Nav className="sideNavNav">
                        {/* <SideNav.Nav defaultSelected={active} className="sideNavNav"> */}
                        <NavItem eventKey="1" className="items">
                            <NavIcon>
                                <MDBIcon icon="home" size="2x" />
                            </NavIcon>
                            <NavText>
                                Inicio
                            </NavText>
                        </NavItem>
                        <NavItem eventKey="0" className="items">
                            <NavIcon>
                                <MDBIcon icon="object-ungroup" size="2x" />
                            </NavIcon>
                            <NavText>
                                Modal Principal
                            </NavText>
                            <NavItem eventKey="01" className="item">
                                <NavText>
                                    Imagen
                                </NavText>
                            </NavItem>
                        </NavItem>
                        <NavItem eventKey="2" className="items">
                            <NavIcon>
                                <MDBIcon icon="object-group" size="2x" />
                            </NavIcon>
                            <NavText>
                                Principal
                            </NavText>
                            <NavItem eventKey="21" className="items">
                                <NavText>
                                    Slider Principal
                                </NavText>
                            </NavItem>
                            <NavItem eventKey="22" className="item">
                                <NavText>
                                    Detalles Campañas
                                </NavText>
                            </NavItem>
                            <NavItem eventKey="23" className="item">
                                <NavText>
                                    Beneficios
                                </NavText>
                            </NavItem>
                            <NavItem eventKey="24" className="item">
                                <NavText>
                                    Nuestros Asociados
                                </NavText>
                            </NavItem>
                        </NavItem>
                        <NavItem eventKey="3" className="items">
                            <NavIcon>
                                <MDBIcon icon="map-marked-alt" size="2x" />
                            </NavIcon>
                            <NavText>
                                Red de Agencias
                            </NavText>
                            <NavItem eventKey="31" className="item">
                                <NavText>
                                    Agencias
                                </NavText>
                            </NavItem>
                            <NavItem eventKey="32" className="item">
                                <NavText>
                                    Agencia - Detalles
                                </NavText>
                            </NavItem>
                        </NavItem>
                        <NavItem eventKey="4" className="items">
                            <NavIcon>
                                <MDBIcon icon="donate" size="2x" />
                            </NavIcon>
                            <NavText>
                                Productos
                            </NavText>
                            <NavItem eventKey="41" className="item">
                                <NavText>
                                    Lista Productos
                                </NavText>
                            </NavItem>
                            <NavItem eventKey="412" className="item">
                                <NavText>
                                    Ahorro
                                </NavText>
                            </NavItem>
                            <NavItem eventKey="51" className="item">
                                <NavText>
                                    Crédito
                                </NavText>
                            </NavItem>
                            <NavItem eventKey="42" className="item">
                                <NavText>
                                    Productos Detalles
                                </NavText>
                            </NavItem>
                        </NavItem>
                        {/* <NavItem eventKey="5" className="items">
                            <NavIcon>
                                <MDBIcon icon="hand-holding-usd" size="2x" />
                            </NavIcon>
                            <NavText>
                                Producto - Crédito
                            </NavText>
                            <NavItem eventKey="51" className="item">
                                <NavText>
                                    Persona Natural
                                </NavText>
                            </NavItem>
                            <NavItem eventKey="512" className="item">
                                <NavText>
                                    Persona Juridica
                                </NavText>
                            </NavItem>
                            <NavItem eventKey="52" className="item">
                                <NavText>
                                    Productos Detalles
                                </NavText>
                            </NavItem>
                        </NavItem> */}
                        <NavItem eventKey="7" className="items">
                            <NavIcon>
                                <MDBIcon icon="shield-alt" size="2x" />
                            </NavIcon>
                            <NavText>
                                Seguros
                            </NavText>
                            <NavItem eventKey="61" className="item">
                                <NavText>
                                    Imagen - Texto
                                </NavText>
                            </NavItem>
                        </NavItem>
                        <NavItem eventKey="8" className="items">
                            <NavIcon>
                                <MDBIcon icon="hands-helping" size="2x" />
                            </NavIcon>
                            <NavText>
                                Convenios
                            </NavText>
                            <NavItem eventKey="71" className="item">
                                <NavText>
                                    Imagen - Texto
                                </NavText>
                            </NavItem>
                            <NavItem eventKey="72" className="item">
                                <NavText>
                                    Convenio Detalles
                                </NavText>
                            </NavItem>
                        </NavItem>
                        <NavItem eventKey="9" className="items">
                            <NavIcon>
                                <MDBIcon icon="heart" size="2x" />
                            </NavIcon>
                            <NavText>
                                Asociados
                            </NavText>
                            <NavItem eventKey="81" className="item">
                                <NavText>
                                    Imagen - Texto
                                </NavText>
                            </NavItem>
                            {/* <NavItem eventKey="82" className="item">
                                <NavText>
                                    Asociados Detalles
                                </NavText>
                            </NavItem> */}
                        </NavItem>
                        <NavItem eventKey="10" className="items">
                            <NavIcon>
                                <MDBIcon icon="user-check" size="2x" />
                            </NavIcon>
                            <NavText>
                                Quiero Asociarme
                            </NavText>
                            <NavItem eventKey="91" className="item">
                                <NavText>
                                    Imagen - Texto
                                </NavText>
                            </NavItem>
                        </NavItem>
                        <NavItem eventKey="11" className="items">
                            <NavIcon>
                                <MDBIcon icon="newspaper" size="2x" />
                            </NavIcon>
                            <NavText>
                                Nuestra Cooperativa
                            </NavText>
                            <NavItem eventKey="101" className="item">
                                <NavText>
                                    Conózcanos
                                </NavText>
                            </NavItem>
                            <NavItem eventKey="102" className="item">
                                <NavText>
                                    Coopsejos
                                </NavText>
                            </NavItem>
                            <NavItem eventKey="103" className="item">
                                <NavText>
                                    Trabaja con Nosotros
                                </NavText>
                            </NavItem>
                        </NavItem>
                        <NavItem eventKey="12" className="items">
                            <NavIcon>
                                <MDBIcon icon="money-bill-wave-alt" size="2x" />
                            </NavIcon>
                            <NavText>
                                Secc - Transaccional
                            </NavText>
                            <NavItem eventKey="111" className="item">
                                <NavText>
                                    Imagen - Texto
                                </NavText>
                            </NavItem>
                            <NavItem eventKey="112" className="item">
                                <NavText>
                                    Elemento (Natural - Juridica)
                                </NavText>
                            </NavItem>
                            <NavItem eventKey="113" className="item">
                                <NavText>
                                    Detalle Elemento (Natural - Juridica)
                                </NavText>
                            </NavItem>
                        </NavItem>
                        <NavItem eventKey="13" className="items">
                            <NavIcon>
                                <MDBIcon icon="question" size="2x" />
                            </NavIcon>
                            <NavText>
                                Preguntas Frecuentes
                            </NavText>
                            <NavItem eventKey="121" className="item">
                                <NavText>
                                    Imagen - Texto
                                </NavText>
                            </NavItem>
                            <NavItem eventKey="122" className="item">
                                <NavText>
                                    Preguntas
                                </NavText>
                            </NavItem>
                        </NavItem>
                        <NavItem eventKey="14" className="items">
                            <NavIcon>
                                <MDBIcon icon="laptop-code" size="2x" />
                            </NavIcon>
                            <NavText>
                                Comunicados
                            </NavText>
                            <NavItem eventKey="131" className="item">
                                <NavText>
                                    Imagen - Texto
                                </NavText>
                            </NavItem>
                            <NavItem eventKey="132" className="item">
                                <NavText>
                                    Comunicados/Formatos
                                </NavText>
                            </NavItem>
                        </NavItem>
                        <NavItem eventKey="15" className="items">
                            <NavIcon>
                                <MDBIcon icon="phone-alt" size="2x" />
                            </NavIcon>
                            <NavText>
                                Llamanos - Robot
                            </NavText>
                            <NavItem eventKey="141" className="item">
                                <NavText>
                                    Numeros
                                </NavText>
                            </NavItem>
                        </NavItem>
                        <NavItem eventKey="16" className="items">
                            <NavIcon>
                                <MDBIcon icon="calculator" size="2x" />
                            </NavIcon>
                            <NavText>
                                Simulador
                            </NavText>
                            <NavItem eventKey="151" className="item">
                                <NavText>
                                    Ahorro
                                </NavText>
                            </NavItem>
                            <NavItem eventKey="152" className="item">
                                <NavText>
                                    Crédito
                                </NavText>
                            </NavItem>
                        </NavItem>
                        <NavItem eventKey="17" className="items">
                            <NavIcon>
                                <MDBIcon icon="file-alt" size="2x" />
                            </NavIcon>
                            <NavText>
                                Documentos
                            </NavText>
                            <NavItem eventKey="161" className="item">
                                <NavText>
                                    Tasas y Tarifas
                                </NavText>
                            </NavItem>
                        </NavItem>
                    </SideNav.Nav>
                </SideNav>
                <main className="pl-5 ml-5 py-5 pr-5 container_fondo">
                    <img className="img-fluid img_logo" src={`${URLImgFront}/assets/logo/logo.png`} alt="logo" />
                    <div className="contenedor">
                        {(() => {
                            switch (active) {
                                case '1': return <Inicio usuario={this.state.nombre} cargo={this.state.cargo} />;
                                case '01': return <ImagenTextoMP />;
                                {/*INICIO TAB PRINCIPAL*/}
                                case '21': return <SliderPrincipal />;
                                case '22': return <DetallesCampañas />;
                                case '23': return <Beneficios />;
                                case '24': return <VideosNuestAsociados />;
                                {/*FIN TAB PRINCIPAL*/}
                                case '31': return <Agencias />;
                                case '32': return <AgenciaDetalles />;
                                case '41': return <Producto />;
                                case '412': return <PersonaA />;
                                case '42': return <ProductosDetalles />;
                                case '51': return <PersonaC />;
                                case '61': return <ImagenTextoSe />;
                                case '71': return <ImagenTextoCo />;
                                case '72': return <ConveniosDetalles />;
                                case '81': return <ImagenTextoA />;
                                case '82': return <AsociadosDetalles />;
                                case '91': return <ImagenTextoQA />;
                                case '101': return <ImagenTextoTC />;
                                case '102': return <Coopsejos />;
                                case '103': return <TrabajaConNosotros />;
                                case '111': return <ImagenTextoST />;
                                //case '112': return <SPersona />;
                                case '113': return <SPersona />;
                                case '121': return <ImagenTextoPF />;
                                case '122': return <Preguntas />;
                                case '131': return <ImagenTextoCoD />;
                                case '132': return <ComunicadosDetalles />;
                                case '141': return <Llamanos />;
                                case '151': return <SAhorro />;
                                case '152': return <SCrédito />;
                                case '161': return <TasasTarifas />;
                                default: return <Inicio />;
                            }
                        })()}
                    </div>
                </main>
            </React.Fragment>
        );
    }
}

export default Principal;