import React, { Component } from 'react';
import { URLImgFront } from '../../../../config/URLDB';
import { NoticiasDetallesEspecificas_ } from '../../../../services/WebAPI_Utrahuilca';
import Iframes from '../../../Iframes/Iframes';
import MensajeF from '../../../Mensaje_Flotante/MensajeF';
import Footer from '../../../Principal/Footer';
import Header from '../../../Principal/Header';

class Detalles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            infoNoticiasDetallesEspe: [],
            stateloadInfo: false
        };
    }

    async componentDidMount() {
        this.cargarInformacion();
    }

    cargarInformacion = () => {
        const data = {
            tran: 8,
            idnotiespecif: 0,
            idnotidet: parseInt(this.props.match.params.id),
            ordennotiespecif: 0,
            urlnotiespecif: 'NA',
            txtnotiespecif1: 'NA',
            txtnotiespecif2: 'NA',
            txtnotiespecif3: 'NA',
            txtnotiespecif4: 'NA',
            tipourlnotiespecif: 0,
            estadonotiespecif: 1,
            usucrea: 'NA'
        }
        NoticiasDetallesEspecificas_(data).then(response => {
            try {
                if (response.data.length > 0) {
                    var fecha = new Date(response.data[0].fecinipublinotidet);
                    var Fecha = fecha.getDate() + "/" + (fecha.getMonth() + 1).toString().padStart(2, '0') + "/" + fecha.getFullYear();
                    this.setState({
                        infoNoticiasDetallesEspe: response.data,
                        tiponoticia: response.data[0].tiponoticia,
                        titulonotidet: response.data[0].titulonotidet,
                        descripnotidet: response.data[0].descripnotidet,
                        feccrea: Fecha,
                        stateloadInfo: true
                    })
                } else { }
            } catch (error) {
                console.log(error);
            }
        });
    }

    render() {
        return (
            <React.Fragment>
                <Header />
                <MensajeF />
                <div className="container py-5">
                    <div className="row pt-5 mt-5">
                        {this.state.stateloadInfo ? (<React.Fragment>
                            <div className="col-md-12">
                                <p className="px-4 py-2 my-0 mr-4" style={{ background: '#FA7500', color: '#fff', borderRadius: '1px', fontSize: '20px' }}>{this.state.tiponoticia}</p>
                                <p className="pt-4 font-weight-bold my-0" style={{ fontSize: '25px' }}>{this.state.titulonotidet}</p>
                                <p className="text-left mt-0" style={{ fontSize: '15px', color: 'gray' }}>{this.state.feccrea}</p>
                                <p className="mt-0 pb-5">{this.state.descripnotidet}</p>
                            </div>
                            {this.state.infoNoticiasDetallesEspe.map((e, i) => {
                                return [
                                    <React.Fragment key={i}>
                                        {e.urlnotiespecif === 'N/A' ?
                                            (
                                                <React.Fragment></React.Fragment>
                                            ) : (
                                                <React.Fragment>
                                                    <div className="col-md-12 text-center">
                                                        {e.tipourlnotiespecif === 2 ?
                                                            (
                                                                <Iframes className="py-5" ancho={'800px'} alto={'400px'} url={e.urlnotiespecif} />
                                                            ) : (
                                                                <img src={`${URLImgFront}/assets${e.urlnotiespecif}`} alt={e.urlnotiespecif} className="img-fluid py-5" />
                                                            )}
                                                    </div>
                                                </React.Fragment>
                                            )}
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-12 text-justify">
                                                    <p dangerouslySetInnerHTML={{__html: e.txtnotiespecif1}} />
                                                    <p dangerouslySetInnerHTML={{__html: e.txtnotiespecif2}} />
                                                </div>
                                                <div className="col-md-12 text-justify">
                                                    <p dangerouslySetInnerHTML={{__html: e.txtnotiespecif3}} />
                                                </div>
                                                <div className="col-md-12 text-justify">
                                                    <p dangerouslySetInnerHTML={{__html: e.txtnotiespecif4}} />
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                ]
                            })}
                        </React.Fragment>) : (<React.Fragment></React.Fragment>)}
                    </div>
                </div>
                <Footer />
            </React.Fragment>
        );
    }
}

export default Detalles;