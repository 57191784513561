import React, { Component } from 'react';
import FileUploader from "./ImagenTexto/FileUploader";
import { MDBIcon, MDBInput } from 'mdbreact';
// import DatePicker from 'react-date-picker';
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import "moment/locale/es-us";
import { ModalPrincipal, GrbModalPrincipal_, CargueImagenes } from '../../../services/WebAPI_Utrahuilca';
import Swal from 'sweetalert2';

class ImagenTexto extends Component {
    uploader = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            value: '',
            fechaInicial: '',
            fechaFinal: '',
            estadoModal: null,
            nombreModal: '',
            rutaModal: '',
            estadoCargar: false,
            estadoCambio: false,
            estadoTiempo: false,
            rutavistainfo: ''
        };
    }

    componentDidMount() {
        this.CargarInformacion();
    }

    textInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    CargarInformacion() {
        const data = {
            tran: 7,
            nombremodal: 'NA',
            rutaimgmodal: 'NA',
            fecinimodal: '01/01/1900',
            fecfinmodal: '01/01/1900',
            rutavistainfo: 'NA',
            usucrea: 'NA'
        }
        ModalPrincipal(data).then(response => {
            try {
                if (response.data.length > 0) {
                    let esModal = '';
                    if (response.data[0].estado === 1) {
                        esModal = 'Activo';
                        this.setState({
                            estadoTiempo: true
                        })
                    } else {
                        esModal = 'Inactivo';
                        this.setState({
                            estadoTiempo: false
                        })
                    }
                    if (response) {
                        this.setState({
                            fechaInicial: new Date(response.data[0].fecinimodal),
                            fechaFinal: new Date(response.data[0].fecfinmodal),
                            estadoModal: esModal,
                            nombreModal: response.data[0].nombremodal,
                            rutaModal: response.data[0].rutaimgmodal,
                            rutavistainfo: response.data[0].rutavistainfo,
                            estadoCargar: true
                        })
                    }
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error al actualizar'
                    })
                }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    text: 'Ocurrio un error al consumir el servicio!'
                })
            }
        });
    }

    onChangeInicial = (e) => {
        this.setState({
            fechaInicial: e
        })
    }

    onChangeFinal = (e) => {
        this.setState({
            fechaFinal: e
        })
    }

    cambio = (name, file) => {
        this.setState({
            estadoCambio: true
        });
    }

    actualizarDatos = () => {
        var ruta = '';
        var nombrem = '';
        var estadoruta = 0;
        var capNombre = this.uploader.current.getFileName();
        if (capNombre === undefined || capNombre === null) {
            estadoruta = 0;
            ruta = this.state.rutaModal;
            nombrem = this.state.nombreModal;
        } else {
            estadoruta = 1;
            ruta = '/Modal Principal/' + this.uploader.current.getFileName();
            nombrem = this.uploader.current.getFileName();
        }
        const data = {
            nombremodal: nombrem,
            rutaimgmodal: ruta,
            fecinimodal: new Date(this.state.fechaInicial),
            fecfinmodal: new Date(this.state.fechaFinal),
            manejavistainfo: 0,
            rutavistainfo: this.state.rutavistainfo,
            estado: 0,
            usucrea: sessionStorage.getItem('idusuario')
        }
        GrbModalPrincipal_(data).then(response => {
            try {
                if (response.data.length > 0) {
                    if (response.data[0].message === "000000") {
                        Swal.fire({
                            icon: 'success',
                            title: 'Se actualizo correctamente'
                        })
                        if (estadoruta === 1) {
                            this.cargarImagen();
                        } else {
                            this.CargarInformacion();
                        }
                    } else {
                        this.setState({
                            estadoModal: false
                        })
                    }
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error al actualizar'
                    })
                }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    text: 'Ocurrio un error al consumir el servicio!'
                })
            }
        });
    }

    cargarImagen = () => {
        var formData = new FormData();
        if (undefined === this.uploader.current) return;
        formData.append("file", this.uploader.current.getFileObject());
        formData.append("rutaImagen", "/Modal Principal/" + this.uploader.current.getFileName());
        CargueImagenes(formData).then(response => {
            if (response) {
                this.CargarInformacion();
            }
        });
    }

    render() {
        const { fechaInicial, fechaFinal, estadoModal, rutaModal, estadoCargar, estadoCambio } = this.state;
        let inputPropsInitial = {
            placeholder: fechaInicial,
        };
        let inputPropsFinal = {
            placeholder: fechaFinal,
        };
        return (
            <React.Fragment>
                <p>Imagen - Modal Principal</p>
                <hr />
                <div className="justify-content-center d-flex">
                    <div className="text-center">
                        {estadoCargar ? (
                            <React.Fragment>
                                <FileUploader ref={this.uploader} estado={estadoCambio} rutaModal={rutaModal} FCambiar={this.cambio} />
                            </React.Fragment>
                        ) : (
                            <React.Fragment></React.Fragment>
                        )}
                    </div>
                </div>
                <div className="container py-4">
                    <div className="row">
                        <div className="col-md-4">
                            <p className="my-1">Fecha Inicial</p>
                            <Datetime
                                onChange={this.onChangeInicial}
                                value={fechaInicial}
                                locale="es-us"
                                inputProps={inputPropsInitial}
                            />
                        </div>
                        <div className="col-md-4">
                            <p className="my-1">Fecha Final</p>
                            <Datetime
                                onChange={this.onChangeFinal}
                                value={fechaFinal}
                                locale="es-us"
                                inputProps={inputPropsFinal}
                            />
                        </div>
                        <div className="col-md-4">
                            <p className="mb-1">Estado Actual</p>
                            <div className={this.state.estadoTiempo ? "alert alert-success my-0 py-2" : "alert alert-danger my-0"}>{estadoModal}</div>
                        </div>
                        <div className="col-md-12 py-3">
                            <MDBInput name="rutavistainfo" label="Ruta redireccionar vista - no obligatorio" value={this.state.rutavistainfo} onChange={this.textInput} />
                        </div>
                    </div>
                </div>
                <div className="pt-2 mt-5 modal-footer w-100">
                    <button type="button" className="btn btn-success px-5" onClick={() => { this.actualizarDatos() }}>Guardar cambios &nbsp; <MDBIcon icon="save" /></button>
                </div>

            </React.Fragment>
        );
    }
}

export default ImagenTexto;