import React, { Component } from 'react';
import 'swiper/css/swiper.min.css';
// import { URLImgFront } from '../../config/URLDB';

class Seccion2 extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    render() {
        return (
            <React.Fragment>
                <div className="bg-white" style={{ width: '100%' }}>
                    <div className="container">
                        <div className="py-5">
                            {/* <p className="texto-contenido font-weight-bold mt-5">YO SOY DIGITAL</p>
                            <div className="row py-4 pb-5">
                                <div className="col-md-5">
                                    <img className="img-fluid" src={`${URLImgFront}/assets/img-variadas/yo_soy_digital.jpg`} alt="img-yosoydigital" />
                                </div>
                                <div className="col-md-7 pl-0">
                                    <div className="d-flex pb-4 pt-4">
                                        <img style={{ width: '40px', height: '40px' }} className="img-fluid" src={`${URLImgFront}/assets/img-variadas/seguros_ok.png`} alt="img-seguros_ok" />
                                        <p className="texto-contenido pl-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                    </div>
                                    <div className="d-flex py-4">
                                        <img style={{ width: '40px', height: '40px' }} className="img-fluid" src={`${URLImgFront}/assets/img-variadas/seguros_ok.png`} alt="img-seguros_ok" />
                                        <p className="texto-contenido pl-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                    </div>
                                    <div className="d-flex py-4">
                                        <img style={{ width: '40px', height: '40px' }} className="img-fluid" src={`${URLImgFront}/assets/img-variadas/seguros_ok.png`} alt="img-seguros_ok" />
                                        <p className="texto-contenido pl-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                    </div>
                                    <div className="d-flex py-4">
                                        <img style={{ width: '40px', height: '40px' }} className="img-fluid" src={`${URLImgFront}/assets/img-variadas/seguros_ok.png`} alt="img-seguros_ok" />
                                        <p className="texto-contenido pl-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                    </div>
                                    <div className="d-flex pt-4">
                                        <img style={{ width: '40px', height: '40px' }} className="img-fluid" src={`${URLImgFront}/assets/img-variadas/seguros_ok.png`} alt="img-seguros_ok" />
                                        <p className="texto-contenido pl-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                    </div>
                                </div>
                            </div>
                            <p>Aquí puedes verificar si eres uno de los ganadores.</p> */}
                            <div className="alert alert-warning">En este momento no tenemos eventos ni concursos vigentes. </div>
                        </div>
                    </div>
                    {/* <div className="pb-5" style={{ background: '#f7f7f7' }}>
                        <div className="container">
                            <div className="row py-5 pb-5">
                                <div className="col-md-6 px-5">
                                    <p className="text-left" style={{ fontSize: '25px', fontWeight: 'bold', color: 'gray' }}>TIPO DE DOCUMENTO</p>
                                    <MDBInput label="Ingrese número de documento" type="text" className="pt-4" />
                                </div>
                                <div className="col-md-6 px-5">
                                    <p className="text-left" style={{ fontSize: '25px', fontWeight: 'bold', color: 'gray' }}>DOCUMENTO</p>
                                    <MDBInput label="Ingrese número de documento" type="text" className="pt-4" />
                                </div>
                                <div className="col-md-6 px-5">
                                    <p className="text-left" style={{ fontSize: '25px', fontWeight: 'bold', color: 'gray' }}>FECHA DE EXPEDICIÓN</p>
                                    <MDBInput type="date" className="pt-4" />
                                </div>
                                <div className="col-md-6 d-flex align-items-center justify-content-center">
                                    <span className="px-5 text-white py-2" style={{ background: '#3f8592', borderRadius: '20px', cursor: 'pointer' }}>Consultar</span>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </React.Fragment>
        );
    }
}

export default Seccion2;