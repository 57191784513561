import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { URLImgFront } from '../../../config/URLDB';

class Seccion07 extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="pt-3 pb-3 pt-md-5" style={{ background: '#0B6A85', height: '100%', width: '100%' }}>
                    <div className="contianer-fluid">
                        <div className="row mx-0">
                            <div className="col-1 px-0"></div>
                            <div className="col-10 px-0">
                                <h3 className="text-center pb-1 pb-sm-2" style={{ fontFamily: 'Posterama 1927', color: '#fff', fontSize: '4.4vw', fontWeight: 'bold' }}>Mecanismo del Sorteo</h3>
                                <p className="text-center" style={{ fontFamily: 'Posterama 1927', color: '#fff', fontSize: '2.5vmin', lineHeight: 'normal' }}>Los sorteos se realizarán mediante el software denominado <span style={{ fontWeight: 'bold' }}>"UTRASORTEO"</span>, creado por UTRAHUILCA, el cual ya cuenta con la base de los números previamente asignados para participar del o los sorteo(s). Este opera a través de un usuario administrdor autorizado por UTRAHUILCA, quien ejecuta el proceso, donde elegirá de manera aleatoria el o los número(s) ganador(es) dentro del rango de números asignados.</p>
                                <p className="text-center" style={{ fontFamily: 'Posterama 1927', color: '#fff', fontSize: '2.5vmin', lineHeight: 'normal' }}>En cada sorteo se elegirá simultáneamente por cada asociado ganador principal, dos (2) ganadores suplentes, siendo uno de ellos suplente principal y el otro suplente, con el propósito de garantizar que en cada sorteo los premios queden en poder de los asociados.</p>
                            </div>
                            <div className="col-1 px-0"></div>
                        </div>
                    </div>
                    <div className="pb-1 pb-sm-2 pb-md-2 pb-lg-2" style={{ width: '55vmin', height: '7vmin', display: 'block', margin: 'auto' }}>
                        <Link to={`/Documentos/TermYCondiciones_TodosPonenTodosGananUTRAHUILCA.pdf/0`} target='_blank'>
                            <div onClick={this.ModalPopupNumeroSorteo} className="button-img" style={{ backgroundImage: `url("${URLImgFront}/assets/EstrategiasComerciales/TPTG_BOTON_TERMINOS_CONDICIONES.png")` }}></div>
                        </Link>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default Seccion07;