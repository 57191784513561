import { MDBIcon } from 'mdbreact';
import React, { Component } from 'react';
import { URLImgFront } from '../../config/URLDB';
import { Convenios_ } from '../../services/WebAPI_Utrahuilca';
import { withRouter } from 'react-router-dom';

class Seccion1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stateImg: false,
            stateOri: false,
            namePers: ''
        };
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate() {
        if (this.props.name !== this.state.namePers) {
            this.loadData();
        } else {

        }
    }

    loadData = () => {
        var nameP = this.props.name;
        if (nameP === "Personas" || nameP === "Empresas") {
            const data = {
                tran: 8,
                idvtaconvenio: 0,
                idtipopers: nameP === 'Personas' ? 1 : 0,
                nombconvenio: 'NA',
                rutafondoconv: 'NA',
                txtconvenio1: 'NA',
                txtconvenio2: 'NA',
                colortexto: 'NA',
                estado: 1,
                usucrea: 'NA'
            }
            Convenios_(data).then(response => {
                try {
                    if (response.data.length > 0) {
                        if (response.data.length >= 1) {
                            if (response.data[0].message === "004404") {
                                this.setState({
                                    stateImg: false,
                                    idtipopers: 0,
                                    nombconvenio: '',
                                    idvtaconvenio: '',
                                    rutafondoconv: '',
                                    txtconvenio1: '',
                                    txtconvenio2: '',
                                    colortexto: '',
                                    namePers: 'Personas'
                                })
                            } else {
                                this.setState({
                                    idtipopers: response.data[0].idtipopers,
                                    nombconvenio: response.data[0].nombconvenio,
                                    idvtaconvenio: response.data[0].idvtaconvenio,
                                    rutafondoconv: response.data[0].rutafondoconv,
                                    txtconvenio1: response.data[0].txtconvenio1,
                                    txtconvenio2: response.data[0].txtconvenio2,
                                    colortexto: response.data[0].colortexto,
                                    stateImg: true,
                                    namePers: this.props.name
                                });
                            }
                        } else {
                            this.setState({
                                stateImg: false
                            })
                        }
                    } else {
                        console.log(response);
                    }
                } catch (error) {
                    console.log(error);
                }
            });
        } else {
            this.props.history.push('/404')
        }
    }

    render() {
        const { stateImg } = this.state;
        return (
            <React.Fragment>
                <div className="diseño_responsive_convenios" style={{ width: '100%', paddingTop: '120px' }}>
                    {stateImg ?
                        (
                            <img style={{ width: '100%', height: '100vh', marginBottom: '25px', objectFit: 'cover' }} className="img-fluid" src={`${URLImgFront}/assets${this.state.rutafondoconv}`} alt="img1" />
                        ) : (
                            <React.Fragment></React.Fragment>
                        )}
                    <span className="ml-4 pt-4" style={{ fontSize: '20px' }}><MDBIcon icon="caret-right" /> {this.props.name} &nbsp; <MDBIcon icon="caret-right" /> Convenios</span>
                    <div className="info_convenios" style={{ position: 'absolute', bottom: '100px', right: '260px' }}>
                        <p className="font-weight-bold text-right mb-0" style={{ fontSize: '50px', color: '' + this.state.colortexto + '' }}>{this.state.txtconvenio1}</p>
                        <p className="pr-0 mr-0 text-right mt-0" style={{ fontSize: '30px', color: '' + this.state.colortexto + '', fontWeight: 'bold' }}>{this.state.txtconvenio2}</p>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(Seccion1);