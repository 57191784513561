import React, { Component } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './style.css';
import Swal from 'sweetalert2';
import { PreguntasFrecuentesLista_ } from '../../services/WebAPI_Utrahuilca';
import { URLImgFront } from '../../config/URLDB';
import { Link } from 'react-router-dom';

var PosicionAbajo = [];

class Seccion3 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            PosicionAbajoA: []
        };
    }

    componentDidMount() {
        const data = {
            tran: 7,
            idpregfrecuente: 0,
            rutaiconpreg: "NA",
            prioridad: 0,
            titulopreg: "NA",
            descpregfrec1: "NA",
            descpregfrec2: "NA",
            descpregfrec3: "NA",
            descpregfrec4: "NA",
            estado: 1,
        };
        PreguntasFrecuentesLista_(data).then((response) => {
            try {
                if (response.data.length > 0) {
                    response.data.map((e) => {
                        if (e.prioridad === 1) {
                            PosicionAbajo.push(e);
                        }
                        return [];
                    })
                    this.setState({
                        PosicionAbajoA: PosicionAbajo
                    });
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Ocurrio un error!",
                    });
                }
            } catch (error) {
                Swal.fire({
                    icon: "error",
                    text:
                        "Ocurrio un error al consumir el servicio!",
                });
            }
        });
    }

    componentWillUnmount = () => {
        PosicionAbajo = [];
        this.setState({
            PosicionAbajoA: []
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className="diseño_seccion3_pg" style={{ width: '100%' }}>
                    <div className="container-fluid py-5">
                        <div className="row px-0">
                            <div className="col-md-9">
                                <div className="card">
                                    <div className="card-body px-2 py-0">
                                        <p className="pt-3 pl-3 font-weight-bold" style={{ fontSize: '25px', color: '#474646' }}>Más preguntas frecuentes</p>
                                        {this.state.PosicionAbajoA.map((e, i) => {
                                            return [
                                                <React.Fragment>
                                                    <Accordion>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                        >
                                                            <Typography style={{ fontSize: '18px' }}>{e.titulopreg}</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <Typography className="py-5 px-3">
                                                                <span>{e.descpregfrec1}</span><br /><br /><br />
                                                                <span>{e.descpregfrec2}</span><br /><br />
                                                                <span className="mt-2 mb-2">{e.descpregfrec3}</span><br /><br />
                                                                <span className="my-0 text-dark">{e.descpregfrec4}</span>
                                                            </Typography>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </React.Fragment>
                                            ]
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className="tarjetas_inferior col-md-3">
                                <div className="container pt-3">
                                    <p className="text-center pt-5" style={{ fontSize: '20px' }}>Si no encuentras la respuesta, contácta</p>
                                    <div className="row w-75 mx-0 px-0">
                                        <div className="col-md-12 my-2 pl-5 py-5 d-flex align-items-center" style={{ background: '#f07f00', color: 'white' }}>
                                            <img className="img-fluid" src={`${URLImgFront}/assets/iconos/preguntas-celular.svg`} alt="preguntas-celular" style={{ width: '50px', height: '50px' }} />
                                            <div className="pl-3">
                                                <p className="my-0 font-weight-bold">Teléfono</p>
                                                <p className="my-0">01 8000 93 01 05</p>
                                            </div>
                                        </div>
                                        {/* <div className="col-md-12 my-2 py-5 d-flex align-items-center" style={{ background: '#f07f00', color: 'white' }}>
                                            <img className="img-fluid" src={`${URLImgFront}/assets/iconos/preguntas-call.svg`} alt="preguntas-call" style={{ width: '50px', height: '50px' }} />
                                            <p className="pl-3">Ir a nuestro <b>chat Online</b></p>
                                        </div> */}
                                        <Link to="/Red_Agencias" className="col-md-12 my-2 pl-5 py-5 d-flex align-items-center" style={{ background: '#f07f00', color: 'white' }}>
                                            <img className="img-fluid" src={`${URLImgFront}/assets/iconos/preguntas-ubicacion.svg`} alt="preguntas-ubicacion" style={{ width: '50px', height: '50px' }} />
                                            <p className="pl-3">Encuentra nuestras <b>oficinas</b></p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Seccion3;