import React, { Component } from 'react';
import MensajeF from '../Mensaje_Flotante/MensajeF';
import Footer from '../Principal/Footer';
import Header from '../Principal/Header';
import '../Principal/Responsive.css';
import { ProductosDetallesGET_ } from '../../services/WebAPI_Utrahuilca';
import { MDBIcon } from 'mdbreact';
import { URLImgFront } from '../../config/URLDB';
import { Link } from 'react-router-dom';

class ProductoDimanico extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Items: [],
            Beneficios: [],
            Requisitos: [],
            infoSelectData: [],
            stateBene: false,
            stateReq: false,
            stateTproduct: false,
            stateInfo: false,
            nombrelinea: ''
        };
    }

    componentDidMount() {
        const id = parseInt(this.props.match.params.id);
        const data = {
            tran: 7,
            idprodvtadet: id,
            idprodutra: 0,
            txtprodvtdet1: 'NA',
            txtprodvtdet2: 'NA',
            txtprodvtdet3: 'NA',
            rutaimgdetalle: 'NA',
            idtipovista: 0,
            rutaproddet: 'NA',
            manejaitems: 0,
            descitemsprod: 'NA',
            manejabeneficios: 0,
            descbenefprod: 'NA',
            manejarequisitos: 0,
            descrequisprod: 'NA',
            infoadicional: 'NA',
            rutaformsolic: 'NA',
            estado: 1,
            usucrea: 'NA'
        }
        ProductosDetallesGET_(data).then(response => {
            try {
                if (response.data.length > 0) {
                    if (response.data.length >= 1) {
                        if (response.data[0].message === "004404") {
                            this.setState({
                                stateInfo: false
                            })
                        } else {
                            if (response.data[0].manejaitems === 1) {
                                this.setState({
                                    stateItems: true
                                })
                            }
                            if (response.data[0].manejabeneficios === 1) {
                                this.setState({
                                    stateBene: true
                                })
                            }
                            if (response.data[0].manejarequisitos === 1) {
                                this.setState({
                                    stateReq: true
                                })
                            }
                            if (response.data[0].idtipoprod === 2) {
                                this.setState({
                                    stateTproduct: true,
                                    nombrelinea: 'Crédito'
                                })
                            } else {
                                this.setState({
                                    stateTproduct: false,
                                    nombrelinea: 'Ahorros'
                                })
                            }
                            this.setState({
                                infoSelectData: response.data,
                                txtprodvtdet1: response.data[0].txtprodvtdet1,
                                idtipoprod: response.data[0].idtipoprod,
                                txtprodvtdet2: response.data[0].txtprodvtdet2,
                                txtprodvtdet3: response.data[0].txtprodvtdet3,
                                rutaimgdetalle: response.data[0].rutaimgdetalle,
                                descitemsprod: response.data[0].descitemsprod,
                                descbenefprod: response.data[0].descbenefprod,
                                descrequisprod: response.data[0].descrequisprod,
                                infoadicional: response.data[0].infoadicional,
                                desctipoprod: (response.data[0].desctipoprod).toLowerCase(),
                                nombprodutra: (response.data[0].nombprodutra).toLowerCase(),
                                stateInfo: true
                            });
                            this.LoadData();
                        }
                    } else {
                        this.setState({
                            stateInfo: false
                        })
                    }
                } else {
                    console.log(response);
                }
            } catch (error) {
                console.log(error);
            }
        });
    }

    LoadData = () => {
        var addItemsN = [];
        var addBeneficiosN = [];
        var addRequisitosN = [];
        const dataJson = this.state.infoSelectData;
        var jsonnuevo = [];
        for (var i = 0; i < dataJson.length; i++) {
            for (var j = i; j < dataJson.length; j++) {
                addItemsN.push({ "descitemprod": dataJson[j].descitemprod, "rutaiconitem": dataJson[j].rutaiconitem });
                addBeneficiosN.push({ "descbenefprod": dataJson[j].descbenefprod1, "rutaimgbenefprod": dataJson[j].rutaimgbenefprod });
                addRequisitosN.push({ "descrequisprod": dataJson[j].descrequisprod1, "rutaimgrequistprod": dataJson[j].rutaimgrequistprod });
                if (j === (dataJson.length - 1)) {
                    j = dataJson.length;
                    i = j - 1;
                    break;
                }
                else {
                    if (dataJson[j].idprodvtadet !== dataJson[j + 1].idprodvtadet) {
                        i = j;
                        j = dataJson.length;
                        break;
                    }
                }
            }
            addItemsN = new Set(addItemsN.map(JSON.stringify));
            let newaddItems = Array.from(addItemsN).map(JSON.parse);
            addBeneficiosN = new Set(addBeneficiosN.map(JSON.stringify));
            let newaddBeneficios = Array.from(addBeneficiosN).map(JSON.parse);
            addRequisitosN = new Set(addRequisitosN.map(JSON.stringify));
            let newaddRequisitos = Array.from(addRequisitosN).map(JSON.parse);
            jsonnuevo.push(
                {
                    newaddItems,
                    newaddBeneficios,
                    newaddRequisitos,
                }
            );
            addItemsN = null;
            addBeneficiosN = null;
            addRequisitosN = null;
        }
        this.setState({
            Items: jsonnuevo[0].newaddItems,
            Beneficios: jsonnuevo[0].newaddBeneficios,
            Requisitos: jsonnuevo[0].newaddRequisitos
        })
    }

    render() {
        return (
            <React.Fragment>
                <Header id={this.state.idtipoprod} />
                <MensajeF />
                {this.state.stateInfo ? (
                    <React.Fragment>
                        <div className="bg-white productos_vista" style={{ width: '100%', paddingTop: '120px' }}>
                            <div style={{ width: '100%', height: '60vh' }} className="contenedor-p">
                                <img style={{ position: 'absolute', right: '240px', top: '230px', height: '320px' }} className="img-fluid img_principal" src={`${URLImgFront}/assets${this.state.rutaimgdetalle}`} alt={this.state.rutaimgdetalle} />
                            </div>
                            <div className="contenido_flotante" style={{ position: 'absolute', top: '320px', left: '160px' }}>
                                <p className="w-75 font-weight-bold texto1" style={{ fontSize: '25px', color: '#3f8592' }}>{this.state.txtprodvtdet1}</p>
                                <p className="texto2" style={{ fontSize: '60px', color: '#747373', fontWeight: 'bold' }}>{this.state.txtprodvtdet2}</p>
                                <p className="font-weight-bold pb-4 texto1">{this.state.txtprodvtdet3}</p>
                                {this.state.stateTproduct ?
                                    (
                                        <Link to="/Asesoria">
                                            <span className="px-5 py-2 mt-3" style={{ background: '#3f8592', color: 'white', fontWeight: 'bold', borderRadius: '20px', cursor: 'pointer' }}>Solicitar Crédito</span>
                                        </Link>
                                    ) : (<React.Fragment></React.Fragment>)}
                            </div>
                            {this.state.stateItems ? (
                                <React.Fragment>
                                    <div className="py-3 pb-4 contenedor-items" style={{ background: '#f7f7f7', width: '100%' }}>
                                        <div className="container-fluid pb-5">
                                            <div className="d-flex align-items-center justify-content-center productos-items">
                                                {this.state.Items.map((e, i) => {
                                                    if (e.descitemprod === "N/A") {
                                                        return []
                                                    } else {
                                                        return [
                                                            <React.Fragment key={i}>
                                                                <div className="mx-3 text-center item" style={{ width: '20%' }}>
                                                                    <img style={{ width: '100px' }} className="img-fluid pb-3" src={`${URLImgFront}/assets${e.rutaiconitem}`} alt={e.rutaiconitem} />
                                                                    <p style={{ height: '20px' }} className="text-center">{e.descitemprod}</p>
                                                                </div>
                                                            </React.Fragment>
                                                        ]
                                                    }
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            ) : (<React.Fragment></React.Fragment>)}
                        </div>
                        <span className="pt-4 font-weight-bold producto_pie" style={{ fontSize: '20px', position: 'absolute', top: '130px', left: '10px' }}><MDBIcon icon="caret-right" className="ml-2 mr-1" /> Personas <MDBIcon icon="caret-right" className="ml-2 mr-1" /> {this.state.nombrelinea} <MDBIcon icon="caret-right" className="ml-2 mr-1" /> {this.state.nombprodutra}</span>
                        <div className="diseño_seccion2_lv" style={{ width: '100%', background: '#fff' }}>
                            <div className="container-fluid py-5 text-dark">
                                <h5 className="pl-5 pb-4 text-center font-weight-bold titulos">{this.state.descbenefprod}</h5>
                                {this.state.stateBene ? (
                                    <React.Fragment>
                                        <p className="font-weight-bold text-center" style={{ fontSize: '30px', color: '#747373' }}>BENEFICIOS</p>
                                        <div className="row">
                                            {this.state.Beneficios.map((e, i) => {
                                                var background = "";
                                                if (i % 2 === 0) {
                                                    background = "#f7f7f7";
                                                } else {
                                                    background = "#FFFFFF";
                                                }
                                                if (e.descbenefprod === "N/A") {
                                                    return []
                                                } else {
                                                    if (e.rutaimgbenefprod === "NA") {
                                                        return [
                                                            <React.Fragment key={i}>
                                                                <div className="col-md-12 py-5" style={{ background: '' + background + '' }}>
                                                                    <div className="row px-5">
                                                                        <div className="col-md-12 d-flex seccion text-center">
                                                                            <div className="pl-3 text-left">
                                                                                <p className="text-justify">{e.descbenefprod}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </React.Fragment>
                                                        ]
                                                    } else {
                                                        return [
                                                            <React.Fragment key={i}>
                                                                <div className="col-md-12 py-1">
                                                                    <div className="row px-5">
                                                                        <div className="col-md-12 d-flex align-items-center seccion text-center">
                                                                            <img style={{ width: '40px', height: '40px' }} className="img-fluid" src={`${URLImgFront}/assets${e.rutaimgbenefprod}`} alt={e.rutaimgbenefprod} />
                                                                            <div className="pl-3 mt-3 text-left">
                                                                                <p className="text-justify">{e.descbenefprod}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </React.Fragment>
                                                        ]
                                                    }
                                                }
                                            })}
                                        </div>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment></React.Fragment>
                                )}
                                {this.state.stateTproduct ? (
                                    <div className="col-md-12 pt-5 px-0">
                                        <div className="container">
                                            <div className="row text-center px-0">
                                                <div className="col-md-6 px-0 my-3">
                                                    <Link to="/Simulador/Credito">
                                                        <span className="px-5 py-2 mt-3" style={{ background: '#f07f00', color: 'white', fontWeight: 'bold', borderRadius: '20px', cursor: 'pointer' }}>Simular Crédito</span>
                                                    </Link>
                                                </div>
                                                <div className="col-md-6 px-0 my-3">
                                                    <Link to="/Asesoria">
                                                        <span className="px-5 py-2 mt-3" style={{ background: 'rgb(63, 133, 146)', color: 'white', fontWeight: 'bold', borderRadius: '20px', cursor: 'pointer' }}>Solicitar Crédito</span>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="col-md-12 pt-5 text-center">
                                        {/* <Link to="/Simulador/Ahorros">
                                            <span className="px-5 py-2 mt-3" style={{ background: '#f07f00', color: 'white', fontWeight: 'bold', borderRadius: '20px', cursor: 'pointer' }}>Simular Ahorro</span>
                                        </Link> &nbsp;&nbsp;&nbsp; */}
                                        <Link to="/Asesoria">
                                            <span className="px-5 py-2 mt-3" style={{ background: 'rgb(63, 133, 146)', color: 'white', fontWeight: 'bold', borderRadius: '20px', cursor: 'pointer' }}>Formulario de Asesoría</span>
                                        </Link>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div style={{ width: '100%', background: '#fff' }}>
                            <div className="container-fluid">
                                <p className="pl-5 pb-4 w-50">{this.state.descrequisprod}</p>
                            </div>
                            {this.state.stateReq ? (
                                <React.Fragment>
                                    <div className="container text-dark">
                                        <p className="font-weight-bold text-center" style={{ fontSize: '30px', color: '#747373' }}>REQUISITOS</p>
                                        <div className="row px-0">
                                            <div className="col-md-12 px-5 py-5">
                                                {this.state.Requisitos.map((e, i) => {
                                                    var stateImg = '';
                                                    if (e.rutaimgrequistprod === "N/A" && e.descrequisprod === "N/A") {
                                                        return [];
                                                    } else {
                                                        if (e.rutaimgrequistprod === "NA") {
                                                            stateImg = false;
                                                        } else {
                                                            stateImg = true;
                                                        }
                                                        return [
                                                            <React.Fragment key={i}>
                                                                <p className="d-flex align-items-center">{stateImg ? <img className="position-absolute" src={`${URLImgFront}/assets${e.rutaimgrequistprod}`} style={{ width: '35px', height: '35px', left: '0' }} alt={e.rutaimgrequistprod} /> : <div></div>} {e.descrequisprod}</p>
                                                            </React.Fragment>
                                                        ]
                                                    }
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.stateTproduct ? (
                                        <React.Fragment>
                                            <div className="container-fluid">
                                                <div className="row">
                                                    <div className="col-md-5 pl-0 pr-0">
                                                        <img style={{ width: '100%' }} src={`${URLImgFront}/assets/Producto Credito/EMPLEADOS.png`} alt="img-EMPLEADOS" />
                                                    </div>
                                                    <div className="col-md-7 pl-5 py-5 text-white" style={{ background: '#3f8592' }}>
                                                        <p className="pl-3 font-weight-bold" style={{ fontSize: '25px' }}>EMPLEADOS</p>
                                                        <p><MDBIcon icon="chevron-right" style={{ color: '#fff' }} /> Diligenciar y presentar el formato de la solicitud del crédito.</p>
                                                        <p><MDBIcon icon="chevron-right" style={{ color: '#fff' }} /> Fotocopia de la cédula de ciudadanía ampliada al 150%.</p>
                                                        <p><MDBIcon icon="chevron-right" style={{ color: '#fff' }} /> Certificación laboral original, no mayor a 30 días de expedido.</p>
                                                        <p><MDBIcon icon="chevron-right" style={{ color: '#fff' }} /> Desprendibles de pago de los dos (2) últimos meses. </p>
                                                        <p><MDBIcon icon="chevron-right" style={{ color: '#fff' }} /> Si está obligado a declarar, debe anexar la Declaración de Renta del último año gravable. En caso de no estar obligado debe anexar la certificación de no declarante.</p>
                                                        <p><MDBIcon icon="chevron-right" style={{ color: '#fff' }} /> Fotocopia del recibo de servicio público.</p>
                                                        {/* <p style={{ position: 'absolute', bottom: '10px', right: '60px', borderBottom: '1px solid #fff', fontWeight: 'bold', fontSize: '20px', cursor: 'pointer' }}>Solicitar Crédito <MDBIcon icon="chevron-right" style={{ color: '#fff' }} /></p> */}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-5 pl-0 pr-0">
                                                        <img style={{ width: '100%' }} src={`${URLImgFront}/assets/Producto Credito/PENSIONADOS.png`} alt="img-PENSIONADOS" />
                                                    </div>
                                                    <div className="col-md-7 pl-5 py-5 text-dark" style={{ background: '#f7f7f7' }}>
                                                        <p className="pl-3 font-weight-bold" style={{ fontSize: '25px' }}>PENSIONADOS</p>
                                                        <p><MDBIcon icon="chevron-right" style={{ color: '#000' }} /> Diligenciar y presentar la solicitud del crédito.</p>
                                                        <p><MDBIcon icon="chevron-right" style={{ color: '#000' }} /> Fotocopia de la cédula de ciudadanía ampliada al 150%. (No se aceptan contraseñas).</p>
                                                        <p><MDBIcon icon="chevron-right" style={{ color: '#000' }} /> Desprendibles de pago de los dos (2) últimos meses.</p>
                                                        <p><MDBIcon icon="chevron-right" style={{ color: '#000' }} /> Copia de la resolución de la pensión.</p>
                                                        <p><MDBIcon icon="chevron-right" style={{ color: '#000' }} /> Si está obligado a declarar, debe anexar la Declaración de Renta del último año gravable. En caso de no estar obligado debe anexar la certificación de no declarante.</p>
                                                        <p><MDBIcon icon="chevron-right" style={{ color: '#000' }} /> Fotocopia del recibo de servicio público más reciente.</p>
                                                        {/* <p style={{ position: 'absolute', bottom: '10px', right: '60px', color: '#747373', borderBottom: '1px solid #747373', fontWeight: 'bold', fontSize: '20px', cursor: 'pointer' }}>Solicitar Crédito <MDBIcon icon="chevron-right" style={{ color: '#747373' }} /></p> */}
                                                    </div>
                                                    {/* <div className="col-md-12 pt-5 pb-5 d-flex justify-content-center">
                                                        <span className="px-5 py-3 mt-3" style={{ background: '#3f8592', color: 'white', fontWeight: 'bold', fontSize: '25px', cursor: 'pointer' }}><MDBIcon icon="download" /> &nbsp; Descargar Formulario de Crédito</span>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    ) : (<React.Fragment></React.Fragment>)}
                                </React.Fragment>
                            ) : (
                                <React.Fragment></React.Fragment>
                            )}
                        </div>
                    </React.Fragment>
                ) : (<React.Fragment></React.Fragment>)}
                <Footer />
            </React.Fragment >
        );
    }
}

export default ProductoDimanico;