import React, { Component } from 'react';
import { URLImgFront } from '../../config/URLDB';
import { PreguntasFrecuentes_ } from '../../services/WebAPI_Utrahuilca';

class Seccion1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stateImg: false,
        };
    }

    async componentDidMount() {
        const data = {
            tran: 7,
            idvtapregfrec: 0,
            nombrepregfrec: 'NA',
            rutaimgfondopreg: 'NA',
            txtpregfrec1: 'NA',
            txtpregfrec2: 'NA',
            colortexto: 'NA',
            estado: 1,
            usucrea: 'NA'
        }
        PreguntasFrecuentes_(data).then(response => {
            try {
                if (response.data.length > 0) {
                    if (response.data.length >= 1) {
                        if (response.data[0].message === "004404") {
                            this.setState({
                                stateImg: false
                            })
                        } else {
                            this.setState({
                                idvtapregfrec: response.data[0].idvtapregfrec,
                                nombrepregfrec: response.data[0].nombrepregfrec,
                                rutaimgfondopreg: response.data[0].rutaimgfondopreg,
                                txtpregfrec1: response.data[0].txtpregfrec1,
                                txtpregfrec2: response.data[0].txtpregfrec2,
                                colortexto: response.data[0].colortexto,
                                stateImg: true
                            });
                        }
                    } else {
                        this.setState({
                            stateImg: false
                        })
                    }
                } else {
                    this.setState({
                        estadoNuevo: true
                    })
                }
            } catch (error) {
                console.log(error);
            }
        });
    }

    render() {
        const { stateImg } = this.state;
        return (
            <React.Fragment>
                <div className="preguntas_diseño" style={{ width: '100%', paddingTop: '120px' }}>
                    {stateImg ?
                        (
                            <img style={{ width: '100%', height: '40vh', objectFit: 'cover' }} className="img-fluid" src={`${URLImgFront}/assets${this.state.rutaimgfondopreg}`} alt={this.state.rutaimgfondopreg} />
                        ) : (
                            <React.Fragment></React.Fragment>
                        )}
                    <div className="contenido_text_pd text-center" style={{ position: 'absolute', top: '280px', left: '0', right: '0', zIndex: '2' }}>
                        <p style={{ fontSize: '20px', color: '' + this.state.colortexto + '' }}>{this.state.txtpregfrec1}</p>
                        <p style={{ fontSize: '40px', color: '' + this.state.colortexto + '', fontWeight: 'bold' }}>{this.state.txtpregfrec2}</p>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Seccion1;