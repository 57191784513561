import { MDBIcon, MDBInput } from 'mdbreact';
import React, { Component } from 'react';
import './StylesAdmin.css';
import { URLImgFront } from '../../config/URLDB';
import { LoginValidar } from '../../services/WebAPI_Utrahuilca';
import Swal from 'sweetalert2';
import { withRouter } from "react-router-dom";

const validate = values => {
    const errors = {}
    if (values.usuario === '') {
        errors.usuario = 'campo obligatorio'
    }
    if (values.clave === '') {
        errors.clave = 'campo obligatorio'
    }
    return errors;
};

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            usuario: '',
            clave: ''
        };
    }

    textInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    textInputUser = (e) => {
        var mayus = e.target.value.toUpperCase();
        this.setState({
            usuario: mayus
        })
    }
    
    validarLogin = () => {
        const { errors, ...sinErrors } = this.state;
        const result = validate(sinErrors);
        this.setState({ errors: result });
        if (!Object.keys(result).length) {
            // formulario validado
            const data = {
                usuario: this.state.usuario,
                clave: this.state.clave,
            }
            LoginValidar(data).then(response => {   
                try {
                    if (response.data.length > 0) {
                        if (response.data.length >= 1) {
                            if (response.data[0].message !== "000000") {
                                Swal.fire({
                                    icon: 'warning',
                                    title: response.data[0].descMessage
                                })
                            } else {
                                sessionStorage.setItem('idusuario', response.data[0].id);
                                sessionStorage.setItem('usuario', this.state.usuario);
                                this.setState({
                                    usuario: '',
                                    clave: '',
                                });
                                this.props.history.push('/admin/principal');
                            }
                        } else {
                            this.setState({
                                usuario: this.state.usuario,
                                clave: this.state.clave,
                            });
                            Swal.fire({
                                icon: 'warning',
                                title: response.data[0].descMessage
                            })
                        }
                    } else {
                        this.setState({
                            usuario: this.state.usuario,
                            clave: this.state.clave,
                        });
                        Swal.fire({
                            icon: 'warning',
                            title: response.data[0].descMessage
                        })
                    }
                } catch (error) {
                    this.setState({
                        usuario: this.state.usuario,
                        clave: this.state.clave,
                    });
                    Swal.fire({
                        icon: 'error',
                        title: response.data[0].descMessage
                    })
                }
            });
        }
    }

    render() {
        const { errors } = this.state;
        return (
            <React.Fragment>
                <div className="login_admin">
                    <div className="card py-3 px-3 contenedor_campos">
                        <div className="card-body text-center">
                            <div className="contenido">
                                <img className="img-fluid" src={`${URLImgFront}/assets/logo/logo.png`} alt="img-logo" />
                            </div>
                            <div>
                                <div className="pb-1 pt-5">
                                    <MDBInput name="usuario" label="Digite su usuario" type="text" value={this.state.usuario} onChange={this.textInputUser} />
                                    {errors.usuario && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', marginTop: '-35px', left: '35px' }}>{errors.usuario}</strong>}
                                </div>
                                <div>
                                    <MDBInput name="clave" label="Digite su contraseña" type="password" value={this.state.clave} onChange={this.textInput} />
                                    {errors.clave && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', marginTop: '-35px', left: '35px' }}>{errors.clave}</strong>}
                                </div>
                                <div className="text-center">
                                    <button className="btn" onClick={this.validarLogin} style={{ background: '#FA7400', color: 'white', fontWeight: 'bold' }}>Ingresar <MDBIcon icon="sign-in-alt" /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(Login);