import React, { Component } from 'react';
import { URLImgFront } from '../../config/URLDB';

class Seccion1 extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    render() {
        return (
            <React.Fragment>
                <div className="pse_diseño" style={{ width: '100%', paddingTop: '120px' }}>
                    {/*<img style={{ width: '100%', height: '100vh', marginBottom: '15px', objectFit: 'cover' }} 
                    className="img-fluid" src={`${URLImgFront}/assets/img_principal/PORTADA PQRSF.png`} alt="PORTADA PQRSF" />*/}
                    <img style={{ width: '100%', height: '100vh', marginBottom: '15px', objectFit: 'cover' }} className="img-fluid" src={`${URLImgFront}/assets/img_principal/PORTADA_PQRSF.webp`} alt="PORTADA PQRSF" />
                </div>
            </React.Fragment>
        );
    }
}

export default Seccion1;