import React, { Component } from 'react';
import { URLImgFront } from '../../config/URLDB';

class Seccion3 extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    render() {
        return (
            <React.Fragment>
                <div style={{ background: '#f3f3f3', width: '100%' }}>
                    <div className="container mt-5 py-0">
                        <div className="row px-0 py-0">
                            <div className="col-md-7 py-0 pb-2">
                                <img style={{ height: '100%', width: '100%' }} className="img-fluid" src={`${URLImgFront}/assets${this.props.rutaimginferior}`} alt="img-1" />
                            </div>
                            <div className="col-md-5 d-flex align-items-center pl-5 py-5">
                                <div className="text-left">
                                    <p className="font-weight-bold" style={{ fontSize: '24px', color: '#6a6969' }}>{this.props.titulo1inferior}</p>
                                    <p style={{ fontSize: '20px', color: '#6a6969' }}>{this.props.desc1inferior}</p>
                                    <p className="my-0 py-0" style={{ fontSize: '24px', color: '#6a6969', fontWeight: 'bold' }}>{this.props.titulo2inferior === 'N/A' ? '' : this.state.titulo2inferior}</p>
                                    <p className="my-0 py-0" style={{ fontSize: '20px', color: '#6a6969' }}>{this.props.desc2inferior === 'N/A' ? '' : this.state.desc2inferior}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment> 
        );
    }
}

export default Seccion3;