import { MDBIcon } from 'mdbreact';
import React, { Component } from 'react';
import Swal from 'sweetalert2';
import { URLImgFront } from '../../config/URLDB';
import { TrabajeconNosotros_ } from '../../services/WebAPI_Utrahuilca';

class Seccion3 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            vacantes: [],
            estadoNoVacantes: false,
            estadoVacante: false,
            SelectData: []
        };
    }

    async componentDidMount() {
        const data = {
            tran: 7,
            idvacante: 0,
            nombcargo: 'NA',
            descperfil: 'NA',
            rutaimgvacant: 'NA',
            desccargo: 'NA',
            estado: 1,
            usucrea: 'NA'
        }
        TrabajeconNosotros_(data).then(response => {
            try {
                if (response.data.length > 0) {
                    if (response.data[0].message === "004404") {
                        this.setState({
                            estadoNoVacantes: true
                        })
                    } else {
                        this.setState({
                            estadoNoVacantes: false
                        });
                    }
                    this.setState({
                        vacantes: response.data
                    });
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Ocurrio un error!'
                    })
                }
            } catch (error) {
                console.log(error)
            }
        });
    }

    cargarOferta = (data) => {
        this.setState({
            estadoVacante: true,
            nombcargo: data.nombcargo,
            descperfil: data.descperfil,
            rutaimgvacant: data.rutaimgvacant,
            desccargo: data.desccargo
        })
    }

    render() {
        return (
            <React.Fragment>
                <div style={{ width: '100%', background: '#fff' }}>
                    <div className="container py-5 text-dark">
                        <p className="text-center mb-0" style={{ fontSize: '20px', color: '#747373' }}>Haga parte del equipo Utrahuilca</p>
                        <p className="text-center my-0 text-justify" style={{ fontSize: '20px', color: '#747373' }}>Adjunte su hoja de vida al correo: seleccionth@utrahuilca.com o contáctenos al 608-872-8182 ext: 512</p>
                    </div>
                    <div className="container-fluid">
                        {this.state.estadoVacante ? (
                            <React.Fragment>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-12 my-0">
                                            <h5 className="font-weight-bold" onClick={() => { this.setState({ estadoVacante: false }) }} style={{ cursor: 'pointer' }}><MDBIcon icon="angle-left" style={{ color: '#e95c0c' }} /> Volver</h5>
                                            <hr />
                                        </div>
                                        <div className="col-md-12 my-0 pb-5" style={{ background: '#fff', color: '#000' }}>
                                            <p className="pl-3 pt-3 my-0" style={{ fontSize: '13px', color: 'gray' }}>VACANTE</p>
                                            <p className="pl-3 font-weight-bold" style={{ fontSize: '25px' }}>{this.state.nombcargo}</p>
                                            <p className="pl-3 font-weight-bold pt-3" style={{ fontSize: '15px' }}>{this.state.descperfil}</p>
                                            <div className="py-5 px-5 oferta_contenido" style={{ whiteSpace: 'pre-line', fontSize: '17px', color: 'white', background: '#e95c0c', border: '1px solid #e95c0c' }}>
                                                <p className="text-justify">{this.state.desccargo}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <div className="row">
                                    {this.state.estadoNoVacantes ? (
                                        <React.Fragment>
                                            <div className="container pb-5">
                                                <div className="col-md-12">
                                                    <div className="alert alert-warning">En el momento no tenemos vacantes.</div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            {this.state.vacantes.map((e, i) => {
                                                var background = "";
                                                var color = "";
                                                if (i % 2 === 0) {
                                                    background = "#e95c0c";
                                                    color = "#fff";
                                                } else {
                                                    background = "#FFFFFF";
                                                    color = "#000";
                                                }
                                                return [
                                                    <React.Fragment key={i}>
                                                        <div className="col-md-6 pl-0 pr-0" style={{ height: '350px', overflow: 'hidden' }}>
                                                            <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={`${URLImgFront}/assets${e.rutaimgvacant}`} alt={e.rutaimgvacant} />
                                                        </div>
                                                        <div className="col-md-6 px-5" style={{ background: '' + background + '', color: '' + color + '', height: '350px' }}>
                                                            <p id="vacant_nombcargo" className="pl-3 font-weight-bold pt-5" style={{ fontSize: '25px' }}>{e.nombcargo}</p>
                                                            <p id="vacant_descperfil" className="pl-3 w-75" style={{ fontSize: '15px' }}>{e.descperfil}</p>
                                                            <p className="font-weight-bold" onClick={() => { this.cargarOferta(e) }} style={{ position: 'absolute', right: '20%', borderBottom: '1px solid ' + color + '', cursor: 'pointer' }}>Me interesa <MDBIcon icon="angle-right" /></p>
                                                        </div>
                                                    </React.Fragment>
                                                ]
                                            })}
                                        </React.Fragment>
                                    )}
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Seccion3;