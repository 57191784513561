import { MDBIcon, MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import React, { Component } from 'react';
import { CargueDocumentos, TasasyTarifas_ } from '../../../services/WebAPI_Utrahuilca';
import Swal from 'sweetalert2';

class TasasTarifas extends Component {
    uploader = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            estadoSinInfo: false,
            estado: null,
            selectedFile: null,
            infoTF: []
        };
    }

    onFileChange = event => {
        this.setState({ selectedFile: event.target.files[0] });
    };

    async componentDidMount() {
        this.cargarInformacion();
    }

    cargarInformacion = () => {
        const data = {
            tran: 5,
            idtasastarifas: 0,
            nomtasastarifas: 'NA',
            rutatasastarifas: 'NA',
            estado: 0,
            usucrea: 'NA'
        }
        TasasyTarifas_(data).then(response => {
            try {
                if (response.data.length > 0) {
                    if (!response.data[0].message) {
                        setTimeout(() => {
                            this.setState({
                                infoTF: response.data,
                                estadoSinInfo: true,
                                estadoImagen: true,
                            })
                        }, 120);
                    } else {
                        this.setState({
                            estadoSinResultados: false,
                            estadoSinInfo: false
                        })
                    }
                } else { }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    text: 'Ocurrio un error al consumir el servicio!'
                })
            }
        });
    }

    textInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    editar = (id) => {
        var seleccionado = [];
        this.state.infoTF.map((e, i) => {
            if (e.idtasastarifas === id) {
                seleccionado.push(e)
            }
            return [];
        })
        this.setState({
            idtasastarifas: seleccionado[0].idtasastarifas,
            nomtasastarifas: seleccionado[0].nomtasastarifas,
            rutatasastarifas: seleccionado[0].rutatasastarifas,
            estadoEditar: true
        })
    }

    actualizarDatos = (id) => {
        const MAXIMO_TAMANIO_BYTES = 3000000;
        if (this.state.selectedFile.size > MAXIMO_TAMANIO_BYTES) {
            const tamanioEnMb = MAXIMO_TAMANIO_BYTES / 1000000;
            Swal.fire({
                icon: 'warning',
                text: `El tamaño máximo es de ${tamanioEnMb} MB`
            })
        } else {
            var rutaPDF = '';
            if (this.state.selectedFile === undefined || this.state.selectedFile === null) {
                rutaPDF = this.state.rutatasastarifas;
            } else {
                rutaPDF = this.state.selectedFile.name;
            }
            const data = {
                tran: 1,
                idtasastarifas: id,
                nomtasastarifas: this.state.nomtasastarifas,
                rutatasastarifas: rutaPDF,
                estado: 1,
                usucrea: sessionStorage.getItem('idusuario')
            }
            TasasyTarifas_(data).then(response => {
                if (response.data.length > 0) {
                    if (response.data[0].message === "000000") {
                        this.cargarArchivos();
                        this.setState({
                            estadoEditar: false,
                            nomtasastarifas: '',
                            selectedFile: null
                        })
                        Swal.fire({
                            icon: 'success',
                            title: 'Se actualizo satisfactoriamente'
                        })
                        this.cargarInformacion();
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Ocurrio un error al actualizar'
                        })
                    }
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Ocurrio un error!'
                    })
                }
            });
        }
    }

    cargarArchivos = () => {
        var formData = new FormData();
        if (undefined === this.state.selectedFile || null === this.state.selectedFile) return;
        
        formData.append("file", this.state.selectedFile, this.state.selectedFile.name);
        formData.append("rutaImagen", '/Archivos/' + this.state.selectedFile.name);
        CargueDocumentos(formData).then(response => {
            if (response) {
                this.cargarInformacion();
            }
        });
    }

    render() {
        const { estadoEditar, idtasastarifas, infoTF } = this.state;
        return (
            <React.Fragment>
                <p className="font-weight-bold">Tasas y Tarifas</p>
                <hr />
                <div className="container">
                    <div className="table-responsive text-center w-100">
                        <MDBTable>
                            <MDBTableHead>
                                <tr className="font-weight-bold">
                                    <td>Nombre</td>
                                    <td>Nombre PDF</td>
                                    {estadoEditar ?
                                        (
                                            <React.Fragment>
                                                <td>Nuevo</td>
                                                <td>Guardar</td>
                                                <td>Cancelar</td>
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment>
                                                <td>Editar</td>
                                                {/* <td>Inactivar</td> */}
                                            </React.Fragment>
                                        )}
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                                {estadoEditar ?
                                    (
                                        <React.Fragment>
                                            <tr>
                                                <td className="w-25"><p>{this.state.nomtasastarifas}</p></td>
                                                <td className="w-25"><p>{this.state.rutatasastarifas}</p></td>
                                                <td className="w-25">
                                                    <input type="file" onChange={this.onFileChange} /><br /><br />
                                                    <span style={{ color: 'gray' }}>Tamaño max. 3MB</span>
                                                </td>
                                                <td className="text-center" onClick={() => { this.actualizarDatos(idtasastarifas) }}><MDBIcon icon="save" size="2x" style={{ color: 'blue', cursor: 'pointer' }} /></td>
                                                <td className="text-center" onClick={() => { this.setState({ estadoEditar: !this.state.estadoEditar }) }}><MDBIcon icon="times" size="2x" style={{ color: 'red', cursor: 'pointer' }} /></td>
                                            </tr>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            {infoTF.map((e, i) => {
                                                // let view;
                                                // if (e.estado === 1) {
                                                //     view = <MDBIcon onClick={() => { this.inactivar(e.idtasastarifas) }} icon="eye" size="2x" style={{ cursor: 'pointer' }} />
                                                // } else {
                                                //     view = <MDBIcon onClick={() => { this.activar(e.idtasastarifas) }} icon="eye-slash" size="2x" style={{ cursor: 'pointer' }} />
                                                // }
                                                return [
                                                    <React.Fragment key={i}>
                                                        <tr>
                                                            <td><p className="font-weight-bold">{e.nomtasastarifas}</p></td>
                                                            <td>{e.rutatasastarifas}</td>
                                                            <td className="text-center" onClick={() => { this.editar(e.idtasastarifas) }}><MDBIcon icon="edit" size="2x" style={{ color: 'green', cursor: 'pointer' }} /></td>
                                                            {/* <td className="text-center">{view}</td> */}
                                                        </tr>
                                                    </React.Fragment>
                                                ]
                                            })}
                                        </React.Fragment>
                                    )}
                            </MDBTableBody>
                        </MDBTable>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default TasasTarifas;