import { MDBIcon } from 'mdbreact';
import React, { Component } from 'react';
import { URLImgFront } from '../../config/URLDB';

class Seccion1 extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    render() {
        return (
            <React.Fragment>
                <div className="diseño_responsive_view" style={{ width: '100%', paddingTop: '120px' }}>
                    <img style={{ width: '100%', height: '90vh', marginBottom: '15px', objectFit: 'cover' }} className="img-fluid" src={`${URLImgFront}/assets/img_principal/PORTADA NUESTROS SERVICIOS.png`} alt="PORTADA NUESTROS SERVICIOS" />
                    <span className="ml-4 pt-4" style={{ fontSize: '20px' }}><MDBIcon icon="caret-right" className="ml-2 mr-1" /> Servicios</span>
                    <div className="vista_p_ahorro_credito" style={{ position: 'absolute', bottom: '200px', left: '140px' }}>
                        <p className="my-0" style={{ fontSize: '60px', color: '#FFF' }}>Conozca todos los</p>
                        <p className="my-0" style={{ fontSize: '60px', color: '#FFF', fontWeight: 'bold' }}>servicios que UTRAHUILCA </p>
                        <p className="pb-5" style={{ fontSize: '60px', color: '#FFF' }}>tiene para usted.</p>
                        {/* <span className="position-absolute" style={{ fontSize: '25px', borderBottom: '1px solid #fff', color: '#fff', cursor: 'pointer' }}>Ver servicios Utrahuilca <MDBIcon icon="caret-right" size="lg" className="pl-4 pt-2" style={{ color: '#f47216', position: 'absolute', right: '-30px' }} /></span> */}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Seccion1;