import React, { Component } from 'react';
import { URLImgFront } from '../../config/URLDB';
import { ComunicadosDetalles_ } from '../../services/WebAPI_Utrahuilca';

class Seccion2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            infoFormatos: []
        };
    }

    async componentDidMount() {
        const data = {
            tran: 7,
            idcomunicado: 0,
            codcomunicado: 2,
            rutaimgcomunic: 'NA',
            titulocomunic: 'NA',
            desccomunic: 'NA',
            rutalinkpdf: 'NA',
            estado: 1,
            usucrea: 'NA'
        }
        ComunicadosDetalles_(data).then(response => {
            try {
                if (response.data.length > 0) {
                    if (response.data[0].message === "004404") {
                        this.setState({
                            stateData: false
                        });
                    } else {
                        this.setState({
                            infoFormatos: response.data,
                            stateData: true
                        });
                    }
                }
            } catch (error) {
                console.log(error);
            }
        });
    }

    render() {
        return (
            <React.Fragment>
                <div style={{ width: '100%' }}>
                    <div className="container-fluid px-5 py-5">
                        {this.state.stateData ? (
                            <React.Fragment>
                                <div className="row px-5 descargue-formatos">
                                    {this.state.infoFormatos.map((e, i) => {
                                        return [
                                            <React.Fragment key={i}>
                                                <div className="col-md-6 my-2 pl-4" style={{ cursor: 'pointer' }}>
                                                    <a href={`${URLImgFront}/assets/Archivos/${e.rutalinkpdf}`} download={`${e.rutalinkpdf}`} target={'_blank'} rel="noopener noreferrer">
                                                        <div className="row">
                                                            <div className="col-md-5 pl-0 pr-0">
                                                                <img style={{ width: '100%', height: '271px', objectFit: 'cover' }} src={`${URLImgFront}/assets${e.rutaimgcomunic}`} alt={e.rutaimgcomunic} />
                                                            </div>
                                                            <div className="col-md-7 py-5 text-dark" style={{ background: '#f7f7f7' }}>
                                                                <p className="pr-5 my-0 text-right" style={{ fontSize: '25px' }}>{e.titulocomunic}</p>
                                                                <p className="pr-5 my-0 text-right" style={{ fontSize: '25px' }}>{e.desccomunic}</p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </React.Fragment>
                                        ]
                                    })}
                                </div>
                            </React.Fragment>
                        ) : (<React.Fragment></React.Fragment>)}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Seccion2;