import { MDBIcon } from 'mdbreact';
import React, { Component } from 'react';
import { URLImgFront } from '../../config/URLDB';

class Seccion1 extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <React.Fragment>
                <div className="diseño_responsive_view" style={{ width: '100%', paddingTop: '120px' }}>
                    <img style={{ width: '100%', height: '90vh', marginBottom: '15px', objectFit: 'cover' }} className="img-fluid" src={`${URLImgFront}/assets${this.props.rutaimgfondprod}`} alt="img1" />
                    <span className="ml-4 pt-4" style={{ fontSize: '20px' }}><MDBIcon icon="caret-right" className="ml-2 mr-1" /> {localStorage.getItem('TipoPers')} <MDBIcon icon="caret-right" className="ml-2 mr-1" /> {localStorage.getItem('nombLinea')}</span>
                    <div className="vista_p_ahorro_credito" style={this.props.posiciontexto ? { position: 'absolute', bottom: '10%', left: '15%' } : { position: 'absolute', bottom: '280px', right: '340px' }}>
                        <p className="my-0" style={{ fontSize: '40px', color: '' + this.props.colortexto + '' }}>{this.props.txtvistprod1}</p>
                        <p className="pb-5" style={{ fontSize: '60px', color: '' + this.props.colortexto + '', fontWeight: 'bold' }}>{this.props.txtvistprod2}</p>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Seccion1;