import React, { Component } from 'react';
import '../responsive/Infantil.css';
import MensajeF from '../components/Mensaje_Flotante/MensajeF';
import Footer from '../components/Principal/Footer';
import Header from '../components/Principal/Header';
import '../components/Principal/Responsive.css';
import { URLImgFront } from '../config/URLDB';
import Infantil from '../components/Infantil Juvenil/Infantil';
import UtraAmigos from '../components/Infantil Juvenil/UtraAmigos';

class InfantilJuvenil extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadpage: false,
            tipoInfantil: 1
        };
    }
    componentDidMount() {
        setTimeout(() => {
            this.setState({
                loadpage: true
            })
        }, 0.1)
    }
    render() {
        return (
            <React.Fragment>
                <Header id={1} />
                <MensajeF />
                {this.state.loadpage ? (
                    <div className="diseño_juvenil" style={{ width: '100%', paddingTop: '120px' }}>
                        <img style={{ height: '3500px', paddingTop: '0px', width: '100%', objectFit: 'cover' }} className="img-2-juvenil" src={`${URLImgFront}/assets/img-vistas/diseno.jpg`} alt="diseno" />
                        <div style={{ width: '100%', position: 'absolute', top: '115px' }}>
                            <img className="img-1-juvenil" style={{ zIndex: '1', width: '75%', display: 'flex', objectFit: 'contain' }} src={`${URLImgFront}/assets/img_principal/PORTADA INFANTIL JUVENIL.png`} alt="PORTADA INFANTIL JUVENIL" />
                            <div className="contenidos" style={{ position: 'absolute', top: '50%', right: '10%', zIndex: '2' }}>
                                <p className="font-weight-bold text-right mb-0 texto1" style={{ fontSize: '40px', color: '#fff' }}>Este es un espacio creado para nuestros</p>
                                <p className="pr-0 mr-0 text-right mt-0 texto2" style={{ fontSize: '60px', color: '#fff' }}>AHORRADORES MÁS JÓVENES</p>
                            </div>
                            <div className="container py-5">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="card item-tipo-infantil" onClick={() => { this.setState({ tipoInfantil: 1 }) }} style={this.state.tipoInfantil === 1 ? { background: '#ff367e', cursor: 'pointer', transform: 'scale(.8)' } : { background: '#ff367e', cursor: 'pointer' }}>
                                            <div className="card-body px-0 py-0  d-flex justify-content-center align-items-center">
                                                <p className="font-weight-bold mt-2" style={{ fontSize: '25px', color: '#fff', fontFamily: "cursive" }}>INFANTIL JUVENIL</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="card item-tipo-infantil" onClick={() => { this.setState({ tipoInfantil: 2 }) }} style={this.state.tipoInfantil === 2 ? { background: '#37c1ea', cursor: 'pointer', transform: 'scale(.8)' } : { background: '#37c1ea', cursor: 'pointer' }}>
                                            <div className="card-body px-0 py-0 d-flex justify-content-center align-items-center">
                                                <p className="font-weight-bold mt-2" style={{ fontSize: '25px', color: '#fff', fontFamily: "cursive" }}>UTRAAMIGOS</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 pt-5">
                                        {this.state.tipoInfantil === 1 ? <Infantil /> : <UtraAmigos />}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                ) : (<React.Fragment></React.Fragment>)}
                <Footer />
            </React.Fragment>
        );
    }
}

export default InfantilJuvenil;