import { MDBIcon, MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import React, { Component } from 'react';
import { Llamanos_ } from '../../../services/WebAPI_Utrahuilca';
import Swal from 'sweetalert2';

class Numeros extends Component {
    uploader = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            estadoSinInfo: false,
            infoNumeros: [],
            idutrallamanos: null,
            nrollamanos: '',
            estado: null
        };
    }

    async componentDidMount() {
        this.cargarInformacion();
    }

    cargarInformacion = () => {
        const data = {
            tran: 4,
            idutrallamanos: 0,
            nrollamanos: 'NA',
            estado: 0,
            usucrea: 'NA'
        }
        Llamanos_(data).then(response => {
            try {
                if (response.data.length > 0) {
                    if (!response.data[0].message) {
                        this.setState({
                            idSeleccionado: response.data[0].idutrallamanos
                        })
                        setTimeout(() => {
                            this.setState({
                                infoNumeros: response.data,
                                estadoSinInfo: true,
                                estadoImagen: true,
                            })
                        }, 120);
                    } else {
                        this.setState({
                            estadoSinResultados: false,
                            estadoSinInfo: false
                        })
                    }
                } else { }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    text: 'Ocurrio un error al consumir el servicio!'
                })
            }
        });
    }

    textInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    editar = (id) => {
        var seleccionado = [];
        this.state.infoNumeros.map((e, i) => {
            if (e.idutrallamanos === id) {
                seleccionado.push(e)
            }
            return [];
        })
        this.setState({
            idutrallamanos: seleccionado[0].idutrallamanos,
            nrollamanos: seleccionado[0].nrollamanos,
            estadoEditar: true
        })
    }

    actualizarDatos = (id) => {
        const data = {
            tran: 1,
            idutrallamanos: id,
            nrollamanos: this.state.nrollamanos,
            estado: 1,
            usucrea: sessionStorage.getItem('idusuario')
        }
        Llamanos_(data).then(response => {
            if (response.data.length > 0) {
                if (response.data[0].message === "000000") {
                    this.setState({
                        estadoEditar: false,
                        nrollamanos: '',
                    })
                    Swal.fire({
                        icon: 'success',
                        title: 'Se actualizo satisfactoriamente'
                    })
                    this.cargarInformacion();
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Ocurrio un error al actualizar'
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Ocurrio un error!'
                })
            }
        });
    }

    activar = (id) => {
        const data = {
            tran: 6,
            idutrallamanos: id,
            nrollamanos: 'NA',
            estado: 1,
            usucrea: sessionStorage.getItem('idusuario')
        }
        Llamanos_(data).then(response => {
            if (response) {
                this.setState({
                    estadoS: false
                })
                Swal.fire({
                    icon: 'success',
                    title: 'Se activo satisfactoriamente'
                });
                this.cargarInformacion();
            }
        });
    }

    inactivar = (id) => {
        const data = {
            tran: 6,
            idutrallamanos: id,
            nrollamanos: 'NA',
            estado: 0,
            usucrea: sessionStorage.getItem('idusuario')
        }
        Llamanos_(data).then(response => {
            if (response) {
                this.setState({
                    estadoS: false
                })
                Swal.fire({
                    icon: 'success',
                    title: 'Se inactivo satisfactoriamente'
                });
                this.cargarInformacion();
            }
        });
    }

    render() {
        const { estadoEditar, idutrallamanos, infoNumeros } = this.state;
        return (
            <React.Fragment>
                <p className="font-weight-bold">Llamanos - Robot(Numeros)</p>
                <hr />
                <div className="container">
                    <div className="table-responsive text-center w-100">
                        <MDBTable>
                            <MDBTableHead>
                                <tr className="font-weight-bold">
                                    <td>Numero</td>
                                    {estadoEditar ?
                                        (
                                            <React.Fragment>
                                                <td>Guardar</td>
                                                <td>Cancelar</td>
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment>
                                                <td>Editar</td>
                                                <td>Inactivar</td>
                                            </React.Fragment>
                                        )}
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                                {estadoEditar ?
                                    (
                                        <React.Fragment>
                                            <tr>
                                                <td className="w-25"><input className="form-control" type="text" name="nrollamanos" value={this.state.nrollamanos} onChange={this.textInput} /></td>
                                                <td className="text-center" onClick={() => { this.actualizarDatos(idutrallamanos) }}><MDBIcon icon="save" size="2x" style={{ color: 'blue', cursor: 'pointer' }} /></td>
                                                <td className="text-center" onClick={() => { this.setState({ estadoEditar: !this.state.estadoEditar }) }}><MDBIcon icon="times" size="2x" style={{ color: 'red', cursor: 'pointer' }} /></td>
                                            </tr>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            {infoNumeros.map((e, i) => {
                                                let view;
                                                if (e.estado === 1) {
                                                    view = <MDBIcon onClick={() => { this.inactivar(e.idutrallamanos) }} icon="eye" size="2x" style={{ cursor: 'pointer' }} />
                                                } else {
                                                    view = <MDBIcon onClick={() => { this.activar(e.idutrallamanos) }} icon="eye-slash" size="2x" style={{ cursor: 'pointer' }} />
                                                }
                                                return [
                                                    <React.Fragment key={i}>
                                                        <tr>
                                                            <td>{e.nrollamanos}</td>
                                                            <td className="text-center" onClick={() => { this.editar(e.idutrallamanos) }}><MDBIcon icon="edit" size="2x" style={{ color: 'green', cursor: 'pointer' }} /></td>
                                                            <td className="text-center">{view}</td>
                                                        </tr>
                                                    </React.Fragment>
                                                ]
                                            })}
                                        </React.Fragment>
                                    )}
                            </MDBTableBody>
                        </MDBTable>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Numeros;