import { MDBIcon } from 'mdbreact';
import React, { Component } from 'react';
import { URLImgFront } from '../../config/URLDB';
import { Seguros_ } from '../../services/WebAPI_Utrahuilca';

class Personas extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stateImg: false,
            stateOri: false
        };
    }

    async componentDidMount() {
        const data = {
            tran: 7,
            idvtaseguro: 0,
            nombseguro: 'NA',
            rutafondoseg: 'NA',
            txtseguro1: 'NA',
            txtseguro2: 'NA',
            txtslider3: 'NA',
            posiciontexto: 0,
            colortexto: 'NA',
            estado: 1,
            usucrea: 'NA'
        }
        Seguros_(data).then(response => {
            try {
                if (response.data.length > 0) {
                    if (response.data.length >= 1) {
                        if (response.data[0].message === "004404") {
                            this.setState({
                                stateImg: false
                            })
                        } else {
                            if (response.data[0].posiciontexto === 1) {
                                this.setState({
                                    left: "5%",
                                    stateOri: true
                                })
                            } else {
                                this.setState({
                                    right: "8%",
                                    stateOri: false
                                })
                            }
                            this.setState({
                                nombseguro: response.data[0].nombseguro,
                                rutafondoseg: response.data[0].rutafondoseg,
                                txtseguro1: response.data[0].txtseguro1,
                                txtseguro2: response.data[0].txtseguro2,
                                txtslider3: response.data[0].txtslider3,
                                posiciontexto: response.data[0].posiciontexto,
                                colortexto: response.data[0].colortexto,
                                stateImg: true
                            });
                        }
                    } else {
                        this.setState({
                            stateImg: false
                        })
                    }
                } else {
                    console.log(response);
                }
            } catch (error) {
                console.log(error);
            }
        });
    }

    render() {
        const { stateImg } = this.state;
        return (
            <React.Fragment>
                <div className="diseño_responsive_view" style={{ width: '100%', paddingTop: '120px' }}>
                    <div className="vista_seguros text-right" style={this.state.stateOri ? { position: 'absolute', bottom: '20%', left: this.state.left } : { position: 'absolute', bottom: '20%', right: this.state.right }}>
                        <p className="my-0" style={{ fontSize: '30px', color: '' + this.state.colortexto + '' }}>{this.state.txtseguro1}</p>
                        <p className="pb-5 " style={{ fontSize: '56px', color: '' + this.state.colortexto + '', fontWeight: 'bold' }}>{this.state.txtseguro2}</p>
                        {/* <span className="position-absolute pt-5" style={{ fontSize: '21px', borderBottom: '1px solid ' + this.state.colortexto + '', color: '' + this.state.colortexto + '', cursor: 'pointer' }}>Ver Seguros <MDBIcon icon="caret-right" size="lg" className="pl-4 pt-2" style={{ color: '' + this.state.colortexto + '', position: 'absolute', right: '-30px' }} /></span> */}
                    </div>
                    {stateImg ?
                        (
                            <img style={{ width: '100%', height: '70vh', marginBottom: '15px', objectFit: 'cover' }} className="img-fluid" src={`${URLImgFront}/assets${this.state.rutafondoseg}`} alt="img1" />
                        ) : (
                            <React.Fragment></React.Fragment>
                        )}
                    <span className="ml-4 pt-4 font-weight-bold" style={{ fontSize: '20px' }}><MDBIcon icon="caret-right" className="ml-2 mr-1" /> Personas <MDBIcon icon="caret-right" className="ml-2 mr-1" /> Seguros</span>
                </div>
            </React.Fragment>
        );
    }
}

export default Personas;