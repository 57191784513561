import { MDBIcon } from 'mdbreact';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.min.css';
import { URLImgFront } from '../../config/URLDB';
import { ProductosSimula_ } from '../../services/WebAPI_Utrahuilca';

class Seccion2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stateLoad: false,
            infoProductos: [],
            slidesPerView: 3
        };
    }

    componentDidMount() {
        if (window.screen.width >= 550 && window.screen.width <= 767) {
            this.setState({
                slidesPerView: 2
            })
        }else if(window.screen.width >= 60 && window.screen.width <= 549){
            this.setState({
                slidesPerView: 1
            })
        }
        this.cargarInfo();
    }

    componentDidUpdate() {
        if (this.state.idtipoprod !== parseInt(localStorage.getItem('idtipoprod')) || this.state.idtipopers !== parseInt(localStorage.getItem('idtipopers'))) {
            this.cargarInfo();
        } else { }

    }

    cargarInfo = () => {
        const data = {
            tran: 10,
            idprodutra: 0,
            nombprodutra: 'NA',
            descprodutra: 'NA',
            rutaimgprod: 'NA',
            rutavistaprod: 'NA',
            prolinea: 0,
            idtipoprod: localStorage.getItem('idtipoprod'),
            idtipopers: localStorage.getItem('idtipopers'),
            estado: 1,
            usucrea: 'NA'
        }
        ProductosSimula_(data).then(response => {
            try {
                if (response.data.length > 0) {
                    if (response.data.length >= 1) {
                        if (response.data[0].message === "004404") {
                        } else {
                            setTimeout(() => {
                                this.setState({
                                    infoProductos: response.data,
                                    idtipoprod: response.data[0].idtipoprod,
                                    idtipopers: response.data[0].idtipopers,
                                    stateLoad: true
                                })
                            }, 10)
                        }
                    } else {
                    }
                } else {
                }
            } catch (error) {
                console.log(error);
            }
        });
    }

    render() {
        const params = {
            slidesPerView: this.state.slidesPerView,
            spaceBetween: 30,
            freeMode: true,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false,
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            shouldSwiperUpdate: true
        }
        let cardSlider = this.state.infoProductos.map((e, i) => {
            if (e.rutaproddet !== "NA") {
                return [
                    <div className="card mb-5" key={i}>
                        <Link to={`${e.rutaproddet.toLowerCase()}`}>
                            <div className="card-header px-0 py-0">
                                <img className="img-fluid sliderImg" style={{ height: '200px', width: '100%' }} src={`${URLImgFront}/assets${e.rutaimgprod}`} alt="img-1" />
                            </div>
                            <div className="card-body py-4 text-dark" style={{ height: '270px', width: '100%' }}>
                                <p className="pb-2 pl-2" style={{ fontSize: '20px' }}>{e.nombprodutra}</p>
                                <p style={{ height: '90px', width: '100%', overflow: 'hidden' }}>{e.descprodutra}</p>
                                <div className="mt-5 bg-white w-100 font-weight-bold mx-0 text-center" style={{ color: '#95bac1', fontSize: '17px' }}>Más Información <MDBIcon icon="arrow-right" size="lg" className="position-absolute" style={{ right: '20px', color: '#ed6b4e' }} /></div>
                            </div>
                        </Link>
                    </div>
                ]
            } else {
                var nameProduct = e.nombprodutra.replace(' ', '_');
                return [
                    <div className="card mb-5" key={i}>
                        <Link to={`/Productos/${e.idprodvtadet}/${nameProduct.toLowerCase()}`}>
                            <div className="card-header px-0 py-0">
                                <img className="img-fluid sliderImg" style={{ height: '200px', width: '100%' }} src={`${URLImgFront}/assets${e.rutaimgprod}`} alt="img-1" />
                            </div>
                            <div className="card-body py-4 text-dark" style={{ height: '270px', width: '100%' }}>
                                <p className="pb-2 pl-2" style={{ fontSize: '20px' }}>{e.nombprodutra}</p>
                                <p style={{ height: '90px', width: '100%', overflow: 'hidden' }}>{e.descprodutra}</p>
                                <div className="mt-5 bg-white w-100 font-weight-bold mx-0 text-center" style={{ color: '#95bac1', fontSize: '17px' }}>Más Información <MDBIcon icon="arrow-right" size="lg" className="position-absolute" style={{ right: '20px', color: '#ed6b4e' }} /></div>
                            </div>
                        </Link>
                    </div>
                ]
            }
        });
        return (
            <React.Fragment>
                <div className="bg-white seccion2" style={{ width: '100%' }}>
                    <div className="container">
                        <div className="text-center py-3 pb-5 mb-4 titulos">
                            <p className="my-0 py-0" style={{ fontSize: '25px', marginLeft: '-280px' }}>Persona {localStorage.getItem('nombTproduct')}</p>
                            <p className="font-weight-bold my-0 py-0" style={{ fontSize: '60px' }}>Cuentas y {localStorage.getItem('nombLinea')}</p>
                        </div>
                        {this.state.stateLoad ? (
                            <React.Fragment>
                                <Swiper {...params} className="mx-5">
                                    {cardSlider}
                                </Swiper>
                            </React.Fragment>
                        ) : (<React.Fragment></React.Fragment>)}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Seccion2;