import React, { Component } from 'react';
import { URLImgFront } from '../../config/URLDB';

class Seccion1 extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    render() {
        return (
            <React.Fragment>
                <div className="pse_diseño" style={{ width: '100%', paddingTop: '120px' }}>
                    <img style={{ width: '100%', height: '90vh', marginBottom: '15px', objectFit: 'cover' }} className="img-fluid" src={`${URLImgFront}/assets/img_principal/revalorizacion.png`} alt="revalorizacion" />
                    {/* <p className="texto_pse" style={{ fontSize: '30px', color: '#fff', fontWeight: 'bold', position: 'absolute', bottom: '-10px', left: '0', right: '0', marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}>REVALORIZACIONES 2020</p> */}
                </div>
            </React.Fragment>
        );
    }
}

export default Seccion1;