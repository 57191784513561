import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.min.css';
import './styleQ.css';
import { URLImgFront } from '../../config/URLDB';


class Seccion2 extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        const params = {
            spaceBetween: 30,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            shouldSwiperUpdate: true,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false,
            }
        }
        return (
            <React.Fragment>
                <div className="diseño_seccion2_quiero_asociarme" style={{ width: '100%' }}>
                    <div className="container-fluid mt-3">
                        <div className="px-0 py-0" style={{ background: '#f7f7f7' }}>
                            <div className="container">
                                <div className="row pb-3">
                                    <div className="col-md-12 py-4 text-center">
                                        <p className="font-weight-bold pl-5" style={{ fontSize: '27px' }}>Persona natural</p>
                                        <Swiper {...params} className="rounded">
                                            <div className="row px-2">
                                                <div className="col-md-6">
                                                    <div className="row">
                                                        <div className="col-md-12 d-flex px-5 align-items-center">
                                                            <p style={{ fontSize: '100px', color: 'gray' }}>1</p>
                                                            <div className="pl-3">
                                                                <p className="font-weight-bold text-left">Copia documento de identidad</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12 mt-0 pt-0">
                                                            <img width="120" className="img-fluid" src={`${URLImgFront}/assets/img-variadas/DOCUMENTOS.png`} alt="img1" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="row">
                                                        <div className="col-md-12 d-flex px-5 align-items-center">
                                                            <p style={{ fontSize: '100px', color: 'gray' }}>2</p>
                                                            <div className="pl-3">
                                                                <p className="font-weight-bold text-left">Diligenciamiento del Formato de vinculación</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12 mt-0 pt-0">
                                                            <img width="120" className="img-fluid" src={`${URLImgFront}/assets/img-variadas/DOCUMENTOS.png`} alt="img1" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Swiper>
                                    </div>
                                </div>
                                <hr />
                                <div className="row py-3">
                                    <div className="col-md-12 py-4 text-center">
                                        <p className="font-weight-bold pl-5" style={{ fontSize: '27px' }}>Persona jurídica</p>
                                        <Swiper {...params} className="rounded">
                                            <div className="row px-2">
                                                <div className="col-md-6">
                                                    <div className="row">
                                                        <div className="col-md-12 d-flex px-5 align-items-center">
                                                            <p style={{ fontSize: '100px', color: 'gray' }}>1</p>
                                                            <div className="pl-3">
                                                                <p className="font-weight-bold text-justify">Carta de condiciones de manejo de la cuenta</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12 mt-0 pt-0">
                                                            <img width="120" className="img-fluid" src={`${URLImgFront}/assets/img-variadas/DOCUMENTOS.png`} alt="img1" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="row">
                                                        <div className="col-md-12 d-flex px-5 align-items-center">
                                                            <p style={{ fontSize: '100px', color: 'gray' }}>2</p>
                                                            <div className="pl-3">
                                                                <p className="font-weight-bold text-justify">Copia de la parte pertinente del Acta o certificado de la misma, en donde el Consejo de Administración o Junta Directiva aprueben la vinculación de la organización a la Cooperativa.</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12 mt-0 pt-0">
                                                            <img width="120" className="img-fluid" src={`${URLImgFront}/assets/img-variadas/DOCUMENTOS.png`} alt="img1" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row px-2">
                                                <div className="col-md-6">
                                                    <div className="row">
                                                        <div className="col-md-12 d-flex px-5 align-items-center">
                                                            <p style={{ fontSize: '100px', color: 'gray' }}>3</p>
                                                            <div className="pl-3">
                                                                <p className="font-weight-bold text-justify">Diligenciamiento del formato de conocimiento del asociado o tercero con la información personal del Representante legal, apoderado, y de cada una de las personas autorizadas en las condiciones de manejo debidamente firmado.</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12 mt-0 pt-0">
                                                            <img width="120" className="img-fluid" src={`${URLImgFront}/assets/img-variadas/DOCUMENTOS.png`} alt="img1" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="row">
                                                        <div className="col-md-12 d-flex px-5 align-items-center">
                                                            <p style={{ fontSize: '100px', color: 'gray' }}>4</p>
                                                            <div className="pl-3">
                                                                <p className="font-weight-bold text-justify">Certificado de existencia y representación legal no mayor a 30 días o copia de la resolución de otorgamiento de personería jurídica. (de acuerdo al tipo de empresa).</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12 mt-0 pt-0">
                                                            <img width="120" className="img-fluid" src={`${URLImgFront}/assets/img-variadas/DOCUMENTOS.png`} alt="img1" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row px-2">
                                                <div className="col-md-6">
                                                    <div className="row">
                                                        <div className="col-md-12 d-flex px-5 align-items-center">
                                                            <p style={{ fontSize: '100px', color: 'gray' }}>5</p>
                                                            <div className="pl-3">
                                                                <p className="font-weight-bold text-justify">Resolución y/o decreto de creación de la entidad.</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12 mt-0 pt-0">
                                                            <img width="120" className="img-fluid" src={`${URLImgFront}/assets/img-variadas/DOCUMENTOS.png`} alt="img1" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="row">
                                                        <div className="col-md-12 d-flex px-5 align-items-center">
                                                            <p style={{ fontSize: '100px', color: 'gray' }}>6</p>
                                                            <div className="pl-3">
                                                                <p className="font-weight-bold text-justify">Declaración de Renta del último año gravable.</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12 mt-0 pt-0">
                                                            <img width="120" className="img-fluid" src={`${URLImgFront}/assets/img-variadas/DOCUMENTOS.png`} alt="img1" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className="row px-2">
                                                <div className="col-md-6">
                                                    <div className="row">
                                                        <div className="col-md-12 d-flex px-5 align-items-center">
                                                            <p style={{ fontSize: '100px', color: 'gray' }}>7</p>
                                                            <div className="pl-3">
                                                                <p className="font-weight-bold text-justify">Estados financieros del último año gravable o balance inicial, firmado por el representante legal, contador y revisor fiscal. En caso de no tener revisor fiscal lo certifica el contador.</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12 mt-0 pt-0">
                                                            <img width="120" className="img-fluid" src={`${URLImgFront}/assets/img-variadas/DOCUMENTOS.png`} alt="img1" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="row">
                                                        <div className="col-md-12 d-flex px-5 align-items-center">
                                                            <p style={{ fontSize: '100px', color: 'gray' }}>8</p>
                                                            <div className="pl-3">
                                                                <p className="font-weight-bold text-left">Fotocopia de los Estatutos.</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12 mt-0 pt-0">
                                                            <img width="120" className="img-fluid" src={`${URLImgFront}/assets/img-variadas/DOCUMENTOS.png`} alt="img1" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Swiper>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid mt-3" style={{ background: '#fff' }}>
                    <div className="row px-0 py-0">
                        <div className="col-md-5 py-0 d-flex justify-content-center">
                            <img className="img-fluid imagen-quiero-asociarme" style={{ left: '0px', position: 'absolute', bottom: '-10px', width: '100%', height: '900px' }} src={`${URLImgFront}/assets/Quiero Asociarme/BENEFICIOS POR SER ASOCIADO.png`} alt="BENEFICIOS POR SER ASOCIADO" />
                        </div>
                        <div className="col-md-7 py-4">
                            <p className="font-weight-bold w-75" style={{ fontSize: '27px', color: '#FA7500' }}>Beneficios por ser asociado:</p>
                            <p className="text-justify"><b style={{ color: '#FA7500' }}>*</b> Ser propietario(a) de una de las Cooperativas de Ahorro y Crédito más grandes del país.</p>
                            <p className="text-justify"><b style={{ color: '#FA7500' }}>*</b> Ser gestor(a) cooperativo(a) a través de la participación en las actividades democráticas realizadas por la cooperativa.</p>
                            <p className="text-justify"><b style={{ color: '#FA7500' }}>*</b> Contribuir directamente al desarrollo económico y social de la región Surcolombiana.</p>
                            <p className="text-justify"><b style={{ color: '#FA7500' }}>*</b> Facilidad de acceso a los productos de ahorro y crédito que se ajustan a sus necesidades.</p>
                            <p className="text-justify"><b style={{ color: '#FA7500' }}>*</b> Para asociados (as) infantiles y juveniles, facilidad en el acceso a los diferentes productos de ahorro sección amiguitos y acceso a escuelas de formación cooperativa, artísticas, deportivas y de artes oficios a través de Fundautrahuilca.</p>
                            <p className="text-justify"><b style={{ color: '#FA7500' }}>*</b> Subsidios totales y parciales de costos financieros en los productos de ahorro y crédito.</p>
                            <p className="text-justify"><b style={{ color: '#FA7500' }}>*</b> Subsidio de apoyo solidario para el (los) beneficiario (s), en caso del fallecimiento del asociado (a) titular.</p>
                            <p className="text-justify"><b style={{ color: '#FA7500' }}>*</b> Tarifas especiales en adquisición de seguros con LA EQUIDAD SEGUROS.</p>
                            <p className="text-justify"><b style={{ color: '#FA7500' }}>*</b> Tarifas preferenciales y Subsidio hasta el 100% en su previsión exequial en convenio con EMCOFUN/LOS OLIVOS.</p>
                            <p className="text-justify"><b style={{ color: '#FA7500' }}>*</b> Acceder a las tarifas preferenciales en productos y servicios a través de los diferentes convenios empresariales de beneficios e interinstitucionales suscritos por UTRAHUILCA.</p>
                            <p className="text-justify"><b style={{ color: '#FA7500' }}>*</b> Ahorro en el costo administrativo en su empresa a través de la suscripción de convenios empresariales (Pago de Salarios, Descuento por libranza y Dispersión de pagos) sin ningún costo.</p>
                            <p className="text-justify"><b style={{ color: '#FA7500' }}>*</b> Ahorro en gastos financieros y disminución de riesgo por custodia de efectivo en su empresa gracias a la suscripción de convenios de SERVIRECAUDO sin ningún costo, para recaudo de sus cuotas de administración, Matriculas, Pensiones, Servicios públicos, entre otros.</p>
                            <p className="text-justify"><b style={{ color: '#FA7500' }}>*</b> Más puntos de atención en las Agencias Mini-Básicas y Extensiones de caja UTRAHUILCA, donde podrá acceder a información del Portafolio de Productos, Servicios y Beneficios, haciendo uso de ellos sin costo alguno y en horarios adicionales.</p>
                            <p className="text-justify"><b style={{ color: '#FA7500' }}>*</b> Participación en los planes de estímulos en ahorro y crédito diseñados para los asociados (as).</p>
                            <p className="text-justify"><b style={{ color: '#FA7500' }}>*</b> Participación en los programas sociales y culturales que organiza FUNDAUTRAHUILCA.</p>
                            <p className="text-justify"><b style={{ color: '#FA7500' }}>*</b> Acceso a educación cooperativa y empresarial gratuita.</p>
                            <p className="text-justify"><b style={{ color: '#FA7500' }}>*</b> Acceso a otros canales transaccionales para realizar consignaciones, retiros, pagos de cuotas de aportes sociales/ahorro/crédito, en puntos de Su Chance, Efecty, Banco Agrario, Banco de Bogotá y corresponsales bancarios del Grupo AVAL (Tarjeta débito afinidad) y las cajas de almacenes del Grupo Éxito.</p>
                        </div>
                    </div>
                </div>
                <div className="container-fluid" style={{ background: '#f7f7f7' }}>
                    <div className="row">
                        <div className="col-md-5 d-flex justify-content-center align-items-center">
                            <div className="text-center px-5 w-75 pb-5 contenido_f_q">
                                <p className="mb-5 text-justify" style={{ fontSize: '25px', color: 'gray' }}>Para recibir asesoría personalizada, comuníquese con nuestros diferentes canales de atención.</p>
                                <div className="row">
                                    <div className="col-md-12">
                                        <Link to="/Canales_Atencion">
                                            <span className="px-5 py-2 w-100 btn-quiero-asociarme" style={{ background: '#3f8592', color: 'white', fontWeight: 'bold', borderRadius: '20px', cursor: 'pointer', fontSize: '20px' }}>Canales de atención</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-7 px-0 py-0">
                            <img style={{ width: '100%' }} src={`${URLImgFront}/assets/img_principal/PORTADA CANALES DE ATENCIÓN.png`} alt="PORTADA CANALES DE ATENCIÓN" />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Seccion2;