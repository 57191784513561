import { MDBIcon } from 'mdbreact';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { URLImgFront } from '../../config/URLDB';

class Seccion3 extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    render() {
        return (
            <React.Fragment>
                <div style={{ width: '100%', background: '#f7f7f7' }}>
                    <div className="container py-5 text-dark">
                        <p className="font-weight-bold" style={{ fontSize: '30px', color: '#000' }}>Beneficios</p>
                        <div className="row px-0">
                            <div className="col-md-12 py-5">
                                <p><MDBIcon icon="chevron-right" style={{ color: '#3f8592' }} /> Ser asociado o asociada de UTRAHUILCA</p>
                                <p><MDBIcon icon="chevron-right" style={{ color: '#3f8592' }} /> Encontrarse al día en el cumplimiento de sus obligaciones legales y estatutarias con la Cooperativa.</p>
                                <p><MDBIcon icon="chevron-right" style={{ color: '#3f8592' }} /> Demostrar su real capacidad de pago y solvencia mediante ingresos lícitos bien sea de su relación laboral, actividades profesionales, productivas, comerciales y servicios.</p>
                                <p><MDBIcon icon="chevron-right" style={{ color: '#3f8592' }} /> Cuando se trate de proyectos productivos se debe anexar el plan de negocios, con flujo de caja incluyendo la cuota del crédito solicitado.</p>
                                <p><MDBIcon icon="chevron-right" style={{ color: '#3f8592' }} /> Presentar las garantías y documentación que le sean solicitadas, incluyendo declaración de renta de los dos (2) últimos años, cuando la Cooperativa lo considere necesario.</p>
                                <p><MDBIcon icon="chevron-right" style={{ color: '#3f8592' }} /> De acuerdo al perfil del solicitante se especificará la documentación necesaria.</p>
                            </div>
                            <div className="col-md-12 pt-5 pb-5 d-flex justify-content-center">
                                <Link to="/Productos/Crédito/1/Solicitud">
                                    <span className="px-5 py-2" style={{ background: '#3f8592', color: 'white', fontWeight: 'bold', borderRadius: '20px', cursor: 'pointer' }}>Solicitar Crédito</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-5 pl-0 pr-0">
                                <img style={{ width: '100%' }} src={`${URLImgFront}/assets/img-variadas/credito_empleados.jpg`} alt="img-1" />
                            </div>
                            <div className="col-md-7 pl-5 py-5 text-white" style={{ background: '#3f8592' }}>
                                <p className="pl-3 font-weight-bold" style={{ fontSize: '25px' }}>EMPLEADOS</p>
                                <p><MDBIcon icon="chevron-right" style={{ color: '#fff' }} /> Diligenciar y presentar el formato de la solicitud del crédito.</p>
                                <p><MDBIcon icon="chevron-right" style={{ color: '#fff' }} /> Fotocopia de la cédula de ciudadanía ampliada al 150%.</p>
                                <p><MDBIcon icon="chevron-right" style={{ color: '#fff' }} /> Certificación laboral original, no mayor a 30 días de expedido.</p>
                                <p><MDBIcon icon="chevron-right" style={{ color: '#fff' }} /> Desprendibles de pago de los dos (2) últimos meses. </p>
                                <p><MDBIcon icon="chevron-right" style={{ color: '#fff' }} /> Si está obligado a declarar, debe anexar la Declaración de Renta del último año gravable. En caso de no estar obligado debe anexar la certificación de no declarante.</p>
                                <p><MDBIcon icon="chevron-right" style={{ color: '#fff' }} /> Fotocopia del recibo de servicio público.</p>
                                <p style={{ position: 'absolute', bottom: '10px', right: '60px', borderBottom: '1px solid #fff', fontWeight: 'bold', fontSize: '20px', cursor: 'pointer' }}>Solicitar Crédito <MDBIcon icon="chevron-right" style={{ color: '#fff' }} /></p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-5 pl-0 pr-0">
                                <img style={{ width: '100%' }} src={`${URLImgFront}/assets/img-variadas/credito_pensionados.jpg`} alt="img-2" />
                            </div>
                            <div className="col-md-7 pl-5 py-5 text-dark" style={{ background: '#f7f7f7' }}>
                                <p className="pl-3 font-weight-bold" style={{ fontSize: '25px' }}>PENSIONADOS</p>
                                <p><MDBIcon icon="chevron-right" style={{ color: '#000' }} /> Diligenciar y presentar la solicitud del crédito.</p>
                                <p><MDBIcon icon="chevron-right" style={{ color: '#000' }} /> Fotocopia de la cédula de ciudadanía ampliada al 150%. (No se aceptan contraseñas).</p>
                                <p><MDBIcon icon="chevron-right" style={{ color: '#000' }} /> Desprendibles de pago de los dos (2) últimos meses.</p>
                                <p><MDBIcon icon="chevron-right" style={{ color: '#000' }} /> Copia de la resolución de la pensión.</p>
                                <p><MDBIcon icon="chevron-right" style={{ color: '#000' }} /> Si está obligado a declarar, debe anexar la Declaración de Renta del último año gravable. En caso de no estar obligado debe anexar la certificación de no declarante.</p>
                                <p><MDBIcon icon="chevron-right" style={{ color: '#000' }} /> Fotocopia del recibo de servicio público más reciente.</p>
                                <p style={{ position: 'absolute', bottom: '10px', right: '60px', color: '#747373', borderBottom: '1px solid #747373', fontWeight: 'bold', fontSize: '20px', cursor: 'pointer' }}>Solicitar Crédito <MDBIcon icon="chevron-right" style={{ color: '#747373' }} /></p>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Seccion3;