import React, { Component } from 'react';
import '../responsive/Footer.css';
import '../responsive/Principal.css';
import MensajeF from '../components/Mensaje_Flotante/MensajeF';
import Footer from '../components/Principal/Footer';
import Header from '../components/Principal/Header';
import NAsociados from '../components/Principal/N_Asociados';
import Seccion1 from '../components/Principal/Seccion1';
import SwiperIMG from '../components/Principal/SwiperIMG';
import TarjetasInferior from '../components/Principal/Tarjetas_Inferior';
import { MDBContainer, MDBModal, MDBModalHeader } from 'mdbreact';
import { ModalPrincipal } from '../services/WebAPI_Utrahuilca';
import Swal from 'sweetalert2';
import { URLImgFront } from '../config/URLDB';


class Principal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: true,
            estadoTiempo: false,
            nombreModal: '',
            rutaimgmodal: '',
            estadoModal: false,
            forecasts: [],
            loading: true
        };
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    }

    async componentDidMount() {
        const data = {
            tran: 6,
            nombremodal: 'NA',
            rutaimgmodal: 'NA',
            fecinimodal: '01/01/1900',
            fecfinmodal: '01/01/1900',
            rutavistainfo: 'NA',
            usucrea: 'NA'
        }
        ModalPrincipal(data).then(response => {
            try {
                if (response.data.length > 0) {
                    if (response.data[0].message === "004404") {
                        this.setState({
                            estadoModal: false
                        })
                    } else {
                        this.setState({
                            estadoTiempo: true,
                            nombreModal: response.data[0].nombremodal,
                            rutaimgmodal: response.data[0].rutaimgmodal,
                            rutavistainfo: response.data[0].rutavistainfo,
                            estadoModal: true
                        })
                    }
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Ocurrio un error!'
                    })
                }
            } catch (error) {
                console.log(error);
            }
        });
    }

    render() {
        const { estadoModal, estadoTiempo, rutaimgmodal } = this.state;
        return (
            <React.Fragment>
                <Header id={2} />
                <SwiperIMG />
                <TarjetasInferior />
                <MDBContainer>
                    {estadoModal ? (
                        <React.Fragment>
                            {estadoTiempo ? (
                                <React.Fragment>
                                    <MDBModal size="lg" isOpen={this.state.modal} toggle={this.toggle} centered>
                                        <MDBModalHeader toggle={this.toggle} >
                                            <img className="img-fluid" src={`${URLImgFront}/assets${rutaimgmodal}`} alt={rutaimgmodal} />
                                        </MDBModalHeader>
                                        <a href={this.state.rutavistainfo} target={'_blank'} rel="noopener noreferrer">
                                            <button onClick={() => { this.toggle() }} className="btn text-white" style={{ background: '#f07f00', width: '160px', marginLeft: '40%' }}>Aceptar</button>
                                        </a>
                                    </MDBModal>
                                </React.Fragment>
                            ) : (
                                <React.Fragment></React.Fragment>
                            )}
                        </React.Fragment>
                    ) : (
                        <React.Fragment></React.Fragment>
                    )}
                </MDBContainer>
                <Seccion1 />
                {/* <NAliados /> */}
                {/* <Noticias /> */}
                <MensajeF />
                <NAsociados />
                <Footer />
            </React.Fragment>
        );
    }
}

export default Principal;