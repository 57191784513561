import React, { Component } from 'react';
import { URLImgFront } from '../../config/URLDB';

class Seccion2 extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    render() {
        return (
            <React.Fragment>
                <div className="bg-white" style={{ width: '100%' }}>
                    <div className="container py-5">
                        <p className="px-5 texto-contenido">Invierta su dinero a término fijo, con una excelente rentabilidad por encima de la ofrecida en el mercado financiero tradicional. La duración va desde 15 días hasta 16 meses.</p>
                        <div className="row px-5 py-4">
                            <div className="col-md-6 d-flex">
                                <img style={{ width: '100px', height: '100px' }} className="img-fluid" src={`${URLImgFront}/assets/iconos/ico-consignaciones.svg`} alt="ico-consignaciones" />
                                <p className="pl-4 mt-3">Dirigido a persona natural mayor de edad y menor de edad.</p>
                            </div>
                            <div className="col-md-6 d-flex">
                                <img style={{ width: '100px', height: '100px' }} className="img-fluid" src={`${URLImgFront}/assets/iconos/ahorra-naranja.svg`} alt="ahorra-naranja" />
                                <p className="pl-4 mt-3">Montos de apertura: Adulto Desde 1 un SMMLV - Infantil Desde el 25% de un S.M.M.L.V. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Seccion2;