import { MDBIcon, MDBInput, MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import React, { Component } from 'react';
import { ProductosSimula_, CargueImagenes, TiposProductos_ } from '../../../services/WebAPI_Utrahuilca';
import FileUploaderB from './Producto/FileUploaderB';
import Swal from 'sweetalert2';
import FileUploader from "./Producto/FileUploader";
import { URLImgFront } from '../../../config/URLDB';

const validate = values => {
    const errors = {}
    if (values.prolinea === 'DEFAULT') {
        errors.prolinea = 'campo obligatorio'
    }
    if (!values.nombprodutra) {
        errors.nombprodutra = 'campo obligatorio'
    }
    if (values.idtipoprod === 'DEFAULT') {
        errors.idtipoprod = 'campo obligatorio'
    }
    if (values.idtipopers === 'DEFAULT') {
        errors.idtipopers = 'campo obligatorio'
    }
    if (!values.descprodutra) {
        errors.descprodutra = 'campo obligatorio'
    }
    return errors;
};

class Producto extends Component {
    uploader = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            estadoNuevo: false,
            errors: {},
            infoProductos: [],
            estado: null,
            idprodutra: null,
            nombprodutra: '',
            descprodutra: '',
            rutaimgprod: '',
            rutavistaprod: '',
            prolinea: 'DEFAULT',
            idtipoprod: 'DEFAULT',
            idtipopers: 'DEFAULT',
            estadoCambioImg: true,
            estadoEditar: false,
            estadoCambio: false,
            estadoSinResultados: false,
            infoProductosUtraW: []
        };
    }

    componentDidMount() {
        this.cargarInformacion();
    }

    cargarInformacion = () => {
        const data = {
            tran: 4,
            idprodutra: 0,
            nombprodutra: 'NA',
            descprodutra: 'NA',
            rutaimgprod: 'NA',
            rutavistaprod: 'NA',
            prolinea: 0,
            idtipoprod: 0,
            idtipopers: 0,
            estado: 1,
            usucrea: 'NA'
        }
        ProductosSimula_(data).then(response => {
            try {
                if (response.data.length > 0) {
                    if (response.data.length >= 1) {
                        if (response.data[0].message === "004404") {
                            this.setState({
                                estadoSinResultados: false
                            })
                        } else {
                            this.setState({
                                infoProductos: response.data,
                                idprodutra: response.data[0].idprodutra,
                                rutaiconobenef: response.data[0].rutaiconobenef,
                                descbenef: response.data[0].descbenef,
                                estado: response.data[0].estado,
                                estadoSinResultados: true
                            })
                        }
                    } else {
                        this.setState({
                            estadoSinResultados: false
                        })
                    }
                } else {
                    this.setState({
                        estadoNuevo: true
                    })
                }
            } catch (error) {
                console.log(error);
            }
        });
    }

    cargarImagen = () => {
        var formData = new FormData();
        if (undefined === this.uploader.current) return;
        
        formData.append("file", this.uploader.current.getFileObject());
        formData.append("rutaImagen", '/Productos/' + this.uploader.current.getFileName());
        CargueImagenes(formData).then(response => {
            if (response) {
                this.cargarInformacion();
            }
        });
    }

    insertarDatos = () => {
        const { errors, ...sinErrors } = this.state;
        const result = validate(sinErrors);
        this.setState({ errors: result });
        if (!Object.keys(result).length) {
            // formulario validado
            const data = {
                tran: 1,
                idprodutra: 0,
                nombprodutra: this.state.nombprodutra,
                descprodutra: this.state.descprodutra,
                rutaimgprod: '/Productos/' + this.uploader.current.getFileName(),
                rutavistaprod: 'NA',
                prolinea: parseInt(this.state.prolinea),
                idtipoprod: parseInt(this.state.idtipoprod),
                idtipopers: parseInt(this.state.idtipopers),
                estado: 1,
                usucrea: sessionStorage.getItem('idusuario')
            }
            ProductosSimula_(data).then(response => {
                if (response.data.length > 0) {
                    if (response.data[0].message === "000000") {
                        this.cargarImagen();
                        Swal.fire({
                            icon: 'success',
                            title: 'Se agrego correctamente.'
                        })
                        this.setState({
                            estadoNuevo: false,
                            nombprodutra: '',
                            descprodutra: '',
                            rutaimgprod: '',
                            prolinea: 'DEFAULT',
                            idtipoprod: 'DEFAULT',
                            idtipopers: 'DEFAULT'
                        })
                        this.cargarInformacion();
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error al insertar.'
                        })
                    }
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Ocurrio un error!'
                    })
                }
            });
        }
    }

    actualizarDatos = (id) => {
        var ruta = '';
        var capNombre = this.uploader.current.getFileName();
        if (capNombre === undefined || capNombre === null) {
            ruta = this.state.rutaimgprod;
        } else {
            ruta = '/Productos/' + this.uploader.current.getFileName();
        }
        const data = {
            tran: 1,
            idprodutra: id,
            nombprodutra: this.state.nombprodutra,
            descprodutra: this.state.descprodutra,
            rutaimgprod: ruta,
            rutavistaprod: 'NA',
            prolinea: this.state.prolinea,
            idtipoprod: parseInt(this.state.idtipoprod),
            idtipopers: parseInt(this.state.idtipopers),
            estado: 1,
            usucrea: sessionStorage.getItem('idusuario')
        }
        ProductosSimula_(data).then(response => {
            if (response.data.length > 0) {
                if (response.data[0].message === "000000") {
                    this.cargarImagen();
                    this.setState({
                        estadoCambioImg: false,
                        estadoEditar: false
                    })
                    Swal.fire({
                        icon: 'success',
                        title: 'Se actualizo satisfactoriamente'
                    })
                    this.cargarInformacion();
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Ocurrio un error al actualizar'
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Ocurrio un error!'
                })
            }
        });
    }

    textInput = (e) => {
        if (e.target.name === "idtipoprod") {
            this.cargarProductos(e.target.value);
        }
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    cargarProductos = (id) => {
        TiposProductos_({ id_cuenta: parseInt(id) }).then(response => {
            try {
                if (response.data.length > 0) {
                    if (response.data[0].respuesta) {
                        Swal.fire({
                            icon: 'success',
                            title: 'No se logró cargar los productos a enlazar'
                        });
                    } else {
                        this.setState({
                            infoProductosUtraW: response.data
                        })
                    }
                } else { }
            } catch (error) {
                console.log(error);
            }
        });
    }

    cambioImagen = (name, file) => {
        this.setState({
            estadoCambioImg: true
        });
    }

    editar = (id) => {
        var seleccionado = [];
        this.state.infoProductos.map((e, i) => {
            if (e.idprodutra === id) {
                seleccionado.push(e)
            }
            return [];
        })
        this.cargarProductos(seleccionado[0].idtipoprod);
        this.setState({
            idprodutra: seleccionado[0].idprodutra,
            prolinea: seleccionado[0].prolinea,
            nombprodutra: seleccionado[0].nombprodutra,
            idtipoprod: seleccionado[0].idtipoprod,
            idtipopers: seleccionado[0].idtipopers,
            descprodutra: seleccionado[0].descprodutra,
            rutaimgprod: seleccionado[0].rutaimgprod,
            estadoEditar: true
        })
    }

    cambio = (name, file) => {
        this.setState({
            estadoCambio: true
        });
    }

    activar = (id) => {
        const data = {
            tran: 9,
            idprodutra: id,
            nombprodutra: 'NA',
            descprodutra: 'NA',
            rutaimgprod: 'NA',
            rutavistaprod: 'NA',
            prolinea: 0,
            idtipoprod: 0,
            idtipopers: 0,
            estado: 1,
            usucrea: sessionStorage.getItem('idusuario')
        }
        ProductosSimula_(data).then(response => {
            if (response) {
                this.setState({
                    estadoS: false
                })
                Swal.fire({
                    icon: 'success',
                    title: 'Se activo satisfactoriamente'
                });
                this.cargarInformacion();
            }
        });
    }

    inactivar = (id) => {
        const data = {
            tran: 9,
            idprodutra: id,
            nombprodutra: 'NA',
            descprodutra: 'NA',
            rutaimgprod: 'NA',
            rutavistaprod: 'NA',
            prolinea: 0,
            idtipoprod: 0,
            idtipopers: 0,
            estado: 0,
            usucrea: sessionStorage.getItem('idusuario')
        }
        ProductosSimula_(data).then(response => {
            if (response) {
                this.setState({
                    estadoS: false
                })
                Swal.fire({
                    icon: 'success',
                    title: 'Se inactivo satisfactoriamente'
                });
                this.cargarInformacion();
            }
        });
    }

    handleNew = () => {
        this.setState({
            estadoNuevo: !this.state.estadoNuevo,
            errors: {},
            nombprodutra: '',
            descprodutra: '',
            prolinea: 'DEFAULT',
            idtipoprod: 'DEFAULT',
            idtipopers: 'DEFAULT',
            infoProductosUtraW: []
        })
        if (this.state.estadoNuevo) {
            this.setState({
                errors: {},
                nombprodutra: '',
                descprodutra: '',
                prolinea: 'DEFAULT',
                idtipoprod: 'DEFAULT',
                idtipopers: 'DEFAULT',
                infoProductosUtraW: []
            })
            this.cargarInformacion();
        }
    }

    render() {
        const { estadoNuevo, estadoCambioImg, estadoCambio, prolinea, nombprodutra, descprodutra, errors, idtipoprod, idtipopers, infoProductos, estadoEditar, idprodutra, estadoSinResultados } = this.state;
        return (
            <React.Fragment>
                <p className="font-weight-bold">Productos</p>
                <hr />
                <div className="plus_add_agencia align-items-center d-flex" onClick={() => { this.handleNew() }}>
                    {estadoNuevo ? <p>Cancelar &nbsp;<MDBIcon icon="times" size="lg" /></p> : <p>Crear Producto &nbsp;<MDBIcon icon="plus-circle" size="lg" /></p>}
                </div>
                {estadoNuevo ? (
                    <React.Fragment>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 my-3 text-center">
                                    <FileUploaderB ref={this.uploader} estadoImg={estadoCambioImg} FCambiar={this.cambioImagen} />
                                </div>
                                <div className="col-md-6 my-2">
                                    <label>Tipo de Producto</label>
                                    <select name="idtipoprod" className="form-control" value={idtipoprod} onChange={this.textInput}>
                                        <option value="DEFAULT" disabled>Seleccione...</option>
                                        <option value="1">Ahorro</option>
                                        <option value="2">Crédito</option>
                                    </select>
                                    {errors.idtipoprod && <strong className="text-danger" style={{ fontSize: '13px' }}>{errors.idtipoprod}</strong>}
                                </div>
                                <div className="col-md-6 my-2">
                                    <label>Prolinea (UTRAWEB)</label>
                                    <select name="prolinea" className="form-control" value={prolinea} onChange={this.textInput}>
                                        <option value="DEFAULT" disabled>Seleccione...</option>

                                        {this.state.infoProductosUtraW &&
                                            this.state.infoProductosUtraW.map((e, i) => {
                                                return [
                                                    <React.Fragment key={i}>
                                                        <option value={e.codprolinea}>{e.descprolinea}</option>
                                                    </React.Fragment>
                                                ]
                                            })
                                        }
                                    </select>

                                    {errors.prolinea && <strong className="text-danger" style={{ fontSize: '13px' }}>{errors.prolinea}</strong>}
                                </div>
                                <div className="col-md-6 my-2">
                                    <label>Tipo de Persona</label>
                                    <select name="idtipopers" className="form-control" value={idtipopers} onChange={this.textInput}>
                                        <option value="DEFAULT" disabled>Seleccione...</option>
                                        <option value="1">Natural</option>
                                        <option value="0">Jurídica</option>
                                    </select>
                                    {errors.idtipopers && <strong className="text-danger" style={{ fontSize: '13px' }}>{errors.idtipopers}</strong>}
                                </div>
                                <div className="col-md-6 my-2">
                                    <label>Nombre del Producto</label>
                                    <MDBInput label="Digite el nombre producto" name="nombprodutra" type="text" value={nombprodutra} onChange={this.textInput} />
                                    {errors.nombprodutra && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '80px' }}>{errors.nombprodutra}</strong>}
                                </div>
                                <div className="col-md-12 mt-3">
                                    <label>Descripción</label>
                                    <MDBInput name="descprodutra" type="textarea" rows="5" value={descprodutra} onChange={this.textInput} />
                                    {errors.descprodutra && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', marginTop: '-30px' }}>{errors.descprodutra}</strong>}
                                </div>
                            </div>
                        </div>
                        <div className="pt-2 mt-5 modal-footer w-100">
                            <button type="button" className="btn btn-primary px-5" onClick={this.insertarDatos}>Crear Producto &nbsp; <MDBIcon icon="save" /></button>
                        </div>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className="container-fluid">
                            <div className="table-responsive text-center w-100">
                                <MDBTable>
                                    <MDBTableHead>
                                        <tr className="font-weight-bold">
                                            <td>Imagen</td>
                                            <td>Nombre Producto</td>
                                            <td>Tipo de Producto</td>
                                            <td>ProLinea</td>
                                            <td>Tipo de Persona</td>
                                            <td>Descripción</td>
                                            {estadoEditar ?
                                                (
                                                    <React.Fragment>
                                                        <td>Guardar</td>
                                                        <td>Cancelar</td>
                                                    </React.Fragment>
                                                ) : (
                                                    <React.Fragment>
                                                        <td>Editar</td>
                                                        <td>Inactivar</td>
                                                    </React.Fragment>
                                                )}
                                        </tr>
                                    </MDBTableHead>
                                    <MDBTableBody>
                                        {estadoSinResultados ? (
                                            <React.Fragment>
                                                {estadoEditar ?
                                                    (
                                                        <React.Fragment>
                                                            <tr>
                                                                <td>
                                                                    <FileUploader ref={this.uploader} estado={estadoCambio} ruta={this.state.rutaimgprod} FCambiar={this.cambio} />
                                                                </td>
                                                                <td className="w-25"><input className="form-control" type="text" name="nombprodutra" value={this.state.nombprodutra} onChange={this.textInput} /></td>
                                                                <td className="w-25">
                                                                    <select name="idtipoprod" className="form-control" value={this.state.idtipoprod} onChange={this.textInput}>
                                                                        <option value="DEFAULT" disabled>Seleccione...</option>
                                                                        <option value="1">Ahorro</option>
                                                                        <option value="2">Crédito</option>
                                                                    </select>
                                                                </td>
                                                                <td className="w-25">
                                                                    <select name="prolinea" className="form-control" value={prolinea} onChange={this.textInput}>
                                                                        <option value="DEFAULT" disabled>Seleccione...</option>
                                                                        
                                                                        {this.state.infoProductosUtraW &&
                                                                            this.state.infoProductosUtraW.map((e, i) => {
                                                                                return [
                                                                                    <React.Fragment key={i}>
                                                                                        <option value={e.codprolinea}>{e.descprolinea}</option>
                                                                                    </React.Fragment>
                                                                                ]
                                                                            })
                                                                        }
                                                                    </select>
                                                                </td>
                                                                <td className="w-25">
                                                                    <select name="idtipopers" className="form-control" value={this.state.idtipopers} onChange={this.textInput}>
                                                                        <option value="DEFAULT" disabled>Seleccione...</option>
                                                                        <option value="1">Natural</option>
                                                                        <option value="0">Jurídica</option>
                                                                    </select>
                                                                </td>
                                                                <td className="w-75"><textarea className="form-control w-100" type="textarea" rows="10" name="descprodutra" value={this.state.descprodutra} onChange={this.textInput} /></td>
                                                                <td className="text-center" onClick={() => { this.actualizarDatos(idprodutra) }}><MDBIcon icon="save" size="2x" style={{ color: 'blue', cursor: 'pointer' }} /></td>
                                                                <td className="text-center" onClick={() => { this.setState({ estadoEditar: !this.state.estadoEditar }) }}><MDBIcon icon="times" size="2x" style={{ color: 'red', cursor: 'pointer' }} /></td>
                                                            </tr>
                                                        </React.Fragment>
                                                    ) : (
                                                        <React.Fragment>
                                                            {infoProductos.map((e, i) => {
                                                                let view;
                                                                let tp;
                                                                let pers;
                                                                if (e.estado === 1) {
                                                                    view = <MDBIcon onClick={() => { this.inactivar(e.idprodutra) }} icon="eye" size="2x" style={{ cursor: 'pointer' }} />
                                                                } else {
                                                                    view = <MDBIcon onClick={() => { this.activar(e.idprodutra) }} icon="eye-slash" size="2x" style={{ cursor: 'pointer' }} />
                                                                }
                                                                if (e.idtipoprod === 1) {
                                                                    tp = 'Ahorro'
                                                                } else {
                                                                    tp = 'Crédito'
                                                                }
                                                                if (e.idtipopers === 1) {
                                                                    pers = 'Natural'
                                                                } else {
                                                                    pers = 'Jurídica'
                                                                }
                                                                return [
                                                                    <React.Fragment key={i}>
                                                                        <tr>
                                                                            <td><img width="150" src={`${URLImgFront}/assets${e.rutaimgprod}`} alt={e.rutaimgprod} /></td>
                                                                            <td>{e.nombprodutra}</td>
                                                                            <td>{tp}</td>
                                                                            <td>{e.prolinea}</td>
                                                                            <td>{pers}</td>
                                                                            <td>{e.descprodutra}</td>
                                                                            <td className="text-center" onClick={() => { this.editar(e.idprodutra) }}><MDBIcon icon="edit" size="2x" style={{ color: 'green', cursor: 'pointer' }} /></td>
                                                                            <td className="text-center">{view}</td>
                                                                        </tr>
                                                                    </React.Fragment>
                                                                ]
                                                            })}
                                                        </React.Fragment>
                                                    )}
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment>
                                                <tr className="alert alert-info text-center w-100">
                                                    <td>Sin resultados</td>
                                                </tr>
                                            </React.Fragment>
                                        )}
                                    </MDBTableBody>
                                </MDBTable>
                            </div>
                        </div>
                    </React.Fragment>
                )}

            </React.Fragment>
        );
    }
}

export default Producto;