import { MDBIcon } from 'mdbreact';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { URLImgFront } from '../../config/URLDB';
// import Chat from './Chat/Chat';
import Llamanos from './LLamanos/Llamanos';
// import Llamamos from './Nosotros_te_llamamos/Llamamos';
import "./style.css";

class MensajeF extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stateContenido: false,
            stateChat: false,
            stateLlamamos: false,
            stateLlamanos: false
        };
    }

    // activarChat = () => {
    //     this.setState({
    //         stateContenido: !this.state.stateContenido,
    //         stateChat: true
    //     })
    // }

    // activarLlamamos = () => {
    //     this.setState({
    //         stateContenido: !this.state.stateContenido,
    //         stateLlamamos: true
    //     })
    // }

    activarLlamanos = () => {
        this.setState({
            stateContenido: !this.state.stateContenido,
            stateLlamanos: true
        })
    }

    cerrarChat = () => {
        this.setState({
            stateContenido: !this.state.stateContenido,
            stateChat: false,
            stateLlamamos: false,
            stateLlamanos: false
        })
    }

    render() {
        const { stateContenido, stateLlamanos } = this.state;
        return (
            <React.Fragment>
                { /* SE HABILITA WS 2023/12/20 YC */}
                <Link className="text-dark" rel="noopener noreferrer" to="/PSE">
                    <div className="d-flex align-items-center justify-content-center boton-flotante botones" style={{ overflow: 'hidden', height: '70px', width: '70px', zIndex: '998', borderRadius: '50%', position: 'fixed', bottom: '180px', right: '30px', cursor: 'pointer', border: '2px solid white' }}>
                        <img className="img-fluid" src={`${URLImgFront}/assets/logo/LogoPSE.png`} alt="PSE" />
                    </div>
                </Link> 

                <a className="text-dark" rel="noopener noreferrer" target={'_blank'} href={`https://api.whatsapp.com/send?phone=573102100828`}>
                    <div className="d-flex align-items-center justify-content-center boton-flotante botones" style={{ overflow: 'hidden', background: '#1bd741', height: '70px', width: '70px', zIndex: '998', borderRadius: '50%', position: 'fixed', bottom: '105px', right: '30px', cursor: 'pointer', border: '2px solid white' }}>
                        <MDBIcon fab icon="whatsapp" size="3x" className="text-white text-center" />
                    </div>
                </a>

                <div className="d-flex align-items-center justify-content-center boton-flotante botones" onClick={() => { this.setState({ stateContenido: !this.state.stateContenido }) }} style={{ overflow: 'hidden', background: '#e95c0c', height: '70px', width: '70px', zIndex: '999', borderRadius: '50%', position: 'fixed', bottom: '30px', right: '30px', cursor: 'pointer', border: '2px solid white' }}>
                    {stateContenido ?
                        (
                            <React.Fragment>
                                <MDBIcon icon="times" size="2x" className="text-white text-center" />
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <img width="40" className="img-fluid parpadea" src={`${URLImgFront}/assets/iconos/robot_chat_Utrahuilca.svg`} alt="robot-chat" />
                            </React.Fragment>
                        )}
                </div>
                {stateContenido ?
                    (
                        <React.Fragment>
                            <div className="contenido-flotante">
                                <Link to="/Red_Agencias">
                                    <p style={{ position: 'absolute', right: '200px', bottom: '410px', color: 'white', zIndex: '99999', fontSize: '13px', cursor: 'pointer' }}>
                                        <MDBIcon className="text-center" icon="map-marker-alt" size="2x" style={{ background: '#fff', padding: '4px 7px', borderRadius: '50%', color: '#e95c0c' }} /> Puntos de Atención
                                    </p>
                                </Link>
                                <Link to="/Preguntas_Frecuentes" style={{ position: 'absolute', right: '240px', bottom: '360px', color: 'white', zIndex: '99999', fontSize: '13px', cursor: 'pointer' }} >
                                    <MDBIcon className="text-center" icon="question" size="2x" style={{ background: '#fff', width: '35px', padding: '5px', borderRadius: '50%', color: '#e95c0c' }} /> Preguntas Frecuentes
                                </Link>
                                <p onClick={this.activarLlamanos} style={{ position: 'absolute', right: '360px', bottom: '265px', color: 'white', zIndex: '99999', fontSize: '13px', cursor: 'pointer' }}>
                                    <MDBIcon className="text-center" icon="phone-alt" size="2x" style={{ background: '#fff', padding: '6px', borderRadius: '50%', color: '#e95c0c' }} /> Llámenos
                                </p>
                                {/* <p onClick={this.activarChat} style={{ position: 'absolute', right: '300px', bottom: '190px', color: 'white', zIndex: '99999', fontSize: '13px', cursor: 'pointer' }}>
                                    <MDBIcon className="text-center" icon="comments" size="2x" style={{ background: '#fff', padding: '7px', borderRadius: '50%', color: '#e95c0c' }} /> Utrahuilca Chat
                                </p> */}
                                <Link to="/Asesoria" style={{ position: 'absolute', right: '260px', bottom: '190px', color: 'white', zIndex: '99999', fontSize: '13px', cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                                    <MDBIcon className="text-center" fab icon="wpforms" size="2x" style={{ background: '#fff', padding: '7px', borderRadius: '50%', color: '#e95c0c' }} />&nbsp; Formulario de Asesoría
                                </Link>
                            </div>
                        </React.Fragment>
                    ) : (
                        <React.Fragment></React.Fragment>
                    )}
                {/* {stateChat ? (
                    <Chat cerrarM={this.cerrarChat} />
                ) : (
                    <React.Fragment></React.Fragment>
                )} */}
                {/* {stateLlamamos ? (
                    <Llamamos cerrarM={this.cerrarChat} />
                ) : (
                    <React.Fragment></React.Fragment>
                )} */}
                {stateLlamanos ? (
                    <Llamanos cerrarM={this.cerrarChat} />
                ) : (
                    <React.Fragment></React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

export default MensajeF;