import { MDBIcon, MDBInput, MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import React, { Component } from 'react';
import FileUploader from './Agencias Detalles/FileUploader';
import FileUploaderAc from './Agencias Detalles/FileUploaderAc';
import Swal from 'sweetalert2';
import { AgenciaDetalles_, AgenciaDetallesPOST_, Agencias_, CargueImagenes } from '../../../services/WebAPI_Utrahuilca';
import { URLImgFront } from '../../../config/URLDB';

var horarios = [];
var telefonos = [];

const validate = values => {
    const errors = {}
    if (values.idredagencia === 'DEFAULT') {
        errors.idredagencia = 'campo obligatorio'
    }
    if (!values.direccion) {
        errors.direccion = 'campo obligatorio'
    }
    if (values.HorariosA.length === 0) {
        errors.dias = 'campo obligatorio';
        errors.horario = 'campo obligatorio';
    }
    if (values.telefonosA.length === 0) {
        errors.indicativo = 'campo obligatorio';
        errors.telefono = 'campo obligatorio';
        errors.extension = 'campo obligatorio';
        errors.whatsapp = 'campo obligatorio';
    }
    return errors;
};

class AgenciaDetalles extends Component {
    uploader = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            estadoNuevo: false,
            errors: {},
            infoAgencias: [],
            idredagencia: 'DEFAULT',
            indicativo: '',
            nrocontacto: '',
            extension: '',
            manejawhatsapp: 'DEFAULT',
            dias: '',
            horario: '',
            infoadicihorario: '',
            direccion: '',
            HorariosA: [],
            telefonosA: [],
            telefonoid: 0,
            estadoCambioImg1: true,
            estadoCambio: false,
            estadoSinResultados: true,
            infoAgenciasDetalles: [],
            stateImg: false,
            dataArray: [],
            estadoEditar: false,
            editidredagencia: 'DEFAULT',
        };
    }

    addcampos = (e) => {
        this.setState({
            form: {
                [e.target.name]: [e.target.value]
            }
        })
    }

    agregarHorarios = () => {
        horarios.push({ dias: this.state.dias, horario: this.state.horario })
        this.setState({
            HorariosA: horarios,
            dias: '',
            horario: ''
        })
    }

    agregarNumeros = () => {
        var whatsapp = "";
        if (this.state.manejawhatsapp === "1") {
            whatsapp = "SI";
        } else {
            whatsapp = "NO";
        }
        telefonos.push({ indicativo: this.state.indicativo, numero: this.state.nrocontacto, extension: this.state.extension, whatsApp: whatsapp })
        this.setState({
            telefonosA: telefonos,
            telefonoid: (this.state.telefonoid + 1),
            indicativo: '',
            nrocontacto: '',
            extension: '',
            manejawhatsapp: 'DEFAULT'
        })
    }

    componentDidMount() {
        this.cargarSelect();
        this.cargarInformacion();
    }

    cargarInformacion = () => {
        const data = {
            tran: 4,
            idredagendetalle: 0,
            idredagencia: 0,
            idmunicipio: 0,
            rutafotoagenc: 'NA',
            direccion: 'NA',
            infoadicihorario: 'NA',
            estado: 0,
            usucrea: 'NA'
        }
        AgenciaDetalles_(data).then(response => {
            try {
                if (response.data.length > 0) {
                    if (response.data.length >= 1) {
                        if (response.data[0].message === "004404") {
                            this.setState({
                                stateImg: false
                            })
                        } else {
                            this.setState({
                                infoAgenciasDetalles: response.data,
                                estadoSinResultados: false,
                                stateImg: true
                            });
                            this.loadTable();
                        }
                    } else {
                        this.setState({
                            stateImg: false
                        })
                    }
                } else {
                    console.log(response);
                }
            } catch (error) {
                console.log(error);
            }
        });
    }

    loadTable = () => {
        const dataJson = this.state.infoAgenciasDetalles;
        var jsonnuevo = [];
        for (var i = 0; i < dataJson.length; i++) {
            var horarios = [];
            var telefonos = [];
            for (var j = i; j < dataJson.length; j++) {
                horarios.push({ "dias": dataJson[j].diaslaboran, "horario": dataJson[j].horariolaboran });
                telefonos.push({ "indicativo": dataJson[j].indicativo, "numero": dataJson[j].nrocontacto, "extension": dataJson[j].extension, "whatsApp": dataJson[j].manejawhatsapp });
                if (j === (dataJson.length - 1)) {
                    j = dataJson.length;
                    i = j - 1;
                    break;
                }
                else {
                    if (dataJson[j].idredagendetalle !== dataJson[j + 1].idredagendetalle) {
                        i = j;
                        j = dataJson.length;
                        break;
                    }
                }
            }
            telefonos = new Set(telefonos.map(JSON.stringify));
            let newtelefonos = Array.from(telefonos).map(JSON.parse);
            horarios = new Set(horarios.map(JSON.stringify));
            let newhorarios = Array.from(horarios).map(JSON.parse);

            jsonnuevo.push(
                {
                    "idredagendetalle": dataJson[i].idredagendetalle,
                    "idredagencia": dataJson[i].idredagencia,
                    "nombreagenc": dataJson[i].nombreagenc,
                    "rutafotoagenc": dataJson[i].rutafotoagenc,
                    "infoadicihorario": dataJson[i].infoadicihorario,
                    "direccion": dataJson[i].direccion,
                    newhorarios,
                    newtelefonos,
                    "usucrea": dataJson[i].usucrea,
                    "feccrea": dataJson[i].feccrea,
                }
            );
            horarios = null;
            telefonos = null;
        }
        horarios = jsonnuevo[0].newhorarios;
        telefonos = jsonnuevo[0].newtelefonos;
        this.setState({
            dataArray: jsonnuevo,
            HorariosA: jsonnuevo[0].newhorarios,
            telefonosA: jsonnuevo[0].newtelefonos
        })
    }

    cargarSelect = () => {
        const data = {
            tran: 7,
            idredagencia: 0,
            idtipoagencia: 0,
            idmunicipio: 0,
            nombreagenc: 'NA',
            descagencia: 'NA',
            rutaiconomapa: 'NA',
            latitud: 'NA',
            longitud: 'NA',
            zoom: 0,
            estado: 1,
            usucrea: 'NA'
        }
        Agencias_(data).then(response => {
            try {
                if (response.data.length >= 1) {
                    this.setState({
                        infoAgencias: response.data,
                    })
                }
            } catch (error) {
                this.setState({
                    infoAgencias: [],
                })
                Swal.fire({
                    icon: 'error',
                    text: 'Ocurrio un error al consumir el servicio!'
                })
            }
        });
    }

    textInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    eliminarHorario = (data) => {
        var indice = horarios.indexOf(data);
        if (indice !== -1) {
            horarios.splice(indice, 1);
            this.setState({
                HorariosA: horarios
            })
        }
    }

    eliminarTel = (data) => {
        var indice = telefonos.indexOf(data);
        if (indice !== -1) {
            telefonos.splice(indice, 1);
            this.setState({
                telefonosA: telefonos
            })
        }
    }

    cambio = (name, file) => {
        this.setState({
            estadoCambioImg1: true
        });
    }

    cambioAct = (name, file) => {
        this.setState({
            estadoCambio: false
        });
    }

    cargarImagen = () => {
        var formData = new FormData();
        if (undefined === this.uploader.current) return;
        
        formData.append("file", this.uploader.current.getFileObject());
        formData.append("rutaImagen", '/Red de Agencias/Agencia Detalles/' + this.uploader.current.getFileName());
        CargueImagenes(formData).then(response => {
            if (response) {
                this.cargarInformacion();
                return;
            }
        });
    }

    insertarDatos = () => {
        const { errors, ...sinErrors } = this.state;
        const result = validate(sinErrors);
        this.setState({ errors: result });
        if (!Object.keys(result).length) {
            // formulario validado
            const data = {
                tran: 1,
                idredagendetalle: 0,
                idredagencia: parseInt(this.state.idredagencia),
                rutafotoagenc: '/Red de Agencias/Agencia Detalles/' + this.uploader.current.getFileName(),
                infoadicihorario: this.state.infoadicihorario,
                direccion: this.state.direccion,
                HorariosA: this.state.HorariosA,
                telefonosA: this.state.telefonosA,
                usucrea: sessionStorage.getItem('idusuario')
            };
            AgenciaDetallesPOST_(data).then(response => {
                try {
                    if (response.data.length > 0) {
                        if (response.data[0].message === "000000") {
                            this.cargarImagen();
                            Swal.fire({
                                icon: 'success',
                                title: 'Se agrego correctamente.'
                            })
                            this.setState({
                                estadoNuevo: false,
                                infoAgencias: [],
                                idredagencia: null,
                                indicativo: '',
                                nrocontacto: '',
                                extension: '',
                                manejawhatsapp: 'DEFAULT',
                                dias: '',
                                horario: '',
                                infoadicihorario: '',
                                direccion: '',
                                HorariosA: [],
                                telefonosA: [],
                                telefonoid: 0,
                            })
                            this.cargarInformacion();
                            this.cargarSelect();
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Error al insertar.'
                            })
                        }
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Ocurrio un error!'
                        })
                    }
                } catch (error) {
                    Swal.fire({
                        icon: 'error',
                        text: 'Ocurrio un error al consumir el servicio, comuniquese con sistemas!'
                    })
                }
            });
        }
    }

    editar = (id) => {
        var seleccionado = [];
        this.state.dataArray.map((e, i) => {
            if (e.idredagendetalle === id) {
                seleccionado.push(e)
            }
            return [];
        })
        horarios = seleccionado[0].newhorarios;
        telefonos = seleccionado[0].newtelefonos;
        this.setState({
            idredagendetalle: id,
            idredagencia: seleccionado[0].idredagencia,
            nombreagenc: seleccionado[0].nombreagenc,
            rutafotoagenc: seleccionado[0].rutafotoagenc,
            HorariosA: seleccionado[0].newhorarios,
            telefonosA: seleccionado[0].newtelefonos,
            editdireccion: seleccionado[0].direccion,
            editinfoadicihorario: seleccionado[0].infoadicihorario,
            estadoEditar: true
        })
    }

    ActualizarDatos = (id) => {
        var ruta = '';
        var capNombre = this.uploader.current.getFileName();
        if (capNombre === undefined || capNombre === null) {
            ruta = this.state.rutafotoagenc;
            this.setState({
                stateInsertImage: false
            })
        } else {
            ruta = '/Red de Agencias/Agencia Detalles/' + this.uploader.current.getFileName();
            this.setState({
                stateInsertImage: true
            })
        }
        var idredagencia = 0;
        if (this.state.editidredagencia === 'DEFAULT') {
            idredagencia = this.state.idredagencia;
        } else {
            idredagencia = this.state.editidredagencia;
        }
        const data = {
            tran: 1,
            idredagendetalle: id,
            idredagencia: idredagencia,
            rutafotoagenc: ruta,
            infoadicihorario: this.state.editinfoadicihorario,
            direccion: this.state.editdireccion,
            HorariosA: this.state.HorariosA,
            telefonosA: this.state.telefonosA,
            usucrea: sessionStorage.getItem('idusuario')
        };
        AgenciaDetallesPOST_(data).then(response => {
            try {
                if (response.data.length > 0) {
                    if (response.data[0].message === "000000") {
                        if (this.state.stateInsertImage) {
                            this.cargarImagen();
                        }
                        Swal.fire({
                            icon: 'success',
                            title: 'Se agrego correctamente.'
                        })
                        horarios = [];
                        telefonos = [];
                        this.setState({
                            estadoNuevo: false,
                            infoAgencias: [],
                            idredagencia: null,
                            indicativo: '',
                            nrocontacto: '',
                            extension: '',
                            manejawhatsapp: 'DEFAULT',
                            dias: '',
                            horario: '',
                            infoadicihorario: '',
                            direccion: '',
                            HorariosA: [],
                            telefonosA: [],
                            telefonoid: 0,
                            estadoEditar: false
                        })
                        this.cargarSelect();
                        this.cargarInformacion();
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error al insertar.'
                        })
                    }
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Ocurrio un error!'
                    })
                }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    text: 'Ocurrio un error al consumir el servicio, comuniquese con sistemas!'
                })
            }
        });
    }

    handleNew = () => {
        this.setState({
            estadoNuevo: !this.state.estadoNuevo,
            idredagencia: 'DEFAULT',
            indicativo: '',
            nrocontacto: '',
            extension: '',
            manejawhatsapp: 'DEFAULT',
            dias: '',
            horario: '',
            infoadicihorario: '',
            direccion: '',
            HorariosA: [],
            errors: {},
            telefonosA: [],
        })
        horarios = [];
        telefonos = [];
        if (this.state.estadoNuevo) {
            this.setState({
                idredagencia: 'DEFAULT',
                indicativo: '',
                nrocontacto: '',
                extension: '',
                manejawhatsapp: 'DEFAULT',
                dias: '',
                horario: '',
                infoadicihorario: '',
                direccion: '',
                HorariosA: [],
                errors: {},
                telefonosA: [],
            })
            horarios = [];
            telefonos = [];
            this.cargarInformacion();
        }
    }

    render() {
        const { estadoNuevo, infoAgencias, HorariosA, telefonosA, estadoEditar, estadoCambioImg1, estadoCambio, errors } = this.state;
        return (
            <React.Fragment>
                <p className="font-weight-bold">Agencias Detalles</p>
                <hr />
                <div className="plus_add_agencia align-items-center d-flex" onClick={() => { this.handleNew() }}>
                    {estadoNuevo ? <p>Cancelar &nbsp;<MDBIcon icon="times" size="lg" /></p> : <p>Crear Agencia Detalles &nbsp;<MDBIcon icon="plus-circle" size="lg" /></p>}
                </div>
                {estadoNuevo ? (
                    <React.Fragment>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 my-2 mt-3">
                                    <label>Selecciona la Agencia</label>
                                    <select name="idredagencia" className="form-control" value={this.state.idredagencia} onChange={this.textInput}>
                                        <option value="DEFAULT" disabled>Selecciona...</option>
                                        {infoAgencias.map((e, i) => {
                                            return [
                                                <option key={i} value={e.idredagencia}>{e.nombreagenc}</option>
                                            ]
                                        })}
                                    </select>
                                    {errors.idredagencia && <strong className="text-danger" style={{ fontSize: '13px' }}>{errors.idredagencia}</strong>}
                                </div>
                                <div className="col-md-6 my-2 text-center">
                                    <FileUploader ref={this.uploader} estado={estadoCambioImg1} ruta={this.state.rutafotoagenc} FCambiar={this.cambio} />
                                </div>
                                <div className="col-md-12 my-2">
                                    <label className="font-weight-bold">Horarios de Atención</label>
                                    <hr />
                                    <div className="row">
                                        <div className="col-md-6">
                                            <MDBInput label="Dias" name="dias" type="text" className="mr-5" value={this.state.dias} onChange={this.textInput} />
                                            {errors.dias && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '50px' }}>{errors.dias}</strong>}
                                        </div>
                                        <div className="col-md-6">
                                            <MDBInput label="Horario" name="horario" type="text" className="mr-5" value={this.state.horario} onChange={this.textInput} />
                                            {errors.horario && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '50px' }}>{errors.horario}</strong>}
                                        </div>
                                        <div onClick={() => { this.agregarHorarios() }} style={{ cursor: 'pointer', position: 'absolute', top: '100px', right: '-40px', zIndex: '999' }} >
                                            <MDBIcon icon="plus-circle" size="2x" />
                                        </div>
                                    </div>
                                    <ul style={{ listStyle: 'none' }}>
                                        {HorariosA.map((e, i) => {
                                            return [
                                                <li key={i} className="justify-content-center d-flex pt-3 border border-warning">
                                                    <p className="font-weight-bold">{e.dias} &nbsp;</p>
                                                    <p>{e.horario}</p>
                                                    <MDBIcon onClick={() => { this.eliminarHorario(e) }} icon="trash" className="text-danger" size="lg" style={{ position: 'absolute', right: '50px', marginTop: '5px', cursor: 'pointer' }} />
                                                </li>
                                            ]
                                        })}
                                    </ul>
                                </div>
                                <div className="col-md-6 my-2">
                                    <MDBInput label="Información Adicional" name="infoadicihorario" value={this.state.infoadicihorario} type="textarea" rows="5" onChange={this.textInput} />
                                </div>
                                <div className="col-md-6 my-2">
                                    <MDBInput label="Dirección" name="direccion" value={this.state.direccion} type="text" onChange={this.textInput} />
                                    {errors.direccion && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '50px' }}>{errors.direccion}</strong>}
                                </div>
                                <div className="col-md-12 my-2">
                                    <label className="font-weight-bold">Numero(s) de contacto</label>
                                    <hr />
                                    <div className="row">
                                        <div className="col-md-3">
                                            <MDBInput label="Indicativo" name="indicativo" type="text" className="mr-5" value={this.state.indicativo} onChange={this.textInput} />
                                            {errors.indicativo && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '50px' }}>{errors.indicativo}</strong>}
                                        </div>
                                        <div className="col-md-3">
                                            <MDBInput label="Telefono" name="nrocontacto" type="text" className="mr-5" value={this.state.nrocontacto} onChange={this.textInput} />
                                            {errors.telefono && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '50px' }}>{errors.telefono}</strong>}
                                        </div>
                                        <div className="col-md-3">
                                            <MDBInput label="Extension" name="extension" type="text" className="mr-5" value={this.state.extension} onChange={this.textInput} />
                                            {errors.extension && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '50px' }}>{errors.extension}</strong>}
                                        </div>
                                        <div className="col-md-3">
                                            <label>¿Maneja WhatsApp?</label>
                                            <select name="manejawhatsapp" className="form-control" value={this.state.manejawhatsapp} onChange={this.textInput}>
                                                <option value="DEFAULT" disabled>Seleccione...</option>
                                                <option value="1">SI</option>
                                                <option value="0">NO</option>
                                            </select>
                                            {errors.whatsapp && <strong className="text-danger" style={{ fontSize: '13px' }}>{errors.whatsapp}</strong>}
                                        </div>
                                        <div onClick={() => { this.agregarNumeros() }} style={{ cursor: 'pointer', position: 'absolute', top: '100px', right: '-40px', zIndex: '999' }} >
                                            <MDBIcon icon="plus-circle" size="2x" />
                                        </div>
                                    </div>
                                    <ul style={{ listStyle: 'none' }}>
                                        {telefonosA.map((e, i) => {
                                            return [
                                                <li key={i} className="justify-content-center d-flex pt-3 border border-warning">
                                                    <p><b className="font-weight-bold">Indicativo:</b> {e.indicativo}, <b className="font-weight-bold">Numero:</b> {e.numero}, <b className="font-weight-bold">Extension:</b> {e.extension}, <b className="font-weight-bold">Tiene WhatsApp:</b>  {e.whatsApp}</p>
                                                    <MDBIcon onClick={() => { this.eliminarTel(e) }} icon="trash" className="text-danger" size="lg" style={{ position: 'absolute', right: '50px', marginTop: '5px', cursor: 'pointer' }} />
                                                </li>
                                            ]
                                        })}
                                    </ul>
                                </div>
                                <div className="col-md-6 my-2 text-center">

                                </div>
                            </div>
                        </div>
                        <div className="pt-2 mt-5 modal-footer w-100">
                            <button type="button" className="btn btn-primary px-5" onClick={this.insertarDatos}>Crear Agencia Detalles &nbsp; <MDBIcon icon="save" /></button>
                        </div>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className="container-fluid">
                            <div className="table-responsive text-center">
                                <MDBTable bordered>
                                    <MDBTableHead>
                                        <tr className="font-weight-bold">
                                            <td>Agencia</td>
                                            <td>Foto Agencia</td>
                                            <td>Horario de Atención</td>
                                            <td>Información Adicional</td>
                                            <td>Dirección</td>
                                            <td>Teléfonos</td>
                                            <td>Editar</td>
                                            {estadoEditar ? (
                                                <td>Cancelar</td>
                                            ) : (
                                                <React.Fragment></React.Fragment>
                                            )}
                                        </tr>
                                    </MDBTableHead>
                                    <MDBTableBody>
                                        {this.state.estadoSinResultados ? (
                                            <React.Fragment>
                                                <tr>
                                                    <td>
                                                        <div className="alert alert-warning">Sin resultados...</div>
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment>
                                                {estadoEditar ?
                                                    (
                                                        <React.Fragment>
                                                            <tr>
                                                                <p>{this.state.idredagencia}</p>
                                                            </tr>
                                                            <tr>
                                                                <td className="w-15">
                                                                    <label>Cambiar de agencia?</label>
                                                                    <select name="editidredagencia" className="form-control" defaultValue={'DEFAULT'} onChange={this.textInput}>
                                                                        <option value="DEFAULT" disabled>Selecciona...</option>
                                                                        {infoAgencias.map((e, i) => {
                                                                            return [
                                                                                <option key={i} value={e.idredagencia}>{e.nombreagenc}</option>
                                                                            ]
                                                                        })}
                                                                    </select>
                                                                </td>
                                                                <td>
                                                                    <FileUploaderAc ref={this.uploader} estadoImg={estadoCambio} ruta={this.state.rutafotoagenc} FCambiar={this.cambioAct} />
                                                                </td>
                                                                <td className="w-50">
                                                                    <tr>
                                                                        <td>
                                                                            <MDBInput label="Dias" name="dias" type="text" className="mr-5" value={this.state.dias} onChange={this.textInput} />
                                                                        </td>
                                                                        <td>
                                                                            <MDBInput label="Horario" name="horario" type="text" className="mr-5" value={this.state.horario} onChange={this.textInput} />
                                                                        </td>
                                                                        <td>
                                                                            <div onClick={() => { this.agregarHorarios() }} style={{ cursor: 'pointer' }} >
                                                                                <MDBIcon icon="plus-circle" size="2x" />
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    {this.state.HorariosA.map((y, i) => {
                                                                        return [
                                                                            <tr key={i}>
                                                                                <td>
                                                                                    <p>{y.dias}</p>
                                                                                </td>
                                                                                <td>
                                                                                    <p>{y.horario}</p>
                                                                                </td>
                                                                                <td><MDBIcon onClick={() => { this.eliminarHorario(y) }} icon="trash" className="text-danger" size="lg" style={{ cursor: 'pointer' }} /></td>
                                                                            </tr>
                                                                        ]
                                                                    })}
                                                                </td>
                                                                <td className="w-25"><textarea className="form-control w-100" type="textarea" rows="10" name="editinfoadicihorario" value={this.state.editinfoadicihorario} onChange={this.textInput} /></td>
                                                                <td className="w-25"><textarea className="form-control" type="textarea" rows="10" name="editdireccion" value={this.state.editdireccion} onChange={this.textInput} /></td>
                                                                <td className="w-50">
                                                                    <tr>
                                                                        <td>
                                                                            <MDBInput label="Indicativo" name="indicativo" type="text" className="mr-5" value={this.state.indicativo} onChange={this.textInput} />
                                                                        </td>
                                                                        <td>
                                                                            <MDBInput label="Telefono" name="nrocontacto" type="text" className="mr-5" value={this.state.nrocontacto} onChange={this.textInput} />
                                                                        </td>
                                                                        <td>
                                                                            <MDBInput label="Extension" name="extension" type="text" className="mr-5" value={this.state.extension} onChange={this.textInput} />
                                                                        </td>
                                                                        <td>
                                                                            <div onClick={() => { this.agregarNumeros() }} style={{ cursor: 'pointer' }} >
                                                                                <MDBIcon icon="plus-circle" size="2x" />
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    {this.state.telefonosA.map((w, i) =>
                                                                        <tr key={i}>
                                                                            <td>
                                                                                <p>Indicativo</p>{w.indicativo}
                                                                            </td>
                                                                            <td>
                                                                                <p>Telefono</p>{w.numero}
                                                                            </td>
                                                                            <td>
                                                                                <p>Extensión</p>{w.extension}
                                                                            </td>
                                                                            <td><MDBIcon onClick={() => { this.eliminarTel(w) }} icon="trash" className="text-danger" size="lg" style={{ cursor: 'pointer' }} /></td>
                                                                        </tr>
                                                                    )}
                                                                </td>
                                                                <td className="text-center" onClick={() => { this.ActualizarDatos(this.state.idredagendetalle) }}><MDBIcon icon="save" size="2x" style={{ color: 'blue', cursor: 'pointer' }} /></td>
                                                                <td className="text-center" onClick={() => { this.setState({ estadoEditar: !this.state.estadoEditar }) }}><MDBIcon icon="times" size="2x" style={{ color: 'red', cursor: 'pointer' }} /></td>
                                                            </tr>
                                                        </React.Fragment>
                                                    ) : (
                                                        <React.Fragment>
                                                            {this.state.dataArray.map((e, i) => {
                                                                return (
                                                                    <React.Fragment key={i}>
                                                                        <tr>
                                                                            <td>{e.nombreagenc}</td>
                                                                            {this.state.stateImg ? (
                                                                                <td><img width="120" src={`${URLImgFront}/assets${e.rutafotoagenc}`} alt={e.rutafotoagenc} /></td>
                                                                            ) : (
                                                                                <React.Fragment></React.Fragment>
                                                                            )}
                                                                            <td className="text-justify">
                                                                                {e.newhorarios.map((y) => <tr>{y.dias + ' ' + y.horario}<br /></tr>)}
                                                                            </td>
                                                                            <td className="text-justify">{e.infoadicihorario}</td>
                                                                            <td>{e.direccion}</td>
                                                                            <td className="text-justify">
                                                                                {e.newtelefonos.map((w) => <tr>{w.indicativo + '' + w.numero + '' + w.extension}<br /></tr>)}
                                                                            </td>
                                                                            <td><MDBIcon onClick={() => { this.editar(e.idredagendetalle) }} icon="edit" size="2x" style={{ color: 'green', cursor: 'pointer' }} /></td>
                                                                        </tr>
                                                                    </React.Fragment>
                                                                );
                                                            })}
                                                        </React.Fragment>
                                                    )}
                                            </React.Fragment>
                                        )}
                                    </MDBTableBody>
                                </MDBTable>
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

export default AgenciaDetalles;