import React, { Component } from 'react';
import Swal from 'sweetalert2';
import { URLImgFront } from '../../config/URLDB';
import { PreguntasFrecuentesLista_ } from '../../services/WebAPI_Utrahuilca';

var PosicionArriba = [];
class Seccion2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            PosicionArribaA: []
        };
    }

    async componentDidMount() {
        const data = {
            tran: 7,
            idpregfrecuente: 0,
            rutaiconpreg: "NA",
            prioridad: 0,
            titulopreg: "NA",
            descpregfrec1: "NA",
            descpregfrec2: "NA",
            descpregfrec3: "NA",
            descpregfrec4: "NA",
            estado: 1,
        };
        PreguntasFrecuentesLista_(data).then((response) => {
            try {
                if (response.data.length > 0) {
                    response.data.map((e) => {
                        if (e.prioridad === 0) {
                            PosicionArriba.push(e);
                        }
                        return [];
                    })
                    this.setState({
                        PosicionArribaA: PosicionArriba
                    });
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Ocurrio un error!",
                    });
                }
            } catch (error) {
                Swal.fire({
                    icon: "error",
                    text:
                        "Ocurrio un error al consumir el servicio!",
                });
            }
        });
    }

    componentWillUnmount = () => {
        PosicionArriba = [];
        this.setState({
            PosicionArribaA: []
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className="diseño_seccion2_pg" style={{ width: '100%' }}>
                    <div className="container py-5">
                        <div className="row px-5">
                            {this.state.PosicionArribaA.map((e, i) => {
                                return [
                                    <React.Fragment key={i}>
                                        <div className="col-md-12 my-2">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="row tarjeta">
                                                        <div className="col-md-3 px-0 ml-0">
                                                            <img width="110" className="img-fluid" src={`${URLImgFront}/assets${e.rutaiconpreg}`} alt={e.rutaiconpreg} />
                                                        </div>
                                                        <div className="col-md-9 px-0 ml-0 py-3" style={{ color: '#4f4e4e' }}>
                                                            <p className="font-weight-bold" style={{ fontSize: '20px' }}>{e.titulopreg}</p>
                                                            <p style={{ fontSize: '17px' }}>{e.descpregfrec1}</p>
                                                            <p className="mt-5 mb-2" style={{ color: '#36ada7' }} >{e.descpregfrec2} </p>
                                                            <p className="my-0 text-dark">{e.descpregfrec3}</p>
                                                            <p>{e.descpregfrec4}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                ]
                            })}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Seccion2;