import React, { Component } from 'react';
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.min.css';
import { Link } from 'react-router-dom';
import { SliderPrincipal_ } from '../../services/WebAPI_Utrahuilca';
import { URLImgFront } from '../../config/URLDB';

class SwiperIMG extends Component {
    constructor(props) {
        super(props);
        this.state = {
            infoSliderPrincipal: [],
            estadoImg: false
        };
    }
    componentDidMount() {
        const data = {
            tran: 4,
            id: 0,
            nombreImagen: 'NA',
            rutaModal: 'NA',
            texto1: 'NA',
            texto2: 'NA',
            texto3: 'NA',
            estado: 0,
            idtipoenlace: 1,
            rutatipoenlace: 'NA',
            posiciontexto: 1,
            colortexto: "NA",
            usucrea: "NA"
        }
        SliderPrincipal_(data).then(response => {
            if (response.data === undefined || response.data === null) {
                return;
            } else {
                if (response.data.length > 0) {
                    setTimeout(() => {
                        this.setState({
                            infoSliderPrincipal: response.data,
                            estadoImg: true
                        })
                    }, 50);
                }
            }
        });
    }

    render() {
        const { estadoImg, infoSliderPrincipal } = this.state;
        const params = {
            spaceBetween: 30,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            autoplay: {
                delay: 12000,
                disableOnInteraction: false,
            }
        }
        return (
            <React.Fragment>
                <div className="design-responsive-swiper-principal" style={{ paddingTop: '120px' }}>
                    {estadoImg ? (
                        <Swiper {...params} className="rounded owl-item">
                            {infoSliderPrincipal.map((e, i) => {
                                if (e.estado === 1) {
                                    var left = "";
                                    var right = "";
                                    if (e.posiciontexto === 1) {
                                        left = "10%";
                                    } else if (e.posiciontexto === 0) {
                                        right = "10%";
                                    }
                                    if (e.idtipoenlace === 0) {
                                        return [
                                            <Link to={`Campaña/${e.idsliderprinc}`} key={i}>
                                                <div className="img-swiper-principal" style={{ backgroundImage: `url("${URLImgFront}/assets${e.rutafondoslider}")`, height: '600px', width: '100%', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', objectFit: 'cover' }}></div>
                                                {/* <img className="img-swiper" style={{ cursor: 'pointer', height: '650px', width: '1920px', objectFit: 'cover' }} src={`${URLImgFront}/assets${e.rutafondoslider}`} alt={e.rutafondoslider} /> */}
                                                <div className="diseño_texto_principal_responsive" style={{ position: 'absolute', right: right, left: left, top: '195px' }}>
                                                    <p className="text-center uno my-0" style={{ fontSize: '35px', color: e.colortexto }}>{e.txtslider1 !== "N/A" ? e.txtslider1 : ' '}</p>
                                                    <p className="text-center dos my-0" style={{ fontSize: '45px', fontWeight: 'bold', color: e.colortexto }}>{e.txtslider2 !== "N/A" ? e.txtslider2 : ' '}</p>
                                                    <p className="text-center uno my-0" style={{ fontSize: '30px', color: e.colortexto }}>{e.txtslider3 !== "N/A" ? e.txtslider3 : ' '}</p>
                                                </div>
                                            </Link>
                                        ]
                                    } else if (e.idtipoenlace === 2) {
                                        return [
                                            <Link to={`/Documentos/${e.rutatipoenlace}`} target='_blank' key={i} rel="noopener noreferrer">
                                                <div className="img-swiper-principal" style={{ backgroundImage: `url("${URLImgFront}/assets${e.rutafondoslider}")`, height: '600px', width: '100%', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', objectFit: 'cover' }}></div>
                                                {/* <img className="img-swiper" style={{ cursor: 'pointer', height: '650px', width: '1920px', objectFit: 'cover' }} src={`${URLImgFront}/assets${e.rutafondoslider}`} alt={e.rutafondoslider} /> */}
                                                <div className="diseño_texto_principal_responsive" style={{ position: 'absolute', right: right, left: left, top: '195px' }}>
                                                    <p className="text-center uno my-0" style={{ fontSize: '35px', color: e.colortexto }}>{e.txtslider1 !== "N/A" ? e.txtslider1 : ' '}</p>
                                                    <p className="text-center dos my-0" style={{ fontSize: '45px', fontWeight: 'bold', color: e.colortexto }}>{e.txtslider2 !== "N/A" ? e.txtslider2 : ' '}</p>
                                                    <p className="text-center uno my-0" style={{ fontSize: '30px', color: e.colortexto }}>{e.txtslider3 !== "N/A" ? e.txtslider3 : ' '}</p>
                                                </div>
                                            </Link>
                                        ]
                                    } else if (e.idtipoenlace === 1 || e.idtipoenlace === 3) {
                                        if (e.rutatipoenlace === "N/A") {
                                            return [
                                                <div key={i}>
                                                    <div className="img-swiper-principal" style={{ backgroundImage: `url("${URLImgFront}/assets${e.rutafondoslider}")`, height: '600px', width: '100%', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', objectFit: 'cover' }}></div>
                                                    {/* <img className="img-swiper" style={{ height: '650px', width: '1920px', objectFit: 'cover' }} src={`${URLImgFront}/assets${e.rutafondoslider}`} alt={e.rutafondoslider} /> */}
                                                    <div className="diseño_texto_principal_responsive" style={{ position: 'absolute', right: right, left: left, top: '195px' }}>
                                                        <p className="text-center uno my-0" style={{ fontSize: '35px', color: e.colortexto }}>{e.txtslider1 !== "N/A" ? e.txtslider1 : ' '}</p>
                                                        <p className="text-center dos my-0" style={{ fontSize: '45px', fontWeight: 'bold', color: e.colortexto }}>{e.txtslider2 !== "N/A" ? e.txtslider2 : ' '}</p>
                                                        <p className="text-center uno my-0" style={{ fontSize: '30px', color: e.colortexto }}>{e.txtslider3 !== "N/A" ? e.txtslider3 : ' '}</p>
                                                    </div>
                                                </div>
                                            ]
                                        } else {
                                            return [
                                                <a href={e.rutatipoenlace} key={i} target={'_blank'} rel="noopener noreferrer">
                                                    <div className="img-swiper-principal" style={{ backgroundImage: `url("${URLImgFront}/assets${e.rutafondoslider}")`, height: '600px', width: '100%', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', objectFit: 'cover' }}></div>
                                                    <div className="diseño_texto_principal_responsive" style={{ position: 'absolute', right: right, left: left, top: '195px' }}>
                                                        <p className="text-center uno my-0" style={{ fontSize: '35px', color: e.colortexto }}>{e.txtslider1 !== "N/A" ? e.txtslider1 : ' '}</p>
                                                        <p className="text-center dos my-0" style={{ fontSize: '45px', fontWeight: 'bold', color: e.colortexto }}>{e.txtslider2 !== "N/A" ? e.txtslider2 : ' '}</p>
                                                        <p className="text-center uno my-0" style={{ fontSize: '30px', color: e.colortexto }}>{e.txtslider3 !== "N/A" ? e.txtslider3 : ' '}</p>
                                                    </div>
                                                </a>
                                            ]
                                        }
                                    } else {
                                        return [];
                                    }
                                } else {
                                    return [];
                                }
                            })}
                        </Swiper>
                    ) : (
                        <React.Fragment></React.Fragment>
                    )}
                </div>
            </React.Fragment>
        );
    }
}

export default SwiperIMG;