import React, { Component } from 'react';

class Seccion2 extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    render() {
        return (
            <React.Fragment>
                <div className="bg-white" style={{ width: '100%' }}>
                    <div className="container text-center py-5">
                        <p className="texto-contenido font-weight-bold">¿QUÉ ES UN CRÉDITO DE LIBRANZA ?</p>
                        <p className="pt-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                        <div className="row py-5 px-5">
                            <div className="col-md-12 pb-3">
                                <p className="texto-contenido font-weight-bold">Obtener tu libranza</p>
                            </div>
                            <div className="col-md-4 pb-5 pt-4" style={{ background: '#f7f7f7', color: '#000' }}>
                                <p className="texto-contenido font-weight-bold pt-5">1</p>
                                <p className="pt-4">Lorem ipsum dolor sit amet, <b className="font-weight-bold">consectetur adipiscing</b> elit.</p>
                            </div>
                            <div className="col-md-4 pb-5 pt-4" style={{ background: '#3f8592', color: '#fff' }}>
                                <p className="texto-contenido font-weight-bold pt-5">2</p>
                                <p className="pt-4">Lorem ipsum <b className="font-weight-bold">dolorsit amet</b>, consectetur adipiscing elit, sed, kevs soul.</p>
                            </div>
                            <div className="col-md-4 pb-5 pt-4" style={{ background: '#f7f7f7', color: '#000' }}>
                                <p className="texto-contenido font-weight-bold pt-5">3</p>
                                <p className="pt-4"><b className="font-weight-bold">Lorem ipsum dolor</b> sit amet, consectetur adipiscing elit, sed tusaft nuwhb dg.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Seccion2;