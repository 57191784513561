import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import { URLImgFront } from '../../config/URLDB';
import './style.css';

class Tarjetas_Inferior extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false
        };
    }

    render() {
        return (
            <React.Fragment>
                <div className="design-responsive-lowerMenu">
                    <div className="container-fluid tarjetas_inferior_home">
                        <div className="row" style={{marginLeft: '-15px', marginRight: '-15px !important'}}>
                            <Link to="/CDAT" className="card border">
                                <div className="card-body text-center">
                                    <div className="text-center text-dark">
                                        <img width="80" className="img-fluid icono-tarjeta" src={`${URLImgFront}/assets/iconos/invierte.svg`}  alt="resuelve-img" />
                                        <p className="pt-3 texto-inferior-tarjeta">Invierta su dinero</p>
                                    </div>
                                </div>
                            </Link>
                            <Link to="/Productos/1/libre_inversión" className="card border">
                                <div className="card-body">
                                    <div className="text-center text-dark">
                                        <img width="80" className="img-fluid icono-tarjeta" src={`${URLImgFront}/assets/iconos/resuelve_dudas.svg`} alt="resuelve_dudas-img" />
                                        <p className="pt-3 texto-inferior-tarjeta">Solicite su crédito</p>
                                    </div>
                                </div>
                            </Link>
                            <Link to="/Productos/9/utramás" className="card border">
                                <div className="card-body">
                                    <div className="text-center text-dark">
                                        <img width="80" className="img-fluid icono-tarjeta" src={`${URLImgFront}/assets/iconos/ahorra.svg`} alt="ahorra-img" />
                                        <p className="pt-3 texto-inferior-tarjeta">Ahorre y cumpla sus metas</p>
                                    </div>
                                </div>
                            </Link>
                            <Link to="/Productos/11/utravivienda" className="card border">
                                <div className="card-body">
                                    <div className="text-center text-dark">
                                        <img width="80" className="img-fluid icono-tarjeta" src={`${URLImgFront}/assets/iconos/casa.svg`} alt="casa-img" />
                                        <p className="pt-3 texto-inferior-tarjeta">La casa de sus sueños</p>
                                    </div>
                                </div>
                            </Link>
                            <Link to="/PSE" className="card border">
                                <div className="card-body">
                                    <div className="text-center text-dark">
                                        <img width="80" className="img-fluid icono-tarjeta" src={`${URLImgFront}/assets/img-variadas/pagosenlinea.png`} alt="pagos_linea-img" />
                                        <p className="pt-3 texto-inferior-tarjeta">Pagos en linea por PSE</p>
                                    </div>
                                </div>
                            </Link>
                            <Link to="/Canales_Atencion" className="card border">
                                <div className="card-body">
                                    <div className="text-center text-dark">
                                        <img width="80" className="img-fluid icono-tarjeta" src={`${URLImgFront}/assets/iconos/canales-call.svg`} alt="invierte-img" />
                                        <p className="pt-3 texto-inferior-tarjeta">Canales de atención</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Tarjetas_Inferior;