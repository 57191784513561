import { MDBIcon } from 'mdbreact';
import React, { Component } from 'react';
import { URLImgFront } from '../../config/URLDB';
import { ConvenioDetalles_ } from '../../services/WebAPI_Utrahuilca';
import { withRouter } from 'react-router-dom';

class Seccion2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            conveniosdeta: [],
            idtipopers: 0,
            namePers: '',
            estadodetalleconv: false,
            titconvenio1: '',
            descconvenio1: '',
            descinfoconvenio: ''
        };
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate() {
        if (this.props.name !== this.state.namePers) {
            this.loadData();
        } else {

        }
    }

    loadData = () => {
        var nameP = this.props.name;
        if (nameP === "Personas" || nameP === "Empresas") {
            const data = {
                tran: 8,
                idconveniodet: 0,
                idtipopers: nameP === 'Personas' ? 1 : 0,
                rutaimgconven: 'NA',
                titconvenio1: 'NA',
                descconvenio1: 'NA',
                titconvenio2: 'NA',
                descconvenio2: 'NA',
                rutaarchiconven: 'NA',
                estado: 1,
                usucrea: 'NA',
                tiporegistro: 0,
                descinfoconvenio: 'NA'
            }
            ConvenioDetalles_(data).then(response => {
                try {
                    if (response.data.length > 0) {
                        if (response.data.length >= 1) {
                            if (response.data[0].message === "004404") {
                                this.setState({
                                    stateImg: false,
                                    namePers: 'Personas',
                                    conveniosdeta: []
                                })
                            } else {
                                this.setState({
                                    conveniosdeta: response.data,
                                    idtipopers: response.data[0].idtipopers,
                                    stateImg: true,
                                    namePers: this.props.name
                                });
                            }
                        } else { }
                    } else {
                        console.log(response);
                    }
                } catch (error) {
                    console.log(error);
                }
            });
        } else {
            this.props.history.push('/404')
        }
    }

    cargarDetalleConven = (data) => {
        this.setState({
            estadodetalleconv: true,
            titconvenio1: data.titconvenio1,
            descconvenio1: data.descconvenio1,
            descinfoconvenio: data.descinfoconvenio
        })
    }

    render() {
        return (
            <React.Fragment>
                <div className="diseño_seccion2_convenios" style={{ width: '100%' }}>
                    {this.state.estadodetalleconv ?
                        (
                            <React.Fragment>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-12 my-0">
                                            <h5 className="font-weight-bold" onClick={() => { this.setState({ estadodetalleconv: false }) }} style={{ cursor: 'pointer' }}><MDBIcon icon="angle-left" style={{ color: '#e95c0c' }} /> Volver</h5>
                                            <hr />
                                        </div>
                                        <div className="col-md-12 my-0 pb-5" style={{ background: '#fff', color: '#000' }}>
                                            <p className="pl-3 pt-3 my-0" style={{ fontSize: '13px', color: 'gray' }}>Convenio</p>
                                            <p className="pl-3 font-weight-bold" style={{ fontSize: '25px' }}>{this.state.titconvenio1}</p>
                                            <p className="pl-3 font-weight-bold pt-3" style={{ fontSize: '15px' }}>{this.state.descconvenio1}</p>
                                            <div className="py-5 px-5 oferta_contenido" style={{ whiteSpace: 'pre-line', fontSize: '17px', color: 'black', border: '1px solid #000' }}>
                                                <p>{this.state.descinfoconvenio}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                {this.state.conveniosdeta.map((e, i) => {
                                    return [
                                        <React.Fragment key={i}>
                                            <div className="container-fluid secciones mt-3" style={{ background: '#f7f7f7' }}>
                                                <div className="row px-0 py-0">
                                                    <div className="col-md-5 px-0 py-0">
                                                        <img style={{ width: '100%' }} src={`${URLImgFront}/assets${e.rutaimgconven}`} alt={e.rutaimgconven} />
                                                    </div>
                                                    <div className="col-md-7 contenido pl-5 d-flex align-items-center">
                                                        <div className="pl-5 convenios">
                                                            <p className="font-weight-bold pl-5 convenios-titulo" style={{ fontSize: '27px' }}>{e.titconvenio1}</p>
                                                            <p className="w-75 pl-5 convenios-descripcion" style={{ fontSize: '25px' }}>{e.descconvenio1}</p>
                                                            <p className="font-weight-bold pl-5 convenios-titulo" style={{ fontSize: '27px' }}>{e.titconvenio2}</p>
                                                            <p className="pl-5 convenios-descripcion2" style={{ fontSize: '18px' }}>{e.descconvenio2}</p>
                                                            {e.tiporegistro == "0" || e.tiporegistro === 'DEFAULT' ?
                                                                (
                                                                    <a href={`${URLImgFront}/assets/Archivos/${e.rutaarchiconven}`} download={`${e.rutaarchiconven}`} target={'_blank'} rel="noopener noreferrer">
                                                                        <span className="mt-5 font-weight-bold mx-0" style={{ color: '#95bac1', fontSize: '17px', position: 'absolute', right: '200px', bottom: '30px' }}>Conozca más <MDBIcon icon="arrow-right" size="lg" className="position-absolute" style={{ right: '-30px', bottom: '5px', color: '#ed6b4e' }} /></span>
                                                                    </a>
                                                                ): (
                                                                    <p className="mt-5 font-weight-bold mx-0" onClick={() => { this.cargarDetalleConven(e) }} style={{ color: '#95bac1', fontSize: '17px', position: 'absolute', right: '200px', bottom: '30px', cursor: 'pointer' }}>Conozca más <MDBIcon icon="arrow-right" size="lg" className="position-absolute" style={{ right: '-30px', bottom: '5px', color: '#ed6b4e' }} /></p>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    ]
                                })}
                            </React.Fragment>
                        )
                    }
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(Seccion2);