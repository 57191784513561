import React, { Component } from 'react';
import { URLImgFront } from '../../config/URLDB';
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.min.css';
import { MDBIcon } from 'mdbreact';
import Iframes from '../Iframes/Iframes';

class Seccion2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stateDate: 1
        };
    }

    cambiarFecha = (id) => {
        this.setState({
            stateDate: id
        })
    }

    render() {
        const params = {
            slidesPerView: 5,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false,
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
        }
        return (
            <React.Fragment>
                <div className="seccion2_conozcanos" style={{ width: '100%' }}>
                    <div className="container py-5 text-dark">
                        <p className="font-weight-bold text-center" style={{ fontSize: '30px', color: '#747373' }}>Valores</p>
                        <ul className="swiper_conozcanos">
                            <Swiper {...params} className="rounded swip pb-5">
                                <li className="text-center">
                                    <img width="80" className="img-fluid pb-2" src={`${URLImgFront}/assets/Conozcanos/iconos valores/HONRADEZ.png`} alt="img-honradez" />
                                    <p className="font-weight-bold">Honradez</p>
                                </li>
                                <li className="text-center">
                                    <img width="80" className="img-fluid pb-2" src={`${URLImgFront}/assets/Conozcanos/iconos valores/RESPETO.png`} alt="img-respeto" />
                                    <p className="font-weight-bold">Respeto</p>
                                </li>
                                <li className="text-center">
                                    <img width="80" className="img-fluid pb-2" src={`${URLImgFront}/assets/Conozcanos/iconos valores/AMABILIDAD.png`} alt="img-amabilidad" />
                                    <p className="font-weight-bold">Amabilidad</p>
                                </li>
                                <li className="text-center">
                                    <img width="80" className="img-fluid pb-2" src={`${URLImgFront}/assets/Conozcanos/iconos valores/ETICA.png`} alt="img-etica" />
                                    <p className="font-weight-bold">Ética</p>
                                </li>
                                <li className="text-center">
                                    <img width="80" className="img-fluid pb-2" src={`${URLImgFront}/assets/Conozcanos/iconos valores/AUTODISCIPLINA.png`} alt="img-AUTODISCIPLINA" />
                                    <p className="font-weight-bold">Autodisciplina</p>
                                </li>
                                <li className="text-center">
                                    <img width="80" className="img-fluid pb-2" src={`${URLImgFront}/assets/Conozcanos/iconos valores/LEALTAD.png`} alt="img-LEALTAD" />
                                    <p className="font-weight-bold">Lealtad</p>
                                </li>
                                <li className="text-center">
                                    <img width="80" className="img-fluid pb-2" src={`${URLImgFront}/assets/Conozcanos/iconos valores/COOPERACIÓN.png`} alt="img-COOPERACIÓN" />
                                    <p className="font-weight-bold">Cooperación</p>
                                </li>
                                <li className="text-center">
                                    <img width="80" className="img-fluid pb-2" src={`${URLImgFront}/assets/Conozcanos/iconos valores/COHERENCIA.png`} alt="img-COHERENCIA" />
                                    <p className="font-weight-bold">Coherencia</p>
                                </li>
                                <li className="text-center">
                                    <img width="80" className="img-fluid pb-2" src={`${URLImgFront}/assets/Conozcanos/iconos valores/CONSTRUCCIÓN AUTOCRÍTICA.png`} alt="img-CONSTRUCCIÓN AUTOCRÍTICA" />
                                    <p className="font-weight-bold">Construcción Autocritica</p>
                                </li>
                                <li className="text-center">
                                    <img width="80" className="img-fluid pb-2" src={`${URLImgFront}/assets/Conozcanos/iconos valores/AMOR.png`} alt="img-AMOR" />
                                    <p className="font-weight-bold">Amor</p>
                                </li>
                            </Swiper>
                        </ul>
                    </div>

                    <div style={{ background: '#f7f7f7' }}>
                        <div className="container py-4 text-center">
                            <p className="font-weight-bold text-center pb-4" style={{ fontSize: '30px', color: '#747373' }}>Himno Utrahuilca</p>
                            <Iframes ancho={'800px'} alto={'400px'} url={'ocXlB99n4Qs'} />{/* GWJuN3J5yNw - 2023/11/25, SE CAMBIA URL DEL VIDEO */}
                        </div>
                    </div>

                    <div className="container-fluid contenido_text_card">
                        <div className="row">
                            <div className="col-md-6 img-somos pl-0 pr-0" style={{ background: '#fff' }}>
                                <img style={{ width: '100%' }} src={`${URLImgFront}/assets/img-variadas/somos.png`} alt="img-somos" />
                            </div>
                            <div className="col-md-6 pt-5 text-dark" style={{ background: '#fff' }}>
                                <p className="font-weight-bold pt-5" style={{ fontSize: '35px' }}>SOMOS</p>
                                <p className="pt-2 text-justify" style={{ fontSize: '25px' }}>La COOPERATIVA LATINOAMERICANA DE AHORRO Y CRÉDITO UTRAHUILCA es una empresa asociativa sin ánimo de lucro creada como organización jurídica de derecho privado y responsabilidad limitada, de patrimonio variable e ilimitado y conformado por asociados. Esta es una empresa cooperativa especializada en ahorro y crédito, enmarcada en la filosofía y doctrina de la economía solidaria, practicante de los principios y valores cooperativos; siendo autogestionarios y generando unos resultados económicos y sociales equilibrados.</p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 text-mision text-dark" style={{ background: '#f7f7f7' }}>
                                <p className="font-weight-bold pt-3" style={{ fontSize: '35px' }}>MISIÓN</p>
                                <p className="text-justify" style={{ fontSize: '25px' }}>Contribuimos cooperativamente al desarrollo integral de la base social, sus familias y la comunidad.</p>
                            </div>
                            <div className="col-md-6 img-mision pl-0 pr-0" style={{ background: '#f7f7f7' }}>
                                <img style={{ width: '100%' }} src={`${URLImgFront}/assets/img-variadas/mision.png`} alt="img-mision" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 img-vision pl-0 pr-0" style={{ background: '#f7f7f7' }}>
                                <img style={{ width: '100%' }} src={`${URLImgFront}/assets/img-variadas/vision.png`} alt="img-vision" />
                            </div>
                            <div className="col-md-6 text-dark" style={{ background: '#f7f7f7' }}>
                                <p className="font-weight-bold pt-5 text-right" style={{ fontSize: '35px' }}>VISIÓN</p>
                                <p className="text-justify" style={{ fontSize: '25px' }}>En el 2026, UTRAHUILCA se consolidará en el modelo cooperativo de las regiones del sur de Colombia, con la práctica integrada, eficiente y efectiva del trabajo económico y la acción social para el desarrollo de los territorios; con productos, servicios y procesos de impacto en la comunidad y en su base social.</p>
                            </div>
                        </div>
                    </div>

                    <div className="container principios">
                        <p className="font-weight-bold text-center py-4 " style={{ fontSize: '30px', color: '#747373' }}>Principios</p>
                        <div className="row">
                            <div className="col-md-4 my-3">
                                <div className="icons-item">
                                    <MDBIcon icon="industry" size="2x" />
                                </div>
                                <div className="w-75 pl-2">
                                    <p className="font-weight-bold">Modelo socioeconómico y político alternativo</p>
                                    <p className="text-justify">Se trabajará continuamente para fortalecer a UTRAHUILCA como modelo empresarial; que incida en la transformación social y económica.</p>
                                </div>
                            </div>
                            <div className="col-md-4 my-3">
                                <div className="icons-item">
                                    <MDBIcon icon="chart-area" size="2x" />
                                </div>
                                <div className="w-75 pl-2">
                                    <p className="font-weight-bold">Generación Eficiente y Equilibrada entre Beneficios Solidarios y Económicos</p>
                                    <p className="text-justify">Nuestro compromiso es entregar a la comunidad beneficios económicos y financieros, pero sobre todo sociales, que permitan mejorar el nivel de vida de nuestros asociados.</p>
                                </div>
                            </div>
                            <div className="col-md-4 my-3">
                                <div className="icons-item">
                                    <MDBIcon icon="sitemap" size="2x" />
                                </div>
                                <div className="w-75 pl-2">
                                    <p className="font-weight-bold">Organización autogestionaria</p>
                                    <p className="text-justify">UTRAHUILCA es una organización autogestionaria y efectiva mediante el aporte económico y social voluntario de cada uno de los asociados para el desarrollo de la  filosofía institucional.</p>
                                </div>
                            </div>
                            <div className="col-md-4 my-3">
                                <div className="icons-item">
                                    <MDBIcon icon="book" size="2x" />
                                </div>
                                <div className="w-75 pl-2">
                                    <p className="font-weight-bold">Educación Cooperativa y Formación Integral</p>
                                    <p className="text-justify">Destinamos recursos para formar a la base social y su núcleo familiar, en filosofía cooperativa y educación financiera, permitiendo desarrollar seres integrales de bien, útiles para la sociedad.</p>
                                </div>
                            </div>
                            <div className="col-md-4 my-3">
                                <div className="icons-item">
                                    <MDBIcon far icon="handshake" size="2x" />
                                </div>
                                <div className="w-75 pl-2">
                                    <p className="font-weight-bold">Clima Organizacional Gratificante</p>
                                    <p className="text-justify">Propiciar en UTRAHUILCA, un ambiente de trabajo gratificante; que genere excelencia del acuerdo cooperativo.</p>
                                </div>
                            </div>
                            <div className="col-md-4 my-3">
                                <div className="icons-item">
                                    <MDBIcon icon="flag-checkered" size="2x" />
                                </div>
                                <div className="w-75 pl-2">
                                    <p className="font-weight-bold">Competitividad e Innovación Empresarial Cooperativa</p>
                                    <p className="text-justify">Generar acciones, productos y servicios que posicionen a UTRAHUILCA en el primer nivel de las empresas Cooperativas de Ahorro y Crédito.</p>
                                </div>
                            </div>
                            <div className="col-md-4 my-3">
                                <div className="icons-item">
                                    <MDBIcon icon="university" size="2x" />
                                </div>
                                <div className="w-75 pl-2">
                                    <p className="font-weight-bold">Acción Política</p>
                                    <p className="text-justify">Organizar, promover y difundir espacios de formación y acciones políticas para el desarrollo del modelo alternativo de economía solidaria.</p>
                                </div>
                            </div>
                            <div className="col-md-4 my-3">
                                <div className="icons-item">
                                    <MDBIcon icon="users" size="2x" />
                                </div>
                                <div className="w-75 pl-2">
                                    <p className="font-weight-bold">Integración cooperativa y solidaria</p>
                                    <p className="text-justify">Unir esfuerzos económicos, sociales, gremiales y políticos para fortalecer el desarrollo de la economía solidaria.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container-fluid">
                        <p className="font-weight-bold text-center py-4" style={{ fontSize: '30px', color: '#747373' }}>Reseña Histórica</p>
                        <div className="container">
                            <div className="row my-2 d-flex align-items-center font-weight-bold " style={{ background: '#f7f7f7', height: '80px' }}>
                                <div onClick={() => { this.cambiarFecha(1) }} className="col-md-3 text-center pt-3 item-reseña" style={this.state.stateDate === 1 ? { background: '#059b4e', color: '#fff', cursor: 'pointer' } : { background: '#F7F7F7', color: '#000', cursor: 'pointer' }}>
                                    <p>1966 - 1971</p>
                                </div>
                                <div onClick={() => { this.cambiarFecha(2) }} className="col-md-3 text-center pt-3 item-reseña" style={this.state.stateDate === 2 ? { background: '#059b4e', color: '#fff', cursor: 'pointer' } : { background: '#F7F7F7', color: '#000', cursor: 'pointer' }}>
                                    <p>1975 - 1981</p>
                                </div>
                                <div onClick={() => { this.cambiarFecha(3) }} className="col-md-3 text-center pt-3 item-reseña" style={this.state.stateDate === 3 ? { background: '#059b4e', color: '#fff', cursor: 'pointer' } : { background: '#F7F7F7', color: '#000', cursor: 'pointer' }}>
                                    <p>1982 - 1986</p>
                                </div>
                                <div onClick={() => { this.cambiarFecha(4) }} className="col-md-3 text-center pt-3 item-reseña" style={this.state.stateDate === 4 ? { background: '#059b4e', color: '#fff', cursor: 'pointer' } : { background: '#F7F7F7', color: '#000', cursor: 'pointer' }}>
                                    <p>1987 - 1991</p>
                                </div>
                            </div>
                            <div className="row my-2 d-flex align-items-center font-weight-bold" style={{ background: '#f7f7f7', height: '80px' }}>
                                <div onClick={() => { this.cambiarFecha(5) }} className="col-md-3 text-center pt-3 item-reseña" style={this.state.stateDate === 5 ? { background: '#059b4e', color: '#fff', cursor: 'pointer' } : { background: '#F7F7F7', color: '#000', cursor: 'pointer' }}>
                                    <p>1992 - 1996</p>
                                </div>
                                <div onClick={() => { this.cambiarFecha(6) }} className="col-md-3 text-center pt-3 item-reseña" style={this.state.stateDate === 6 ? { background: '#059b4e', color: '#fff', cursor: 'pointer' } : { background: '#F7F7F7', color: '#000', cursor: 'pointer' }}>
                                    <p>1997 - 2001</p>
                                </div>
                                <div onClick={() => { this.cambiarFecha(7) }} className="col-md-3 text-center pt-3 item-reseña" style={this.state.stateDate === 7 ? { background: '#059b4e', color: '#fff', cursor: 'pointer' } : { background: '#F7F7F7', color: '#000', cursor: 'pointer' }}>
                                    <p>2002 - 2006</p>
                                </div>
                                <div onClick={() => { this.cambiarFecha(8) }} className="col-md-3 text-center pt-3 item-reseña" style={this.state.stateDate === 8 ? { background: '#059b4e', color: '#fff', cursor: 'pointer' } : { background: '#F7F7F7', color: '#000', cursor: 'pointer' }}>
                                    <p>2007 - 2013</p>
                                </div>
                            </div>
                            {/*NUEVOS ITEMS 2022-11-22*/}
                            <div className="row my-2 d-flex align-items-center font-weight-bold" style={{ background: '#f7f7f7', height: '80px' }}>
                                <div onClick={() => { this.cambiarFecha(9) }} className="col-md-3 text-center pt-3 item-reseña" style={this.state.stateDate === 9 ? { background: '#059b4e', color: '#fff', cursor: 'pointer' } : { background: '#F7F7F7', color: '#000', cursor: 'pointer' }}>
                                    <p>2014 - 2018</p>
                                </div>
                                <div onClick={() => { this.cambiarFecha(10) }} className="col-md-3 text-center pt-3 item-reseña" style={this.state.stateDate === 10 ? { background: '#059b4e', color: '#fff', cursor: 'pointer' } : { background: '#F7F7F7', color: '#000', cursor: 'pointer' }}>
                                    <p>2019 - 2023</p>
                                </div>
                            </div>
                        </div>

                        <div className="contenido-reseña" style={{ background: '#f7f7f7' }}>
                            {this.state.stateDate === 1 ? (
                                <div className="py-5 mx-0 px-5">
                                    <h5 className="font-weight-bold">Es creada la Cooperativa - 02 Febrero 1966</h5>
                                    <p className="text-justify">Los motivos de creación de la Cooperativa, se remontan a los años 1965 y 1966, cuando entidades estatales como el Distrito de Carreteras No. 11, Obras Públicas Municipales, Empresas Públicas de Neiva y Hospital San Miguel de Neiva, estaban atravesando por una situación económica muy difícil , llegando a adeudar hasta tres quincenas a sus trabajadores, obligando a estos a vender sus nóminas a agiotistas que tenían conocimiento de la llegada de recursos a esas empresas y diez días antes se las compraban, descontándoles hasta un 10%.</p>
                                    <p className="text-justify">La iniciativa de crear una cooperativa, fue liderada por el señor Francisco Antonio Gordillo Cruz, Presidente de la Federación de Sindicatos de la Unión de Trabajadores de Colombia y el señor Ismael Quevedo, representante de la Unión de Cooperativas de Colombia UCONAL y Gerente de la Cooperativa San Miguel de Garzón, quienes empiezan a realizar gestiones para crear en Neiva una Cooperativa de ahorro y crédito, con el fin de solucionar en parte, la situación económica difícil que afectaba a este grupo de las empresas antes mencionadas.</p>
                                    <p className="text-justify">Estos gestores, inician tareas de sensibilización en cada Asamblea Sindical, donde explicaban las bondades del proyecto empresarial cooperativo, situación que presentaba dificultades porque algunas Cooperativas existentes como la de Inquilinos de la Galería de Neiva y la Industria Licorera del Huila se encontraban en quiebra y en otros casos los fondos de todos los asociados habían sido hurtados por los gestores, por lo anterior el panorama no era el mejor para crear una nueva Cooperativa; sin embargo, es preciso resaltar que fue el elevado grado de motivación y el liderazgo de estos dos gestores, lo que permitió la creación de esta cooperativa. Este proyecto empresarial nace con un monto de capital de $ 530,oo de aportes de los 30 asociados, incluidos los directivos; buscaba que la comunidad se asociara y ahorrara, para luego obtener créditos con intereses bajos, comparándolos con los existentes en el mercado, además de dictar cursos cooperativos para difundir su filosofía , principios y valores, característicos del sector cooperativo, que además permite la participación de los asociados en los estamentos directivos así como en los rendimientos económicos, al permitirles ser dueños directos de la Cooperativa.</p>
                                    <p className="text-justify">El 12 de abril del año 1970 se modifica la razón social de la Cooperativa de Trabajadores del Huila de acuerdo con el Acta N° 4 de la Asamblea General, en la que se aprobó conforme a lineamientos de trabajar por la integración sindical y cooperativa del Décimo Primer Congreso de la UTC, en la que se hizo hincapié que el nombre de la cooperativa debía ser el de Cooperativa de Ahorro y Crédito UTRAHUILCA UTC Ltda. En la mencionada Asamblea, uno de los fundadores el señor Francisco Antonio Gordillo, propuso que el nombre de las cooperativas que se crearan debía llevar la sigla de las federaciones regionales de trabajo, como reconocimiento al esfuerzo del movimiento sindical, el cual en ese momento era combatido duramente por el gobierno y los patrones. Es de esta forma que se obtiene el nombre de Cooperativa de ahorro y crédito UTRAHUILCA UTC Ltda., que significaba Unión de Trabajadores del Huila y Caquetá, debido a que todos sus asociados y dirigentes pertenecían a las bases trabajadoras, existiendo asociados tanto del Huila como del Caquetá, en el caso del Seguro Social, como la oficina del Caquetá dependía del Huila, se les permitió su afiliación así como a los trabajadores del Distrito de Carreteras.</p>
                                </div>
                            ) : (<React.Fragment></React.Fragment>)}
                            {this.state.stateDate === 2 ? (
                                <div className="py-5 mx-0 px-5">
                                    <h5 className="font-weight-bold">1975 - 1981 - Venta de Electrodomésticos</h5>
                                    <p className="text-justify">A partir de 1975 se amplía la planta de personal, conservando la misma estructura, pero vinculando más personal, además de Revisor fiscal, esta estrategia, basada en recursos disponibles de la actividad económica de ahorro y crédito, además de las capacidades y liderazgo de los directivos y trabajadores, permitió el crecimiento en asociados. La asociación era de vínculo cerrado, es decir solo permitía la afiliación de trabajadores oficiales.</p>
                                    <p className="text-justify">Cuando la cooperativa estaba ubicada en el Pasaje San Francisco adicionó además del ahorro y crédito, la venta de electrodomésticos y máquinas de escribir a los asociados, actividad que fue sustituida por la cooperativa hogar de Bogotá y que pertenecía a la UTC, posteriormente se creó ENCOVEN (Empresa Cooperativa de ventas), en 1983 la cual reemplazó dicha actividad comercial y pertenecía a todas las cooperativas de Neiva, cooperativa que no funcionó y fue liquidada posteriormente.   </p>
                                </div>
                            ) : (<React.Fragment></React.Fragment>)}
                            {this.state.stateDate === 3 ? (
                                <div className="py-5 mx-0 px-5">
                                    <h5 className="font-weight-bold">1982 - 1986 - Apertura Agencia Pitalito. Creación EMCOOVEN, EMCOFUN, EMCOSALUD, EDICOOPH.</h5>
                                    <p className="text-justify">Estratégica, presupuestos y estrategias publicitarias, la cooperativa inicio su etapa de mayor crecimiento; estos líderes sindicales le dieron otra visión y abrieron.</p>
                                    <p className="text-justify">En la década del 80 con la llegada a cargos directivos de Salvador Gómez Gómez, José Ramiro Becerra Sterling y Yael Garaviño, profesionales con una formación académica mayor a los fundadores y predecesores, quienes aplicaron conceptos de planeación estratégica, presupuestos y estrategias publicitarias, la cooperativa inicio su etapa de mayor crecimiento; estos líderes sindicales le dieron otra visión y abrieron el vínculo de ahorro y crédito para todo el que quisiera afiliarse, aprovechando la crisis económica presentada en el sector financiero tradicional, situación que permitió fortalecer el sector cooperativo, entre ellos UTRAHUILCA, es así, que de acuerdo a datos estadísticos de la cooperativa, pasa en el año 1982 de tener 1.882 asociados a 3.274 en 1983, con un incremento del 73%; situación similar ocurrió con el valor del capital, al incrementarse en un 65% al pasar de$ 30.150.559 pesos a $49.782.048, respectivamente.</p>
                                    <p className="text-justify">En la administración del señor Salvador Gómez, se fundó la primera agencia fuera de Neiva, ubicada en el Municipio de Pitalito, en el año 1986, lo que le permitió a la Cooperativa incrementar su número de asociados a 13.546 en el año 1987 y un capital de $ 306'308.300.oo pesos. Con esta apertura la cooperativa implementa la estrategia de ampliación de cobertura geográfica, lo que le dio mayor impulso en el sur del departamento del Huila.</p>
                                    <p className="text-justify">A través de la integración cooperativa practicada por UTRAHUILCA, se han creado empresas cooperativas como EMCOFUN LOS OLIVOS, EMCOSALUD, EMCOVEN, EDICOOPH entre otras, algunas de ellas se mantienen en la actualidad y otras han desaparecido por diferentes situaciones. A través de estas empresas se pretendió brindar más y mejores servicios a los asociados y la comunidad.</p>
                                </div>
                            ) : (<React.Fragment></React.Fragment>)}
                            {this.state.stateDate === 4 ? (
                                <div className="py-5 mx-0 px-5">
                                    <h5 className="font-weight-bold">1987 - 1991 - Conversión a MULTIACTIVA. Apertura San Agustín y Campoalegre. Nace Asocoph - 01 Febrero 1987</h5>
                                    <p className="text-justify">El 17 de marzo de 1991 la XXV Asamblea General ordinaria, aprueba la reforma estatutaria que cambia su razón de Cooperativa especializada de Ahorro y Crédito por Cooperativa Multiactiva del Huila "UTRAHUILCA", acatando el Artículo 61 y 63 de la Ley 79/88 que promulgan: Art 61 "Las Cooperativas en razón del desarrollo de sus actividades podrán ser especializadas, multiactivas e integrales." Art 63: Serán cooperativas multiactivas las que se organizan para atender varias necesidades, mediante concurrencia de servicios en una sola entidad jurídica". Durante la segunda administración de José Ramiro Becerra Sterling, quien asume como Gerente en 1990, es cuando la Cooperativa inicia un gran periodo de crecimiento y se abren nuevas agencias como San Agustín y Campoalegre. En 1991, luego del retiro de UTRAHILCA, EDICOOPH fue liquidada, creándose la Asociación de Cooperativas del Huila ASOCOPH, organismo de Segundo Grado.</p>
                                </div>
                            ) : (<React.Fragment></React.Fragment>)}
                            {this.state.stateDate === 5 ? (
                                <div className="py-5 mx-0 px-5">
                                    <h5 className="font-weight-bold">Apertura Agencias Isnos, Baraya, Sta. María, Timaná, Neiva Sur, Hobo, Saladoblanco, Tesalia y Neiva Oriente. Creación Sección Infantil y Juvenil AMIGUITOS. Creación FUNDAUTRAHUILCA - 04 Febrero 1992</h5>
                                    <p className="text-justify">De acuerdo con La Ley 79 de 1988 la cual le dio un marco legal propio al cooperativismo en Colombia, UTRAHUILCA acoge el artículo 27 que dispone la forma en que pueden vincularse menores de edad a la cooperativa, "Podrán ser asociados de las cooperativas las personas naturales legalmente capaces y los menores de edad que hayan cumplido 14 años, o quiénes sin haberlos cumplido se asocien a través de representante legal".</p>
                                    <p className="text-justify">El Consejo de Administración de UTRAHUILCA motivado por esta normatividad, analizó y expidió la reglamentación necesaria para iniciar este proyecto en la cooperativa, es así como incluye en el artículo 26 de sus estatutos, la creación de la Sección Infantil Juvenil "AMIGUITOS". El primer ejercicio realizado a través de "AMIGUITOS" fue su apertura oficial realizada en el Parque Solidaridad el 7 de mayo de 1991, a la cual asistieron de 1.500 niños.</p>
                                    <p className="text-justify">Esta entidad solidaria, incursionó en el sector de la educación formal y no formal a través del Colegio UTRAHUILCA, que gracias al liderazgo de directivos como Yael Garaviño Rodríguez y José Ramiro Becerra hoy se conserva, convertido en fundación UTRAHUILCA, y que además del colegio, funciona el Instituto de educación no formal; clubes deportivos, escuelas artísticas y la Escuela de formación de pensamiento político y liderazgo social EPU.</p>
                                    <p className="text-justify">El 9 de Julio de 1996 el Consejo de Administración analiza y aprueba los estatutos de la Fundación Social UTRAHUILCA, como estrategia para iniciar el proceso de transición de la multiactividad a la especialización como cooperativa financiera, teniendo en cuenta el proyecto de ley 78 que cursaba en el parlamento y que finalmente fue aprobado como Ley 454 de 1998.</p>
                                    <p className="text-justify">El 23 de agosto del año 1996 el señor José Ramiro Becerra Sterling, como Gerente General, protocoliza el cuerpo estatutario en escritura pública en la Notaría Primera del Circulo de Neiva, en representación de la Cooperativa Multiactiva del Huila "UTRAHUILCA". Se inscribe en Cámara de Comercio de Neiva el 14 de enero de 1997 bajo el número 312 del libro respectivo.</p>
                                </div>
                            ) : (<React.Fragment></React.Fragment>)}
                            {this.state.stateDate === 6 ? (
                                <div className="py-5 mx-0 px-5">
                                    <h5 className="font-weight-bold">Crisis Cooperativa, Reuniones Informativas, Conversión Ahorro y Crédito. Afiliación FOGACOOP. Incorporación COFICOL y COFINAM-01 Febrero 1997</h5>
                                    <p className="text-justify">Dentro del ciclo de vida de la empresa, la crisis más fuerte fue la presentada en el año 1998, debido a la expedición del Decreto N° 798 del 20 de marzo de 1997, en el cual se prohíbe a los entes del orden nacional tener depósitos e inversiones en entidades que no se estén vigiladas por La Superintendencia Bancaria y la Superintendencia de Valores. Esta situación desencadeno en el retiro masivo de dineros y terminación de convenios con entidades nacionales, departamentales y municipales, lo cual incremento el pánico financiero.</p>
                                    <p className="text-justify">Importantes cooperativas como COFIANDINA, que era la más grande existente en la Región, colapso como consecuencia de la crisis financiera a nivel nacional, que ocasionó el retiro masivo de asociados por el pánico existente, siendo intervenida. En esta crisis el 70% de los depósitos se agotaron en menos de un mes y la liquidez se agotó por el masivo retiro de ahorros.</p>
                                    <p className="text-justify">Según lo expuesto en las memoria de la XXXVI Asamblea General de Asociados correspondiente al año 1998 "Durante los 32 años de funcionamiento de la Cooperativa UTRAHUILCA, podemos catalogar a 1998, sin lugar a equivocarnos, como el año más difícil para nuestra institución; pero a la vez ha sido el periodo en que mayormente se puso a prueba la capacidad de los organismos de Dirección, Vigilancia, Control, Administración y Operación de la Cooperativa; lo mismo que la capacidad de respuesta de nuestros asociados; especialmente en la solidaridad, fidelidad y lealtad a la institución".</p>
                                    <p className="text-justify">Así mismo, como estrategia para afrontar la situación, encontramos dentro del Acta N°4 de 28 de marzo de 1998 correspondiente al Consejo de Administración, el informe presentado por el Gerente General, José Ramiro Becerra Sterling, en el cual expone: "Dentro de los propósitos de mejoramiento continuo de la Cooperativa hemos emprendido una gira por todas las agencias, haciendo reuniones con empleados y asociados, con el propósito de dar información sobre la situación económica de la cooperativa al mes de febrero de 1998 y hacer un análisis con relación a la concepción de lo que es una empresa cooperativa, las causas de la crisis actual de muchas cooperativas financieras, lo mismo que planteamientos de cómo concebimos el verdadero papel de las cooperativa populares, haciendo énfasis en la filosofía institucional de UTRAHUILCA".</p>
                                    <p className="text-justify">De esta forma, a partir de 1999 se dio inicio a las Reuniones Informativas con los asociados por agencias, para mostrarles los diferentes estados financieros como balance general, los depósitos de ahorro, deudas, entre otros, demostrando que tenía la cartera de crédito sólida, lo mismo que recursos físicos, informándole a los asociados sobre la verdad de lo que estaba ocurriendo; algunos se retiraron de la cooperativa, otros la apoyaron y se vendió un bono de apoyo solidario de $20.000,oo, para aumentar los aportes sociales, con lo cual, la gente empezó a entender la situación; a pesar de ello, los aportes bajaron, pues no ahorraban y solamente se dirigían a la Cooperativa a pagar deudas de los créditos otorgados; las agencias permanecían solas, la gente no acudía a ellas a utilizar los servicios, especialmente la de Neiva principal y sur, así como las de Pitalito, Campoalegre, san Agustín, Timaná, Baraya y Santa María. En la actualidad se mantiene esta estrategia de Reuniones informativas con los asociados como mecanismo de interacción - participación entre la cooperativa y su base social para dar a conocer la marcha, funcionamiento y proyectos de desarrollo de la misma.</p>
                                    <p className="text-justify">Se acudió, por parte de los directivos a FOGACOOP, fondo similar a FOGAFIN para el sector Cooperativo, para que por problemas de liquidez no se perdiera otra Cooperativa como COFICOL (la cooperativa UTRAHUILCA aprobó la incorporación de esta cooperativa el 27 de febrero de 1999, según el Acta N°3) y COFINAM, las cuales habían obtenido pérdidas del 40% de los aportes sociales.</p>
                                    <p className="text-justify">Ante esta situación, la Superintendencia de economía solidaria aprobó las incorporaciones de estas dos cooperativa a UTRAHUILCA, a cambio de entregar algunos recursos físicos, consistente en unas instalaciones ubicadas en Neiva, donde actualmente funciona un fondo de Pensiones, el cual se pensaba remodelar, pero por la crisis , no se pudo realizar y se entregó a Fogacoop, junto con un lote que tenía la cooperativa destinado para construir vivienda de interés social en convenio con la constructora La Equidad.</p>
                                    <p className="text-justify">Fogacoop entregó recursos por 1.200 millones de pesos y fue lenta su recuperación.</p>
                                </div>
                            ) : (<React.Fragment></React.Fragment>)}
                            {this.state.stateDate === 7 ? (
                                <div className="py-5 mx-0 px-5">
                                    <h5 className="font-weight-bold">Apertura Agencia La Plata, Florencia y Garzón. Convenios PADEMER, BANCOLDEX, LA EQUIDAD. Convenio Tarjeta Débito, Cajeros Automáticos-07 Febrero 2002</h5>
                                    <p className="text-justify">La cooperativa cuenta con tecnología satelital para procesar información y para los puntos de atención como es el caso de los cajeros automáticos. El aspecto tecnológico tuvo un gran avance a través del convenio de afinidad con Banco de Bogotá, por medio del cual se instalaron cajeros automáticos en todas las agencias de la cooperativa, los campesinos han sido los más beneficiados, pues nunca habían tenido acceso al manejo de una tarjeta débito.</p>
                                    <p className="text-justify">Por su parte, la tarjeta débito UTRAHUILCA, permite que los asociados tengan acceso inmediato a su dinero en cajeros automáticos y Datafonos. Así mismo, puede utilizarse en cajeros electrónicos de todas las redes para retiros en efectivo y en pagos por Datafonos en los establecimientos de comercio para realizar sus compras. Consulta de saldo de cuenta de ahorros a través de cajeros electrónicos.</p>
                                </div>
                            ) : (<React.Fragment></React.Fragment>)}
                            {this.state.stateDate === 8 ? (
                                <div className="py-5 mx-0 px-5">
                                    <h5 className="font-weight-bold">Conversión Cooperativa Latinoamericana - 2013</h5>{/*01 Febrero 2013*/}
                                    <p className="text-justify">En la Asamblea celebrada el 17 de marzo de 2007, se modifican los estatutos de la Cooperativa y se aprueba el nombre de la Cooperativa Latinoamericana de Ahorro y Crédito - UTRAHUILCA.</p>
                                    <p className="text-justify">Además se establece convenio con FINAGRO para otorgar créditos destinados a inversiones agrícolas, turísticas y mineras, con FINDETER para financiamiento de viviendas de interés social y con EFECTY para recaudo de cuotas de crédito.</p>
                                    <p className="text-justify">Un gran logro de la cooperativa fue la apertura de otra agencia fuera del departamento del Huila, está vez en el Cauca, específicamente en la ciudad de Popayán.</p>
                                    {/*TEXTO NUEVO 2022-11-22*/}
                                    <p className="text-justify">•	La cooperativa toma la póliza de seguro deudor a través de La Equidad Seguros para amparar la cartera de UTRAHUILCA por fallecimiento del o la asociada.</p>
                                    <p className="text-justify">•	Se apertura agencia en el municipio de Palermo</p>
                                    <p className="text-justify">•	Se adelantó el proceso para la formalización de la vinculación de UTRAHUILCA a la red financiera COOPCENTRAL, para favorecer la presencia del cooperativismo en el panorama económico de Colombia.</p>
                                    <p className="text-justify">•	Se elaboró y registro el software UTRAWEB para adecuar, ampliar y agilizar el manejo de la información e integrar módulos administrativos y contables</p>
                                </div>
                            ) : (<React.Fragment></React.Fragment>)}
                            {/*NUEVOS ITEMS 2022-11-22*/}
                            {this.state.stateDate === 9 ? (
                                <div className="py-5 mx-0 px-5">
                                    <h5 className="font-weight-bold">2014</h5>
                                    <p className="text-justify">•	Apertura agencia San Vicente del Caguán - Caquetá</p>
                                    <p className="text-justify">•	Inauguración de nueva sede propia en la ciudad de Florencia</p>
                                    <p className="text-justify">•	Puesta en marcha del sistema contable y financiero UTRAWEB</p>
                                    <p className="text-justify">•	La confederación de Cooperativas de Colombia COONFECOOP, con el aval expedido por Cooperativas de las Américas, otorgó a UTRAHUILCA el uso del sello 100% Cooperativa, insignia que identifica a las cooperativas que hacen su trabajo social y económico, bajo los lineamientos de la filosofía cooperativa y solidaria.</p>
                                    <p className="text-justify">•	Se firma convenio de recaudo con el Banco Agrario de Colombia.</p>


                                    <h5 className="font-weight-bold pt-4">2015</h5>
                                    <p className="text-justify">•	Se realizó una probación para el incremento a la reserva para la protección de aportes sociales, equivalente al 5% adicional a lo establecido en la ley, con los excedentes cooperativos de 2015.</p>
                                    <p className="text-justify">•	Se implementa la tarjeta visionamos del Banco Coopcentral, a través de la Red Servibanca.</p>
                                    <p className="text-justify">•	Apertura de agencia en Acevedo – Huila.</p>
                                    <p className="text-justify">•	Se crea convenio con la Equidad Seguros para adquisición de SOAT en las agencias de UTRAHUILCA.</p>

                                    
                                    <h5 className="font-weight-bold pt-4">2016</h5>
                                    <p className="text-justify">•	Aperturaron agencias en Mocoa – Putumayo y Algeciras – Huila.</p>
                                    <p className="text-justify">•	Se conmemoraron 50 años de filosofía y acción cooperativa permanente de UTRAHUILCA, mediante actos solemnes en el Centro de Convenciones José Eustacio Rivera.</p>
                                    <p className="text-justify"></p>

                                    
                                    <h5 className="font-weight-bold pt-4">2017</h5>
                                    <p className="text-justify">
                                        •	Aperturaron agencias minibásicas en:
                                        <p className="text-justify"></p>
                                        <p className="text-justify">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1. Norte de Florencia – Caquetá</p>
                                        <p className="text-justify">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2. Puerto Rico - Caquetá</p>
                                        <p className="text-justify">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3. Oriente de Neiva - Huila</p>
                                        <p className="text-justify">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4. Sur de Neiva – Huila</p>
                                    </p>
                                    <p className="text-justify">•	Se realizó la compra de un terreno de 45.000 metros cuadrados con el propósito de construir la infraestructura donde se realizarán las diferentes actividades de UTRAHUILCA con extensión a la comunidad </p>
                                    <p className="text-justify">•	Empieza a implementarse el Multiportal de la Red Coopcentral para la realización de operaciones vía internet</p>

                                    
                                    <h5 className="font-weight-bold pt-4">2018</h5>
                                    <p className="text-justify">•	Se efectuó el apoyo de candidatos del cooperativismo, al senado JOSÉ RAMIRO BECERRA STERLING (Q.E.P.D.) y a la cámara de representantes ISRAEL SILVA GUARNIZO, con el propósito de visibilizar el movimiento cooperativo y solidario como actor importante en el escenario de la economía solidaria.</p>
                                    <p className="text-justify">
                                        •	Aperturaron agencias minibásicas en:
                                        <p className="text-justify"></p>
                                        <p className="text-justify">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1. Terminal de Transportes de Neiva- Huila</p>
                                        <p className="text-justify">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2. Centro Comercial Único Neiva- Huila</p>
                                        <p className="text-justify">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3. La Libertad, Florencia – Caquetá</p>
                                        <p className="text-justify">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4. Puerto Rico – Caquetá</p>
                                    </p>
                                </div>
                            ) : (<React.Fragment></React.Fragment>)}
                            {this.state.stateDate === 10 ? (
                                <div className="py-5 mx-0 px-5">
                                    <h5 className="font-weight-bold">2019</h5>
                                    <p className="text-justify">•	UTRAHUILCA compró a la empresa Cooperativa EMCOOFUN, 116 cinerarios y 108 osarios, en el cementerio Jardines del Paraíso en la ciudad de Neiva. </p>
                                    <p className="text-justify">•	Se apertura la agencia en Santander de Quilichao, Cauca, y el Punto de Promoción Cooperativo en el corregimiento de San Luis, Neiva - Huila. </p>
                                    {/*<p className="text-justify">
                                        •	Se Aperturaron puntos de atención cooperativa en:
                                        <p className="text-justify"></p>
                                        <p className="text-justify">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1. Centro Poblado San Luis, Neiva – Huila </p>
                                        <p className="text-justify">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2. Norte del Departamento del Cauca</p>
                                    </p>*/}
                                    <p className="text-justify">•	Se inició la implementación del Sistema de Administración de Riesgo de Liquidez SARL.</p>


                                    <h5 className="font-weight-bold pt-4">2020</h5>
                                    <p className="text-justify">•	Se apertura la agencia en Ibagué, Tolima </p>
                                    <p className="text-justify">•	Ante la emergencia sanitaria en el país provocada por el COVID-19, se aprobaron periodos de gracia y cupos de crédito para ayudar a aliviar la delicada situación económica de nuestra base social y el territorio. </p>
                                    {/*<p className="text-justify">•	Se reactivó el programa FINANSOL  enfocado a brindar cupos de crédito para asociados con escasos recursos.</p>*/}


                                    <h5 className="font-weight-bold pt-4">2021</h5>
                                    <p className="text-justify">
                                        •	UTRAHUILCA incorporó a la Cooperativa Laboyana de Ahorro y Crédito COOLAC, dando la bienvenida a 12.998 asociados, lo que conllevó a este crecimiento:
                                        <p className="text-justify"></p>
                                        <p className="text-justify">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#10004;&nbsp;Nueve agencias nuevas. En el Huila: Bruselas, Teruel, Palestina, Yaguará, Saladoblanco e Íquira; en el Caquetá: El Doncello y San José del Fragua; en Putumayo, Puerto Asís.</p>
                                        <p className="text-justify">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#10004;&nbsp;Tres Puntos de Promoción Cooperativa nuevos. Oporapa y El Juncal en el Huila y Curillo en el Caquetá.</p>
                                        <p className="text-justify">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#10004;&nbsp;Se unificó la base social en: Santa María, Neiva, Palermo, Isnos, Florencia, San Vicente del Caguán, Mocoa e Ibagué.</p>
                                        <p className="text-justify">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#10004;&nbsp;Se creó una nueva agencia minibásica en Pitalito</p>
                                    </p>
                                    <p className="text-justify">•	Se aprobó la vinculación a la Federación Nacional de Cooperativas del Sector Educativo Colombiano FENSECOOP. </p>
                                </div>
                            ) : (<React.Fragment></React.Fragment>)}
                        </div>
                    </div>
                    <div className="container pb-5 text-center">
                        <p className="font-weight-bold text-center pt-4" style={{ fontSize: '30px', color: '#747373' }}>Estatutos</p>
                        <span>Descargar</span><br /><br />
                        <a style={{ background: '#FA7500', borderRadius: '20px', padding: '8px 12px 8px 12px', color: 'white' }} href={`${URLImgFront}/assets/Archivos/ESTATUTOS%20UTRAHUILCA.pdf`} download><span>Estatutos Cooperativa Utrahuilca</span></a>
                    </div>
                </div>
            </React.Fragment >
        );
    }
}

export default Seccion2;