import React, { Component } from 'react';

class Seccion05 extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="bg-white py-2 py-sm-3 py-md-2 py-lg-5">
                    <div className="d-flex justify-content-center">
                        <p className="text-center" style={{ fontFamily: 'Posterama 1927', color: '#0B6A85', fontSize: '3vw', fontWeight: 'bold', lineHeight: 'normal' }}>Asignación de números adicionales para <span><br /></span>SORTEOS MENSUALES, TRIMESTRALES y el GRAN SORTEO</p>
                    </div>
                </div>
                <div className="container-fluid bg-white pb-2">
                    <div className="row mx-0">
                        <div className="col-1 px-0"></div>
                        <div className="col-10 px-0" style={{ fontSize: '2.5vmin' }}>
                            <div className="d-flex justify-content pb-0 pb-md-3">
                                <p style={{ fontFamily: 'Posterama 1927 Bold', color: '#808080', lineHeight: 'normal' }}>1. Para acceder a los número adicionales deberán haber cumplido primero con la <span style={{ fontWeight: 'bold' }}>meta de incremento</span> de los productos participantes de acuerdo con cada condición de periodicidad del sorteo. </p>
                            </div>

                            <div className="d-flex justify-content pb-0 pb-md-3">
                                <p style={{ fontFamily: 'Posterama 1927 Bold', color: '#808080', lineHeight: 'normal' }}>2. Se asignarán (2) números adicionales para participar en los sorteos a los asociados que completen y mantengan el equivalente a dos (2) SMMLV en la cuenta de <span style={{ fontWeight: 'bold' }}>Aportes Sociales UTRASOCIAL y/o AHORRO PERMANENTE.</span></p>
                            </div>

                            <div className="d-flex justify-content pb-0 pb-md-3">
                                <p style={{ fontFamily: 'Posterama 1927 Bold', color: '#808080', lineHeight: 'normal' }}>3. Se asignará un (1) número adicional para participar a los asociados que se encuentren <span style={{ fontWeight: 'bold' }}>datos actualizados.</span></p>
                            </div>

                            <div className="d-flex justify-content pb-0 pb-md-3">
                                <p style={{ fontFamily: 'Posterama 1927 Bold', color: '#808080', lineHeight: 'normal' }}>4. Se asignarán cinco (5) números adicionales para participar en los sorteos trimestrales y el gran sorteo si <span style={{ fontWeight: 'bold' }}>cumple</span> con la condición de los tres (3) productos participantes.</p>
                            </div>

                            <div className="d-flex justify-content pb-0 pb-md-3">
                                <p style={{ fontFamily: 'Posterama 1927 Bold', color: '#808080', lineHeight: 'normal' }}>5. Los números asignados en cada fecha de corte no son acumulativos para los siguientes cortes de sorteos.</p>
                            </div>
                        </div>
                        <div className="col-1 px-0"></div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Seccion05;