import React, { Component } from 'react';
import FileUploader from "./ImagenTexto/FileUploader";
import FileUploaderUpdate from "./ImagenTexto/FileUploaderUpdate";
import { MDBIcon, MDBInput } from 'mdbreact';
import { Asociados_, CargueImagenes } from '../../../services/WebAPI_Utrahuilca';
import Swal from 'sweetalert2';

const validate = values => {
    const errors = {}
    if (!values.nombvtaasociado) {
        errors.nombvtaasociado = 'campo obligatorio'
    }
    if (!values.txtvtaasociado1) {
        errors.txtvtaasociado1 = 'campo obligatorio'
    }
    if (!values.txtvtaasociado2) {
        errors.txtvtaasociado2 = 'campo obligatorio'
    }
    if (!values.txtvtaasociado3) {
        errors.txtvtaasociado3 = 'campo obligatorio'
    }
    return errors;
};

class ImagenTexto extends Component {
    uploader = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            estadoNuevo: false,
            errors: {},
            estadoCambioImg1: true,
            infoAsociados: [],
            nombvtaasociado: '',
            txtvtaasociado1: '',
            txtvtaasociado2: '',
            txtvtaasociado3: '',
            editnombvtaasociado: '',
            edittxtvtaasociado1: '',
            edittxtvtaasociado2: '',
            estadoImagen: false,
            rutaimgasoci: '',
            estadoSinInfo: false
        };
    }

    async componentDidMount() {
        this.cargarInformacion();
    }

    cargarInformacion = () => {
        const data = {
            tran: 4,
            idvtaasociado: 0,
            nombvtaasociado: 'NA',
            rutaimgasoci: 'NA',
            txtvtaasociado1: 'NA',
            txtvtaasociado2: 'NA',
            txtvtaasociado3: 'NA',
            estado: 0,
            usucrea: 'NA'
        }
        Asociados_(data).then(response => {
            try {
                if (response.data.length > 0) {
                    if (!response.data[0].message) {
                        this.setState({
                            idSeleccionado: response.data[0].idvtaasociado
                        })
                        setTimeout(() => {
                            this.setState({
                                infoAsociados: response.data,
                                editnombvtaasociado: response.data[0].nombvtaasociado,
                                rutaimgasoci: response.data[0].rutaimgasoci,
                                edittxtvtaasociado1: response.data[0].txtvtaasociado1,
                                edittxtvtaasociado2: response.data[0].txtvtaasociado2,
                                edittxtvtaasociado3: response.data[0].txtvtaasociado3,
                                estado: response.data[0].estado,
                                estadoSinInfo: true,
                                estadoImagen: true,
                            })
                            document.getElementById('' + response.data[0].idvtaasociado + '').style.background = "#f47216";
                            document.getElementById('' + response.data[0].idvtaasociado + '').style.color = "#fff";
                            document.getElementById('' + response.data[0].idvtaasociado + '').style.fontWeight = "bold";
                        }, 120);
                    } else {
                        this.setState({
                            estadoSinResultados: false,
                            estadoNuevo: true,
                            estadoCambioImg1: true,
                            estadoSinInfo: false
                        })
                    }
                } else { }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    text: 'Ocurrio un error al consumir el servicio, comuniquese con sistemas!'
                })
            }
        });
    }

    textInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    cambioImagen1 = (name, file) => {
        this.setState({
            estadoCambioImg1: true
        });
    }

    cambioImagen = (name, file) => {
        this.setState({
            estadoCambioImg: true
        });
    }

    cargarImagen = () => {
        var formData = new FormData();
        if (undefined === this.uploader.current) return;
        formData.append("file", this.uploader.current.getFileObject());
        formData.append("rutaImagen", '/Asociados/ImagenTexto/' + this.uploader.current.getFileName());
        CargueImagenes(formData).then(response => {
            if (response) {
                this.cargarInformacion();
            }
        });
    }

    insertarDatos = () => {
        const { errors, ...sinErrors } = this.state;
        const result = validate(sinErrors);
        this.setState({ errors: result });
        if (!Object.keys(result).length) {
            // formulario validado
            const data = {
                tran: 1,
                idvtaasociado: 0,
                nombvtaasociado: this.state.nombvtaasociado,
                rutaimgasoci: '/Asociados/ImagenTexto/' + this.uploader.current.getFileName(),
                txtvtaasociado1: this.state.txtvtaasociado1,
                txtvtaasociado2: this.state.txtvtaasociado2,
                txtvtaasociado3: this.state.txtvtaasociado3,
                estado: 1,
                usucrea: sessionStorage.getItem('idusuario')
            }
            Asociados_(data).then(response => {
                if (response.data.length > 0) {
                    if (response.data[0].message === "000000") {
                        this.cargarImagen();
                        Swal.fire({
                            icon: 'success',
                            title: 'Se agrego correctamente.'
                        })
                        this.setState({
                            estadoNuevo: false,
                            nombvtaasociado: '',
                            txtvtaasociado1: '',
                            txtvtaasociado2: '',
                            txtvtaasociado3: '',
                            estadoImagen: false,
                            rutaimgasoci: '',
                        })
                        this.cargarInformacion();
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error al insertar.'
                        })
                    }
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Ocurrio un error!'
                    })
                }
            });
        }
    }

    busquedaId = (id) => {
        document.getElementById('' + this.state.idSeleccionado + '').style.background = "#fff";
        document.getElementById('' + this.state.idSeleccionado + '').style.color = "#000";
        document.getElementById('' + this.state.idSeleccionado + '').style.fontWeight = "normal";
        document.getElementById('' + id + '').style.background = "#f47216";
        document.getElementById('' + id + '').style.color = "#fff";
        document.getElementById('' + id + '').style.fontWeight = "bold";
        this.setState({
            estadoCambioImg: false,
            est: !this.state.est,
            idSeleccionado: id
        })
        const data = {
            tran: 5,
            idvtaasociado: id,
            nombvtaasociado: 'NA',
            rutaimgasoci: 'NA',
            txtvtaasociado1: 'NA',
            txtvtaasociado2: 'NA',
            txtvtaasociado3: 'NA',
            estado: this.state.estado,
            usucrea: 'NA'
        }
        Asociados_(data).then(response => {
            if (response.data.length > 0) {
                this.setState({
                    editnombvtaasociado: response.data[0].nombvtaasociado,
                    rutaimgasoci: response.data[0].rutaimgasoci,
                    edittxtvtaasociado1: response.data[0].txtvtaasociado1,
                    edittxtvtaasociado2: response.data[0].txtvtaasociado2,
                    edittxtvtaasociado3: response.data[0].txtvtaasociado3,
                    estado: response.data[0].estado,
                })
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'No hay información'
                })
            }
        });
    }

    activar = (id) => {
        const data = {
            tran: 6,
            idvtaasociado: id,
            nombvtaasociado: 'NA',
            rutaimgasoci: 'NA',
            txtvtaasociado1: 'NA',
            txtvtaasociado2: 'NA',
            txtvtaasociado3: 'NA',
            estado: 1,
            usucrea: sessionStorage.getItem('idusuario')
        }
        Asociados_(data).then(response => {
            if (response) {
                this.setState({
                    estadoS: false
                })
                Swal.fire({
                    icon: 'success',
                    title: 'Se activo satisfactoriamente'
                });
                document.getElementById('' + this.state.idSeleccionado + '').style.background = "#fff";
                document.getElementById('' + this.state.idSeleccionado + '').style.color = "#000";
                document.getElementById('' + this.state.idSeleccionado + '').style.fontWeight = "normal";
                this.cargarInformacion();
            }
        });
    }

    actualizarDatos = (id) => {
        var ruta = '';
        var capNombre = this.uploader.current.getFileName();
        if (capNombre === undefined || capNombre === null) {
            ruta = this.state.rutaimgasoci;
        } else {
            ruta = '/Asociados/ImagenTexto/' + this.uploader.current.getFileName();
        }
        const data = {
            tran: 1,
            idvtaasociado: id,
            nombvtaasociado: this.state.editnombvtaasociado,
            rutaimgasoci: ruta,
            txtvtaasociado1: this.state.edittxtvtaasociado1,
            txtvtaasociado2: this.state.edittxtvtaasociado2,
            txtvtaasociado3: this.state.edittxtvtaasociado3,
            estado: 1,
            usucrea: sessionStorage.getItem('idusuario')
        }
        Asociados_(data).then(response => {
            if (response.data.length > 0) {
                if (response.data[0].message === "000000") {
                    this.cargarImagen();
                    this.setState({
                        estadoCambio: false,
                        estadoEditar: false,
                    })
                    Swal.fire({
                        icon: 'success',
                        title: 'Se actualizo satisfactoriamente'
                    })
                    document.getElementById('' + this.state.idSeleccionado + '').style.background = "#fff";
                    document.getElementById('' + this.state.idSeleccionado + '').style.color = "#000";
                    document.getElementById('' + this.state.idSeleccionado + '').style.fontWeight = "normal";
                    this.cargarInformacion();
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Ocurrio un error al actualizar'
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Ocurrio un error!'
                })
            }
        });
    }

    handleNew = () => {
        this.setState({
            estadoNuevo: !this.state.estadoNuevo,
            errors: {},
        })
        if (this.state.estadoNuevo) {
            this.setState({
                errors: {},
            })
            this.cargarInformacion();
        }
    }

    render() {
        const { errors, estadoNuevo, infoAsociados, nombvtaasociado, estadoCambioImg1, estadoCambioImg, estadoSinInfo, txtvtaasociado1, txtvtaasociado2, txtvtaasociado3, estadoImagen, rutaimgasoci } = this.state;
        return (
            <React.Fragment>
                <p>Imagen - Texto Asociados</p>
                <hr />
                {estadoSinInfo ? (
                    <React.Fragment>
                        <div className="plus_add_agencia align-items-center d-flex" onClick={() => { this.handleNew() }}>
                            {estadoNuevo ? <p>Cancelar &nbsp;<MDBIcon icon="times" size="lg" /></p> : <p>Agregar Nuevo &nbsp;<MDBIcon icon="plus-circle" size="lg" /></p>}
                        </div>
                    </React.Fragment>
                ) : (
                    <React.Fragment></React.Fragment>
                )}
                {estadoNuevo ? (
                    <React.Fragment>
                        <div className="justify-content-center d-flex">
                            <div className="text-center">
                                <FileUploader ref={this.uploader} estadoImg={estadoCambioImg1} FCambiar={this.cambioImagen1} />
                            </div>
                        </div>
                        <div className="container mt-5 pt-5 pb-5">
                            <div className="row">
                                <div className="col-md-6">
                                    <MDBInput label="Nombre" type="text" name="nombvtaasociado" value={nombvtaasociado} onChange={this.textInput} />
                                    {errors.nombvtaasociado && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '50px' }}>{errors.nombvtaasociado}</strong>}
                                </div>
                                <div className="col-md-6">
                                    <MDBInput label="Texto 1" type="text" name="txtvtaasociado1" value={txtvtaasociado1} onChange={this.textInput} />
                                    {errors.txtvtaasociado1 && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '50px' }}>{errors.txtvtaasociado1}</strong>}
                                </div>
                                <div className="col-md-6">
                                    <MDBInput label="Texto 2" type="text" name="txtvtaasociado2" value={txtvtaasociado2} onChange={this.textInput} />
                                    {errors.txtvtaasociado2 && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '50px' }}>{errors.txtvtaasociado2}</strong>}
                                </div>
                                <div className="col-md-6">
                                    <MDBInput label="Texto 3" type="text" name="txtvtaasociado3" value={txtvtaasociado3} onChange={this.textInput} />
                                    {errors.txtvtaasociado3 && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '50px' }}>{errors.txtvtaasociado3}</strong>}
                                </div>
                            </div>
                        </div>
                        <div className="pt-2 mt-5 modal-footer w-100">
                            <button type="button" className="btn btn-primary px-5" onClick={this.insertarDatos}>Guardar nuevo &nbsp; <MDBIcon icon="save" /></button>
                        </div>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className="listado_banner pt-5">
                            <p>Seleccionar banner</p>
                            <ul>
                                {infoAsociados.map((e, i) => {
                                    let view;
                                    if (e.estado === 1) {
                                        view = <MDBIcon icon="eye" size="lg" style={{ color: 'black', position: 'absolute', right: '-38px', marginTop: '5px' }} />
                                    } else {
                                        view = <MDBIcon onClick={() => { this.activar(e.idvtaasociado) }} icon="eye-slash" size="lg" style={{ color: 'black', position: 'absolute', right: '-38px', marginTop: '5px' }} />
                                    }
                                    return [
                                        <li key={i} id={e.idvtaasociado} onClick={() => { this.busquedaId(e.idvtaasociado) }}>{e.nombvtaasociado} {view}</li>
                                    ]
                                })}
                            </ul>
                        </div>
                        <div className="justify-content-center d-flex">
                            <div className="text-center">
                                {estadoImagen ? (
                                    <FileUploaderUpdate ref={this.uploader} ruta={rutaimgasoci} estadoImg={estadoCambioImg} FCambiar={this.cambioImagen} />
                                ) : (
                                    <React.Fragment></React.Fragment>
                                )}
                            </div>
                        </div>
                        <div className="container mt-5 pt-5 pb-5">
                            <div className="row">
                                <div className="col-md-6">
                                    <MDBInput label="Nombre" type="text" name="editnombvtaasociado" value={this.state.editnombvtaasociado} onChange={this.textInput} />
                                </div>
                                <div className="col-md-6">
                                    <MDBInput label="Texto 1" type="text" name="edittxtvtaasociado1" value={this.state.edittxtvtaasociado1} onChange={this.textInput} />
                                </div>
                                <div className="col-md-6">
                                    <MDBInput label="Texto 2" type="text" name="edittxtvtaasociado2" value={this.state.edittxtvtaasociado2} onChange={this.textInput} />
                                </div>
                                <div className="col-md-6">
                                    <MDBInput label="Texto 3" type="text" name="edittxtvtaasociado3" value={this.state.edittxtvtaasociado3} onChange={this.textInput} />
                                </div>
                            </div>
                        </div>
                        <div className="pt-2 mt-5 modal-footer w-100">
                            <button type="button" className="btn btn-success px-5" onClick={() => { this.actualizarDatos(this.state.idSeleccionado) }}>Guardar cambios &nbsp; <MDBIcon icon="save" /></button>
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

export default ImagenTexto;