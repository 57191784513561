import React, { Component } from 'react';
import { MDBIcon, MDBInput } from 'mdbreact';
import ReCAPTCHA from "react-google-recaptcha";
import { Agencias_, Asesoria_ } from '../../services/WebAPI_Utrahuilca';
import Select from "react-select";
import Swal from 'sweetalert2';
import Loading from '../Loading';

const validate = values => {
    const errors = {}
    if (values.tipoiden === 'DEFAULT') {
        errors.tipoiden = 'campo obligatorio'
    }
    if (values.asociado === 'DEFAULT') {
        errors.asociado = 'campo obligatorio'
    }
    if (values.selectedOrigen === 'DEFAULT') {
        errors.selectedOrigen = 'campo obligatorio'
    }
    if (values.codocupacion === 'DEFAULT') {
        errors.codocupacion = 'campo obligatorio'
    }
    if (values.idprodserv === 'DEFAULT') {
        errors.idprodserv = 'campo obligatorio'
    }
    if (!values.nombres) {
        errors.nombres = 'campo obligatorio'
    }
    if (!values.apellidos) {
        errors.apellidos = 'campo obligatorio'
    }
    if (!values.nit) {
        errors.nit = 'campo obligatorio'
    }
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    if (!pattern.test(values.correo)) {
        errors.correo = "Introduce una dirección de correo electrónico válida.";
    }
    if (!values.correo) {
        errors.correo = 'campo obligatorio'
    }
    if (!values.confirmarcorreo) {
        errors.confirmarcorreo = 'campo obligatorio'
    }
    if (values.correo !== values.confirmarcorreo) {
        errors.correo = 'Los correos no coinciden'
        errors.confirmarcorreo = 'Los correos no coinciden'
    }
    if (!values.telcel) {
        errors.telcel = 'campo obligatorio'
    }
    else if (values.telcel.length < 10) {
        errors.telcel = 'La longitud del campo es 10'
    }

    if(values.checkbox === false){
        errors.checkbox = 'campo obligatorio'
    }
    return errors;
};

class FormAsesoria extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            estadoSolicitud: true,
            tipoiden: 'DEFAULT',
            asociado: 'DEFAULT',
            codocupacion: 'DEFAULT',
            infoAgencias: [],
            selectedOrigen: 'DEFAULT',
            idprodserv: 'DEFAULT',
            otraocupacion: 'N/A',
            montoreq: '',
            stateDollar: false,
            infoadicional: '',
            telcel: '',
            nit: '',
            stateLoadign: false,
            checkbox: false
        };
        this.recaptchaRef = React.createRef();
    }

    validRecat = () => {
        if (this.recaptchaRef.current.getValue()) {
            this.setState({
                estadoSolicitud: false
            })
        } else {
            this.setState({
                estadoSolicitud: true
            })
        }
    }

    componentDidMount() {
        if (this.props.idprodserv === "Ahorros") {
            this.setState({
                idprodserv: "1"
            })
        } else if (this.props.idprodserv === "Credito") {
            this.setState({
                idprodserv: "0"
            })
        }
        this.cargarInformacion();
    }

    cargarInformacion = () => {
        const data = {
            tran: 4,
            idredagencia: 0,
            idtipoagencia: 0,
            idmunicipio: 0,
            nombreagenc: 'NA',
            descagencia: 'NA',
            rutaiconomapa: 'NA',
            latitud: 'NA',
            longitud: 'NA',
            zoom: 0,
            estado: 0,
            usucrea: 'NA'
        }
        Agencias_(data).then(response => {
            try {
                if (response.data.length > 0) {
                    this.setState({
                        infoAgencias: response.data
                    })
                } else { }
            } catch (error) {
                console.log(error);
            }
        });
    }

    handleChangeOrigen = selectedOrigen => {
        this.setState({
            selectedOrigen: selectedOrigen
        })
    };

    textInput = (e) => {
        if (e.target.name === "infoadicional") {
            let regex = new RegExp("^[a-zA-Z0-9 ]+$");
            if (regex.test(e.target.value) || e.target.value === "") {
                this.setState({
                    [e.target.name]: e.target.value
                });
            }
        } else if (e.target.name === "telcel") {
            let regex = new RegExp("^[0-9]+$");
            if (regex.test(e.target.value) || e.target.value === "") {
                this.setState({
                    [e.target.name]: e.target.value
                });
            }
        } else if (e.target.name === "nit") {
            let regex = new RegExp("^[a-zA-Z0-9]+$");
            if (regex.test(e.target.value) || e.target.value === "") {
                this.setState({
                    [e.target.name]: e.target.value
                });
            }
        } else {
            this.setState({
                [e.target.name]: e.target.value
            })
        }
    }

    activeButton = () => {
        this.setState({
            estadoSolicitud: !this.state.estadoSolicitud
        })
    }

    formatearMiles = (e) => {
        var num = e.target.value.replace(/\./g, '');
        if (e.target.value === "") {
            this.setState({
                stateDollar: false
            })
        } else {
            this.setState({
                stateDollar: true
            })
        }
        if (!isNaN(num)) {
            num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.');
            num = num.split('').reverse().join('').replace(/^[.]/, '');
            this.setState({
                montoreq: num
            })
        }
        else {
            this.setState({
                montoreq: e.target.value.replace(/[^\d.]*/g, '')
            })
        }
    }

    sendForm = () => {
        const { errors, ...sinErrors } = this.state;
        const result = validate(sinErrors);
        this.setState({ errors: result });
        if (!Object.keys(result).length) {
            // formulario validado
            this.setState({
                stateLoadign: true
            })
            const data = {
                idprodserv: parseInt(this.state.idprodserv),
                infoadicional: this.state.infoadicional === undefined ? 'N/A' : this.state.infoadicional,
                tipoiden: this.state.tipoiden,
                nit: this.state.nit,
                nombres: this.state.nombres,
                apellidos: this.state.apellidos,
                asociado: parseInt(this.state.asociado),
                montoreq: this.state.montoreq === '' ? 0 : parseFloat(this.state.montoreq.replace(/\./g, '')),
                correo: this.state.correo,
                telcel: this.state.telcel,
                codage: parseInt(this.state.selectedOrigen.value),
                codocupacion: parseInt(this.state.codocupacion),
                otraocupacion: this.state.otraocupacion
            }
            Asesoria_(data).then(response => {
                try {
                    this.setState({
                        stateLoadign: false
                    })
                    if (response[0] === "000000") {
                        var checkBox = document.querySelector("#checkBoxa");
                        checkBox.checked = false;
                        this.setState({
                            idprodserv: 'DEFAULT',
                            infoadicional: '',
                            tipoiden: 'DEFAULT',
                            nit: '',
                            nombres: '',
                            apellidos: '',
                            asociado: 'DEFAULT',
                            montoreq: '',
                            correo: '',
                            selectedOrigen: 'DEFAULT',
                            confirmarcorreo: '',
                            telcel: '',
                            codage: null,
                            codocupacion: 'DEFAULT',
                            otraocupacion: '',
                            estadoSolicitud: true,
                            stateDollar: false
                        })
                        Swal.fire({
                            icon: 'success',
                            text: response[1]
                        })

                    } else {
                        Swal.fire({
                            icon: 'error',
                            text: response[1]
                        })
                    }
                } catch (error) {
                    console.log(error);
                }
            });
        }
    }

    render() {
        const { errors } = this.state;
        const municipioLista = this.state.infoAgencias.map((e) => ({
            label: e.nombreagenc,
            value: e.codageutra
        }))
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-6">
                        <p className="font-weight-bold mb-0 pb-0">Nombres <b className="text-danger">*</b></p>
                        <MDBInput maxLength="150" name="nombres" value={this.state.nombres} label="Ingrese sus nombres" type="text" className="mt-0" onChange={this.textInput} />
                        {errors.nombres && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '75px' }}>{errors.nombres}</strong>}
                    </div>
                    <div className="col-md-6">
                        <p className="font-weight-bold mb-0 pb-0">Apellidos <b className="text-danger">*</b></p>
                        <MDBInput maxLength="150" name="apellidos" value={this.state.apellidos} label="Ingrese sus apellidos" type="text" className="mt-0" onChange={this.textInput} />
                        {errors.apellidos && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '75px' }}>{errors.apellidos}</strong>}
                    </div>
                    <div className="col-md-6">
                        <p className="font-weight-bold mb-0 pb-0">Tipo de Documento <b className="text-danger">*</b></p>
                        <select name="tipoiden" className="form-control mt-2" value={this.state.tipoiden} onChange={this.textInput}>
                            <option value={'DEFAULT'} disabled>Seleccione...</option>
                            <option value="C">Cédula de Ciudadania</option>
                            <option value="E">Cédula de Extranjería</option>
                            <option value="N">NIT</option>
                            <option value="P">Pasaporte</option>
                        </select>
                        {errors.tipoiden && <strong className="text-danger" style={{ fontSize: '13px' }}>{errors.tipoiden}</strong>}
                    </div>
                    <div className="col-md-6 mt-2">
                        <p className="font-weight-bold mb-0 pb-0">Número de Documento de Identidad <b className="text-danger">*</b></p>
                        <MDBInput maxLength="20" name="nit" value={this.state.nit} label="Ingrese su número de documento" type="text" className="mt-0" onChange={this.textInput} />
                        {errors.nit && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '75px' }}>{errors.nit}</strong>}
                    </div>
                    <div className={this.state.idprodserv === "0" ? "col-md-4" : "col-md-6"}>
                        <p className="font-weight-bold mb-0 pb-0">¿Es asociado a Utrahuilca? <b className="text-danger">*</b></p>
                        <select name="asociado" className="form-control mt-2" value={this.state.asociado} onChange={this.textInput}>
                            <option value={'DEFAULT'} disabled>Seleccione...</option>
                            <option value="1">SI</option>
                            <option value="0">NO</option>
                        </select>
                        {errors.asociado && <strong className="text-danger" style={{ fontSize: '13px' }}>{errors.asociado}</strong>}
                    </div>
                    <div className={this.state.idprodserv === "0" ? "col-md-4 mt-2" : "col-md-6 mt-2"}>
                        <p className="font-weight-bold mb-0 pb-0">Servicio del cual requiere asesoría <b className="text-danger">*</b></p>
                        <select name="idprodserv" className="form-control mt-2" value={this.state.idprodserv} onChange={this.textInput}>
                            <option value={'DEFAULT'} disabled>Seleccione...</option>
                            <option value="0">Crédito</option>
                            <option value="1">Ahorro</option>
                            <option value="2">Vinculación</option>
                        </select>
                        {errors.idprodserv && <strong className="text-danger" style={{ fontSize: '13px' }}>{errors.idprodserv}</strong>}
                    </div>
                    {this.state.idprodserv === "0" ? (
                        <div className="col-md-4">
                            <p className="font-weight-bold mb-0 pb-0">Monto de Dinero que requiere</p>
                            <MDBInput label="$ Monto a solicitar" onKeyUp={this.formatearMiles} name="montoreq" value={this.state.montoreq} onChange={this.formatearMiles} className="pl-4" />{this.state.stateDollar ? <MDBIcon icon="dollar-sign" style={{ position: 'absolute', left: '18px', marginTop: '-67px' }} /> : <div></div>}
                        </div>
                    ) : (<React.Fragment></React.Fragment>)}
                    <div className="col-md-12 pt-3">
                        <p className="font-weight-bold mb-0 pb-0">Descripción de la asesoría (opcional)</p>
                        <MDBInput name="infoadicional" value={this.state.infoadicional} type="textarea" rows="5" className="mt-0" onChange={this.textInput} />
                    </div>
                    <div className="col-md-6 pt-3">
                        <p className="font-weight-bold mb-0 pb-0">Correo Electrónico <b className="text-danger">*</b></p>
                        <MDBInput maxLength="150" name="correo" value={this.state.correo} label="Ingrese su correo" type="email" className="mt-0" onChange={this.textInput} />
                        {errors.correo && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '85px' }}>{errors.correo}</strong>}
                    </div>
                    <div className="col-md-6 pt-3">
                        <p className="font-weight-bold mb-0 pb-0">Confirmar Correo Electrónico <b className="text-danger">*</b></p>
                        <MDBInput maxLength="150" name="confirmarcorreo" value={this.state.confirmarcorreo} label="Confirmar correo" type="email" className="mt-0" onChange={this.textInput} />
                        {errors.confirmarcorreo && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '85px' }}>{errors.confirmarcorreo}</strong>}
                    </div>
                    <div className="col-md-6">
                        <p className="font-weight-bold mb-0 pb-0">Teléfono Celular <b className="text-danger">*</b></p>
                        <MDBInput maxLength="20" name="telcel" value={this.state.telcel} label="Ingrese su número" type="text" className="mt-0" onChange={this.textInput} />
                        {errors.telcel && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '75px' }}>{errors.telcel}</strong>}
                    </div>
                    <div className="col-md-6">
                        <p className="font-weight-bold mb-0 pb-0">Agencia más cercana <b className="text-danger">*</b></p>
                        <Select
                            className="font-weight-normal"
                            placeholder="Seleccione..."
                            name="selectedOrigen"
                            value={this.state.selectedOrigen}
                            onChange={this.handleChangeOrigen}
                            options={municipioLista}
                            noOptionsMessage={() => 'Sin resultados!'}
                        />
                        {errors.selectedOrigen && <strong className="text-danger" style={{ fontSize: '13px' }}>{errors.selectedOrigen}</strong>}
                    </div>
                    <div className="col-md-6 mt-2">
                        <p className="font-weight-bold mb-0 pb-0">Ocupación <b className="text-danger">*</b></p>
                        <select name="codocupacion" className="form-control mt-2" value={this.state.codocupacion} onChange={this.textInput}>
                            <option value={'DEFAULT'} disabled>Seleccione...</option>
                            <option value="1">Empleado</option>
                            <option value="2">Cesante</option>
                            <option value="3">Dependiente Economico</option>
                            <option value="4">Independiente</option>
                            <option value="6">Pensionado</option>
                            <option value="7">Otro</option>
                        </select>
                        {errors.codocupacion && <strong className="text-danger" style={{ fontSize: '13px' }}>{errors.codocupacion}</strong>}
                    </div>
                    {this.state.codocupacion === "6" ?
                        (
                            <div className="col-md-6">
                                <p className="font-weight-bold mb-0 pb-0">Otra</p>
                                <MDBInput maxLength="150" name="otraocupacion" value={this.state.otraocupacion} label="Ingrese otra ocupación" type="text" className="mt-0" onChange={this.textInput} />
                            </div>
                        ) : (<React.Fragment></React.Fragment>)}
                </div>
                <div className="terminos">
                    <div className="pt-4">
                        <p className="font-weight-bold">Términos y condiciones</p>
                        <p className="text-justify">Términos y condiciones: Autorizo a la Cooperativa latinoamericana de ahorro y Crédito UTRAHUILCA con domicilio principal en la Cra 6 # 5-37 Oficina Principal Neiva - Huila, Colombia, teléfono: 8728181 Ext:101, en su calidad de responsable del tratamiento de datos personales para recolectar, almacenar, procesar, actualizar, modificar y eliminar los datos suministrados con el fin de realizar la gestión de trámites relacionados con la presente solicitud. En cumplimiento de la Ley 1581 de 2012 y Decreto 1377 de 2013, declaro que he sido informado de manera clara y expresa las finalidades para las cuales se recopilan mis datos, así mismo que conozco el derecho a conocer, actualizar, corregir y suprimir la información y revocar la autorización. Para mayor información puedo consultar la Política de Tratamiento de Datos Personales de la Cooperativa latinoamericana de ahorro y Crédito UTRAHUILCA que se encuentra en la página web. http://www.utrahuilca.coop/</p>
                        <hr className="bg-dark" />
                        <div className="pt-1 d-flex align-items-center">
                            <input type="checkbox" id="checkBoxa" onClick={() => { this.setState({ checkbox: !this.state.checkbox }) }} />&nbsp;<span className="font-weight-bold mt-0 pt-0 "> Acepto términos y condiciones </span>
                        </div>
                        {errors.checkbox && <strong className="text-danger" style={{ fontSize: '13px' }}>{errors.checkbox}</strong>}
                        <div className="pt-3 justify-content-center  align-items-center  d-flex">
                            <form onSubmit={this.onSubmit} >
                                <ReCAPTCHA
                                    ref={this.recaptchaRef}
                                    sitekey="6LfTTPgbAAAAAOKQp-b36jm-sJGpLIKw8cnZTzu-"
                                    onChange={this.validRecat}
                                />
                            </form>
                        </div>
                        <div className="col-md-12 my-4 d-flex text-center justify-content-center">
                            <button className="btn px-5" onClick={this.sendForm} disabled={this.state.estadoSolicitud} style={{ background: '' + this.props.color + '', color: 'white', borderRadius: '20px' }}>Enviar Solicitud</button>
                        </div>
                    </div>
                </div>
                {this.state.stateLoadign ? (
                    <Loading />
                ) : (<React.Fragment></React.Fragment>)}
            </React.Fragment>
        );
    }
}

export default FormAsesoria;