import React, { Component } from 'react';
import Swal from 'sweetalert2';
import { URLImgFront } from '../../config/URLDB';
import { ComunicadosDetalles_ } from '../../services/WebAPI_Utrahuilca';

class Seccion2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataCom: [],
            stateData: false
        };
    }

    async componentDidMount() {
        const data = {
            tran: 7,
            idcomunicado: 0,
            codcomunicado: 1,
            rutaimgcomunic: 'NA',
            titulocomunic: 'NA',
            desccomunic: 'NA',
            rutalinkpdf: 'NA',
            estado: 1,
            usucrea: 'NA'
        }
        ComunicadosDetalles_(data).then(response => {
            try {
                if (response.data.length > 0) {
                    if (response.data[0].message === "004404") {
                        this.setState({
                            stateData: false
                        });
                    } else {
                        this.setState({
                            dataCom: response.data,
                            stateData: true
                        });
                    }
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Ocurrio un error!'
                    })
                }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    text: 'Ocurrio un error al consumir el servicio, comuniquese con sistemas!'
                })
            }
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className="diseño_seccion2_com" style={{ width: '100%' }}>
                    <div className="container-fluid py-5">
                        {this.state.stateData ? (
                            <React.Fragment>
                                <div className="row px-3">
                                    {this.state.dataCom.map((e, i) => {
                                        return [
                                            <React.Fragment key={i}>
                                                <div className="col-md-12 my-3">
                                                    <div className="row">
                                                        <div className="col-md-6 pl-0 pr-0">
                                                            <img style={{ width: '100%', height: '200px', objectFit: 'cover' }} src={`${URLImgFront}/assets${e.rutaimgcomunic}`} alt={e.rutaimgcomunic} />
                                                        </div>
                                                        <div className="col-md-6 pt-5 pb-5 pb-3 text-dark" style={{ background: '#f7f7f7' }}>
                                                            <p className="pl-5 my-0 text-left" style={{ fontSize: '20px' }}>{e.titulocomunic}</p>
                                                            <p className="pl-5 my-0 text-left" style={{ fontSize: '20px' }}>{e.desccomunic}</p>
                                                            <a rel="noopener noreferrer" target="_blank" href={`${URLImgFront}/assets/Archivos/${e.rutalinkpdf}`} download={e.rutalinkpdf}><span className="px-4 py-1 mt-3 btn_opcion" style={{ background: '#FA7500', color: 'white', fontWeight: 'bold', borderRadius: '20px', cursor: 'pointer', position: 'absolute', right: '40px', top: '30px' }}>Descargar</span></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        ]
                                    })}
                                </div>
                            </React.Fragment>
                        ) : (
                            <React.Fragment></React.Fragment>
                        )}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Seccion2;