import React, { Component } from 'react';
import CasosExito from './Coopsejos/CasosExito';
import Eventos from './Coopsejos/Eventos';
import ImagenTexto from './Coopsejos/ImagenTexto';
import Noticias from './Coopsejos/Noticias';
import './styles.css';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

class Coopsejos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stateSelect: '2'
        };
    }

    selectOption = (id) => {
        this.setState({
            stateSelect: id
        })
    }

    render() {
        return (
            <React.Fragment>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography style={{ fontSize: '18px' }}>Coopsejos Imagen Principal</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="w-100">
                            <ImagenTexto />
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography style={{ fontSize: '18px' }}>Coopsejos (Eventos, Noticias, Casos de Exito)</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="w-100">
                            <div className="d-flex justify-content-center">
                                <ul className="ul-coopsejos">
                                    <li onClick={() => { this.selectOption("1") }} style={this.state.stateSelect === "1" ? { background: '#FA7500', color: '#fff' } : { background: '#fff', color: '#000' }}>Eventos</li>
                                    <li onClick={() => { this.selectOption("2") }} style={this.state.stateSelect === "2" ? { background: '#FA7500', color: '#fff' } : { background: '#fff', color: '#000' }}>Noticias</li>
                                    <li onClick={() => { this.selectOption("3") }} style={this.state.stateSelect === "3" ? { background: '#FA7500', color: '#fff' } : { background: '#fff', color: '#000' }}>Casos de Exito</li>
                                </ul>
                            </div>
                            {this.state.stateSelect === "1" ? <Eventos /> : <div></div>}
                            {this.state.stateSelect === "2" ? <Noticias /> : <div></div>}
                            {this.state.stateSelect === "3" ? <CasosExito /> : <div></div>}
                        </div>
                    </AccordionDetails>
                </Accordion>
            </React.Fragment>
        );
    }
}

export default Coopsejos;