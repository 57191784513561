import { MDBIcon, MDBInput, MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import React, { Component } from 'react';
import { CargueDocumentos, CargueImagenes, ConvenioDetalles_, GrabConveniosDetalle_ } from '../../../services/WebAPI_Utrahuilca';
import FileUploaderB from './ConveniosDetalles/FileUploaderB';
import FileUploader from './ConveniosDetalles/FileUploader';
import Swal from 'sweetalert2';
import { URLImgFront } from '../../../config/URLDB';

const validate = values => {
    const errors = {}
    if (!values.titconvenio1) {
        errors.titconvenio1 = 'campo obligatorio'
    }
    if (!values.descconvenio1) {
        errors.descconvenio1 = 'campo obligatorio'
    }
    if (!values.titconvenio2) {
        errors.titconvenio2 = 'campo obligatorio'
    }
    if (!values.descconvenio2) {
        errors.descconvenio2 = 'campo obligatorio'
    }
    
    if (values.idtipopers === 'DEFAULT') {
        errors.idtipopers = 'campo obligatorio'
    }

    if (values.tiporegistro === 'DEFAULT') {
        errors.tiporegistro = 'Campo Obligagorio'
    }
    else {
        if (values.tiporegistro === '0') {
            if (values.selectedFile === null) {
                errors.selectedFile = 'Campo Obligagorio'
            }
        }
        else {
            if (!values.descinfoconvenio) {
                errors.descinfoconvenio = 'Campo Obligagorio'
            }
        }
    }
    return errors;
};

class ConveniosDetalles extends Component {
    uploader = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            estadoNuevo: false,
            errors: {},
            estadoCambioImg1: true,
            idtipopers: 'DEFAULT',
            estadoSinInfo: false,
            infoConveniosDetalles: [],
            idconveniodet: null,
            rutaimgconven: '',
            titconvenio1: '',
            descconvenio1: '',
            titconvenio2: '',
            descconvenio2: '',
            tiporegistro: 'DEFAULT',
            descinfoconvenio: '',
            estadoCambio: false,
            estado: null,
            selectedFile: null
        };
    }

    async componentDidMount() {
        this.cargarInformacion();
    }

    cargarInformacion = () => {
        const data = {
            tran: 4,
            idconveniodet: 0,
            idtipopers: 0,
            rutaimgconven: 'NA',
            titconvenio1: 'NA',
            descconvenio1: 'NA',
            titconvenio2: 'NA',
            descconvenio2: 'NA',
            tiporegistro: 0,
            rutaarchiconven: 'NA',
            descinfoconvenio: 'NA',
            estado: 0,
            usucrea: 'NA'
        }
        ConvenioDetalles_(data).then(response => {
            try {
                if (response.data.length > 0) {
                    if (!response.data[0].message) {
                        this.setState({
                            idSeleccionado: response.data[0].idconveniodet
                        })
                        setTimeout(() => {
                            this.setState({
                                infoConveniosDetalles: response.data,
                                rutaimgconven: response.data[0].rutaimgconven,
                                estado: response.data[0].estado,
                                estadoSinInfo: true,
                                estadoImagen: true,
                            })
                        }, 120);
                    } else {
                        this.setState({
                            estadoSinResultados: false,
                            estadoNuevo: true,
                            estadoCambioImg1: true,
                            estadoSinInfo: false
                        })
                    }
                } else { }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    text: 'Ocurrio un error al consumir el servicio, comuniquese con sistemas!'
                })
            }
        });
    }

    textInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    cambioImagen1 = (name, file) => {
        this.setState({
            estadoCambioImg1: true
        });
    }

    cargarImagen = () => {
        var formData = new FormData();
        if (undefined === this.uploader.current) return;
        formData.append("file", this.uploader.current.getFileObject());
        formData.append("rutaImagen", '/Convenios/Convenio Detalles/' + this.uploader.current.getFileName());
        CargueImagenes(formData).then(response => {
            if (response) {
                this.cargarInformacion();
            }
        });
    }

    cargarArchivos = () => {
        var formData = new FormData();
        if (undefined === this.state.selectedFile || null === this.state.selectedFile) return;
        formData.append("file", this.state.selectedFile, this.state.selectedFile.name);
        formData.append("rutaImagen", '/Archivos/' + this.state.selectedFile.name);
        CargueDocumentos(formData).then(response => {
            if (response) {
                this.cargarInformacion();
            }
        });
    }

    insertarDatos = () => {
        const { errors, ...sinErrors } = this.state;
        const result = validate(sinErrors);
        this.setState({ errors: result });
        if (!Object.keys(result).length) {
            // formulario validado
            const data = {
                tran: 1,
                idconveniodet: 0,
                idtipopers: parseInt(this.state.idtipopers),
                rutaimgconven: '/Convenios/Convenio Detalles/' + this.uploader.current.getFileName(),
                titconvenio1: this.state.titconvenio1,
                descconvenio1: this.state.descconvenio1,
                titconvenio2: this.state.titconvenio2,
                descconvenio2: this.state.descconvenio2,
                tiporegistro: this.state.tiporegistro,
                rutaarchiconven: this.state.tiporegistro == "0" ? this.state.selectedFile.name : 'NA',
                descinfoconvenio: this.state.tiporegistro == "1" ? this.state.descinfoconvenio : 'NA',
                estado: 1,
                usucrea: sessionStorage.getItem('idusuario')
            }
            GrabConveniosDetalle_(data).then(response => {
                if (response.data.length > 0) {
                    if (response.data[0].message === "000000") {
                        this.cargarImagen();
                        this.cargarArchivos();
                        Swal.fire({
                            icon: 'success',
                            title: 'Se agrego correctamente.'
                        })
                        this.setState({
                            estadoNuevo: false,
                            idconveniodet: null,
                            idtipopers: 'DEFAULT',
                            rutaimgconven: '',
                            titconvenio1: '',
                            descconvenio1: '',
                            titconvenio2: '',
                            descconvenio2: '',
                            tiporegistro: 'DEFAULT',
                            descinfoconvenio: '',
                            estado: null,
                            estadoImagen: false,
                            displayColorPicker: false,
                            rutafondoImg: '',
                            color: '',
                            selectedFile: null
                        })
                        this.cargarInformacion();
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error al insertar.'
                        })
                    }
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Ocurrio un error!'
                    })
                }
            });
        }
    }

    editar = (id) => {
        var seleccionado = [];
        this.state.infoConveniosDetalles.map((e, i) => {
            if (e.idconveniodet === id) {
                seleccionado.push(e)
            }
            return [];
        })
        this.setState({
            idconveniodet: seleccionado[0].idconveniodet,
            idtipopers: seleccionado[0].idtipopers,
            rutaarchiconven: seleccionado[0].rutaarchiconven,
            titconvenio1: seleccionado[0].titconvenio1,
            descconvenio1: seleccionado[0].descconvenio1,
            titconvenio2: seleccionado[0].titconvenio2,
            descconvenio2: seleccionado[0].descconvenio2,
            tiporegistro: seleccionado[0].tiporegistro,
            rutaimgconven: seleccionado[0].rutaimgconven,
            descinfoconvenio: seleccionado[0].descinfoconvenio,
            estadoEditar: true
        })
    }

    cambio = (name, file) => {
        this.setState({
            estadoCambio: true
        });
    }

    onFileChange = event => {
        this.setState({ selectedFile: event.target.files[0] });
    };

    actualizarDatos = (id) => {
        var ruta = '';
        var capNombre = this.uploader.current.getFileName();
        if (capNombre === undefined || capNombre === null) {
            ruta = this.state.rutaimgconven;
        } else {
            ruta = '/Convenios/Convenio Detalles/' + this.uploader.current.getFileName();
        }
        var rutaPDF = '';
        if (this.state.selectedFile === undefined || this.state.selectedFile === null) {
            rutaPDF = this.state.rutaarchiconven;
        } else {
            rutaPDF = this.state.selectedFile.name;
        }
        const data = {
            tran: 1,
            idconveniodet: id,
            idtipopers: parseInt(this.state.idtipopers),
            rutaimgconven: ruta,
            titconvenio1: this.state.titconvenio1,
            descconvenio1: this.state.descconvenio1,
            titconvenio2: this.state.titconvenio2,
            descconvenio2: this.state.descconvenio2,
            tiporegistro: this.state.tiporegistro,
            rutaarchiconven: this.state.tiporegistro == "0" ? rutaPDF : 'NA',
            descinfoconvenio: this.state.tiporegistro == "1" ? this.state.descinfoconvenio : 'NA',
            estado: 1,
            usucrea: sessionStorage.getItem('idusuario')
        }
        GrabConveniosDetalle_(data).then(response => {
            if (response.data.length > 0) {
                if (response.data[0].message === "000000") {
                    this.cargarImagen();
                        this.cargarArchivos();
                    this.setState({
                        estadoCambio: false,
                        estadoEditar: false,
                        rutaimgconven: '',
                        titconvenio1: '',
                        descconvenio1: '',
                        titconvenio2: '',
                        descconvenio2: 'DEFAULT',
                        tiporegistro: 'DEFAULT',
                        descinfoconvenio: '',
                        selectedFile: null
                    })
                    Swal.fire({
                        icon: 'success',
                        title: 'Se actualizo satisfactoriamente'
                    })
                    this.cargarInformacion();
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Ocurrio un error al actualizar'
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Ocurrio un error!'
                })
            }
        });
    }

    activar = (id) => {
        const data = {
            tran: 6,
            idconveniodet: id,
            idtipopers: 0,
            rutaimgconven: 'NA',
            titconvenio1: 'NA',
            descconvenio1: 'NA',
            titconvenio2: 'NA',
            descconvenio2: 'NA',
            tiporegistro: 0,
            rutaarchiconven: 'NA',
            descinfoconvenio: 'NA',
            estado: 1,
            usucrea: sessionStorage.getItem('idusuario')
        }
        ConvenioDetalles_(data).then(response => {
            if (response) {
                this.setState({
                    estadoS: false
                })
                Swal.fire({
                    icon: 'success',
                    title: 'Se activo satisfactoriamente'
                });
                this.cargarInformacion();
            }
        });
    }

    inactivar = (id) => {
        const data = {
            tran: 6,
            idconveniodet: id,
            idtipopers: 0,
            rutaimgconven: 'NA',
            titconvenio1: 'NA',
            descconvenio1: 'NA',
            titconvenio2: 'NA',
            descconvenio2: 'NA',
            tiporegistro: 0,
            rutaarchiconven: 'NA',
            descinfoconvenio: 'NA',
            estado: 0,
            usucrea: sessionStorage.getItem('idusuario')
        }
        ConvenioDetalles_(data).then(response => {
            if (response) {
                this.setState({
                    estadoS: false
                })
                Swal.fire({
                    icon: 'success',
                    title: 'Se inactivo satisfactoriamente'
                });
                this.cargarInformacion();
            }
        });
    }

    handleNew = () => {
        this.setState({
            estadoNuevo: !this.state.estadoNuevo,
            errors: {},
            rutaimgconven: '',
            titconvenio1: '',
            descconvenio1: '',
            titconvenio2: '',
            descconvenio2: '',
            tiporegistro: 'DEFAULT',
            descinfoconvenio: '',
            idtipopers: 'DEFAULT',
            selectedFile: null
        })
        if (this.state.estadoNuevo) {
            this.setState({
                errors: {},
                rutaimgconven: '',
                titconvenio1: '',
                descconvenio1: '',
                titconvenio2: '',
                descconvenio2: '',
                tiporegistro: 'DEFAULT',
                descinfoconvenio: '',
                idtipopers: 'DEFAULT',
                selectedFile: null
            })
            this.cargarInformacion();
        }
    }

    render() {
        const { estadoNuevo, estadoCambioImg1, estadoCambio, estadoSinInfo, titconvenio1, errors, descconvenio1, titconvenio2, descconvenio2, tiporegistro, descinfoconvenio, estadoEditar, idconveniodet, infoConveniosDetalles } = this.state;
        return (
            <React.Fragment>
                <p className="font-weight-bold"> Detalles Convenios </p>
                <hr />
                {estadoSinInfo ?
                    (
                        <React.Fragment>
                            <div className="plus_add_agencia align-items-center d-flex" onClick={() => { this.handleNew() }}>
                                {estadoNuevo ? <p>Cancelar &nbsp;<MDBIcon icon="times" size="lg" /></p> : <p>Agregar Nuevo &nbsp;<MDBIcon icon="plus-circle" size="lg" /></p>}
                            </div>
                        </React.Fragment>
                    ) : (
                        <React.Fragment></React.Fragment>
                    )
                }
                {estadoNuevo ?
                    (
                        <React.Fragment>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12 my-2 text-center">
                                        <FileUploaderB ref={this.uploader} estadoImg={estadoCambioImg1} FCambiar={this.cambioImagen1} />
                                    </div>
                                    <div className="col-md-6 my-2">
                                        <label>Tipo Persona</label>
                                        <select name="idtipopers" value={this.state.idtipopers} className="form-control" onChange={this.textInput}>
                                            <option value="DEFAULT" disabled>Seleccione...</option>
                                            <option value="1">Natural</option>
                                            <option value="0">Jurídica</option>
                                        </select>
                                        {errors.idtipopers && <strong className="text-danger" style={{ fontSize: '13px' }}>{errors.idtipopers}</strong>}
                                    </div>
                                    <div className="col-md-6 my-2">
                                        <MDBInput label="Titulo 1" name="titconvenio1" type="text" value={titconvenio1} onChange={this.textInput} />
                                        {errors.titconvenio1 && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '50px' }}>{errors.titconvenio1}</strong>}
                                    </div>
                                    <div className="col-md-6 my-2">
                                        <MDBInput label="Descripción 1" name="descconvenio1" type="textarea" value={descconvenio1} onChange={this.textInput} />
                                        {errors.descconvenio1 && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '60px' }}>{errors.descconvenio1}</strong>}
                                    </div>
                                    <div className="col-md-6 my-2">
                                        <MDBInput label="Titulo 2" name="titconvenio2" type="text" value={titconvenio2} onChange={this.textInput} />
                                        {errors.titconvenio2 && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '50px' }}>{errors.titconvenio2}</strong>}
                                    </div>
                                    <div className="col-md-6 my-2">
                                        <MDBInput label="Descripción 2" name="descconvenio2" type="textarea" value={descconvenio2} onChange={this.textInput} />
                                        {errors.descconvenio2 && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '60px' }}>{errors.descconvenio2}</strong>}
                                    </div>
                                    <div className="col-md-6 my-2">
                                        <label>Tipo Registro</label>
                                        <select name="tiporegistro" className="form-control" value={tiporegistro} onChange={this.textInput}>
                                            <option value="DEFAULT" disabled> Seleccione..</option>
                                            <option value="0">PDF</option>
                                            <option value="1">TEXTO</option>
                                        </select>
                                        {errors.tiporegistro && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '75px' }}>{errors.tiporegistro}</strong>}
                                    </div>
                                    <div className="col-md-12 my-2">
                                        {this.state.tiporegistro == "0" || this.state.tiporegistro === "DEFAULT" ?
                                            (
                                                <React.Fragment>
                                                    <input type="file" onChange={this.onFileChange} />
                                                    {errors.selectedFile && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '35px', left: '17px' }}>{errors.selectedFile}</strong>}
                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment>
                                                    <label>Descripción del Convenio</label>
                                                    <textarea className="form-control" type="textarea" rows="8" name="descinfoconvenio" value={descinfoconvenio} onChange={this.textInput} />
                                                </React.Fragment>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="pt-2 mt-5 modal-footer w-100">
                                <button type="button" className="btn btn-primary px-5" onClick={this.insertarDatos}>Crear Producto &nbsp; <MDBIcon icon="save" /></button>
                            </div>
                        </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <div className="container-fluid">
                                    <div className="table-responsive text-center w-100">
                                        <MDBTable>
                                            <MDBTableHead>
                                                <tr className="font-weight-bold">
                                                    <td>Imagen</td>
                                                    <td>Tipo Persona</td>
                                                    <td>Titulo 1</td>
                                                    <td>Descripción 1</td>
                                                    <td>Titulo 2</td>
                                                    <td>Descripción 2</td>
                                                    <td>Tipo Registro</td>
                                                    <td>Descripción Registro</td>
                                                    {estadoEditar ?
                                                        (
                                                            <React.Fragment>
                                                                <td>Guardar</td>
                                                                <td>Cancelar</td>
                                                            </React.Fragment>
                                                        ) : (
                                                            <React.Fragment>
                                                                <td>Editar</td>
                                                                <td>Inactivar</td>
                                                            </React.Fragment>
                                                        )
                                                    }
                                                </tr>
                                            </MDBTableHead>
                                            <MDBTableBody>
                                                {estadoEditar ?
                                                    (
                                                        <React.Fragment>
                                                            <tr>
                                                                <td>
                                                                    <FileUploader ref={this.uploader} estadoImg={estadoCambio} ruta={this.state.rutaimgconven} FCambiar={this.cambio} />
                                                                </td>
                                                                <td>
                                                                    <select name="idtipopers" value={this.state.idtipopers} className="form-control" onChange={this.textInput}>
                                                                        <option value="DEFAULT" disabled>Seleccione...</option>
                                                                        <option value="1">Natural</option>
                                                                        <option value="0">Jurídica</option>
                                                                    </select>
                                                                </td>
                                                                <td className="w-25"><textarea className="form-control" type="textarea" rows="5" name="titconvenio1" value={this.state.titconvenio1} onChange={this.textInput} /></td>
                                                                <td className="w-25"><textarea className="form-control" type="textarea" rows="5" name="descconvenio1" value={this.state.descconvenio1} onChange={this.textInput} /></td>
                                                                <td className="w-25"><textarea className="form-control" type="textarea" rows="5" name="titconvenio2" value={this.state.titconvenio2} onChange={this.textInput} /></td>
                                                                <td className="w-25"><textarea className="form-control" type="textarea" rows="5" name="descconvenio2" value={this.state.descconvenio2} onChange={this.textInput} /></td>
                                                                <td className="w-25">
                                                                    <select name="tiporegistro" className="form-control" value={this.state.tiporegistro} onChange={this.textInput}>
                                                                        <option value="DEFAULT" disabled> Seleccione..</option>
                                                                        <option value="0">PDF</option>
                                                                        <option value="1">TEXTO</option>
                                                                    </select>
                                                                </td>
                                                                <td className="w-25">
                                                                    {this.state.tiporegistro == "0" || this.state.tiporegistro === "DEFAULT" ?
                                                                        (
                                                                            <React.Fragment>
                                                                                <p>{this.state.rutaarchiconven}</p><hr />
                                                                                <input type="file" onChange={this.onFileChange} />
                                                                            </React.Fragment>
                                                                        ) : (
                                                                        <React.Fragment>
                                                                            <p><textarea className="form-control" style={{ width: '200px' }} type="textarea" rows="6" name="descinfoconvenio" value={this.state.descinfoconvenio} onChange={this.textInput} /></p>
                                                                            </React.Fragment>
                                                                        )
                                                                    }
                                                                </td>
                                                                <td className="text-center" onClick={() => { this.actualizarDatos(idconveniodet) }}><MDBIcon icon="save" size="2x" style={{ color: 'blue', cursor: 'pointer' }} /></td>
                                                                <td className="text-center" onClick={() => { this.setState({ estadoEditar: !this.state.estadoEditar }) }}><MDBIcon icon="times" size="2x" style={{ color: 'red', cursor: 'pointer' }} /></td>
                                                            </tr>
                                                        </React.Fragment>
                                                    ) : (
                                                        <React.Fragment>
                                                            {infoConveniosDetalles.map((e, i) => {
                                                                let view;
                                                                if (e.estado === 1) {
                                                                    view = <MDBIcon onClick={() => { this.inactivar(e.idconveniodet) }} icon="eye" size="2x" style={{ cursor: 'pointer' }} />
                                                                } else {
                                                                    view = <MDBIcon onClick={() => { this.activar(e.idconveniodet) }} icon="eye-slash" size="2x" style={{ cursor: 'pointer' }} />
                                                                }
                                                                return [
                                                                    <React.Fragment key={i}>
                                                                        <tr>
                                                                            <td><img width="180" src={`${URLImgFront}/assets${e.rutaimgconven}`} alt={e.rutaimgconven} /></td>
                                                                            <td>{e.idtipopers === 1 ? 'Natural' : 'Jurídica'}</td>
                                                                            <td>{e.titconvenio1}</td>
                                                                            <td>{e.descconvenio1}</td>
                                                                            <td>{e.titconvenio2}</td>
                                                                            <td>{e.descconvenio2}</td>
                                                                            <td>{e.tiporegistro === 1 ? 'TEXTO' : 'PDF'}</td>
                                                                            <td>{e.tiporegistro === 1 ? '.....' :  e.rutaarchiconven }</td>
                                                                            <td className="text-center" onClick={() => { this.editar(e.idconveniodet) }}><MDBIcon icon="edit" size="2x" style={{ color: 'green', cursor: 'pointer' }} /></td>
                                                                            <td className="text-center">{view}</td>
                                                                        </tr>
                                                                    </React.Fragment>
                                                                ]
                                                            })}
                                                        </React.Fragment>
                                                    )
                                                }
                                            </MDBTableBody>
                                        </MDBTable>
                                    </div>
                                </div>
                        </React.Fragment>
                    )
                }
            </React.Fragment>
        );
    }
}

export default ConveniosDetalles;