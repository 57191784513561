import React, { Component } from 'react';
import { MDBIcon, MDBInput, MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import Swal from 'sweetalert2';
import { URLImgFront } from '../../../../config/URLDB';
import { CargueImagenes, CasosDeExito_ } from '../../../../services/WebAPI_Utrahuilca';
import FileUploaderB from './FileUploader/FileUploaderB';
import FileUploader from './FileUploader/FileUploader';

const validate = values => {
    const errors = {}
    if (values.prioridadcsdet === 'DEFAULT') {
        errors.prioridadcsdet = 'campo obligatorio'
    }
    if (values.redessocialescsdet === 'DEFAULT') {
        errors.redessocialescsdet = 'campo obligatorio'
    }
    if (!values.titulo1csdet) {
        errors.titulo1csdet = 'campo obligatorio'
    }
    if (!values.titulo2csdet) {
        errors.titulo2csdet = 'campo obligatorio'
    }
    if (!values.descripcioncsdet) {
        errors.descripcioncsdet = 'campo obligatorio'
    }
    return errors;
};

class CasosExito extends Component {
    uploader = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            estadoNuevo: false,
            errors: {},
            estadoCambioImg1: true,
            estadoSinInfo: false,
            infoCasosExito: [],
            imgprincipalcsdet: '',
            titulo1csdet: '',
            titulo2csdet: '',
            descripcioncsdet: '',
            redessocialescsdet: 'DEFAULT',
            estadoCambio: false,
            estado: null,
            selectedFile: null,
            urlcsdetface: '',
            urlcsdetyout: '',
            urlcsdetinst: '',
            urlwebcsdet: '',
            idcsdet: null,
            prioridadcsdet: 'DEFAULT'
        };
    }

    async componentDidMount() {
        this.cargarInformacion();
    }

    cargarInformacion = () => {
        const data = {
            tran: 4,
            idcsdet: 0,
            imgprincipalcsdet: 'NA',
            titulo1csdet: 'NA',
            titulo2csdet: 'NA',
            descripcioncsdet: 'NA',
            redessocialescsdet: 0,
            urlcsdetface: 'NA',
            urlcsdetyout: 'NA',
            urlcsdetinst: 'NA',
            urlwebcsdet: 'NA',
            prioridadcsdet: 0,
            estado: 0,
            usucrea: 'NA'
        }
        CasosDeExito_(data).then(response => {
            try {
                if (response.data.length > 0) {
                    if (!response.data[0].message) {
                        this.setState({
                            idSeleccionado: response.data[0].idcsdet
                        })
                        setTimeout(() => {
                            this.setState({
                                infoCasosExito: response.data,
                                estado: response.data[0].estado,
                                estadoSinInfo: true,
                                estadoImagen: true,
                            })
                        }, 120);
                    } else {
                        this.setState({
                            estadoSinResultados: false,
                            estadoNuevo: true,
                            estadoCambioImg1: true,
                            estadoSinInfo: false
                        })
                    }
                } else { }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    text: 'Ocurrio un error al consumir el servicio, comuniquese con sistemas!'
                })
            }
        });
    }

    textInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    cambioImagen1 = (name, file) => {
        this.setState({
            estadoCambioImg1: true
        });
    }

    cargarImagen = () => {
        var formData = new FormData();
        if (undefined === this.uploader.current) return;
        formData.append("file", this.uploader.current.getFileObject());
        formData.append("rutaImagen", '/Coopsejos/Casos de Exito/' + this.uploader.current.getFileName());
        CargueImagenes(formData).then(response => {
            if (response) {
                this.cargarInformacion();
            }
        });
    }

    insertarDatos = () => {
        const { errors, ...sinErrors } = this.state;
        const result = validate(sinErrors);
        this.setState({ errors: result });
        if (!Object.keys(result).length) {
            // formulario validado
            const data = {
                tran: 1,
                idcsdet: 0,
                imgprincipalcsdet: '/Coopsejos/Casos de Exito/' + this.uploader.current.getFileName(),
                titulo1csdet: this.state.titulo1csdet,
                titulo2csdet: this.state.titulo2csdet,
                descripcioncsdet: this.state.descripcioncsdet,
                redessocialescsdet: parseInt(this.state.redessocialescsdet),
                urlcsdetface: this.state.urlcsdetface === "" ? 'NA' : this.state.urlcsdetface,
                urlcsdetyout: this.state.urlcsdetyout === "" ? 'NA' : this.state.urlcsdetyout,
                urlcsdetinst: this.state.urlcsdetinst === "" ? 'NA' : this.state.urlcsdetinst,
                urlwebcsdet: this.state.urlwebcsdet === "" ? 'NA' : this.state.urlwebcsdet,
                prioridadcsdet: parseInt(this.state.prioridadcsdet),
                estado: 1,
                usucrea: sessionStorage.getItem('idusuario')
            }
            CasosDeExito_(data).then(response => {
                if (response.data.length > 0) {
                    if (response.data[0].message === "000000") {
                        this.cargarImagen();
                        Swal.fire({
                            icon: 'success',
                            title: 'Se agrego correctamente.'
                        })
                        this.setState({
                            estadoNuevo: false,
                            imgprincipalcsdet: '',
                            titulo1csdet: '',
                            titulo2csdet: '',
                            descripcioncsdet: '',
                            redessocialescsdet: 'DEFAULT',
                            prioridadcsdet: 'DEFAULT',
                            estado: null,
                            estadoImagen: false,
                            displayColorPicker: false,
                        })
                        this.cargarInformacion();
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error al insertar.'
                        })
                    }
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Ocurrio un error!'
                    })
                }
            });
        }
    }

    editar = (id) => {
        var seleccionado = [];
        this.state.infoCasosExito.map((e, i) => {
            if (e.idcsdet === id) {
                seleccionado.push(e)
            }
            return [];
        })
        this.setState({
            idcsdet: seleccionado[0].idcsdet,
            imgprincipalcsdet: seleccionado[0].imgprincipalcsdet,
            prioridadcsdet: seleccionado[0].prioridadcsdet,
            titulo1csdet: seleccionado[0].titulo1csdet,
            titulo2csdet: seleccionado[0].titulo2csdet,
            descripcioncsdet: seleccionado[0].descripcioncsdet,
            redessocialescsdet: seleccionado[0].redessocialescsdet,
            urlcsdetface: seleccionado[0].urlcsdetface,
            urlcsdetyout: seleccionado[0].urlcsdetyout,
            urlcsdetinst: seleccionado[0].urlcsdetinst,
            urlwebcsdet: seleccionado[0].urlwebcsdet,
            estadoEditar: true
        })
    }

    cambio = (name, file) => {
        this.setState({
            estadoCambio: true
        });
    }

    onFileChange = event => {
        this.setState({ selectedFile: event.target.files[0] });
    };

    actualizarDatos = (id) => {
        var ruta = '';
        var capNombre = this.uploader.current.getFileName();
        if (capNombre === undefined || capNombre === null) {
            ruta = this.state.imgprincipalcsdet;
        } else {
            ruta = '/Coopsejos/Casos de Exito/' + this.uploader.current.getFileName();
        }
        const data = {
            tran: 1,
            idcsdet: id,
            imgprincipalcsdet: ruta,
            titulo1csdet: this.state.titulo1csdet,
            titulo2csdet: this.state.titulo2csdet,
            descripcioncsdet: this.state.descripcioncsdet,
            redessocialescsdet: parseInt(this.state.redessocialescsdet),
            urlcsdetface: this.state.urlcsdetface === "" ? 'NA' : this.state.urlcsdetface,
            urlcsdetyout: this.state.urlcsdetyout === "" ? 'NA' : this.state.urlcsdetyout,
            urlcsdetinst: this.state.urlcsdetinst === "" ? 'NA' : this.state.urlcsdetinst,
            urlwebcsdet: this.state.urlwebcsdet === "" ? 'NA' : this.state.urlwebcsdet,
            prioridadcsdet: parseInt(this.state.prioridadcsdet),
            estado: 1,
            usucrea: sessionStorage.getItem('idusuario')
        }
        CasosDeExito_(data).then(response => {
            if (response.data.length > 0) {
                if (response.data[0].message === "000000") {
                    this.cargarImagen();
                    this.setState({
                        estadoCambio: false,
                        estadoEditar: false,
                        imgprincipalcsdet: '',
                        titulo1csdet: '',
                        titulo2csdet: '',
                        descripcioncsdet: '',
                        redessocialescsdet: 'DEFAULT',
                        prioridadcsdet: 'DEFAULT'
                    })
                    Swal.fire({
                        icon: 'success',
                        title: 'Se actualizo satisfactoriamente'
                    })
                    this.cargarInformacion();
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Ocurrio un error al actualizar'
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Ocurrio un error!'
                })
            }
        });
    }

    activar = (id) => {
        const data = {
            tran: 6,
            idcsdet: id,
            imgprincipalcsdet: 'NA',
            titulo1csdet: 'NA',
            titulo2csdet: 'NA',
            descripcioncsdet: 'NA',
            redessocialescsdet: 0,
            urlcsdetface: 'NA',
            urlcsdetyout: 'NA',
            urlcsdetinst: 'NA',
            urlwebcsdet: 'NA',
            prioridadcsdet: 0,
            estado: 1,
            usucrea: sessionStorage.getItem('idusuario')
        }
        CasosDeExito_(data).then(response => {
            if (response) {
                this.setState({
                    estadoS: false
                })
                Swal.fire({
                    icon: 'success',
                    title: 'Se activo satisfactoriamente'
                });
                this.cargarInformacion();
            }
        });
    }

    inactivar = (id) => {
        const data = {
            tran: 6,
            idcsdet: id,
            imgprincipalcsdet: 'NA',
            titulo1csdet: 'NA',
            titulo2csdet: 'NA',
            descripcioncsdet: 'NA',
            redessocialescsdet: 0,
            urlcsdetface: 'NA',
            urlcsdetyout: 'NA',
            urlcsdetinst: 'NA',
            urlwebcsdet: 'NA',
            prioridadcsdet: 0,
            estado: 0,
            usucrea: sessionStorage.getItem('idusuario')
        }
        CasosDeExito_(data).then(response => {
            if (response) {
                this.setState({
                    estadoS: false
                })
                Swal.fire({
                    icon: 'success',
                    title: 'Se inactivo satisfactoriamente'
                });
                this.cargarInformacion();
            }
        });
    }

    handleNew = () => {
        this.setState({
            estadoNuevo: !this.state.estadoNuevo,
            errors: {},
            imgprincipalcsdet: '',
            titulo1csdet: '',
            titulo2csdet: '',
            descripcioncsdet: '',
            redessocialescsdet: 'DEFAULT',
            selectedFile: null,
            urlcsdetface: '',
            urlcsdetyout: '',
            urlcsdetinst: '',
            urlwebcsdet: ''
        })
        if (this.state.estadoNuevo) {
            this.setState({
                errors: {},
                imgprincipalcsdet: '',
                titulo1csdet: '',
                titulo2csdet: '',
                descripcioncsdet: '',
                redessocialescsdet: 'DEFAULT',
                selectedFile: null,
                urlcsdetface: '',
                urlcsdetyout: '',
                urlcsdetinst: '',
                urlwebcsdet: ''
            })
            this.cargarInformacion();
        }
    }

    render() {
        const { estadoNuevo, estadoCambioImg1, estadoCambio, estadoSinInfo, titulo1csdet, errors, titulo2csdet, descripcioncsdet, estadoEditar, idcsdet, infoCasosExito } = this.state;
        return (
            <React.Fragment>
                <div className="pb-5 mb-5 pt-3">
                    <p>Casos de Exito</p>
                    <hr />
                    {estadoSinInfo ? (
                        <React.Fragment>
                            <div className="plus_add_agencia_2 align-items-center d-flex" onClick={() => { this.handleNew() }}>
                                {estadoNuevo ? <p>Cancelar &nbsp;<MDBIcon icon="times" size="lg" /></p> : <p>Agregar Nuevo &nbsp;<MDBIcon icon="plus-circle" size="lg" /></p>}
                            </div>
                        </React.Fragment>
                    ) : (
                        <React.Fragment></React.Fragment>
                    )}
                    {estadoNuevo ? (
                        <React.Fragment>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12 my-2 text-center">
                                        <FileUploaderB ref={this.uploader} estadoImg={estadoCambioImg1} FCambiar={this.cambioImagen1} />
                                    </div>
                                    <div className="col-md-6 my-2">
                                        <label>Prioridad</label>
                                        <select name="prioridadcsdet" value={this.state.prioridadcsdet} className="form-control" onChange={this.textInput}>
                                            <option value="DEFAULT" disabled>Seleccione...</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                        </select>
                                        {errors.prioridadcsdet && <strong className="text-danger" style={{ fontSize: '13px' }}>{errors.prioridadcsdet}</strong>}
                                    </div>
                                    <div className="col-md-6 my-2">
                                        <MDBInput label="Titulo" name="titulo1csdet" type="text" value={titulo1csdet} onChange={this.textInput} />
                                        {errors.titulo1csdet && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '50px' }}>{errors.titulo1csdet}</strong>}
                                    </div>
                                    <div className="col-md-6 my-2">
                                        <MDBInput label="Subtitulo" name="titulo2csdet" type="textarea" rows="5" value={titulo2csdet} onChange={this.textInput} />
                                        {errors.titulo2csdet && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '127px' }}>{errors.titulo2csdet}</strong>}
                                    </div>
                                    <div className="col-md-6 my-2">
                                        <MDBInput label="Descripción" name="descripcioncsdet" type="textarea" rows="5" value={descripcioncsdet} onChange={this.textInput} />
                                        {errors.descripcioncsdet && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '127px' }}>{errors.descripcioncsdet}</strong>}
                                    </div>
                                    <div className="col-md-6 my-2">
                                        <label>Maneja redes sociales?</label>
                                        <select name="redessocialescsdet" value={this.state.redessocialescsdet} className="form-control" onChange={this.textInput}>
                                            <option value="DEFAULT" disabled>Seleccione...</option>
                                            <option value="1">SI</option>
                                            <option value="0">NO</option>
                                        </select>
                                        {errors.redessocialescsdet && <strong className="text-danger" style={{ fontSize: '13px' }}>{errors.redessocialescsdet}</strong>}
                                    </div>
                                    {this.state.redessocialescsdet === "1" ? (
                                        <React.Fragment>
                                            <div className="col-md-6 my-2">
                                                <MDBInput label="URL Facebook" name="urlcsdetface" type="text" value={this.state.urlcsdetface} onChange={this.textInput} />
                                            </div>
                                            <div className="col-md-6 my-2">
                                                <MDBInput label="URL YouTube" name="urlcsdetyout" type="text" value={this.state.urlcsdetyout} onChange={this.textInput} />
                                            </div>
                                            <div className="col-md-6 my-2">
                                                <MDBInput label="URL Intagram" name="urlcsdetinst" type="text" value={this.state.urlcsdetinst} onChange={this.textInput} />
                                            </div>
                                        </React.Fragment>
                                    ) : (<React.Fragment></React.Fragment>)}
                                    <div className="col-md-6 my-2">
                                        <MDBInput label="URL WEB" name="urlwebcsdet" type="text" value={this.state.urlwebcsdet} onChange={this.textInput} />
                                    </div>
                                </div>
                            </div>
                            <div className="pt-2 mt-5 modal-footer w-100">
                                <button type="button" className="btn btn-primary px-5" onClick={this.insertarDatos}>Crear Producto &nbsp; <MDBIcon icon="save" /></button>
                            </div>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <div className="container-fluid">
                                <div className="table-responsive text-center w-100 px-0">
                                    <MDBTable>
                                        <MDBTableHead>
                                            <tr className="font-weight-bold">
                                                <td>Imagen</td>
                                                <td>Prioridad</td>
                                                <td>Titulo</td>
                                                <td>Subtitulo</td>
                                                <td>Descripción</td>
                                                <td>Maneja Redes?</td>
                                                <td>URLs</td>
                                                {estadoEditar ?
                                                    (
                                                        <React.Fragment>
                                                            <td>Guardar</td>
                                                            <td>Cancelar</td>
                                                        </React.Fragment>
                                                    ) : (
                                                        <React.Fragment>
                                                            <td>Editar</td>
                                                            <td>Inactivar</td>
                                                        </React.Fragment>
                                                    )}
                                            </tr>
                                        </MDBTableHead>
                                        <MDBTableBody>
                                            {estadoEditar ?
                                                (
                                                    <React.Fragment>
                                                        <tr>
                                                            <td className="w-10">
                                                                <FileUploader ref={this.uploader} estadoImg={estadoCambio} ruta={this.state.imgprincipalcsdet} FCambiar={this.cambio} />
                                                            </td>
                                                            <td>
                                                                <select name="prioridadcsdet" value={this.state.prioridadcsdet} className="form-control" onChange={this.textInput}>
                                                                    <option value="DEFAULT" disabled>Seleccione...</option>
                                                                    <option value="1">1</option>
                                                                    <option value="2">2</option>
                                                                    <option value="3">3</option>
                                                                </select>
                                                            </td>
                                                            <td className="w-25"><textarea className="form-control" type="textarea" rows="5" name="titulo1csdet" value={this.state.titulo1csdet} onChange={this.textInput} /></td>
                                                            <td><textarea className="form-control" type="textarea" rows="5" name="titulo2csdet" value={this.state.titulo2csdet} onChange={this.textInput} /></td>
                                                            <td><textarea className="form-control" type="textarea" rows="5" name="descripcioncsdet" value={this.state.descripcioncsdet} onChange={this.textInput} /></td>
                                                            <td>
                                                                <select name="redessocialescsdet" value={this.state.redessocialescsdet} className="form-control" onChange={this.textInput}>
                                                                    <option value="DEFAULT" disabled>Seleccione...</option>
                                                                    <option value="1">SI</option>
                                                                    <option value="0">NO</option>
                                                                </select>
                                                            </td>
                                                            <td className="w-25">
                                                                <b>URL Facebook:</b>
                                                                <textarea className="form-control" type="textarea" rows="5" name="urlcsdetface" value={this.state.urlcsdetface} onChange={this.textInput} />
                                                                <b>URL YouTube:</b>
                                                                <textarea className="form-control" type="textarea" rows="5" name="urlcsdetyout" value={this.state.urlcsdetyout} onChange={this.textInput} />
                                                                <b>URL Instagram:</b>
                                                                <textarea className="form-control" type="textarea" rows="5" name="urlcsdetinst" value={this.state.urlcsdetinst} onChange={this.textInput} />
                                                                <b>URL WEB:</b>
                                                                <textarea className="form-control" type="textarea" rows="5" name="urlwebcsdet" value={this.state.urlwebcsdet} onChange={this.textInput} />
                                                            </td>
                                                            <td className="text-center" onClick={() => { this.actualizarDatos(idcsdet) }}><MDBIcon icon="save" size="2x" style={{ color: 'blue', cursor: 'pointer' }} /></td>
                                                            <td className="text-center" onClick={() => { this.setState({ estadoEditar: !this.state.estadoEditar }) }}><MDBIcon icon="times" size="2x" style={{ color: 'red', cursor: 'pointer' }} /></td>
                                                        </tr>
                                                    </React.Fragment>
                                                ) : (
                                                    <React.Fragment>
                                                        {infoCasosExito.map((e, i) => {
                                                            let view;
                                                            if (e.estado === 1) {
                                                                view = <MDBIcon onClick={() => { this.inactivar(e.idcsdet) }} icon="eye" size="2x" style={{ cursor: 'pointer' }} />
                                                            } else {
                                                                view = <MDBIcon onClick={() => { this.activar(e.idcsdet) }} icon="eye-slash" size="2x" style={{ cursor: 'pointer' }} />
                                                            }
                                                            return [
                                                                <React.Fragment key={i}>
                                                                    <tr>
                                                                        <td><img width="180" src={`${URLImgFront}/assets${e.imgprincipalcsdet}`} alt={e.imgprincipalcsdet} /></td>
                                                                        <td>{e.prioridadcsdet}</td>
                                                                        <td>{e.titulo1csdet}</td>
                                                                        <td>{e.titulo2csdet}</td>
                                                                        <td>{e.descripcioncsdet}</td>
                                                                        <td>{e.redessocialescsdet === 1 ? 'SI' : 'NO'}</td>
                                                                        <td><b>URL Facebook:</b> {e.urlcsdetface} <br />
                                                                            <b>URL YouTube:</b> {e.urlcsdetyout} <br />
                                                                            <b>URL Instagram:</b> {e.urlcsdetinst} <br />
                                                                            <b>URL WEB:</b> {e.urlwebcsdet}</td>
                                                                        <td className="text-center" onClick={() => { this.editar(e.idcsdet) }}><MDBIcon icon="edit" size="2x" style={{ color: 'green', cursor: 'pointer' }} /></td>
                                                                        <td className="text-center">{view}</td>
                                                                    </tr>
                                                                </React.Fragment>
                                                            ]
                                                        })}
                                                    </React.Fragment>
                                                )}
                                        </MDBTableBody>
                                    </MDBTable>
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </div>
            </React.Fragment>
        );
    }
}

export default CasosExito;