import axios from "axios";
import { _utuaForm, urlPage, urlInternal } from "../config/URLDB";

// cargue de imagenes
export const CargueImagenes = (data) => {
    return axios
        .post('WebApiUtraMulti/Multimedia/UploadMultimedia', data, {
            headers: {
                "Content-Type": "multipart/form-data;charset=UTF-8",
            },
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
};

// cargue de documentos
export const CargueDocumentos = (data) => {
    return axios
        .post('WebApiUtraDoc/Documentos/UploadDocumentos', data, {
            headers: {
                "Content-Type": "multipart/form-data;charset=UTF-8",
            },
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });
};

// Metodos de servicios para el consumo del Modal Principal
export const ModalPrincipal = (data) => {
    const fecha = new Date();
    return axios
        .get(`${urlPage}/ModalPrincipal/GetModalPrincipal`, {
            params: {
                tran: data.tran,
                idmodalprinc: 0,
                nombremodal: data.nombremodal,
                rutaimgmodal: data.rutaimgmodal,
                fecinimodal: data.fecinimodal,
                fecfinmodal: data.fecfinmodal,
                manejavistainfo: 0,
                rutavistainfo: data.rutavistainfo,
                estado: 0,
                usucrea: data.usucrea,
                feccrea: fecha
            },
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

// Metodo de servicios para el consumo del Slider Principal
export const SliderPrincipal_ = (data) => {
    const fecha = new Date();
    return axios
        .get(`${urlPage}/Principal/GetSliderPrincipal`, {
            params: {
                tran: data.tran,
                idsliderprinc: data.id,
                nombsliderprinc: data.nombreImagen,
                txtslider1: data.texto1,
                txtslider2: data.texto2,
                txtslider3: data.texto3,
                rutafondoslider: data.rutaModal,
                posiciontexto: data.posiciontexto,
                colortexto: data.colortexto,
                idtipoenlace: data.idtipoenlace,
                rutatipoenlace: data.rutatipoenlace,
                estado: data.estado,
                usucrea: data.usucrea,
                feccrea: fecha
            },
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

// Metodo de servicios para el consumo de las Campañas
export const DetallesCampañasPrincipal_ = (data) => {
    const fecha = new Date();
    return axios
        .get(`${urlPage}/Principal/GetDetalleCampanas`, {
            params: {
                tran: data.tran,
                idvtacampcomer: data.idvtacampcomer,
                idsliderprinc: data.idsliderprinc,
                nombcampcomer: data.nombcampcomer,
                rutaimgicon1: data.rutaimgicon1,
                descimgicono1: data.descimgicono1,
                rutaimgicon2: data.rutaimgicon2,
                descimgicono2: data.descimgicono2,
                rutaimgicon3: data.rutaimgicon3,
                descimgicono3: data.descimgicono3,
                descobjetcamp: data.descobjetcamp,
                rutaimgperfil: data.rutaimgperfil,
                usucrea: data.usucrea,
                feccrea: fecha
            },
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

// Metodo de servicios para el consumo de los beneficios
export const BeneficiosPrincipal_ = (data) => {
    const fecha = new Date();
    return axios
        .get(`${urlPage}/Principal/GetBeneficios`, {
            params: {
                tran: data.tran,
                idbenefutra: data.idbenefutra,
                titulobenef: data.titulobenef,
                rutaiconobenef: data.rutaiconobenef,
                descbenef: data.descbenef,
                estado: data.estado,
                usucrea: data.usucrea,
                feccrea: fecha
            },
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

// Metodo de servicios para el consumo de los noticias
export const TNoticiasPrincipal_ = (data) => {
    const fecha = new Date();
    return axios
        .get(`${urlPage}/General/GetTipoNoticia`, {
            params: {
                tran: data.tran,
                idtiponoticia: data.idtiponoticia,
                tiponoticia: data.tiponoticia,
                desctiponotic: data.desctiponotic,
                estado: data.estado,
                usucrea: "NA",
                feccrea: fecha
            },
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

// Metodo de servicios para el consumo de los noticias
export const NoticiasPrincipal_ = (data) => {
    const fecha = new Date();
    return axios
        .get(`${urlPage}/Principal/GetNoticias`, {
            params: {
                tran: data.tran,
                idnoticutra: data.idnoticutra,
                idtiponoticia: data.idtiponoticia,
                rutafondonotic: data.rutafondonotic,
                descnoticia: data.descnoticia,
                estado: data.estado,
                usucrea: data.usucrea,
                feccrea: fecha
            },
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};


// Metodo de servicios para el consumo de las agencias
export const Agencias_ = (data) => {
    const fecha = new Date();
    return axios
        .get(`${urlPage}/RedAgencias/GetRedAgencias`, {
            params: {
                tran: data.tran,
                idredagencia: data.idredagencia,
                idtipoagencia: data.idtipoagencia,
                idmunicipio: data.idmunicipio,
                codageutra: 0,
                codcasuutra: 0,
                nombreagenc: data.nombreagenc,
                descagencia: data.descagencia,
                rutaiconomapa: data.rutaiconomapa,
                latitud: data.latitud,
                longitud: data.longitud,
                zoom: data.zoom,
                estado: data.estado,
                usucrea: data.usucrea,
                feccrea: fecha
            },
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};


//LISTA - 211104
export const ListRedAgencias = (data) => {
    return axios
        .get(`${urlPage}/RedAgencias/GetListRedAgencias`, {
            params: {
                prmto: data.prmto,
                redAgencia: data.redAgencia,
                tipoAgencia: data.tipoAgencia,
                municipio: data.municipio,
                estado: data.estado,
                usuario: data.usuario
            }
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        })
}


// Metodo de servicios para el consumo de agencias detalles
export const AgenciaDetallesPOST_ = (data) => {
    const fecha = new Date();
    const modelo = {
        RedAgenciasDetalles: {
            idredagendetalle: data.idredagendetalle,
            idredagencia: data.idredagencia,
            rutafotoagenc: data.rutafotoagenc,
            direccion: data.direccion,
            infoadicihorario: data.infoadicihorario,
            usucrea: data.usucrea,
            feccrea: fecha
        },
        AgenciasHorarios: data.HorariosA,
        AgenciasNumerosContacto: data.telefonosA
    };

    let axiosConfig = {
        headers: {
            "Content-Type": "application/json;charset=UTF-8",
        },
    };
    return axios.post(`${urlPage}/RedAgencias/PostGrabarRedAgenciasDetalle`, modelo, axiosConfig)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const AgenciaDetalles_ = (data) => {
    const fecha = new Date();
    return axios
        .get(`${urlPage}/RedAgencias/GetRedAgenciasDetalle`, {
            params: {
                tran: data.tran,
                idredagendetalle: data.idredagendetalle,
                idredagencia: data.idredagencia,
                idmunicipio: data.idmunicipio,
                rutafotoagenc: data.rutafotoagenc,
                direccion: data.direccion,
                infoadicihorario: data.infoadicihorario,
                estado: data.estado,
                usucrea: data.usucrea,
                feccrea: fecha
            },
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};


// Metodo de servicios para el consumo de los departamentos de agencias
export const DptoAgencias_ = (data) => {
    const fecha = new Date();
    return axios
        .get(`${urlPage}/General/GetDepartamentos`, {
            params: {
                tran: data.tran,
                iddepartamento: data.iddepartamento,
                idpais: 0,
                codlugardpto: "NA",
                nombredpto: "NA",
                codigodpto: "NA",
                abreviadpto: "NA",
                sucursalesutra: 1,
                feccreadpto: "01/01/1990",
                cantidadmpios: 0,
                capital: "NA",
                poblaciondpto: 0,
                latdpto: "NA",
                longdpto: "NA",
                zoomdpto: 0,
                usucrea: "NA",
                feccrea: fecha
            },
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

// Metodo de servicios para el consumo de los municipios
export const Municipios_ = (data) => {
    const fecha = new Date();
    return axios
        .get(`${urlPage}/General/GetMunicipios`, {
            params: {
                tran: data.tran,
                idmunicipio: 0,
                iddepartamento: data.iddepartamento,
                codlugarmpio: "NA",
                nombrempio: "NA",
                codigompio: "NA",
                abreviampio: "NA",
                sucursalesutra: 1,
                latmpio: "NA",
                longmpio: "NA",
                zoommpio: 0,
                estado: data.estado,
                usucrea: "NA",
                feccrea: fecha
            },
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

// Metodo de servicios para el consumo seguros
export const Seguros_ = (data) => {
    const fecha = new Date();
    return axios
        .get(`${urlPage}/Seguros/GetVistaSeguros`, {
            params: {
                tran: data.tran,
                idvtaseguro: data.idvtaseguro,
                nombseguro: data.nombseguro,
                rutafondoseg: data.rutafondoseg,
                txtseguro1: data.txtseguro1,
                txtseguro2: data.txtseguro2,
                posiciontexto: data.posiciontexto,
                colortexto: data.colortexto,
                estado: data.estado,
                usucrea: data.usucrea,
                feccrea: fecha
            },
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

// Metodo de servicios para el consumo convenios
export const Convenios_ = (data) => {
    const fecha = new Date();
    return axios
        .get(`${urlPage}/Convenios/GetVistaConvenios`, {
            params: {
                tran: data.tran,
                idvtaconvenio: data.idvtaconvenio,
                idtipopers: data.idtipopers,
                nombconvenio: data.nombconvenio,
                rutafondoconv: data.rutafondoconv,
                txtconvenio1: data.txtconvenio1,
                txtconvenio2: data.txtconvenio2,
                colortexto: data.colortexto,
                estado: data.estado,
                usucrea: data.usucrea,
                feccrea: fecha
            },
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

// Metodo de servicios para el consumo convenios detalles
export const ConvenioDetalles_ = (data) => {
    const fecha = new Date();
    return axios
        .get(`${urlPage}/Convenios/GetDetalleConvenios`, {
            params: {
                tran: data.tran,
                idconveniodet: data.idconveniodet,
                idtipopers: data.idtipopers,
                rutaimgconven: data.rutaimgconven,
                titconvenio1: data.titconvenio1,
                descconvenio1: data.descconvenio1,
                titconvenio2: data.titconvenio2,
                descconvenio2: data.descconvenio2,
                tiporegistro: data.tiporegistro,
                descinfoconvenio: data.descinfoconvenio,
                rutaarchiconven: data.rutaarchiconven,
                estado: data.estado,
                usucrea: data.usucrea,
                feccrea: fecha
            },
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

// Metodo de servicios para el consumo de asociados
export const Asociados_ = (data) => {
    const fecha = new Date();
    return axios
        .get(`${urlPage}/Asociados/GetVistaAsociados`, {
            params: {
                tran: data.tran,
                idvtaasociado: data.idvtaasociado,
                nombvtaasociado: data.nombvtaasociado,
                rutaimgasoci: data.rutaimgasoci,
                txtvtaasociado1: data.txtvtaasociado1,
                txtvtaasociado2: data.txtvtaasociado2,
                txtvtaasociado3: data.txtvtaasociado3,
                estado: data.estado,
                usucrea: data.usucrea,
                feccrea: fecha
            },
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

// Metodo de servicios para el consumo de asociados detalles
export const AsociadosDetalles_ = (data) => {
    const fecha = new Date();
    return axios
        .get(`${urlPage}/Asociados/GetAsociadosDetalle`, {
            params: {
                tran: data.tran,
                idasocdetalle: data.idasocdetalle,
                rutaimgasodet: data.rutaimgasodet,
                nombreasodet: data.nombreasodet,
                nombrelink: data.nombrelink,
                linkvistadet: data.linkvistadet,
                estado: data.estado,
                usucrea: data.usucrea,
                feccrea: fecha
            },
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

// Metodo de servicios para el consumo de quiero asociarme
export const QuieroAsociarme_ = (data) => {
    const fecha = new Date();
    return axios
        .get(`${urlPage}/QuieroAsociarme/GetQuieroAsociarme`, {
            params: {
                tran: data.tran,
                idquieroasoc: data.idquieroasoc,
                nombasociarme: data.nombasociarme,
                rutaimgasociarm: data.rutaimgasociarm,
                txtasociarme1: data.txtasociarme1,
                txtasociarme2: data.txtasociarme2,
                colortexto: data.colortexto,
                estado: data.estado,
                usucrea: data.usucrea,
                feccrea: fecha
            },
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

// Metodo de servicios para el consumo de conozcanos
export const Conozcanos_ = (data) => {
    const fecha = new Date();
    return axios
        .get(`${urlPage}/Conozcanos/GetVistaConozcanos`, {
            params: {
                tran: data.tran,
                idvtaconozcanos: data.idvtaconozcanos,
                nombreconozcanos: data.nombreconozcanos,
                rutaimgconozc: data.rutaimgconozc,
                txtconozcanos1: data.txtconozcanos1,
                txtconozcanos2: data.txtconozcanos2,
                colortexto: data.colortexto,
                colorlinea: data.colorlinea,
                estado: data.estado,
                usucrea: data.usucrea,
                feccrea: fecha
            },
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

// Metodo de servicios para el consumo de trabaje con nosotros
export const TrabajeconNosotros_ = (data) => {
    const fecha = new Date();
    return axios
        .get(`${urlPage}/Conozcanos/GetVacantesUtrahuilca`, {
            params: {
                tran: data.tran,
                idvacante: data.idvacante,
                rutaimgvacant: data.rutaimgvacant,
                nombcargo: data.nombcargo,
                descperfil: data.descperfil,
                desccargo: data.desccargo,
                estado: data.estado,
                usucrea: data.usucrea,
                feccrea: fecha
            },
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

// Metodo de servicios para el consumo de trabaje con nosotros POST ACTIVAR - INACTIVAR
export const TrabajeconNosotrosAdmin_ = (data) => {
    const fecha = new Date();
    const VacantesUtrahuilca = {
        idvacante: data.idvacante,
        estado: data.estado,
        usucrea: data.usucrea,
        feccrea: fecha,
    };
    let axiosConfig = {
        headers: {
            "Content-Type": "application/json;charset=UTF-8",
        },
    };
    return axios
        .post(
            `${urlPage}/Conozcanos/AdminCambEstadoVacantesUtrahuilca`,
            VacantesUtrahuilca,
            axiosConfig
        )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

// Metodo de servicios para el consumo de trabaje con nosotros POST
export const TrabajeconNosotrosPOST_ = (data) => {
    const fecha = new Date();
    const VacantesUtrahuilca = {
        idvacante: data.idvacante,
        rutaimgvacant: data.rutaimgvacant,
        nombcargo: data.nombcargo,
        descperfil: data.descperfil,
        desccargo: data.desccargo,
        estado: data.estado,
        usucrea: data.usucrea,
        feccrea: fecha,
    };
    let axiosConfig = {
        headers: {
            "Content-Type": "application/json;charset=UTF-8",
        },
    };
    return axios.post(`${urlPage}/Conozcanos/AdminGrabarVacantesUtrahuilca`, VacantesUtrahuilca, axiosConfig)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

// Metodo de servicios para el consumo de seccion transaccional
export const SeccionTransaccional_ = (data) => {
    const fecha = new Date();
    return axios
        .get(`${urlPage}/SeccionTransaccional/GetVistaCoopVirtual`, {
            params: {
                tran: data.tran,
                idvtacoopvirt: data.idvtacoopvirt,
                nombvtacoop: data.nombvtacoop,
                rutaimgcoopvirt: data.rutaimgcoopvirt,
                txtcoopvirtual1: data.txtcoopvirtual1,
                txtcoopvirtual2: data.txtcoopvirtual2,
                colortexto: data.colortexto,
                estado: data.estado,
                usucrea: data.usucrea,
                feccrea: fecha
            },
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

// Metodo de servicios para el consumo de seccion transaccional - Personas
//SE DEPURA TIPO DE PERSONA
export const SeccionPNaturalJuridica_ = (data) => {
    const fecha = new Date();
    return axios
        .get(`${urlPage}/SeccionTransaccional/GetCoopVirtualDetalle`, {
            params: {
                tran: data.tran,
                idcoopvirtdet: data.idcoopvirtdet,
                //idtipopers: data.idtipopers,
                rutaimgdeta: data.rutaimgdeta,
                titcoopvirtdet: data.titcoopvirtdet,
                desccoopvirtdet: data.desccoopvirtdet,
                piecoopvirtdet: data.piecoopvirtdet,
                manejaenlace: data.manejaenlace,
                rutaenlace: data.rutaenlace,
                estado: data.estado,
                usucrea: data.usucrea,
                feccrea: fecha,
                tipocargue: data.tipocargue,
                idmaestrocoopv: data.idmaestrocoopv
            },
        })
        .then((response) => {
            //debugger;
            return response;
        })
        .catch((error) => {
            return error;
        });
};

//NUEVA FUNCIÓN
//SE DEPURA TIPO DE PERSONA Y SE AGREGA NUEVO CAMPO
export const GrabarSeccionPNaturalJuridica_ = (data) => {
    const fecha = new Date();
    const CoopVirtualDetalle = {
        tran: data.tran,
        idcoopvirtdet: data.idcoopvirtdet,
        //idtipopers: data.idtipopers,
        rutaimgdeta: data.rutaimgdeta,
        titcoopvirtdet: data.titcoopvirtdet,
        desccoopvirtdet: data.desccoopvirtdet,
        piecoopvirtdet: data.piecoopvirtdet,
        manejaenlace: data.manejaenlace,
        rutaenlace: data.rutaenlace,
        estado: data.estado,
        usucrea: data.usucrea,
        feccrea: fecha,
        tipocargue: data.tipocargue,
        idmaestrocoopv: data.idmaestrocoopv
    };

    let axiosConfig = {
        headers: {
            "Content-Type": "application/json;charset=UTF-8",
        },
    };
    return axios.post(`${urlPage}/SeccionTransaccional/PostGrabarCoopVirtualDetalle`, CoopVirtualDetalle, axiosConfig)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
}

//NUEVO OCTUBRE 2024
export const SeccionPMaestroCoopVirtual_ = (data) => {
    return axios
        .get(`${urlPage}/SeccionTransaccional/GetMaestroCoopVirtual`, {
            params: {
                tran: data.tran,
                idmaestrocoopv: data.idmaestrocoopv,
                idtipopers: data.idtipopers,
                titulo: data.titulo,
                rutaiconotit: data.rutaiconotit,
                descripcion: data.descripcion,
                estado: data.estado,
                idusucrea: data.idusucrea
            },
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

// Metodo de servicios para el consumo de preguntas frecuentes
export const PreguntasFrecuentes_ = (data) => {
    const fecha = new Date();
    return axios
        .get(`${urlPage}/PreguntasFrecuentes/GetVistPregFrecuentes`, {
            params: {
                tran: data.tran,
                idvtapregfrec: data.idvtapregfrec,
                nombrepregfrec: data.nombrepregfrec,
                rutaimgfondopreg: data.rutaimgfondopreg,
                txtpregfrec1: data.txtpregfrec1,
                txtpregfrec2: data.txtpregfrec2,
                colortexto: data.colortexto,
                estado: data.estado,
                usucrea: data.usucrea,
                feccrea: fecha
            },
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

// Metodo de servicios para el consumo de preguntas frecuentes lista
export const PreguntasFrecuentesLista_ = (data) => {
    const fecha = new Date();
    return axios
        .get(`${urlPage}/PreguntasFrecuentes/GetPreguntasFrecuentes`, {
            params: {
                tran: data.tran,
                idpregfrecuente: data.idpregfrecuente,
                rutaiconpreg: data.rutaiconpreg,
                prioridad: data.prioridad,
                titulopreg: data.titulopreg,
                descpregfrec1: data.descpregfrec1,
                descpregfrec2: data.descpregfrec2,
                descpregfrec3: data.descpregfrec3,
                descpregfrec4: data.descpregfrec4,
                estado: data.estado,
                usucrea: "NA",
                feccrea: fecha
            },
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

// Metodo de servicios para el consumo de comunicados
export const Comunicados_ = (data) => {
    const fecha = new Date();
    return axios
        .get(`${urlPage}/Comunicados/GetVistaComunicados`, {
            params: {
                tran: data.tran,
                idvtacomunicado: data.idvtacomunicado,
                nombvtacomunic: data.nombvtacomunic,
                rutaimgfondocomun: data.rutaimgfondocomun,
                txtcomunicado1: data.txtcomunicado1,
                txtcomunicado2: data.txtcomunicado2,
                colortexto: data.colortexto,
                estado: data.estado,
                usucrea: data.usucrea,
                feccrea: fecha
            },
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

// Metodo de servicios para el consumo de comunicados detalles
export const ComunicadosDetalles_ = (data) => {
    const fecha = new Date();
    return axios
        .get(`${urlPage}/Comunicados/GetComunicados`, {
            params: {
                tran: data.tran,
                idcomunicado: data.idcomunicado,
                codcomunicado: data.codcomunicado,
                rutaimgcomunic: data.rutaimgcomunic,
                titulocomunic: data.titulocomunic,
                desccomunic: data.desccomunic,
                rutalinkpdf: data.rutalinkpdf,
                estado: data.estado,
                usucrea: data.usucrea,
                feccrea: fecha
            },
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

// Metodo de servicios para el consumo de Llamanos
export const Llamanos_ = (data) => {
    const fecha = new Date();
    return axios
        .get(`${urlPage}/General/GetUtrahuilcaLlamanos`, {
            params: {
                tran: data.tran,
                idutrallamanos: data.idutrallamanos,
                indnumero: 0,
                nrollamanos: data.nrollamanos,
                estado: data.estado,
                usucrea: data.usucrea,
                feccrea: fecha
            },
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

// Metodo de servicios para el consumo de Productos Ahorro y Crédito
export const PersonasVista_ = (data) => {
    const fecha = new Date();
    return axios
        .get(`${urlPage}/Productos/GetVistaProductos`, {
            params: {
                tran: data.tran,
                idvtaprod: data.idvtaprod,
                nombvtaprod: data.nombvtaprod,
                idtipoprod: data.idtipoprod,
                idtipopers: data.idtipopers,
                rutaimgfondprod: data.rutaimgfondprod,
                txtvistprod1: data.txtvistprod1,
                txtvistprod2: data.txtvistprod2,
                rutaimginferior: data.rutaimginferior,
                posiciontexto: data.posiciontexto,
                colortexto: data.colortexto,
                titulo1inferior: data.titulo1inferior,
                desc1inferior: data.desc1inferior,
                titulo2inferior: data.titulo2inferior,
                desc2inferior: data.desc2inferior,
                estado: data.estado,
                usucrea: data.usucrea,
                feccrea: fecha
            },
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

// Metodo de servicios para el consumo de Productos Vista Detalles INSERT - UPDATE
export const ProductosDetalles_ = (data) => {
    const fecha = new Date();
    const ProductosVtaDetalles = {
        tran: data.tran,
        idprodvtadet: data.idprodvtadet,
        idprodutra: data.idprodutra,
        txtprodvtadet1: data.txtprodvtadet1,
        txtprodvtadet2: data.txtprodvtadet2,
        txtprodvtadet3: data.txtprodvtadet3,
        rutaimgdetalle: data.rutaimgdetalle,
        manejaitems: data.manejaitems,
        proddetitems: data.proddetitems,
        idtipovista: data.idtipovista,
        rutaproddet: data.rutaproddet,
        manejabeneficios: data.manejabeneficios,
        descbenefprod: data.descbenefprod,
        proddetbeneficios: data.proddetbeneficios,
        manejarequisitos: data.manejarequisitos,
        descrequisprod: data.descrequisprod,
        proddetrequisitos: data.proddetrequisitos,
        infoadicional: data.infoadicional,
        estado: data.estado,
        usucrea: data.usucrea,
        feccrea: fecha
    };
    let axiosConfig = {
        headers: {
            "Content-Type": "application/json;charset=UTF-8",
        },
    };
    return axios
        .post(`${urlPage}/Productos/PostProdVistaDetalles`, ProductosVtaDetalles, axiosConfig)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

// Metodo de servicios para el consumo de Productos Vista Detalles GET - INACTIVE - ACTIVE
export const ProductosDetallesGET_ = (data) => {
    const fecha = new Date();
    return axios
        .get(`${urlPage}/Productos/GetProdVistaDetalles`, {
            params: {
                tran: data.tran,
                idprodvtadet: data.idprodvtadet,
                idprodutra: data.idprodutra,
                txtprodvtdet1: data.txtprodvtdet1,
                txtprodvtdet2: data.txtprodvtdet2,
                txtprodvtdet3: data.txtprodvtdet3,
                rutaimgdetalle: data.rutaimgdetalle,
                idtipovista: data.idtipovista,
                rutaproddet: data.rutaproddet,
                manejaitems: data.manejaitems,
                descitemsprod: data.descitemsprod,
                manejabeneficios: data.manejabeneficios,
                descbenefprod: data.descbenefprod,
                manejarequisitos: data.manejarequisitos,
                descrequisprod: data.descrequisprod,
                infoadicional: data.infoadicional,
                rutaformsolic: data.rutaformsolic,
                estado: data.estado,
                usucrea: data.usucrea,
                feccrea: fecha
            },
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

// Metodo de servicios para el consumo del SIMULADOR
export const ProductosSimula_ = (data) => {
    const fecha = new Date();
    return axios
        .get(`${urlPage}/Productos/GetProductosUtra`, {
            params: {
                tran: data.tran,
                idprodutra: data.idprodutra,
                nombprodutra: data.nombprodutra,
                descprodutra: data.descprodutra,
                rutaimgprod: data.rutaimgprod,
                rutavistaprod: data.rutavistaprod,
                prolinea: data.prolinea,
                idtipoprod: data.idtipoprod,
                idtipopers: data.idtipopers,
                estado: data.estado,
                usucrea: data.usucrea,
                feccrea: fecha
            },
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};



// Metodo de servicios para el consumo del SIMULADOR
export const Simulador_ = (data) => {
    const fecha = new Date();
    return axios
        .get(`${urlPage}/SimuladorProductos/GetSimuladorProductos`, {
            params: {
                tran: data.tran,
                idsimuladorprod: data.idsimuladorprod,
                idprodutra: data.idprodutra,
                descsimulador: data.descsimulador,
                rutaiconosimul: data.rutaiconosimul,
                rutafondosimul: data.rutafondosimul,
                color: data.color,
                posicion: data.posicion,
                estado: data.estado,
                usucrea: data.usucrea,
                feccrea: fecha
            },
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

//NUEVO 211125
export const SimProductosGPostAdmin_ = (data) => {
    const fecha = new Date();
    const SimuladorProductos = {
        idsimuladorprod: data.idsimuladorprod,
        idprodutra: data.idprodutra,
        descsimulador: data.descsimulador,
        rutaiconosimul: data.rutaiconosimul,
        rutafondosimul: data.rutafondosimul,
        color: data.color,
        posicion: data.posicion,
        estado: data.estado,
        usucrea: data.usucrea,
        feccrea: fecha
    };
    let axiosConfig = {
        headers: {
            "Content-Type": "application/json;charset=UTF-8",
        },
    };
    return axios.post(`${urlPage}/SimuladorProductos/SimProductosGPostAdmin`, SimuladorProductos, axiosConfig)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};



// Metodos de servicios para el consumo de la Validacion del Login
export const LoginValidar = (data) => {
    return axios
        .get(`${urlPage}/Usuarios/GetValidarAccesoUsuario`, {
            params: {
                usuario: data.usuario,
                clave: data.clave
            },
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

//NUEVO METODO PARA CONSUMO WEB SERVICES DEL INGRESO DE LA FUNDACION
export const LoginValidarFundacion = (data) => {
    return axios
        .get(`${urlPage}/Usuarios/GetValidarAccesoUsuarioFundacion`, {
            params: {
                usuario: data.usuario,
                clave: data.clave
            },
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

// Metodos de servicios para el consumo de los datos del usuario
export const getProfile = (data) => {
    return axios
        .get(`${urlPage}/Usuarios/GetUsuarios`, {
            params: {
                idusuario: parseInt(data.idusuario),
                usuario: data.usuario
            },
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

// Metodos de servicios para el consumo de los datos de TASAS Y TARIFAS
export const TasasyTarifas_ = (data) => {
    return axios
        .get(`${urlPage}/General/GetTasasTarifas`, {
            params: {
                tran: data.tran,
                idtasastarifas: data.idtasastarifas,
                nomtasastarifas: data.nomtasastarifas,
                rutatasastarifas: data.rutatasastarifas,
                estado: data.estado,
                usucrea: data.usucrea
            },
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

// Metodo de servicio para el consumo de COOPSEJOS
export const Coopsejos_ = (data) => {
    const fecha = new Date();
    return axios
        .get(`${urlPage}/Coopsejos/GetVistaCoopsejos`, {
            params: {
                tran: data.tran,
                idvtacoopsejos: data.idvtacoopsejos,
                nomvtacoopsejos: data.nomvtacoopsejos,
                rutaimgcoopsejos: data.rutaimgcoopsejos,
                txtcoopsejos1: data.txtcoopsejos1,
                txtcoopsejos2: data.txtcoopsejos2,
                colortexto: data.colortexto,
                estado: data.estado,
                usucrea: data.usucrea,
                feccrea: fecha
            },
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

// Metodo de servicio para el consumo de Tipos de Noticias
export const TiposNoticias_ = (data) => {
    const fecha = new Date();
    return axios
        .get(`${urlPage}/General/GetTipoNoticia`, {
            params: {
                tran: data.tran,
                idtiponoticia: data.idtiponoticia,
                tiponoticia: data.tiponoticia,
                desctiponotic: data.desctiponotic,
                estado: data.estado,
                usucrea: data.usucrea,
                feccrea: fecha
            },
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

// Metodo de servicio para el consumo de COOPSEJOS > NOTICIAS
export const NoticiasEventosDetalles_ = (data) => {
    return axios
        .get(`${urlPage}/Coopsejos/GetNoticiasDetalle`, {
            params: {
                tran: data.tran,
                idnotidet: data.idnotidet,
                codcoopsejos: data.codcoopsejos,
                titulonotidet: data.titulonotidet,
                descripnotidet: data.descripnotidet,
                urlnotidet: data.urlnotidet,
                imgnotidet: data.imgnotidet,
                fecinipublinotidet: data.fecinipublinotidet,
                fecfinpublinotidet: data.fecfinpublinotidet,
                idtiponoticia: data.idtiponoticia,
                prioridadnotidet: data.prioridadnotidet,
                estado: data.estado,
                usucrea: data.usucrea
            },
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

// Metodo de servicios para el consumo de noticias especificas
export const NoticiasDetallesEspecificas_ = (data) => {
    return axios
        .get(`${urlPage}/Coopsejos/GetNoticiasEspecificas`, {
            params: {
                tran: data.tran,
                idnotiespecif: data.idnotiespecif,
                idnotidet: data.idnotidet,
                ordennotiespecif: data.ordennotiespecif,
                urlnotiespecif: data.urlnotiespecif,
                txtnotiespecif1: data.txtnotiespecif1,
                txtnotiespecif2: data.txtnotiespecif2,
                txtnotiespecif3: data.txtnotiespecif3,
                txtnotiespecif4: data.txtnotiespecif4,
                tipourlnotiespecif: data.tipourlnotiespecif,
                estadonotiespecif: data.estadonotiespecif,
                usuarionotiespecif: data.usucrea
            },
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

// Metodo de servicios para el consumo de noticias especificas POST ACTIVAR - INACTIVAR
export const NoticiasDetallesEspecificasAdmin_ = (data) => {
    const NoticiasEspecificas = {
        idnotiespecif: data.idnotiespecif,
        estadonotiespecif: data.estadonotiespecif,
        usuarionotiespecif: data.usucrea
    };

    let axiosConfig = {
        headers: {
            "Content-Type": "application/json;charset=UTF-8",
        },
    };
    
    return axios
        .post(
            `${urlPage}/Coopsejos/AdminCambEstadoNoticiasEspecificas`,
            NoticiasEspecificas,
            axiosConfig
        )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

// Metodo de servicios para el consumo de noticias especificas POST
export const NoticiasDetallesEspecificasPOST_ = (data) => {
    const NoticiasEspecificas = {
        idnotiespecif: data.idnotiespecif,
        idnotidet: data.idnotidet,
        ordennotiespecif: data.ordennotiespecif,
        urlnotiespecif: data.urlnotiespecif,
        txtnotiespecif1: data.txtnotiespecif1,
        txtnotiespecif2: data.txtnotiespecif2,
        txtnotiespecif3: data.txtnotiespecif3,
        txtnotiespecif4: data.txtnotiespecif4,
        tipourlnotiespecif: data.tipourlnotiespecif,
        estadonotiespecif: data.estadonotiespecif,
        usuarionotiespecif: data.usucrea,
    };
    let axiosConfig = {
        headers: {
            "Content-Type": "application/json;charset=UTF-8",
        },
    };
    return axios.post(`${urlPage}/Coopsejos/AdminGrabarNoticiasEspecificas`, NoticiasEspecificas, axiosConfig)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

// Metodo de servicio para el consumo de COOPSEJOS > CASOS DE EXITO
export const CasosDeExito_ = (data) => {
    return axios
        .get(`${urlPage}/Coopsejos/GetCasosExitosDetalle`, {
            params: {
                tran: data.tran,
                idcsdet: data.idcsdet,
                imgprincipalcsdet: data.imgprincipalcsdet,
                imgsecundariacsdet: data.imgsecundariacsdet,
                titulo1csdet: data.titulo1csdet,
                titulo2csdet: data.titulo2csdet,
                descripcioncsdet: data.descripcioncsdet,
                redessocialescsdet: data.redessocialescsdet,
                urlcsdetface: data.urlcsdetface,
                urlcsdetyout: data.urlcsdetyout,
                urlcsdetinst: data.urlcsdetinst,
                urlwebcsdet: data.urlwebcsdet,
                prioridadcsdet: data.prioridadcsdet,
                estado: data.estado,
                usucrea: data.usucrea
            },
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};









//Internal
export const VerifAsociadoConvenio_ = (data) => {
    return axios
        .get(`${urlInternal}/ConsultasPortal/GetConsultVerifAsociadoConvenio`, {
            params: {
                tipoiden: data.tipoiden,
                nit: data.nit,
            }
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const RevalorizacionAsociado_ = (data) => {
    return axios
        .get(`${urlInternal}/ConsultasPortal/GetConsultRevalorizacionAsociado`, {
            params: {
                tipoiden: data.tipoiden,
                nit: data.nit,
                fecini: data.fecini
            }
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};




//AJUST - 211108
export const ListProdPagar = (data) => {
    return axios
        .get(`${urlInternal}/ConsultasPortal/GetProductosPagar`, {
            params: {
                codage: data.codage,
                prolinea: data.prolinea,
                codcue: data.codcue,
                tipoProd: data.tipoProd
            }
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const ProcesarPago = (data) => {
    const ValidarPago = {
        tipoProd: data.tipoProd,
        codage: data.codage,
        prolinea: data.prolinea,
        codcue: data.codcue,
        vlrpagar: data.valorPago,
    }
    let axiosConfig = {
        headers: {
            "Content-Type": "application/json;charset=UTF-8",
        },
    };

    return axios.post(`${urlPage}/ConsultasPortal/ProcesarPago`, ValidarPago, axiosConfig)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const ProcesarInicioPago = (data) => {
    const modelo = {
        InfoProcesarPago: {
            tipoProd: data.tipoProd,
            codage: data.codage,
            prolinea: data.prolinea,
            codcue: data.codcue,
            vlrpagar: data.valorPago,
        },
        InfoProcesarInicioPago: {
            tipoiden: data.tipodocum,
            nrodocumento: data.nrodocumento,
            nombres: data.nombres,
            apellidos: data.apellidos,
            email: data.email,
            nrocelular: data.nrocelular,
            descripcion: data.descripcion
        }
    };

    let axiosConfig = { headers: { "Content-Type": "application/json;charset=UTF-8", }, };

    return axios.post(`${urlPage}/ConsultasPortal/IniciarPago`, modelo, axiosConfig)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

//AJUST 081121
export const TiposProductos_ = (data) => {
    return axios
        .get(`${urlInternal}/ConsultasPortal/GetConsultTiposProductos`, {
            params: {
                tipoProd: data.idcuenta
            }
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

//LISTA PROD - 211104
export const ListProductos = (data) => {
    return axios
        .get(`${urlInternal}/ConsultasPortal/GetListProductos`, {
            params: {
                prmto: data.prmto,
                usuario: data.usuario,
                tipoProd: data.tipoProd
            }
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};




export const Asesoria_ = (data) => {
    return axios
        .get(`${urlInternal}/SolicitudAsesoria/GrabarSolicAsesoria`, {
            params: {
                idprodserv: data.idprodserv,
                infoadicional: data.infoadicional,
                tipoiden: data.tipoiden,
                nit: data.nit,
                nombres: data.nombres,
                apellidos: data.apellidos,
                asociado: data.asociado,
                montoreq: data.montoreq,
                correo: data.correo,
                telcel: data.telcel,
                codage: data.codage,
                codocupacion: data.codocupacion,
                otraocupacion: data.otraocupacion,
                usucrea: _utuaForm
            }
        })
        .then((response) => {
            return [response.data[0].codresp, response.data[0].desresp];
        })
        .catch((error) => {
            return error;
        });
};

export const PQRSF_ = (data) => {
    return axios
        .get(`${urlInternal}/ProcesoEnvio/EnviarSolicitud`, {
            params: {
                tipodoc: data.tipodoc,
                nrodocumento: data.nrodocumento,
                nombres: data.nombres,
                apellidos: data.apellidos,
                email: data.email,
                asunto: data.asunto,
                direccion: data.direccion,
                nrocelular: data.nrocelular,
                tiposolic: data.tiposolic,
                msjpqrs: data.msjpqrs,
                tiporesp: data.tiporesp,
                ip: data.ip,
                configenvio: data.configenvio
            }
        })
        .then((response) => {
            return [response.data[0].codresp, response.data[0].desresp, response.data[0].idseguimemail];
        })
        .catch((error) => {
            return error;
        });
};

// Metodo de servicio para realizar consumo de OTP
export const GenerarCodigoOTP_ = (data) => {
    return axios
        .get(`${urlInternal}/CodigoOTP/GenerarCodigo`, {
            params: {
                cod_mot_otp: 5,
                usuario: data.usuario,
                tipper: 1,
                tipoiden: data.tipoiden,
                nit: data.nit,
                nomcom: data.nomcom,
                email: data.correo,
                telcel: data.telcel
            }
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const ValidarCodigoOTP_ = (data) => {
    return axios
        .get(`${urlInternal}/CodigoOTP/ValidarCodigo`, {
            params: {
                id_cod_otp: data.id_cod_otp,
                cod_mot_otp: 5,
                codigo_otp: data.codigo_otp,
                usuario: "NA"
            }
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

export const Simulacion_ = (data) => {
    return axios
        .get(`${urlInternal}/Simulacion/SimulacionProductos`, {
            params: {
                prolinea: data.prolinea,
                monto: data.monto,
                plazo: data.plazo,
                tipoprod: data.tipoprod
            }
        })
        .then((response) => {
            return response
        })
        .catch((error) => {
            return error;
        });
};

export const CargarPrincNuestrosAsociados_ = () => {
    return axios
        .get(`${urlPage}/Principal/GetPrincNuestAsociadosAct`, { })
        .then((response) => {
            return response
        })
        .catch((error) => {
            return error;
        });
};

export const CVistaPrincipalVideos_ = (data) => {
    const fecha = new Date();
    return axios
        .get(`${urlPage}/Principal/GetVistPrincVideos`, {
            params: {
                tran : data.tran,
                idprincvid: data.idprincvid,
                titulovid: data.titulovid,
                enlacevid: data.enlacevid,
                estadovid: data.estadovid,
                usuario: data.usuario,
                fecha: fecha
            }
        })
        .then((response) => {
            return response
        })
        .catch((error) => {
            return error;
        });
}

export const GrVistaPrincipalVideos_ = (data) => {
    const fecha = new Date();
    const VtaPrincVideos = {
        idprincvideo: data.idprincvideo,
        titulovideo: data.titulovideo,
        enlacevideo: data.enlacevideo,
        estado: 1,
        idusucrea: data.idusucrea,
        feccrea: fecha
    };

    let axiosConfig = {
        headers: {
            "Content-Type": "application/json;charset=UTF-8",
        },
    };
    return axios.post(`${urlPage}/Principal/PostVistPrincVideos`, VtaPrincVideos, axiosConfig)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
}

export const GrabConveniosDetalle_ = (data) => {
    const fecha = new Date();
    const ConveniosDetalle = {
        idconveniodet: data.idconveniodet,
        idtipopers: data.idtipopers,
        rutaimgconven: data.rutaimgconven,
        titconvenio1: data.titconvenio1,
        descconvenio1: data.descconvenio1,
        titconvenio2: data.titconvenio2,
        descconvenio2: data.descconvenio2,
        tiporegistro: data.tiporegistro,
        rutaarchiconven: data.rutaarchiconven,
        descinfoconvenio: data.descinfoconvenio,
        estado: data.estado,
        usucrea: data.usucrea,
        feccrea: fecha
    };

    let axiosConfig = {
        headers: {
            "Content-Type": "application/json;charset=UTF-8",
        },
    };
    return axios.post(`${urlPage}/Convenios/PostGrDetalleConvenios`, ConveniosDetalle, axiosConfig)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
}

export const GrbModalPrincipal_ = (data) => {
    const fecha = new Date();
    const ModalPrincipal = {
        idmodalprinc: data.idmodalprinc,
        nombremodal: data.nombremodal,
        rutaimgmodal: data.rutaimgmodal,
        fecinimodal: data.fecinimodal,
        fecfinmodal: data.fecfinmodal,
        manejavistainfo: data.manejavistainfo,
        rutavistainfo: data.rutavistainfo,
        estado: data.estado,
        usucrea: data.usucrea,
        feccrea: fecha
    };

    let axiosConfig = {
        headers: {
            "Content-Type": "application/json;charset=UTF-8",
        },
    };
    return axios.post(`${urlPage}/ModalPrincipal/PostModalPrincipal`, ModalPrincipal, axiosConfig)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
}


//NUEVO METODO PARA CONSULTAR ESTADO DE APORTE 09/02/2023
export const ConsultarEstadoAportes = (data) => {
    return axios
        .get(`${urlInternal}/ClientesAsociados/ConsultarEstadoAporte`, {
            params: {
                tipoiden: data.tipoiden,
                nit: data.nit,
                idusuario: data.usuario
            }
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};



//NUEVO METODO PARA CONSULTAR SI LA CAMPAÑA ESTA ACTIVA
export const ConsultarCampaniasActiva = (data) => {
    return axios
        .get(`${urlInternal}/ClientesAsociados/ConsultarCampaniasActiva`, {
            params: {
                tran: data.tran,
                id_camp: data.id_camp,
            }
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};


//NUEVO METODO PARA CONSULTAR LOS DATOS DEL CLIENTE Y LOS NUMEROS QUE TIENE ASIGNADOS PARA LA CAMPAÑA
export const ConsultarDatosClienteCampania = (data) => {
    return axios
        .get(`${urlInternal}/ClientesAsociados/ConsultarDatosClientesCampania`, {
            params: {
                tipoiden: data.tipoiden,
                nit: data.nit,
                fecha_exp: data.fec_exp,
                id_camp: data.id_camp,
            }
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};

/*Nuevo método para consultar los clientes en elecciones*/
export const ConsultarClienteElecciones = (data) => {
    return axios
        .get(`${urlPage}/Asociados/GetConsultarClienteEleccion`,
            {
                params: {
                    tran: data.tran,
                    tipoiden: data.tipoiden,
                    nit: data.nit
                }
            })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};