import React, { Component } from 'react';
import FileUploader from "./ImagenTexto/FileUploader";
import FileUploaderUpdate from "./ImagenTexto/FileUploaderUpdate";
import { MDBIcon, MDBInput } from 'mdbreact';
import reactCSS from 'reactcss';
import { SketchPicker } from 'react-color';
import { CargueImagenes, PersonasVista_ } from '../../../../services/WebAPI_Utrahuilca';
import Swal from 'sweetalert2';

const validate = values => {
    const errors = {}
    if (!values.nombvtaprod) {
        errors.nombvtaprod = 'campo obligatorio'
    }
    if (values.idtipopers === 'DEFAULT') {
        errors.idtipopers = 'campo obligatorio'
    }
    if (!values.txtvistprod1) {
        errors.txtvistprod1 = 'campo obligatorio'
    }
    if (!values.txtvistprod2) {
        errors.txtvistprod2 = 'campo obligatorio'
    }
    if (values.posiciontexto === 'DEFAULT') {
        errors.posiciontexto = 'campo obligatorio'
    }
    if (!values.titulo1inferior) {
        errors.titulo1inferior = 'campo obligatorio'
    }
    if (!values.desc1inferior) {
        errors.desc1inferior = 'campo obligatorio'
    }
    return errors;
};

class ImagenTexto extends Component {
    uploader = React.createRef();
    uploader2 = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            estadoNuevo: false,
            estadoCambioImg1: true,
            errors: {},
            infoPersona: [],
            idtipopers: 'DEFAULT',
            nombvtaprod: '',
            txtvistprod1: '',
            txtvistprod2: '',
            rutaimginferior: '',
            posiciontexto: 'DEFAULT',
            color: '#ffffff',
            titulo1inferior: '',
            desc1inferior: '',
            titulo2inferior: '',
            desc2inferior: '',
            editnombvtaprod: '',
            edittxtvistprod1: '',
            edittxtvistprod2: '',
            editidtipopers: 'DEFAULT',
            estadoImagen: false,
            displayColorPicker: false,
            rutaimgfondprod: '',
            estadoSinInfo: false,
        };
    }

    async componentDidMount() {
        this.cargarInformacion();
    }

    cargarInformacion = () => {
        const data = {
            tran: 7,
            idvtaprod: 0,
            idtipoprod: 1,
            idtipopers: 1,
            nombvtaprod: 'NA',
            rutaimgfondprod: 'NA',
            txtvistprod1: 'NA',
            txtvistprod2: 'NA',
            rutaimginferior: 'NA',
            posiciontexto: 0,
            colortexto: 'NA',
            titulo1inferior: 'NA',
            desc1inferior: 'NA',
            titulo2inferior: 'NA',
            desc2inferior: 'NA',
            estado: 0,
            usucrea: 'NA'
        }
        PersonasVista_(data).then(response => {
            try {
                if (response.data.length > 0) {
                    if (!response.data[0].message) {
                        this.setState({
                            idSeleccionado: response.data[0].idvtaprod
                        })
                        setTimeout(() => {
                            this.setState({
                                infoPersona: response.data,
                                editnombvtaprod: response.data[0].nombvtaprod,
                                rutaimgfondprod: response.data[0].rutaimgfondprod,
                                edittxtvistprod1: response.data[0].txtvistprod1,
                                edittxtvistprod2: response.data[0].txtvistprod2,
                                editidtipopers: response.data[0].idtipopers,
                                editposiciontexto: response.data[0].posiciontexto,
                                color: response.data[0].colortexto,
                                rutaimginferior: response.data[0].rutaimginferior,
                                edittitulo1inferior: response.data[0].titulo1inferior,
                                editdesc1inferior: response.data[0].desc1inferior,
                                edittitulo2inferior: response.data[0].titulo2inferior,
                                editdesc2inferior: response.data[0].desc2inferior,
                                estado: response.data[0].estado,
                                estadoSinInfo: true,
                                estadoImagen: true,
                                estadoCambioImg: false,
                                estadoCambioImg1: false,
                            })
                            document.getElementById('' + response.data[0].idvtaprod + '').style.background = "#f47216";
                            document.getElementById('' + response.data[0].idvtaprod + '').style.color = "#fff";
                            document.getElementById('' + response.data[0].idvtaprod + '').style.fontWeight = "bold";
                        }, 120);
                    } else {
                        this.setState({
                            estadoSinResultados: false,
                            estadoNuevo: true,
                            estadoCambioImg1: true,
                            estadoSinInfo: false
                        })
                    }
                } else { }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    text: 'Ocurrio un error al consumir el servicio, comuniquese con sistemas!'
                })
            }
        });
    }

    textInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    cambioImagen1 = (name, file) => {
        this.setState({
            estadoCambioImg1: true
        });
    }

    cambioImagen = (name, file) => {
        this.setState({
            estadoCambioImg: true
        });
    }

    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    };

    handleClose = () => {
        this.setState({ displayColorPicker: false })
    };

    handleChange = (color) => {
        this.setState({ color: color.hex })
    };

    cargarImagen = () => {
        var formData = new FormData();
        var rutaSuperior = '';
        var rutaInferior = '';
        var capNombre = this.uploader.current.getFileName();
        var capNombre2 = this.uploader2.current.getFileName();
        
        if (capNombre === undefined || capNombre === null || capNombre === "") {
            rutaSuperior = this.state.rutaimgfondprod;
        } else {
            rutaSuperior = '/Producto Ahorro/Persona/' + this.uploader.current.getFileName();
            formData.append("file", this.uploader.current.getFileObject());
            formData.append("rutaImagen", rutaSuperior);
        }
        if (capNombre2 === undefined || capNombre2 === null || capNombre2 === "") {
            rutaInferior = this.state.rutaimginferior;
        } else {
            rutaInferior = '/Producto Ahorro/Persona/' + this.uploader2.current.getFileName();
            formData.append("file", this.uploader2.current.getFileObject());
            formData.append("rutaImagen", rutaInferior);
        }
        CargueImagenes(formData).then(response => {
            if (response) {
                this.cargarInformacion();
            }
        });
    }

    insertarDatos = () => {
        const { errors, ...sinErrors } = this.state;
        const result = validate(sinErrors);
        this.setState({ errors: result });
        if (!Object.keys(result).length) {
            // formulario validado
            const data = {
                tran: 1,
                idvtaprod: 0,
                idtipoprod: 1,
                idtipopers: parseInt(this.state.idtipopers),
                nombvtaprod: this.state.nombvtaprod,
                rutaimgfondprod: '/Producto Ahorro/Persona/' + this.uploader.current.getFileName(),
                txtvistprod1: this.state.txtvistprod1,
                txtvistprod2: this.state.txtvistprod2,
                rutaimginferior: '/Producto Ahorro/Persona/' + this.uploader2.current.getFileName(),
                posiciontexto: parseInt(this.state.posiciontexto),
                colortexto: this.state.color,
                titulo1inferior: this.state.titulo1inferior,
                desc1inferior: this.state.desc1inferior,
                titulo2inferior: this.state.titulo2inferior === "" ? 'N/A' : this.state.titulo2inferior,
                desc2inferior: this.state.desc2inferior === "" ? 'N/A' : this.state.desc2inferior,
                estado: 1,
                usucrea: sessionStorage.getItem('idusuario')
            }
            PersonasVista_(data).then(response => {
                if (response.data.length > 0) {
                    if (response.data[0].message === "000000") {
                        this.cargarImagen();
                        Swal.fire({
                            icon: 'success',
                            title: 'Se agrego correctamente.'
                        })
                        this.setState({
                            estadoNuevo: false,
                            nombvtaprod: '',
                            txtvistprod1: '',
                            txtvistprod2: '',
                            estadoImagen: false,
                            displayColorPicker: false,
                            rutafondoImg: '',
                            color: '',
                            estadoCambioImg: false,
                            posiciontexto: 'DEFAULT',
                            titulo1inferior: '',
                            desc1inferior: '',
                            titulo2inferior: '',
                            desc2inferior: '',
                        })
                        this.cargarInformacion();
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error al insertar.'
                        })
                    }
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Ocurrio un error!'
                    })
                }
            });
        }
    }

    busquedaId = (id) => {
        document.getElementById('' + this.state.idSeleccionado + '').style.background = "#fff";
        document.getElementById('' + this.state.idSeleccionado + '').style.color = "#000";
        document.getElementById('' + this.state.idSeleccionado + '').style.fontWeight = "normal";
        document.getElementById('' + id + '').style.background = "#f47216";
        document.getElementById('' + id + '').style.color = "#fff";
        document.getElementById('' + id + '').style.fontWeight = "bold";
        this.setState({
            estadoCambioImg: false,
            estadoCambioImg1: false,
            est: !this.state.est,
            idSeleccionado: id
        })
        const data = {
            tran: 5,
            idvtaprod: id,
            idtipoprod: 1,
            idtipopers: 1,
            nombvtaprod: 'NA',
            rutaimgfondprod: 'NA',
            txtvistprod1: 'NA',
            txtvistprod2: 'NA',
            rutaimginferior: 'NA',
            posiciontexto: 0,
            colortexto: 'NA',
            titulo1inferior: 'NA',
            desc1inferior: 'NA',
            titulo2inferior: 'NA',
            desc2inferior: 'NA',
            estado: this.state.estado,
            usucrea: 'NA'
        }
        PersonasVista_(data).then(response => {
            if (response.data.length > 0) {
                this.setState({
                    editnombvtaprod: response.data[0].nombvtaprod,
                    rutaimgfondprod: response.data[0].rutaimgfondprod,
                    edittxtvistprod1: response.data[0].txtvistprod1,
                    edittxtvistprod2: response.data[0].txtvistprod2,
                    editposiciontexto: response.data[0].posiciontexto,
                    editidtipopers: response.data[0].idtipopers,
                    color: response.data[0].colortexto,
                    rutaimginferior: response.data[0].rutaimginferior,
                    edittitulo1inferior: response.data[0].titulo1inferior,
                    editdesc1inferior: response.data[0].desc1inferior,
                    edittitulo2inferior: response.data[0].titulo2inferior,
                    editdesc2inferior: response.data[0].desc2inferior,
                    estado: response.data[0].estado,
                    estadoImagen: true
                })
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'No hay información'
                })
            }
        });
    }

    activar = (id, idtp) => {
        const data = {
            tran: 6,
            idvtaprod: id,
            idtipoprod: 1,
            idtipopers: idtp,
            nombvtaprod: 'NA',
            rutaimgfondprod: 'NA',
            txtvistprod1: 'NA',
            txtvistprod2: 'NA',
            rutaimginferior: 'NA',
            posiciontexto: 0,
            colortexto: 'NA',
            titulo1inferior: 'NA',
            desc1inferior: 'NA',
            titulo2inferior: 'NA',
            desc2inferior: 'NA',
            estado: 1,
            usucrea: sessionStorage.getItem('idusuario')
        }
        PersonasVista_(data).then(response => {
            if (response) {
                this.setState({
                    estadoS: false
                })
                Swal.fire({
                    icon: 'success',
                    title: 'Se activo satisfactoriamente'
                });
                document.getElementById('' + this.state.idSeleccionado + '').style.background = "#fff";
                document.getElementById('' + this.state.idSeleccionado + '').style.color = "#000";
                document.getElementById('' + this.state.idSeleccionado + '').style.fontWeight = "normal";
                this.cargarInformacion();
            }
        });
    }

    actualizarDatos = (id) => {
        var rutaSuperior = '';
        var rutaInferior = '';
        var capNombre = this.uploader.current.getFileName();
        var capNombre2 = this.uploader2.current.getFileName();
        if (capNombre === undefined || capNombre === null || capNombre === "") {
            rutaSuperior = this.state.rutaimgfondprod;
        } else {
            rutaSuperior = '/Producto Ahorro/Persona/' + this.uploader.current.getFileName();
        }
        if (capNombre2 === undefined || capNombre2 === null || capNombre2 === "") {
            rutaInferior = this.state.rutaimginferior;
        } else {
            rutaInferior = '/Producto Ahorro/Persona/' + this.uploader2.current.getFileName();
        }
        const data = {
            tran: 1,
            idvtaprod: id,
            idtipoprod: 1,
            idtipopers: parseInt(this.state.editidtipopers),
            nombvtaprod: this.state.editnombvtaprod,
            rutaimgfondprod: rutaSuperior,
            txtvistprod1: this.state.edittxtvistprod1,
            txtvistprod2: this.state.edittxtvistprod2,
            rutaimginferior: rutaInferior,
            posiciontexto: parseInt(this.state.editposiciontexto),
            colortexto: this.state.color,
            titulo1inferior: this.state.edittitulo1inferior,
            desc1inferior: this.state.editdesc1inferior,
            titulo2inferior: this.state.edittitulo2inferior === "" ? 'N/A' : this.state.edittitulo2inferior,
            desc2inferior: this.state.editdesc2inferior === "" ? 'N/A' : this.state.editdesc2inferior,
            estado: 1,
            usucrea: sessionStorage.getItem('idusuario')
        }
        PersonasVista_(data).then(response => {
            if (response.data.length > 0) {
                if (response.data[0].message === "000000") {
                    this.cargarImagen();
                    this.setState({
                        estadoCambio: false,
                        estadoEditar: false,
                    })
                    Swal.fire({
                        icon: 'success',
                        title: 'Se actualizo satisfactoriamente'
                    })
                    document.getElementById('' + this.state.idSeleccionado + '').style.background = "#fff";
                    document.getElementById('' + this.state.idSeleccionado + '').style.color = "#000";
                    document.getElementById('' + this.state.idSeleccionado + '').style.fontWeight = "normal";
                    this.cargarInformacion();
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Ocurrio un error al actualizar'
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Ocurrio un error!'
                })
            }
        });
    }

    handleNew = () => {
        this.setState({
            estadoNuevo: !this.state.estadoNuevo,
            estadoCambioImg: !this.state.estadoCambioImg,
            estadoCambioImg1: !this.state.estadoCambioImg1,
            errors: {}
        })
        if (this.state.estadoNuevo) {
            this.setState({
                errors: {}
            })
            this.cargarInformacion();
        }
    }

    render() {
        const { estadoNuevo, infoPersona, nombvtaprod, estadoCambioImg1, idtipopers, editidtipopers, editposiciontexto, errors, estadoCambioImg, estadoSinInfo, txtvistprod1, txtvistprod2, estadoImagen, posiciontexto, rutaimgfondprod, rutaimginferior } = this.state;
        const styles = reactCSS({
            'default': {
                color: {
                    width: '36px',
                    height: '14px',
                    borderRadius: '2px',
                    background: this.state.color,
                },
                swatch: {
                    padding: '5px',
                    background: '#fff',
                    borderRadius: '1px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                    display: 'inline-block',
                    cursor: 'pointer',
                },
                popover: {
                    position: 'absolute',
                    zIndex: '2',
                },
                cover: {
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                },
            },
        });
        return (
            <React.Fragment>
                <p>Imagen - Texto Ahorro</p>
                <hr />
                {estadoSinInfo ? (
                    <React.Fragment>
                        <div className="plus_add_agencia align-items-center d-flex" onClick={() => { this.handleNew() }}>
                            {estadoNuevo ? <p>Cancelar &nbsp;<MDBIcon icon="times" size="lg" /></p> : <p>Agregar Nuevo &nbsp;<MDBIcon icon="plus-circle" size="lg" /></p>}
                        </div>
                    </React.Fragment>
                ) : (
                    <React.Fragment></React.Fragment>
                )}
                {estadoNuevo ? (
                    <React.Fragment>
                        <div className="justify-content-center d-flex">
                            <div className="text-center">
                                <FileUploader ref={this.uploader} estadoImg={estadoCambioImg1} FCambiar={this.cambioImagen1} />
                            </div>
                        </div>
                        <div className="container mt-2 pt-3 pb-5">
                            <div className="row mb-4">
                                <div className="col-md-6">
                                    <MDBInput label="Nombre" type="text" name="nombvtaprod" value={nombvtaprod} onChange={this.textInput} />
                                    {errors.nombvtaprod && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '50px' }}>{errors.nombvtaprod}</strong>}
                                </div>
                                <div className="col-md-6">
                                    <label>Tipo de Persona</label>
                                    <select name="idtipopers" className="form-control" value={idtipopers} onChange={this.textInput}>
                                        <option value="DEFAULT" disabled>Seleccione..</option>
                                        <option value="1">Natural</option>
                                        <option value="0">Juridica</option>
                                    </select>
                                    {errors.idtipopers && <strong className="text-danger" style={{ fontSize: '13px' }}>{errors.idtipopers}</strong>}
                                </div>
                                <div className="col-md-6">
                                    <MDBInput label="Texto 1" type="text" name="txtvistprod1" value={txtvistprod1} onChange={this.textInput} />
                                    {errors.txtvistprod1 && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '50px' }}>{errors.txtvistprod1}</strong>}
                                </div>
                                <div className="col-md-6">
                                    <MDBInput label="Texto 2" type="text" name="txtvistprod2" value={txtvistprod2} onChange={this.textInput} />
                                    {errors.txtvistprod2 && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', top: '50px' }}>{errors.txtvistprod2}</strong>}
                                </div>
                                <div className="col-md-6 mt-3">
                                    <label>Posición Texto</label>
                                    <select name="posiciontexto" className="form-control" value={posiciontexto} onChange={this.textInput}>
                                        <option value="DEFAULT" disabled>Seleccione..</option>
                                        <option value="1">Izquierda</option>
                                        <option value="0">Derecha</option>
                                    </select>
                                    {errors.posiciontexto && <strong className="text-danger" style={{ fontSize: '13px' }}>{errors.posiciontexto}</strong>}
                                </div>
                                <div className="col-md-6 mt-3">
                                    <p>Color Texto</p>
                                    <div style={styles.swatch} onClick={this.handleClick}>
                                        <div style={styles.color} />
                                    </div>
                                    {this.state.displayColorPicker ? <div style={styles.popover}>
                                        <div style={styles.cover} onClick={this.handleClose} />
                                        <SketchPicker color={this.state.color} onChange={this.handleChange} />
                                    </div> : null}
                                </div>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-md-6 text-center">
                                    <FileUploaderUpdate ref={this.uploader2} estadoImg={estadoCambioImg1} FCambiar={this.cambioImagen} />
                                </div>
                                <div className="col-md-6">
                                    <MDBInput label="Titulo 1 Inferior" type="text" name="titulo1inferior" value={this.state.titulo1inferior} onChange={this.textInput} />
                                    {errors.titulo1inferior && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', marginTop: '-30px' }}>{errors.titulo1inferior}</strong>}
                                    <MDBInput label="Descripcion 1 Inferior" type="text" name="desc1inferior" value={this.state.desc1inferior} onChange={this.textInput} />
                                    {errors.desc1inferior && <strong className="text-danger" style={{ fontSize: '13px', position: 'absolute', marginTop: '-30px' }}>{errors.desc1inferior}</strong>}
                                    <MDBInput label="Titulo 2 Inferior" type="text" name="titulo2inferior" value={this.state.titulo2inferior} onChange={this.textInput} />
                                    <MDBInput label="Descripcion 2 Inferior" type="text" name="desc2inferior" value={this.state.desc2inferior} onChange={this.textInput} />
                                </div>
                            </div>
                        </div>
                        <div className="pt-2 mt-5 modal-footer w-100">
                            <button type="button" className="btn btn-primary px-5" onClick={this.insertarDatos}>Guardar nuevo &nbsp; <MDBIcon icon="save" /></button>
                        </div>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className="listado_banner pt-5">
                            <p>Seleccionar banner</p>
                            <ul>
                                {infoPersona.map((e, i) => {
                                    let view;
                                    if (e.estado === 1) {
                                        view = <MDBIcon icon="eye" size="lg" style={{ color: 'black', position: 'absolute', right: '-38px', marginTop: '5px' }} />
                                    } else {
                                        view = <MDBIcon onClick={() => { this.activar(e.idvtaprod, e.idtipopers) }} icon="eye-slash" size="lg" style={{ color: 'black', position: 'absolute', right: '-38px', marginTop: '5px' }} />
                                    }
                                    return [
                                        <li key={i} id={e.idvtaprod} onClick={() => { this.busquedaId(e.idvtaprod) }}>{e.nombvtaprod} {view}</li>
                                    ]
                                })}
                            </ul>
                        </div>
                        <div className="justify-content-center d-flex">
                            <div className="text-center">
                                {estadoImagen ? (
                                    <FileUploader ref={this.uploader} ruta={rutaimgfondprod} estadoImg={estadoCambioImg} FCambiar={this.cambioImagen} />
                                ) : (
                                    <React.Fragment></React.Fragment>
                                )}
                            </div>
                        </div>
                        <div className="container mt-5 pt-5 pb-5">
                            <div className="row">
                                <div className="col-md-6">
                                    <MDBInput label="Nombre" type="text" name="editnombvtaprod" value={this.state.editnombvtaprod} onChange={this.textInput} />
                                </div>
                                <div className="col-md-6">
                                    <label>Tipo de Persona</label>
                                    <select name="editidtipopers" className="form-control" value={editidtipopers} onChange={this.textInput}>
                                        <option value="DEFAULT" disabled>Seleccione..</option>
                                        <option value="1">Natural</option>
                                        <option value="0">Juridica</option>
                                    </select>
                                </div>
                                <div className="col-md-6">
                                    <MDBInput label="Texto 1" type="text" name="edittxtvistprod1" value={this.state.edittxtvistprod1} onChange={this.textInput} />
                                </div>
                                <div className="col-md-6">
                                    <MDBInput label="Texto 2" type="text" name="edittxtvistprod2" value={this.state.edittxtvistprod2} onChange={this.textInput} />
                                </div>
                                <div className="col-md-6 mt-3">
                                    <label>Posición Texto</label>
                                    <select name="editposiciontexto" className="form-control" value={editposiciontexto} onChange={this.textInput}>
                                        <option value="DEFAULT" disabled>Seleccione..</option>
                                        <option value="1">Izquierda</option>
                                        <option value="0">Derecha</option>
                                    </select>
                                </div>
                                <div className="col-md-6 mt-3">
                                    <p>Color Texto</p>
                                    <div style={styles.swatch} onClick={this.handleClick}>
                                        <div style={styles.color} />
                                    </div>
                                    {this.state.displayColorPicker ? <div style={styles.popover}>
                                        <div style={styles.cover} onClick={this.handleClose} />
                                        <SketchPicker color={this.state.color} onChange={this.handleChange} />
                                    </div> : null}
                                </div>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-md-6 text-center">
                                    {estadoImagen ? (
                                        <FileUploaderUpdate ref={this.uploader2} ruta={rutaimginferior} estadoImg={estadoCambioImg1} FCambiar={this.cambioImagen1} />
                                    ) : (
                                        <React.Fragment></React.Fragment>
                                    )}
                                </div>
                                <div className="col-md-6">
                                    <MDBInput label="Titulo 1 Inferior" type="text" name="edittitulo1inferior" value={this.state.edittitulo1inferior} onChange={this.textInput} />
                                    <MDBInput label="Descripcion 1 Inferior" type="text" name="editdesc1inferior" value={this.state.editdesc1inferior} onChange={this.textInput} />
                                    <MDBInput label="Titulo 2 Inferior" type="text" name="edittitulo2inferior" value={this.state.edittitulo2inferior === 'N/A' ? '' : this.state.edittitulo2inferior} onChange={this.textInput} />
                                    <MDBInput label="Descripcion 2 Inferior" type="text" name="editdesc2inferior" value={this.state.editdesc2inferior === 'N/A' ? '' : this.state.editdesc2inferior} onChange={this.textInput} />
                                </div>
                            </div>
                        </div>
                        <div className="pt-2 mt-5 modal-footer w-100">
                            <button type="button" className="btn btn-success px-5" onClick={() => { this.actualizarDatos(this.state.idSeleccionado) }}>Guardar cambios &nbsp; <MDBIcon icon="save" /></button>
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

export default ImagenTexto;