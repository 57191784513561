import React, { Component } from 'react';
import { URLImgFront } from '../../../config/URLDB';

class Seccion01 extends Component {

    render() {
        return (
            <React.Fragment>
                <div className="bg-white py-2 pt-4">
                    <div className="mb-lg-3 pt-5" style={{ width: '100%' }}>
                        <div className="d-flex justify-content-center pt-4">
                            <img style={{ width: '100%', marginBottom: '0px', objectFit: 'contain' }} className="img-fluid" src={`${URLImgFront}/assets/EstrategiasComerciales/TPTG_CAMPANIAAPORTESSOCIALES.jpg`} alt="Estrategia" />
                        </div>
                        <div>
                            <p className="text-center px-3 px-md-5 my-0 mx-2 mx-md-5 pb-1" style={{ fontSize: '4.3vmin', fontFamily: 'Posterama 1927', color: '#808080', fontWeight: 'bold', lineHeight:'normal' }}>Consulte los números que tiene asignados para el sorteo</p>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Seccion01;