import React, { Component } from 'react';
import { URLImgFront } from '../../../config/URLDB';
import DetalleItemsTipoPersona from './DetalleItemsTipoPersona';

class PersonaNatural extends Component {
    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props);
        this.state = {
            activeAccordion: false
        };
    }

    toggleAccordion = (index) => {
        this.setState(prevState => ({
            activeAccordion: prevState.activeAccordion === index ? null : index
        }));
    };
    
    render() {
        const { activeAccordion } = this.state;

        return (
            <React.Fragment>
                {this.props.data.map((e, i) => {
                    const isActive = activeAccordion === i;

                    return [
                        <React.Fragment key={i}>
                            {/*<div className="col-md-12 my-3 px-0" style={{ background: '#fff' }}>*/}
                            {/*    <div className="row">*/}
                            {/*        <div className="col-md-6">*/}
                            {/*            {this.props.stateImg ? (*/}
                            {/*                <React.Fragment>*/}
                            {/*                    {e.tipocargue === 1 ? (*/}
                            {/*                        <Iframes ancho={'100%'} alto={'400px'} url={e.rutaimgdeta} />*/}
                            {/*                    ) : (*/}
                            {/*                        <img className="img-fluid" style={{ width: '100%' }} src={`${URLImgFront}/assets${e.rutaimgdeta}`} alt={e.rutaimgdeta} />*/}
                            {/*                    )}*/}
                            {/*                </React.Fragment>*/}
                            {/*            ) : (*/}
                            {/*                <React.Fragment></React.Fragment>*/}
                            {/*            )}*/}
                            {/*        </div>*/}

                            {/*        <div className="col-md-6 d-flex align-items-center">*/}
                            {/*            <div className="pl-5 pb-5 tarjetas_contenido">*/}
                            {/*                <p className="font-weight-bold pl-5" style={{ fontSize: '24px' }}>{e.titcoopvirtdet}</p>*/}
                            {/*                <p className="w-75 pl-5" style={{ fontSize: '22px' }}>{e.desccoopvirtdet}</p>*/}
                            {/*                <p className="font-weight-bold pl-5" style={{ fontSize: '15px' }}>{e.piecoopvirtdet} </p>*/}
                            {/*                {e.manejaenlace === 1 ? (*/}
                            {/*                    <a href={`${e.rutaenlace}`} target={'_blank'} rel="noopener noreferrer">*/}
                            {/*                        <span className="mt-5 font-weight-bold px-5 text-white" style={{ background: '#FA7500', fontSize: '17px', borderRadius: '20px', position: 'absolute', left: '30%', bottom: '30px' }}>{e.titcoopvirtdet}</span>*/}
                            {/*                    </a>*/}
                            {/*                ) : (<React.Fragment></React.Fragment>)}*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            
                            <div className="col-md-12 my-2 px-0 accordion-container">
                                <div className={`accordion-item ${isActive ? 'active' : ''}`}>
                                    <div className="accordion-header" onClick={() => this.toggleAccordion(i)}>
                                        <span className="accordion-icon" style={{ width: '40px', height: '40px' }}>
                                            <object data={`${URLImgFront}/assets${e.rutaiconotit}`} type="image/svg+xml" className={`item-icon-accordion ${isActive ? 'active' : ''}`} aria-label="PnControlIcono"></object>
                                        </span>
                                        <span className="accordion-title">{e.titulo}</span>
                                        <span className="accordion-arrow"><i className={`fa fa-angle-down ${isActive ? 'rotate' : ''}`}></i></span>
                                    </div>

                                    <div className="accordion-content align-items-center" style={{ display: isActive ? 'block' : 'none' }}>
                                        <DetalleItemsTipoPersona data={e.idmaestrocoopv} />
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    ]
                })}
            </React.Fragment>
        );
    }
}

export default PersonaNatural;