import React from 'react';
import Header from '../components/Principal/Header';
import Footer from '../components/Principal/Footer';
import Seccion01 from '../components/EstrategiasComerciales/TodosPonenTodosGanan/Seccion01';
import Seccion02 from '../components/EstrategiasComerciales/TodosPonenTodosGanan/Seccion02';
import Seccion03 from '../components/EstrategiasComerciales/TodosPonenTodosGanan/Seccion03';
import Seccion04 from '../components/EstrategiasComerciales/TodosPonenTodosGanan/Seccion04';
import Seccion05 from '../components/EstrategiasComerciales/TodosPonenTodosGanan/Seccion05';
import Seccion06 from '../components/EstrategiasComerciales/TodosPonenTodosGanan/Seccion06';
import Seccion07 from '../components/EstrategiasComerciales/TodosPonenTodosGanan/Seccion07';

function EstrategiasComercialesTPTG() {

    return (
        <React.Fragment>
            <Header id={2} />

            <Seccion01 />

            <Seccion02 />

            <Seccion03 />

            <Seccion04 />

            <Seccion05 />

            <Seccion06 />

            <Seccion07 />

            <Footer />
        </React.Fragment>
    );
}

export default EstrategiasComercialesTPTG;